import * as React from 'react';

import {
  Box,
  BoxProps,
} from '@waglabs/box-next';
import StyledNavLink from '@waglabs/styled-nav-link';
import Icon from '@waglabs/icon';

import { ROUTE_APP } from '../../../../../../constants';

const WagLogo = () => (
  <Box
    display={['block', 'block', 'none']}
    m={0}
    width={1}
    textAlign="center"
  >
    <StyledNavLink
      to={ROUTE_APP}
      data-testid="logo"
    >
      <Icon
        mt={1}
        hover
        name="wagTextLogo"
        alt="Wag! Logo"
        width={['48px', '64px']}
        height={['24px', '32px']}
      />
    </StyledNavLink>
  </Box>
);

export type MobileNavigationTitleProps = BoxProps;
export const MobileNavigationTitle = (props: MobileNavigationTitleProps) => {

  return (
    <Box
      display="block"
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      zIndex="0"
      {...props}
    >
      <WagLogo />
    </Box>
  );
};
