
import { createAction } from 'redux-actions';
import type {
  CreateDogParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  OWNER_REGISTRATION_DOG_INFO_CREATE,
  OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS,
  OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE,
} from '../../actionTypes';


/**
 * Register owner create dog info
 */
export type RegisterOwnerDogInfoCreateParams = {
  age: string,
  name: string,
  breed: string,
  imageURL: string,
};

export type RegisterOwnerDogInfoCreatePayload = RegisterOwnerDogInfoCreateParams;

export type RegisterOwnerDogInfoCreateAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO_CREATE,
  payload: RegisterOwnerDogInfoCreatePayload,
};

export const registerOwnerDogInfoCreate: (
  RegisterOwnerDogInfoCreateParams,
) => RegisterOwnerDogInfoCreateAction = createAction(
  OWNER_REGISTRATION_DOG_INFO_CREATE,
);


/**
 * Register owner dog create info success
 */
export type RegisterOwnerDogInfoCreateSuccessAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS,
  payload: CreateDogParsedResponse,
};

export const registerOwnerDogInfoCreateSuccess: (
  CreateDogParsedResponse,
) => RegisterOwnerDogInfoCreateSuccessAction = createAction(
  OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS,
);


/**
 * Register owner dog create info failure
 */
export type RegisterOwnerDogInfoCreateFailureAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const registerOwnerDogInfoCreateFailure: (
  ErrorParsedResponse,
) => RegisterOwnerDogInfoCreateFailureAction = createAction(
  OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE,
);
