import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import type { DeleteOwnerPreferredWalkerRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_PREFERRED_WALKER_DELETE, Endpoint } from "../../../constants/endpoints";
import { OWNER_PREFERRED_WALKER_DELETE } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { deleteOwnerPreferredWalkerSuccess, deleteOwnerPreferredWalkerFailure, DeleteOwnerPreferredWalkerAction } from "../../../actions/owner/deleteOwnerPreferredWalker";
import { deleteOwnerPreferredWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const deleteOwnerPreferredWalker$ = (action$: Action$, state$: State$, {
  destroy$
}: Dependencies) => action$.pipe(ofType(OWNER_PREFERRED_WALKER_DELETE), retry(3), switchMap(({
  payload: {
    walkerID
  }
}: DeleteOwnerPreferredWalkerAction) => destroy$(({
  endpoint: ENDPOINT_OWNER_PREFERRED_WALKER_DELETE({
    ownerID: getOwnerID(state$.value),
    walkerID
  }),
  payload: {},
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: DeleteOwnerPreferredWalkerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: deleteOwnerPreferredWalkerSuccess,
  onFailure: deleteOwnerPreferredWalkerFailure,
  parser: deleteOwnerPreferredWalkerResponseParser
})), catchError(handleErrorV5Response(action$, deleteOwnerPreferredWalkerFailure)))));

export default deleteOwnerPreferredWalker$;