
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalksInThePastParsedResponse,
} from 'webApp';

import {
  WALKS_IN_THE_PAST_GET,
  WALKS_IN_THE_PAST_GET_SUCCESS,
  WALKS_IN_THE_PAST_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walks in the past for the current user
 */
export type GetWalksInThePastAction = {
  type: typeof WALKS_IN_THE_PAST_GET,
}

export const getWalksInThePast: () => GetWalksInThePastAction = createAction(
  WALKS_IN_THE_PAST_GET,
);


/**
 * Get walks in the past for the current user success
 */
export type GetWalksInThePastSuccessAction = {
  type: typeof WALKS_IN_THE_PAST_GET_SUCCESS,
  payload: GetWalksInThePastParsedResponse,
}

export const getWalksInThePastSuccess: (
  GetWalksInThePastParsedResponse,
) => GetWalksInThePastSuccessAction = createAction(
  WALKS_IN_THE_PAST_GET_SUCCESS,
);


/**
 * Get walks in the past for the current user failure
 */
export type GetWalksInThePastFailureAction = {
  type: typeof WALKS_IN_THE_PAST_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalksInThePastFailure: (
  ErrorParsedResponse
) => GetWalksInThePastFailureAction = createAction(
  WALKS_IN_THE_PAST_GET_FAILURE,
);
