import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const WeightOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M20.913 2H3.087C2.487 2 2 2.46 2 3.035v17.93C2 21.54 2.487 22 3.087 22h17.826c.6 0 1.087-.46 1.087-1.035V3.035C22 2.46 21.513 2 20.913 2zm.217 18.965c0 .087-.104.165-.217.165H3.087c-.121 0-.217-.078-.217-.165V3.035c0-.087.104-.165.217-.165h17.826c.121 0 .217.078.217.165v17.93zM9.334 9.687c.13.13.322.165.487.087a5.271 5.271 0 014.35 0 .454.454 0 00.495-.096l2.61-2.626a.425.425 0 00.122-.33.448.448 0 00-.165-.313A8.394 8.394 0 0011.996 4.6a8.394 8.394 0 00-5.237 1.809.448.448 0 00-.166.313.425.425 0 00.122.33l2.619 2.635zm2.662-4.217a7.51 7.51 0 014.306 1.339l-2.053 2.06a6.443 6.443 0 00-1.462-.373l1.175-1.67-.714-.504-1.496 2.121a6.097 6.097 0 00-2.01.418L7.69 6.8a7.567 7.567 0 014.307-1.33z"
      fill="#727272"
      fillRule="nonzero"
    />
  </SvgIcon>
);
