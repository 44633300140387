import { useSelector } from "react-redux";
import { WALK_TYPE_TRAINING, WALK_TYPE_DIGITAL_TRAINING } from "../constants";
import { getWalkTypes } from "../selectors";
const walkTrainingIds = [WALK_TYPE_TRAINING, WALK_TYPE_DIGITAL_TRAINING];
export const useGetTrainingWalk = (length?: number) => {
  const data = useSelector(getWalkTypes);

  if (!Array.isArray(data)) {
    return undefined;
  }

  const trainingWalk = data.find(walk => walkTrainingIds.includes(walk.id) && length && walk.length === length);
  return trainingWalk;
};