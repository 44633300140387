// for use with onKeyPress events, will execute callback if user presses enter
const onEnterPress = (
  e: KeyboardEvent,
  callback?: () => void,
  shouldPreventDefault?: boolean,
) => {
  const keycode = e.keyCode ? e.keyCode : e.which;

  if (keycode === 13) {
    if (shouldPreventDefault) {
      e.preventDefault();
    }

    if (callback) {
      callback();
    }
  }
};
export default onEnterPress;
