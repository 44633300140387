
import { createAction } from 'redux-actions';
import type {
  PasswordTipsParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  PASSWORD_TIPS,
  PASSWORD_TIPS_FAILURE,
  PASSWORD_TIPS_SUCCESS,
} from '../../actionTypes';

/**
 * Get password tips
 */

export type GetPasswordTipsAction = {
  type: typeof PASSWORD_TIPS;
};
export const getPasswordTips: () => GetPasswordTipsAction = createAction(
  PASSWORD_TIPS,
);

/**
 * Get password tips success
 */

export type GetPasswordTipsSuccessAction = {
  type: typeof PASSWORD_TIPS_SUCCESS,
  payload: PasswordTipsParsedResponse,
}

export const getPasswordTipsSuccess: (response: PasswordTipsParsedResponse) =>
  GetPasswordTipsSuccessAction = createAction(
    PASSWORD_TIPS_SUCCESS,
  );

/**
 * Get password tips failure
 */

export type GetPasswordTipsFailureAction = {
  type: typeof PASSWORD_TIPS_FAILURE,
  payload: ErrorParsedResponse,
}

export const getPasswordTipsFailure: (error: ErrorParsedResponse) =>
  GetPasswordTipsFailureAction = createAction(
    PASSWORD_TIPS_FAILURE,
  );
