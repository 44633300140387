import { handleActions } from 'redux-actions';
import type { Dogs } from 'webApp';

import { BOOKING_FIELDS_ADD } from '../../actions/actionTypes';
import type { AddBookingFieldsAction } from '../../actions/booking/bookingFieldsAdd';

export type BookingDay = {
  key: string;
  label: string;
  selected: boolean;
};
export type BookingFieldsState = {
  startDate: Date[];
  startTime: string;
  recurringTimes: any;
  days: BookingDay[];
  dogs: Dogs;
  walkTypeId: number;
  pickUp: boolean;
};
export const initialState = {
  startDate: [],
  startTime: '',
  recurringTimes: {},
  days: [],
  dogs: [],
  walkTypeId: 1,
  pickUp: false,
};
export const reducer: any = handleActions<BookingFieldsState, Record<string, any>>({
  [BOOKING_FIELDS_ADD]: (state: BookingFieldsState, {
    payload: {
      startDate,
      startTime,
      recurringTimes,
      days,
      dogs,
      walkTypeId,
      pickUp,
    },
  }: AddBookingFieldsAction): BookingFieldsState => ({ ...state,
    startDate,
    startTime,
    recurringTimes,
    days,
    dogs,
    walkTypeId,
    pickUp }),
}, initialState);
export default reducer;
