import {
  AnyAction,
  createSlice,
} from '@reduxjs/toolkit';

import { STATUS_CODE_CONFLICT } from '../../constants';
import { ReduxState } from '../../reducers';
import { initialState as oldInitialState } from '../../reducers/owner/owner.reducer';
import { webApp } from '../../types/webapp';
import { socialAuthApi } from '../auth/socialAuthApi';
import { authApi as authApiV6 } from '../auth/v6/authApi';

export type OwnerState = webApp.Owner & {
  /**
   * TODO - This property was recently added and carried over
   * from the `Auth` slice
   *
   * There has been a discussion between ownerToken and sessionToken
   * Check to see if we can streamline it next iteration
   *
   */
  ownerToken?: string | null;
  socialEmail?: string | undefined;
  directBookingWalker?: webApp.DirectBookingWalker;
};

const initialState: Partial<OwnerState> = {
  ...oldInitialState,
  // add new initial state
};

const slice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    setOwnerID: (state, { payload }) => ({
      ...state,
      ownerID: payload,
    }),
    setOwnerToken: (state, { payload }) => ({
      ...state,
      token: payload,
    }),
    setPreferredServices: (state, { payload }) => ({
      ...state,
      preferredServices: payload,
    }),
    setDirectBookingWalker: (state, { payload }) => ({
      ...state,
      directBookingWalker: payload,
    }),
    setAddress: (state, { payload }) => ({
      ...state,
      address: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher<AnyAction>(
      authApiV6.endpoints.postAuthenticationUpgrade.matchFulfilled,
      (state, {
        payload,
      }) =>
      /* Leaving return statement for easy debugging and an example
            of how to add a return statement to these reducers */
        ({
          ...state,
          ownerID: payload.user.roleId,
          firstName: payload.user.firstName,
          lastName: payload.user.lastName,
          email: payload.user.email,
        }),
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postFacebookSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.token,
        ownerID: payload.id,
        newAccount: payload.newAccount,
      }),
    );

    /*
        TODO dry up rejected matchers
        for Google, Facebook and Apple
        to all use the same actions.
      */

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postFacebookSocialAuthentication.matchRejected,
      (state, {
        payload,
      }) => {
        /*
            Conditional logic for eror state 409
            to start link accounts flow for Apple
          */
        if (payload.status === STATUS_CODE_CONFLICT) {
          return ({
            ...state,
            version: payload.version,
            status: payload.status,
            title: payload.title,
            type: payload.type,
            detail: payload.detail,
            socialEmail: payload.email,
          });
        }

        return ({
          ...state,
          version: payload.version,
          status: payload.status,
          title: payload.title,
          type: payload.type,
          detail: payload.detail,
        });
      },
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postAppleSocialAuthentication.matchRejected,
      (state, {
        payload,
      }) => {
        if (payload.status === STATUS_CODE_CONFLICT) {
          return ({
            ...state,
            version: payload.version,
            status: payload.status,
            title: payload.title,
            type: payload.type,
            detail: payload.detail,
            socialEmail: payload.email,
          });
        }

        return ({
          ...state,
          version: payload.version,
          status: payload.status,
          title: payload.title,
          type: payload.type,
          detail: payload.detail,
        });
      },
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postGoogleSocialAuthentication.matchRejected,
      (state, {
        payload,
      }) => {
        if (payload.status === STATUS_CODE_CONFLICT) {
          return ({
            ...state,
            version: payload.version,
            status: payload.status,
            title: payload.title,
            type: payload.type,
            detail: payload.detail,
            socialEmail: payload.email,
          });
        }

        return ({
          ...state,
          version: payload.version,
          status: payload.status,
          title: payload.title,
          type: payload.type,
          detail: payload.detail,
        });
      },
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postGoogleSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.token,
        ownerID: payload.id,
        newAccount: payload.newAccount,
      }),
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postAppleSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.token,
        ownerID: payload.id,
        newAccount: payload.newAccount,
      }),
    );
  },
});

const selectors = {
  getOwner: (state: ReduxState) => ({
    ...state.owner,
    /**
     * Unwrap `ownerID` from its multiple type variation
     */
    ownerID: String(state.owner.ownerID),
  }),
  getOwnerID: (state: ReduxState) => state.owner.ownerID,
  getOwnerToken: (state: ReduxState) => state.owner.ownerToken,
  getSocialEmail: (state: ReduxState) => state.owner.socialEmail,
  getDirectBookingWalker: (state: ReduxState) => state.owner.directBookingWalker,
  getAddress: (state: ReduxState) => state.owner.address,
  getOwnerPremiumIsSubscribed: (state: ReduxState) => !!state.owner.premium && state.owner.premium.isSubscribed,
};

export const ownerSlice = {
  ...slice,
  selectors,
};
