// ! TODO: - Move to wag-react
import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const FemaleIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#727272"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M20 8.48C20 4.353 16.638 1 12.5 1S5 4.353 5 8.48c0 3.978 3.132 7.225 7.059 7.454V18.6h-3.53v.88h3.53V23h.882v-3.52h3.53v-.88h-3.53v-2.666C16.868 15.704 20 12.458 20 8.48Zm-14.118 0c0-3.643 2.965-6.6 6.618-6.6 3.653 0 6.618 2.957 6.618 6.6s-2.965 6.6-6.618 6.6c-3.653 0-6.618-2.957-6.618-6.6Z"
      fill="#995AA0"
      fillRule="nonzero"
      stroke="#995AA0"
    />
  </SvgIcon>
);
