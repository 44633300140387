import { useSelector } from 'react-redux';

import { draftsSlice } from '../slices/drafts';
import { webApp } from '../types/webapp';

import { useDraft } from './useDraft';

export const useBookingDraft = <T extends webApp.BaseBookingDraft>(
  draftKey: string,
) => {
  const draftHelpers = useDraft<T>(draftKey);
  const draft = useSelector(
    draftsSlice.selectors.getBookingDraftByKey(draftKey),
  ) as T | undefined;

  const { addDraft, clearDraft } = draftHelpers;

  return {
    addDraft,
    clearDraft,
    draft,
  };
};
