import {
  handleWagApiResponse,
  HandleWagApiResponseFn,
} from './handleWagApiResponse/handleWagApiResponse';
import { handleWagApiV4Response } from './handleWagApiV4Response/handleWagApiV4Response';
import { handleWagApiV5Response } from './handleWagApiV5Response/handleWagApiV5Response';
import { handleErrorResponse as handleApiErrorResponse } from './handleErrorResponse/handleErrorResponse';

export const handleResponse = ({
  transformResponse,
  response,
  onSuccess,
  onFailure,
  parser,
  isArray,
  additionalData
}: HandleWagApiResponseFn) => handleWagApiResponse({
  transformResponse,
  response,
  onSuccess,
  onFailure,
  parser,
  isArray,
  additionalData
});
export const handleV4Response = ({
  response,
  onSuccess,
  onFailure,
  parser
}) => handleWagApiV4Response({
  response,
  onSuccess,
  onFailure,
  parser
});
export const handleV5Response = ({
  response,
  onSuccess,
  onFailure,
  parser
}) => handleWagApiV5Response({
  response,
  onSuccess,
  onFailure,
  parser
});
export const handleErrorResponse = ({
  response,
  onError
}) => handleApiErrorResponse({
  response,
  onError
});
export { handleErrorV5Response } from "./handleErrorV5Response/handleErrorV5Response";