
import { createAction } from 'redux-actions';
import type {
  PostAuthenticationVerifyParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  AUTHENTICATION_VERIFY_POST,
  AUTHENTICATION_VERIFY_POST_SUCCESS,
  AUTHENTICATION_VERIFY_POST_FAILURE,
} from '../../actionTypes';

/**
 * Post authentication verify
 */
export type PostAuthenticationVerifyParams = {
  sessionToken?: string,
  code: string,
  authenticationMethod: string,
};

export type PostAuthenticationVerifyPayload = PostAuthenticationVerifyParams;

export type PostAuthenticationVerifyAction = {
  type: typeof AUTHENTICATION_VERIFY_POST,
  payload: PostAuthenticationVerifyPayload,
};

export const postAuthenticationVerify: (
  params: PostAuthenticationVerifyParams,
) => PostAuthenticationVerifyAction = createAction(
  AUTHENTICATION_VERIFY_POST,
);

/**
 * Post authentication verify success action
 */
export type PostAuthenticationVerifySuccessAction = {
  type: typeof AUTHENTICATION_VERIFY_POST_SUCCESS,
};

export const postAuthenticationVerifySuccess: (
  response: PostAuthenticationVerifyParsedResponse
) => PostAuthenticationVerifySuccessAction = createAction(
  AUTHENTICATION_VERIFY_POST_SUCCESS,
);

/**
 * Post authentication verify failure action
 */
export type PostAuthenticationVerifyFailureAction = {
  type: typeof AUTHENTICATION_VERIFY_POST_FAILURE,
  payload: ErrorParsedResponse,
};

export const postAuthenticationVerifyFailure: (
  error: ErrorParsedResponse,
) => PostAuthenticationVerifyFailureAction = createAction(
  AUTHENTICATION_VERIFY_POST_FAILURE,
);
