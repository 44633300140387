
import { createAction } from 'redux-actions';
import type {
  UpdateCreditCardParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  CREDIT_CARD_UPDATE,
  CREDIT_CARD_UPDATE_SUCCESS,
  CREDIT_CARD_UPDATE_FAILURE,
} from '../../actionTypes';


/**
 * Update credit card
 */
export type UpdateCreditCardParams = {
  stripeToken: string,
}

export type UpdateCreditCardPayload = UpdateCreditCardParams;

export type UpdateCreditCardAction = {
  type: typeof CREDIT_CARD_UPDATE,
  payload: UpdateCreditCardPayload,
};

export const updateCreditCard: (UpdateCreditCardParams) => UpdateCreditCardAction = createAction(
  CREDIT_CARD_UPDATE,
  ({ stripeToken }) => ({ stripeToken }),
);

/**
 * Update credit card success
 */
export type UpdateCreditCardSuccessAction = {
  type: typeof CREDIT_CARD_UPDATE_SUCCESS,
  payload: UpdateCreditCardParsedResponse,
};

export const updateCreditCardSuccess: (
  UpdateCreditCardParsedResponse,
) => UpdateCreditCardSuccessAction = createAction(
  CREDIT_CARD_UPDATE_SUCCESS,
);


/**
 * Update credit card failure
 */

export type UpdateCreditCardFailureAction = {
  type: typeof CREDIT_CARD_UPDATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateCreditCardFailure: (
  ErrorParsedResponse,
) => UpdateCreditCardFailureAction = createAction(
  CREDIT_CARD_UPDATE_FAILURE,
);
