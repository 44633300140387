import moment from "moment";
import type { GetWalksInProgressResponse, WalkInProgress as WalkInProgressWagApi } from "wagAPI";
import type { GetWalksInProgressParsedResponse, WalkInProgress as WalkInProgressWebApp } from "webApp";
import { parseWalkSitting } from "./getWalksUpcomingResponseParser";
import { toStandardTime } from "../../../utils/scheduleFormatter";
export const parseWalkInProgress = (walk: WalkInProgressWagApi): WalkInProgressWebApp => {
  const startDateMatcher = (walk.date || '').match(/(\d{4})-(\d\d?)-(\d\d?)/); // year-month-day

  const startDate = startDateMatcher ? `${startDateMatcher[2]}/${startDateMatcher[3]}/${startDateMatcher[1]}` : '';
  const isCancelled = Boolean(Number(walk.is_cancelled));
  const isConfirmed = Boolean(Number(walk.is_confirmed));
  const result: WalkInProgressWebApp = {
    daysOfTheWeek: [moment(startDate, 'MM/DD/YYYY').format('dddd')],
    dogs: walk.dogs.map(({
      id
    }) => id),
    isCancelled,
    // because this should only be used for endpoints representing noncompleted walks
    isCompleted: false,
    isConfirmed: isConfirmed && !isCancelled,
    isPending: !isConfirmed && !isCancelled,
    isRecurring: Boolean(Number(walk.is_recurring)),
    // Use the same property to get the price for all the walks parser
    total: walk.estimated_price,
    scheduleID: String(walk.schedule_id),
    startDate,
    // Unlike other getWalks parsers, the start_time here is already a time.
    // No need to convert from date to time. Just need to convert to a standard time.
    startTime: toStandardTime(walk.start_time),
    status: walk.state,
    walkerID: String(walk.walker_id) || '0',
    walkID: String(walk.walk_id),
    walkTypeID: Number(walk.walk_type_id),
    walker: null
  };

  if (walk.walker_id && walk.walker) {
    const {
      walker: {
        bio,
        first_name: firstName,
        id,
        last_name: lastName,
        latitude,
        longitude,
        reviews,
        phone,
        picture,
        rating,
        thumb,
        video
      }
    } = walk;
    result.walker = {
      bio,
      firstName,
      id,
      lastName,
      latitude: Number(latitude),
      longitude: Number(longitude),
      numReviews: reviews.length,
      phone: String(phone),
      picture,
      rating: Number(rating),
      thumb,
      video
    };
  }

  if (walk.sitting) {
    result.sitting = parseWalkSitting(walk);
  }

  return result;
};
export const getWalksInProgressResponseParser = ({
  walk
}: GetWalksInProgressResponse): GetWalksInProgressParsedResponse => ({
  walk: walk && parseWalkInProgress(walk)
});
export default getWalksInProgressResponseParser;