
import { createAction } from 'redux-actions';
import type {
  GetOwnerParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_GET,
  OWNER_GET_SUCCESS,
  OWNER_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get owner
 */
export type GetOwnerAction = {
  type: typeof OWNER_GET,
};

export const getOwner: () => GetOwnerAction = createAction(
  OWNER_GET,
);


/**
 * Get owner success
 */
export type GetOwnerSuccessAction = {
  type: typeof OWNER_GET_SUCCESS,
  payload: GetOwnerParsedResponse,
};

export const getOwnerSuccess: (
  GetOwnerParsedResponse,
) => GetOwnerSuccessAction = createAction(
  OWNER_GET_SUCCESS,
);


/**
 * Get owner failure
 */
export type GetOwnerFailureAction = {
  type: typeof OWNER_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerFailure: (
  ErrorParsedResponse,
) => GetOwnerFailureAction = createAction(
  OWNER_GET_FAILURE,
);
