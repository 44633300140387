import { createSlice } from '@reduxjs/toolkit';

import { store } from '../../types/store';

export type BreedsState = {
  allTypes: store.V6.PetTypeName[];
  allNames: string[];
  byType: Record<store.V6.PetTypeName, {
    type: store.V6.PetTypeName;
    name: string;
  }[]>;
  byName: Record<string, {
    type: store.V6.PetTypeName;
    name: string;
  }[]>;
};

const initialState: BreedsState = {
  allTypes: [],
  allNames: [],
  byType: {
    bird: [],
    cat: [],
    dog: [],
    ferret: [],
    fish: [],
    hamster: [],
    lizard: [],
    rabbit: [],
    rodent: [],
    tortoise: [],
    turtle: [],
  },
  byName: {},
};

/**
 * At the time of creating this, we do not use or store anything
 * under this slice. But retaining this alongside of the breedsApi
 * in case we needed
 */
const slice = createSlice({
  name: 'breeds',
  initialState,
  reducers: {
    clear: () => initialState,
  },
});

export const breedsSlice = {
  ...slice,
};
