import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { OwnerRegisterV5Request, OwnerRegisterResponse } from "wagAPI";
import { handleResponse, handleErrorV5Response } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_REGISTER, Endpoint } from "../../../constants/endpoints";
import { OWNER_REGISTRATION_BASIC_INFO } from "../../../actions/actionTypes";
import { registerOwnerBasicInfoSuccess, registerOwnerBasicInfoFailure, RegisterOwnerBasicInfoAction } from "../../../actions/registerOwner/registerOwnerBasicInfo";
import { getDeviceInformationHeaders, getUTMS } from "../../../selectors";
import { registerOwnerResponseParser } from "../../../helpers/Http/ResponseParsers";

const registerOwnerBasicInfo$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_REGISTRATION_BASIC_INFO), retry(3), switchMap(({
  payload
}: RegisterOwnerBasicInfoAction) => {
  const {
    email,
    password,
    firstName,
    lastName,
    phone,
    signupFlowSource,
    promoCode,
    questionId
  }: RegisterOwnerBasicInfoAction = payload;
  return post$(({
    endpoint: ENDPOINT_OWNER_REGISTER,
    payload: {
      email,
      firstName,
      lastName,
      password,
      phoneNumber: phone,
      registrationSource: process.env.REACT_APP_REGISTRATION_SOURCE || 'webapp',
      signupFlowSource: signupFlowSource || process.env.REACT_APP_SIGNUP_FLOW_SOURCE || 'walking-webpaid:webapp',
      promoCode,
      questionId,

      /**
       * ! hardcoded as of now because back-end doesn't currently do anything
       * useful with referredWalkerId
       */
      referredWalkerId: promoCode ? '12345' : undefined,
      utms: getUTMS(state$.value)
    },
    additionalHeaders: getDeviceInformationHeaders(state$.value)
  } as {
    endpoint: Endpoint;
    payload: OwnerRegisterV5Request;
    additionalHeaders: any;
  })).pipe(flatMap((response: OwnerRegisterResponse) => handleResponse({
    response,
    onSuccess: registerOwnerBasicInfoSuccess,
    onFailure: registerOwnerBasicInfoFailure,
    parser: registerOwnerResponseParser
  })), catchError(handleErrorV5Response(action$, registerOwnerBasicInfoFailure)));
}));

export default registerOwnerBasicInfo$;