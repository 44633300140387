import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { logout } from '../actions/owner/auth';

export const useLogout = () => {
  const dispatch = useDispatch();

  const execute = () => {
    dispatch(logout());
  };

  return {
    execute: useCallback(execute, [dispatch]),
  };
};
