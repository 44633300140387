import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';

import { TRAINING_BOOKING } from '../../../actions/actionTypes';
import {
  bookingTrainingSuccess,
  bookingTrainingFailure,
  BookingTrainingAction,
} from '../../../actions/booking/bookingTraining';
import {
  WALK_TYPE_TRAINING,
  TRAINING_TYPE_ID,
} from '../../../constants';
import {
  ENDPOINT_TRAINING_BOOKING,
  Endpoint,
} from '../../../constants/endpoints';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  getOwnerID,
  getOwnerToken,
} from '../../../selectors';
import { wagApi } from '../../../types/wagapi';
import { webApp } from '../../../types/webapp';

const mapDogsToTrainingBookingPets = (pets: webApp.V6.Pet[]) => (
  Array.isArray(pets) ? pets.map((pet) => Number(pet.id)) : []
);

const bookingTraining$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies,
) => action$.pipe(
  ofType(TRAINING_BOOKING),
  retry(3),
  switchMap(
    ({
      payload: { behaviours, selectedPets, notes, times, usePreferred },
    }: BookingTrainingAction) => post$({
      endpoint: ENDPOINT_TRAINING_BOOKING({
        ownerID: getOwnerID(state$.value),
      }),
      payload: {
        behaviours,
        dogs: mapDogsToTrainingBookingPets(selectedPets),
        notes,
        training_times: times,
        use_preferred: usePreferred || false,
        training_type: TRAINING_TYPE_ID,
        walkTypeId: WALK_TYPE_TRAINING,
      },
      token: getOwnerToken(state$.value),
    } as {
      endpoint: Endpoint;
      payload: wagApi.BookingTrainingRequestPayload;
      token: string;
    }).pipe(
      flatMap((response) => handleResponse({
        response,
        onSuccess: bookingTrainingSuccess,
        onFailure: bookingTrainingFailure,
      } as any), // TODO - Update typings on handleResponse
      ),
      catchError(handleErrorV5Response(action$, bookingTrainingFailure)),
    ),
  ),
);

export default bookingTraining$;
