import * as React from "react";
import Flex from "@waglabs/flex";
type Props = {
  children: any;
  flexDirection?: string | string[];
};
export const BodyContainer = ({
  children,
  ...props
}: Props) => <Flex maxWidth={['100%', '100%', 960]} boxSizing="border-box" width={1} {...props}>
    {children}
  </Flex>;
BodyContainer.defaultProps = {
  flexDirection: 'column'
};