import { ofType } from "redux-observable";
import { catchError, flatMap, switchMap } from "rxjs/operators";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { WALK_DETAILS_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { ENDPOINT_WALK_DETAILS_GET } from "../../../constants/endpoints";
import { getWalkDetailsResponseParser } from "../../../helpers/Http/ResponseParsers";
import { getWalkDetailsSuccess, getWalkDetailsFailure } from "../../../actions/walk/getWalkDetails";
export const getWalkDetails$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALK_DETAILS_GET), switchMap(({
  payload: {
    walkID
  }
}) => get$({
  endpoint: ENDPOINT_WALK_DETAILS_GET({
    ownerID: getOwnerID(state$.value),
    walkID
  }),
  token: getOwnerToken(state$.value)
}).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkDetailsSuccess,
  onFailure: getWalkDetailsFailure,
  parser: getWalkDetailsResponseParser
})), catchError(handleErrorV5Response(action$, getWalkDetailsFailure)))));