
import { createAction } from 'redux-actions';

import {
  WALK_COVER_CREATE,
  WALK_COVER_CREATE_SUCCESS,
  WALK_COVER_CREATE_FAILURE,
} from '../../actionTypes';

/**
 * createWalkCover
 */
type CreateWalkCoverAction = {
  type: typeof WALK_COVER_CREATE,
  payload: {
    id: string,
    token: string,
    walkId: [string],
  },
};

type CreateWalkCoverParams = {
  token: string, // Walker token
  id: string, // Walker id
  walkId: [string] // Id's of walks to be covered
};

export const createWalkCover: (
  params: CreateWalkCoverParams
) => CreateWalkCoverAction = createAction(
  WALK_COVER_CREATE,
  ({
    id,
    token,
    walkId,
  }: CreateWalkCoverParams) => ({ id, token, walkId }),
);

/**
 * createWalkCoverSuccess
 */
type CreateWalkCoverSuccessAction = {
  type: typeof WALK_COVER_CREATE_SUCCESS,
};
export const createWalkCoverSuccess: () => (
  CreateWalkCoverSuccessAction
) = createAction(
  WALK_COVER_CREATE_SUCCESS,
);

/**
 * createWalkCoverFailure
 */
type CreateWalkCoverFailureAction = {
  type: typeof WALK_COVER_CREATE_SUCCESS,
};
export const createWalkCoverFailure: () => (
  CreateWalkCoverFailureAction
) = createAction(
  WALK_COVER_CREATE_FAILURE,
);
