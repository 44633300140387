import { useState } from 'react';

export type UseStepperReturnType = {
  activeStep: number;
  setActiveStep: (...args: Array<any>) => any;
  isCompleted: boolean;
  isLastStep: boolean;
  handleNext: (...args: Array<any>) => any;
  handleBack: (...args: Array<any>) => any;
  handleReset: (...args: Array<any>) => any;
  meta: Record<string, any>;
  setMeta: (...args: Array<any>) => any;
  forceCompleteSteps: (...args: Array<any>) => any;
  steps: any[];
};
export const useStepper = (steps: any[], defaultMeta?: Record<string, any>): UseStepperReturnType => {
  const [activeStep, setActiveStep] = useState<number>(0);

  /**
   * Temporary storage between stages instead of Context for perf,
   * defaultMeta object stops the necessity of assigning objects to the 
   * global state on first render using useEffect style hooks, reducing the
   * risk of race conditions significantly
   */
  const [meta, setMeta] = useState<Record<string, any>>(defaultMeta || {});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const forceCompleteSteps = () => {
    setActiveStep(steps.length);
  };

  return {
    activeStep,
    setActiveStep,
    isCompleted: activeStep === steps.length,
    isLastStep: activeStep === steps.length - 1,
    handleNext,
    handleBack,
    handleReset,
    meta,
    setMeta,
    forceCompleteSteps,
    steps,
  };
};
