// ! TODO: - Move to wag-react
import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const MaleIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#727272"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M22.569 1h-5.72v.88h4.655l-8.061 8.06a7.42 7.42 0 0 0-4.963-1.9A7.485 7.485 0 0 0 1 15.52 7.485 7.485 0 0 0 8.48 23a7.485 7.485 0 0 0 7.48-7.48c0-1.9-.722-3.634-1.901-4.963l8.06-8.061v4.655H23v-5.72A.421.421 0 0 0 22.57 1ZM8.489 22.12a6.602 6.602 0 0 1-6.6-6.6c0-3.643 2.957-6.6 6.6-6.6s6.6 2.957 6.6 6.6-2.957 6.6-6.6 6.6Z"
      fill="#4669B2"
      fillRule="nonzero"
      stroke="#4669B2"
    />
  </SvgIcon>
);
