import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetOwnerRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_GET, Endpoint } from "../../../constants/endpoints";
import { OWNER_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getOwnerSuccess, getOwnerFailure } from "../../../actions/owner/getOwner";
import { getOwnerResponseParser } from "../../../helpers/Http/ResponseParsers";

const getOwner$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_GET,
  payload: {
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetOwnerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getOwnerSuccess,
  onFailure: getOwnerFailure,
  parser: getOwnerResponseParser
})), catchError(handleErrorV5Response(action$, getOwnerFailure)))));

export default getOwner$;