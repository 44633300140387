import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { UpdateWalkerPasswordRequest } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_PASSWORD_UPDATE } from "../../../constants/endpoints";
import { UPDATE_WALKER_PASSWORD } from "../../../actions/actionTypes";
import { updateWalkerPasswordSuccess, updateWalkerPasswordFailure } from "../../../actions/walker/updateWalkerPassword";
import { getWalkerId, getWalkerPasswordUpdateToken } from "../../../selectors";
import type { UpdateWalkerPasswordParams } from "../../../actions/walker/updateWalkerPassword";

const updateWalkerPassword$ = (action$: Action$, state$: State$, {
  putLegacy$
}: Dependencies) => action$.pipe(ofType(UPDATE_WALKER_PASSWORD), switchMap(({
  payload: {
    password
  }
}: {
  payload: UpdateWalkerPasswordParams;
}) => putLegacy$(({
  endpoint: ENDPOINT_WALKER_PASSWORD_UPDATE({
    walkerID: getWalkerId(state$.value)
  }),
  payload: {
    password
  },
  token: getWalkerPasswordUpdateToken(state$.value)
} as {
  endpoint: string;
  payload: UpdateWalkerPasswordRequest;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updateWalkerPasswordSuccess,
  onFailure: updateWalkerPasswordFailure
})), catchError(handleErrorV5Response(action$, updateWalkerPasswordFailure)))));

export default updateWalkerPassword$;