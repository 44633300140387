export const QuizElements = {
  EndorsementsHeaderText: 'endorsements-header-text',
  IntroStageHeaderText: 'intro-stage-header-text',
  QuestionsCarouselHeaderText: 'questions-carousel-header-text',
  ResultsStageHeaderText: 'results-stage-header-text',
  EndorsementsCheckbox: 'endorsements-checkbox',
  EndorsementsNextButton: 'endorsements-next-button',
  IntroNextButton: 'intro-next-button',
  IntroBackButton: 'intro-back-button',
  QuestionsCarouselNextButton: 'questions-carousel-next-button',
  QuestionsCarouselBackButton: 'questions-carousel-back-button',
  ResultsBackButton: 'results-back-button',
};
