/**
 * @deprecated use `PetStrings`
 */

export const PetWeight = {
  Small: 5,
  Medium: 21,
  Large: 51,
  XL: 100,
};

// Rename to petWeightOptions
/**
 * @deprecated - Use `petWeightOptions`
 */
export const dogWeightOptions = [
  {
    label: 'S: 5 - 20 lb',
    value: PetWeight.Small,
    sizeName: 'small',
  },
  {
    label: 'M: 21 - 50 lb',
    value: PetWeight.Medium,
    sizeName: 'medium',
  },
  {
    label: 'L: 51 - 99 lb',
    value: PetWeight.Large,
    sizeName: 'large',
  },
  {
    label: 'XL: 100+ lb',
    value: PetWeight.XL,
    sizeName: 'extraLarge',
  },
];

// TODO - migration
// Once we've migrated all the places to use `petWeightOptions`, move the implementation
// to this variable
export const petWeightOptions = [{
  label: '5 - 20 lb',
  value: PetWeight.Small,
  sizeName: 'small',
  sizeNameDisplayLabel: 'Small',
}, {
  label: '21 - 50 lb',
  value: PetWeight.Medium,
  sizeName: 'medium',
  sizeNameDisplayLabel: 'Medium',
}, {
  label: '51 - 99 lb',
  value: PetWeight.Large,
  sizeName: 'large',
  sizeNameDisplayLabel: 'Large',
}, {
  label: '100+ lb',
  value: PetWeight.XL,
  sizeName: 'extraLarge',
  sizeNameDisplayLabel: 'XL',
}];

// `dogWeightOptions` (array) converted to object for easy lookup through id (value)
// Map = key-value pair
/**
 * @deprecated - Use `petWeightMap`
 */
export const dogWeightMap = dogWeightOptions.reduce<{
  [key: number]: {
    label: string,
    value: number,
  },
}>((accumulator, currentValue) => {
  accumulator[currentValue.value] = currentValue;
  return accumulator;
}, {});

// TODO - migration
// Once we've migrated all the places to use `petWeightMap`, move the implementation
// to this variable
export const petWeightMap = petWeightOptions.reduce<{
  [key: number]: {
    label: string,
    value: number,
    sizeName: string;
  },
}>((accumulator, currentValue) => {
  accumulator[currentValue.value] = currentValue;
  return accumulator;
}, {});
