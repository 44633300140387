import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const EyeIcon = ({
  color = '#00BF8F',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...rest}
  >
    <path
      d="M12 20c-7.948 0-10.94-7.266-10.967-7.337a.42.42 0 010-.326C1.059 12.266 4.052 5 12 5s10.94 7.266 10.967 7.337a.42.42 0 010 .326C22.941 12.734 19.948 20 12 20zM1.913 12.496c.475 1.059 3.292 6.621 10.087 6.621s9.612-5.562 10.087-6.621c-.475-1.06-3.292-6.622-10.087-6.622s-9.612 5.562-10.087 6.622zM12 16.469c-2.183 0-3.96-1.784-3.96-3.973 0-2.19 1.777-3.973 3.96-3.973s3.96 1.783 3.96 3.973-1.777 3.973-3.96 3.973zm0-7.063a3.089 3.089 0 00-3.08 3.09 3.089 3.089 0 003.08 3.09 3.089 3.089 0 003.08-3.09A3.089 3.089 0 0012 9.406z"
      fill={color}
      fillRule="nonzero"
    />
  </SvgIcon>
);
