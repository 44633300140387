
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';

import {
  OWNER_PASSWORD_RESET,
  OWNER_PASSWORD_RESET_SUCCESS,
  OWNER_PASSWORD_RESET_FAILURE,
} from '../../actionTypes';


/**
 * Reset owner password
 */
export type ResetOwnerPasswordParams = {
  password: string,
  resetToken: string,
  userID: string,
};

export type ResetOwnerPasswordPayload = ResetOwnerPasswordParams;

export type ResetOwnerPasswordAction = {
  type: typeof OWNER_PASSWORD_RESET,
  payload: ResetOwnerPasswordPayload,
};

export const resetOwnerPassword: (
  ResetOwnerPasswordParams,
) => ResetOwnerPasswordAction = createAction(
  OWNER_PASSWORD_RESET, ({
    password,
    resetToken,
    userID,
  }) => ({
    password,
    resetToken,
    userID,
  }),
);


/**
 * Reest owner success
 */
export type ResetOwnerPasswordSuccessAction = {
  type: typeof OWNER_PASSWORD_RESET_SUCCESS,
};

export const resetOwnerPasswordSuccess: () => ResetOwnerPasswordSuccessAction = createAction(
  OWNER_PASSWORD_RESET_SUCCESS,
);


/**
 * Reset owner failure
 */
export type ResetOwnerPasswordFailureAction = {
  type: typeof OWNER_PASSWORD_RESET_FAILURE,
  payload: ErrorParsedResponse,
};

export const resetOwnerPasswordFailure: (
  ErrorParsedResponse,
) => ResetOwnerPasswordFailureAction = createAction(
  OWNER_PASSWORD_RESET_FAILURE,
);
