import React from "react";
import styled from "styled-components";
import { background, border, color, compose, flexbox, grid, layout, position, shadow, space, typography } from "styled-system";
const styleProps = compose(background, border, color, flexbox, grid, layout, position, shadow, space, typography);
type Props = {
  component: "h4" | "h5" | "h6" | "hr" | "li" | "nav" | "ol" | "ul" | "section" | "svg";
};

const Element = (props: Props) => {
  const {
    component: Component = 'div',
    ...others
  } = props;
  return <Component {...others} />;
};

// Don't forward style props to root component, doing so will cause a console error
const StyledElement = styled(({
  bg,
  alignItems: ai,
  flexDirection: fd,
  flexWrap: fw,
  ...others
}) => <Element {...others} />)`
  ${styleProps}
`;
export { StyledElement as Element };
export default StyledElement;