import {
  handleActions,
  combineActions,
} from 'redux-actions';

import {
  AUTHENTICATION_CODE_POST,
  AUTHENTICATION_CODE_POST_FAILURE,
  AUTHENTICATION_CODE_POST_SUCCESS,
  AUTHENTICATION_PASSWORD_POST,
  AUTHENTICATION_PASSWORD_POST_FAILURE,
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
  AUTHENTICATION_SESSION_GET,
  AUTHENTICATION_SESSION_GET_FAILURE,
  AUTHENTICATION_SESSION_GET_SUCCESS,
  AUTHENTICATION_SESSION_POST,
  AUTHENTICATION_SESSION_POST_FAILURE,
  AUTHENTICATION_SESSION_POST_SUCCESS,
  AUTHENTICATION_UPGRADE_POST,
  AUTHENTICATION_UPGRADE_POST_FAILURE,
  AUTHENTICATION_UPGRADE_POST_SUCCESS,
  AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE,
  AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS,
  AUTHENTICATION_VERIFY_POST,
  AUTHENTICATION_VERIFY_POST_FAILURE,
  AUTHENTICATION_VERIFY_POST_SUCCESS,
  BOOKING_CANCEL_SCHEDULE,
  BOOKING_CANCEL_SCHEDULE_FAILURE,
  BOOKING_CANCEL_SCHEDULE_SUCCESS,
  BOOKING_EDIT_SCHEDULE,
  BOOKING_EDIT_SCHEDULE_FAILURE,
  BOOKING_EDIT_SCHEDULE_SUCCESS,
  BOOKING_MEET_GREET,
  BOOKING_MEET_GREET_FAILURE,
  BOOKING_MEET_GREET_SUCCESS,
  BOOKING_OVERNIGHT,
  BOOKING_OVERNIGHT_FAILURE,
  BOOKING_OVERNIGHT_SUCCESS,
  BOOKING_SET_SCHEDULE,
  BOOKING_SET_SCHEDULE_FAILURE,
  BOOKING_SET_SCHEDULE_SUCCESS,
  CREDIT_CARD_UPDATE,
  CREDIT_CARD_UPDATE_FAILURE,
  CREDIT_CARD_UPDATE_SUCCESS,
  DOGS_GET,
  DOGS_GET_FAILURE,
  DOGS_GET_SUCCESS,
  DOG_BREEDS_GET,
  DOG_BREEDS_GET_FAILURE,
  DOG_BREEDS_GET_SUCCESS,
  DOG_BREED_ASSETS_GET,
  DOG_BREED_ASSETS_GET_FAILURE,
  DOG_BREED_ASSETS_GET_SUCCESS,
  DOG_CREATE,
  DOG_CREATE_FAILURE,
  DOG_CREATE_SUCCESS,
  DOG_DELETE,
  DOG_DELETE_FAILURE,
  DOG_DELETE_SUCCESS,
  DOG_PROFILE_PATCH,
  DOG_PROFILE_PATCH_FAILURE,
  DOG_PROFILE_PATCH_SUCCESS,
  DOG_UPDATE_QUESTIONAIRE,
  DOG_UPDATE_QUESTIONAIRE_FAILURE,
  DOG_UPDATE_QUESTIONAIRE_SUCCESS,
  DOG_UPDATE_V4,
  DOG_UPDATE_V4_FAILURE,
  DOG_UPDATE_V4_SUCCESS,
  ESTIMATE_OVERNIGHT,
  ESTIMATE_OVERNIGHT_FAILURE,
  ESTIMATE_OVERNIGHT_SUCCESS,
  LOGIN_WITH_CODE_REQUEST_CREATE,
  LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE,
  LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS,
  LOGIN_WITH_CODE_SUBMIT,
  LOGIN_WITH_CODE_SUBMIT_FAILURE,
  LOGIN_WITH_CODE_SUBMIT_SUCCESS,
  LOGOUT,
  MY_PROFILE_UPDATE,
  MY_PROFILE_UPDATE_FAILURE,
  MY_PROFILE_UPDATE_SUCCESS,
  OWNER_ADD_PREFERRED_WALKER as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE,
  OWNER_ADD_PREFERRED_WALKER_FAILURE as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_FAILURE,
  OWNER_ADD_PREFERRED_WALKER_SUCCESS as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_SUCCESS,
  OWNER_APPLY_DETAILS_REGISTER,
  OWNER_APPLY_DETAILS_REGISTER_FAILURE,
  OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
  OWNER_AUTH_REFRESH_GET,
  OWNER_AUTH_REFRESH_GET_FAILURE,
  OWNER_AUTH_REFRESH_GET_SUCCESS,
  OWNER_AVAILABLE_SERVICES,
  OWNER_AVAILABLE_SERVICES_FAILURE,
  OWNER_AVAILABLE_SERVICES_SUCCESS,
  OWNER_CREDIT_GET,
  OWNER_CREDIT_GET_FAILURE,
  OWNER_CREDIT_GET_SUCCESS,
  OWNER_GET,
  OWNER_GET_FAILURE,
  OWNER_GET_SUCCESS,
  OWNER_GOOGLE_SIGNIN_REGISTER,
  OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  OWNER_HOME_ACCESS_GET,
  OWNER_HOME_ACCESS_GET_FAILURE,
  OWNER_HOME_ACCESS_GET_SUCCESS,
  OWNER_HOME_ACCESS_UPDATE,
  OWNER_HOME_ACCESS_UPDATE_FAILURE,
  OWNER_HOME_ACCESS_UPDATE_SUCCESS,
  OWNER_LOGIN,
  OWNER_LOGIN_FAILURE,
  OWNER_LOGIN_SUCCESS,
  OWNER_MASKED_INFO_GET,
  OWNER_MASKED_INFO_GET_FAILURE,
  OWNER_MASKED_INFO_GET_SUCCESS,
  OWNER_PASSWORD_CHANGE,
  OWNER_PASSWORD_CHANGE_FAILURE,
  OWNER_PASSWORD_CHANGE_SUCCESS,
  OWNER_PASSWORD_RESET,
  OWNER_PASSWORD_RESET_EMAIL_SEND,
  OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
  OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
  OWNER_PASSWORD_RESET_FAILURE,
  OWNER_PASSWORD_RESET_SUCCESS,
  OWNER_PAYMENT_METHODS_GET,
  OWNER_PAYMENT_METHODS_GET_FAILURE,
  OWNER_PAYMENT_METHODS_GET_SUCCESS,
  OWNER_PAYMENT_METHOD_CREATE,
  OWNER_PAYMENT_METHOD_CREATE_FAILURE,
  OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
  OWNER_PAYMENT_METHOD_UPDATE,
  OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
  OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
  OWNER_PREFERRED_WALKER_CREATE,
  OWNER_PREFERRED_WALKER_CREATE_FAILURE,
  OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
  OWNER_PREFERRED_WALKER_DELETE,
  OWNER_PREFERRED_WALKER_DELETE_FAILURE,
  OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
  OWNER_PREMIUM_SUBSCRIPTION_GET,
  OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
  OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
  OWNER_REGISTRATION_ADDRESS,
  OWNER_REGISTRATION_ADDRESS_FAILURE,
  OWNER_REGISTRATION_ADDRESS_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO,
  OWNER_REGISTRATION_BASIC_INFO_FAILURE,
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_V2,
  OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  OWNER_REGISTRATION_PREFERRED_SERVICES,
  OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE,
  OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS,
  OWNER_REGISTRATION_REQUEST_LOCKBOX,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
  OWNER_SERVICES_GET,
  OWNER_SERVICES_GET_FAILURE,
  OWNER_SERVICES_GET_SUCCESS,
  OWNER_SMART_MODULES,
  OWNER_SMART_MODULES_FAILURE,
  OWNER_SMART_MODULES_SUCCESS,
  OWNER_UPDATE,
  OWNER_UPDATE_FAILURE,
  OWNER_UPDATE_SUCCESS,
  PASSWORD_TIPS,
  PASSWORD_TIPS_FAILURE,
  PASSWORD_TIPS_SUCCESS,
  PAST_PET_CAREGIVERS_GET,
  PAST_PET_CAREGIVERS_GET_SUCCESS,
  PAST_PET_CAREGIVERS_GET_FAILURE,
  PREFERRED_WALKER_UPDATE,
  PREFERRED_WALKER_UPDATE_FAILURE,
  PREFERRED_WALKER_UPDATE_SUCCESS,
  PROMO_CODE_APPLY,
  PROMO_CODE_APPLY_FAILURE,
  PROMO_CODE_APPLY_SUCCESS,
  REBOOKING,
  REBOOKING_FAILURE,
  REBOOKING_SUCCESS,
  RESET_REQUEST,
  SERVICEABLE_ZIP_CODE_CHECK,
  SERVICEABLE_ZIP_CODE_CHECK_FAILURE,
  SERVICEABLE_ZIP_CODE_CHECK_SUCCESS,
  SERVICE_PROVIDERS_GET,
  SERVICE_PROVIDERS_GET_FAILURE,
  SERVICE_PROVIDERS_GET_SUCCESS,
  SITTING_QUESTIONNAIRE_GET,
  SITTING_QUESTIONNAIRE_GET_FAILURE,
  SITTING_QUESTIONNAIRE_GET_SUCCESS,
  SUBSCRIBE_TO_PREMIUM,
  SUBSCRIBE_TO_PREMIUM_FAILURE,
  SUBSCRIBE_TO_PREMIUM_SUCCESS,
  TRAINING_BOOKING,
  TRAINING_BOOKING_FAILURE,
  TRAINING_BOOKING_SUCCESS,
  UPDATE_WALKER_PASSWORD,
  UPDATE_WALKER_PASSWORD_FAILURE,
  UPDATE_WALKER_PASSWORD_SUCCESS,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD_SUCCESS,
  VISITOR_ACCOUNT_CREATE,
  VISITOR_ACCOUNT_CREATE_FAILURE,
  VISITOR_ACCOUNT_CREATE_SUCCESS,
  WALKER_CONTACT_INFO_FAILURE,
  WALKER_CONTACT_INFO_GET,
  WALKER_CONTACT_INFO_SUCCESS,
  WALKER_GET,
  WALKER_GET_FAILURE,
  WALKER_GET_SUCCESS,
  WALKER_PASSWORD_RESET,
  WALKER_PASSWORD_RESET_FAILURE,
  WALKER_PASSWORD_RESET_SUCCESS,
  WALKER_PROFILE_BY_PROMO_CODE_GET,
  WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
  WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
  WALKER_PROFILE_GET,
  WALKER_PROFILE_GET_FAILURE,
  WALKER_PROFILE_GET_SUCCESS,
  WALKER_SCHEDULE_GET,
  WALKER_SCHEDULE_GET_FAILURE,
  WALKER_SCHEDULE_GET_SUCCESS,
  WALKER_SIGN_IN,
  WALKER_SIGN_IN_FAILURE,
  WALKER_SIGN_IN_SUCCESS,
  WALKER_SIGN_UP,
  WALKER_SIGN_UP_FAILURE,
  WALKER_SIGN_UP_SUCCESS,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
  WALKS_IN_PENDING_REVIEW_GET,
  WALKS_IN_PENDING_REVIEW_GET_FAILURE,
  WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
  WALKS_IN_PROGRESS_GET,
  WALKS_IN_PROGRESS_GET_FAILURE,
  WALKS_IN_PROGRESS_GET_SUCCESS,
  WALKS_IN_THE_PAST_GET,
  WALKS_IN_THE_PAST_GET_FAILURE,
  WALKS_IN_THE_PAST_GET_SUCCESS,
  WALKS_UPCOMING_GET,
  WALKS_UPCOMING_GET_FAILURE,
  WALKS_UPCOMING_GET_SUCCESS,
  WALK_COVER_CREATE,
  WALK_COVER_CREATE_FAILURE,
  WALK_COVER_CREATE_SUCCESS,
  WALK_DETAILS_GET,
  WALK_DETAILS_GET_FAILURE,
  WALK_DETAILS_GET_SUCCESS,
  WALK_SCHEDULE_UPDATE,
  WALK_SCHEDULE_UPDATE_FAILURE,
  WALK_SCHEDULE_UPDATE_SUCCESS,
} from '../../actions/actionTypes';
import {
  AUTHENTICATION_CODE_POST_REQUEST_KEY,
  AUTHENTICATION_PASSWORD_POST_REQUEST_KEY,
  AUTHENTICATION_SESSION_GET_REQUEST_KEY,
  AUTHENTICATION_SESSION_POST_REQUEST_KEY,
  AUTHENTICATION_UPGRADE_POST_REQUEST_KEY,
  AUTHENTICATION_VERIFY_POST_REQUEST_KEY,
  BOOKING_CANCEL_SCHEDULE_REQUEST_KEY,
  BOOKING_EDIT_SCHEDULE_REQUEST_KEY,
  BOOKING_MEET_GREET_REQUEST_KEY,
  BOOKING_OVERNIGHT_REQUEST_KEY,
  BOOKING_SET_SCHEDULE_REQUEST_KEY,
  CREDIT_CARD_UPDATE_REQUEST_KEY,
  CREDIT_PACKAGES_REQUEST_KEY,
  DOG_BREED_ASSETS_REQUEST_KEY,
  DOG_CREATE_REQUEST_KEY,
  DOG_DELETE_REQUEST_KEY,
  DOG_GET_BREEDS_REQUEST_KEY,
  DOG_PROFILE_PATCH_REQUEST_KEY,
  DOG_PROFILE_REQUEST_KEY,
  DOG_UPDATE_QUESTIONAIRE_REQUEST_KEY,
  DOG_UPDATE_V4_REQUEST_KEY,
  ESTIMATE_OVERNIGHT_REQUEST_KEY,
  GET_DOGS_REQUEST_KEY,
  LOCKBOX_REQUEST_REQUEST_KEY,
  LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY,
  LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY,
  MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY,
  OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY,
  OWNER_AUTH_REFRESH_REQUEST_KEY,
  OWNER_AVAILABLE_SERVICES_REQUEST_KEY,
  OWNER_CREDIT_EXPIRATION_REQUEST_KEY,
  OWNER_CREDIT_REQUEST_KEY,
  OWNER_GET_REQUEST_KEY,
  OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY,
  OWNER_HOME_ACCESS_GET_REQUEST_KEY,
  OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY,
  OWNER_MASKED_INFO_GET_REQUEST_KEY,
  OWNER_PASSWORD_CHANGE_REQUEST_KEY,
  OWNER_PASSWORD_RESET_REQUEST_KEY,
  OWNER_PAYMENT_METHODS_GET_REQUEST_KEY,
  OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY,
  OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY,
  OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY,
  OWNER_PROFILE_REQUEST_KEY,
  OWNER_REGISTRATION_ADDRESS_REQUEST_KEY,
  OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY,
  OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY,
  OWNER_SERVICES_GET_REQUEST_KEY,
  OWNER_SMART_MODULES_REQUEST_KEY,
  OWNER_TOKEN_JWT_REQUEST_KEY,
  OWNER_TOKEN_REQUEST_KEY,
  OWNER_UPDATE_REQUEST_KEY,
  PASSWORD_RESET_EMAIL_REQUEST_KEY,
  PASSWORD_RESET_REQUEST_KEY,
  PAST_PET_CAREGIVERS_GET_REQUEST_KEY,
  PREFERRED_WALKER_REQUEST_KEY,
  PROMO_CODE_REQUEST_KEY,
  REBOOKING_REQUEST_KEY,
  REGISTER_REQUEST_KEY,
  REGISTRATION_REQUEST_KEY,
  SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY,
  SERVICE_PROVIDERS_GET_REQUEST_KEY,
  SITTING_QUESTIONNAIRE_REQUEST_KEY,
  SUBSCRIBE_TO_PREMIUM_REQUEST_KEY,
  TRAINING_BOOKING_REQUEST_KEY,
  VISITOR_ACCOUNT_CREATE_REQUEST_KEY,
  WALKER_CONTACT_INFO_REQUEST_KEY,
  WALKER_GET_REQUEST_KEY,
  WALKER_PASSWORD_RESET_REQUEST_KEY,
  WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY,
  WALKER_PROFILE_REQUEST_KEY,
  WALKER_SIGN_IN_REQUEST_KEY,
  WALKER_SIGN_UP_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY,
  WALKER_WALK_GET_REQUEST_KEY,
  WALKS_IN_PAST_REQUEST_KEY,
  WALKS_IN_PROGRESS_REQUEST_KEY,
  WALKS_PENDING_REVIEW_REQUEST_KEY,
  WALKS_REQUEST_KEY,
  WALKS_UPCOMING_REQUEST_KEY,
  WALK_COVER_CREATE_REQUEST_KEY,
  WALK_DETAILS_GET_REQUEST_KEY,
  WALK_RATE_AND_TIP_REQUEST_KEY,
  WALK_SCHEDULE_UPDATE_REQUEST_KEY,
  WALK_TYPES_REQUEST_KEY,
} from '../../constants/requestKeys';
import getErrorMessageForStatus from '../../helpers/Http/getErrorMessageForStatus';
import getRequestKeyForAction from '../../utils/getRequestKeyForAction';

export type RequestsState = {};

const createDefaultRequest = (overrides: Record<string, any> = {}) => ({
  isFetching: false,
  isFetched: false,
  error: false,
  errorCode: '',
  errorMessage: '',
  ...overrides,
});

export const initialState = {
  [AUTHENTICATION_CODE_POST_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_PASSWORD_POST_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_SESSION_GET_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_SESSION_GET_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_SESSION_POST_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_UPGRADE_POST_REQUEST_KEY]: createDefaultRequest(),
  [AUTHENTICATION_VERIFY_POST_REQUEST_KEY]: createDefaultRequest(),
  [BOOKING_CANCEL_SCHEDULE_REQUEST_KEY]: createDefaultRequest(),
  [BOOKING_EDIT_SCHEDULE_REQUEST_KEY]: createDefaultRequest(),
  [BOOKING_MEET_GREET_REQUEST_KEY]: createDefaultRequest(),
  [BOOKING_OVERNIGHT_REQUEST_KEY]: createDefaultRequest(),
  [BOOKING_SET_SCHEDULE_REQUEST_KEY]: createDefaultRequest(),
  [CREDIT_CARD_UPDATE_REQUEST_KEY]: createDefaultRequest(),
  [CREDIT_PACKAGES_REQUEST_KEY]: createDefaultRequest(),
  [DOG_BREED_ASSETS_REQUEST_KEY]: createDefaultRequest(),
  [DOG_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [DOG_DELETE_REQUEST_KEY]: createDefaultRequest(),
  [DOG_GET_BREEDS_REQUEST_KEY]: createDefaultRequest(),
  [DOG_PROFILE_PATCH_REQUEST_KEY]: createDefaultRequest(),
  [DOG_PROFILE_REQUEST_KEY]: createDefaultRequest(),
  [DOG_UPDATE_QUESTIONAIRE_REQUEST_KEY]: createDefaultRequest(),
  [DOG_UPDATE_V4_REQUEST_KEY]: createDefaultRequest(),
  [ESTIMATE_OVERNIGHT_REQUEST_KEY]: createDefaultRequest(),
  [GET_DOGS_REQUEST_KEY]: createDefaultRequest(),
  [LOCKBOX_REQUEST_REQUEST_KEY]: createDefaultRequest(),
  [LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY]: createDefaultRequest(),
  [MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_AUTH_REFRESH_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_AVAILABLE_SERVICES_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_CREDIT_EXPIRATION_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_CREDIT_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_HOME_ACCESS_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_MASKED_INFO_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PASSWORD_CHANGE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PASSWORD_RESET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PAYMENT_METHODS_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_PROFILE_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_REGISTRATION_ADDRESS_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_SERVICES_GET_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_SMART_MODULES_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_TOKEN_JWT_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_TOKEN_REQUEST_KEY]: createDefaultRequest(),
  [OWNER_UPDATE_REQUEST_KEY]: createDefaultRequest(),
  [PASSWORD_RESET_EMAIL_REQUEST_KEY]: createDefaultRequest(),
  [PASSWORD_RESET_REQUEST_KEY]: createDefaultRequest(),
  [PAST_PET_CAREGIVERS_GET_REQUEST_KEY]: createDefaultRequest(),
  [PREFERRED_WALKER_REQUEST_KEY]: createDefaultRequest(),
  [PROMO_CODE_REQUEST_KEY]: createDefaultRequest(),
  [REBOOKING_REQUEST_KEY]: createDefaultRequest(),
  [REGISTER_REQUEST_KEY]: createDefaultRequest(),
  [REGISTRATION_REQUEST_KEY]: createDefaultRequest(),
  [SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY]: createDefaultRequest(),
  [SERVICE_PROVIDERS_GET_REQUEST_KEY]: createDefaultRequest(),
  [SITTING_QUESTIONNAIRE_REQUEST_KEY]: createDefaultRequest(),
  [SUBSCRIBE_TO_PREMIUM_REQUEST_KEY]: createDefaultRequest(),
  [TRAINING_BOOKING_REQUEST_KEY]: createDefaultRequest(),
  [VISITOR_ACCOUNT_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_CONTACT_INFO_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_GET_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_PASSWORD_RESET_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_PROFILE_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_SIGN_IN_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_SIGN_UP_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY]: createDefaultRequest(),
  [WALKER_WALK_GET_REQUEST_KEY]: createDefaultRequest(),
  [WALKS_IN_PAST_REQUEST_KEY]: createDefaultRequest(),
  [WALKS_IN_PROGRESS_REQUEST_KEY]: createDefaultRequest(),
  [WALKS_PENDING_REVIEW_REQUEST_KEY]: createDefaultRequest(),
  [WALKS_REQUEST_KEY]: createDefaultRequest(),
  [WALKS_UPCOMING_REQUEST_KEY]: createDefaultRequest(),
  [WALK_COVER_CREATE_REQUEST_KEY]: createDefaultRequest(),
  [WALK_DETAILS_GET_REQUEST_KEY]: createDefaultRequest(),
  [WALK_RATE_AND_TIP_REQUEST_KEY]: createDefaultRequest(),
  [WALK_SCHEDULE_UPDATE_REQUEST_KEY]: createDefaultRequest(),
  [WALK_TYPES_REQUEST_KEY]: createDefaultRequest(),
};
export const reducer: any = handleActions(
  {
    [RESET_REQUEST]: (state, { payload }) => ({
      ...state,
      [payload.requestKey]: createDefaultRequest(),
    }),
    // Requests
    [combineActions(
      AUTHENTICATION_CODE_POST,
      AUTHENTICATION_PASSWORD_POST,
      AUTHENTICATION_SESSION_GET,
      AUTHENTICATION_SESSION_GET,
      AUTHENTICATION_SESSION_POST,
      AUTHENTICATION_UPGRADE_POST,
      AUTHENTICATION_VERIFY_POST,
      BOOKING_CANCEL_SCHEDULE,
      BOOKING_EDIT_SCHEDULE,
      BOOKING_MEET_GREET,
      BOOKING_OVERNIGHT,
      BOOKING_SET_SCHEDULE,
      CREDIT_CARD_UPDATE,
      DOGS_GET,
      DOG_BREEDS_GET,
      DOG_BREED_ASSETS_GET,
      DOG_CREATE,
      DOG_DELETE,
      DOG_PROFILE_PATCH,
      DOG_UPDATE_QUESTIONAIRE,
      DOG_UPDATE_V4,
      ESTIMATE_OVERNIGHT,
      LOGIN_WITH_CODE_REQUEST_CREATE,
      LOGIN_WITH_CODE_SUBMIT,
      MY_PROFILE_UPDATE,
      OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE,
      OWNER_APPLY_DETAILS_REGISTER,
      OWNER_AUTH_REFRESH_GET,
      OWNER_AVAILABLE_SERVICES,
      OWNER_CREDIT_GET,
      OWNER_GET,
      OWNER_GOOGLE_SIGNIN_REGISTER,
      OWNER_HOME_ACCESS_GET,
      OWNER_HOME_ACCESS_UPDATE,
      OWNER_LOGIN,
      OWNER_MASKED_INFO_GET,
      OWNER_PASSWORD_CHANGE,
      OWNER_PASSWORD_RESET,
      OWNER_PASSWORD_RESET_EMAIL_SEND,
      OWNER_PAYMENT_METHODS_GET,
      OWNER_PAYMENT_METHOD_CREATE,
      OWNER_PAYMENT_METHOD_UPDATE,
      OWNER_PREFERRED_WALKER_CREATE,
      OWNER_PREFERRED_WALKER_DELETE,
      OWNER_PREMIUM_SUBSCRIPTION_GET,
      OWNER_REGISTRATION_ADDRESS,
      OWNER_REGISTRATION_BASIC_INFO,
      OWNER_REGISTRATION_BASIC_INFO_V2,
      OWNER_REGISTRATION_PREFERRED_SERVICES,
      OWNER_REGISTRATION_REQUEST_LOCKBOX,
      OWNER_SERVICES_GET,
      OWNER_SMART_MODULES,
      OWNER_UPDATE,
      PASSWORD_TIPS,
      PAST_PET_CAREGIVERS_GET,
      PREFERRED_WALKER_UPDATE,
      PROMO_CODE_APPLY,
      REBOOKING,
      SERVICEABLE_ZIP_CODE_CHECK,
      SERVICE_PROVIDERS_GET,
      SITTING_QUESTIONNAIRE_GET,
      SUBSCRIBE_TO_PREMIUM,
      TRAINING_BOOKING,
      UPDATE_WALKER_PASSWORD,
      VERIFY_PASSWORD,
      VISITOR_ACCOUNT_CREATE,
      WALKER_CONTACT_INFO_GET,
      WALKER_GET,
      WALKER_PASSWORD_RESET,
      WALKER_PROFILE_BY_PROMO_CODE_GET,
      WALKER_PROFILE_GET,
      WALKER_SCHEDULE_GET,
      WALKER_SIGN_IN,
      WALKER_SIGN_UP,
      WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
      WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL,
      WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
      WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
      WALKS_IN_PENDING_REVIEW_GET,
      WALKS_IN_PROGRESS_GET,
      WALKS_IN_THE_PAST_GET,
      WALKS_UPCOMING_GET,
      WALK_COVER_CREATE,
      WALK_DETAILS_GET,
      WALK_SCHEDULE_UPDATE,
    )]: (state, { type }) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: false,
        errorCode: undefined,
        errorMessage: undefined,
        errorMessages: {},
        isFetched: false,
        isFetching: true,
      },
    }),
    // Successes
    [combineActions(
      AUTHENTICATION_CODE_POST_SUCCESS,
      AUTHENTICATION_PASSWORD_POST_SUCCESS,
      AUTHENTICATION_SESSION_GET_SUCCESS,
      AUTHENTICATION_SESSION_GET_SUCCESS,
      AUTHENTICATION_SESSION_POST_SUCCESS,
      AUTHENTICATION_UPGRADE_POST_SUCCESS,
      AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS,
      AUTHENTICATION_VERIFY_POST_SUCCESS,
      BOOKING_CANCEL_SCHEDULE_SUCCESS,
      BOOKING_EDIT_SCHEDULE_SUCCESS,
      BOOKING_MEET_GREET_SUCCESS,
      BOOKING_OVERNIGHT_SUCCESS,
      BOOKING_SET_SCHEDULE_SUCCESS,
      DOGS_GET_SUCCESS,
      DOG_BREEDS_GET_SUCCESS,
      DOG_BREED_ASSETS_GET_SUCCESS,
      DOG_CREATE_SUCCESS,
      DOG_DELETE_SUCCESS,
      DOG_PROFILE_PATCH_SUCCESS,
      DOG_UPDATE_QUESTIONAIRE_SUCCESS,
      DOG_UPDATE_V4_SUCCESS,
      ESTIMATE_OVERNIGHT_SUCCESS,
      LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS,
      LOGIN_WITH_CODE_SUBMIT_SUCCESS,
      MY_PROFILE_UPDATE_SUCCESS,
      OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_SUCCESS,
      OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
      OWNER_AUTH_REFRESH_GET_SUCCESS,
      OWNER_AVAILABLE_SERVICES_SUCCESS,
      OWNER_CREDIT_GET_SUCCESS,
      OWNER_GET_SUCCESS,
      OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
      OWNER_HOME_ACCESS_GET_SUCCESS,
      OWNER_HOME_ACCESS_UPDATE_SUCCESS,
      OWNER_LOGIN_SUCCESS,
      OWNER_MASKED_INFO_GET_SUCCESS,
      OWNER_PASSWORD_CHANGE_SUCCESS,
      OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
      OWNER_PASSWORD_RESET_SUCCESS,
      OWNER_PAYMENT_METHODS_GET_SUCCESS,
      OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
      OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
      OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
      OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
      OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
      OWNER_REGISTRATION_ADDRESS_SUCCESS,
      OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
      OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
      OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS,
      OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
      OWNER_SERVICES_GET_SUCCESS,
      OWNER_SMART_MODULES_SUCCESS,
      OWNER_UPDATE_SUCCESS,
      PASSWORD_TIPS_SUCCESS,
      PAST_PET_CAREGIVERS_GET_SUCCESS,
      PREFERRED_WALKER_UPDATE_SUCCESS,
      PROMO_CODE_APPLY_SUCCESS,
      REBOOKING_SUCCESS,
      SERVICEABLE_ZIP_CODE_CHECK_SUCCESS,
      SERVICE_PROVIDERS_GET_SUCCESS,
      SITTING_QUESTIONNAIRE_GET_SUCCESS,
      SUBSCRIBE_TO_PREMIUM_SUCCESS,
      TRAINING_BOOKING_SUCCESS,
      UPDATE_WALKER_PASSWORD_SUCCESS,
      VERIFY_PASSWORD_SUCCESS,
      VISITOR_ACCOUNT_CREATE_SUCCESS,
      WALKER_CONTACT_INFO_SUCCESS,
      WALKER_GET_SUCCESS,
      WALKER_PASSWORD_RESET_SUCCESS,
      WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
      WALKER_PROFILE_GET_SUCCESS,
      WALKER_SCHEDULE_GET_SUCCESS,
      WALKER_SIGN_IN_SUCCESS,
      WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
      WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
      WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
      WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
      WALKS_IN_PROGRESS_GET_SUCCESS,
      WALKS_IN_THE_PAST_GET_SUCCESS,
      WALKS_UPCOMING_GET_SUCCESS,
      WALK_COVER_CREATE_SUCCESS,
      WALK_DETAILS_GET_SUCCESS,
      WALK_SCHEDULE_UPDATE_SUCCESS,
    )]: (state, { type }) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: false,
        errorCode: undefined,
        errorMessage: undefined,
        errorMessages: {},
        isFetched: true,
        isFetching: false,
      },
    }),
    [CREDIT_CARD_UPDATE_SUCCESS]: (
      state,
      { type, payload: { isCardInvalid } },
    ) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: false,
        errorCode: undefined,
        errorMessage: undefined,
        errorMessages: {},
        isCardInvalid,
        isFetched: true,
        isFetching: false,
      },
    }),
    [WALKER_SIGN_UP_SUCCESS]: (state, { type }) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: false,
        errorCode: undefined,
        errorMessage: undefined,
        errorMessages: {},
        isWalkerSignUpSuccessful: true,
        isFetched: true,
        isFetching: false,
      },
    }),
    [WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS]: (
      state,
      { type, payload: { accountLinkUrl } },
    ) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: false,
        errorCode: undefined,
        errorMessage: undefined,
        errorMessages: {},
        accountLinkUrl,
        isWalkerSignUpSuccessful: true,
        isFetched: true,
        isFetching: false,
      },
    }),
    [CREDIT_CARD_UPDATE_FAILURE]: (state, { type }) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: true,
        errorCode: undefined,
        errorMessage: 'Invalid credit card, please update payment info.',
        errorMessages: {},
        isFetched: true,
        isFetching: false,
      },
    }),
    [TRAINING_BOOKING_FAILURE]: (
      state,
      {
        type,
        payload: {
          response: { detail, status: errorCode },
        },
      },
    ) => ({
      ...state,
      [getRequestKeyForAction(type)]: {
        error: true,
        errorCode,
        errorMessage: detail,
        errorMessages: undefined,
        isFetched: true,
        isFetching: false,
      },
    }),
    [combineActions(
      AUTHENTICATION_CODE_POST_FAILURE,
      AUTHENTICATION_PASSWORD_POST_FAILURE,
      AUTHENTICATION_SESSION_GET_FAILURE,
      AUTHENTICATION_SESSION_GET_FAILURE,
      AUTHENTICATION_SESSION_POST_FAILURE,
      AUTHENTICATION_UPGRADE_POST_FAILURE,
      AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE,
      AUTHENTICATION_VERIFY_POST_FAILURE,
      BOOKING_CANCEL_SCHEDULE_FAILURE,
      BOOKING_EDIT_SCHEDULE_FAILURE,
      BOOKING_MEET_GREET_FAILURE,
      BOOKING_OVERNIGHT_FAILURE,
      BOOKING_SET_SCHEDULE_FAILURE,
      DOGS_GET_FAILURE,
      DOG_BREEDS_GET_FAILURE,
      DOG_BREED_ASSETS_GET_FAILURE,
      DOG_CREATE_FAILURE,
      DOG_DELETE_FAILURE,
      DOG_PROFILE_PATCH_FAILURE,
      DOG_UPDATE_QUESTIONAIRE_FAILURE,
      DOG_UPDATE_V4_FAILURE,
      ESTIMATE_OVERNIGHT_FAILURE,
      LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE,
      LOGIN_WITH_CODE_SUBMIT_FAILURE,
      MY_PROFILE_UPDATE_FAILURE,
      OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_FAILURE,
      OWNER_APPLY_DETAILS_REGISTER_FAILURE,
      OWNER_AUTH_REFRESH_GET_FAILURE,
      OWNER_AVAILABLE_SERVICES_FAILURE,
      OWNER_CREDIT_GET_FAILURE,
      OWNER_GET_FAILURE,
      OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
      OWNER_HOME_ACCESS_GET_FAILURE,
      OWNER_HOME_ACCESS_UPDATE_FAILURE,
      OWNER_LOGIN_FAILURE,
      OWNER_MASKED_INFO_GET_FAILURE,
      OWNER_PASSWORD_CHANGE_FAILURE,
      OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
      OWNER_PASSWORD_RESET_FAILURE,
      OWNER_PAYMENT_METHODS_GET_FAILURE,
      OWNER_PAYMENT_METHOD_CREATE_FAILURE,
      OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
      OWNER_PREFERRED_WALKER_CREATE_FAILURE,
      OWNER_PREFERRED_WALKER_DELETE_FAILURE,
      OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
      OWNER_REGISTRATION_ADDRESS_FAILURE,
      OWNER_REGISTRATION_BASIC_INFO_FAILURE,
      OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
      OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE,
      OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
      OWNER_SERVICES_GET_FAILURE,
      OWNER_SMART_MODULES_FAILURE,
      OWNER_UPDATE_FAILURE,
      PASSWORD_TIPS_FAILURE,
      PAST_PET_CAREGIVERS_GET_FAILURE,
      PREFERRED_WALKER_UPDATE_FAILURE,
      PROMO_CODE_APPLY_FAILURE,
      REBOOKING_FAILURE,
      SERVICEABLE_ZIP_CODE_CHECK_FAILURE,
      SERVICE_PROVIDERS_GET_FAILURE,
      SITTING_QUESTIONNAIRE_GET_FAILURE,
      SUBSCRIBE_TO_PREMIUM_FAILURE,
      UPDATE_WALKER_PASSWORD_FAILURE,
      VERIFY_PASSWORD_FAILURE,
      VISITOR_ACCOUNT_CREATE_FAILURE,
      WALKER_CONTACT_INFO_FAILURE,
      WALKER_GET_FAILURE,
      WALKER_PASSWORD_RESET_FAILURE,
      WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
      WALKER_PROFILE_GET_FAILURE,
      WALKER_SCHEDULE_GET_FAILURE,
      WALKER_SIGN_IN_FAILURE,
      WALKER_SIGN_UP_FAILURE,
      WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
      WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE,
      WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
      WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
      WALKS_IN_PENDING_REVIEW_GET_FAILURE,
      WALKS_IN_PROGRESS_GET_FAILURE,
      WALKS_IN_THE_PAST_GET_FAILURE,
      WALKS_UPCOMING_GET_FAILURE,
      WALK_COVER_CREATE_FAILURE,
      WALK_DETAILS_GET_FAILURE,
      WALK_SCHEDULE_UPDATE_FAILURE,
    )]: (state, { payload, type }) => {
      const errorCode = payload.errorCode || payload.code;
      return {
        ...state,
        [getRequestKeyForAction(type)]: {
          error: true,
          errorCode,
          errorMessage: getErrorMessageForStatus(errorCode, payload.error),
          errorMessages: payload.errorMessages,
          isFetched: true,
          isFetching: false,
        },
      };
    },
    [LOGOUT]: () => initialState,
  },
  initialState,
);
export default reducer;
