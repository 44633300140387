import { forkJoin } from "rxjs";
import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { parseQueryString } from "../../../utils/parseQueryString";
import { DOGS_GET_SUCCESS } from "../../../actions/actionTypes";
import { ENDPOINT_DOG_QUESTIONAIRE } from "../../../constants/endpoints";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { getDogQuestionaireFailure, getDogQuestionaireSuccess } from "../../../actions/dog/getDogQuestionaire/getDogQuestionaire.actions";

const parseDogHomeQuestionaire = response => {
  try {
    const {
      questions
    } = response;
    const returnResponse = questions.reduce((acc, question) => {
      // The question object doesn't necessary have a value each time
      if (!Object.prototype.hasOwnProperty.call(question, 'value')) {
        return acc;
      }

      return { ...acc,
        [question.identifier]: question.value
      };
    }, {});
    return { ...returnResponse,
      type: 'home',
      dogID: response.dogID
    };
  } catch (e) {
    return {};
  }
};

const getDogHomeQuestionaire$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(DOGS_GET_SUCCESS), switchMap(() => {
  const promises = state$.value.dogs.dogs.map(dog => get$({
    endpoint: ENDPOINT_DOG_QUESTIONAIRE,
    payload: {
      id: getOwnerID(state$.value),
      dog_id: dog.dogID,
      questionnaire: 'home'
    },
    token: getOwnerToken(state$.value)
  }));
  return forkJoin(promises).pipe(mergeMap(responses => responses.map(response => {
    const {
      request: {
        url
      }
    } = response;
    const {
      dog_id: dogID
    } = parseQueryString(url);
    return handleResponse({
      response,
      onSuccess: getDogQuestionaireSuccess,
      onFailure: getDogQuestionaireFailure,
      parser: parseDogHomeQuestionaire,
      isArray: false,
      additionalData: {
        dogID
      }
    });
  })), catchError(handleErrorV5Response(action$, getDogQuestionaireFailure)));
}));

const parseDogWalkQuestionaire = response => {
  try {
    const {
      questions
    } = response;
    const returnResponse = questions.reduce((acc, question) => {
      // The question object doesn't necessary have a value each time
      if (!Object.prototype.hasOwnProperty.call(question, 'value')) {
        return acc;
      }

      return { ...acc,
        [question.identifier]: question.value
      };
    }, {});
    return { ...returnResponse,
      type: 'walk',
      dogID: response.dogID
    };
  } catch (e) {
    return {};
  }
};

const getDogWalkQuestionaire$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(DOGS_GET_SUCCESS), switchMap(() => {
  const promises = state$.value.dogs.dogs.map(dog => get$({
    endpoint: ENDPOINT_DOG_QUESTIONAIRE,
    payload: {
      id: getOwnerID(state$.value),
      dog_id: dog.dogID,
      questionnaire: 'walk'
    },
    token: getOwnerToken(state$.value)
  }));
  return forkJoin(promises).pipe(mergeMap(responses => responses.map(response => {
    const {
      request: {
        url
      }
    } = response;
    const {
      dog_id: dogID
    } = parseQueryString(url);
    return handleResponse({
      response,
      onSuccess: getDogQuestionaireSuccess,
      onFailure: getDogQuestionaireFailure,
      parser: parseDogWalkQuestionaire,
      isArray: false,
      additionalData: {
        dogID
      }
    });
  })), catchError(handleErrorV5Response(action$, getDogQuestionaireFailure)));
}));

export { getDogHomeQuestionaire$, getDogWalkQuestionaire$ };