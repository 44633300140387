import { useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { authSlice } from '../slices/auth';


/* 
  Handles interacting and making requests 
  to and parsing responses from
  the Facebook Login SDK. 
*/
export const useFacebookLogin = () => {
  const [submitting, setSubmitting] = useState(false);

  const accessToken = useSelector(authSlice.selectors.getFacebookAccessToken);
  const dispatch = useDispatch();

  const execute = async () => {
    setSubmitting(true);
    return new Promise((resolve, reject) => {
      FB.login((response: fb.StatusResponse) => {
        setSubmitting(false);
        if (response.status !== 'connected') {
          reject(response);
          return;
        }
        resolve(response.authResponse);
      }, { 
        scope: 'public_profile, email',
        return_scopes: true,
      });
    });
  };

  const setAccessToken = (accessToken: string) => {
    // set last auth provider used in state to facebook
    dispatch(authSlice.actions.setFacebookAccessToken(accessToken));
  };

  return {
    execute,
    submitting,
    setAccessToken,
    accessToken,
  };
};
