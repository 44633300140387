import moment from "moment";
import type { GetWalksUpcomingResponse, WalkUpcoming as WalkUpcomingWagApi } from "wagAPI";
import type { GetWalksUpcomingParsedResponse, WalkUpcoming as WalkUpcomingWebApp } from "webApp";
import { parseWalkerProfile } from "./getWalkerProfileResponseParser";
import { toStandardTime } from "../../../utils/scheduleFormatter";
// TODO - Add more support; Currently partial parsing applied
export const parseWalkSitting = ({
  sitting
}: any) => ({
  startDate: sitting.start_date,
  startTime: toStandardTime(sitting.start_time),
  endDate: sitting.end_date,
  endTime: sitting.end_time
});
export const parseWalkUpcoming = (walk: WalkUpcomingWagApi): WalkUpcomingWebApp => {
  const startDateMatcher = (walk.date || '').match(/(\d{4})-(\d\d?)-(\d\d?)/); // year-month-day

  const startDate = startDateMatcher ? `${startDateMatcher[2]}/${startDateMatcher[3]}/${startDateMatcher[1]}` : '';
  const isCancelled = Boolean(Number(walk.is_cancelled));
  const isConfirmed = Boolean(Number(walk.is_confirmed));
  const result: WalkUpcomingWebApp = {
    daysOfTheWeek: [moment(startDate, 'MM/DD/YYYY').format('dddd')],
    dogs: walk.dogs.map(({
      id
    }) => id),
    isCancelled,
    // because this should only be used for endpoints representing noncompleted walks
    isCompleted: false,
    isConfirmed: isConfirmed && !isCancelled,
    isPending: !isConfirmed && !isCancelled,
    isRecurring: Boolean(Number(walk.is_recurring)),
    // Use the same property to get the price for all the walks parser
    total: walk.estimated_price,
    scheduleID: String(walk.schedule_id),
    startDate,
    startTime: toStandardTime(walk.start_time),
    status: walk.state,
    walker: null,
    walkerID: String(walk.walker_id) || '0',
    walkID: String(walk.walk_id),
    walkTypeID: Number(walk.walk_type_id)
  };

  if (walk.walker_id && walk.walker) {
    // TODO - The shape of the walker should be
    // the same with the walker profile.
    // Same component = same shape!
    const walker: any = Object.assign({}, walk.walker, {
      badges: {
        achievement: [],
        compliment: []
      }
    });
    result.walker = parseWalkerProfile(walker);
  }

  if (walk.sitting) {
    result.sitting = parseWalkSitting(walk);
  }

  return result;
};
export const getWalksUpcomingResponseParser = ({
  walks
}: GetWalksUpcomingResponse): GetWalksUpcomingParsedResponse => walks.map((walk: WalkUpcomingWagApi): WalkUpcomingWebApp => parseWalkUpcoming(walk));
export default getWalksUpcomingResponseParser;