// FIXME Do a find and replace on the spelling below ERORRRRR
export const ERROR_MESSAGE_INCORRECT_CODE = 'The code you entered is invalid. Please try again.';
export const ERROR_MESSAGE_INVALID_CODE = 'Please enter a valid code';
export const ERORR_MESSAGE_MAINTENACE = 'We are currently undergoing maintenance, please check back at a later time';
export const ERORR_MESSAGE_PAYMENT_REQUIRED = 'Payment Method Required'; // FIXME: validate if this is correct message

export const ERORR_MESSAGE_RESOURCE_NOT_FOUND = 'Resource not found';
export const ERORR_MESSAGE_SERVER_ERROR = 'Oops, something went wrong';
export const ERORR_MESSAGE_SERVER_NOT_RESPONDING = '';
export const ERORR_MESSAGE_UNAUTHENTICATED = 'You are unauthenticated';
export const ERROR_MESSAGE_UNEXPECTED = 'An unexpected error has occurred. Please try again.';
export const ERROR_MESSAGE_WELLNESS_CHECKOUT_FAILURE = 'Wellness Checkout Failure';
export const ERROR_MESSAGE_VET_CHAT_CHECKOUT_FAILURE = 'Vet Chat Checkout Failure';
export const ERROR_MESSAGE_PREMIUM_CHECKOUT_FAILURE = 'Premium Checkout Failure';

export type StatusMessage =
  typeof ERORR_MESSAGE_SERVER_NOT_RESPONDING
  | typeof ERROR_MESSAGE_INCORRECT_CODE
  | typeof ERROR_MESSAGE_INVALID_CODE
  | typeof ERORR_MESSAGE_MAINTENACE
  | typeof ERORR_MESSAGE_PAYMENT_REQUIRED
  | typeof ERORR_MESSAGE_RESOURCE_NOT_FOUND
  | typeof ERORR_MESSAGE_SERVER_ERROR
  | typeof ERORR_MESSAGE_SERVER_NOT_RESPONDING
  | typeof ERORR_MESSAGE_UNAUTHENTICATED
  | typeof ERROR_MESSAGE_UNEXPECTED;
