import { handleActions } from 'redux-actions';
import type {
  WalkInProgress,
  WalkInThePast,
  WalkReview,
  WalkUpcoming,
} from 'webApp';

import {
  LOGOUT,
  WALK_CONFIRMATION_VIEWED,
  WALK_SCHEDULE_UPDATE_SUCCESS,
  WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
  WALKS_IN_PROGRESS_GET_SUCCESS,
  WALKS_IN_THE_PAST_GET_SUCCESS,
  WALKS_UPCOMING_GET_SUCCESS,
  WALK_RATE_AND_TIP,
  WALK_RATE_AND_TIP_SUCCESS,
  WALK_RATE_AND_TIP_FAILURE,
  WALK_DETAILS_GET_SUCCESS,
} from '../../actions/actionTypes';
import type { GetWalkDetailsSuccessAction } from '../../actions/walk/getWalkDetails';
import type { GetWalksInPendingReviewSuccessAction } from '../../actions/walk/getWalksInPendingReview';
import type { GetWalksInProgressSuccessAction } from '../../actions/walk/getWalksInProgress';
import type { GetWalksInThePastSuccessAction } from '../../actions/walk/getWalksInThePast';
import type { GetWalksUpcomingSuccessAction } from '../../actions/walk/getWalksUpcoming';
import type { RateAndTipWalkAction } from '../../actions/walk/rateAndTipWalk';
import type { UpdateWalkScheduleSuccessAction } from '../../actions/walk/updateWalkSchedule';
import type { ViewWalkConfirmationAction } from '../../actions/walk/walk';
import sortWalksByDate from '../../utils/sortWalksByDate';
import { sortByWalkDate } from '../../utils/sortWalksByDate/sortWalksByDate';

export type State = {
  lastWalkScheduled: {
    days: number[];
    isRecurring: boolean;
    startTime: string;
    startDate: string;
    walkTypeID: number;
  };
  walksInProgress: WalkInProgress[];
  walksInThePast: WalkInThePast[];
  walksPendingConfirmation: WalkUpcoming[];
  walksPendingReview: string[];
  oldWalksPendingReview: string[];
  walkReviews: Partial<{ [key: string]: WalkReview }>;
  walksUpcoming: WalkUpcoming[];
  viewedWalkConfirmationIds: string[];
  walkRateAndTipSuccess: boolean;
};
export const initialState = {
  lastWalkScheduled: {
    days: [],
    isRecurring: false,
    startTime: '',
    startDate: '',
    walkTypeID: 1,
  },
  walkReviews: {},
  walksInProgress: [],
  walksInThePast: [],
  walksPendingConfirmation: [],
  walksPendingReview: [],
  oldWalksPendingReview: [],
  walksUpcoming: [],
  viewedWalkConfirmationIds: [],
  walkRateAndTipSuccess: false,
};
const viewedWalkConfirmationIDArraySize = 200;
type TruncatedWalk = {
  walkID: string;
  startDate: string;
  startTime: string;
};
type CategorizedWalkIDs = Record<string, Record<string, TruncatedWalk>>;

const categorizeUpcomingWalks = (walks: WalkUpcoming[]): CategorizedWalkIDs => (
  walks.reduce((result: CategorizedWalkIDs, walkUpcoming: WalkUpcoming) => ({
    ...result,
    [walkUpcoming.scheduleID]: {
      ...(result[walkUpcoming.scheduleID] ? result[walkUpcoming.scheduleID] : {}),
      [walkUpcoming.walkID]: {
        walkID: walkUpcoming.walkID,
        startTime: walkUpcoming.startTime,
        startDate: walkUpcoming.startDate,
      },
    },
  }), {})
);

const reduceUpcomingWalk = (state: State, upcomingWalks: WalkUpcoming[]) => {
  const categorizedWalks = categorizeUpcomingWalks(
    upcomingWalks.filter((walk) => walk.isConfirmed === true),
  );
  const viewedWalkConfirmationIDs = state.viewedWalkConfirmationIds.reduce((result, walkID) => ({
    ...result,
    [walkID]: true,
  }), {});
  const newWalkNotificationsToSuppress = [];
  Object.entries(categorizedWalks).forEach(([, walks]) => {
    // $FlowFixMe
    Object.values(walks) // $FlowFixMe
      .sort(sortByWalkDate) // flow can't tell the object value will be of correct type
      .slice(1).forEach( // $FlowFixMe flow can't tell the object value will be of correct type
        (truncatedWalk: TruncatedWalk) => {
          if (!viewedWalkConfirmationIDs[truncatedWalk.walkID]) {
            newWalkNotificationsToSuppress.push(truncatedWalk.walkID);
          }
        },
      );
  });
  return {
    ...state,
    walksUpcoming: upcomingWalks.filter((walk) => walk.isConfirmed === true).sort(sortWalksByDate),
    walksPendingConfirmation: upcomingWalks.filter(
      (walk) => walk.isConfirmed === false,
    ).sort(sortWalksByDate),
    viewedWalkConfirmationIds: [
      ...newWalkNotificationsToSuppress,
      ...state.viewedWalkConfirmationIds,
    ].slice(0, viewedWalkConfirmationIDArraySize),
  };
};

export const reducer: any = handleActions({
  [WALKS_IN_THE_PAST_GET_SUCCESS]: (state: State, {
    payload,
  }: GetWalksInThePastSuccessAction): State => ({
    ...state,
    walksInThePast: payload.sort(sortWalksByDate),
  }),
  [WALK_DETAILS_GET_SUCCESS]: (state: State, {
    payload,
  }: GetWalkDetailsSuccessAction): State => {
    const payloadWalk: any[] = [
      ...state.walksUpcoming,
      ...state.viewedWalkConfirmationIds,
      ...state.walksPendingConfirmation,
      payload,
    ];
    return reduceUpcomingWalk(state, payloadWalk);
  },
  [WALKS_UPCOMING_GET_SUCCESS]: (state: State, {
    payload,
  }: GetWalksUpcomingSuccessAction): State => reduceUpcomingWalk(state, payload),
  [WALKS_IN_PROGRESS_GET_SUCCESS]: (state: State, {
    payload: {
      walk,
    },
  }: GetWalksInProgressSuccessAction): State => ({
    ...state,
    walksInProgress: walk ? [walk] : [],
  }),
  [WALKS_IN_PENDING_REVIEW_GET_SUCCESS]: (state: State, {
    payload,
  }: GetWalksInPendingReviewSuccessAction): State => ({
    ...state,
    walksPendingReview: payload,
  }),
  [WALK_SCHEDULE_UPDATE_SUCCESS]: (state: State, {
    payload: {
      id,
      days,
      isRecurring,
      startTime,
      startDate,
      requestTypeID,
      walkTypeID,
    },
  }: UpdateWalkScheduleSuccessAction): State => ({
    ...state,
    lastWalkScheduled: {
      id,
      days,
      isRecurring,
      startTime,
      startDate,
      requestTypeID,
      walkTypeID,
    },
  }),
  [WALK_CONFIRMATION_VIEWED]: (state: State, {
    payload,
  }: ViewWalkConfirmationAction): State => ({
    ...state,
    viewedWalkConfirmationIds: [
      payload,
      ...state.viewedWalkConfirmationIds.slice(0, viewedWalkConfirmationIDArraySize - 1),
    ],
  }),
  [WALK_RATE_AND_TIP]: (state: State, {
    payload: {
      walkID,
    },
  }: RateAndTipWalkAction): State => ({
    ...state,
    walksPendingReview: state.walksPendingReview.filter(
      (pendingWalkID) => pendingWalkID !== walkID,
    ),
    oldWalksPendingReview: state.walksPendingReview,
  }),
  [WALK_RATE_AND_TIP_SUCCESS]: (state: State, {
    payload,
  }): State => ({
    ...state,
    walkRateAndTipSuccess: !payload.tip_payment_failure,
  }),
  [WALK_RATE_AND_TIP_FAILURE]: (state: State): State => ({
    ...state,
    walksPendingReview: state.oldWalksPendingReview,
    oldWalksPendingReview: initialState.walksPendingReview,
  }),
  [LOGOUT]: () => initialState,
}, initialState);
export default reducer;
