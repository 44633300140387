
import { createAction } from 'redux-actions';
import type {
  UpdateMyProfileParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  MY_PROFILE_UPDATE,
  MY_PROFILE_UPDATE_SUCCESS,
  MY_PROFILE_UPDATE_FAILURE,
} from '../../actionTypes';

/**
 * Update my profile
 */
export type UpdateMyProfileParams = {
  apartmentNumber: string;
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  latitude?: string;
  longitude?: string;
  phone: string;
  state: string;
  streetAddress: string;
  zipCode: string;
}

export type UpdateMyProfilePayload = UpdateMyProfileParams;

export type UpdateMyProfileAction = {
  type: typeof MY_PROFILE_UPDATE;
  payload: UpdateMyProfilePayload;
};

export const updateMyProfile: (params: UpdateMyProfileParams) => UpdateMyProfileAction = createAction(
  MY_PROFILE_UPDATE,
);


/**
 * Update my profile success
 */
export type UpdateMyProfileSuccessAction = {
  type: typeof MY_PROFILE_UPDATE_SUCCESS;
  payload: UpdateMyProfileParsedResponse;
};

export const updateMyProfileSuccess: (
  response: UpdateMyProfileParsedResponse,
) => UpdateMyProfileSuccessAction = createAction(
  MY_PROFILE_UPDATE_SUCCESS,
);


/**
 * Update my profile failure
 */
export type UpdateMyProfileFailureAction = {
  type: typeof MY_PROFILE_UPDATE_FAILURE;
  payload: ErrorParsedResponse;
}

export const updateMyProfileFailure: (
  error: ErrorParsedResponse,
) => UpdateMyProfileFailureAction = createAction(
  MY_PROFILE_UPDATE_FAILURE,
);
