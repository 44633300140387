import { defer } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { FEATURE_DEV_SELECTOR_ENABLED } from '../../constants';
import { getOwnerToken } from '../../selectors';
import { configSlice } from '../../slices/config';
import store from '../../store/configureStore';
import objectToQueryString from '../../utils/objectToQueryString';

const defaultDomain = process.env.REACT_APP_WAG_API_URL;

if (!defaultDomain) {
  throw new Error('Api url not specified in .env');
}

type HttpHeaders = {
  'Content-Type': string;
  Authorization?: string;
};

/**
 * get the headers for the requests
 *
 * @param  token - Auth token string
 */
export const getHeaders = (
  token?: string,
  additionalHeaders?: Record<string, any>,
): HttpHeaders => {
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (token) {
    const state = store.getState();
    const ownerToken = getOwnerToken(state);
    headers = {
      ...headers,
      // Authorization: `Bearer ${token}`,
      Authorization: `Bearer ${ownerToken}`,
    };
  }

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }

  return headers;
};
type HttpHeadersLegacy = {
  'Content-Type': string;
  Authorization?: string;
};

/**
 * get the headers for the requests
 *
 * @param  token - Auth token string
 */
export const getHeadersLegacy = (
  token?: string,
  additionalHeaders?: Record<string, any>,
): HttpHeadersLegacy => {
  let headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer: ${token}`, // Wagapi requires ':'
    };
  }

  if (additionalHeaders) {
    headers = { ...headers, ...additionalHeaders };
  }

  return headers;
};

const getDomain = (
  domain: string = defaultDomain,
): string => {
  if (!FEATURE_DEV_SELECTOR_ENABLED) {
    // Do not use the domain from the store if on production mode
    // Dev selector is turned off in production
    return domain;
  }

  const state = store.getState();
  const config = configSlice.selectors.getConfig(state);
  const configDomain = (config.domain && config.domain.value) || domain;
  return configDomain;
};

/**
 * get the GET URL
 *
 * @param  {string} endpoint
 * @param  {object} payload
 * @return {string}
 */
export const getGETURL = (
  domain: string,
  endpoint: string,
  payload: any,
): string => {
  const configDomain = getDomain(domain);
  const uri = `${configDomain}/${endpoint}`;

  if (!payload) {
    return uri;
  }

  return `${uri}?${objectToQueryString(payload)}`;
};
export type GETRequestProps = {
  endpoint: string;
  payload?: any;
  token?: string;
  additionalHeaders?: any;
  domain?: string;
};

/**
 * GET request rxjs fetch observable
 *
 * @param  {GETRequestProps} endpoint
 * @return {Observable}
 */
export const get$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: GETRequestProps) => defer(() => ajax.get(
  getGETURL(domain, endpoint, payload),
  getHeaders(token, additionalHeaders),
));

/**
 * GET request rxjs fetch observable
 *
 * @param  {GETRequestProps} endpoint
 * @return {Observable}
 */
export const getLegacy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: GETRequestProps) => defer(() => ajax.get(
  getGETURL(domain, endpoint, payload),
  getHeadersLegacy(token, additionalHeaders),
));
export type PATCHRequestProps = {
  endpoint: string;
  payload: any;
  token?: string;
  additionalHeaders?: any;
  domain?: string;
};

/**
 * PATCH request rxjs fetch observable
 *
 * @param  {PATCHRequestProps}
 * @return {Observable}
 */
export const patch$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: PATCHRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.patch(
    `${configDomain}/${endpoint}`,
    payload,
    getHeaders(token, additionalHeaders),
  ));
};

/**
 * PATCH request rxjs fetch observable
 *
 * @param  {PATCHRequestProps}
 * @return {Observable}
 */
export const patchLegacy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: PATCHRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.patch(
    `${configDomain}/${endpoint}`,
    payload,
    getHeadersLegacy(token, additionalHeaders),
  ));
};
export type POSTRequestProps = {
  endpoint: string;
  payload: any;
  token?: string;
  additionalHeaders?: any;
  domain?: string;
};

/**
 * POST request rxjs fetch observable
 *
 * @param  {POSTRequestProps}
 * @return {Observable}
 */
export const post$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: POSTRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.post(
    `${configDomain}/${endpoint}`,
    payload,
    getHeaders(token, additionalHeaders),
  ));
};

/**
 * POST request rxjs fetch observable
 *
 * @param  {POSTRequestProps}
 * @return {Observable}
 */
export const postLegacy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: POSTRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.post(
    `${configDomain}/${endpoint}`,
    payload,
    getHeadersLegacy(token, additionalHeaders),
  ));
};
export type PUTRequestProps = {
  endpoint: string;
  payload: any;
  token?: string;
  additionalHeaders?: any;
  domain?: string;
};

/**
 * PUT request rxjs fetch observable
 *
 * @param  {PUTRequestProps}
 * @return {Observable}
 */
export const put$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: PUTRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.put(
    `${configDomain}/${endpoint}`,
    payload,
    getHeaders(token, additionalHeaders),
  ));
};

/**
 * PUT request rxjs fetch observable
 *
 * @param  {PUTRequestProps}
 * @return {Observable}
 */
export const putLegacy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: PUTRequestProps) => {
  const configDomain = getDomain(domain);
  return defer(() => ajax.put(
    `${configDomain}/${endpoint}`,
    payload,
    getHeadersLegacy(token, additionalHeaders),
  ));
};
export type DELETERequestProps = {
  endpoint: string;
  payload: any;
  token?: string;
  additionalHeaders?: any;
  domain?: string;
};

/**
 * DELETE request rxjs fetch observable
 *
 * @param  {DELETERequestProps}
 * @return {Observable}
 */
export const destroy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: DELETERequestProps) => defer(() => ajax.delete(
  getGETURL(domain, endpoint, payload),
  getHeaders(token, additionalHeaders),
));

/**
 * DELETE request rxjs fetch observable
 *
 * @param  {DELETERequestProps}
 * @return {Observable}
 */
export const destroyLegacy$ = ({
  endpoint,
  payload,
  token,
  additionalHeaders,
  domain = defaultDomain,
}: DELETERequestProps) => defer(() => ajax.delete(
  getGETURL(domain, endpoint, payload),
  getHeadersLegacy(token, additionalHeaders),
));
