import {
  combineActions,
  handleActions,
} from 'redux-actions';

import {
  LOGIN_WITH_CODE_SUBMIT_SUCCESS,
  LOGOUT,
  OWNER_AUTH_REFRESH_GET_SUCCESS,
  OWNER_LOGIN_SUCCESS,
  SET_BROWSER_ID,
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS,
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  AUTHENTICATION_SESSION_GET_SUCCESS,
  AUTHENTICATION_SESSION_POST_SUCCESS,
  AUTHENTICATION_VERIFY_POST_SUCCESS,
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
  AUTHENTICATION_CODE_POST_SUCCESS,
} from '../../actions/actionTypes';
import type { SetBrowserIDAction } from '../../actions/owner/auth';
import type { GetOwnerRefreshTokenSuccessAction } from '../../actions/owner/getOwnerRefreshToken';
import type { LoginOwnerSuccessAction } from '../../actions/owner/loginOwner';

export type AuthState = {
  browserID: string | null | undefined;
  ownerID: string | number;
  ownerUUID: string | undefined;
  ownerToken: string | null | undefined;
  allowedMethods: string[] | null | undefined;
  sessionToken: string | null | undefined;
  preferredMethod: string | null | undefined;
  legacyToken: string | null | undefined;
  refreshToken: string | null | undefined;
  sessionTokenCreatedAt?: string | null; // Dates are stored as string in the Redux store
  facebookAccessToken?: string | null | undefined;
  googleIdToken?: string | null | undefined;
  appleIdToken?: string | null | undefined;
  socialAuthProviderToLink?: string | null | undefined;
};

// Note - Use src/slices/auth moving forward
export const initialState = {
  browserID: null,
  ownerID: NaN,
  ownerUUID: null,
  ownerToken: null,
  allowedMethods: [],
  sessionToken: null,
  preferredMethod: null,
  legacyToken: null,
  refreshToken: null,
  sessionTokenCreatedAt: null,
  facebookAccessToken: null,
};

export type SignInSuccessAction = LoginOwnerSuccessAction;

// Note - Use src/slices/auth moving forward
export const reducer: any = handleActions(
  {
    [SET_BROWSER_ID]: (
      state: AuthState,
      { payload: { browserID } }: SetBrowserIDAction,
    ): AuthState => ({ ...state, browserID }),
    [OWNER_AUTH_REFRESH_GET_SUCCESS]: (
      state: AuthState,
      { payload: { ownerToken } }: GetOwnerRefreshTokenSuccessAction,
    ): AuthState => ({ ...state, ownerToken }),
    [OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS]: (
      state: AuthState,
      { payload: { token: sessionToken } },
    ) => ({ ...state, sessionToken, sessionTokenCreatedAt: new Date() }),
    [AUTHENTICATION_SESSION_GET_SUCCESS]: (
      state: AuthState,
      { payload: { allowedMethods } },
    ) => ({ ...state, allowedMethods }),
    [AUTHENTICATION_SESSION_POST_SUCCESS]: (
      state: AuthState,
      { payload: {
        allowedMethods,
        sessionToken,
        preferredMethod,
      } },
    ) => ({ ...state, allowedMethods, sessionToken, preferredMethod }),
    [AUTHENTICATION_CODE_POST_SUCCESS]: (
      state: AuthState,
      {
        payload: {
          authenticationMethod,
        },
      },
    ) => ({
      ...state,
      preferredMethod: authenticationMethod,
    }),
    [combineActions(
      AUTHENTICATION_PASSWORD_POST_SUCCESS,
      AUTHENTICATION_VERIFY_POST_SUCCESS,
    )]: (
      state: AuthState,
      {
        payload: {
          id: ownerID,
          uuid: ownerUUID,
          token: legacyToken,
          session: {
            token: ownerToken,
          },
          refresh: {
            token: refreshToken,
          },
        },
      },
    ) => ({
      ...state,
      ownerID,
      ownerUUID,
      legacyToken,
      ownerToken,
      refreshToken,
    }),
    [combineActions(
      LOGIN_WITH_CODE_SUBMIT_SUCCESS,
      OWNER_LOGIN_SUCCESS,
      OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
      OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
      OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
    )]: (
      state: AuthState,
      { payload: { ownerToken, ownerID, ownerUUID } }: SignInSuccessAction,
    ): AuthState => ({
      ...state,
      ownerToken,
      ownerID,
      ownerUUID,
      legacyToken: ownerToken,
    }),
    [LOGOUT]: (): AuthState => initialState,
  },
  initialState,
);

export default reducer;
