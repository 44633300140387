import { combineEpics } from "redux-observable";
import makeStripeCallEpic$ from "./makeStripeConnectCall/makeStripeConnectCall.epic";
import getWalker$ from "./getWalker/getWalker.epic";
import getWalkerContactInfo$ from "./getWalkerContactInfo/getWalkerContactInfo.epic";
import getWalkerProfile$ from "./getWalkerProfile/getWalkerProfile.epic";
import getWalkerProfileByPromoCode$ from "./getWalkerProfileByPromoCode/getWalkerProfileByPromoCode.epic";
import resetWalkerPassword$ from "./resetWalkerPassword/resetWalkerPassword.epic";
import getWalkerStripeConnectRedirectUrl$ from "./getWalkerStripeConnectRedirectUrl/getWalkerStripeConnectRedirectUrl.epic";
import verifyWalkerMigration$ from "./verifyWalkerMigration/verifyWalkerMigration.epic";
import verifyWalkerStripeToken$ from "./verifyWalkerStripeToken/verifyWalkerStripeToken.epic";
import getWalkerWalk$ from "./getWalkerWalk/getWalkerWalk.epic";
import signInWalker$ from "./signInWalker/signInWalker.epic";
import signUpWalker$ from "./signUpWalker/signUpWalker.epic";
import updateWalkerPassword$ from "./updateWalkerPassword/updateWalkerPassword.epic";
export default combineEpics(getWalker$, getWalkerContactInfo$, getWalkerProfile$, getWalkerProfileByPromoCode$, resetWalkerPassword$, getWalkerStripeConnectRedirectUrl$, verifyWalkerMigration$, makeStripeCallEpic$, verifyWalkerStripeToken$, getWalkerWalk$, signInWalker$, signUpWalker$, updateWalkerPassword$);