import { useState } from 'react';

import { APP_S3_BUCKET_URL } from '../constants';
import { usePostCreateMediaUploadUrlMutation } from '../slices/operations/v6/operationsApi';
import { wagApi } from '../types/wagapi';
import { tryCatch } from '../utils';

export type UploadFile = {
  container: string;
  filename: string;
  handle: string;
  key: string;
  mimetype: string;
  originalFile: {
    name: string;
    type: string;
    size: number;
  };
  originalPath: string;
  size: number;
  source: string;
  status: string;
  uploadId: string;
  url: string;
};
export type UploadResult = {
  filesFailed: UploadFile[];
  filesUploaded: UploadFile[];
};
export type BucketUploadResult = UploadFile & {
  bucketURL: string;
};
export type UploadResultWithBucket = {
  filesFailed: BucketUploadResult[];
  filesUploaded: BucketUploadResult[];
};
export type UseUploaderProps = {
  onSuccess?: (...args: Array<any>) => any;
  onError?: (...args: Array<any>) => any;
};
export const createS3BucketURL = (file: UploadFile) => {
  const bucketURL = APP_S3_BUCKET_URL;
  const normalizedBucketURL = new URL(bucketURL).origin;
  const url = [normalizedBucketURL, file.key].join('/');
  const normalizedURL = new URL(url).href; // get the full normalized url

  return normalizedURL;
};
export const useUploader = ({
  onSuccess = () => { },
  onError = () => { },
}: UseUploaderProps = {
  onSuccess: () => { },
  onError: () => { },
}) => {
  const [filesUploaded, setFilesUploaded] = useState<BucketUploadResult[]>([]);
  const [error, setError] = useState(null);

  const handleOnSuccess = (uploadResult: UploadResult) => {
    if (!uploadResult.filesUploaded && !uploadResult.filesUploaded.length) {
      return undefined;
    }

    const files = uploadResult.filesUploaded.map<any>((fileUploaded) => ({
      ...fileUploaded,
      bucketURL: createS3BucketURL(fileUploaded),
    }));
    setFilesUploaded(files);
    onSuccess(files);
    return files;
  };

  const handleOnError = (_error: Error) => {
    setError(_error);
    onError(_error);
  };

  return {
    filesUploaded,
    error,
    handleOnSuccess,
    handleOnError,
  };
};

export type UploaderV2Props = {
  mediaItemType: 'primary';
  refEntityType: 'dog' | 'owner' | (string & Record<never, never>);
  media: File;
};

export const useUploaderV2 = () => {
  const [
    executePostCreateMediaUploadUrl,
    {
      isError,
      isLoading: isPostCreateMediaUploadUrlLoading,
      isSuccess,
      reset: resetPostCreateMediaUploadUrl,
    },
  ] = usePostCreateMediaUploadUrlMutation();
  const [isS3UploadLoading, setIsS3UploadLoading] = useState(false);

  const createMediaUploadUrl = (params: wagApi.V6.PostCreateMediaUploadUrlPayload) => (
    executePostCreateMediaUploadUrl(params)
      .unwrap()
  );

  const uploadToS3 = async (s3Url: string, file: File) => {
    setIsS3UploadLoading(true);

    const [response, err] = await tryCatch(() => {
      fetch(s3Url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });
    });

    if (err) {
      setIsS3UploadLoading(false);
      return err;
    }

    setIsS3UploadLoading(false);

    return response;
  };

  const execute = async (params: wagApi.V6.PostCreateMediaUploadUrlPayload) => {
    const result = await createMediaUploadUrl(params);
    await uploadToS3(result.uploadUrl, params.media);
    return result;
  };

  const reset = () => {
    resetPostCreateMediaUploadUrl();
    setIsS3UploadLoading(false);
  };

  return {
    isError,
    isLoading: isPostCreateMediaUploadUrlLoading || isS3UploadLoading,
    isSuccess,
    execute,
    createMediaUploadUrl,
    uploadToS3,
    reset,
  };
};
