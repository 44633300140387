import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { draftsSlice } from '../slices/drafts';

export const useDraftJanitor = () => {
  const dispatch = useDispatch();

  const clearByKey = (draftKey: string) => {
    dispatch(draftsSlice.actions.clearByDraftKey({
      draftKey,
    }));
  };

  const clearByKeys = (draftKeys: readonly string[]) => {
    draftKeys.forEach((draftKey) => {
      dispatch(draftsSlice.actions.clearByDraftKey({
        draftKey,
      }));
    });
  };

  const clearAll = () => {
    dispatch(draftsSlice.actions.clear());
  };

  return {
    clearByKey: useCallback(clearByKey, [dispatch]),
    clearByKeys: useCallback(clearByKeys, [dispatch]),
    clearAll: useCallback(clearAll, [dispatch]),
  };
};
