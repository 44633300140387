export * from './loginByEmailForm';
export * from './loginByCodeForm';
export * from './loginByPasswordForm';

export const loginForm = {
  SignInWithEmailBtn: 'sign-in-with-email-btn',
  FacebookLoginButton: 'facebook-login-button',
  AppleLoginButton: 'apple-login-button',
  GoogleLoginButton: 'google-login-button',
  SignInWithGoogleButton: 'sign-in-with-google-button',
  SignInWithFacebookButton: 'sign-in-with-facebook-button',
  SignInWithAppleButton: 'sign-in-with-apple-button',
  GoogleSocialLoginErrorMessage: 'google-social-login-error-message',
  FacebookSocialLoginErrorMessage: 'facebook-social-login-error-message',
  AppleSocialLoginErrorMessage: 'apple-social-login-error-message',
};
