import * as React from 'react';

import Flex from '@waglabs/flex';
import Overlay from '@waglabs/overlay';

import { SidebarContext } from '../../../../SidebarContext';
import { SidebarNavigationContainer } from '../SidebarNavigation/SidebarNavigation';

export const SidebarWithOverlay = () => (
  <SidebarContext.Consumer>
    {({
      isSidebarVisible,
      onToggleSidebar,
    }) => (
      <Flex
        position="fixed"
        zIndex={2}
        width={1}
        height={isSidebarVisible ? '100%' : '0px'}
        mt={[45, 45, 60]}
        overflowY="scroll"
      >
        <Overlay
          zIndex={3}
          mt={[45, 45, 60]}
          onClick={onToggleSidebar}
          display={isSidebarVisible ? 'flex' : 'none'}
          hover={false}
        />
        <SidebarNavigationContainer
          isVisible={isSidebarVisible}
          onToggleSidebar={onToggleSidebar}
        />
      </Flex>
    )}
  </SidebarContext.Consumer>
);

SidebarWithOverlay.defaultProps = {
  isVisible: false,
};
