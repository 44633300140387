import Flex from '@waglabs/flex';
import styled from 'styled-components';

export const SidebarNavigationHoverContainer = styled(Flex)`
  background-color: ${props => props.theme.colors.white};
  flex-shrink: 0;

  &:hover {
    background-color: ${props => props.theme.colors.backgroundGray};
  }
`;
