import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  groupBy,
  pipe,
  uniq,
  map,
  mergeAll,
  mergeWith,
  concat,
} from 'ramda';
import { AnyAction } from 'redux';

import {
  ServiceState,
  State,
  Timezone,
} from '../../constants';
import { ReduxState } from '../../reducers';
import {
  ServicesState as OldState,
  initialState as oldInitialState,
} from '../../reducers/services';
import { store } from '../../types/store';
import { wagApi } from '../../types/wagapi';
import { webApp } from '../../types/webapp';
import { maybe } from '../../utils';
import { ownersApi as ownersApiV6 } from '../owners/v6';

export type ServicesState = OldState & {
  allIds: number[];
  allStatuses: string[];
  allStates: string[];
  byCategory: Record<string, number[]>;
  byId: Record<string, store.V6.Service>;
  byStatus: Record<string, number[]>;
  byState: Record<string, number[]>;
};
const initialState: ServicesState = {
  ...oldInitialState,
  allIds: [],
  allStates: [],
  allStatuses: [],
  byCategory: {},
  byId: {},
  byStatus: {},
  byState: {},
};

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    const normalizeService = (item: wagApi.V6.GetOwnerServicesResponse['items']['0']) => ({
      ...item,
      pets: item.pets.map((pet) => pet.id),
    }) as store.V6.Service;

    const groupById = groupBy((item: store.V6.Service) => String(item.id));
    const groupByStatus = groupBy((item: store.V6.Service) => item.status);
    const groupByState = groupBy((item: store.V6.Service) => item.state);
    const mapToServiceId = map((service: store.V6.Service) => service.id);
    const mapToServiceIds = (
      map((services: store.V6.Service[]) => mapToServiceId(services))
    );
    /**
     * `mergeWith` with concat + uniq to avoid dupes
     * https://ramdajs.com/docs/#mergeWith
     */
    const mergeWithUniq = (
      left: Record<string, number[]>,
      right: Record<string, number[]>,
    ) => mergeWith(
      pipe<any, any, any>(
        concat,
        uniq,
      ),
      left,
      right,
    );

    builder.addMatcher<AnyAction>(
      ownersApiV6.endpoints.getOwnerWalkServices.matchFulfilled,
      (state, { payload }) => {
        const { items: rawItems } = payload as wagApi.V6.GetOwnerServicesResponse;

        const items = rawItems.map(normalizeService);

        const allIds = mapToServiceId(items);
        const allStatuses = items.map((item) => item.status);
        const allStates = items.map((item) => item.state);

        /**
         * Walk
         */
        const byCategory = {
          walk: mapToServiceId(items),
        } as unknown as Record<string, number[]>;
        /**
         * TODO - update typings w/ ramda
         */
        const byId = pipe<store.V6.Service[], any, any>(
          groupById,
          map((item: store.V6.Service[]) => mergeAll(item)),
        )(items) as unknown as Record<string, store.V6.Service>;

        const byStatus = pipe<store.V6.Service[], any, any>(
          groupByStatus,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        const byState = pipe<store.V6.Service[], any, any>(
          groupByState,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        return ({
          ...state,
          allIds: uniq([
            ...maybe([])(state.allIds),
            ...allIds,
          ]),
          allStates: uniq([
            ...maybe([])(state.allStates),
            ...allStates,
          ]),
          byCategory: mergeWithUniq(state.byCategory, byCategory),
          byId: {
            ...state.byId,
            ...byId,
          },
          byStatus: mergeWithUniq(state.byStatus, byStatus),
          byState: mergeWithUniq(state.byState, byState),
          allStatuses: uniq([
            ...maybe([])(state.allStatuses),
            ...allStatuses,
          ]),
        });
      },
    );

    builder.addMatcher<AnyAction>(
      ownersApiV6.endpoints.getOwnerTrainingServices.matchFulfilled,
      (state, { payload }) => {
        const { items: rawItems } = payload as wagApi.V6.GetOwnerServicesResponse;

        const items = rawItems.map(normalizeService);

        const allIds = mapToServiceId(items);
        const allStatuses = items.map((item) => item.status);
        const allStates = items.map((item) => item.state);

        /**
         * Training
         */
        const byCategory = {
          training: mapToServiceId(items),
        } as unknown as Record<string, number[]>;
        /**
         * TODO - update typings w/ ramda
         */
        const byId = pipe<store.V6.Service[], any, any>(
          groupById,
          map((item: store.V6.Service[]) => mergeAll(item)),
        )(items) as unknown as Record<string, store.V6.Service>;

        const byStatus = pipe<store.V6.Service[], any, any>(
          groupByStatus,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        const byState = pipe<store.V6.Service[], any, any>(
          groupByState,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        return ({
          ...state,
          allIds: uniq([
            ...maybe([])(state.allIds),
            ...allIds,
          ]),
          allStates: uniq([
            ...maybe([])(state.allStates),
            ...allStates,
          ]),
          byCategory: mergeWithUniq(state.byCategory, byCategory),
          byId: {
            ...state.byId,
            ...byId,
          },
          byStatus: mergeWithUniq(state.byStatus, byStatus),
          byState: mergeWithUniq(state.byState, byState),
          allStatuses: uniq([
            ...maybe([])(state.allStatuses),
            ...allStatuses,
          ]),
        });
      },
    );

    builder.addMatcher<AnyAction>(
      ownersApiV6.endpoints.getOwnerSittingServices.matchFulfilled,
      (state, { payload }) => {
        const { items: rawItems } = payload as wagApi.V6.GetOwnerServicesResponse;

        const items = rawItems.map(normalizeService);

        const allIds = mapToServiceId(items);
        const allStatuses = items.map((item) => item.status);
        const allStates = items.map((item) => item.state);

        /**
         * sitting
         */
        const byCategory = {
          sitting: mapToServiceId(items),
        } as unknown as Record<string, number[]>;
        /**
         * TODO - update typings w/ ramda
         */
        const byId = pipe<store.V6.Service[], any, any>(
          groupById,
          map((item: store.V6.Service[]) => mergeAll(item)),
        )(items) as unknown as Record<string, store.V6.Service>;
        const byStatus = pipe<store.V6.Service[], any, any>(
          groupByStatus,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        const byState = pipe<store.V6.Service[], any, any>(
          groupByState,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        return ({
          ...state,
          allIds: uniq([
            ...maybe([])(state.allIds),
            ...allIds,
          ]),
          allStates: uniq([
            ...maybe([])(state.allStates),
            ...allStates,
          ]),
          byCategory: mergeWithUniq(state.byCategory, byCategory),
          byId: {
            ...state.byId,
            ...byId,
          },
          byStatus: mergeWithUniq(state.byStatus, byStatus),
          byState: mergeWithUniq(state.byState, byState),
          allStatuses: uniq([
            ...maybe([])(state.allStatuses),
            ...allStatuses,
          ]),
        });
      },
    );

    builder.addMatcher<AnyAction>(
      ownersApiV6.endpoints.getOwnerDropInServices.matchFulfilled,
      (state, { payload }) => {
        const { items: rawItems } = payload as wagApi.V6.GetOwnerServicesResponse;

        const items = rawItems.map(normalizeService);

        const allIds = mapToServiceId(items);
        const allStatuses = items.map((item) => item.status);
        const allStates = items.map((item) => item.state);

        /**
         * drop-in
         */
        const byCategory = {
          dropIn: mapToServiceId(items),
        } as unknown as Record<string, number[]>;
        /**
         * TODO - update typings w/ ramda
         */
        const byId = pipe<store.V6.Service[], any, any>(
          groupById,
          map((item: store.V6.Service[]) => mergeAll(item)),
        )(items) as unknown as Record<string, store.V6.Service>;
        const byStatus = pipe<store.V6.Service[], any, any>(
          groupByStatus,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        const byState = pipe<store.V6.Service[], any, any>(
          groupByState,
          mapToServiceIds,
        )(items) as unknown as Record<string, number[]>;

        return ({
          ...state,
          allIds: uniq([
            ...maybe([])(state.allIds),
            ...allIds,
          ]),
          allStates: uniq([
            ...maybe([])(state.allStates),
            ...allStates,
          ]),
          byCategory: mergeWithUniq(state.byCategory, byCategory),
          byId: {
            ...state.byId,
            ...byId,
          },
          byStatus: mergeWithUniq(state.byStatus, byStatus),
          byState: mergeWithUniq(state.byState, byState),
          allStatuses: uniq([
            ...maybe([])(state.allStatuses),
            ...allStatuses,
          ]),
        });
      },
    );
  },
});

// selector functions

/**
 * A "localized" selector is one that expects just a piece of the state as an argument,
 * without knowing or caring where that is in the root state
 */
const localizedSelectors = {
  selectPets: (petIds: store.V6.Service['pets'] = [], pets: store.V6.Pet[] = []) => (
    petIds.map((petId) => {
      const pet = pets.find((storePet) => storePet.id === petId);
      if (pet) {
        return pet;
      }

      /**
       * This will be filtered out by `.filter(Boolean)`
       */
      return undefined;
    })
  ),
};
const selectors = {
  getServiceById: (serviceId: number) => (state: ReduxState): webApp.V6.Service | undefined => {
    const rawService = state?.services?.byId?.[serviceId];

    if (!rawService) {
      return undefined;
    }

    const allPets = state.pets.allIds
      .map((id) => state.pets.byId[id])
      .filter((pet) => pet.state === State.Active);

    const service = {
      ...rawService,
      /**
       * Use `filter(Boolean)` to automatically remove falsy values
       * `.find` can return undefined if not found (in such rare cases)
       *
       * rawService.pets returns the petIds
       */
      pets: localizedSelectors
        .selectPets(rawService.pets, allPets)
        .filter(Boolean) as store.V6.Pet[],
    };

    return service;
  },
  getAllServicesByState: (serviceState: store.V6.Service['state']) => (state: ReduxState) => {
    const rawWalkIds = state?.services?.byState?.[serviceState];

    if (!rawWalkIds || !rawWalkIds.length) {
      return [];
    }

    const allPets = state.pets.allIds
      .map((id) => state.pets.byId[id])
      .filter((pet) => pet.state === State.Active);

    const walks = rawWalkIds.map((rawWalkId) => ({
      ...state.services.byId[rawWalkId],
      /**
       * Use `filter(Boolean)` to automatically remove falsy values
       * `.find` can return undefined if not found (in such rare cases)
       */
      pets: localizedSelectors
        .selectPets(state.services.byId[rawWalkId].pets, allPets)
        .filter(Boolean),
    }))
      .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)));

    return walks;
  },
  getAllUpcomingServicesWithFilter: (filter?: {
    limit: number,
    state?: 'pending' | 'confirmed',
  }) => (state: ReduxState) => (
    selectors.getAllServicesByState(filter?.state || ServiceState.Pending)(state)
      .filter((service) => moment.tz(service.startTime, Timezone.default).isSameOrAfter(moment()))
      .slice(0, filter?.limit)
  ),
  getAllPastServicesWithFilter: (filter?: {
    limit: number,
  }) => (state: ReduxState) => (
    selectors.getAllServicesByState(ServiceState.Completed)(state)
      .slice(0, filter?.limit)
  ),
  getAllStartedServicesWithFilter: (filter?: {
    limit: number,
  }) => (state: ReduxState) => (
    selectors.getAllServicesByState(ServiceState.Started)(state)
      .slice(0, filter?.limit)
  ),
  getLatestUpcomingService: () => (state: ReduxState) => (
    [...selectors.getAllUpcomingServicesWithFilter({
      limit: 1,
    })(state)].shift()
  ),
  getLatestUpcomingConfirmedService: () => (state: ReduxState) => (
    [...selectors.getAllUpcomingServicesWithFilter({
      limit: 1,
      state: ServiceState.Confirmed,
    })(state)].shift()
  ),
  getLatestPastService: () => (state: ReduxState) => (
    [...selectors.getAllPastServicesWithFilter({
      limit: 1,
    })(state)].shift()
  ),
  getLatestStartedService: () => (state: ReduxState) => (
    [...selectors.getAllStartedServicesWithFilter({
      limit: 1,
    })(state)].shift()
  ),
};

export const servicesSlice = {
  ...slice,
  selectors,
};
