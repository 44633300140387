import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  filter,
  groupBy,
  map,
  mergeAll,
  pipe,
  uniq,
} from 'ramda';
import { AnyAction } from 'redux';

import {
  DateFormat,
  PetStrings,
  wellnessPetPlanAddonByNameMap,
  WellnessPlan,
  wellnessPlanByNameMap,
} from '../../constants';
import { ReduxState } from '../../reducers';
import { app } from '../../types/app';
import { store } from '../../types/store';
import { wagApi } from '../../types/wagapi';
import {
  arrayToObject,
  maybe,
} from '../../utils';
import { ownersApi } from '../owners/v6';

export type WellnessState = app.Wellness.Wellness & {
  /**
   * The records that are stored in here are normalized just
   * like how the API endpoint returns it. It doesn't return the full
   * data of the pet and owner, only identifiers. This shouldn't be limited
   * to the API endpoint only.
   * See corresponding typing on `store.V6.Wellness.Subscription`
   */
  normalizedAllIds: string[];
  /**
   * The records that are stored in here are normalized just
   * like how the API endpoint returns it. It doesn't return the full
   * data of the pet and owner, only identifiers. This shouldn't be limited
   * to the API endpoint only.
   * See corresponding typing on `store.V6.Wellness.Subscription`
   */
  normalizedBySubscriptionId: Record<string, store.V6.Wellness.Subscription>;
  /**
   * Unlike the normalize structure, this holds all denormalized records
   * like how we create records based on the form. They have different structure.
   * Forms can be created in a flat structure or normalize.
   * See corresponding typing on `app.Wellness.Subscription`
   */
  allIds: string[];
  /**
   * Unlike the normalize structure, this holds all denormalized records
   * like how we create records based on the form. They have different structure.
   * Forms can be created in a flat structure or normalize.
   * See corresponding typing on `app.Wellness.Subscription`
   */
  bySubscriptionId: Record<string, app.Wellness.Subscription>;
};
const initialState: WellnessState = {
  /**
   * @deprecated - Use other available storages for storing/retrieving records
   */
  pets: [], // pets contain the addons
  /**
   * @deprecated - Use other available storages for storing/retrieving records
   */
  userInfo: {},
  /**
   * @deprecated - Use other available storages for storing/retrieving records
   */
  addressInfo: {},
  /**
   * @deprecated - Use other available storages for storing/retrieving records
   */
  sameBillingAndMailingAddress: false,
  /**
   * @deprecated - Use other available storages for storing/retrieving records
   */
  extras: [],
  normalizedAllIds: [],
  normalizedBySubscriptionId: {},
  allIds: [],
  bySubscriptionId: {},
};

const slice = createSlice({
  name: 'wellness',
  initialState,
  reducers: {
    /**
     * @deprecated - Let's use the subscription functions
     */
    save: (state, {
      payload,
    }: {
      payload: Partial<app.Wellness.Wellness>,
    }) => ({
      ...state,
      ...payload,
    }),
    /**
     * @deprecated - Let's use the subscription functions
     */
    savePetAddons: (state, {
      payload,
    }: {
      payload: app.Wellness.WellnessPet[],
    }) => {
      const petsPayloadMap = arrayToObject(
        payload,
      ) as Record<number, app.Wellness.WellnessPet>;

      const petsWithAddons = state.pets.map((pet) => {
        if (petsPayloadMap[pet.id]) {
          return {
            ...pet,
            addons: petsPayloadMap[pet.id].addons || [],
          };
        }
        return pet;
      });

      return ({
        ...state,
        pets: petsWithAddons,
      });
    },
    /**
     * @deprecated - Let's use the subscription functions
     */
    removePet: (state, {
      payload,
    }: {
      payload: Partial<app.Wellness.WellnessPet>,
    }) => ({
      ...state,
      pets: state.pets.filter((pet) => pet.id !== payload.id),
    }),
    /**
     * @deprecated - Let's use the subscription functions
     */
    removeExtra: (state, {
      payload,
    }: {
      payload: Partial<app.Wellness.WellnessExtra>
    }) => ({
      ...state,
      extras: state.extras.filter((extra) => extra.id !== payload.id),
    }),
    createSubscription: (state, {
      payload,
    }: {
      payload: app.Wellness.Subscription
    }) => ({
      ...state,
      allIds: [...state.allIds, payload.uuid],
      bySubscriptionId: {
        ...state.bySubscriptionId,
        [payload.uuid]: payload,
      },
    }),
    updateSubscription: (state, {
      payload,
    }: {
      payload: {
        uuid: string,
        newSubscription: app.Wellness.Subscription,
      }
    }) => {
      /**
       * We should only update records on `bySubscriptionId`
       * other storages are for "reading" purposes only (see selectors)
       */
      const subscription = state.bySubscriptionId[payload.uuid];

      if (!subscription) {
        return state;
      }

      return {
        ...state,
        bySubscriptionId: {
          ...state.bySubscriptionId,
          [payload.uuid]: payload.newSubscription,
        },
      };
    },
    removeSubscription: (state, {
      payload,
    }: {
      payload: {
        uuid: string,
      }
    }) => {
      /**
       * We should only update records on `bySubscriptionId`
       * other storages are for "reading" purposes only (see selectors)
       */
      const subscription = state.bySubscriptionId[payload.uuid];

      if (!subscription) {
        return state;
      }

      const newAllids = filter(
        (uuid) => uuid !== payload.uuid,
        state.allIds,
      );

      const newBySubscriptId = filter(
        ({ uuid }) => uuid !== payload.uuid,
        state.bySubscriptionId,
      );

      return {
        ...state,
        allIds: newAllids,
        bySubscriptionId: newBySubscriptId,
      };
    },
    removeNormalizedSubscription: (state, {
      payload,
    }: {
      payload: {
        uuid: string,
      }
    }) => {
      const subscription = state.normalizedBySubscriptionId[payload.uuid];

      if (!subscription) {
        return state;
      }

      const newAllids = filter(
        (uuid) => uuid !== payload.uuid,
        state.normalizedAllIds,
      );

      const newBySubscriptId = filter(
        ({ uuid }) => uuid !== payload.uuid,
        state.normalizedBySubscriptionId,
      );

      return {
        ...state,
        normalizedAllIds: newAllids,
        normalizedBySubscriptionId: newBySubscriptId,
      };
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher<AnyAction>(
      ownersApi.endpoints.getOwnerWellnessSubscriptions.matchFulfilled,
      (state, { payload }) => {
        const { items: rawItems } = payload as wagApi.V6.GetOwnerWellnessSubscriptionsResponse;

        const groupById = groupBy((item: store.V6.Wellness.Subscription) => String(item.uuid));

        const normalizedAllIds = rawItems.map((item) => item.uuid);

        // TODO - Update typings; Typing the `pipe` is difficult on this case
        const normalizedBySubscriptionId = pipe<any, any, any>(
          groupById,
          map((item: store.V6.Wellness.Subscription[]) => mergeAll(item)),
        )(rawItems) as unknown as Record<string, store.V6.Wellness.Subscription>;

        return ({
          ...state,
          normalizedAllIds: uniq([
            ...maybe([])(state.normalizedAllIds),
            ...normalizedAllIds,
          ]),
          normalizedBySubscriptionId,
        });
      },
    );
  },
});

// selector functions
const selectors = {
  get: (state: ReduxState) => ({
    ...state.wellness,
    pets: state.wellness.pets.filter((pet) => pet.plan.id !== WellnessPlan.Plans.NoPlan),
  }),
  getRaw: (state: ReduxState) => state.wellness,
  getPets: (state: ReduxState) => state.wellness.pets,
  getPetAddonsById: (id: number) => (state: ReduxState) => (
    state.wellness.pets.find((pet) => pet.id === id)?.addons
  ),
  getExistingPetAndTransformToSubscriptionById: (
    petUuid: string,
  ) => (state: ReduxState): app.Wellness.Subscription | undefined => {
    const pet = state.pets.byId[petUuid];

    if (!pet) {
      return undefined;
    }

    const weight = PetStrings.SizeOptions.find(
      (sizeOption) => sizeOption.value === pet.size,
    )?.weightValue || 0;

    const subscription: app.Wellness.Subscription = {
      addons: [],
      pet: {
        birthday: pet.birthday || '',
        breed: pet.breed,
        name: pet.name,
        weight,
        imageUrl: pet.imageUrl,
      },
      plan: null,
      status: WellnessPlan.SubscriptionStatus.None,
      uuid: pet.uuid || petUuid,
    };

    return subscription;
  },
  getExistingPetIdsWithoutPlans: (state: ReduxState) => {
    const makeReducer = (existingSubscriptionIds: string[]) => {
      const reducerFn = (
        accumulator: string[],
        currentValue: string,
      ) => {
        const petAlreadyHasSubscription = existingSubscriptionIds
          .includes(currentValue);

        if (petAlreadyHasSubscription) {
          return accumulator;
        }

        return [...accumulator, currentValue];
      };

      return reducerFn;
    };

    const getFromNormalizeStorage = makeReducer(
      state.wellness.normalizedAllIds.flatMap((existingSubscription) => (
        state.wellness.normalizedBySubscriptionId[existingSubscription]
          ?.petIdentifier || []
      )),
    );

    const getFromDefaultStorage = makeReducer(
      state.wellness.allIds.flatMap((existingSubscription) => (
        state.wellness.bySubscriptionId[existingSubscription]
          ?.uuid || []
      )),
    );

    const ids = state.pets.allIds
      .reduce(getFromNormalizeStorage, [] as string[])
      .reduce(getFromDefaultStorage, [] as string[]);

    return ids;
  },
  normalizedBySubscriptionId: (
    uuid: string,
  ) => (state: ReduxState): app.Wellness.Subscription | undefined => {
    const normalizedSubscription = state.wellness.normalizedBySubscriptionId[uuid];

    if (!normalizedSubscription) {
      return undefined;
    }

    const normalizedPet = state.pets.byId[normalizedSubscription.petIdentifier];

    if (!normalizedPet) {
      return undefined;
    }

    const weight = PetStrings.SizeOptions.find(
      (sizeOption) => sizeOption.value === normalizedPet.size,
    )?.weightValue || 0;

    const subscription: app.Wellness.Subscription = {
      addons: normalizedSubscription.addons.map(
        (addonLiteralName) => wellnessPetPlanAddonByNameMap[addonLiteralName],
      ),
      pet: {
        birthday: normalizedPet.birthday || '',
        breed: normalizedPet.breed,
        name: normalizedPet.name,
        weight,
        imageUrl: normalizedPet.imageUrl,
      },
      plan: wellnessPlanByNameMap[normalizedSubscription.wellnessPlan],
      status: normalizedSubscription.status,
      uuid: normalizedSubscription.uuid,
      ...normalizedSubscription.createdAt && {
        billingDate: moment(normalizedSubscription.createdAt)
          .add(30, 'days')
          .format(DateFormat.default),
      },
    };

    return subscription;
  },
  allIds: (state: ReduxState) => {
    const existingPetIds = selectors.getExistingPetIdsWithoutPlans(state);

    const ids = [
      // Retrieve records that came from the API
      ...state.wellness.normalizedAllIds,
      // Retrieve existing pets ids so we can encourage them to get a wellness plan
      // But do not get all existingPetIds that exists on `state.wellness.allIds`
      // because they already have a "unpaid" subscription record
      ...existingPetIds,
      // Retrieve records that came from the form that the user used in the page
      ...state.wellness.allIds,
    ];

    return ids;
  },
  bySubscriptionId: (uuid: string) => (state: ReduxState): app.Wellness.Subscription => (
    state.wellness.bySubscriptionId[uuid]
    || selectors.normalizedBySubscriptionId(uuid)(state)
    || selectors.getExistingPetAndTransformToSubscriptionById(uuid)(state)
  ),
};

export const wellnessSlice = {
  ...slice,
  selectors,
};
