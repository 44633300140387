import { FormikState } from 'formik';
import {
  lensPath,
  view,
} from 'ramda';

type FormikFieldHelper<T> = Pick<FormikState<T>, 'errors' | 'touched'>;
type FormikFieldHelperOptions = {
  errorKeyExtractor: string;
};

const isErrorObject = (error: unknown) => typeof error === 'object';

/**
 * This is used for <FieldArray /> components with Formik
 */
export const getFormikFieldArrayHelper = ({
  errors,
  touched,
}: FormikFieldHelper<any>) => (fieldPath: (string | number)[], {
  errorKeyExtractor,
}: FormikFieldHelperOptions = {
  errorKeyExtractor: '',
}) => {
  const pathLens = lensPath(fieldPath);
  const hasTouched = view(pathLens, touched);
  const errorMessage = view(pathLens, errors);
  const hasError = hasTouched && Boolean(errorMessage);

  if (!hasError) {
    return {
      hasError: false,
      errorMessage: '',
    };
  }

  if (isErrorObject(errorMessage)) {
    return {
      hasError,
      errorMessage: String(errorMessage[errorKeyExtractor] || ''),
    };
  }

  return {
    hasError,
    errorMessage: String(errorMessage),
  };
};
