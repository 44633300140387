import type { ReduxState as State } from '../index';

import type {
  PasswordTips,
  PasswordVerification,
} from './password.reducer';

export const getPasswordVerification: (arg0: State) => PasswordVerification = ({
  password: {
    verification,
  },
}) => verification;
export const getPasswordTips: (arg0: State) => PasswordTips = ({
  password: {
    tips,
  },
}) => tips;
