import { useCallback } from 'react';

import { FEATURE_ROLLBAR_ENABLED } from '../constants';
import { RollbarLogType } from '../globals';

const log = (logType: RollbarLogType) => (message?: string, ...optionalParams: unknown) => {
  if (!FEATURE_ROLLBAR_ENABLED) {
    console.warn(`Attempted to run Rollbar.${logType}(...), but Rollbar is not enabled`);
    return;
  }

  const {
    Rollbar,
  } = window;

  if (Rollbar) {
    Rollbar[logType](message, ...optionalParams);
  }
};

export const useRollbar = () => {
  const critical = log('critical');
  const debug = log('debug');
  const error = log('error');
  const info = log('info');
  const warning = log('warning');

  return {
    critical: useCallback(critical, [critical]),
    debug: useCallback(debug, [debug]),
    error: useCallback(error, [error]),
    info: useCallback(info, [info]),
    warning: useCallback(warning, [warning]),
  };
};
