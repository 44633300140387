import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { RebookingRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_REBOOKING, Endpoint } from "../../../constants/endpoints";
import { REBOOKING } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { rebookingSuccess, rebookingFailure, RebookingAction } from "../../../actions/booking/rebooking";
import { rebookingResponseParser } from "../../../helpers/Http/ResponseParsers";

const rebooking$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(REBOOKING), retry(3), switchMap(({
  payload: {
    walkerId,
    walkerUuid,
    walkTypeId,
    startDate,
    startTime,
    endDate,
    endTime,
    usePreferred,
    dogIds,
    accessInfo = {}
  }
}: RebookingAction) => post$(({
  endpoint: ENDPOINT_REBOOKING({
    ownerID: getOwnerID(state$.value),
  }),
  payload: {
    id: getOwnerID(state$.value),
    walker_id: walkerId,
    walker_uuid: walkerUuid,
    use_preferred: usePreferred ? 1 : 0,
    use_fastest_available: 0,
    date: startDate,
    start_time: startTime,
    end_time: endTime,
    end_date: endDate,
    walk_type_id: walkTypeId,
    dog_ids: dogIds,
    access_details: [{
      key_mode: [Number(accessInfo.method)],
      notes: accessInfo.notes
    }],
    time_windows: null
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: RebookingRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: rebookingSuccess,
  onFailure: rebookingFailure,
  parser: rebookingResponseParser
})), catchError(handleErrorV5Response(action$, rebookingFailure)))));

export default rebooking$;