import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { WalkerProfileRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_PROFILE, Endpoint } from "../../../constants/endpoints";
import { WALKER_PROFILE_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalkerProfileSuccess, getWalkerProfileFailure, GetWalkerProfileAction } from "../../../actions/walker/getWalkerProfile";
import { getWalkerProfileResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalkerProfile$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKER_PROFILE_GET), mergeMap(({
  payload: {
    walkerID
  }
}: GetWalkerProfileAction) => get$(({
  endpoint: ENDPOINT_WALKER_PROFILE(walkerID),
  payload: {
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: WalkerProfileRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerProfileSuccess,
  onFailure: getWalkerProfileFailure,
  parser: getWalkerProfileResponseParser
})), catchError(handleErrorV5Response(action$, getWalkerProfileFailure)))));

export default getWalkerProfile$;