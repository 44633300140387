
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
} from 'webApp';
import {
  ESTIMATE_CLEAR,
  ESTIMATE_OVERNIGHT,
  ESTIMATE_OVERNIGHT_SUCCESS,
  ESTIMATE_OVERNIGHT_FAILURE,
} from '../../actionTypes';

/**
 * Clear an Estimate
 */
export type ClearOvernightEstimateAction = {
  type: typeof ESTIMATE_CLEAR,
};

export const clearOvernightEstimate: () => ClearOvernightEstimateAction = createAction(
  ESTIMATE_CLEAR,
);

/**
 * Estimate an overnight
 */
export type EstimateOvernightParams = {
  dogCount: number,
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  hasPickupAndDropoff: boolean,
  walkTypeId: number,
};

export type EstimateOvernightPayload = EstimateOvernightParams;

export type EstimateOvernightAction = {
  type: typeof ESTIMATE_OVERNIGHT,
  payload: EstimateOvernightPayload,
};

export const estimateOvernight: (
  EstimateOvernightParams
) => EstimateOvernightAction = createAction(
  ESTIMATE_OVERNIGHT,
  ({
    dogCount,
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    walkTypeId,
  }) => ({
    dogCount,
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    walkTypeId,
  }),
);


/**
 * Estimate an overnight Success
 */
export type EstimateOvernightSuccessAction = {
  type: typeof ESTIMATE_OVERNIGHT_SUCCESS,
  payload: any,
};

export const estimateOvernightSuccess: (
) => EstimateOvernightSuccessAction = createAction(
  ESTIMATE_OVERNIGHT_SUCCESS,
);


/**
 * Estimate an overnight Failure
 */
export type EstimateOvernightFailureAction = {
  type: typeof ESTIMATE_OVERNIGHT_FAILURE,
  payload: ErrorParsedResponse,
};

export const estimateOvernightFailure: (
  ErrorParsedResponse,
) => EstimateOvernightFailureAction = createAction(
  ESTIMATE_OVERNIGHT_FAILURE,
);
