import { useState, useEffect, useCallback } from "react";
import type { SwiperInstance } from "react-id-swiper";
export type UseSwiper = {
  swiper: SwiperInstance;
  // TODO - We can't get the proper type from `react` here
  updateSwiper: (...args: Array<any>) => any;
  currentIndex: number;
  utils: {
    handleSlidePrev: () => void;
    handleSlideNext: () => void;
    reset: () => void;
  };
};
export const useSwiper = (): UseSwiper => {
  const [swiper, updateSwiper] = useState<SwiperInstance>(null);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [swiper]);

  const handleSlidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleSlideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const reset = () => {
    updateCurrentIndex(0);
    swiper.slideTo(0);
  };

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', updateIndex);
    }

    return () => {
      if (swiper) {
        swiper.off('slideChange', updateIndex);
      }
    };
  }, [swiper, updateIndex]);
  return {
    swiper,
    updateSwiper,
    currentIndex,
    utils: {
      handleSlidePrev,
      handleSlideNext,
      reset
    }
  };
};