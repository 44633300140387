import { combineEpics } from "redux-observable";
import bookingTraining$ from "./bookingTraining/bookingTraining.epic";
import cancelSchedule$ from "./cancelSchedule/cancelSchedule.epic";
import editSchedule$ from "./editSchedule/editSchedule.epic";
import estimate$ from "./overnight/estimate.epic";
import meetGreet$ from "./meetGreet/meetGreet.epic";
import overnight$ from "./overnight/overnight.epic";
import setSchedule$ from "./setSchedule/setSchedule.epic";
import sittingQuestionnaire$ from "./getSittingQuestionnaire/getSittingQuestionnaire.epic";
import rebooking$ from "./rebooking/rebooking.epic";
export default combineEpics(bookingTraining$, cancelSchedule$, editSchedule$, estimate$, meetGreet$, overnight$, setSchedule$, sittingQuestionnaire$, rebooking$);