import {
  Helmet as ReactHelmet,
  HelmetProps as ReactHelmetProps,
} from 'react-helmet';

export type HelmetProps = ReactHelmetProps;
export const Helmet = ({
  children,
}: HelmetProps) => (
  <ReactHelmet
    titleTemplate="%s | Wag! Walking"
    defaultTitle="Wag! Walking"
  >
    {children}
  </ReactHelmet>
);
