
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
} from 'webApp';
import {
  BOOKING_EDIT_SCHEDULE,
  BOOKING_EDIT_SCHEDULE_SUCCESS,
  BOOKING_EDIT_SCHEDULE_FAILURE,
} from '../../actionTypes';

import type { BookingCancelScheduleParams } from '../bookingCancelSchedule';
import type { BookingSetScheduleParams } from '../bookingSetSchedule';

export type BookingEditScheduleParams = BookingCancelScheduleParams & BookingSetScheduleParams;

export type BookingEditSchedulePayload = BookingEditScheduleParams;

export type BookingEditScheduleAction = {
  type: typeof BOOKING_EDIT_SCHEDULE,
  payload: BookingEditSchedulePayload,
};

export type BookingEditScheduleSuccessAction = {
  type: typeof BOOKING_EDIT_SCHEDULE_SUCCESS,
  payload: BookingEditSchedulePayload,
};

export type BookingEditScheduleFailureAction = {
  type: typeof BOOKING_EDIT_SCHEDULE_FAILURE,
  payload: ErrorParsedResponse,
};

export const bookingEditSchedule: (
  BookingEditScheduleParams
) => BookingEditScheduleAction = createAction(
  BOOKING_EDIT_SCHEDULE,
);

export const bookingEditScheduleSuccess: (
  BookingEditSchedulePayload,
) => BookingEditScheduleSuccessAction = createAction(
  BOOKING_EDIT_SCHEDULE_SUCCESS,
);

export const bookingEditScheduleFailure: (
  ErrorParsedResponse,
) => BookingEditScheduleFailureAction = createAction(
  BOOKING_EDIT_SCHEDULE_FAILURE,
);
