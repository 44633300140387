import type { GetOwnerServicesRequestResponse, Service as ServiceWagApi } from "wagAPI";
import type { GetOwnerServicesParsedResponse, Service as ServiceWebApp } from "webApp";
export const parseOwnerService = (service: ServiceWagApi): ServiceWebApp => {
  const result: ServiceWebApp = {
    id: service.id,
    distance: service.distance,
    isCancelled: service.is_cancelled,
    isCompleted: service.is_completed,
    isConfirmed: service.is_confirmed,
    isDoorLocked: service.is_door_locked,
    isPee: service.is_pee,
    isPoo: service.is_poo,
    lengthMinutes: service.length_in_minutes,
    photoURL: service.photo_url,
    videoURL: service.video_url,
    note: service.note,
    tip: service.tip,
    total: service.total,
    schedule: {
      id: service.schedule.id,
      isRecurring: service.schedule.is_recurring
    },
    startDate: service.start_date,
    startTime: service.start_time,
    endDate: service.end_date,
    completedTime: service.completed_time,
    walker: service.walker,
    serviceType: service.service_type,
    serviceTypeDisplayName: service.service_type_display_name,
    dog: service.dog.map(dog => ({
      name: dog.name,
      age: dog.age,
      imageURL: dog.image_url,
      breed: dog.breed,
      id: dog.id
    }))
  };
  return result;
};
export const getOwnerServicesResponseParser = ({
  services
}: GetOwnerServicesRequestResponse): GetOwnerServicesParsedResponse => services.map((serviceItem: ServiceWagApi): ServiceWebApp => parseOwnerService(serviceItem));
export default getOwnerServicesResponseParser;