export const AUTHENTICATION_CODE_POST_REQUEST_KEY = 'postAuthenticationCode';
export const AUTHENTICATION_PASSWORD_POST_REQUEST_KEY = 'postAuthenticationPassword';
export const AUTHENTICATION_SESSION_GET_REQUEST_KEY = 'getAuthenticationSession';
export const AUTHENTICATION_SESSION_POST_REQUEST_KEY = 'postAuthenticationSession';
export const AUTHENTICATION_UPGRADE_POST_REQUEST_KEY = 'postAuthenticationUpgrade';
export const AUTHENTICATION_VERIFY_POST_REQUEST_KEY = 'postAuthenticationVerify';
export const BOOKING_CANCEL_SCHEDULE_REQUEST_KEY = 'bookingCancelSchedule';
export const BOOKING_EDIT_SCHEDULE_REQUEST_KEY = 'bookingEditSchedule';
export const BOOKING_MEET_GREET_REQUEST_KEY = 'bookingMeetGreet';
export const BOOKING_OVERNIGHT_REQUEST_KEY = 'bookingOvernight';
export const BOOKING_SET_SCHEDULE_REQUEST_KEY = 'bookingSetSchedule';
export const CREDIT_CARD_UPDATE_REQUEST_KEY = 'creditCardUpdate';
export const CREDIT_PACKAGES_REQUEST_KEY = 'packages';
export const DOG_BREED_ASSETS_REQUEST_KEY = 'dogBreedAssets';
export const DOG_CREATE_REQUEST_KEY = 'dogCreate';
export const DOG_DELETE_REQUEST_KEY = 'dogDelete';
export const DOG_GET_BREEDS_REQUEST_KEY = 'dogGetBreeds';
export const DOG_PROFILE_PATCH_REQUEST_KEY = 'patchDogProfile';
export const DOG_PROFILE_REQUEST_KEY = 'dog';
export const DOG_UPDATE_QUESTIONAIRE_REQUEST_KEY = 'dogUpdateQuestionaire';
export const DOG_UPDATE_V4_REQUEST_KEY = 'dogUpdateV4';
export const ESTIMATE_OVERNIGHT_REQUEST_KEY = 'estimateOvernight';
export const GET_DOGS_REQUEST_KEY = 'getDogs';
export const GET_WALK_COORDINATES_KEY = (walkID: string) => `walkCoordinates${walkID}`;
export const LOCKBOX_REQUEST_REQUEST_KEY = 'lockBoxRequest';
export const LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY = 'createLoginWithCodeRequest';
export const LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY = 'submitLoginWithCode';
export const MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY = 'myProfileUpdate';
export const OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY = 'registerOwnerApplyDetails';
export const OWNER_AUTH_REFRESH_REQUEST_KEY = 'refreshAuth';
export const OWNER_AVAILABLE_SERVICES_REQUEST_KEY = 'ownerAvailableServices';
export const OWNER_CREDIT_EXPIRATION_REQUEST_KEY = 'creditExpiration';
export const OWNER_CREDIT_REQUEST_KEY = 'ownerCredit';
export const OWNER_GET_REQUEST_KEY = 'ownerGet';
export const OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY = 'registerOwnerGoogleSignin';
export const OWNER_HOME_ACCESS_GET_REQUEST_KEY = 'ownerHomeAccessGet';
export const OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY = 'ownerHomeAccessUpdate';
export const OWNER_MASKED_INFO_GET_REQUEST_KEY = 'getOwnerMaskedInfo';
export const OWNER_PASSWORD_CHANGE_REQUEST_KEY = 'passwordChange';
export const OWNER_PASSWORD_RESET_REQUEST_KEY = 'ownerPasswordReset';
export const OWNER_PAYMENT_METHODS_GET_REQUEST_KEY = 'getOwnerPaymentMethods';
export const OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY = 'createOwnerPaymentMethod';
export const OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY = 'updateOwnerPaymentMethod';
export const OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY = 'addOwnerPreferredWalkerByPromoCode';
export const OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY = 'createOwnerPreferredWalker';
export const OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY = 'deleteOwnerPreferredWalker';
export const OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY = 'getOwnerPremiumSubscription';
export const OWNER_PROFILE_REQUEST_KEY = 'owner';
export const OWNER_REGISTRATION_ADDRESS_REQUEST_KEY = 'ownerRegistrationAddress';
export const OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY = 'registerOwnerBasicInfo';
export const OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY = 'ownerRegistrationPreferredServices';
export const OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY = 'ownerRegistrationSendLockbox';
export const OWNER_SERVICES_GET_REQUEST_KEY = 'getOwnerServices';
export const OWNER_SMART_MODULES_REQUEST_KEY = 'ownerSmartModules';
export const OWNER_TOKEN_JWT_REQUEST_KEY = 'loginToken';
export const OWNER_TOKEN_REQUEST_KEY = 'loginToken_deprecated';
export const OWNER_UPDATE_REQUEST_KEY = 'ownerUpdate';
export const PASSWORD_RESET_EMAIL_REQUEST_KEY = 'passwordResetEmail';
export const PASSWORD_RESET_REQUEST_KEY = 'passwordReset';
export const PASSWORD_TIPS_REQUEST_KEY = 'passwordTips';
export const PAST_PET_CAREGIVERS_GET_REQUEST_KEY = 'getPastPetCaregivers';
export const PREFERRED_WALKER_REQUEST_KEY = 'preferredWalker';
export const PROMO_CODE_REQUEST_KEY = 'promoCodeApply';
export const REBOOKING_REQUEST_KEY = 'rebooking';
export const REGISTER_REQUEST_KEY = 'registerForm';
export const REGISTRATION_REQUEST_KEY = 'registerForm';
export const SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY = 'checkServiceableZipCode';
export const SERVICE_PROVIDERS_GET_REQUEST_KEY = 'getServiceProviders';
export const SITTING_QUESTIONNAIRE_REQUEST_KEY = 'sittingQuestionnaire';
export const SUBSCRIBE_TO_PREMIUM_REQUEST_KEY = 'subscribeToPremium';
export const TRAINING_BOOKING_REQUEST_KEY = 'bookingTraining';
export const UPDATE_WALKER_PASSWORD_REQUEST_KEY = 'updateWalkerPassword';
export const VERIFY_PASSWORD_REQUEST_KEY = 'verifyPassword';
export const VISITOR_ACCOUNT_CREATE_REQUEST_KEY = 'createVisitorAccount';
export const WALKER_CONTACT_INFO_REQUEST_KEY = 'walkerContactInfo';
export const WALKER_GET_REQUEST_KEY = 'walkerGet';
export const WALKER_PASSWORD_RESET_REQUEST_KEY = 'walkerPasswordReset';
export const WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY = 'getWalkerProfileByPromoCode';
export const WALKER_PROFILE_GET_REQUEST_KEY = (walkerID: string) => `walkerProfile${walkerID}`;
export const WALKER_PROFILE_REQUEST_KEY = 'walkerProfile';
export const WALKER_SIGN_IN_REQUEST_KEY = 'walkerStripeConnectSignIn';
export const WALKER_SIGN_UP_REQUEST_KEY = 'walkerStripeConnectSignUp';
export const WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY = 'walkerStripeConnectGetRedirectUrl';
export const WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY = 'walkerStripeConnectSignUpGetRedirectUrl';
export const WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_REQUEST_KEY = 'walkerStripeConnectVerifyMigration';
export const WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY = 'walkerStripeConnectVerify';
export const WALKER_WALK_GET_REQUEST_KEY = 'walkerWalkGet';
export const WALKS_IN_PAST_REQUEST_KEY = 'walksPast';
export const WALKS_IN_PROGRESS_REQUEST_KEY = 'walksInProgress';
export const WALKS_PENDING_REVIEW_REQUEST_KEY = 'walksPendingReview';
export const WALKS_REQUEST_KEY = 'walks';
export const WALKS_UPCOMING_REQUEST_KEY = 'walksUpcoming';
export const WALK_COVER_CREATE_REQUEST_KEY = 'walkCoverCreate';
export const WALK_DETAILS_GET_REQUEST_KEY = 'walkDetails';
export const WALK_RATE_AND_TIP_REQUEST_KEY = 'walkRateAndTip';
export const WALK_SCHEDULE_UPDATE_REQUEST_KEY = 'createSchedule';
export const WALK_TYPES_REQUEST_KEY = 'walkTypes';
export type RequestKey =
  | typeof BOOKING_CANCEL_SCHEDULE_REQUEST_KEY
  | typeof AUTHENTICATION_CODE_POST_REQUEST_KEY
  | typeof AUTHENTICATION_PASSWORD_POST_REQUEST_KEY
  | typeof AUTHENTICATION_SESSION_GET_REQUEST_KEY
  | typeof AUTHENTICATION_SESSION_POST_REQUEST_KEY
  | typeof AUTHENTICATION_UPGRADE_POST_REQUEST_KEY
  | typeof AUTHENTICATION_VERIFY_POST_REQUEST_KEY
  | typeof BOOKING_EDIT_SCHEDULE_REQUEST_KEY
  | typeof BOOKING_MEET_GREET_REQUEST_KEY
  | typeof BOOKING_OVERNIGHT_REQUEST_KEY
  | typeof BOOKING_SET_SCHEDULE_REQUEST_KEY
  | typeof CREDIT_CARD_UPDATE_REQUEST_KEY
  | typeof CREDIT_PACKAGES_REQUEST_KEY
  | typeof DOG_BREED_ASSETS_REQUEST_KEY
  | typeof DOG_CREATE_REQUEST_KEY
  | typeof DOG_DELETE_REQUEST_KEY
  | typeof DOG_GET_BREEDS_REQUEST_KEY
  | typeof DOG_PROFILE_PATCH_REQUEST_KEY
  | typeof DOG_PROFILE_REQUEST_KEY
  | typeof GET_DOGS_REQUEST_KEY
  | typeof ESTIMATE_OVERNIGHT_REQUEST_KEY
  | typeof GET_WALK_COORDINATES_KEY
  | typeof LOCKBOX_REQUEST_REQUEST_KEY
  | typeof LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY
  | typeof LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY
  | typeof LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY
  | typeof LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY
  | typeof MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY
  | typeof OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY
  | typeof OWNER_AUTH_REFRESH_REQUEST_KEY
  | typeof OWNER_AVAILABLE_SERVICES_REQUEST_KEY
  | typeof OWNER_CREDIT_EXPIRATION_REQUEST_KEY
  | typeof OWNER_CREDIT_REQUEST_KEY
  | typeof OWNER_GET_REQUEST_KEY
  | typeof OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY
  | typeof OWNER_HOME_ACCESS_GET_REQUEST_KEY
  | typeof OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY
  | typeof OWNER_MASKED_INFO_GET_REQUEST_KEY
  | typeof OWNER_PASSWORD_CHANGE_REQUEST_KEY
  | typeof OWNER_PASSWORD_RESET_REQUEST_KEY
  | typeof OWNER_PAYMENT_METHODS_GET_REQUEST_KEY
  | typeof OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY
  | typeof OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY
  | typeof OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY
  | typeof OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY
  | typeof OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY
  | typeof OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY
  | typeof OWNER_PROFILE_REQUEST_KEY
  | typeof OWNER_REGISTRATION_ADDRESS_REQUEST_KEY
  | typeof OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY
  | typeof OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY
  | typeof OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY
  | typeof OWNER_SERVICES_GET_REQUEST_KEY
  | typeof OWNER_SMART_MODULES_REQUEST_KEY
  | typeof OWNER_TOKEN_JWT_REQUEST_KEY
  | typeof OWNER_TOKEN_REQUEST_KEY
  | typeof OWNER_UPDATE_REQUEST_KEY
  | typeof PASSWORD_RESET_EMAIL_REQUEST_KEY
  | typeof PASSWORD_RESET_REQUEST_KEY
  | typeof PASSWORD_TIPS_REQUEST_KEY
  | typeof PAST_PET_CAREGIVERS_GET_REQUEST_KEY
  | typeof PREFERRED_WALKER_REQUEST_KEY
  | typeof PROMO_CODE_REQUEST_KEY
  | typeof REBOOKING_REQUEST_KEY
  | typeof REGISTER_REQUEST_KEY
  | typeof REGISTRATION_REQUEST_KEY
  | typeof SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY
  | typeof SERVICE_PROVIDERS_GET_REQUEST_KEY
  | typeof SITTING_QUESTIONNAIRE_REQUEST_KEY
  | typeof SUBSCRIBE_TO_PREMIUM_REQUEST_KEY
  | typeof TRAINING_BOOKING_REQUEST_KEY
  | typeof UPDATE_WALKER_PASSWORD_REQUEST_KEY
  | typeof VERIFY_PASSWORD_REQUEST_KEY
  | typeof WALKER_CONTACT_INFO_REQUEST_KEY
  | typeof WALKER_GET_REQUEST_KEY
  | typeof WALKER_PASSWORD_RESET_REQUEST_KEY
  | typeof WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY
  | typeof WALKER_PROFILE_REQUEST_KEY
  | typeof WALKER_SIGN_IN_REQUEST_KEY
  | typeof WALKER_SIGN_UP_REQUEST_KEY
  | typeof WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY
  | typeof WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY
  | typeof WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_REQUEST_KEY
  | typeof WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY
  | typeof WALKS_IN_PAST_REQUEST_KEY
  | typeof WALKS_IN_PROGRESS_REQUEST_KEY
  | typeof WALKS_PENDING_REVIEW_REQUEST_KEY
  | typeof WALKS_REQUEST_KEY
  | typeof WALKS_UPCOMING_REQUEST_KEY
  | typeof WALK_DETAILS_GET_REQUEST_KEY
  | typeof WALK_RATE_AND_TIP_REQUEST_KEY
  | typeof WALK_SCHEDULE_UPDATE_REQUEST_KEY
  | typeof WALK_TYPES_REQUEST_KEY;
