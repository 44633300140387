import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import moment from "moment-timezone";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_SERVICES_GET, Endpoint } from "../../../constants/endpoints";
import { OWNER_SERVICES_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getOwnerServicesSuccess, getOwnerServicesFailure } from "../../../actions/owner/getOwnerServices";
import { getOwnerServicesResponseParser } from "../../../helpers/Http/ResponseParsers";

const getOwnerServices$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_SERVICES_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_SERVICES_GET({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    start_datetime: moment().subtract(1, 'day').tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm a z'),
    end_datetime: moment().add(3, 'months').tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm a z') // to 3 months ahead

  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getOwnerServicesSuccess,
  onFailure: getOwnerServicesFailure,
  parser: getOwnerServicesResponseParser
})), catchError(handleErrorV5Response(action$, getOwnerServicesFailure)))));

export default getOwnerServices$;