import { handleActions } from 'redux-actions';

import {
  DOG_BREEDS_CLEAR,
  DOG_BREEDS_GET_SUCCESS,
} from '../../actions/actionTypes';
import type { GetDogBreedsSuccessAction } from '../../actions/dog/getDogBreeds';

export type DogBreedsState = Array<string>;
export const initialState: DogBreedsState = [];
export const reducer: any = handleActions({
  [DOG_BREEDS_GET_SUCCESS]: (state: DogBreedsState, {
    payload: {
      breeds,
    },
  }: GetDogBreedsSuccessAction) => breeds,
  [DOG_BREEDS_CLEAR]: () => initialState,
}, initialState);
export default reducer;
