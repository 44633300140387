import { flatMap, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { WalkerGetWalkResponse, GetWalkerWalkRequestPayload } from "wagAPI";
import type { GetWalkerWalkParsedResponse } from "webApp";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_SCHEDULE_GET } from "../../../constants/endpoints";
import { WALKER_SCHEDULE_GET } from "../../../actions/actionTypes";
import { getWalkerWalkSuccess, getWalkerWalkFailure, GetWalkerWalkAction } from "../../../actions/walker/getWalkerWalk";

const parseResponse: (arg0: WalkerGetWalkResponse) => GetWalkerWalkParsedResponse = response => ({
  dogName: response.walk_data.dog.name,
  dogImageUrl: response.walk_data.dog.image_url,
  sittingBoardingStart: response.walk_data.sitting ? response.walk_data.sitting.start_date : null,
  sittingBoardingEnd: response.walk_data.sitting ? response.walk_data.sitting.end_date : null,
  walkType: response.walk_data.walk_type_id,
  walkDate: response.walk_data.date,
  startTime: response.walk_data.start_time,
  walkerSupportPhone: response.walk_data.crm_phone_nr
});

const getWalkerWalk$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKER_SCHEDULE_GET), switchMap(({
  payload: {
    id,
    token,
    walkId
  }
}: GetWalkerWalkAction) => get$(({
  endpoint: ENDPOINT_WALKER_SCHEDULE_GET,
  token,
  payload: {
    id,
    walk_id: walkId
  }
} as {
  endpoint: typeof ENDPOINT_WALKER_SCHEDULE_GET;
  token: string;
  payload: GetWalkerWalkRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerWalkSuccess,
  onFailure: getWalkerWalkFailure,
  parser: parseResponse
})), catchError(handleErrorV5Response(action$, getWalkerWalkFailure)))));

export default getWalkerWalk$;