import React, {
  Suspense,
  useEffect,
} from 'react';

import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import queryString from 'query-string';
import BrowserDetection from 'react-browser-detection';
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle as BaseThemeGlobalStyle } from '@waglabs/base-theme';
import Page from '@waglabs/page';
import { GlobalStyle } from '@waglabs/webapp-theme';
import WithStripe from '@waglabs/with-stripe';

import './styles/styles.css';
import '@waglabs/bootstrap/dist/css/bootstrap.css';

import { asyncComponent } from './components/AsyncComponent';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorReporterProvider from './components/ErrorBoundary/ErrorReporterProvider';
import { Helmet } from './components/Helmet';
import NavTrigger from './components/NavTrigger/NavTrigger';
import { SegmentPageTrigger } from './components/SegmentPageTrigger';
import Track, { TrackerProvider } from './components/Tracker';
import FacebookPixel from './components/Tracker/Facebook/FacebookPixel';
import { GoogleTagManager } from './components/Tracker/GoogleTagManager';
import { WagRedirect } from './components/WagRedirect';
import { config } from './config';
import {
  FEATURE_DEV_SELECTOR_ENABLED,
  QS_REDIRECT_URL_KEY,
  Routes,
} from './constants';
import {
  ROUTE_APP,
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_SIGNUP_V1,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_UNSERVICEABLE,
  ROUTE_DEV_SELECTOR,
} from './constants/routes';
import {
  URL_CHROME_DOWNLOAD,
  URL_FIREFOX_DOWNLOAD,
  WagWalkingRedirects,
} from './constants/urls';
import BrowserID from './containers/BrowserID';
import CaptureParams from './containers/CaptureParams';
import Notifications from './containers/Notifications/NotificationContainer/NotificationContainer';
import Auth from './helpers/Auth';
import { isInEmbed } from './hooks/useIsInEmbed';
import { ReduxState } from './reducers';
import { getBrowserID } from './selectors';

// This is for code splitting so our application isn't downloaded all in one go, do not remove!
// Components need to be default exports for Routing to work
const AndroidCoverWebview = asyncComponent(() => import('./containers/AndroidCoverWebview'));
const ForgotPassword = asyncComponent(() => import('./containers/ForgotPassword'));
const LoggedInContainer = asyncComponent(() => import('./containers/LoggedInContainer'));
const Login = asyncComponent(() => import('./containers/Login'));
const PasswordRecovery = asyncComponent(() => import('./containers/PasswordRecovery/PasswordRecovery'));
const Unserviceable = asyncComponent(() => import('./components/Unserviceable/Unserviceable'));
const WalkerStripeConnect = asyncComponent(() => import('./containers/WalkerStripeConnect/WalkerStripeConnect'));
const WalkerSignUp = asyncComponent(() => import('./containers/WalkerDashboard/components/WalkerSignup/WalkerSignUpFormContainer'));
const WalkerSignIn = asyncComponent(() => import('./containers/WalkerDashboard/components/WalkerSignIn/WalkerSignInFormContainer'));
const Welcome = asyncComponent(() => import('./components/Welcome'));
const DevSelectorPageContainer = asyncComponent(() => import('./containers/DevSelectorPageContainer'));
const WellnessPageContainer = asyncComponent(() => import('./containers/WellnessPageContainer'));
const WellnessPremiumPageContainer = asyncComponent(() => import('./containers/WellnessPremiumPageContainer'));
const WellnessRecommendationPageContainer = asyncComponent(() => import('./containers/WellnessRecommendationPageContainer'));
const VetChatPageContainer = asyncComponent(() => import('./containers/VetChatPageContainer'));
const PremiumPageContainer = asyncComponent(() => import('./containers/PremiumPageContainer'));

// Components imported through config are toggled based on environments
const SignUp = config.signupComponent;

type Props = {
  browserID: string,
};

type DefaultProps = {
  ptoken: string,
}

const renderLoggedOut = ({
  location: {
    pathname,
  },
}) => {
  const queryStringToAttach = queryString.stringify({
    [QS_REDIRECT_URL_KEY]: pathname,
  });

  return (
    <Switch>
      <Route
        exact
        path={ROUTE_APP}
        component={Welcome}
      />

      <WagRedirect
        to={{
          path: ROUTE_LOGIN,
          search: queryStringToAttach,
        }}
      />
    </Switch>
  );
};

const shouldRedirect = (pathname: string) => {
  if (WagWalkingRedirects[pathname]) {
    window.location.href = WagWalkingRedirects[window.location.pathname];
  };
};

const renderTracked = () => (
  <ErrorBoundary>
    {
      /**
       * lazy components should be rendered inside a `Suspense` component, which
       * allows us to show some fallback content (such as a loading indicator)
       * while we’re waiting for the lazy component to load.
       */
    }
    <Suspense fallback={<div />}>
      <Route
        path={ROUTE_APP}
        render={({ location }) => <CaptureParams location={location} />}
      />
      <Switch>
        <Route
          path="/android-cover-walker-schedule"
          render={() => <AndroidCoverWebview />}
        />
        {/* Legacy routes */}
        <Route
          path="/login/login"
          render={() => <Redirect to="/login" />}
        />
        <Route
          path="/login/forgot-password"
          render={() => <Redirect to="/forgot-password" />}
        />
        {/* end legacy routes */}

        <Route
          path="/walker/stripe-connect"
          component={WalkerStripeConnect}
        />
        <Route
          path={Routes.WalkerSignUp}
        >
          <WalkerSignUp />
        </Route>

        <Route
          path={Routes.WalkerSignIn}
        >
          <WalkerSignIn />
        </Route>

        <Route
          path="/login/reset-password/:userType/*/:userID/:resetToken"
          render={({ match }) => (
            <PasswordRecovery
              userType={match.params.userType}
              userId={match.params.userID}
              resetToken={match.params.resetToken}
            />
          )}
        />

        <Route
          exact
          path={ROUTE_LOGIN}
          component={Login}
        />

        <Route
          path={ROUTE_SIGNUP}
          render={(routerProps) => (
            <SignUp
              {...routerProps}
            />
          )}
        />

        <Route
          path={ROUTE_SIGNUP_V1}
          render={() => (
            <Redirect to={ROUTE_SIGNUP} />
          )}
        />

        <Route
          path={ROUTE_FORGOT_PASSWORD}
          component={ForgotPassword}
        />

        <Route
          path={ROUTE_UNSERVICEABLE}
          render={({ history }) => (
            <Page
              width="100%"
              maxWidth="600px"
              m="0 auto"
            >
              <Unserviceable
                history={history}
              />
            </Page>
          )}
        />

        {FEATURE_DEV_SELECTOR_ENABLED && (
          <Route
            path={ROUTE_DEV_SELECTOR}
          >
            <DevSelectorPageContainer />
          </Route>
        )}

        {/*
          Wellness needs to be check first before the main wellness container
          to avoid route match conflicts
        */}

        <Route
          path={Routes.WellnessPremium}
        >
          <>
            <Helmet>
              <title>Wellness Premium Plans</title>
            </Helmet>
            <WellnessPremiumPageContainer />
          </>
        </Route>

        <Route
          path={Routes.Wellness}
        >
          <>
            <Helmet>
              <title>Wellness Plans</title>
            </Helmet>
            <WellnessPageContainer />
          </>
        </Route>

        <Route
          path={Routes.WagHealth}
        >
          <>
            <Helmet>
              <title>Wellness Premium Plans</title>
            </Helmet>
            <VetChatPageContainer />
          </>
        </Route>

        <Route
          path={Routes.WellnessRecommendation}
        >
          <>
            <Helmet>
              <title>Wellness Recommendations</title>
            </Helmet>
            <WellnessRecommendationPageContainer />
          </>
        </Route>

        <Route
          path={Routes.Premium}
        >
          <>
            <Helmet>
              <title>Premium</title>
            </Helmet>
            <PremiumPageContainer />
          </>
        </Route>

        <Route
          path={ROUTE_APP}
          render={({ location, match, history }) => {
            if (location.hash.slice(0, 2) === '#/') { // legacy routes begin with a hash
              // strip hash and proceed to hash-less route
              return <Redirect to={location.hash.slice(2)} />;
            } if (location.search.includes('start=1')) { // legacy signup flows used start = 1
              return <Redirect to={`/signup${location.search}`} />; // return redirect and include search params
            }

            shouldRedirect(location.pathname);

            return (
              <Auth renderLoggedOut={renderLoggedOut}>
                <LoggedInContainer
                  location={location}
                  match={match}
                  history={history}
                />
              </Auth>
            );
          }}
        />

        <Redirect to={ROUTE_APP} />
      </Switch>
    </Suspense>
    <NavTrigger />
    <Notifications />
    <FacebookPixel />
    <SegmentPageTrigger />
  </ErrorBoundary>
);

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

export const SeamlessTokenContext = React.createContext<string>('');

const App = ({
  browserID,
}: Props) => (
  <>
    <GoogleTagManager />
    <BaseThemeGlobalStyle theme={config.theme} />
    <GlobalStyle theme={config.theme} />
    <ThemeProvider theme={config.theme}>
      <LocalizationProvider
        dateAdapter={MomentUtils}
      >
        <ErrorReporterProvider>
          <WithStripe>
            <BrowserID>
              <BrowserRouter
                {...isInEmbed && {
                  basename: Routes.Embed,
                }}
              >
                <ScrollToTop>
                  <TrackerProvider browserID={browserID}>
                    <Track>
                      {renderTracked}
                    </Track>
                  </TrackerProvider>
                </ScrollToTop>
              </BrowserRouter>
            </BrowserID>
          </WithStripe>
        </ErrorReporterProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </>
);

const ConnectedApp = connect((state: ReduxState) => ({
  browserID: getBrowserID(state) || '',
}))(App);

const browserHandler = {
  ie: () => (
    <div className="text-center">
      <p>
        Sorry, we do not support IE 11 or below. Please consider upgrading your browser to
        {' '}
        <a href={URL_CHROME_DOWNLOAD}>Chrome</a>
        {' '}
        or
        {' '}
        <a href={URL_FIREFOX_DOWNLOAD}>Firefox</a>
        .
      </p>
    </div>
  ),
  default: () => <ConnectedApp />,
};

const AppWithBrowserHandler = ({ ptoken }: DefaultProps) => (
  <SeamlessTokenContext.Provider value={ptoken}>
    <BrowserDetection>
      {browserHandler}
    </BrowserDetection>
  </SeamlessTokenContext.Provider>
);

export default AppWithBrowserHandler;
