import React from 'react';
import styled from 'styled-components';

import {
  color,
} from 'styled-system';

type Props = React.SVGProps<SVGSVGElement> & SVGAccessibility;

const StyledSvg = styled.svg`
  ${color};

  &:hover {
    cursor: ${props => (props.hover ? 'pointer' : 'auto')};
  }
`;

// prettier-ignore
const Arrow = (props: Props) => {
  const {
    title,
    role = 'presentation',
  } = props;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.92 20.67"
      aria-labelledby="title"
      role={role}
      {...props}
    >
      <title id="title">{title}</title>
      <path
        fill="currentColor"
        d="M2.58 20.68a2.54 2.54 0 0 1-1.82-.76 2.58 2.58 0 0 1 0-3.65l5.92-5.93L.76 4.42A2.584 2.584 0 1 1 4.41.76l7.75 7.75a2.59 2.59 0 0 1 0 3.66l-7.75 7.75a2.56 2.56 0 0 1-1.83.76"
      />
    </StyledSvg>
  );
};

export default Arrow;
