import { handleActions } from 'redux-actions';
import type { GetWalkerProfileParsedResponse } from 'webApp';

import { WALKER_PROFILE_GET_SUCCESS } from '../../actions/actionTypes';
import type { GetWalkerProfileSuccessAction } from '../../actions/walker/getWalkerProfile';
import replaceOrAddItemToArray from '../../utils/replaceOrAddItemToArray';

export type State = GetWalkerProfileParsedResponse[];
export const initialState: State = [];
export const reducer: any = handleActions({
  [WALKER_PROFILE_GET_SUCCESS]: (state: State, {
    payload,
  }: GetWalkerProfileSuccessAction): State => replaceOrAddItemToArray({
    items: state,
    item: payload,
    findItemCallback: (walker) => walker.id === payload.id,
    addToBeginning: true,
  }).slice(0, 30),
}, initialState);
export default reducer;
