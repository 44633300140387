import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { CreateOwnerPaymentMethodRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_PAYMENT_METHOD_CREATE, Endpoint } from "../../../constants/endpoints";
import { OWNER_PAYMENT_METHOD_CREATE } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { createOwnerPaymentMethodSuccess, createOwnerPaymentMethodFailure, CreateOwnerPaymentMethodAction } from "../../../actions/owner/createOwnerPaymentMethod";
import { ownerPaymentMethodResponseParser } from "../../../helpers/Http/ResponseParsers";

const createOwnerPaymentMethod$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_PAYMENT_METHOD_CREATE), switchMap(({
  payload: {
    stripeToken
  }
}: CreateOwnerPaymentMethodAction) => post$(({
  endpoint: ENDPOINT_OWNER_PAYMENT_METHOD_CREATE({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    token: stripeToken
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: CreateOwnerPaymentMethodRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: createOwnerPaymentMethodSuccess,
  onFailure: createOwnerPaymentMethodFailure,
  parser: ownerPaymentMethodResponseParser
})), catchError(handleErrorV5Response(action$, createOwnerPaymentMethodFailure)))));

export default createOwnerPaymentMethod$;