import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const phoneSchema = Yup
  .string()
  .required()
  .default('')
  .test('phone-test', 'Please enter a valid phone number', (value) => (
    isPossiblePhoneNumber(value)
  ));
