import { camelizeKeys } from 'humps';

import type {
  DeprecatedV5DogResponse,
  V5DogResponse,
} from 'wagAPI';
import type {
  V5Dog,
  DeprecatedV5Dog,
} from 'webApp';

export const deprecatedParseV5Dog = ({
  age,
  breed,
  id,
  imageUrl,
  name,
}: DeprecatedV5DogResponse): DeprecatedV5Dog => ({
  yearsOld: age,
  breed,
  dogID: id,
  imageURL: imageUrl,
  name,
});

export const parseV5Dog = (
  response: V5DogResponse[],
): V5Dog[] => {
  const parsedResponse = camelizeKeys(response);
  return parsedResponse.map(item => ({
    ...item,
    monthsOld: item.ageMonths,
    yearsOld: item.age,
    dogID: item.id,
  }));
};
