import {
  pick,
  map,
  head,
  pipe,
} from 'ramda';

/**
 *
 * This is similar to ramda's `pick` function
 * whereas you provide a set of keys and an object on where
 * to get the given property key
 *
 * `pickQueryStrings` is a function that accepts
 * a set of query string keys (1st arg) that will be used
 * as the key extractor from the provided object (2nd arg)
 *
 * After picking the values using the query string keys,
 * we determine if the retrieved value is an array or a literal string
 * If it's an array, we only retrieve the first item using the `head` function.
 *
 * The reason we check for array values is because
 * if the URL consist of duplicate keys, it returns an array
 */
export const pickQueryStrings = (
  queryStringKeys: readonly string[],
  queryString: Record<string, unknown>,
) => {
  // TODO - Fix typings of the `pipe` fn
  // TS is intercepting it incorrectly
  const result = pipe<any, any, any>(
    pick(queryStringKeys),
    map((queryStringValue) => (
      Array.isArray(queryStringValue)
        ? head(queryStringValue)
        : queryStringValue
    )),
  )(queryString);
  return result;
};
