// @flow
import faker from 'faker';
import type {
  DogBreedAssets,
  FormErrorMessages,
  WalkInProgress,
  WalkInThePast,
  WalkUpcoming,
} from 'webApp';

import {
  WALK_TYPE_REGULAR_WALK,
  WALK_TYPE_DELUXE_WALK,
  WALKER_STRIPE_CONNECT_STATUS_COMPLETED,
  WalkType,
  WALK_TYPE_DROP_IN,
} from '../constants/app';
import type { RequestKey } from '../constants/requestKeys';
import { WalkTypes } from '../constants/walkTypes';
import type { ReduxState as State } from '../reducers';
import { getServicesWithPremium } from '../reducers/owner';
import { webApp } from '../types/webapp';
import { isObjEmpty } from '../utils';

export { getApprovalTimeEstimate } from './getApprovalTimeEstimate';

/**
 * DEPRECIATION WARNING
 *
 * Please do not add new store selectors to this file, instead follow the steps outlined below
 *
 * 1. Create a directory for reducer you are pulling data from if it doesnt already exist
 * 2. Create a directory that corresponds to the selector name within that reducer directory
 * 3. Place selector source code within the selector named directory
 * 4. Create unit tests for the selector within the selector named directory
 * 5. Export source code from this index.js file
 *
 * These steps will help us migrate selectors to co-locate with reducers at a later time,
 * follow SRP, scale, and unit test our selectors.
 *
 * FIXME - Migrate all selectors to be co-located with reducers as they are tightly coupled
 */

export {
  getSignupFlowSource,
  getUTMS,
} from './queryParams';

/*
 * =======================================
 * Please do not add more selectors here
 * =======================================
 */
export const getRequestIsFetching = (
  state: State,
  requestKey: RequestKey,
) => state.requests[requestKey] && state.requests[requestKey].isFetching;

export const getRequestIsFetched = (
  state: State,
  requestKey: RequestKey,
) => state.requests[requestKey] && state.requests[requestKey].isFetched;

export const getRequestError = (
  state: State,
  requestKey: RequestKey,
) => state.requests[requestKey] && state.requests[requestKey].error;

export const getRequestIsSuccess = (state: State, requestKey: RequestKey) => {
  const request = state.requests[requestKey];
  return request && !request.error && request.isFetched;
};

export const getRequestErrorCode = (state: State, requestKey: RequestKey) => {
  if (getRequestError(state, requestKey)) {
    return state.requests[requestKey].errorCode;
  }
  return '';
};

export const getRequestErrorMessage = (state: State, requestKey: RequestKey) => {
  if (getRequestError(state, requestKey)) {
    return state.requests[requestKey].errorMessage;
  }
  return '';
};
/**
 * @param  {State} state
 * @param  {RequestKey} requestKey
 * @returns FormErrorMessages
 */
export const getRequestErrorMessages = (
  state: State,
  requestKey: RequestKey,
): FormErrorMessages => {
  try {
    return state.requests[requestKey].errorMessages;
  } catch (e) {
    return {};
  }
};

export const getRequestErrorMessageCreditCard = (state: State, requestKey: RequestKey) => {
  if (getRequestError(state, requestKey)) {
    return (
      state.requests[requestKey]
      && state.requests[requestKey].error
      && 'An error occurred while processing the card.'
    );
  }
  return '';
};

export const getIsCCSuccessfullyAdded = (state: State) => state.requests.creditCardUpdate
  && state.requests.creditCardUpdate.isCardInvalid === false;

export const getCreditBalance = ({
  owner,
}: State) => (owner.creditBalance || 0) + (owner.temporaryCreditBalance || 0);

export const getCreditBalanceMinusTotalServices = (state: State) => {
  /* Check if there are any pending walks that
    amount to greater than the credit balance and subtract
    it from the credit balance if so
  */
  let creditBalance = getCreditBalance(state) || 0;

  const costOfAllPendingWalks = getCostOfAllPendingWalks(state) || 0;
  if (costOfAllPendingWalks > creditBalance) {
    creditBalance = 0;
  }

  if (costOfAllPendingWalks < creditBalance) {
    creditBalance -= costOfAllPendingWalks;
  }

  return (creditBalance || 0);
};

export const getDog = (state: State) => state.dog && state.dog.dog;
export const getDogSummary = (state: State) => state.owner && state.owner.dog;

export const getOwner = (state: State): webApp.Owner => state.owner;

/**
 * Determines if there currently is a dog
 *
 * @param  {State} state
 * @returns boolean
 */
export const getDogExists = (state: State) => Boolean(getDog(state).dogID);

export const getServices = (state: State) => state.services;

export const getWalkTypes = (state: State) => {
  // merge hardcoded values prioritizing redux values
  const filtered = WalkTypes.filter((wt) => !state.services.walkTypes.find((t2) => t2.id === wt.id));
  const merged = state.services.walkTypes.concat(filtered);
  const sortedWalkTypes = merged.sort((a, b) => +a.id - +b.id);
  const servicesWithPremium = getServicesWithPremium(state);

  // We need to accomodate if the owner is subscribed or not and use the services there
  const walkTypesWithNewPrices = sortedWalkTypes.map((walk) => {
    const serviceWithPremium = servicesWithPremium.find((service) => service.walkTypeId === walk.id);
    if (!serviceWithPremium) {
      return walk;
    }
    return {
      ...walk,
      price: serviceWithPremium.price,
    };
  });

  return walkTypesWithNewPrices;
};

export const getWalkType = (
  state: State,
  walkTypeID: WalkType,
) => getWalkTypes(state).find((walkType) => walkType.id === walkTypeID);

export const getWalkerProfile = (
  state: State,
  walkerID: string,
) => state.walkers.find((walker) => walker.id === walkerID);

export const getWalker = (state: State) => state.walker && state.walker.walker;

/**
 * Gets the authenticated walker
 *
 * @param  {State} state
 * @returns Walker
 */
export const getAuthWalker = (state: State) => state.walker.walker;

export const getWalkerWalkReview = (state: State) => state.walkers && state.walker;

export const getWalkers = (state: State) => state.walkers;

export const getWalksPendingReview = (state: State) => state.walks.walksPendingReview;

export const getCreditPackages = (state: State) => state.services.packages;

// export const getWalk = (state: State) => (state && state.walk ? state.walk : {});

/**
 * Is the checked serviceable zip code serviceable
 *
 * @param  {State} state
 * @returns boolean
 */
export const isZipCodeServiceable = (
  state: State,
): boolean => state.serviceableZipCode.isServiceable;

export const getWalksInProgress = (
  state: State,
): WalkInProgress[] => state.walks.walksInProgress;

export const getWalksInThePast = (
  state: State,
): WalkInThePast[] => state.walks.walksInThePast;

export const getWalksUpcoming = (
  state: State,
): WalkUpcoming[] => state.walks.walksUpcoming;

export const getWalksPendingConfirmation = (
  state: State,
): WalkUpcoming[] => state.walks.walksPendingConfirmation;

export const getCostOfAllPendingWalks = (
  state: State,
): number => {
  const upcomingWalks = getWalksUpcoming(state);
  const walksInProgress = getWalksInProgress(state);
  const walksPendingConfirmation = getWalksPendingConfirmation(state);
  const allFutureWalks = [...upcomingWalks, ...walksInProgress, ...walksPendingConfirmation];
  return allFutureWalks.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
};

export const getHasBooked = (state: State) => {
  const hasLastWalkScheduled = (
    typeof state.walks.lastWalkScheduled === 'object'
    && state.walks.lastWalkScheduled.startTime
    && state.walks.lastWalkScheduled.startDate
  ) || false;

  return getWalksPendingConfirmation(state).length > 0
    || getWalksUpcoming(state).length > 0
    || getWalksInProgress(state).length > 0
    || hasLastWalkScheduled;
};

export const getLastWalkScheduled = (state: State) => state.walks.lastWalkScheduled;

export const getSupportedWalkTypes = (state: State) => [
  getWalkType(state, WALK_TYPE_REGULAR_WALK),
  getWalkType(state, WALK_TYPE_DELUXE_WALK),
];

export const getSupportedDropinsTypes = (state: State) => [
  getWalkType(state, WALK_TYPE_DROP_IN),
];

export const getHasValidCreditCard = (state: State) => state.owner.stripeCard.brand
    && state.owner.stripeCard.lastFourDigits
    && state.owner.stripeCard.expirationMonth
    && state.owner.stripeCard.expirationYear;

export const getPaymentMethods = (state: State) => {
  if (!state.owner.paymentMethods || !state.owner.paymentMethods.length) {
    return 'No card on file';
  }

  return state.owner.paymentMethods;
};

export const getRequestStates = (state: State, requestKey: RequestKey) => ({
  isFetching: getRequestIsFetching(state, requestKey),
  error: getRequestError(state, requestKey),
  errorCode: getRequestErrorCode(state, requestKey),
  isSuccess: getRequestIsSuccess(state, requestKey),
  errorMessage: getRequestErrorMessage(state, requestKey),
  errorMessages: getRequestErrorMessages(state, requestKey),
});

export const getOwnerToken = (state: State) => state.auth.ownerToken;
export const getLegacyOwnerToken = (state: State) => state.auth.legacyToken;

export const getOwnerID = (state: State): string => state.auth.ownerID;

export const getOwnerUUID = (state: State) => state.auth.ownerUUID;

/**
 * Gets auth params from state
 *
 * @param {State} state Application State
 * @returns { ownerID: string, token: string }
 */
export const getAuthParams = (state: State): { ownerID: string, token: string } => ({
  ownerID: getOwnerID(state),
  token: getOwnerToken(state),
});

// TODO - remove these eslints
// eslint-disable-next-line max-len
// export const getBrowserID = (state: State) => String(Math.floor(Math.random()*(999-100+1)+100)) + state.auth.browserID.slice(3);
// eslint-disable-next-line no-unused-vars
export const getBrowserID = (state: State) => null;

// eslint-disable-next-line no-unused-vars
export const getDeviceInformationHeaders = (state: State) => ({
  'X-APP-VERSION': '1.21',
  'X-DEVICE-TYPE': 'web',
  'X-DEVICE-ID': faker.random.uuid(),
});

/**
 * Gets device ID from state, for use in some v5 endpoints
 *
 * @param {State} state Application State
 * @returns { 'X-DEVICE-ID': string }
 */
export const getDeviceIdHeaders = (state: State) => ({
  'X-DEVICE-ID': getBrowserID(state),
});

/**
 * Gets the walker's token
 *
 * @param  {State} state
 * @returns string
 */
export const getWalkerToken = (state: State) => state.walker.authToken;

/**
 * Gets the walker's password update token
 *
 * @param  {State} state
 * @returns string
 */
export const getWalkerPasswordUpdateToken = (state: State) => state.walker.passwordResetJWT;

/**
 * Gets the walker's id
 *
 * @param  {GlobalState} state
 * @returns string
 */
export const getWalkerId = (state: State) => state.walker.walker.id;

/**
 * Is the walker's account connected with stripe
 *
 * @param  {State} state
 * @returns boolean
 */
export const getIsStripeConnectComplete = (
  state: State,
) => state.walker.walker.stripeConnectFlow === WALKER_STRIPE_CONNECT_STATUS_COMPLETED;

/**
 * Get the stripe-connect redirect url for the walker
 *
 * @param  {State} state
 * @returns boolean
 */
export const getStripeConnectRedirectUrl = (state: State) => state.walker.stripeRedirectUrl;

/**
 * Gets walker auth params from state
 *
 * @param {State} state Application State
 * @returns { ownerID: string, token: string }
 */
export const getWalkerAuthParams = (state: State): { walkerID: string, token: string } => ({
  walkerID: getWalker(state).walkerID,
  token: getWalkerToken(state),
});

/**
 * Gets if walker is already a stripe "payable" user
 *
 * @param {State} state Application State
 * @returns { isPayable: boolean }
 */
export const getIsWalkerPayable = (state: State): { isPayable: boolean } => state.walker.isPayable;

/**
 * Gets dog breeds from the store
 *
 * @param {State} state Application State
 * @returns string[] - dog breeds
 */
export const getDogBreeds = (state: State): string[] => state.dogBreeds;

/**
 * Gets dog breed assets from the store
 *
 * @param {State} state Application State
 * @returns {DogBreedAssets}
 */
export const getDogBreedAssets = (state: State): DogBreedAssets => state.dogBreedAssets;

/**
 * @param {GlobalState} state  Application State
 * @returns The walk to be covered
 */
export const getWalkToBeCovered = (state: State): {
  dogName: string,
  walkDate: string,
  startTime: string,
  walkerSupportPhone: string,
} => state.walker.walkToBeCovered;

export const getOwnerAddress = (state: State) => state.owner.address;

export const getOwnerZipCode = (state: State) => (state.owner.address && state.owner.address.zipCode) || '';

export const getOwnerHasAddress = (state: State) => (
  state.owner.address
    && state.owner.address.streetAddress
    && state.owner.address.zipCode
);

export const getOwnerDogID = (state: State) => state.owner.dogID;

export const getIsLockBoxRequested = (state: State) => (
  state.owner.lockbox
    && state.owner.lockbox.status
);
export const getPreferredWalkers = (state: State) => state.owner.preferredWalkers;

export const getAllWalks = (state: State) => [
  ...getWalksInProgress(state),
  ...getWalksInThePast(state),
  ...getWalksPendingConfirmation(state),
  ...getWalksUpcoming(state),
];

/**
 * @deprecated - use `getWalkV2` as that handles a lean response when no walk has been found
 */
export const getWalk = (state: State, walkID: number | string) => {
  const result = getAllWalks(state).find((walk) => walk && walk.walkID && walk.walkID === walkID);
  return result || { error: 'Walk not found.' };
};

export const getWalkV2 = (id: number | string) => (state: State) => {
  const result = getAllWalks(state).find((walk) => walk && walk.walkID && walk.walkID === id);
  return result;
};

export const getDogs = (state: State) => state.dogs && state.dogs.dogs;

/**
 * @deprecated - use `getDogByIdV2`
 */
export const getDogById = (state: State, id: number) => {
  try {
    return state.dogs.dogs.find((dog) => String(dog.dogID) === id);
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable-next-line no-console */
      console.log('Unable to getDogById in selector:', e);
    }

    return {};
  }
};

export const getDogByIdV2 = (id: number) => (state: State) => {
  const dog = state.dogs.dogs.find((stateDog) => stateDog.dogID === id) || {};
  return dog;
};

/**
 * @deprecated - use `getDogQuestionaireByIdV2`
 */
export const getDogQuestionaireById = (state: State, id: number) => {
  try {
    const possibleDog = state.dogs.dogs.find((dog) => String(dog.dogID) === id);
    if (possibleDog && possibleDog.questionaire) {
      return possibleDog.questionaire;
    }
    return {};
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint-disable-next-line no-console */
      console.log('Unable to getDogQuestionaireById in selector:', e);
    }

    return {
      home: {},
      walk: {},
    };
  }
};

export const getDogQuestionaireByIdV2 = (id: number) => (state: State) => {
  const dog = getDogByIdV2(id)(state);
  const emptyState = {
    home: {},
    walk: {},
  };

  if (!dog || isObjEmpty(dog)) {
    return emptyState;
  }

  if (!dog.questionaire || isObjEmpty(dog.questionaire)) {
    return emptyState;
  }

  return dog.questionaire;
};
