
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetOwnerPremiumSubscriptionParsedSuccessResponse,
} from 'webApp';

import {
  OWNER_PREMIUM_SUBSCRIPTION_GET,
  OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
  OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get owner premium subscription
 */

export type GetOwnerPremiumSubscriptionAction = {
  type: typeof OWNER_PREMIUM_SUBSCRIPTION_GET,
};

export const getOwnerPremiumSubscription: (
) => GetOwnerPremiumSubscriptionAction = createAction(
  OWNER_PREMIUM_SUBSCRIPTION_GET,
);


/**
 * Get owner premium subscription success action
 */
export type GetOwnerPremiumSubscriptionSuccessAction = {
  type: typeof OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
  payload: GetOwnerPremiumSubscriptionParsedSuccessResponse,
};

export const getOwnerPremiumSubscriptionSuccess: (
  GetOwnerPremiumSubscriptionParsedSuccessResponse
) => GetOwnerPremiumSubscriptionSuccessAction = createAction(
  OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
);


/**
 * Get owner premium subscription failure action
 */
export type GetOwnerPremiumSubscriptionFailureAction = {
  type: typeof OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerPremiumSubscriptionFailure: (
  ErrorParsedResponse,
) => GetOwnerPremiumSubscriptionFailureAction = createAction(
  OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
);
