import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';
import {
  OWNER_REGISTRATION_DOG_INFO,
  OWNER_REGISTRATION_DOG_INFO_SUCCESS,
  OWNER_REGISTRATION_DOG_INFO_FAILURE,
} from '../../actionTypes';

/**
 * Register owner dog info
 */
export type RegisterOwnerDogInfoParams = {
  dogs: Partial<webApp.V5Dog>[];
};

export type RegisterOwnerDogInfoPayload = RegisterOwnerDogInfoParams;

export type RegisterOwnerDogInfoAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO,
  payload: RegisterOwnerDogInfoPayload,
};

export const registerOwnerDogInfo: (
  params: RegisterOwnerDogInfoParams,
) => RegisterOwnerDogInfoAction = createAction(
  OWNER_REGISTRATION_DOG_INFO,
);

/**
 * Register owner dog info success action
 */
export type RegisterOwnerDogInfoSuccessAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO_SUCCESS,
};

export const registerOwnerDogInfoSuccess: (
  response: webApp.RegisterOwnerDogInfoParsedResponse
) => RegisterOwnerDogInfoSuccessAction = createAction(
  OWNER_REGISTRATION_DOG_INFO_SUCCESS,
);

/**
 * Register owner dog info failure action
 */
export type RegisterOwnerDogInfoFailureAction = {
  type: typeof OWNER_REGISTRATION_DOG_INFO_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const registerOwnerDogInfoFailure: (
  error: webApp.ErrorParsedResponse,
) => RegisterOwnerDogInfoFailureAction = createAction(
  OWNER_REGISTRATION_DOG_INFO_FAILURE,
);
