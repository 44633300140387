
import { createAction } from 'redux-actions';
import type {
  SignInWalkerParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  WALKER_SIGN_IN,
  WALKER_SIGN_IN_SUCCESS,
  WALKER_SIGN_IN_FAILURE,
} from '../../actionTypes';


/**
 *  sign in Walker
 */
export type SignInWalkerParams = {
  email: string,
  password: string,
  source: string,
  willUpdatePassword?: boolean,
};

export type SignInWalkerPayload = SignInWalkerParams;

export type SignInWalkerAction = {
  type: typeof WALKER_SIGN_IN,
  payload: SignInWalkerPayload,
};

export const signInWalker: (params: SignInWalkerParams) => SignInWalkerAction = createAction(
  WALKER_SIGN_IN,
);


/**
 *  Sign in walker success
 */
export type SignInWalkerSuccessAction = {
  type: typeof WALKER_SIGN_IN_SUCCESS,
  payload: SignInWalkerParsedResponse,
};

export const signInWalkerSuccess: (
  SignInWalkerParsedResponse,
) => SignInWalkerSuccessAction = createAction(
  WALKER_SIGN_IN_SUCCESS,
);


/**
 *  Sign in Walker failure
 */
export type SignInWalkerFailureAction = {
  type: typeof WALKER_SIGN_IN_FAILURE,
  payload: ErrorParsedResponse,
};

export const signInWalkerFailure: (ErrorParsedResponse) => SignInWalkerFailureAction = createAction(
  WALKER_SIGN_IN_FAILURE,
);
