import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { CreateWalkCoverRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_WALK_COVER_CREATE } from "../../../constants/endpoints";
import { WALK_COVER_CREATE } from "../../../actions/actionTypes";
import { createWalkCoverSuccess, createWalkCoverFailure } from "../../../actions/walkCover/createWalkCover";

const createWalkCover$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(WALK_COVER_CREATE), switchMap(({
  payload: {
    id,
    token,
    walkId
  }
}) => post$(({
  endpoint: ENDPOINT_WALKER_WALK_COVER_CREATE,
  token,
  payload: {
    id,
    walk_ids: [walkId]
  }
} as {
  endpoint: typeof ENDPOINT_WALKER_WALK_COVER_CREATE;
  token: string;
  payload: CreateWalkCoverRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: createWalkCoverSuccess,
  onFailure: createWalkCoverFailure
})), catchError(handleErrorV5Response(action$, createWalkCoverFailure)))));

export default createWalkCover$;