import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType, Action$, State$, Dependencies } from "redux-observable";
import { OWNER_PREMIUM_SUBSCRIPTION_GET } from "../../../actions/actionTypes";
import { getOwnerPremiumSubscriptionSuccess, getOwnerPremiumSubscriptionFailure } from "../../../actions/owner";
import { ENDPOINT_OWNER_PREMIUM_SUBSCRIPTION_GET, Endpoint } from "../../../constants/endpoints";
import { getOwnerPremiumSubscriptionResponseParser } from "../../../helpers/Http/ResponseParsers";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { getOwnerToken } from "../../../selectors";
export const getOwnerPremiumSubscription$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_PREMIUM_SUBSCRIPTION_GET), retry(3), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_PREMIUM_SUBSCRIPTION_GET,
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getOwnerPremiumSubscriptionSuccess,
  onFailure: getOwnerPremiumSubscriptionFailure,
  parser: getOwnerPremiumSubscriptionResponseParser
})), catchError(handleErrorV5Response(action$, getOwnerPremiumSubscriptionFailure)))));
export default getOwnerPremiumSubscription$;