import { flatMap, takeUntil } from "rxjs/operators";
import { timer } from "rxjs";
import { ofType } from "redux-observable";
import type { Action$ } from "redux-observable";
import { WALK_COORDINATES_POLL_START, WALK_COORDINATES_POLL_STOP } from "../../../actions/actionTypes";
import { getWalkCoordinates, StartWalkCoordinatesPollAction } from "../../../actions/walk/walkCoordinates";
const POLLING_INTERVAL = 10000; // 10 seconds

const pollWalkCoordinates$ = (action$: Action$) => action$.pipe(ofType(WALK_COORDINATES_POLL_START), flatMap(({
  payload: {
    walkID
  }
}: StartWalkCoordinatesPollAction) => timer(0, POLLING_INTERVAL).pipe(flatMap(() => [getWalkCoordinates(walkID)]), takeUntil(action$.pipe(ofType(WALK_COORDINATES_POLL_STOP))))));

export default pollWalkCoordinates$;