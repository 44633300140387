import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import { CreateDogRequestPayload } from 'wagAPI';
import { ofType } from 'redux-observable';
import {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import {
  handleErrorV5Response,
  handleV5Response,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_DOG_CREATE_V5,
} from '../../../constants/endpoints';
import {
  OWNER_REGISTRATION_DOG_INFO_CREATE,
} from '../../../actions/actionTypes';
import {
  getOwnerToken,
  getOwnerID,
} from '../../../selectors';
import {
  CreateDogAction,
} from '../../../actions/dog/createDog';
import {
  deprecatedParseV5Dog,
} from '../../../helpers/Http/ResponseParsers/parseV5Dog';
import {
  registerOwnerDogInfoCreateSuccess,
  registerOwnerDogInfoCreateFailure,
} from '../../../actions/registerOwner/registerOwnerDogInfoCreate';


const createDog$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies,
) => action$.pipe(
  ofType(OWNER_REGISTRATION_DOG_INFO_CREATE),
  switchMap(({
    payload: {
      age,
      breed,
      imageUrl,
      likes,
      name,
      vetName,
      vetPhone,
    },
  }: CreateDogAction) => post$(({
    endpoint: ENDPOINT_DOG_CREATE_V5({
      ownerID: getOwnerID(state$.value),
    }),
    payload: {
      age,
      breed,
      imageUrl,
      likes,
      name,
      vet_name: vetName,
      vet_phone: vetPhone,
      is_new_dog: true,
      id: getOwnerID(state$.value),
    },
    token: getOwnerToken(state$.value),
  } as {
    endpoint: string,
    payload: CreateDogRequestPayload,
    token: string,
  }))
    .pipe(
      flatMap(response => handleV5Response({
        response,
        onSuccess: registerOwnerDogInfoCreateSuccess,
        onFailure: registerOwnerDogInfoCreateFailure,
        parser: deprecatedParseV5Dog,
      })),

      catchError(handleErrorV5Response(action$, registerOwnerDogInfoCreateFailure)),
    )),
);

export default createDog$;
