import * as Yup from 'yup';

export const branchDeepLinksSchema = Yup.object({
  channel: Yup.string().default('web'),
  feature: Yup.string().required(),
  campaign: Yup.string().required(),
  tags: Yup.array().of(Yup.string()).default([]),
  data: Yup.object()
    .shape({
      $customSmsText: Yup.string().required(),
      $ogImageUrl: Yup.string().required().default(
        'https://cdn.branch.io/branch-assets/1551749067743-og_image.jpeg',
      ),
      branchSplashScreen: Yup.string().default(
        'animated_signup_flow_treatment',
      ),
      customFweFullscreen: Yup.boolean().default(true),
      dlwag: Yup.string().default('dlwag://wagpremium'),
      fweButtonCtaTextColor: Yup.string().default('FFFFFF'),
      fweHeadlineText: Yup.string(),
      fweHeadlineTextColor: Yup.string().default('FFFFFF'),
      fweDeclineTextColor: Yup.string().default('00BF8F'),
      fweDeclineCopy: Yup.string().default(''),
      fweButtonCta: Yup.string().default('Get Started'),
      fweButtonColor: Yup.string().default('00BF8F'),
      fweImageUrl: Yup.string().default(
        'https://cdn.branch.io/branch-assets/1576000455120-og_image.png',
      ),
      headlineText: Yup.string(),
      signupCtaDeeplink: Yup.string().default('dlwag://wagpremium'),
      signupImage: Yup.string().default(
        'https://cdn.branch.io/branch-assets/1576000455120-og_image.png',
      ),
    })
    .required(),
});

// https://github.com/jquense/yup#typescript-setting
export type BranchDeepLinksSchema = Yup.InferType<typeof branchDeepLinksSchema>;
