import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
// import type { SittingQuestionnaireRequestPayload } from 'wagAPI';
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_SITTING_QUESTIONNAIRE, Endpoint } from "../../../constants/endpoints";
import { SITTING_QUESTIONNAIRE_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getSittingQuestionnaireSuccess, getSittingQuestionnaireFailure, SittingQuestionnaireAction } from "../../../actions/booking/getSittingQuestionnaire";

const sittingQuestionnaire$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(SITTING_QUESTIONNAIRE_GET), retry(3), switchMap(({
  payload: {
    walkTypeId
  }
}: SittingQuestionnaireAction) => get$(({
  endpoint: ENDPOINT_SITTING_QUESTIONNAIRE,
  payload: {
    id: getOwnerID(state$.value),
    walk_type_id: walkTypeId
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: any;
  // TODO type
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getSittingQuestionnaireSuccess,
  onFailure: getSittingQuestionnaireFailure
})), catchError(handleErrorV5Response(action$, getSittingQuestionnaireFailure)))));

export default sittingQuestionnaire$;