import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  handleErrorResponse,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_OWNER_AUTH_REFRESH,
  Endpoint,
} from '../../../constants/endpoints';
import { OWNER_AUTH_REFRESH_GET } from '../../../actions/actionTypes';
import { getOwnerRefreshTokenResponseParser } from '../../../helpers/Http/ResponseParsers';
import {
  getOwnerRefreshTokenSuccess,
  getOwnerRefreshTokenFailure,
} from '../../../actions/owner/getOwnerRefreshToken';

const getOwnerRefreshToken$ = (
  action$: Action$,
  state$: State$,
  { get$ }: Dependencies
) =>
  action$.pipe(
    ofType(OWNER_AUTH_REFRESH_GET),
    switchMap(() =>
      get$({
        endpoint: ENDPOINT_OWNER_AUTH_REFRESH,
      } as {
        endpoint: Endpoint;
        additionalHeaders: any;
      }).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: getOwnerRefreshTokenSuccess,
            onFailure: getOwnerRefreshTokenFailure,
            parser: getOwnerRefreshTokenResponseParser,
          })
        ),
        catchError((response) =>
          handleErrorResponse({
            response,
            onError: getOwnerRefreshTokenFailure,
          })
        )
      )
    )
  );

export default getOwnerRefreshToken$;
