import type {
  AvailableService,
  PaymentMethod,
  Service,
  SmartModuleProps,
} from 'webApp';

import type { ReduxState as State } from '../index';

export const getModules = (state: State): SmartModuleProps[] => (
  state.owner && state.owner.modules ? state.owner.modules : []
);

export const getServices = (state: State): AvailableService[] => (
  state.owner && state.owner.availableServices ? state.owner.availableServices : []
);

export const getAllOwnerServices = (state: State) => {
  if (!state.owner.services) {
    return [];
  }

  return state.owner.services;
};

export const getOwnerServiceByID = (
  state: State,
  walkID: string,
): Service => getAllOwnerServices(state).find((service) => service.id === +walkID);

export const getAllOwnerUpcomingServices = (state: State): Service[] => {
  if (!state.owner.services) {
    return [];
  }

  return state.owner.services
    .filter((service) => !(service.isCompleted || service.isCancelled))
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
};

export const getPaymentMethods = (state: State) => {
  if (!state.owner.paymentMethods) {
    return 'No card on file';
  }

  return state.owner.paymentMethods;
};

export const getOwnerDefaultPaymentMethod = ({
  owner: {
    paymentMethods,
  },
}: State): PaymentMethod => {
  if (!paymentMethods.length) {
    return null;
  }

  return paymentMethods.find((card) => card.isDefault);
};

export const hasPaymentMethods = (state: State) => !!state.owner.paymentMethods.length;

export const getOwnerAddress = (state: State) => state.owner.address;

export const getDefaultHomeAccess = (state: State) => state.owner.homeAccess;

export const getPromoCodeResponse = (state: State) => state.owner.promoCodeSuccess;

export const getOwnerMaskedEmail = (state: State): string => state.owner.maskedEmail;

export const getOwnerEmail = (state: State): string => state.owner.email;

export const getOwnerFirstName = (state: State): string => state.owner.firstName;

export const getOwnerLastName = (state: State): string => state.owner.lastName;

export const getOwnerPhone = (state: State): string => state.owner.phone;

export const getOwnerMaskedPhone = (state: State): string => state.owner.maskedPhone;

export const getOwnerLoginKey = (state: State): string => state.owner.loginKey;

export const getOwnerServiceProvider = (state: State) => state.owner.serviceProvider;

export const getOwnerServiceProviderProfiles = (state: State) => (
  state.owner.serviceProvider && state.owner.serviceProvider.profiles
);

export const getOwnerPremiumSubscriptionCanTrial = (
  state: State,
): boolean => !!state.owner.premium && state.owner.premium.canTrial;

export const getOwnerPremiumSubscriptionCanSubscribe = (
  state: State,
): boolean => !!state.owner.premium && state.owner.premium.canSubscribe;

export const getOwnerPremiumIsSubscribed = (
  state: State,
): boolean => !!state.owner.premium && state.owner.premium.isSubscribed;

export const getPreferredServices = (state: State): string[] => (
  state.owner.preferredServices
);

export const getServicesWithPremium = (state: State): any[] => (
  (
    getOwnerPremiumIsSubscribed(state)
      && state.owner.premium
      && state.owner.premium.servicesWithPremium
  ) || []
);

export const getPastPetCaregivers = (state: State): any[] => state.owner.pastPetCaregivers;
