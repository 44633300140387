import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetWalksInProgressRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKS_IN_PROGRESS, Endpoint } from "../../../constants/endpoints";
import { WALKS_IN_PROGRESS_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalksInProgressSuccess, getWalksInProgressFailure } from "../../../actions/walk/getWalksInProgress";
import { getWalksInProgressResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalksInProgress$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKS_IN_PROGRESS_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_WALKS_IN_PROGRESS,
  payload: {
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetWalksInProgressRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalksInProgressSuccess,
  onFailure: getWalksInProgressFailure,
  parser: getWalksInProgressResponseParser
})), catchError(handleErrorV5Response(action$, getWalksInProgressFailure)))));

export default getWalksInProgress$;