
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  OWNER_APPLY_DETAILS_REGISTER,
  OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
  OWNER_APPLY_DETAILS_REGISTER_FAILURE,
} from '../../actionTypes';

/**
 * Register owner apply details
 */
export type RegisterOwnerApplyDetailsParams = {
  token: string,
  phoneNumber: string,
};

export type RegisterOwnerApplyDetailsPayload = RegisterOwnerApplyDetailsParams;

export type RegisterOwnerApplyDetailsAction = {
  type: typeof OWNER_APPLY_DETAILS_REGISTER,
  payload: RegisterOwnerApplyDetailsPayload,
};

export const registerOwnerApplyDetails: (
  params: RegisterOwnerApplyDetailsParams,
) => RegisterOwnerApplyDetailsAction = createAction(
  OWNER_APPLY_DETAILS_REGISTER,
);


/**
 * Register owner apply details success action
 */
export type RegisterOwnerApplyDetailsSuccessAction = {
  type: typeof OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
};

export const registerOwnerApplyDetailsSuccess: (
  response: webApp.RegisterOwnerApplyDetailsParsedResponse
) => RegisterOwnerApplyDetailsSuccessAction = createAction(
  OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
);


/**
 * Register owner apply details failure action
 */
export type RegisterOwnerApplyDetailsFailureAction = {
  type: typeof OWNER_APPLY_DETAILS_REGISTER_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const registerOwnerApplyDetailsFailure: (
  error: webApp.ErrorParsedResponse,
) => RegisterOwnerApplyDetailsFailureAction = createAction(
  OWNER_APPLY_DETAILS_REGISTER_FAILURE,
);
