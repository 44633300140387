import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import { stringifyUrl } from 'query-string';

import { wagApi } from '../../../types/wagapi';

import {
  handleResponse,
  handleErrorV5Response,
} from '../../../helpers/Http/responseHandlers';
import { registerOwnerResponseParser } from '../../../helpers/Http/ResponseParsers';

import {
  ENDPOINT_OWNER_SIGNUP_BASIC_INFO,
  Endpoint,
} from '../../../constants/endpoints';
import { ENV_SIGNUP_REGISTRATION_SOURCE } from '../../../constants';

import { OWNER_REGISTRATION_BASIC_INFO_V2 } from '../../../actions/actionTypes';
import {
  registerOwnerBasicInfoV2Success,
  registerOwnerBasicInfoV2Failure,
  RegisterOwnerBasicInfoV2Action,
} from '../../../actions/registerOwner/registerOwnerBasicInfo';

import { getDeviceInformationHeaders } from '../../../selectors';

import { getSessionToken } from '../../../reducers/auth';

const registerOwnerBasicInfoV2$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(OWNER_REGISTRATION_BASIC_INFO_V2),
    retry(3),
    switchMap(({ payload }: RegisterOwnerBasicInfoV2Action) => {
      const {
        email,
        password,
        firstName,
        lastName,
        phone: phoneNumber,
        qs,
      } = payload;

      const signupToken = getSessionToken(state$.value);

      const endpoint = stringifyUrl({
        url: ENDPOINT_OWNER_SIGNUP_BASIC_INFO,
        query: qs,
      });

      return post$({
        endpoint,
        payload: {
          email,
          firstName,
          lastName,
          password,
          phoneNumber,
          token: signupToken,
          registrationSource: ENV_SIGNUP_REGISTRATION_SOURCE,
        },
        additionalHeaders: getDeviceInformationHeaders(state$.value),
      } as {
        endpoint: keyof Endpoint;
        payload: wagApi.OwnerRegisterBasicInfoRequest;
        additionalHeaders: unknown;
      }).pipe(
        flatMap((response: wagApi.OwnerRegisterResponse) =>
          // TODO - Update typings
          handleResponse({
            response,
            onSuccess: registerOwnerBasicInfoV2Success,
            onFailure: registerOwnerBasicInfoV2Failure,
            parser: registerOwnerResponseParser,
          } as any)
        ),
        catchError(
          handleErrorV5Response(action$, registerOwnerBasicInfoV2Failure)
        )
      );
    })
  );

export default registerOwnerBasicInfoV2$;
