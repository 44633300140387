import { catchError, flatMap, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { UpdateDogQuestionaireRequestPayload } from "wagAPI";
import { handleErrorResponse, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_DOG_QUESTIONAIRE, Endpoint } from "../../../constants/endpoints";
import { OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { updateDogQuestionaireSuccess, updateDogQuestionaireFailure, UpdateDogQuestionaireAction } from "../../../actions/dog/updateDogQuestionaire";

const registerOwnerDogQuestionaireCreate$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS), switchMap(({
  payload: {
    dogID
  }
}: UpdateDogQuestionaireAction) => post$(({
  endpoint: ENDPOINT_DOG_QUESTIONAIRE,
  payload: {
    dog_id: dogID,
    answers: {
      walk: {
        dmWalkEat: false,
        dmWalkLeashTrained: false,
        dmWalkScared: false,
        dmWalkJump: false,
        dmWalkPullLeash: false,
        dmWalkChase: false,
        dmWalkInteraction: false,
        dmWalkDirt: false
      },
      home: {
        dmHomeProtect: false,
        dmHomeShy: false,
        dmHomePees: false,
        dmHomeRushOut: false
      }
    },
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: UpdateDogQuestionaireRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updateDogQuestionaireSuccess,
  onFailure: updateDogQuestionaireFailure
})), catchError(response => handleErrorResponse({
  response,
  onError: updateDogQuestionaireFailure
})))));

export default registerOwnerDogQuestionaireCreate$;