import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const PremiumCircleIcon = ({
  id = 'icon',
  ...rest
}: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 56 56"
    {...rest}
  >
    <defs>
      <circle
        id="premium-circle-icon-a"
        cx="28"
        cy="28"
        r="28"
      />

    </defs>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <mask
          id="premium-circle-icon-b"
          fill="#fff"
        >
          <use
            xlinkHref="#premium-circle-icon-a"
          />
        </mask>
        <use
          fill="#F9CD46"
          xlinkHref="#premium-circle-icon-a"
        />
        <path
          d="M35.591 31.298c2.053-1.494 4.791-1.93 6.596-3.734 3.422-3.422.746-8.15-1.618-11.324-2.24-2.987-4.791-6.596-3.422-10.08C38.64 2.427 43.43 1.68 47.102.187c11.2-4.48 15.556-18.791 26.382-24.205 5.476-2.738 12.258-2.738 17.734.062 5.475 2.8 9.52 8.214 10.515 14.312 1.37 8.088-2.364 16.302-1.68 24.453.498 6.098 3.423 12.009 2.49 18.044-.934 6.098-5.787 11.076-11.387 13.751-9.894 4.792-22.4 4.792-33.103 6.907-5.226.996-10.889 1.369-15.617-.995-2.987-1.494-6.347-4.73-8.4-8.463-2.24-4.23-2.738-9.706 1.555-12.755ZM25.76 7.653c2.053 4.231 2.302 9.707-2.178 12.57-2.115 1.368-4.915 1.68-6.782 3.421-3.547 3.298-1.182 8.152.996 11.387 2.115 3.049 4.417 6.845 2.924 10.267-1.68 3.67-6.471 4.169-10.267 5.413-11.386 3.92-16.55 17.982-27.564 22.836-5.6 2.489-12.382 2.115-17.733-.934-5.352-3.049-9.085-8.71-9.77-14.809-.933-8.15 3.236-16.115 2.987-24.328-.186-6.098-2.8-12.134-1.555-18.107 1.244-6.036 6.346-10.765 12.07-13.129 10.08-4.231 22.65-3.609 33.414-5.164 5.29-.747 10.89-.81 15.556 1.804 2.8 1.493 6.035 4.916 7.902 8.773"
          fill="#F8BC44"
          mask="url(#premium-circle-icon-b)"
        />
      </g>
      <path
        d="M43.991 21.467c0 .373.125.684.249.933-10.329 5.413-14.249-1.307-15.556-5.289.747-.249 1.245-.995 1.245-1.804A1.939 1.939 0 0 0 28 13.377a1.939 1.939 0 0 0-1.929 1.93c0 .809.56 1.555 1.245 1.804-1.37 3.982-5.29 10.702-15.556 5.289.187-.311.249-.622.249-.933a1.939 1.939 0 0 0-1.929-1.93 1.939 1.939 0 0 0-1.929 1.93c0 1.057.871 1.929 1.929 1.929.311 0 .684-.063.933-.25l6.97 17.174h20.035l6.969-17.173c.249.124.622.249.933.249a1.939 1.939 0 0 0 1.929-1.93 1.939 1.939 0 0 0-1.929-1.928 1.939 1.939 0 0 0-1.929 1.929Z"
        fill="#FFF3CD"
      />
    </g>
  </SvgIcon>
);
