import { combineEpics } from "redux-observable";
import authEpic from "./auth";
import bookingEpic from "./booking";
import dogEpic from "./dog";
import ownerEpic from "./owner";
import passwordEpic from "./password";
import registerOwnerEpic from "./registerOwner";
import responseEpic from "./response";
import walkEpic from "./walk";
import walkerEpic from "./walker";
import walkCoverEpic from "./walkCover";
export default combineEpics(authEpic, bookingEpic, dogEpic, ownerEpic, passwordEpic, registerOwnerEpic, responseEpic, walkEpic, walkerEpic, walkCoverEpic);