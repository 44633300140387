import type { OwnerPaymentMethodResponse } from "wagAPI";
import type { OwnerPaymentMethodsParsedResponse } from "webApp";
export const getOwnerPaymentMethodsResponseParser = (response: OwnerPaymentMethodResponse): OwnerPaymentMethodsParsedResponse => Object.keys(response).map(card => ({
  id: response[card].id,
  brand: response[card].brand,
  expMonth: response[card].exp_month,
  expYear: response[card].exp_year,
  last4: response[card].last4,
  tokenizationMethod: response[card].tokenization_method,
  isDefault: response[card].is_default
}));
export default getOwnerPaymentMethodsResponseParser;