import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type WellnessPlanPlatinumIconProps = SvgIconProps;

export const WellnessPlanPlatinumIcon = (
  props: WellnessPlanPlatinumIconProps
) => (
  <SvgIcon
    viewBox="0 0 42 42"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#D1D1D1"
        d="M21.087 22.675V3l4.38 13.542z"
      />
      <path
        fill="#EDEDED"
        d="M21.094 22.675V3l-4.379 13.542z"
      />
      <path
        fill="#D1D1D1"
        d="m20.992 22.619 18.712-6.08-11.526 8.35z"
      />
      <path
        fill="#AAA"
        d="m20.994 22.626 18.712-6.08-14.232.02z"
      />
      <path
        fill="#AAA"
        d="m20.97 22.517 11.564 15.918-11.503-8.381z"
      />
      <path
        fill="#D8D8D8"
        d="m20.963 22.522 11.565 15.917-4.417-13.53z"
      />
      <g>
        <path
          fill="#AAA"
          d="m21.189 22.619-18.712-6.08 11.526 8.35z"
        />
        <path
          fill="#D1D1D1"
          d="m21.187 22.626-18.712-6.08 14.232.02z"
        />
      </g>
      <g>
        <path
          fill="#D1D1D1"
          d="M21.12 22.517 9.554 38.435l11.503-8.381z"
        />
        <path
          fill="#EDEDED"
          d="M21.125 22.522 9.561 38.439l4.417-13.53z"
        />
      </g>
    </g>
  </SvgIcon>
);
