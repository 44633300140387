import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory as History } from 'react-router-dom';
import Box from '@waglabs/box';

import { HEADER_HEIGHT } from '../../../../../../constants/styling';
import Arrow from '../../../../../../assets/svg/Arrow';
import { ARIA_LABEL } from '../../../../../../constants/app';

export type BackProps = {
  color?: string,
  history: History,
  onClick?: () => void,
  backPath?: string,
};

export const BackButton = ({
  color = 'text.activeTextLink',
  history,
  onClick,
  backPath,
}: BackProps) => {
  const handleBackPath = () => {
    /**
     * ! We need to wait for 400ms before triggering route navigation
     * to allow the virtual keyboard to close if an input is focused
     * and the user click on the back button.
     * Issue is specific to `iOS Safari`
     * See https://wagsters.atlassian.net/browse/BRICK-482
     */
    setTimeout(() => (
      backPath ? history.push(backPath) : history.goBack()
    ), 400);
  };

  const handleOnClick = () => (
    onClick ? onClick() : handleBackPath()
  );

  return (
    <Box
      hover
      aria-label={ARIA_LABEL.BOX.BACK}
      color={color}
      height={HEADER_HEIGHT}
      px={2}
      role="button"
      tabIndex={0}
      transform="scaleX(-1)"
      width="10px"
      onClick={handleOnClick}
    >
      <Arrow
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export const Back = withRouter<any>(BackButton);
