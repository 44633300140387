
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';

import {
  VISITOR_ACCOUNT_CREATE,
  VISITOR_ACCOUNT_CREATE_SUCCESS,
  VISITOR_ACCOUNT_CREATE_FAILURE,
} from '../../actionTypes';


/**
 * Create visitor account
 */
export type CheckVisitorAccountAction = {
  type: typeof VISITOR_ACCOUNT_CREATE,
};

export const createVisitorAccount: () => CheckVisitorAccountAction = createAction(
  VISITOR_ACCOUNT_CREATE,
);


/**
 * Create visitor account success action
 */
export type CheckVisitorAccountSuccessAction = {
  type: typeof VISITOR_ACCOUNT_CREATE_SUCCESS,
};

export const createVisitorAccountSuccess: () => CheckVisitorAccountSuccessAction = createAction(
  VISITOR_ACCOUNT_CREATE_SUCCESS,
);


/**
 * Create visitor account failure action
 */
export type CheckVisitorAccountFailureAction = {
  type: typeof VISITOR_ACCOUNT_CREATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const createVisitorAccountFailure: (
  ErrorParsedResponse,
) => CheckVisitorAccountFailureAction = createAction(
  VISITOR_ACCOUNT_CREATE_FAILURE,
);
