import {
  combineActions,
  handleActions,
} from 'redux-actions';

import {
  DOG_CREATE_SUCCESS,
  DOGS_GET_SUCCESS,
  DOGS_GET_FAILURE,
  DOG_DELETE,
  DOG_DELETE_FAILURE,
  DOG_UPDATE_V4_SUCCESS,
  DOG_UPDATE_V4_FAILURE,
  DOG_UPDATE_QUESTIONAIRE,
  DOG_UPDATE_QUESTIONAIRE_FAILURE,
  LOGOUT,
  DOG_GET_QUESTIONAIRE_SUCCESS,
  OWNER_REGISTRATION_DOG_INFO,
  DOG_PROFILE_PATCH_SUCCESS,
  DOG_PROFILE_PATCH,
  DOGS_CLEAR,
} from '../../actions/actionTypes';
import type { RegisterOwnerDogInfoAction } from '../../actions/registerOwner/registerOwnerDogInfo';
import { webApp } from '../../types/webapp';

export type DogsState = {
  dogs: webApp.V5Dog[];
  oldDogs: webApp.V5Dog[];
};
export const initialState: DogsState = {
  dogs: [],
  oldDogs: [],
};

const createEmptyQuestionaire = () => ({
  walk: {
    dmWalkEat: false,
    dmWalkLeashTrained: false,
    dmWalkScared: false,
    dmWalkJump: false,
    dmWalkPullLeash: false,
    dmWalkChase: false,
    dmWalkInteraction: false,
    dmWalkDirt: false,
  },
  home: {
    dmHomeProtect: false,
    dmHomeShy: false,
    dmHomePees: false,
    dmHomeRushOut: false,
  },
});

export const dogs: any = handleActions({
  [DOG_DELETE]: (state, {
    payload,
  }) => ({
    dogs: state.dogs.filter((dog) => String(dog.dogID) !== String(payload.dogID)),
    oldDogs: state.dogs,
  }),
  [DOG_CREATE_SUCCESS]: (state, {
    payload,
  }) => ({
    dogs: [...state.dogs, payload],
  }),
  [OWNER_REGISTRATION_DOG_INFO]: (state, {
    payload: {
      dogs: dogsList, // Aliased because dogs is defined as the reducer name above

    },
  }: RegisterOwnerDogInfoAction): DogsState => ({
    ...state,
    dogs: [
      ...state.dogs,
      // endpoint accepts `imageUrl`, it should be `imageURL`
      // so that it is consistent across the app
      ...dogsList.map(({
        imageUrl: imageURL,
        ...restDog
      }) => ({
        ...restDog,
        imageURL,
      })),
    ],
  }),
  // TODO - We can remove this if the BE returns the correct response
  // Currently BE doesn't return other fields
  [DOG_PROFILE_PATCH]: (state, {
    payload,
  }) => ({
    dogs: state.dogs.map((dog) => {
      if (dog.dogID === payload.dogID) {
        // TODO - Why it is in array? Should we convert it to object instead
        // since the UI doesn't support multiple dog vets?
        const veterinarians = dog.veterinarians.map((vet) => ({
          ...vet,
          name: payload.vetName,
          phone: payload.vetPhone,
        }));
        return {
          ...dog,
          ...payload,
          veterinarians,
        };
      }

      return dog;
    }),
    oldDogs: state.dogs,
  }),
  [combineActions(DOG_UPDATE_V4_SUCCESS, DOG_CREATE_SUCCESS, DOG_PROFILE_PATCH_SUCCESS)]: (state, {
    payload,
  }) => ({
    dogs: state.dogs.map((dog) => {
      if (String(dog.dogID) === String(payload.dogID)) {
        return {
          questionaire: {
            ...createEmptyQuestionaire(),
            ...dog.questionaire,
          },
          ...dog,
          ...payload,
          imageURL: payload.imageUrl,
        };
      }

      dog.imageURL = dog.imageUrl;
      return dog;
    }),
    oldDogs: state.dogs,
  }),
  [DOG_UPDATE_QUESTIONAIRE]: (state, {
    payload,
  }) => ({
    dogs: state.dogs.map((dog) => {
      if (String(dog.dogID) === String(payload.dogID)) {
        return {
          ...dog,
          questionaire: payload,
        };
      }

      return dog;
    }),
    oldDogs: state.dogs,
  }),
  [DOGS_GET_SUCCESS]: (state, {
    payload,
  }) => ({
    dogs: payload.map((dog) => ({
      questionaire: {
        ...createEmptyQuestionaire(),
        ...dog.questionaire,
      },
      ...dog,
      imageURL: dog.imageUrl,
    })),
    oldDogs: [],
  }),
  [combineActions(
    DOGS_GET_FAILURE,
    DOG_DELETE_FAILURE,
    DOG_UPDATE_V4_FAILURE,
    DOG_UPDATE_QUESTIONAIRE_FAILURE,
  )]: (state) => ({
    dogs: state.oldDogs,
    oldDogs: [],
  }),
  [combineActions(DOGS_CLEAR, LOGOUT)]: () => initialState,
  [DOG_GET_QUESTIONAIRE_SUCCESS]: (state, {
    payload: {
      dogID,
      type,
      ...questionaire
    },
  }) => ({
    ...state,
    dogs: state.dogs.map((dog) => {
      if (dog.dogID === Number(dogID)) {
        if (type === 'walk') {
          return {
            ...dog,
            questionaire: {
              ...dog.questionaire,
              walk: questionaire,
            },
          };
        }

        if (type === 'home') {
          return {
            ...dog,
            questionaire: {
              ...dog.questionaire,
              home: questionaire,
            },
          };
        }
      }

      return dog;
    }),
  }),
}, initialState);
