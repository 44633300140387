import React from 'react';

import {
  withRouter,
  Location,
  RouterHistory,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { theme } from '@waglabs/base-theme';
import Flex from '@waglabs/flex';

import { Container } from '../../components/Container';
import { DirectBookWalkerChip } from '../../components/DirectBookWalkerChip';
import { ROUTE_HOME } from '../../constants/routes';
import { useIsInEmbed } from '../../hooks';
import { useGetWalkerFromUrlQuery } from '../../hooks/useGetWalkerFromUrlQuery';

import { Navigation } from './components/Navigation';
import { SidebarContextProviderWithRouter } from './components/Navigation/components/SidebarContextProvider/SidebarContextProvider';
import { SidebarWithOverlay } from './components/Navigation/components/SidebarWithOverlay';

type Props = {
  children: any;
  location: Location;
  hideNavOnMobile?: boolean;

  /**
   * TODO: Not sure if this is the proper type here
   * should be picked from <Flex /> component?
   * or React.CSSProperties?
   */
  containerStyle?: any;
  mainContentContainerStyle?: any;
  history?: RouterHistory;
};

export const BaseNavigationPage = ({
  children,
  location,
  hideNavOnMobile,
  containerStyle,
  mainContentContainerStyle,
  ...rest
}: Props) => {
  const isInEmbed = useIsInEmbed();
  const isOnHomePage = location.pathname === ROUTE_HOME;
  const walker = useGetWalkerFromUrlQuery();
  return (
    <SidebarContextProviderWithRouter>
      <ThemeProvider
        theme={theme}
      >
        <Container
          paddingTop="10px"
        >
          {(isInEmbed && walker.isValid) && (
            <DirectBookWalkerChip
              value={walker}
            />
          )}
        </Container>
      </ThemeProvider>
      <Flex
        boxSizing="border-box"
        flexDirection="column"
        height={undefined}
        width={1}
        {...containerStyle}
      >
        {/* Don't show navigation in modal sign up flow */}
        {!isInEmbed && (
          <Navigation // TODO: Remove in Navigation PR
            display={
              hideNavOnMobile
                ? isOnHomePage || hideNavOnMobile
                  ? 'none'
                  : ['flex', 'flex', 'none']
                : undefined
            }
            {...rest}
          />
        )}

        <SidebarWithOverlay />

        <Flex
          flexDirection="column" // mt="0px" // TODO: Remove in Navigation PR
          mt={isInEmbed ? '0px' : '60px'}
          width={1}
          {...mainContentContainerStyle}
        >
          {children}
        </Flex>
      </Flex>
    </SidebarContextProviderWithRouter>
  );
};
export const NavigationPage = withRouter<any>(BaseNavigationPage);
