import { ofType } from 'redux-observable';
import type { Action$ } from 'redux-observable';
import { timer } from 'rxjs';
import {
  flatMap,
  takeUntil,
} from 'rxjs/operators';

import {
  WALKS_POLL_START,
  WALKS_POLL_STOP,
} from '../../../actions/actionTypes';
import { getWalksInPendingReview } from '../../../actions/walk/getWalksInPendingReview';
import { getWalksInProgress } from '../../../actions/walk/getWalksInProgress';
import { getWalksInThePast } from '../../../actions/walk/getWalksInThePast';
import { getWalksUpcoming } from '../../../actions/walk/getWalksUpcoming';
import { ENV_POLLING_WALKS_INTERVAL } from '../../../constants';

const pollWalks$ = (action$: Action$) => action$.pipe(
  ofType(WALKS_POLL_START),
  flatMap(() => timer(
    0,
    ENV_POLLING_WALKS_INTERVAL,
  )
    .pipe(flatMap(
      () => [
        getWalksInProgress(),
        getWalksInPendingReview(),
        getWalksInThePast(),
        getWalksUpcoming()],
    ), takeUntil(
      action$.pipe(ofType(WALKS_POLL_STOP)),
    ))),
);

export default pollWalks$;
