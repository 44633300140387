/* eslint-disable no-useless-computed-key */
import { Distribution } from '@waglabs/get-app-button';

import { webApp } from '../types/webapp';
import { getDollarString } from '../utils';

export const APP_VERSION = '4.6.0';

/**
 * Alerts
 */
export const ALERT_PRIMARY = 'primary';
export const ALERT_SECONDARY = 'secondary';
export const ALERT_SUCCESS = 'success';
export const ALERT_DANGER = 'danger';
export const ALERT_WARNING = 'warning';
export const ALERT_INFO = 'info';
export const ALERT_LIGHT = 'light';
export const ALERT_DARK = 'dark';
export const ALERT_BOOK_WALK = 'bookWalk';
export const ALERT_NOT_ELIGIBLE_FOR_FREE_WALK = 'notEligibleForFreeWalk';
export type AlertType = typeof ALERT_PRIMARY | typeof ALERT_SECONDARY | typeof ALERT_SUCCESS | typeof ALERT_DANGER | typeof ALERT_WARNING | typeof ALERT_INFO | typeof ALERT_LIGHT | typeof ALERT_DARK | typeof ALERT_BOOK_WALK | typeof ALERT_NOT_ELIGIBLE_FOR_FREE_WALK;

/**
 * Notifications
 */
export const NOTIFICATION_DANGER = 'danger';
export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_INFO = 'info';
export const NOTIFICATION_WARNING = 'warning';
export type NotificationType = typeof NOTIFICATION_DANGER | typeof NOTIFICATION_SUCCESS | typeof NOTIFICATION_INFO | typeof NOTIFICATION_WARNING;

/**
 * Stripe
 */
export const CVC_CHECK_FAIL = 'fail';

/**
 * Response Messages
 */
export const RESPONSE_MESSAGE_UNAUTHENTICATED = 'Unauthenticated';
export const RESPONSE_MESSAGE_SERVER_ERROR = 'We are currently having trouble handling your request, please try again later';

/**
 * Walk Types
 */
export const WALK_TYPE_REGULAR_WALK = 1;
export const WALK_TYPE_DELUXE_WALK = 4;
export const WALK_TYPE_SITTING = 10;
export const WALK_TYPE_BOARDING = 11;
export const WALK_TYPE_MEET_GREET = 20;
export const WALK_TYPE_DROP_IN = 22;
export const WALK_TYPE_TRAINING = 14; // in-home training

export const WALK_TYPE_DIGITAL_TRAINING = 24;
export type WalkType = typeof WALK_TYPE_REGULAR_WALK | typeof WALK_TYPE_DELUXE_WALK | typeof WALK_TYPE_SITTING | typeof WALK_TYPE_BOARDING | typeof WALK_TYPE_MEET_GREET | typeof WALK_TYPE_TRAINING | typeof WALK_TYPE_DIGITAL_TRAINING;

/**
 * Schedule Types
 */
export const SCHEDULE_TYPE_SCHEDULED = 'scheduled';
export const SCHEDULE_TYPE_ASAP = 'asap';
export type ScheduleType = typeof SCHEDULE_TYPE_SCHEDULED | typeof SCHEDULE_TYPE_ASAP;

/**
 * Walker stripe connect status types
 */
export const WALKER_STRIPE_CONNECT_STATUS_COMPLETED = 'Completed';
export type WalkerStripeConnectStatusType = typeof WALKER_STRIPE_CONNECT_STATUS_COMPLETED;

/**
 * UTM's
 */
export const UTM_AD_GROUP_ID = 'utm_adgroupid';
export const UTM_AD_POSITION = 'utm_adposition';
export const UTM_CAMPAIGN = 'utm_campaign';
export const UTM_CAMPAIGN_ID = 'utm_campaignid';
export const UTM_CREATIVE = 'utm_creative';
export const UTM_KEYWORD = 'utm_keyword';
export const UTM_MEDIUM = 'utm_medium';
export const UTM_SOURCE = 'utm_source';
export const UTM_TARGET_ID = 'utm_targetid';
export const UTM_CONTENT = 'utm_content';
export const UTM_TERM = 'utm_term';
export const URL_PARAM_FROM = 'from';
export type UTM = typeof UTM_AD_GROUP_ID | typeof UTM_AD_POSITION | typeof UTM_CAMPAIGN_ID | typeof UTM_CAMPAIGN | typeof UTM_CREATIVE | typeof UTM_KEYWORD | typeof UTM_MEDIUM | typeof UTM_SOURCE | typeof UTM_TARGET_ID | typeof UTM_CONTENT | typeof UTM_TERM;
export type UTMObject = Record<UTM, string>;
export const recognizedUTMs: UTM[] = [UTM_AD_GROUP_ID, UTM_AD_POSITION, UTM_CAMPAIGN_ID, UTM_CAMPAIGN, UTM_CREATIVE, UTM_KEYWORD, UTM_MEDIUM, UTM_SOURCE, UTM_TARGET_ID, UTM_CONTENT, UTM_TERM];
export const mapParamsToUTMs: any = {
  utm_campaign: URL_PARAM_FROM,
};

/**
 * Attribution sources
 */
export const ATTRIBUTION_SOURCE_PAID_SIGNUP = 'walking-webpaid:wc-sem-test';
export const ATTRIBUTION_SOURCE_DEFAULT_SIGNUP = 'walking-webpaid:wc';
export type AttributionSource = typeof ATTRIBUTION_SOURCE_PAID_SIGNUP | typeof ATTRIBUTION_SOURCE_DEFAULT_SIGNUP;

/**
 * Services
 */
export const SERVICE_BOARDING = 'boarding';
export const SERVICE_MEET_GREET = 'meet_greet';
export const SERVICE_SITTING = 'sitting';
export const SERVICE_WALKING = 'walking';
export const WALK_STATUS = {
  ERROR: 0,
  REQUESTED: 1,
  // ! Maps to `WALK_STATE_APPROVED`
  ACCEPTED: 2,
  IN_PROGRESS: 3,
  COMPLETED_NO_REPORT: 4,
  COMPLETED_REPORT_SENT: 5,
  COMPLETED_REPORT_REVIEWED: 6,
  CANCELLED: 7,
  DROP_IN_IN_PROGRESS: 8,
};
export const WALK_EVENT = {
  CANCEL_WALK: 'CANCEL_WALK',
  EDIT_WALK: 'EDIT_WALK',
  GET_HELP_WALK: 'GET_HELP_WALK',
  REQUEST_AGAIN_WALK: 'REQUEST_AGAIN_WALK',
};
export const PROFILE_CARD_ACTION_EVENT = {
  CONTACT_WALKER: 'CONTACT_WALKER',
};

/**
* Contact
*/
export const HELP_EMAIL = 'help@wagwalking.com';
export const FEEDBACK_EMAIL_SUBJECT = 'Wag! Feedback';

/**
 * Access Methods
 */
export const ACCESS_METHOD_LOCKBOX = '0';
export const ACCESS_METHOD_KEY_HIDDEN = '1';
export const ACCESS_METHOD_KEY_WITH_DOORMAN = '2';
export const ACCESS_METHOD_WILL_BE_HOME = '3';
export const ACCESS_METHOD_OTHER = '4';

/**
 * Services
 */
export const WALK_DURATION_20 = '20 min';
export const WALK_DURATION_30 = '30 min';
export const WALK_DURATION_60 = '60 min';
export const DROPIN_DURATION_20 = '20 min';
export const WALK_DURATION_20_ID = 12;
export const WALK_DURATION_30_ID = 1;
export const WALK_DURATION_60_ID = 4;
export const DROPIN_DURATION_20_ID = 22;
export const WALK_DURATION_20_MINS = 20;
export const WALK_DURATION_30_MINS = 30;
export const WALK_DURATION_60_MINS = 60;
export const DROPIN_DURATION_20_MINS = 20;
export const WALK_DURATION_20_FREE_PRICE = 0;
export const WALK_DURATION_30_FREE_PRICE = 10;
export const WALK_DURATION_60_FREE_PRICE = 20;
export const TRAINING_DURATION_60_MINS = 60;
export const WALK_DURATION_DESCRIPTION = {
  [WALK_DURATION_20_MINS]: WALK_DURATION_20,
  [WALK_DURATION_30_MINS]: WALK_DURATION_30,
  [WALK_DURATION_60_MINS]: WALK_DURATION_60,
};
export const WALK_TYPE_TO_WALK_DURATION = {
  [WALK_DURATION_20_ID]: WALK_DURATION_20_MINS,
  [WALK_DURATION_30_ID]: WALK_DURATION_30_MINS,
  [WALK_DURATION_60_ID]: WALK_DURATION_60_MINS,
};
export const WALK_DURATION_TO_WALK_TYPE = {
  [WALK_DURATION_20_MINS]: WALK_DURATION_20_ID,
  [WALK_DURATION_30_MINS]: WALK_DURATION_30_ID,
  [WALK_DURATION_60_MINS]: WALK_DURATION_60_ID,
};
export const CANCEL_SCHEDULE_MODE_DATE = 0;
export const CANCEL_SCHEDULE_MODE_DAY = 1;
export const CANCEL_SCHEDULE_MODE_ALL = 2;

/**
 * Upcoming Services
 */
export const UPCOMING_SERVICE_OVERNIGHT_SERVICE_TYPE = 'Overnight';
export const UPCOMING_SERVICE_SITTING_SERVICE_TYPE = 'Sitting';
export const UPCOMING_SERVICE_BOARDING_SERVICE_TYPE = 'Boarding';
export const UPCOMING_SERVICE_DROP_IN_SERVICE_TYPE = 'Drop-in';
export const UPCOMING_SERVICE_TRAINING_SERVICE_TYPE = 'Training';

/**
 * VersionChecker interval
 */
export const VERSION_CHECKER_INTERVAL = 86400000; // 1000*60*60*24=86400000

export const VERSION_CHECKER_STORAGE_KEY = 'wag-walking-version';

/**
 * Referral Text
 */
export const REFERRAL_TEXT = 'Your preferred walker has been added!';
/**
 * Global Location History Access
 */
export const WEBVIEW_URL_CURRENT = 'webview-url-current';
export const WEBVIEW_SEARCH_CURRENT = 'webview-search-current';
export const WEBVIEW_URL_LAST = 'webview-url-last';

/**
 * Direct booking metadata
 */
export const SERVICE_CATEGORY_SELECTED = 'service-category-selected';
export const SERVICE_TYPE_SELECTED = 'service-type-selected';

/**
 * Promo, Credits, Pricing
 */
export const ADD_PREFERRED = 'was added as preferred, but no credits were applied.';
export const ERROR_PREFERRED = 'There was an error applying your promo code.';

/**
 * Drop In text
 */
export const BOOK_A_DROP_IN_VISIT = 'Book a Drop-in Visit!';
export const PET_CARE_PROVIDER_1 = 'A Pet Care Provider will stop by your home for 20 minutes to check on your pup!';
export const DROP_IN_VISITS = 'Drop-In Visits';
export const SELECT_FROM_1_OF_2_OPTIONS = 'Select from 1 of 2 options:';
export const ASAP = 'ASAP';
export const SCHEDULED = 'Scheduled';
export const START_PRICE = 'Start price';
export const VISIT = '/ visit';
export const CONTINUE = 'Continue';

/**
 * WALK TIMES
 */
export const SINGLE_WALK_TIMES = ['6:00 AM - 7:00 AM', '7:00 AM - 8:00 AM', '8:00 AM - 9:00 AM', '9:00 AM - 10:00 AM', '10:00 AM - 11:00 AM', '11:00 AM - 12:00 PM', '12:00 PM - 1:00 PM', '1:00 PM - 2:00 PM', '2:00 PM - 3:00 PM', '3:00 PM - 4:00 PM', '4:00 PM - 5:00 PM', '5:00 PM - 6:00 PM', '6:00 PM - 7:00 PM', '7:00 PM - 8:00 PM', '8:00 PM - 9:00 PM', '9:00 PM - 10:00 PM', '10:00 PM - 11:00 PM'];
export const REBOOKING_WALK_TIMES = ['8:00 AM - 9:00 AM', '9:00 AM - 10:00 AM', '10:00 AM - 11:00 AM', '11:00 AM - 12:00 PM', '12:00 PM - 1:00 PM', '1:00 PM - 2:00 PM', '2:00 PM - 3:00 PM', '3:00 PM - 4:00 PM', '4:00 PM - 5:00 PM', '5:00 PM - 6:00 PM', '6:00 PM - 7:00 PM', '7:00 PM - 8:00 PM', '8:00 PM - 9:00 PM', '9:00 PM - 10:00 PM', '10:00 PM - 11:00 PM'];
export const DROPIN_TIMES = ['6:00 AM - 6:30 AM', '6:30 AM - 7:00 AM', '7:00 AM - 7:30 AM', '7:30 AM - 8:00 AM', '8:00 AM - 8:30 AM', '8:30 AM - 9:00 AM', '9:00 AM - 9:30 AM', '9:30 AM - 10:00 AM', '10:00 AM - 10:30 AM', '10:30 AM - 11:00 AM', '11:00 AM - 11:30 AM', '11:30 AM - 12:00 PM', '12:00 PM - 12:30 PM', '12:30 PM - 1:00 PM', '1:00 PM - 1:30 PM', '1:30 PM - 2:00 PM', '2:00 PM - 2:30 PM', '2:30 PM - 3:00 PM', '3:00 PM - 3:30 PM', '3:30 PM - 4:00 PM', '4:00 PM - 4:30 PM', '4:30 PM - 5:00 PM', '5:00 PM - 5:30 PM', '5:30 PM - 6:00 PM', '6:00 PM - 6:30 PM', '6:30 PM - 7:00 PM', '7:00 PM - 7:30 PM', '7:30 PM - 8:00 PM', '8:00 PM - 8:30 PM', '8:30 PM - 9:00 PM', '9:00 PM - 9:30 PM', '9:30 PM - 10:00 PM', '10:00 PM - 10:30 PM', '10:30 PM - 11:00 PM'];
export const RECOMMENDED_TIMES = ['11:00 AM - 12:00 PM'];
export const HIGHDEMAND_TIMES = ['8:00 AM - 9:00 AM', '9:00 AM - 10:00 AM', '10:00 AM - 11:00 AM'];
export const TIME_PICKER_TIME_MAP = {
  '6:00 AM - 7:00 AM': '6:00:00',
  '7:00 AM - 8:00 AM': '7:00:00',
  '8:00 AM - 9:00 AM': '8:00:00',
  '9:00 AM - 10:00 AM': '9:00:00',
  '10:00 AM - 11:00 AM': '10:00:00',
  '11:00 AM - 12:00 PM': '11:00:00',
  '12:00 PM - 1:00 PM': '12:00:00',
  '1:00 PM - 2:00 PM': '13:00:00',
  '2:00 PM - 3:00 PM': '14:00:00',
  '3:00 PM - 4:00 PM': '15:00:00',
  '4:00 PM - 5:00 PM': '16:00:00',
  '5:00 PM - 6:00 PM': '17:00:00',
  '6:00 PM - 7:00 PM': '18:00:00',
  '7:00 PM - 8:00 PM': '19:00:00',
  '8:00 PM - 9:00 PM': '20:00:00',
  '9:00 PM - 10:00 PM': '21:00:00',
  '10:00 PM - 11:00 PM': '22:00:00',
  '6:00 AM - 6:30 AM': '6:00:00',
  // dropin times
  '6:30 AM - 7:00 AM': '6:30:00',
  '7:00 AM - 7:30 AM': '7:00:00',
  '7:30 AM - 8:00 AM': '7:30:00',
  '8:00 AM - 8:30 AM': '8:00:00',
  '8:30 AM - 9:00 AM': '8:30:00',
  '9:00 AM - 9:30 AM': '9:00:00',
  '9:30 AM - 10:00 AM': '9:30:00',
  '10:00 AM - 10:30 AM': '10:00:00',
  '10:30 AM - 11:00 AM': '10:30:00',
  '11:00 AM - 11:30 AM': '11:00:00',
  '11:30 AM - 12:00 AM': '11:30:00',
  '12:00 AM - 12:30 AM': '12:00:00',
  '12:30 AM - 1:00 AM': '12:30:00',
  '1:00 PM - 1:30 PM': '13:00:00',
  '1:30 PM - 2:00 PM': '13:30:00',
  '2:00 PM - 2:30 PM': '14:00:00',
  '2:30 PM - 3:00 PM': '14:30:00',
  '3:00 PM - 3:30 PM': '15:00:00',
  '3:30 PM - 4:00 PM': '15:30:00',
  '4:00 PM - 4:30 PM': '16:00:00',
  '4:30 PM - 5:00 PM': '16:30:00',
  '5:00 PM - 5:30 PM': '17:00:00',
  '5:30 PM - 6:00 PM': '17:30:00',
  '6:00 PM - 6:30 PM': '18:00:00',
  '6:30 PM - 7:00 PM': '18:30:00',
  '7:00 PM - 7:30 PM': '19:00:00',
  '7:30 PM - 8:00 PM': '19:30:00',
  '8:00 PM - 8:30 PM': '20:00:00',
  '8:30 PM - 9:00 PM': '20:30:00',
  '9:00 PM - 9:30 PM': '21:00:00',
  '9:30 PM - 10:00 PM': '21:30:00',
  '10:00 PM - 10:30 PM': '22:00:00',
  '10:30 PM - 11:00 PM': '22:30:00',
};
export const LEARN_MORE = 'Learn more*';
export const LEARN_MORE_TITLE = 'Drop-In Visits';
export const LEARN_MORE_BODY = 'Please note outside activity is not permitted for puppies under the age of 6 months.';
export const LEARN_MORE_BUTTON = 'Got it';

/**
 * Aria labels for accessibility
 */
export const ARIA_LABEL = {
  ARROW: {
    EXPANDED_PAYMENT_DETAILS: 'Expand price summary button',
    SHRINK_PAYMENT_DETAILS: 'Shrink price summary button',
  },
  BOX: {
    BACK: 'Back',
  },
  BUTTON: {
    CONTINUE: 'Continue',
  },
  DOG_CHECKBOX: {
    NAME: (name: string) => `Dog ${name}`,
  },
  FAB: {
    NAVIGATION_MENU: 'Navigation menu',
  },
  ICON: {
    ACCESS_NOTES: 'Access Notes',
    CALENDAR: 'Calendar',
    CLOSE_SELECTION: 'Close selection',
    CREATE_ACCESS_NOTES: 'Create Access Notes',
    PICK_SCHEDULE: 'Pick a schedule',
    SELECT_DOGS: 'Select Dogs',
  },
  IMAGE: {
    WALKER_HEADS: 'Walker Heads',
    ARROW_RIGHT: 'Arrow right',
  },
  SERVICE_ICON: {
    SELECT_SITTING: 'Select Sitting',
    SELECT_BOARDING: 'Select Boarding',
  },
  SERVICE_IMAGE: {
    SELECT_SERVICE: ({
      walkDurationDesc,
      price,
    }: {
      walkDurationDesc: string;
      price: number;
    }) => `Select ${walkDurationDesc} walk for a price of ${price}`,
  },
  DROP_SERVICE_IMAGE: {
    SELECT_SERVICE: ({
      walkType,
      price,
    }: {
      walkType: string;
      price: number;
    }) => `Select ${walkType} 20 min Drop in for a price of ${price}`,
  },
};

/**
 * Remove Wag! from string
 */
export const removeWag = (input = '') => input.replace('Wag! ', '');
export const ProviderService: Record<webApp.ProviderServiceKey, string> = {
  Walk: 'walk',
  Sitting: 'sitting',
  DropIn: 'drop-in',
  Daycare: 'daycare',
  Boarding: 'boarding',
  Training: 'training',
};
// Used in bookingTraining epic
export const TRAINING_TYPE_ID = 14;
export const MONTHLY_PREMIUM_SUB_PLAN_ID = process.env.REACT_APP_MONTHLY_PREMIUM_SUB_PLAN_ID || 'ec4602e6-1f91-43b4-89f8-70fde5d203f5';
export const ANNUAL_PREMIUM_SUB_PLAN_ID = process.env.REACT_APP_ANNUAL_PREMIUM_SUB_PLAN_ID || 'eb8057cb-66d3-413d-b667-3462f5faf5e2';
export const PREMIUM_SUBSCRIPTION_PRICE = Number(process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PRICE)
 || 9.99;
export const PREMIUM_SUBSCRIPTION_PRICE_STRING = getDollarString(PREMIUM_SUBSCRIPTION_PRICE);
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH = (
  Number(process.env.REACT_APP_PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH) || 7.99
);
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH_STRING = getDollarString(
  PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_PER_MONTH,
);
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE = (
  Number(process.env.REACT_APP_PREMIUM_SUBSCRIPTION_ANNUAL_PRICE) || 95.99
);
export const PREMIUM_SUBSCRIPTION_ANNUAL_PRICE_STRING = getDollarString(
  PREMIUM_SUBSCRIPTION_ANNUAL_PRICE,
);

export const MINIMUM_PASSWORD_LENGTH = 8;
export const MINIMUM_PASSWORD_SCORE = 2;
export const FeatureFlag = {
  SignInV2Enabled: Number(process.env.REACT_APP_FEATURE_SIGN_IN_V2_ENABLED),
};
export const Auth = {
  // TODO: Remove Email and Phone, it doesn't look like they're supported on the
  // wagapi side (https://github.com/waglabs/wagapi/blob/develop/app/Authentication/SignIn/Enum/AuthenticationMethod.php)
  AllowedMethods: {
    Email: 'email',
    Phone: 'phone',
    CodeEmail: 'code-email',
    CodeSMS: 'code-sms',
    Password: 'password',
    TwoFactorPassword: 'two-factor-password',
  },
  RoleType: {
    Owner: 'owner',
    Walker: 'walker',
  },
} as const;
export const DeviceType = {
  Web: 'web',
} as const;
export const APP_S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL || '';
export const NEW_RELIC_ACCOUNT_ID = process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID;
export const NEW_RELIC_TRUST_KEY = process.env.REACT_APP_NEW_RELIC_TRUST_KEY;
export const NEW_RELIC_AGENT_ID = process.env.REACT_APP_NEW_RELIC_AGENT_ID;
export const NEW_RELIC_LICENSE_KEY = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY;
export const NEW_RELIC_APP_ID = process.env.REACT_APP_NEW_RELIC_APP_ID;
export const AppDownloadLink = {
  Signup: {
    [Distribution.AppStore]: 'https://wagwalking.app.link/0rqeS8nR19',
    [Distribution.PlayStore]: 'https://wagwalking.app.link/0rqeS8nR19',
  },
  Login: {
    [Distribution.AppStore]: 'https://wagwalking.app.link/TT1kkhE8Pcb',
    [Distribution.PlayStore]: 'https://wagwalking.app.link/TT1kkhE8Pcb',
  },
  Premium: 'https://wagwalking.app.link/jmHEm9rxMob',
};
// Using GetTheAppModal from @waglabs/seo-modals
export const GetTheAppModalLink = {
  PastServices: {
    ViewFullHistory: 'https://wagwalking.app.link/LwrOrhPWWbb',
    Chat: 'https://wagwalking.app.link/umaM1QRWWbb',
  },
};

export const MobileTakeoverModalLink = 'https://wagwalking.app.link/0vvUOZGwrGb';
export const BRANCH_WINDOW_KEY = 'branch';
export const SIGNUP_DOG_INFO_NO_IMAGE = 'Pet Caregivers love seeing happy pets!';
export const SIGNUP_DOG_INFO_WITH_IMAGE = 'Can\'t wait to see that tail wag!';

// Social sign in options
// TODO convert to enum
export const APPLE_SOCIAL_SIGN_IN = 'Apple';
export const GOOGLE_SOCIAL_SIGN_IN = 'Google';
export const FACEBOOK_SOCIAL_SIGN_IN = 'Facebook';

export const WalkerStripeLoginLabels = {
  LoginByCodeFormStep: {
    [Auth.AllowedMethods.CodeEmail]: 'We\'ve emailed you your sign in code, please enter it below.',
    [Auth.AllowedMethods.CodeSMS]: 'We\'ve texted you your sign in code, please enter it below.',
  },
};

export const WalkerLoginLabels = {
  LoginByEmailFormStep: {
    [Auth.AllowedMethods.Email]: 'Please sign in to pick up where you left off.',
  },
};

export enum VetChatSubscriptionLabels {
  Monthly = 'Wag! Monthly Vet Chat Subscription',
  Annual = 'Wag! Annual Vet Chat Subscription',
}

export const MagicLoginLabels = {
  LoginByCodeFormStep: {
    [Auth.AllowedMethods.CodeEmail]: 'We\'ve emailed you your sign in code, please enter it below.',
    [Auth.AllowedMethods.CodeSMS]: 'We\'ve texted you your sign in code, please enter it below.',
  },
};

export enum RollbarSteps {
  SubmittingLoggedInWellnessCheckoutForm = 'submitting_logged_in_wellness_checkout_form',
  SubmittingLoggedOutWellnessCheckoutForm = 'submitting_logged_out_wellness_checkout_form',
  SubmittingLoggedInVetChatCheckoutForm = 'submitting_logged_in_vet_chat_checkout_form',
  SubmittingLoggedOutVetChatCheckoutForm = 'submitting_logged_out_vet_chat_checkout_form',
  SubmittingPremiumCheckoutForm = 'submitting_premium_checkout_form',
  PostingRegisterOwnerEndpoint = 'posting_register_owner_endpoint',
}

export enum WellnessRecommendationPageNames {
  wellnessIntro = 'Wellness Intro',
  wellnessPetBreed = 'Wellness Pet Breeed',
  wellnessPetAge = 'Wellness Pet Age',
  wellnessPetSize = 'Wellness Pet Size',
  wellnessPetNeutered = 'Wellness Pet Neutered',
  wellnessGrooming = 'Wellness Grooming',
  wellnessRecommendations = 'Wellness Recommendations',
  wellnessUpdatePlan = 'Wellness Update Plan',
  wellnessUpdateAddons = 'Wellness Update Addons',
  wellnessBillingInformation = 'Wellness Billing Information',
  clickedCompareWellnessPlans = 'Clicked Compare Wellness Plans',
}

export const WellnessPlan = {
  DiscountAmount: 0,
  Plans: {
    NoPlan: 0,
    Gold: 1,
    Platinum: 2,
    Diamond: 3,
  },
  // Mapped on the ids of each addon on the `wellnessPetPlanAddonMap`
  Addons: {
    FleaTickHeartwormMeds: 1,
    Grooming: 2,
    DentalCleaning: 3,
    SpayNeuter: 4,
  },
  Extras: {
    /**
     * We currently look up in the created map object
     * using a "virtual id"
     * Probably in the next iteration we might want to switch if we
     * find that it is hard to manage
     *
     * See `src/constants/wellnessPlans.ts` and `wellnessPlanExtrasMap`
     * for more info on the mapping
     */
    WagPremium: 1,
  },
  SubscriptionStatus: {
    // https://stg-api.wagwalking.com/docs/index.html#/Wellness/GET%3A-api-v6-owners-%7BownerIdentifier%7D-wellness-subscriptions
    Active: 'active',
    Unpaid: 'unpaid',
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    Trialing: 'trialing',
    PastDue: 'past_due',
    Cancelled: 'cancelled',
    // Custom subscription status not mapped to the endpoint
    None: 'none',
  },
} as const;

/**
 * TODO - Should be included in the /full endpoint
 */
export const Timezone = {
  default: 'America/Los_Angeles',
} as const;

export const PetGender = {
  Male: 'Male',
  Female: 'Female',
} as const;

export const HumanGender = {
  Male: 'Male',
  Female: 'Female',
  Neutral: 'Prefer not to say',
} as const;

export const IdentityDocumentsLabels = {
  LicenseAndSSN: 'I have a valid driver\'s license and SSN',
  PassportAndSSN: 'I have a valid US Passport and SSN',
  StateIdAndSSN: 'I have a valid state issued ID and SSN',
};

export const PetGroomingFrequency = {
  Never: 0,
  EverySixMonths: 6,
  EveryThreeMonths: 3,
  EveryMonth: 1,
};

export const DateFormat = {
  default: 'YYYY-MM-DD',
  Birthday: 'YYYY-MM-DD',
};

export const TimeFormat = {
  default: 'hh:mm:ss a',
};

export type PremiumPlanType = {
  [key: string]: {
    [key: string]: string,
  }
}

export const Countries = {
  UnitedStates: 'USA',
};

export const PremiumPlan: PremiumPlanType = {
  // https://github.com/waglabs/wagapi/blob/develop/app/Owner/Premium/Models/OwnerPremiumPlan.php#L34
  Plans: {
    // New plan id with 9.99/month price
    Monthly: MONTHLY_PREMIUM_SUB_PLAN_ID,
    Annual: ANNUAL_PREMIUM_SUB_PLAN_ID,
  },
} as const;

export const WalkerOnboardingStages = {
  Welcome: 'welcome',
  AboutMe: 'about-me',
  Northpass: 'northpass',
  OnboardingQuiz: 'onboarding-quiz',
  Stripe: 'stripe',
  Vetty: 'vetty',
  Approved: 'approved',
};

/*
      The api supports certain keys that won't trigger
      the guards that block unknown keys in development
      environments. Requests made using the rtk-query
      library need an extra unique key that is generated
      using a timestamp on the FE.As otherwise the
      automatic caching in the rtk query library will
      cache responses from previous sessions leading to
      issues such as old state being persisted from previous signups.
      '_disable_v4_wrapper' is the only key on the BE that won't have
      unintended effects when passed as a query parameter
    */
export const CacheBustingKey = '_disable_v4_wrapper';

export const PetParentBranchLink = 'https://wagwalking.app.link/fOJeeb0Gvqb';

export enum ServicesEnum {
  Walk='Walk',
  DropIn='DropIn',
  Overnight='Overnight',
}

// Time in milliseconds
export enum Time {
  OneSecond = 1000,
  TwoSeconds = 2000,
  ThreeSeconds = 3000,
  FifteenSeconds = 15000,
  OneMinute = 60000,
  TwoMinutes = 120000,
  ThreeMinutes = 180000,
  ThirtyMinutes = 1800000,
}
