import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

type Props = SvgIconProps;

export const OvernightServiceIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 64 64"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 0h64v64H0z" />
      <path
        opacity=".201"
        d="M4 4h56v56H4z"
      />
      <path
        d="M25.031 8A15.471 15.471 0 115 28.16c.144 10.92 9 19.683 19.854 19.635 10.854-.048 19.632-8.889 19.68-19.8C44.582 17.084 35.88 8.144 25.031 8z"
        fill="#F9CD46"
      />
      <g fillRule="nonzero">
        <path
          d="M29.261 55.034a14.313 14.313 0 01-2.499-.39C24.665 53.9 23.9 52.196 23.9 50a5.04 5.04 0 015.022-5.055c.144 0 .3 0 .423.015a11.37 11.37 0 01-.084-1.362 10.716 10.716 0 0120.676-4.038h.438a7.761 7.761 0 010 15.519H29.261v-.045z"
          fill="#FFF"
        />
        <path
          d="M50.375 55.886H28.439v-.108c-.66-.06-1.312-.179-1.95-.357-2.265-.795-3.414-2.622-3.414-5.427a5.874 5.874 0 015.376-5.859v-.543a11.538 11.538 0 0122.035-4.872 8.586 8.586 0 01.39 17.151h-.168a3.092 3.092 0 01-.333.015zM49.217 54.2l.855.027h.3a6.936 6.936 0 000-13.869h-.399l-.6.03-.219-.549a9.9 9.9 0 00-19.083 3.732c0 .421.027.842.078 1.26l.123.984-.99-.06h-.372a4.218 4.218 0 00-4.2 4.23c0 2.1.732 3.318 2.31 3.873.753.18 1.52.296 2.292.348l.3.021 19.605-.027z"
          fill="#EDEDED"
        />
      </g>
      <path
        d="M29.261 55.034a14.313 14.313 0 01-2.499-.39C24.665 53.9 23.9 52.196 23.9 50a5.04 5.04 0 015.022-5.055c.144 0 .3 0 .423.015a11.37 11.37 0 01-.084-1.362 10.716 10.716 0 0120.676-4.038h.438a7.761 7.761 0 010 15.519H29.261v-.045z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fill="#F9CD46"
        d="M5.291 16.871l2.442-.42.669-2.544.477 2.559 2.394.6-2.469.453-.663 2.442-.423-2.457zM12.665 22.679l3.888-.675 1.059-4.047.765 4.074 3.819.951-3.939.723-1.059 3.894-.666-3.915zM51.38 30.011l2.442-.42.669-2.544.477 2.559 2.391.6-2.466.453-.663 2.442-.423-2.457z"
      />
    </g>
  </SvgIcon>
);
