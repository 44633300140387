
import { createAction } from 'redux-actions';
import type {
  RebookingParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  REBOOKING,
  REBOOKING_SUCCESS,
  REBOOKING_FAILURE,
} from '../../actionTypes';


/**
 * REBOOK a walk
 * id,
 * token,
 * walker_id,
 * walker_uuid,
 * walk_type_id,
 * date,
 * use_preferred,
 * use_best_available,
 * minutes_to_wait_for_walker,
 * start_windows,
 * start_time
 */

export type RebookingParams = {
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  walkTypeId: number,
  walkerId: number,
  walkerUuid?: string;
  usePreferred: boolean,
  useBestAvailable: boolean,
  dogIds: number[],
  accessInfo: Partial<{
    method: string,
    keyHiddenLocation: string,
    lockboxCode: string,
    lockboxOther: string,
    otherNotes: string,
    additionalNotes: string,
    notes: string,
  }>
};

export type RebookingPayload = RebookingParams;

export type RebookingAction = {
  type: typeof REBOOKING,
  payload: RebookingPayload,
};

export const rebooking: (
  arg0: RebookingParams
) => RebookingAction = createAction(
  REBOOKING,
  ({ // TODO  verify that we need these callbacks
    startDate,
    startTime,
    endDate,
    endTime,
    walkTypeId,
    walkerId,
    walkerUuid,
    usePreferred,
    useBestAvailable,
    dogIds,
    accessInfo,
  }: RebookingParams) => ({
    startDate,
    startTime,
    endDate,
    endTime,
    walkTypeId,
    walkerId,
    walkerUuid,
    usePreferred,
    useBestAvailable,
    dogIds,
    accessInfo,
  }),
);


/**
 * Book a walk Success
 */
export type RebookingSuccessAction = {
  type: typeof REBOOKING_SUCCESS,
  payload: RebookingParsedResponse,
};

export const rebookingSuccess: (
  RebookingParsedResponse,
) => RebookingSuccessAction = createAction(
  REBOOKING_SUCCESS,
);


/**
 * Book a walk Failure
 */
export type RebookingFailureAction = {
  type: typeof REBOOKING_FAILURE,
  payload: ErrorParsedResponse,
};

export const rebookingFailure: (
  ErrorParsedResponse,
) => RebookingFailureAction = createAction(
  REBOOKING_FAILURE,
);
