import { parseUrl } from "query-string";
export const useGetUrlQuery = () => {
  // TODO: use `useLocation()` from `react-router-dom@5.1.0`
  const {
    location: {
      href
    } = {
      href: ''
    }
  } = window;
  return parseUrl(href).query;
};