// Component from https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import React, {
  Component,
  ReactElement,
} from 'react';

type ComponentModule = any;
type AsyncComponentProps = Record<string, string>;
type AsyncComponentState = {
  component: (<Props extends any>(props: Props) => ReactElement) | null;
};

export const asyncComponent = <Props extends unknown>(
  componentModuleFromImport: () => ComponentModule,
) => {
  class AsyncComponent extends Component<AsyncComponentProps, AsyncComponentState> {
    // isMounted flag prevents a memory leak on hot updates
    // https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
    _isMounted = false;

    constructor(props: AsyncComponentProps) {
      super(props);
      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      this._isMounted = true; // eslint-disable-line

      const {
        default: component,
      } = await componentModuleFromImport();

      if (this._isMounted) {
        // eslint-disable-line
        this.setState({
          component,
        });
      }
    }

    componentWillUnmount() {
      this._isMounted = false; // eslint-disable-line
    }

    render() {
      const {
        component,
      } = this.state;
      const ComponentToRender = component;
      return ComponentToRender ? <ComponentToRender {...this.props} /> : null;
    }
  }

  return AsyncComponent as unknown as (props: Props) => JSX.Element;
};
