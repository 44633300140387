export const DEFAULT_ERROR_TITLE = 'OOPS!';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';
export const API_ERROR_TITLE = 'OOPS!';
export const API_ERROR_MESSAGE = 'API Server currently unavailable.';
export const CVC_CHECK_ERROR = 'Invalid CVC code';
export const BOOKING_IN_THE_PAST_ERROR = 'You’ve selected a walk time in the past. Please select a different time.';
export const BOOKING_BOARDING_IN_THE_PAST_ERROR = 'You’ve selected a boarding time in the past. Please select a different time.';
export const BOOKING_SITTING_IN_THE_PAST_ERROR = 'You’ve selected a sitting time in the past. Please select a different time.';
export const BOOKING_TRAINING_IN_THE_PAST_ERROR = 'Trainings must be booked one day in advance. Please select a future date.';
// Magic Login Flow Errors
export const MAGIC_LOGIN_BY_EMAIL_ERROR_400 = 'There is no Pet Parent account associated with the email address you entered. Please confirm your email, or create an account to continue.';
export const MAGIC_LOGIN_BY_EMAIL_ERROR_429 = 'You’ve made too many requests to sign in, please wait a few minutes before trying again.';
export const MAGIC_LOGIN_BY_GOOGLE_ERROR = 'This account isn’t authenticated with Google. Try signing in by email instead.';
export const MAGIC_LOGIN_UNSUPPORTED_METHOD = 'Incorrect sign in method. Please try again.';

export enum ErrorTypes {
  REQUEST_INVALID = '_request.invalid',
  SOCIAL_PROVIDER_MISSING_SCOPE = '_auth.social_provider_missing_scope',
}

// Signup Flow Errors
export const SIGN_UP_BY_GOOGLE_MULTIPLE_ACCOUNTS_ERROR = 'It looks like there\'s an existing account associated with this email.';

export const MagicLoginErrors = {
  CreateAuthenticationSessionForPetParentFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'There is no Pet Parent account associated with the email address you entered. Please confirm your email, or create an account to continue.',
    },
    '_request.throttled': {
      statusCode: 429,
      type: '_request.throttled',
      message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
    },
  },
  CreateAuthenticationSessionForPetCaregiverFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'There is no Pet Caregiver account associated with the email address you entered. Please confirm your email.',
    },
    '_request.throttled': {
      statusCode: 429,
      type: '_request.throttled',
      message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
    },
  },
  EmailFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'There is no Pet Parent account associated with the phone number you entered. Please confirm your phone number, or create an account to continue.',
    },
  },
  PhoneFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'There is no Pet Parent account associated with the email address you entered. Please confirm your email, or create an account to continue.',
    },
    '_request.throttled': {
      statusCode: 400,
      type: '_request.throttled',
      message: 'You’ve made too many requests to sign in, please wait a few minutes before trying again.',
    },
  },
  PasswordFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'Expired sign in session. Please go back and try again.',
    },
    '_auth.invalid_password': {
      statusCode: 400,
      type: '_auth.invalid_password',
      message: 'Your email or password is incorrect. Please try again',
    },
    '_user.precondition_failed': {
      statusCode: 412,
      type: '_user.precondition_failed',
      message: 'Incorrect sign in method. Please try again.',
    },
    '_request.throttled': {
      statusCode: 429,
      type: '_request.throttled',
      message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
    },
  },
  CodeFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'Expired sign in session. Please go back and try again.',
    },
    '_auth.invalid_one_time_code': {
      statusCode: 400,
      type: '_auth.invalid_one_time_code',
      message: 'Incorrect sign in code, please double-check the code and try again.',
    },
    '_user.precondition_failed': {
      statusCode: 412,
      type: '_user.precondition_failed',
      message: 'Incorrect sign in method. Please try again.',
    },
    '_request.throttled': {
      statusCode: 429,
      type: '_request.throttled',
      message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
    },
  },
  MethodSelectionFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'Expired sign in session. Please go back and try again.',
    },
  },
} as const;

export const PasswordResetErrors = {
  CreateSessionFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'Your password reset link is no longer valid, please request a new link and try again.',
    },
  },
  NewPasswordFlow: {
    '_request.invalid': {
      statusCode: 400,
      type: '_request.invalid',
      message: 'Your password reset session has timed out, please request a new link and try again.',
    },
    '_user.insecure_password': {
      statusCode: 400,
      type: '_user.insecure_password',
      message: 'Password does not meet strength requirements, please try again.',
    },
  },
} as const;

export const SocialAuthErrors = {
  LoginWithSocialFlow: {
    '_request.invalid': {
      status: 400,
      title: 'Invalid Request',
      detail: 'string',
      message: 'We were unable to process your request, please try again.',
      type: '_request.invalid',
    },
    '_auth.user_role_not_allowed_to_sign_in': {
      status: 400,
      title: 'Ruh-roh!',
      message: 'Your account is not active. Please contact Customer Success if you think this is an error.',
      type: '_auth.user_role_not_allowed_to_sign_in',
      detail: 'string',
    },
    '_auth.forbidden': {
      status: 403,
      title: 'Ruh-roh!',
      message: 'This social account is already linked with another Wag! account, or your account emails do not match.',
      type: '_auth.forbidden',
      detail: 'string',
    },
    '_user.already_exists': {
      status: 409,
      title: 'User Already Exists',
      message: 'Email is already in use, please sign in and then attempt to link the account.',
      detail: 'string',
      type: 'user.already_exists',
    },
    '_user.precondition_failed': {
      status: 412,
      title: 'User role is not allowed to use social auth',
      detail: 'string',
      message: 'You are not allowed to signin via social authentication. please try again.',
      type: '_user.precondition_failed',
    },
    '_auth.social_provider_missing_scope': {
      status: 412,
      title: 'User role is not allowed to use social auth',
      detail: 'string',
      message: 'We were unable to get all required account details from the social provider. Please try again.',
      type: '_auth.social_provider_missing_scope',
    },
  },
  LinkAccountsAuthApiErrors: {
    '_user.invalid_request': {
      status: 400,
      title: 'Invalid Request',
      detail: 'string',
      type: '_user.invalid_request',
    },
    '_user.authentication': {
      title: 'Authentication Failed',
      status: 401,
      type: '_user.authentication',
      detail: 'string',
    },
  },
} as const;

export const PatchOwnerProfileErrors = {
  V5: {
    '_user.invalid_request': {
      statusCode: 400,
      type: '_user.invalid_request',
      message: 'Failed to update owner profile, please try again.',
    },
  },
} as const;

export const GetOwnerWellnessSubscriptionsErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    detail: 'Bad request',
  },
  '_user.authentication': {
    status: 401,
    type: '_user.authentication',
    detail: 'Unauthorized request',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    detail: 'Forbidden request',
  },
} as const;

export const PostOwnerErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    detail: 'We were unable to process your request, please try again.',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    detail: 'Forbidden request',
    message: 'Forbidden request',
  },
  '_user.already_exists': {
    status: 409,
    type: '_user.already_exists',
    detail: 'Looks like you already have an account. Please go to "My account" instead, or enter a different email.',
    message: 'Looks like you already have an account. Please go to "My account" instead, or enter a different email.',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
} as const;

export const PutWalkerAnswersErrors = {
  InvalidRequest: {
    status: 400,
    type: '_user.invalid_request',
    detail: 'We were unable to process your request, please try again.',
    message: 'We were unable to process your request, please try again.',
  },
  WalkerQuizScoreTooLow: {
    title: "Pet Caregiver's onboarding quiz score was too low",
    status: 400,
    type: '_walker.quiz_score_too_low',
    detail: 'string',
  },
};

export const PostWellnessSubscriptionErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    detail: 'Bad request',
    message: 'Bad request',
  },
  '_user.payment_failure': {
    status: 402,
    type: '_user.payment_failure',
    detail: 'There was an error while processing your subscription. Please try again by updating your card details or check if you have sufficient funds on your credit card',
    message: 'There was an error while processing your subscription. Please try again by updating your card details or check if you have sufficient funds on your credit card',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    detail: 'Authorization Failed',
    message: 'Authorization Failed',
  },
  '_user.pet_already_has_a_wellness_subscription': {
    status: 409,
    type: '_user.pet_already_has_a_wellness_subscription',
    detail: 'Pet already has a wellness subscription',
    message: 'Pet already has a wellness subscription',
  },
  '_user.payment_method_required': {
    status: 412,
    type: '_user.payment_method_required',
    detail: 'Payment method required to be on file',
    message: 'Payment method required to be on file',
  },
} as const;

export const GetWalkerStripeLoginLinkErrors = {
  V4: {
    '_user.invalid_request': {
      status: 400,
      type: '_user.invalid_request',
      detail: 'There was an error while processing your account. Please try again',
      message: 'There was an error while processing your account. Please try again',
    },
  },
} as const;

export const PatchWalkerErrors = {
  '_user.invalid_request':
  {
    title: 'Invalid Request',
    status: 400,
    type: '_user.invalid_request',
    detail: 'string',
  },
  '_user.authentication': {
    title: 'Authentication Failed',
    status: 401,
    type: '_user.authentication',
    detail: 'string',
  },
  '_user.authorization': {
    title: 'Authorization Failed',
    status: 403,
    type: '_user.authorization',
    detail: 'string',
  },
  '_entity.not_found': {
    title: 'Not Found',
    status: 404,
    type: '_entity.not_found',
    detail: 'string',
  },
};

export const GetWalkerApplicationErrors = {
  '_user.invalid_request': {
    title: 'Invalid Request',
    status: 400,
    type: '_user.invalid_request',
    detail: 'string',
  },
  UserAuthentication: {
    version: 6,
    title: 'Authentication Failed',
    status: 401,
    type: '_user.authentication',
    detail: 'Unable to authenticate',
  },
};
export const GetOwnerServicesErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'Bad request',
  },
  '_user.authentication': {
    title: 'Authentication Failed',
    status: 401,
    type: '_user.authentication',
    message: 'Unable to authenticate',
  },
};

export const PostOwnerSignupApplyDetailsErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const PostCreateOwnerDogErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
};

export const PostOwnerPaymentMethodErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_external.request_failure': {
    statusCode: 424,
    type: '_external.request_failure',
    message: 'There was an error while processing your subscription. Please try again by updating your card details or check if you have sufficient funds on your credit card',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const PutOwnerAddressErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const PostOwnerPetsErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authentication': {
    status: 401,
    type: '_user.authentication',
    message: 'Unable to authenticate',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const GetOwnerPremiumSubscriptionPlansErrors = {
  '_user.invalid_request': {
    status: 400,
    title: 'Invalid Request',
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authentication': {
    status: 401,
    title: 'Authentication Failed',
    type: '_user.authentication',
    message: 'Unable to authenticate',
  },
  '_user.authorization': {
    status: 403,
    title: 'Authorization Failed',
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    title: 'Not Found',
    type: '_entity.not_found',
    message: 'Not Found',
  },
};

export const PutOwnerPremiumSubErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authentication': {
    status: 401,
    type: '_user.authentication',
    message: 'Unable to authenticate',
  },
  '_user.payment_failure': {
    title: 'Payment Failure',
    status: 402,
    type: '_user.payment_failure',
    detail: 'string',
    message: 'No card on file. Please add a payment method.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.method_not_allowed': {
    title: 'Method Not Allowed',
    status: 405,
    type: '_request.method_not_allowed',
    detail: 'string',
    message: 'We were unable to process your request, please try again.',
  },
  '_internal.unable_to_acquire_lock': {
    title: 'Unable to complete the requested action, please try again',
    status: 409,
    type: '_internal.unable_to_acquire_lock',
    detail: 'string',
    message: 'Unable to complete the requested action, please try again',
  },
};

export const GetOwnerPetsErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const GetBreedsErrors = {
  '_user.invalid_request': {
    status: 400,
    type: '_user.invalid_request',
    message: 'We were unable to process your request, please try again.',
  },
  '_user.authorization': {
    status: 403,
    type: '_user.authorization',
    message: 'Authorization Failed',
  },
  '_entity.not_found': {
    status: 404,
    type: '_entity.not_found',
    message: 'Not Found',
  },
  '_request.throttled': {
    statusCode: 429,
    type: '_request.throttled',
    message: 'You\'ve made too many requests to sign in, please wait a few minutes before trying again.',
  },
};

export const PutPetImageErrors = {
  '_user.invalid_request': {
    title: 'Invalid Request',
    status: 400,
    type: '_user.invalid_request',
    detail: 'string',
  },
  '_user.authentication': {
    title: 'Authentication Failed',
    status: 401,
    type: '_user.authentication',
    detail: 'string',
  },
  '_user.authorization': {
    title: 'Authorization Failed',
    status: 403,
    type: '_user.authorization',
    detail: 'string',
  },
  '_entity.not_found': {
    title: 'Not Found',
    status: 404,
    type: '_entity.not_found',
    detail: 'string',
  },
  'client.upload_failed': {
    title: 'Invalid Request',
    type: 'client.upload_failed',
    detail: 'Failed to upload the given image. Please check your internet connection and try again.',
  },
};

export const PatchPetErrors = {
  '_user.invalid_request': {
    title: 'Invalid Request',
    status: 400,
    type: '_user.invalid_request',
    detail: 'string',
  },
  '_user.authentication': {
    title: 'Authentication Failed',
    status: 401,
    type: '_user.authentication',
    detail: 'string',
  },
  '_user.authorization': {
    title: 'Authorization Failed',
    status: 403,
    type: '_user.authorization',
    detail: 'string',
  },
  '_entity.not_found': {
    title: 'Not Found',
    status: 404,
    type: '_entity.not_found',
    detail: 'string',
  },
  'client.upload_failed': {
    title: 'Invalid Request',
    type: 'client.upload_failed',
    detail: 'Failed to upload the given image. Please check your internet connection and try again.',
  },
};
