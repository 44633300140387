export const VetChatCheckoutForm = {
  AcceptedTerms: 'acceptedTerms',
  BillingAddressCity: 'billingAddressCity',
  BillingAddressState: 'billingAddressState',
  BillingAddressSuiteOrApt: 'billingAddressSuiteOrApt',
  BillingAddressZipCode: 'billingAddressZipCode',
  BillingStreetAddress: 'billingStreetAddress',
  City: 'city',
  CreditCardNumber: 'creditCardNumber',
  Cvv: 'cvv',
  Email: 'email',
  ErrorText: 'error-text',
  ExpiryDate: 'expiryDate',
  FirstName: 'firstName',
  LastName: 'lastName',
  MobileTermsAndConditions: 'mobileTermsAndConditions',
  Phone: 'phone',
  SameBillingAndMailingDetails: 'isSameBillingAndMailingDetails',
  State: 'state',
  StreetAddress: 'streetAddress',
  SubmitBtn: 'submit-btn',
  SuiteOrApt: 'suiteOrApt',
  WellnessTermsAndConditions: 'wellnessTermsAndConditions',
  ZipCode: 'zipCode',
  MonthlyPlan: 'monthly-plan',
  AnnualPlan: 'annual-plan',
} as const;
