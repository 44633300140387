import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingTraining, BookingTrainingParams } from "../../actions/booking/bookingTraining/bookingTraining.actions";
import { getRequestStates } from "../../selectors";
import { TRAINING_BOOKING_REQUEST_KEY } from "../../constants";
export const useTrainingBooking = () => {
  const dispatch = useDispatch();
  const [trainingParams, setTrainingParams] = useState<BookingTrainingParams | null>(null);

  const getBookingTrainingRequestState = state => getRequestStates(state, TRAINING_BOOKING_REQUEST_KEY);

  const {
    errorMessage,
    isFetching,
    isSuccess
  } = useSelector(getBookingTrainingRequestState);
  const execute = setTrainingParams;
  useEffect(() => {
    if (trainingParams) {
      dispatch(bookingTraining(trainingParams));
    }
  }, [dispatch, trainingParams]);
  return {
    // Response from the API endpoint is 201
    data: null,
    error: errorMessage || false,
    isLoading: isFetching,
    isSuccess,
    execute
  };
};