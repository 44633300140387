
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  UpdateDogQuestionaireParsedResponse,
  DogQuestionaire,
} from 'webApp';
import {
  DOG_UPDATE_QUESTIONAIRE,
  DOG_UPDATE_QUESTIONAIRE_SUCCESS,
  DOG_UPDATE_QUESTIONAIRE_FAILURE,
} from '../../actionTypes';


/**
 * Update dog
 */
export type UpdateDogQuestionaireParams = DogQuestionaire & {
  dogID: string,
};

export type UpdateDogQuestionairePayload = UpdateDogQuestionaireParams;

export type UpdateDogQuestionaireAction = {
  type: typeof DOG_UPDATE_QUESTIONAIRE,
  payload: UpdateDogQuestionairePayload,
};

export const updateDogQuestionaire: (
  UpdateDogQuestionaireParams
) => UpdateDogQuestionaireAction = createAction(
  DOG_UPDATE_QUESTIONAIRE, ({
    dogID,
    walk: {
      dmWalkEat,
      dmWalkLeashTrained,
      dmWalkScared,
      dmWalkJump,
      dmWalkPullLeash,
      dmWalkChase,
      dmWalkInteraction,
      dmWalkDirt,
    },
    home: {
      dmHomeProtect,
      dmHomeShy,
      dmHomePees,
      dmHomeRushOut,
    },
  }) => ({
    dogID,
    walk: {
      dmWalkEat,
      dmWalkLeashTrained,
      dmWalkScared,
      dmWalkJump,
      dmWalkPullLeash,
      dmWalkChase,
      dmWalkInteraction,
      dmWalkDirt,
    },
    home: {
      dmHomeProtect,
      dmHomeShy,
      dmHomePees,
      dmHomeRushOut,
    },
  }),
);

/**
 * Update dog Success
 */
export type UpdateDogQuestionaireSuccessAction = {
  type: typeof DOG_UPDATE_QUESTIONAIRE_SUCCESS,
  payload: UpdateDogQuestionaireParsedResponse,
};

export const updateDogQuestionaireSuccess: (
  UpdateDogQuestionaireParsedResponse,
) => UpdateDogQuestionaireSuccessAction = createAction(DOG_UPDATE_QUESTIONAIRE_SUCCESS);


/**
 * Update dog Failure
 */
export type UpdateDogQuestionaireFailureAction = {
  type: typeof DOG_UPDATE_QUESTIONAIRE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateDogQuestionaireFailure: (
  ErrorParsedResponse,
) => UpdateDogQuestionaireFailureAction = createAction(DOG_UPDATE_QUESTIONAIRE_FAILURE);
