import type { ReduxAction } from "redux";
import type { ApiResponse } from "wagAPI";
import type { ErrorParsedResponse, FormErrorMessages } from "webApp";
import getResponseActionForStatusCode from "../getResponseActionForStatusCode";
import { RESPONSE_MESSAGE_SERVER_ERROR } from "../../../constants/app";
import { STATUS_CODE_SERVER_ERROR, STATUS_CODE_SUCCESS } from "../../../constants/statusCodes";
export const getAPIErrorCodeForResponse = (response: ApiResponse): number => {
  if (response.response) {
    if (response.response.error_code && response.response.error_code !== STATUS_CODE_SUCCESS) {
      return response.response.error_code;
    }

    if (response.response.code) {
      return response.response.code;
    }
  }

  if (response.xhr && response.xhr.status_text) {
    return Number(response.xhr.status_text);
  }

  // No error code
  return STATUS_CODE_SERVER_ERROR;
};
export const getAPIErrorMessageForResponse = (response: ApiResponse) => {
  if (response.response) {
    return response.response.error;
  }

  return RESPONSE_MESSAGE_SERVER_ERROR;
};
export const getAPIErrorMessagesForResponse = (response: ApiResponse): FormErrorMessages => {
  try {
    if (!response.response || typeof response.response !== 'object' || !response.response.error_messages || typeof response.response.error_messages !== 'object') {
      return {};
    }

    return Object.keys(response.response.error_messages).reduce((acc, errorMessage) => ({ ...acc,
      [errorMessage]: response.response && response.response.error_messages && response.response.error_messages[errorMessage][0] ? response.response.error_messages[errorMessage][0] : ''
    }), {});
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Unable to get error messages for response: ${e}`); // eslint-disable-line
    }

    return {};
  }
};
export const getErrorResponse = (response: ApiResponse): ErrorParsedResponse => ({
  error: getAPIErrorMessageForResponse(response),
  errorCode: getAPIErrorCodeForResponse(response),
  errorMessages: getAPIErrorMessagesForResponse(response)
});
export const getErrorDataFromResponse = (response: ApiResponse): ErrorParsedResponse => {
  try {
    // In this case, we have an error thats from fetch, not the API
    if (response.xhr) {
      return {
        errorCode: response.xhr.status,
        error: response.xhr.status_text,
        errorMessages: {}
      };
    }

    return getErrorResponse(response);
  } catch (e) {
    // Log out response errors if not in production
    if (process.env.NODE_ENV !== 'production') {
      console.log(response); // eslint-disable-line

      console.log(`Request Error: ${e}`); // eslint-disable-line
    }

    // Generic error response if something goes terribly wrong
    return {
      error: RESPONSE_MESSAGE_SERVER_ERROR,
      errorCode: STATUS_CODE_SERVER_ERROR,
      errorMessages: {}
    };
  }
};
export const handleErrorResponse = ({
  response,
  onError
}: {
  response: ApiResponse;
  onError: ReduxAction;
}) => {
  const {
    Rollbar
  } = window;
  const responseData = getErrorDataFromResponse(response);

  if (responseData.errorCode === STATUS_CODE_SERVER_ERROR) {
    if (Rollbar) {
      Rollbar.error(`wagAPI error response :: ${JSON.stringify(responseData)}`);
    }
  }

  return [getResponseActionForStatusCode(responseData.errorCode), onError(responseData)];
};
export default handleErrorResponse;