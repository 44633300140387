import { flatMap, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { WalkerStripeConnectGetReirectUrlPayload } from "wagAPI";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_STRIPE_CONNECT_GET_REDIRECT_URL } from "../../../constants/endpoints";
import { WALKER_STRIPE_CONNECT_GET_REDIRECT_URL } from "../../../actions/actionTypes";
import { getWalkerStripeConnectRedirectUrlSuccess, getWalkerStripeConnectRedirectUrlFailure } from "../../../actions/walker/getWalkerStripeConnectRedirectUrl";
import { getWalkerToken, getWalkerId } from "../../../selectors";
import { getWalkerStripeConnectRedirectUrlResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalkerStripeConnectRedirectUrl$ = (action$: Action$, state$: State$, {
  getLegacy$
}: Dependencies) => action$.pipe(ofType(WALKER_STRIPE_CONNECT_GET_REDIRECT_URL), switchMap(() => getLegacy$(({
  endpoint: ENDPOINT_WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
  token: getWalkerToken(state$.value),
  payload: {
    id: getWalkerId(state$.value)
  }
} as {
  endpoint: typeof ENDPOINT_WALKER_STRIPE_CONNECT_GET_REDIRECT_URL;
  payload: WalkerStripeConnectGetReirectUrlPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerStripeConnectRedirectUrlSuccess,
  onFailure: getWalkerStripeConnectRedirectUrlFailure,
  parser: getWalkerStripeConnectRedirectUrlResponseParser
})), catchError(handleErrorV5Response(action$, getWalkerStripeConnectRedirectUrlFailure)))));

export default getWalkerStripeConnectRedirectUrl$;