import type { GetCreditPackagesResponse, CreditPackage as WagAPICreditPackage } from "wagAPI";
import type { CreditPackage as WebAppCreditPackage, GetCreditPackagesParsedResponse } from "webApp";
export const getCreditPackagesResponseParser = ({
  packages
}: GetCreditPackagesResponse): GetCreditPackagesParsedResponse => {
  const validCohort = 'non_recurring_big_discount';
  // filter out all packages which don't have cohorts field or don't contain valid cohort
  return packages.filter(p => {
    if (!p.available_to_cohorts) {
      return true;
    }

    for (let i = 0; i < p.available_to_cohorts.length; i += 1) {
      for (let j = 0; j < p.available_to_cohorts[i].length; j += 1) {
        if (p.available_to_cohorts[i][j] === validCohort) {
          return true;
        }
      }
    }

    return false;
  }).map(({
    cost,
    credits,
    id,
    is_promoted: isPromoted,
    refill_bonus: refillBonus
  }: WagAPICreditPackage) => ({
    cost,
    credits,
    id,
    isPromoted: Boolean(isPromoted),
    refillBonus
  } as WebAppCreditPackage)).reduce( // convert to object with walk id as key
  (acc, ele) => ({ ...acc,
    [ele.id]: ele
  }), {});
};
export default getCreditPackagesResponseParser;