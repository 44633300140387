import { webApp } from '../types/webapp';

export const WALK_TYPE_ID_WALK = 1;
export const WALK_TYPE_ID_SITTING = 10;
export const WALK_TYPE_ID_BOARDING = 11;
export const WALK_TYPE_ID_TRAINING = 14;
export const WALK_TYPE_ID_DROP_INS = 22;
export const WALK_TYPE_ID_HEALTH = 23;
export const WalkTypes = [{
  id: 1,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: 'A 30 min walk for your dog to get exercise and go potty.',
  descriptionShort: 'Starting at $20',
  length: 30,
  name: 'Walk',
  // removed Wag! walked for PETWAG-282
  nameShort: 'Walk',
  price: 20
}, {
  id: 2,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'Meet a walker',
  descriptionShort: '',
  length: 30,
  name: 'Meet and Greet',
  nameShort: '',
  price: 0
}, {
  id: 3,
  additionalDogPrice: 1,
  cancelPrice: 10,
  description: '10 minute potty break plus optional feeding!',
  descriptionShort: '',
  length: 10,
  name: 'Potty Break',
  nameShort: 'Potty',
  price: 15
}, {
  id: 4,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: 'Treat your dog to a luxurious 1 hour stroll!',
  descriptionShort: 'Starting at $30',
  length: 60,
  name: 'Deluxe Walk',
  nameShort: 'Deluxe',
  price: 30
}, {
  id: 5,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'A chance for us to test the functionality of our systems here at Wag',
  descriptionShort: '',
  length: 30,
  name: 'Test Walk',
  nameShort: '',
  price: 0
}, {
  id: 6,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: 'A 4-hour hike for your dog at a special location of your choice. The 4 hours include travel time and playtime.',
  descriptionShort: '',
  length: 240,
  name: 'Hiking',
  nameShort: 'Hiking',
  price: 75
}, {
  id: 7,
  additionalDogPrice: 0,
  cancelPrice: 40,
  description: 'Includes natural shampoo, blow dry, 15 minute brush out, ear cleaning, nail trim, scented spritz, sani-trim PLUS complimentary gland expression & teeth cleaning.',
  descriptionShort: 'Mobile Pet Salon',
  length: 100,
  name: 'Bath',
  nameShort: 'Groom',
  price: 19.99
}, {
  id: 8,
  additionalDogPrice: 0,
  cancelPrice: 50,
  description: 'Includes bath with natural shampoo, blow dry, 15 minute brush out, ear cleaning, nail trim, scented spritz, customized trim & style PLUS complimentary gland expression & teeth brushing.',
  descriptionShort: 'Mobile Pet Salon',
  length: 100,
  name: 'Bath + Grooming',
  nameShort: 'Grooming',
  price: 19.99
}, {
  id: 9,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'Send adoptable rescue Valentine puppies to someone you love or straight to your office or home. Pups will be dressed in their best Valentine\'s outfits for their play dates! Today from 1030am-2pm. 100% of proceeds go to charity. Limited availability.',
  descriptionShort: 'Mobile Pet Salon',
  length: 20,
  name: 'Puppies',
  nameShort: 'Puppies',
  price: 30
}, {
  id: 10,
  additionalDogPrice: 24,
  cancelPrice: 25,
  description: 'An overnight stay where the walkers stay at your home looking after your dog(s).  Wag home access options included.',
  descriptionShort: 'Sitting & Boarding',
  length: 0,
  name: 'Sitting',
  nameShort: 'Overnight',
  price: 39
}, {
  id: 11,
  additionalDogPrice: 24,
  cancelPrice: 25,
  description: 'An overnight stay where your dog(s) stay at the home of your dog walker. Pick-up and drop-off available!',
  descriptionShort: 'Sitting & Boarding',
  length: 0,
  name: 'Boarding',
  nameShort: 'Boarding',
  price: 39
}, {
  id: 12,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: 'A 20 min walk for your dog to get some exercise and go potty.',
  descriptionShort: 'Starting at $15',
  length: 20,
  name: '20m Walk',
  nameShort: '20m Walk',
  price: 15
}, {
  id: 14,
  additionalDogPrice: 20,
  cancelPrice: 20,
  description: 'One on one private training sessions performed at your home!',
  descriptionShort: '1st Session FREE!',
  length: 60,
  name: 'Training',
  nameShort: 'Training',
  price: 60
}, {
  id: 15,
  additionalDogPrice: 0,
  cancelPrice: 15,
  description: 'An 8 hour Private Daycare session where only your dog(s) stay at the home of your dog sitter. Pick-Up and drop-off available!',
  descriptionShort: 'At Sitter\'s Home',
  length: 480,
  name: 'DO NOT USE - Daycare Private',
  nameShort: 'Daycare',
  price: 30
}, {
  id: 16,
  additionalDogPrice: 0,
  cancelPrice: 5,
  description: 'An 8 hour Group Daycare session where your dog(s) stay at the home of your dog sitter with other dogs from Wag! Pick-up and drop-off available!',
  descriptionShort: 'At Sitter\'s Home',
  length: 480,
  name: 'DO NOT USE - Daycare Group',
  nameShort: 'Group',
  price: 10
}, {
  id: 18,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'Useful items for your pup',
  descriptionShort: 'Useful items for your pup',
  length: 0,
  name: 'Pup Shop',
  nameShort: 'Pup Shop',
  price: 0
}, {
  id: 19,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'Get a fanny pack for free, just pay for shipping',
  descriptionShort: 'Fanny pack shipping fee',
  length: 0,
  name: 'Fanny Pack Shipping',
  nameShort: 'Shipping',
  price: 5
}, {
  id: 20,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: 'Meet your local walker plus get a FREE 20 min walk',
  descriptionShort: 'Meet + Greet',
  length: 30,
  name: 'Meet and Greet + Walk',
  nameShort: 'MnG+W',
  price: 20
}, {
  id: 21,
  additionalDogPrice: 18,
  cancelPrice: 25,
  description: 'Daytime care for your dog at the sitter\'s home.',
  descriptionShort: 'Hosts Available',
  length: 480,
  name: 'Daycare',
  nameShort: 'Daycare',
  price: 0
}, {
  id: 22,
  additionalDogPrice: 5,
  cancelPrice: 10,
  description: '20-minute drop-in visits to check on your pup!',
  descriptionShort: 'Schedule a Visit!',
  length: 20,
  name: 'Drop-in Visit',
  nameShort: 'Drop-in',
  price: 15
}, {
  id: 24,
  additionalDogPrice: 0,
  cancelPrice: 0,
  description: 'Work with your trainer over Video Chat',
  descriptionShort: '$30 per session',
  length: 30,
  name: 'Training',
  nameShort: 'Training',
  price: 30
}] as const;
// WalkTypes (array) converted to object for easy lookup through id (walkTypeId)
// Map = key-value pair
export const WalkTypeMap = WalkTypes.reduce((accumulator, currentValue) => {
  accumulator[currentValue.id] = currentValue;
  return accumulator;
}, {} as { [key in number]: webApp.WalkType });
