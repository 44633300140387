import type { UpdateCreditCardResponse } from "wagAPI";
import type { UpdateCreditCardParsedResponse } from "webApp";
export const updateCreditCardResponseParser = ({
  all_cards: allCards,
  is_card_invalid: isCardInvalid
}: UpdateCreditCardResponse): UpdateCreditCardParsedResponse => {
  const {
    brand,
    exp_month: expirationMonth,
    exp_year: expirationYear,
    last4: lastFourDigits
  } = allCards[0];
  return {
    brand,
    expirationMonth,
    expirationYear,
    isCardInvalid,
    lastFourDigits
  };
};
export default updateCreditCardResponseParser;