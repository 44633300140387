
import { createAction } from 'redux-actions';
import type {
  OwnerGoogleSigninParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_GOOGLE_SIGNIN_REGISTER,
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
} from '../../actionTypes';

/**
 * Register owner google signin
 */
export type RegisterOwnerGoogleSigninParams = {
  id: string,
  deviceID: string,
};

export type RegisterOwnerGoogleSigninPayload = RegisterOwnerGoogleSigninParams;

export type RegisterOwnerGoogleSigninAction = {
  type: typeof OWNER_GOOGLE_SIGNIN_REGISTER,
  payload: RegisterOwnerGoogleSigninPayload,
};

export const registerOwnerGoogleSignin: (
  RegisterOwnerGoogleSigninParams,
) => RegisterOwnerGoogleSigninAction = createAction(
  OWNER_GOOGLE_SIGNIN_REGISTER,
);


/**
 * Register owner google signin success action
 */
export type RegisterOwnerGoogleSigninSuccessAction = {
  type: typeof OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  payload: OwnerGoogleSigninParsedResponse,
};

export const registerOwnerGoogleSigninSuccess: (
  OwnerGoogleSigninParsedResponse,
) => RegisterOwnerGoogleSigninSuccessAction = createAction(
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
);


/**
 * Register owner google signin failure action
 */
export type RegisterOwnerGoogleSigninFailureAction = {
  type: typeof OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
  payload: ErrorParsedResponse,
};

export const registerOwnerGoogleSigninFailure: (
  ErrorParsedResponse,
) => RegisterOwnerGoogleSigninFailureAction = createAction(
  OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
);
