import { useDispatch, useSelector } from "react-redux";
import { addPreferredWalker, AddPreferredWalkerParams } from "../../actions/owner/addPreferredWalker";
import { OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY } from "../../constants";
import { getRequestStates } from "../../selectors";
export type UseCreateOwnerPreferredWalkerByPromoCodeParams = AddPreferredWalkerParams;
export const useCreateOwnerPreferredWalkerByPromoCode = () => {
  const createOwnerPreferredWalkerByPromoCodeRequestState = state => getRequestStates(state, OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY);

  const {
    error,
    isFetching,
    isSuccess
  } = useSelector(createOwnerPreferredWalkerByPromoCodeRequestState);
  const dispatch = useDispatch();

  const execute = (requestData: UseCreateOwnerPreferredWalkerByPromoCodeParams) => {
    dispatch(addPreferredWalker(requestData));
  };

  return {
    error,
    isLoading: isFetching,
    isSuccess,
    execute
  };
};