import { useSelector } from "react-redux";
import { getOwnerID, getToken, isLoggedIn as isLoggedInSelector } from "../reducers/auth";
export type UseGetAuthResult = {
  token?: string;
  ownerID?: string;
  isLoggedIn: boolean;
};
export const useGetAuth = () => {
  const token = useSelector(getToken);
  const ownerID = useSelector(getOwnerID);
  const isLoggedIn = useSelector(isLoggedInSelector);
  return {
    token,
    ownerID,
    isLoggedIn
  };
};