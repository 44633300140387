import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import type { GetOwnerCreditRequestPayload } from 'wagAPI';

import { OWNER_CREDIT_GET } from '../../../actions/actionTypes';
import {
  getOwnerCreditSuccess,
  getOwnerCreditFailure,
} from '../../../actions/owner/getOwnerCredit';
import {
  ENDPOINT_OWNER_CREDIT_GET,
  Endpoint,
} from '../../../constants/endpoints';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import { getOwnerCreditResponseParser } from '../../../helpers/Http/ResponseParsers';
import {
  getOwnerToken,
  getOwnerID,
} from '../../../selectors';

const getOwnerCredit$ = (action$: Action$, state$: State$, {
  get$,
}: Dependencies) => action$.pipe(ofType(OWNER_CREDIT_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_CREDIT_GET,
  payload: {
    id: getOwnerID(state$.value),
  },
  token: getOwnerToken(state$.value),
} as {
  endpoint: Endpoint;
  payload: GetOwnerCreditRequestPayload;
  token: string;
})).pipe(flatMap((response) => handleResponse({
  response,
  onSuccess: getOwnerCreditSuccess,
  onFailure: getOwnerCreditFailure,
  parser: getOwnerCreditResponseParser,
})), catchError(handleErrorV5Response(action$, getOwnerCreditFailure)))));

export default getOwnerCredit$;
