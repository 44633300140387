import { useCallback } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { getPastPetCaregivers } from '../../actions/owner';
import { resetRequest } from '../../actions/requests';
import { PAST_PET_CAREGIVERS_GET_REQUEST_KEY } from '../../constants';
import { getRequestStates } from '../../selectors';
import { getPastPetCaregivers as getPastPetCaregiversSelector } from '../../reducers/owner';
import { ReduxState } from '../../reducers';

export const useGetPastPetCaregivers = () => {
  const data = useSelector(getPastPetCaregiversSelector);

  const getPastPetCaregiversRequestState = (state: ReduxState) =>
    getRequestStates(
      state,
      PAST_PET_CAREGIVERS_GET_REQUEST_KEY
    );

  const { error, isFetching, isSuccess } = useSelector(
    getPastPetCaregiversRequestState
  );
  const dispatch = useDispatch();

  const execute = () => {
    dispatch(getPastPetCaregivers());
  };

  const reset = () => {
    dispatch(
      resetRequest(PAST_PET_CAREGIVERS_GET_REQUEST_KEY)
    );
  };

  return {
    error,
    data,
    isLoading: isFetching,
    isSuccess,
    execute: useCallback(execute, [dispatch]),
    reset: useCallback(reset, [dispatch]),
  };
};
