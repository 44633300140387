import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_HOME_ACCESS_UPDATE, Endpoint } from "../../../constants/endpoints";
import { OWNER_HOME_ACCESS_UPDATE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { OwnerHomeAccessUpdatePayload, updateOwnerHomeAccessSuccess, updateOwnerHomeAccessFailure, OwnerHomeAccessUpdateAction } from "../../../actions/owner/updateOwnerHomeAccess";

const updateOwnerHomeAccess$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_HOME_ACCESS_UPDATE), retry(3), switchMap(({
  payload
}: OwnerHomeAccessUpdateAction) => post$(({
  endpoint: ENDPOINT_OWNER_HOME_ACCESS_UPDATE,
  payload: {
    id: getOwnerID(state$.value),
    home_access_details: payload
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: OwnerHomeAccessUpdatePayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updateOwnerHomeAccessSuccess,
  onFailure: updateOwnerHomeAccessFailure
})), catchError(handleErrorV5Response(action$, updateOwnerHomeAccessFailure)))));

export default updateOwnerHomeAccess$;