 
type Props = {
  key: string,
};

// See https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events-table
export const standardEventNames = {
  AddPaymentInfo: 'AddPaymentInfo',
  AddToCart: 'AddToCart',
  AddToWishlist: 'AddToWishlist',
  CompleteRegistration: 'CompleteRegistration',
  Contact: 'Contact',
  CustomizeProduct: 'CustomizeProduct',
  Donate: 'Donate',
  FindLocation: 'FindLocation',
  InitiateCheckout: 'InitiateCheckout',
  Lead: 'Lead',
  PageView: 'PageView',
  Purchase: 'Purchase',
  Schedule: 'Schedule',
  Search: 'Search',
  StartTrial: 'StartTrial',
  SubmitApplication: 'SubmitApplication',
  Subscribe: 'Subscribe',
  ViewContent: 'ViewContent',
};

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_FACEBOOK_TRACKING_URL) {
  throw new Error('Facebook tracking URL missing');
}

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_FACEBOOK_PIXEL_URL) {
  throw new Error('Facebook pixel URL missing');
}

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_FACEBOOK_KEY) {
  throw new Error('Facebook pixel URL missing');
}

class Facebook {
  constructor(props: Props) {
    if (process.env.REACT_APP_STAGE !== 'test' && !props.key) {
      throw new Error('Facebook key missing');
    }

    /* eslint-disable */
    (function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        // $FlowFixMe
        n && n.callMethod
          ? n.callMethod.apply(n, arguments) // $FlowFixMe
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode && s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */

    window.fbq('init', props.key);
    this.standardEvent('PageView');
  }

  standardEvent = (eventName: $Keys<typeof standardEventNames>, params?: Object) => {
    window.fbq('track', eventName, params);
  };

  // view events in Facebook event manager https://business.facebook.com/events_manager/?business_id=<OMMITTED>>&selected_data_sources=PIXEL
  event = (eventName: string, params?: Object) => {
    if (standardEventNames[eventName]) {
      this.standardEvent(standardEventNames[eventName], params);
    }
    window.fbq('trackCustom', eventName, params);
  };

  logout = () => {};
}

export default Facebook;
