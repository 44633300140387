
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalksInProgressParsedResponse,
} from 'webApp';

import {
  WALKS_IN_PROGRESS_GET,
  WALKS_IN_PROGRESS_GET_SUCCESS,
  WALKS_IN_PROGRESS_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walks in progress for the current user
 */
export type GetWalksInProgressAction = {
  type: typeof WALKS_IN_PROGRESS_GET,
};

export const getWalksInProgress: () => GetWalksInProgressAction = createAction(
  WALKS_IN_PROGRESS_GET,
);


/**
 * Get walks in progress for the current user success
 */
export type GetWalksInProgressSuccessAction = {
  type: typeof WALKS_IN_PROGRESS_GET_SUCCESS,
  payload: GetWalksInProgressParsedResponse,
};

export const getWalksInProgressSuccess: (
  GetWalksInProgressParsedResponse,
) => GetWalksInProgressSuccessAction = createAction(
  WALKS_IN_PROGRESS_GET_SUCCESS,
);


/**
 * Get walks in progress for the current user failure
 */
export type GetWalksInProgressFailureAction = {
  type: typeof WALKS_IN_PROGRESS_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalksInProgressFailure: (
  ErrorParsedResponse,
) => GetWalksInProgressFailureAction = createAction(
  WALKS_IN_PROGRESS_GET_FAILURE,
);
