import { DeepPartial } from '@reduxjs/toolkit';
import { omit } from 'ramda';

import { ReduxState } from '../reducers';

type MigrationFunction = (
  currentState: DeepPartial<ReduxState>,
  initialState: ReduxState,
) => DeepPartial<ReduxState>;
type MigrationHistory = {
  [version: number]: MigrationFunction;
};

/**
 * How to add a state migration?
 *
 * Whenever you want to change the value of an existing property or
 *  add a new property in the store, do the following:
 *
 * 1. Add a new migration version (eg: 1, 2, 3)
 * 2. Go to specific slice (eg: src/slices/config.ts)
 * 3. Update the initialState to have a defined structure and initial data
 * 4. On your MigrationFunction, utilize spread operator and do the necessary changes
 * 5. Update `migrationOptions` version to the version you've specified on step 1.
 *    After that, refresh the page. The new changes should now reflect.
 *
 * Whenever you want to delete a property, do the following:
 *
 * 1. Add a new migration version (eg: 1, 2, 3)
 * 2. Go to specific slice (eg: src/slices/config.ts)
 * 3. Remove the property in the respective initialState
 * 3. On your MigrationFunction, utilize spread operator and use `omit(...)` from Ramda
 *    to remove the property
 * 4. Update `migrationOptions` version to the version you've specified on step 1.
 *    After that, refresh the page. The new changes should now reflect.
 */
export const history: MigrationHistory = {
  1: (currentState, initialState) => {
    const nextState = ({
      ...currentState,
      config: {
        ...currentState.config,
        migration: {
          ...currentState.config?.migration,
          ...initialState.config.migration,
          value: (currentState.config?.migration?.value as number) || 1,
        } as ReduxState['config']['migration'],
      },
    });

    return omit(['form'], nextState);
  },
};

type MigrationOptions = {
  history: MigrationHistory;
  version: 0 | (keyof typeof history);
};

export const migrationOptions: MigrationOptions = {
  history,
  version: 1,
};
