import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { authSlice } from '../../slices/auth';

import { usePostAppleSocialAuthenticationMutation } from '../../slices/auth/socialAuthApi';
import { useAppleLogin } from '../../hooks/useAppleLogin';
import { tryCatch } from '../../utils/tryCatch';

export const useAppleLoginWithAuthentication = () => {
  const [
    executePostAppleSocialAuthentication,
    {
      isLoading: appleAuthenticationSubmitting,
      data: appleAuthenticationResponse,
      error: appleAuthenticationError,
      isError: appleAuthenticationIsError,
    }
  ] = usePostAppleSocialAuthenticationMutation();

  const {
    executeAppleLogin,
  } = useAppleLogin();

  const dispatch = useDispatch();

  const [appleLoginError, setAppleLoginError] = useState(null);

  const executeAppleLoginWithAuthentication = async (params) => {
    const [appleLoginResponse, appleLoginError] = await executeAppleLogin();

    if (appleLoginError) {
      setAppleLoginError(appleLoginError);
      return;
    }
    if (appleLoginResponse) {
      const {
        id_token: idToken,
      } = appleLoginResponse.authorization;

      if (idToken) {
        dispatch(authSlice.actions.setAppleIdToken(idToken));
        const appleLoginWithAuthenticationResponse = await executePostAppleSocialAuthentication({
          accessToken: idToken,
          ...params
        }).unwrap()

      return appleLoginWithAuthenticationResponse;
      }
    }
  }
  return {
    executeAppleLoginWithAuthentication,
    appleAuthenticationResponse,
    appleAuthenticationSubmitting,
    appleLoginWithAuthenticationError: appleAuthenticationError,
    appleLoginWithAuthenticationIsError: appleAuthenticationIsError,
  };
};
