
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';
import {
  OWNER_REGISTRATION_REQUEST_LOCKBOX,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
} from '../../actionTypes';


/**
 * Register owner request lockbox
 */
export type RegisterOwnerRequestLockboxPayload = {
  lockboxAddress: string,
};

export type RegisterOwnerRequestLockboxAction = {
  type: typeof OWNER_REGISTRATION_REQUEST_LOCKBOX,
  payload: RegisterOwnerRequestLockboxPayload,
};

export const registerOwnerRequestLockbox: (
  string,
) => RegisterOwnerRequestLockboxAction = createAction(
  OWNER_REGISTRATION_REQUEST_LOCKBOX,
  lockboxAddress => ({ lockboxAddress }),
);


/**
 * Register owner request lockbox success
 */
export type RegisterOwnerRequestLockboxSuccessAction = {
  type: typeof OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
};

export const registerOwnerRequestLockboxSuccess: ()
=> RegisterOwnerRequestLockboxSuccessAction = createAction(
  OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
);


/**
 * Register owner request lockbox failure
 */
export type RegisterOwnerRequestLockboxFailureAction = {
  type: typeof OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
  payload: ErrorParsedResponse,
};

export const registerOwnerRequestLockboxFailure: (
  ErrorParsedResponse,
) => RegisterOwnerRequestLockboxFailureAction = createAction(
  OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
);
