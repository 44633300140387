import { catchError, flatMap, switchMap, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { LockboxRequestPayload } from "wagAPI";
import { handleErrorResponse, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_REQUEST_LOCKBOX, Endpoint } from "../../../constants/endpoints";
import { OWNER_REGISTRATION_REQUEST_LOCKBOX } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { registerOwnerRequestLockboxSuccess, registerOwnerRequestLockboxFailure, RegisterOwnerRequestLockboxAction } from "../../../actions/registerOwner/registerOwnerRequestLockbox";

const registerOwnerRequestLockbox$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_REGISTRATION_REQUEST_LOCKBOX), retry(3), switchMap(({
  payload: {
    lockboxAddress
  }
}: RegisterOwnerRequestLockboxAction) => post$(({
  endpoint: ENDPOINT_REQUEST_LOCKBOX,
  payload: {
    lockbox_address: lockboxAddress,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: LockboxRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: registerOwnerRequestLockboxSuccess,
  onFailure: registerOwnerRequestLockboxFailure
})), catchError(response => handleErrorResponse({
  response,
  onError: registerOwnerRequestLockboxFailure
})))));

export default registerOwnerRequestLockbox$;