import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_PAYMENT_METHODS_GET, Endpoint } from "../../../constants/endpoints";
import { OWNER_PAYMENT_METHODS_GET } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { getOwnerPaymentMethodsSuccess, getOwnerPaymentMethodsFailure } from "../../../actions/owner/getOwnerPaymentMethods";
import { getOwnerPaymentMethodsResponseParser } from "../../../helpers/Http/ResponseParsers";

const getOwnerPaymentMethods$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_PAYMENT_METHODS_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_PAYMENT_METHODS_GET({
    ownerID: getOwnerID(state$.value)
  }),
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getOwnerPaymentMethodsSuccess,
  onFailure: getOwnerPaymentMethodsFailure,
  parser: getOwnerPaymentMethodsResponseParser
})), catchError(handleErrorV5Response(action$, getOwnerPaymentMethodsFailure)))));

export default getOwnerPaymentMethods$;