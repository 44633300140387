
import type { ErrorParsedResponse } from 'webApp';
import { createAction } from 'redux-actions';

import {
  WALK_RATE_AND_TIP,
  WALK_RATE_AND_TIP_SUCCESS,
  WALK_RATE_AND_TIP_FAILURE,
} from '../../actionTypes';

/**
 * Rate a walk and tip it
 */
export type RateAndTipWalkParams = {
  walkID: string,
  rating: string,
  comment: string,
  tip: number,
  shouldBlock: boolean,
  issues: Array<string>,
  ownerID: string,
};

export type RateAndTipWalkPayload = RateAndTipWalkParams;

export type RateAndTipWalkAction = {
  type: typeof WALK_RATE_AND_TIP,
  payload: RateAndTipWalkPayload,
};

export const rateAndTipWalk: (RateAndTipWalkParams) => RateAndTipWalkAction = createAction(
  WALK_RATE_AND_TIP,
);


/**
 * Rate a walk and tip it success
 */
export type RateAndTipWalkSuccess = {
  type: typeof WALK_RATE_AND_TIP_SUCCESS,
};

export const rateAndTipWalkSuccess: () => RateAndTipWalkSuccess = createAction(
  WALK_RATE_AND_TIP_SUCCESS,
);


/**
 * Rate a walk and tip it failure
 */
export type RateAndTipWalkFailure = {
  type: typeof WALK_RATE_AND_TIP_FAILURE,
  payload: ErrorParsedResponse,
};

export const rateAndTipWalkFailure: (
  ErrorParsedResponse,
) => RateAndTipWalkFailure = createAction(
  WALK_RATE_AND_TIP_FAILURE,
);
