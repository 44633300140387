import { combineEpics } from 'redux-observable';

import addPreferredWalker from './addPreferredWalker/addPreferredWalker.epic';
import applyPromoCode$ from './applyPromoCode/applyPromoCode.epic';
import changeOwnerPassword$ from './changeOwnerPassword/changeOwnerPassword.epic';
import createVisitorAccount$ from './createVisitorAccount/createVisitorAccount.epic';
import createOwnerPaymentMethod$ from './createOwnerPaymentMethod/createOwnerPaymentMethod.epic';
import createOwnerPreferredWalker$ from './createOwnerPreferredWalker/createOwnerPreferredWalker.epic';
import deleteOwnerPreferredWalker$ from './deleteOwnerPreferredWalker/deleteOwnerPreferredWalker.epic';
import getCreditPackages$ from './getCreditPackages/getCreditPackages.epic';
import getOwner$ from './getOwner/getOwner.epic';
import getOwnerCredit$ from './getOwnerCredit/getOwnerCredit.epic';
import getOwnerMaskedInfo$ from './getOwnerMaskedInfo/getOwnerMaskedInfo.epic';
import getSmartModules$ from './getSmartModules/getSmartModules.epic';
import getAvailableServices$ from './getAvailableServices/getAvailableServices.epic';
import getServiceProvider$ from './getServiceProviders/getServiceProviders.epic';
import getOwnerHomeAccess$ from './getOwnerHomeAccess/getOwnerHomeAccess.epic';
import getOwnerPaymentMethods$ from './getOwnerPaymentMethods/getOwnerPaymentMethods.epic';
import getOwnerServices$ from './getOwnerServices/getOwnerServices.epic';
import resetOwnerPassword$ from './resetOwnerPassword/resetOwnerPassword.epic';
import sendOwnerPasswordResetEmail$ from './sendOwnerPasswordResetEmail/sendOwnerPasswordResetEmail.epic';
import updateCreditCard$ from './updateCreditCard/updateCreditCard.epic';
import updateMyProfile$ from './updateMyProfile/updateMyProfile.epic';
import updateOwnerHomeAccess$ from './updateOwnerHomeAccess/updateOwnerHomeAccess.epic';
import updateOwnerPaymentMethod$ from './updateOwnerPaymentMethod/updateOwnerPaymentMethod.epic';
import updatePreferredWalker$ from './updatedPreferredWalker/updatePreferredWalker.epic';
import getOwnerRefreshToken$ from './getOwnerRefreshToken/getOwnerRefreshToken.epic';
import { getOwnerPremiumSubscription$ } from './getOwnerPremiumSubscription';
import { subscribeToPremium$ } from './subscribeToPremium';
import { getPastPetCaregivers$ } from './getPastPetCaregivers';

export default combineEpics(
  addPreferredWalker,
  applyPromoCode$,
  changeOwnerPassword$,
  createVisitorAccount$,
  createOwnerPaymentMethod$,
  createOwnerPreferredWalker$,
  deleteOwnerPreferredWalker$,
  getCreditPackages$,
  getOwner$,
  getOwnerCredit$,
  getOwnerMaskedInfo$,
  getPastPetCaregivers$,
  getServiceProvider$,
  getSmartModules$,
  getAvailableServices$,
  getOwnerHomeAccess$,
  getOwnerMaskedInfo$,
  getOwnerPaymentMethods$,
  getOwnerServices$,
  resetOwnerPassword$,
  sendOwnerPasswordResetEmail$,
  subscribeToPremium$,
  updateCreditCard$,
  updateMyProfile$,
  updatePreferredWalker$,
  updateOwnerHomeAccess$,
  updateOwnerPaymentMethod$,
  getOwnerRefreshToken$,
  getOwnerPremiumSubscription$
);
