
import type { ReduxState as State } from '../../../reducers';
import {
  recognizedUTMs,
  mapParamsToUTMs,
  UTMObject,
  UTM,
} from '../../../constants/app';

/**
 * Get the UTMS that the webapp recognizes out of the query params reducer
 *
 * @param {State} state
 * @param {UTM[]} matchingUTMS
 * @returns {UTMObject}
 */
export const getUTMS = (
  state: State,
  matchingUTMS: UTM[] = recognizedUTMs,
): UTMObject => {
  try {
    return matchingUTMS.reduce((acc, allowedUTM): UTMObject => {
      const possibleUTM = state.queryParams[allowedUTM];

      if (!possibleUTM) {
        // check if we need to rewrite a url param to match a utm param
        const key = mapParamsToUTMs[allowedUTM];
        const overwrittenUTM = state.queryParams[key];
        if (overwrittenUTM) {
          return {
            ...acc,
            [allowedUTM]: overwrittenUTM,
          };
        }
        // nothing found let's just return current accumulator
        return acc;
      }

      return {
        ...acc,
        [allowedUTM]: Array.isArray(possibleUTM) ? possibleUTM[0] : possibleUTM,
      };
    }, {});
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Unable to get UTMS from state: ', e); // eslint-disable-line no-console
    }

    return {};
  }
};

export default getUTMS;
