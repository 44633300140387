import { ApiErrorV4 } from '../buildApiErrorPipeline';

export const createErrorForV4 = (
  error: ApiErrorV4,
  newSourceError?: ApiErrorV4,
): ApiErrorV4 => {
  const v4Error = (new Error(error as any) as unknown) as ApiErrorV4;
  v4Error.version = 4;

  // Copy error object to another
  const source = newSourceError || error;
  return Object.getOwnPropertyNames(source).reduce((accumulator, key) => {
    accumulator[key] = source[key];
    return accumulator;
  }, v4Error);
};
