import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type CallIconProps = SvgIconProps;
export const CallIcon = ({
  color = '#00BF8F',
  ...rest
}: CallIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    color={color}
    {...rest}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <circle fill="#00BF8F" cx="20" cy="20" r="20" />
      <path
        d="M4 4h32v32H4z"
      />
      <path
        d="M25.72 22.447c-1.93-1.27-2.471-.934-3.196-.28l-.184.164c-.103.107-.388.354-.631.362a.32.32 0 0 1-.239-.099c-.566-.533-2.02-1.932-2.078-1.987a91.48 91.48 0 0 0-1.918-2.037c-.094-.094-.1-.167-.096-.213.016-.197.261-.417.345-.477.1-.088.173-.15.244-.21.766-.639 1.136-1.174-.17-3.2-1.07-1.657-1.73-2.32-2.439-2.448-.44-.08-.872.056-1.394.434-.905.625-1.8 1.335-1.944 2.523-.21 1.736 1.237 4.22 4.73 8.094C20.11 26.436 22.585 28 24.54 28c.065 0 .13-.002.194-.005 1.4-.076 2.293-.981 2.91-1.756.278-.33.39-.731.325-1.16-.127-.836-.905-1.746-2.25-2.632Z"
        fill="#FFF"
      />
    </g>
  </SvgIcon>
);
