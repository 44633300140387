export const STATUS_CODE_SUCCESS = 200;
export const STATUS_CODE_UNAUTHENTICATED = 401;
export const STATUS_CODE_PAYMENT_REQUIRED = 402;
export const STATUS_CODE_RESOURCE_NOT_FOUND = 404;
export const STATUS_CODE_CONFLICT = 409;
export const STATUS_CODE_PRECONDITION_FAILED = 412;
export const STATUS_CODE_SERVER_ERROR = 500;
export const STATUS_CODE_MAINTENACE = 503;
export const STATUS_CODE_SERVER_NOT_RESPONDING = 0;
// for custom errors we want to throw
export const STATUS_CODE_CUSTOM_ERROR = 9999;
export type StatusCode = typeof STATUS_CODE_SERVER_NOT_RESPONDING | typeof STATUS_CODE_SUCCESS | typeof STATUS_CODE_UNAUTHENTICATED | typeof STATUS_CODE_PAYMENT_REQUIRED | typeof STATUS_CODE_RESOURCE_NOT_FOUND | typeof STATUS_CODE_SERVER_ERROR | typeof STATUS_CODE_MAINTENACE;