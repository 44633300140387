import type { ReduxAction } from 'redux';

import { wagApi } from '../../../types/wagapi';

import { getErrorResponse } from '../handleErrorResponse/handleErrorResponse';

export const getErrorCodeFromResponse = (response: wagApi.ApiResponse) => {
  if (response && response.response) {
    // 'error_code' signifies an error in some cases with Wag
    if (response.response.error_code && response.response.error_code !== 200) {
      return response.response.error_code;
    }

    // 'code' also signifies an error in some cases with Wag
    if (response.response.code) {
      return response.response.code;
    }
  }

  // No error code
  return null;
};

export type HandleWagApiResponseFn = {
  response: wagApi.ApiResponse;
  onSuccess: ReduxAction;
  onFailure: ReduxAction;
  parser?: (data: any) => any;
  transformResponse?: (data: any) => any;
  isArray?: boolean;
  additionalData?: Record<string, any>;
};

export const getDataFromResponse = ({ response }: any) =>
  response || null;

export const handleWagApiResponse = ({
  response,
  onSuccess,
  onFailure,
  parser,
  transformResponse = (x) => x,
  isArray = true,
  additionalData = {},
}: HandleWagApiResponseFn) => {
  const transformedResponse = {
    ...response,
    response: transformResponse(response.response),
  };
  const errorCode = getErrorCodeFromResponse(transformedResponse);
  let data = getDataFromResponse(transformedResponse);

  // Add any additional data we might need
  if (typeof additionalData === 'object') {
    data = { ...data, ...additionalData };
  }

  if (errorCode) {
    return [onFailure(getErrorResponse(response))];
  }

  let returnData = onSuccess(data);

  if (parser) {
    returnData = onSuccess(parser(data));
  }

  if (isArray) {
    return [returnData];
  }

  return returnData;
};
export default handleWagApiResponse;
