import * as React from 'react';

import { useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import type {
  ParsedV4Dog,
  Owner,
} from 'webApp';

import Avatar from '@waglabs/avatar';
import { Button } from '@waglabs/button-next';
import Flex from '@waglabs/flex';
import Image from '@waglabs/image';
import Link from '@waglabs/link';
import Text from '@waglabs/text';

import defaultImage from '../../../../../../assets/svg/default-pet-profile-image-placeholder.svg';
import { ROUTE_PROFILE } from '../../../../../../constants';
import { useGetOwner } from '../../../../../../hooks';
import { petsSlice } from '../../../../../../slices/pets';
import { MyProfileSeparator } from '../../../../../MyProfile/components/MyProfileEdit/components/MyProfileForm/components/MyProfileSeparator/MyProfileSeparator';
import { SidebarBecomeAWalkerButton } from '../SidebarBecomeAWalkerButton/SidebarBecomeAWalkerButton';
import { SidebarNavigationItem } from '../SidebarNavigationItem/SidebarNavigationItem';

import AvatarPositions from './assets/AvatarPositions';
import { NavigationItemData } from './assets/NavigationItemData';
import { SidebarNavigationHoverContainer } from './components/SidebarNavigationHoverContainer';
import LogoutButtonContainer from './containers/LogoutButtonContainer';

type Props = {
  isVisible: string,
  dogs: ParsedV4Dog[],
  owner: Owner,
  onToggleSidebar: () => null,
}
export const SidebarNavigationContainer = ({
  isVisible,
  onToggleSidebar,
}: Props) => {
  const {
    data: owner,
  } = useGetOwner();
  const pets = useSelector(petsSlice.selectors.getAll);
  const petsProfilesToShow = pets.slice(0, 3);

  return (
    <Flex
      alignItems="center"
      bg="white"
      flexDirection="column"
      pt={3}
      pb={5}
      height={['100%', 'auto']}
      minHeight="100vh"
      flexShrink="0"
      width={[300, 360]}
      boxSizing="border-box"
      zIndex={10}
      position="absolute"
      left={isVisible ? '0' : [-300, -360, -360]}
      transition="0.3s ease-in-out left"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        mb={2}
        flexShrink="0"
        width={1}
      >
        <Flex
          flexDirection="row"
          position="relative"
          height={90}
          flexShrink="0"
          width={1}
          boxSizing="border-box"
        >
          <Flex
            position="absolute"
            left="50%"
            width={1}
            flexShrink="0"
            height="100%"
            transform="translateX(-50%)"
            boxSizing="border-box"
          >
            {petsProfilesToShow.map((pet, index) => (
              <Flex // Single image container
                key={pet.uuid}
                position="absolute"
                left={AvatarPositions[petsProfilesToShow.length][index].position}
                transform={AvatarPositions[petsProfilesToShow.length][index].transform}
                zIndex={AvatarPositions[petsProfilesToShow.length][index].zIndex}
                boxSizing="border-box"
              >
                {pet.imageUrl && (
                  <Avatar
                    src={pet.imageUrl}
                    bg="white"
                    containerProps={{
                      border: '3px solid white',
                    }}
                    imageProps={{
                      alt: pet.name,
                    }}
                  />
                )}
                {!pet.imageUrl && (
                  <Image
                    src={defaultImage}
                    width={90}
                    height={90}
                    borderRadius="64px"
                    alt={pet.name}
                  />
                )}
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Text
          fontSize={6}
          fontWeight="bold"
          textTransform="capitalize"
          mt={2}
          mb={0}
        >
          {`${owner.firstName} ${owner.lastName}`}
        </Text>
        <ReactRouterLink
          to={ROUTE_PROFILE}
        >
          <Button
            fontSize={[4, 4, 7]}
            mb={2}
          >
            View Profile
          </Button>
        </ReactRouterLink>
        <MyProfileSeparator />
      </Flex>
      <Link
        href="https://wagwalking.com/dog-walker"
      >
        <SidebarBecomeAWalkerButton />
      </Link>
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        flexShrink="0"
        bg="white"
        pt={3}
        width={1}
      >
        { NavigationItemData.map((item) => (
          <SidebarNavigationHoverContainer
            key={item.label}
            width={1}
            hover
            mb={[0, 0, 1]}
            data-testid={item.dataTestId}
          >
            <SidebarNavigationItem
              onClick={onToggleSidebar}
              href={item.path}
              iconName={item.iconName}
              label={item.label}
              {...item}
            />
          </SidebarNavigationHoverContainer>
        ))}
        <MyProfileSeparator />
        <LogoutButtonContainer />
      </Flex>
    </Flex>
  );
};
