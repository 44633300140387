import React from "react";
import { withRouter, Location } from "react-router-dom";
import Trigger from "../Trigger/Trigger";
import dataLayerInstance from "../Tracker/GoogleTagManager/DataLayer/dataLayerInstance";
type WrapperProps = {
  location: Location;
};
type Props = WrapperProps;

const onNavigate = () => dataLayerInstance.push({
  event: 'Navigate'
});

const NavTrigger = ({
  location: {
    pathname,
    search
  }
}: Props) => <Trigger watch={`${pathname}${search}`} onUpdate={onNavigate} />;

export default withRouter<any>(NavTrigger);