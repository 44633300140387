
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  OwnerPaymentMethodsParsedResponse,
} from 'webApp';

import {
  OWNER_PAYMENT_METHODS_GET,
  OWNER_PAYMENT_METHODS_GET_SUCCESS,
  OWNER_PAYMENT_METHODS_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get owner payment methods
 */
export type GetOwnerPaymentMethodsAction = {
  type: typeof OWNER_PAYMENT_METHODS_GET,
};

export const getOwnerPaymentMethods: () => GetOwnerPaymentMethodsAction = createAction(
  OWNER_PAYMENT_METHODS_GET,
);


/**
 * Get owner payment methods success
 */
export type GetOwnerPaymentMethodsSuccessAction = {
  type: typeof OWNER_PAYMENT_METHODS_GET_SUCCESS,
  payload: OwnerPaymentMethodsParsedResponse,
};

export const getOwnerPaymentMethodsSuccess: (
  OwnerPaymentMethodsParsedResponse,
) => GetOwnerPaymentMethodsSuccessAction = createAction(
  OWNER_PAYMENT_METHODS_GET_SUCCESS,
);


/**
 * Get owner payment methods failure
 */
export type GetOwnerPaymentMethodsFailureAction = {
  type: typeof OWNER_PAYMENT_METHODS_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerPaymentMethodsFailure: (
  ErrorParsedResponse,
) => GetOwnerPaymentMethodsFailureAction = createAction(
  OWNER_PAYMENT_METHODS_GET_FAILURE,
);
