import type { UpdateOwnerResponse, Walker as WalkerWagAPI } from "wagAPI";
import type { UpdateOwnerV5ParsedResponse, UpdatePetcoOwnerParsedResponse, Walker as WalkerWebApp } from "webApp";
export const parseWalker = ({
  bio,
  first_name: firstName,
  id,
  last_name: lastName,
  latitude,
  longitude,
  num_reviews: numReviews,
  phone,
  picture,
  rating,
  thumb,
  video
}: WalkerWagAPI): WalkerWebApp => ({
  bio,
  firstName,
  id,
  lastName,
  latitude,
  longitude,
  numReviews,
  phone: String(phone),
  picture,
  rating,
  thumb,
  video
});
export const updatePetcoOwnerResponseParser = ({
  owner
}: UpdateOwnerResponse): UpdatePetcoOwnerParsedResponse => ({
  address: {
    regionID: String(owner.region_id),
    cityID: String(owner.city_id),
    longitude: String(owner.longitude),
    latitude: String(owner.latitude),
    streetAddress: owner.address,
    apartment: owner.apt,
    state: owner.state,
    zipCode: owner.zipcode
  },
  dogID: String(owner.dog_id),
  // email: owner.email, // because of WEB-580, uncomment when SHRD-412 fix applied
  firstName: owner.first_name,
  lastName: owner.last_name,
  freeWalk: Boolean(owner.free_walk),
  isCardInvalid: Boolean(owner.is_card_invalid),
  // lastName: owner.last_name, // uncomment when SHRD-412 fix applied
  numberOfReferrals: owner.total_referral,
  numberOfWalksCompleted: owner.walk_completed_count,
  ownerID: owner.id,
  phone: String(owner.phone),
  preferredWalkers: Array.isArray(owner.owner_preferred_walkers) ? owner.owner_preferred_walkers.map((walkerWagApi: WalkerWagAPI): WalkerWebApp => parseWalker(walkerWagApi)) : []
});
export const updateOwnerResponseParser = ({
  first_name: firstName,
  last_name: lastName,
  phone,
  email,
  address: {
    address_1: streetAddress,
    address_2: apartment,
    state,
    zipcode: zipCode
  },
  latitude,
  longitude
}: UpdateOwnerResponse): UpdateOwnerV5ParsedResponse => ({
  address: {
    apartment,
    latitude,
    longitude,
    state,
    streetAddress,
    zipCode
  },
  email,
  firstName,
  lastName,
  phone
});
export default updateOwnerResponseParser;