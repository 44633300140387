import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetWalkerContactInfoPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_CONTACT_INFO, Endpoint } from "../../../constants/endpoints";
import { WALKER_CONTACT_INFO_GET } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { getWalkerContactInfoSuccess, getWalkerContactInfoFailure, GetWalkerContactInfoAction } from "../../../actions/walker/getWalkerContactInfo";

const getWalkerContactInfo$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKER_CONTACT_INFO_GET), mergeMap(({
  payload: {
    scheduleId,
    walkID
  }
}: GetWalkerContactInfoAction) => get$(({
  endpoint: ENDPOINT_WALKER_CONTACT_INFO,
  payload: {
    id: getOwnerID(state$.value),
    schedule_id: scheduleId,
    walk_id: walkID
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetWalkerContactInfoPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerContactInfoSuccess,
  onFailure: getWalkerContactInfoFailure
})), catchError(handleErrorV5Response(action$, getWalkerContactInfoFailure)))));

export default getWalkerContactInfo$;