import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { RateWalkRequest } from "wagAPI"; // eslint-disable-line

import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALK_RATE_AND_TIP_V5 } from "../../../constants/endpoints";
import { WALK_RATE_AND_TIP } from "../../../actions/actionTypes";
import { getOwnerToken } from "../../../selectors";
import { rateAndTipWalkSuccess, rateAndTipWalkFailure } from "../../../actions/walk/rateAndTipWalk";
import type { RateAndTipWalkAction } from "../../../actions/walk/rateAndTipWalk/rateAndTipWalk.actions";

// eslint-disable-line
const walkRateAndTip$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(WALK_RATE_AND_TIP), switchMap(({
  payload: {
    comment,
    rating,
    shouldBlock,
    tip,
    walkID,
    issues,
    ownerID
  }
}: RateAndTipWalkAction) => post$(({
  endpoint: ENDPOINT_WALK_RATE_AND_TIP_V5(ownerID, walkID),
  payload: {
    should_block_walker: shouldBlock,
    comment,
    rating: Number(rating),
    tip,
    reasons_for_bad_review: issues
  },
  token: getOwnerToken(state$.value)
} as {
  payload: RateWalkRequest;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: rateAndTipWalkSuccess,
  onFailure: rateAndTipWalkFailure
})), catchError(handleErrorV5Response(action$, rateAndTipWalkFailure)))));

export default walkRateAndTip$;