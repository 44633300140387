import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type QuestionFilledIconProps = SvgIconProps & JSX.IntrinsicElements['svg'];
export const QuestionFilledIcon = (props: QuestionFilledIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    color="#D8D8D8"
    {...props}
  >
    <path
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1Zm.11 15.032c-.859 0-1.555.652-1.555 1.484S11.251 19 12.11 19c.868 0 1.564-.652 1.564-1.484s-.696-1.484-1.564-1.484ZM12.47 5c-2.05 0-3.575.804-4.185 2.051-.19.36-.286.719-.286 1.125 0 .719.467 1.182 1.22 1.182.582 0 1.01-.265 1.25-.87.304-.841.934-1.295 1.868-1.295 1.049 0 1.773.643 1.773 1.57 0 .869-.372 1.342-1.601 2.07-1.125.652-1.707 1.39-1.707 2.505v.132c0 .775.477 1.323 1.287 1.323.677 0 1.049-.368 1.192-1.002.124-.794.41-1.2 1.678-1.928 1.344-.785 2.04-1.758 2.04-3.186C17 6.475 15.179 5 12.471 5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);
