import { createAction } from "redux-actions";
import { QUERY_PARAMS_RESET, QUERY_PARAMS_SET } from "../actionTypes";

/**
 * Set query params
 */
export type SetQueryParamsAction = {
  type: typeof QUERY_PARAMS_SET;
  payload: Record<string, string>;
};
export const setQueryParams: (arg0: Record<string, string>) => SetQueryParamsAction = createAction(QUERY_PARAMS_SET);

/**
 * Reset query params
 */
export type ResetQueryParamsAction = {
  type: typeof QUERY_PARAMS_RESET;
};
export const resetQueryParams: () => ResetQueryParamsAction = createAction(QUERY_PARAMS_RESET);