import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import type { BookingCancelScheduleRequestPayload } from 'wagAPI';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  Endpoint,
  ENDPOINT_BOOKING_CANCEL_SCHEDULE,
} from '../../../constants/endpoints';
import { BOOKING_CANCEL_SCHEDULE } from '../../../actions/actionTypes';
import { getOwnerToken, getOwnerID } from '../../../selectors';
import {
  bookingCancelScheduleSuccess,
  bookingCancelScheduleFailure,
  BookingCancelScheduleAction,
} from '../../../actions/booking/bookingCancelSchedule';
import { bookingCancelScheduleResponseParser } from '../../../helpers/Http/ResponseParsers';

export const cancelSchedule$$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies,
  { scheduleID, date, day, mode, cancellationReason }: any
) =>
  post$({
    endpoint: ENDPOINT_BOOKING_CANCEL_SCHEDULE,
    payload: {
      schedule_id: scheduleID,
      date,
      day,
      mode,
      id: getOwnerID(state$.value),
      cancellation_reason: cancellationReason,
    },
    token: getOwnerToken(state$.value),
  } as {
    endpoint: Endpoint;
    payload: BookingCancelScheduleRequestPayload;
    token: string;
  }).pipe(
    flatMap((response) =>
      handleResponse({
        response,
        onSuccess: bookingCancelScheduleSuccess,
        onFailure: bookingCancelScheduleFailure,
        parser: bookingCancelScheduleResponseParser,
      } as any) // TODO - Update typings on handleResponse
    ),
    catchError(handleErrorV5Response(action$, bookingCancelScheduleFailure))
  );

const cancelSchedule$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(BOOKING_CANCEL_SCHEDULE),
    retry(3),
    switchMap(
      ({
        payload: { scheduleID, date, day, mode, cancellationReason },
      }: BookingCancelScheduleAction) =>
        cancelSchedule$$(
          action$,
          state$,
          {
            post$,
          },
          {
            scheduleID,
            date,
            day,
            mode,
            cancellationReason,
          }
        )
    )
  );

export default cancelSchedule$;
