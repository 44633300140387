import {
  createAction,
  createSlice,
} from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import {
  AUTHENTICATION_VERIFY_POST_SUCCESS,
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
} from '../../actions/actionTypes';
import { ReduxState } from '../../reducers';
import {
  initialState as oldInitialState,
  State as WalkerState,
} from '../../reducers/walker/walker.reducer';

const initialState: WalkerState = {
  ...oldInitialState,
  // add new initial state
};

export const setWalkerId = createAction<any>('walker/setWalkerId');

const slice = createSlice({
  name: 'walker',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setWalkerId, (state, action) => ({
      ...state,
      walker: {
        id: action.payload,
        roleId: action.payload,
      },
    }));
    builder.addMatcher<AnyAction>(
      (action) => [
        AUTHENTICATION_VERIFY_POST_SUCCESS,
        AUTHENTICATION_PASSWORD_POST_SUCCESS,
      ].includes(action.type),
      (state, {
        payload,
      }) => ({
        ...state,
        authToken: payload.session.token,
        walker: {
          id: payload.id,
          roleId: payload.id,
        },
      }),
    );
  },
});

// selector functions
const selectors = {
  getToken: (state: ReduxState) => state.walker.authToken,
  getWalkerId: (state: ReduxState) => state.walker.walker.id,
  getWalker: (state: ReduxState) => ({
    ...state.walker.walker,
    fullname: [state.walker.walker.firstName, state.walker.walker.lastName].filter(Boolean).join(' '),
  }),
};

export const walkerSlice = {
  ...slice,
  selectors,
};
