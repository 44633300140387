
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  OwnerCreditParsedResponse,
} from 'webApp';

import {
  OWNER_CREDIT_GET,
  OWNER_CREDIT_GET_SUCCESS,
  OWNER_CREDIT_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get owner credit
 */
export type GetOwnerCreditAction = {
  type: typeof OWNER_CREDIT_GET,
};

export const getOwnerCredit: () => GetOwnerCreditAction = createAction(
  OWNER_CREDIT_GET,
);


/**
 * Get owner credit success
 */

export type GetOwnerCreditSuccessAction = {
  type: typeof OWNER_CREDIT_GET_SUCCESS,
  payload: OwnerCreditParsedResponse,
};

export const getOwnerCreditSuccess: (
  OwnerCreditParsedResponse,
) => GetOwnerCreditSuccessAction = createAction(
  OWNER_CREDIT_GET_SUCCESS,
);


/**
 * Get owner credit failure
 */
export type GetOwnerCreditFailureAction = {
  type: typeof OWNER_CREDIT_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerCreditFailure: (
  ErrorParsedResponse,
) => GetOwnerCreditFailureAction = createAction(
  OWNER_CREDIT_GET_FAILURE,
);
