import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type WellnessPlanGoldIconProps = SvgIconProps;

export const WellnessPlanGoldIcon = (props: WellnessPlanGoldIconProps) => (
  <SvgIcon
    viewBox="0 0 42 42"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M15.37 14.718h11.306a1.1 1.1 0 0 1 1.062.812l2.43 8.943a1.1 1.1 0 0 1-1.063 1.389H12.901a1.1 1.1 0 0 1-1.06-1.394l2.468-8.943a1.1 1.1 0 0 1 1.061-.807Z"
        fill="#F9CD46"
      />
      <path
        d="M15.37 14.718h11.306a1.1 1.1 0 0 1 1.062.812l.195.715h-11.38a1.1 1.1 0 0 0-1.056.79l-2.607 8.827a1.092 1.092 0 0 1-1.053-1.384l2.472-8.953a1.1 1.1 0 0 1 1.061-.807Z"
        fill="#FABC44"
      />
      <path
        d="M25.826 26.856h11.306a1.1 1.1 0 0 1 1.062.812l2.429 8.943A1.1 1.1 0 0 1 39.56 38H23.357a1.1 1.1 0 0 1-1.061-1.393l2.469-8.943a1.1 1.1 0 0 1 1.06-.808Z"
        fill="#F9CD46"
      />
      <path
        d="M25.826 26.856h11.306a1.1 1.1 0 0 1 1.062.812l.194.716h-11.38a1.1 1.1 0 0 0-1.056.789L23.346 38a1.092 1.092 0 0 1-1.053-1.383l2.472-8.953a1.1 1.1 0 0 1 1.06-.808Z"
        fill="#FABC44"
      />
      <path
        d="M4.915 26.856H16.22a1.1 1.1 0 0 1 1.062.812l2.429 8.943A1.1 1.1 0 0 1 18.65 38H2.446a1.1 1.1 0 0 1-1.061-1.393l2.469-8.943a1.1 1.1 0 0 1 1.06-.808Z"
        fill="#F9CD46"
      />
      <path
        d="M4.915 26.856H16.22a1.1 1.1 0 0 1 1.062.812l.194.716H6.097a1.1 1.1 0 0 0-1.056.789L2.435 38a1.092 1.092 0 0 1-1.053-1.383l2.472-8.953a1.1 1.1 0 0 1 1.06-.808Z"
        fill="#FABC44"
      />
      <path
        d="M28.108 5.207c1.266 1.166 2.64 1.849 4.126 2.049 1.485.2 2.946-.142 4.384-1.025-1.125 1.275-1.82 2.5-2.087 3.676-.267 1.175.083 2.806 1.051 4.891-.83-1.225-2.074-1.905-3.733-2.038-1.66-.133-3.287.33-4.883 1.392 1.39-1.231 2.192-2.646 2.404-4.245.213-1.599-.208-3.166-1.262-4.7ZM12.346 3c.874.805 1.823 1.276 2.848 1.414 1.025.138 2.034-.098 3.027-.707-.776.88-1.257 1.726-1.441 2.537-.184.812.058 1.937.726 3.377-.573-.846-1.432-1.315-2.577-1.407-1.146-.092-2.27.228-3.37.96.959-.85 1.512-1.826 1.659-2.93.147-1.103-.144-2.185-.872-3.244ZM2.441 17.345c.874.805 1.823 1.276 2.848 1.415 1.025.138 2.034-.098 3.026-.708-.776.88-1.256 1.726-1.44 2.538-.185.811.057 1.937.726 3.376-.573-.846-1.432-1.315-2.577-1.407-1.146-.092-2.27.229-3.371.961.96-.85 1.513-1.827 1.66-2.93.147-1.104-.144-2.185-.872-3.245Z"
        fill="#F9CD46"
      />
    </g>
  </SvgIcon>
);
