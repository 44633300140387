import {
  useEffect,
  useState,
} from 'react';

import { BRANCH_WINDOW_KEY } from '../constants';

export const useBranch = () => {
  const [branch, setBranch] = useState<typeof window.branch | null>(null);

  useEffect(() => {
    const windowBranch = window[BRANCH_WINDOW_KEY];

    if (!windowBranch) {
      // eslint-disable-next-line no-console
      console.error('Failed to retrieve Branch instance');
      return;
    }

    setBranch(windowBranch);
  }, []);
  return branch;
};
