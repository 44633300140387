
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  AUTHENTICATION_CODE_POST,
  AUTHENTICATION_CODE_POST_SUCCESS,
  AUTHENTICATION_CODE_POST_FAILURE,
} from '../../actionTypes';

/**
 * Post authentication code
 */
export type PostAuthenticationCodeParams = {
  sessionToken?: string,
  authenticationMethod: string,
};

export type PostAuthenticationCodePayload = PostAuthenticationCodeParams;

export type PostAuthenticationCodeAction = {
  type: typeof AUTHENTICATION_CODE_POST,
  payload: PostAuthenticationCodePayload,
};

export const postAuthenticationCode: (
  params: PostAuthenticationCodeParams,
) => PostAuthenticationCodeAction = createAction(
  AUTHENTICATION_CODE_POST,
);

/**
 * Post authentication code success action
 */
export type PostAuthenticationCodeSuccessAction = {
  type: typeof AUTHENTICATION_CODE_POST_SUCCESS,
};

export const postAuthenticationCodeSuccess: (
  response: webApp.PostAuthenticationCodeParsedResponse
) => PostAuthenticationCodeSuccessAction = createAction(
  AUTHENTICATION_CODE_POST_SUCCESS,
);

/**
 * Post authentication code failure action
 */
export type PostAuthenticationCodeFailureAction = {
  type: typeof AUTHENTICATION_CODE_POST_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const postAuthenticationCodeFailure: (
  error: webApp.ErrorParsedResponse,
) => PostAuthenticationCodeFailureAction = createAction(
  AUTHENTICATION_CODE_POST_FAILURE,
);
