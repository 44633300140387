import { handleActions } from 'redux-actions';
import type { DogBreedAssets } from 'webApp';

import {
  DOG_BREED_ASSETS_GET_SUCCESS,
  DOG_BREED_ASSETS_GET_FAILURE,
} from '../../actions/actionTypes';
import type { GetDogBreedAssetsSuccessAction } from '../../actions/dog/getDogBreedAssets';
import TeenImage from '../../assets/svg/dog-middle-age.svg';
import AdultImage from '../../assets/svg/dog-old-age.svg';
import PuppyImage from '../../assets/svg/dog-puppy.svg';

export type DogBreedAssetsState = DogBreedAssets;
export const initialState: DogBreedAssetsState = {
  adultImage: AdultImage,
  name: 'Golden Retriever',
  puppyImage: PuppyImage,
  teenImage: TeenImage,
};
export const reducer: any = handleActions({
  [DOG_BREED_ASSETS_GET_SUCCESS]: (state: DogBreedAssetsState, {
    payload,
  }: GetDogBreedAssetsSuccessAction): DogBreedAssetsState => payload,
  [DOG_BREED_ASSETS_GET_FAILURE]: (): DogBreedAssetsState => initialState,
}, initialState);
export default reducer;
