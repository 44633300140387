import { useSelector } from 'react-redux';

import { walkerSlice } from '../slices/walker';

export const useGetWalkerAuth = () => {
  const token = useSelector(walkerSlice.selectors.getToken);
  const walkerId = useSelector(walkerSlice.selectors.getWalkerId);

  return {
    token,
    id: walkerId,
  };
};
