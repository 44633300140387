import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_HOME_ACCESS_GET, Endpoint } from "../../../constants/endpoints";
import { OWNER_HOME_ACCESS_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getOwnerHomeAccessSuccess, getOwnerHomeAccessFailure } from "../../../actions/owner/getOwnerHomeAccess";

const getOwnerHomeAccess$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_HOME_ACCESS_GET), retry(3), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_HOME_ACCESS_GET,
  payload: {
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: {
    id: string;
  };
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getOwnerHomeAccessSuccess,
  onFailure: getOwnerHomeAccessFailure
})), catchError(handleErrorV5Response(action$, getOwnerHomeAccessFailure)))));

export default getOwnerHomeAccess$;