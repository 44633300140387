import type { ReduxState as State } from '../index';

export const getPreferredMethod = (state: State) => state.auth && state.auth.preferredMethod;

export const getAllowedMethods = (state: State) => (state.auth && state.auth.allowedMethods) || [];

export const getAllowedMethodsExcludingPreferredMethod = (state: State) => (
  getAllowedMethods(state).filter(
    (method) => getPreferredMethod(state) !== method,
  )
);
export const getSessionToken = (state: State) => state.auth && state.auth.sessionToken;

export const getToken = (state: State) => state.auth && state.auth.ownerToken;

export const getLegacyOwnerToken = (state: State) => state.auth && state.auth.legacyToken;

export const getOwnerID = (state: State) => state.auth && state.auth.ownerID;

// Both should have value otherwise it is false
export const isLoggedIn = (state: State) => !!(getToken(state) && getOwnerID(state)) || false;

export const getSessionTokenCreatedAt = (state: State) => state.auth
  && state.auth.sessionTokenCreatedAt
  && new Date(state.auth.sessionTokenCreatedAt);
