import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type WellnessNavigationOutlinedIconProps = SvgIconProps;
export const WellnessNavigationOutlinedIcon = (
  props: WellnessNavigationOutlinedIconProps
) => (
  <SvgIcon viewBox="0 0 30 28" {...props}>
    <g
      fill="#AAA"
      fillRule="nonzero"
    >
      <path
        d="M14.989 27a.54.54 0 0 1-.403-.18c-.437-.464-10.719-11.38-12.31-13.967C1.427 11.475 1 10.04 1 8.616 1 4.424 4.394 1 8.571 1A7.567 7.567 0 0 1 15 4.582 7.567 7.567 0 0 1 21.429 1C25.606 1 29 4.412 29 8.616c0 1.424-.426 2.859-1.277 4.237-1.59 2.588-11.872 13.503-12.309 13.966a.63.63 0 0 1-.425.181ZM8.56 2.141c-3.562 0-6.451 2.904-6.451 6.486 0 1.22.37 2.44 1.109 3.639 1.355 2.214 9.8 11.254 11.77 13.344 1.96-2.09 10.406-11.141 11.772-13.344.74-1.198 1.109-2.43 1.109-3.639 0-3.57-2.89-6.486-6.451-6.486a6.455 6.455 0 0 0-5.914 3.887c-.18.407-.851.407-1.03 0A6.455 6.455 0 0 0 8.56 2.141Z"
        stroke="#AAA"
        strokeWidth=".6"
      />
      <path
        d="M12.434 18c-.524 0-1.005-.192-1.373-.84-.708-1.244.156-2.165.917-2.973.184-.196.372-.396.54-.6.108-.132.212-.304.324-.488.384-.632.912-1.5 2.128-1.5h.048c1.216 0 1.744.872 2.128 1.508.112.188.22.364.328.496.168.208.364.412.552.612.753.796 1.609 1.697.905 2.933-.645 1.132-1.64.88-2.6.632-.433-.112-.88-.224-1.313-.224h-.048c-.436 0-.884.116-1.32.232-.408.104-.824.212-1.216.212Zm6.189-4.985a.984.984 0 0 1-.577-.18c-.52-.364-.616-1.168-.208-1.8.22-.34.548-.577.905-.649.288-.056.568 0 .796.156.52.364.616 1.169.208 1.8-.22.34-.548.577-.904.649a1.058 1.058 0 0 1-.22.024Zm-7.25 0c-.072 0-.144-.008-.216-.02a1.433 1.433 0 0 1-.904-.648c-.408-.632-.312-1.437.208-1.8a1.04 1.04 0 0 1 .796-.157c.357.072.685.308.905.648.408.633.312 1.437-.208 1.8a1.022 1.022 0 0 1-.58.177Zm5.041-2.12a.949.949 0 0 1-.26-.037c-.636-.172-.996-.944-.808-1.72.104-.428.356-.784.692-.98.26-.152.548-.196.816-.124.636.172.996.944.808 1.72-.104.428-.356.784-.688.98-.18.104-.372.16-.56.16Zm-2.832 0c-.188 0-.38-.053-.556-.157-.336-.196-.584-.556-.688-.98-.188-.776.172-1.548.808-1.72.268-.076.56-.032.816.124.336.196.588.556.692.98.188.776-.172 1.548-.808 1.72-.088.02-.176.032-.264.032Z"
      />
    </g>
  </SvgIcon>
);
