import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const PriceTagIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 15 15"
    color="#FFF"
    {...props}
  >
    <g
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M13.442.2v5.126L5.66 12.974c-.322.368-.672.368-1.049 0-.566-.553-3.76-3.76-4.06-4.143C.35 8.576.35 8.24.55 7.818L7.964.2h5.477ZM10.42 1.843c-.75 0-1.358.604-1.358 1.35 0 .745.608 1.35 1.358 1.35.75 0 1.358-.605 1.358-1.35 0-.746-.608-1.35-1.358-1.35Z"
      />
      <path
        d="M14.8 1.55v5.125l-7.782 7.649c-.322.368-.672.368-1.05 0a66.466 66.466 0 0 1-.424-.421c.264.062.459-.17.767-.433.31-.265 2.903-2.814 7.782-7.649V1.55h.707Z"
      />
    </g>
  </SvgIcon>
);
