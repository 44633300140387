
import { createAction } from 'redux-actions';
import {
  LOGOUT,
  SET_BROWSER_ID,
} from '../../actionTypes';

/**
 * Logout the authenticated user
 */
export type LogoutAction = {
  type: typeof LOGOUT,
};

export const logout: () => LogoutAction = createAction(LOGOUT, () => null);

/**
 * Set the current browser id
 */
export type SetBrowserIDPayload = {
  browserID: string,
};

export type SetBrowserIDAction = {
  type: typeof SET_BROWSER_ID,
  payload: SetBrowserIDPayload,
};

export const setBrowserID: (string) => SetBrowserIDAction = createAction(
  SET_BROWSER_ID,
  browserID => ({ browserID }),
);
