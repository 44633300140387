import { useMediaQuery as useRRMediaQuery } from "react-responsive";
import { useStyledTheme } from "./useStyledTheme";
export const useMediaQuery = () => {
  const {
    theme
  } = useStyledTheme();
  const isTablet = useRRMediaQuery({
    minWidth: theme.breakpoints[0]
  });
  const isDesktop = useRRMediaQuery({
    minWidth: theme.breakpoints[1]
  });
  return {
    isDesktop,
    isTablet,
    isMobile: !isTablet && !isDesktop
  };
};