import { useSelector, useDispatch } from "react-redux";
import { getRequestStates } from "../../selectors";
import { OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY } from "../../constants";
import { deleteOwnerPreferredWalker } from "../../actions/owner/deleteOwnerPreferredWalker";
export const useDeleteOwnerPreferredWalker = () => {
  const getOwnerServiceProviderRequestState = state => getRequestStates(state, OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY);

  const {
    error,
    isFetching,
    isSuccess
  } = useSelector(getOwnerServiceProviderRequestState);
  const dispatch = useDispatch();

  const execute = (walkerID: string) => {
    dispatch(deleteOwnerPreferredWalker({
      walkerID
    }));
  };

  return {
    // Don't need the response from the server here because it doesn't return the walker.
    data: null,
    error,
    isLoading: isFetching,
    isSuccess,
    execute
  };
};