import type { ParsedWalk } from 'webApp';

type Walk = {
  startTime: string;
  startDate: string;
};
export const parseTime = (walk: Walk): number[] => walk.startTime.split(':').map((n) => Number(n));
export const parseDate = (walk: Walk): number[] => walk.startDate.split('/').map((n) => Number(n));
export const parseWalk = (walk: Walk): ParsedWalk => {
  const [hour, minute, second] = parseTime(walk);
  const [month, day, year] = parseDate(walk);
  return {
    day,
    hour,
    minute,
    month,
    second,
    year,
  };
};
export const sortByWalkDate = (a: Walk, b: Walk): 1 | -1 | 0 => {
  const parsedA = parseWalk(a);
  const parsedB = parseWalk(b);
  return ['year', 'month', 'day', 'hour', 'minute', 'second'].reduce((acc, ele) => {
    if (acc === 0) {
      if (parsedA[ele] < parsedB[ele]) {
        return -1;
      }

      if (parsedA[ele] > parsedB[ele]) {
        return 1;
      }

      return 0;
    }

    return acc;
  }, 0);
};
export default sortByWalkDate;
