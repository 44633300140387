import { useSelector, useDispatch } from "react-redux";
import { getRequestStates } from "../../selectors";
import { OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY } from "../../constants";
import { createOwnerPreferredWalker } from "../../actions/owner/createOwnerPreferredWalker";
export const useCreateOwnerPreferredWalker = () => {
  const getOwnerServiceProviderRequestState = state => getRequestStates(state, OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY);

  const {
    error,
    isFetching,
    isSuccess
  } = useSelector(getOwnerServiceProviderRequestState);
  const dispatch = useDispatch();

  const execute = (walkerID: string) => {
    dispatch(createOwnerPreferredWalker({
      walkerID
    }));
  };

  return {
    // Don't need the response from the server here because it doesn't return the walker.
    data: null,
    error,
    isLoading: isFetching,
    isSuccess,
    execute
  };
};