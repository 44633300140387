import moment from 'moment';

export const clearObject = (myObject) => {
  for (const member in myObject) delete myObject[member]; // eslint-disable-line

};
export const compose = (...fns) => (...args) => fns.forEach((fn) => fn && fn(...args));
export const hasNestedProperty = (object, property) => {
  if (object && typeof object === 'object') {
    if (typeof property === 'string' && property !== '') {
      const split = property.split('.');
      return split.reduce((obj, prop, i, array) => {
        if (i === array.length - 1) {
          return !!(obj !== null && typeof obj === 'object' && prop in obj);
        }

        return obj && obj[prop];
      }, object);
    }

    if (typeof property === 'number') {
      return property in object;
    }

    return false;
  }

  return false;
};
export const path = (property) => (object) => {
  if (object && typeof object === 'object') {
    if (typeof property === 'string' && property !== '') {
      const split = property.split('.');
      return split.reduce((obj, prop, i, array) => {
        if (i === array.length - 1) {
          return obj !== null && typeof obj === 'object' && prop in obj ? obj[prop] : undefined;
        }

        return obj && obj[prop] ? obj[prop] : undefined;
      }, object);
    }

    if (typeof property === 'number') {
      return object[property];
    }

    return undefined;
  }

  return undefined;
};
export const getName = ({
  firstName = '',
  lastName = '',
}) => [firstName, lastName].join(' ');
export const formatName = (name, showLastName) => {
  const names = name.split(' ');
  const firstName = names.shift();
  const lastName = names.join(' ');
  let displayName = firstName;

  if (lastName && showLastName) {
    displayName = `${displayName} ${lastName}`;
  } else if (lastName) {
    displayName = `${displayName} ${lastName[0]}`;
  }

  return displayName;
};
export const getRating = ({
  rating = 0,
}) => +rating;

export const isDateInThePast = (date) => {
  const momentedStartDate = moment(date, 'YYYY-MM-DD');
  return momentedStartDate.isBefore(moment(), 'day');
};

export * from './ageToDate';
export * from './arrayToObject';
export * from './dateToAge';
export * from './forms';
export * from './getServiceCostDetails';
export * from './pickQueryStrings';
export * from './tryCatch';
export * from './currencyCalculator';
export * from './getFormikHelper';
export * from './google';
export * from './isObjEmpty';
export * from './listStrings';
export * from './monads';
export * from './omit';
export * from './pipe';
export * from './range';
export * from './smartModulesSorter';
export * from './validation-schemas';
