import * as React from 'react';
import Flex from '@waglabs/flex';
import { useStyledTheme } from '../../../../hooks/useStyledTheme';
import { MobileNavigationTitle } from './components/MobileNavigationTitle';
import type {
  MobileNavigationTitleProps,
} from './components/MobileNavigationTitle';
import { BodyContainer } from '../../../../components/BodyContainer/BodyContainer';
import { Back } from './components/Back/Back';

type Props = {
  renderWagLeftButton?: (any) => React$Node,
  renderWagRightButton?: () => React$Node,
  title?: string,
  onToggleSidebar?: () => null,
  hideNavOnMobile?: boolean,
  history: any,
  mobileNavigationTitleProps?: MobileNavigationTitleProps;
};

export const Navigation = ({
  renderWagLeftButton,
  renderWagRightButton,
  title,
  onToggleSidebar,
  hideNavOnMobile,
  history,
  mobileNavigationTitleProps,
  ...rest
}: Props) => {
  const { isPartnerMode } = useStyledTheme();
  const handleLeftButton = () => (
    <>
      {renderWagLeftButton && onToggleSidebar
        && renderWagLeftButton({ onToggleSidebar })}
      {renderWagLeftButton && !onToggleSidebar
        && renderWagLeftButton()}
    </>
  );

  return (
    <Flex
      width={1}
      px={[0, 0, 4]}
      height={60}
      borderBottom={1}
      borderColor="backgroundGray"
      justifyContent="center"
      boxSizing="border-box"
      alignItems="center"
      flexDirection="row"
      position="fixed"
      bg="white"
      zIndex={5}
      display={hideNavOnMobile ? ['none', 'none', 'flex'] : 'flex'}
      {...rest}
    >
      <BodyContainer>
        <Flex
          width={1}
          alignItems="center"
          justifyContent="space-between"
          boxSizing="border-box"
          position="relative"
          px={[3, 3, 0]}
        >
          {isPartnerMode ? (
            <Back />
          ) : handleLeftButton()}

          <MobileNavigationTitle
            title={title || ''}
            {...mobileNavigationTitleProps}
          />

          {renderWagRightButton && renderWagRightButton()}
        </Flex>
      </BodyContainer>
    </Flex>
  );
};

export default Navigation;
