import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleV5Response } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_AVAILABLE_SERVICES_V5 } from "../../../constants/endpoints";
import { OWNER_AVAILABLE_SERVICES } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getAvailableServicesSuccess, getAvailableServicesFailure } from "../../../actions/owner/getAvailableServices";
import { getAvailableServicesResponseParser } from "../../../helpers/Http/ResponseParsers";

const getAvailableServices$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_AVAILABLE_SERVICES), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_AVAILABLE_SERVICES_V5({
    ownerID: getOwnerID(state$.value)
  }),
  token: getOwnerToken(state$.value)
} as {
  endpoint: string;
  token: string;
})).pipe(flatMap(response => handleV5Response({
  response,
  onSuccess: getAvailableServicesSuccess,
  onFailure: getAvailableServicesFailure,
  parser: getAvailableServicesResponseParser
})), catchError(handleErrorV5Response(action$, getAvailableServicesFailure)))));

export default getAvailableServices$;