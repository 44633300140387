import { useDispatch } from "react-redux";
import { resetRequest } from "../actions/requests";
export const useResetRequest = (requestKey: string) => {
  const dispatch = useDispatch();

  const execute = () => {
    dispatch(resetRequest(requestKey));
  };

  return {
    execute
  };
};