import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_PROFILE_BY_PROMO_CODE_GET, Endpoint } from "../../../constants/endpoints";
import { WALKER_PROFILE_BY_PROMO_CODE_GET } from "../../../actions/actionTypes";
import { getOwnerToken } from "../../../selectors";
import { getWalkerProfileByPromoCodeSuccess, getWalkerProfileByPromoCodeFailure } from "../../../actions/walker/getWalkerProfileByPromoCode";
import { getWalkerProfileByPromoCodeResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalkerProfileByPromoCode$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKER_PROFILE_BY_PROMO_CODE_GET), retry(3), switchMap(({
  payload: {
    promoCode
  }
}) => get$(({
  endpoint: ENDPOINT_WALKER_PROFILE_BY_PROMO_CODE_GET({
    promoCode
  }),
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerProfileByPromoCodeSuccess,
  onFailure: getWalkerProfileByPromoCodeFailure,
  parser: getWalkerProfileByPromoCodeResponseParser
})), catchError(handleErrorV5Response(action$, getWalkerProfileByPromoCodeFailure)))));

export default getWalkerProfileByPromoCode$;