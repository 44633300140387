import { handleActions } from 'redux-actions';

import {
  GLOBAL_ERROR_SHOW,
  GLOBAL_ERROR_HIDE,
} from '../../actions/actionTypes';
import type { ShowErrorAction } from '../../actions/error';

export type ErrorState = {
  title?: string;
  message: string;
};
export const initialState = {
  title: '',
  message: '',
};
export const reducer: any = handleActions({
  [GLOBAL_ERROR_SHOW]: (state: ErrorState, {
    payload: {
      title,
      message,
    },
  }: ShowErrorAction): ErrorState => ({ ...state,
    title,
    message }),
  [GLOBAL_ERROR_HIDE]: (state: ErrorState): ErrorState => ({ ...state }),
}, initialState);
export default reducer;
