import {
  handleActions,
  combineActions,
} from 'redux-actions';

import {
  LOGOUT,
  QUERY_PARAMS_RESET,
  QUERY_PARAMS_SET,
} from '../../actions/actionTypes';
import type { LogoutAction } from '../../actions/owner/auth';
import type {
  SetQueryParamsAction,
  ResetQueryParamsAction,
} from '../../actions/queryParams';

export type QueryParamsState = Record<string, string>;

type Action = SetQueryParamsAction | ResetQueryParamsAction | LogoutAction;

export const initialState: QueryParamsState = {};

const reducer = handleActions<QueryParamsState, Action>({
  [QUERY_PARAMS_SET]: (state, action) => {
    if (action.payload) {
      const newPayload = action.payload;
      const keys = Object.keys(action.payload);

      keys.forEach((key) => {
        // Don't handle any logic below if this is a non-UTM parameter
        if (!key.startsWith('utm_')) {
          return;
        }

        // If any UTM params are arrays, extract the first value before
        // saving the UTMs into the store
        if (Array.isArray(action.payload[key])) {
          newPayload[key] = action.payload[key][0];
        }
      });

      return {
        ...state,
        ...newPayload,
      };
    }

    return state;
  },
  [combineActions(LOGOUT, QUERY_PARAMS_RESET)]: () => initialState,
}, initialState);

export default reducer;
