 // NOTE: This is the new navigation that will replace
// the old one
import * as React from 'react';

import Flex from '@waglabs/flex';

import { MobileNavigationTitle } from './components/MobileNavigationTitle';
import { BodyContainer } from '../../../../components/BodyContainer/BodyContainer';

import {
  HEADER_HEIGHT,
} from '../../../../constants/styling';

type Props = {
  title?: string,
  onToggleSidebar?: () => null,
  hideNavOnMobile: boolean,
};

type State = {

};

export class ResponsiveNavigation extends React.Component<Props, State> {
  static defaultProps = {
    title: '',
    onToggleSidebar: () => null,
    hideNavOnMobile: false,
  }

  state = {};

  render() {
    const {
      title,
      onToggleSidebar,
      hideNavOnMobile,
      ...others
    } = this.props;

    return (
      <Flex
        /* width={[
          `calc(100% - ${DESKTOP_MENU_WIDTHS[0]})`,
          `calc(100% - ${DESKTOP_MENU_WIDTHS[1]})`,
          `calc(100% - ${DESKTOP_MENU_WIDTHS[2]})`,
          `calc(100% - ${DESKTOP_MENU_WIDTHS[3]})`,
        ]} */
        width={1}
        px={0}
        // FIXME - adding one for the border on the bottom
        // left destop sidebar (EmbeddedNavigationMenuDesktop) has border
        // top to give it a solid line all the way accross .. not proud of this fix...
        // height={(`${parseInt(HEADER_HEIGHT, 10) + 1}px`)}
        height={HEADER_HEIGHT}
        borderBottom={1}
        borderColor="backgroundGray"
        justifyContent="center"
        boxSizing="border-box"
        alignItems="center"
        flexDirection="row"
        position="fixed"
        bg="white"
        zIndex={5}
        display={hideNavOnMobile ? ['none', 'none', 'flex'] : 'flex'}
        style={{ alignSelf: 'center' }}
        {...others}
      >
        <BodyContainer
          maxWidth="100%"
          height="100%"
        >
          <Flex
            width={1}
            alignItems="center"
            justifyContent="space-between"
            boxSizing="border-box"
            position="relative"
            px={[3, 3, 3]}
            height="100%"
          >
            <MobileNavigationTitle title={title || ''} />
          </Flex>
        </BodyContainer>
      </Flex>
    );
  }
}
