import { Component } from "react";
import { connect } from "react-redux";
import generateIDFALikeBrowserID from "../../utils/getIDFA";
import { setBrowserID } from "../../actions/owner/auth";
type Props = {
  children: React.ReactNode;
  onSetBrowserID: (...args: Array<any>) => any;
};

class BrowserID extends Component<Props> {
  componentDidMount() {
    const {
      onSetBrowserID
    } = this.props;

    if (!window.localStorage.getItem('browser-id')) {
      window.localStorage.setItem('browser-id', generateIDFALikeBrowserID());
    }

    const browserID = window.localStorage.getItem('browser-id');
    onSetBrowserID(browserID);
  }

  render() {
    const {
      children
    } = this.props;
    return children;
  }

}

export default connect(null, {
  onSetBrowserID: setBrowserID
})(BrowserID);