import { useDispatch } from 'react-redux';
import type { StyledSystemProps } from 'webApp';

import {
  createNotification,
  clearAll as clearAllNotifications,
} from '../actions/notification';
import type { NotificationType } from '../constants';

export type AlertNotificationParams = {
  notificationType?: NotificationType;
  notificationStyleProps?: StyledSystemProps;
  timeout? : number;
  text: string;
};

export const useAlertNotification = () => {
  const dispatch = useDispatch();

  const clearAll = () => {
    dispatch(clearAllNotifications);
  };

  const execute = ({
    notificationType = 'success',
    text,
    timeout,
    notificationStyleProps,
  }: AlertNotificationParams) => {
    dispatch(createNotification({
      notificationType,
      text,
      timeout,
      notificationStyleProps,
    }));
    clearAll();
  };

  return {
    execute,
    clearAll,
  };
};
