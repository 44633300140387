import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { V5AddPreferredWalkerRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_ADD_PREFERRED_WALKER, Endpoint } from "../../../constants/endpoints";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { OWNER_ADD_PREFERRED_WALKER } from "../../../actions/actionTypes";
import { AddPreferredWalkerAction, addPreferredWalkerSuccess, addPreferredWalkerFailure } from "../../../actions/owner/addPreferredWalker";

const addPreferredWalker$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_ADD_PREFERRED_WALKER), switchMap(({
  payload: {
    walkerPromoCode
  }
}: AddPreferredWalkerAction) => post$(({
  endpoint: ENDPOINT_OWNER_ADD_PREFERRED_WALKER({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    promo_code: walkerPromoCode
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: V5AddPreferredWalkerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: addPreferredWalkerSuccess,
  onFailure: addPreferredWalkerFailure
})), catchError(handleErrorV5Response(action$, addPreferredWalkerFailure)))));

export default addPreferredWalker$;