import {
  createSlice,
  current,
} from '@reduxjs/toolkit';

import { ReduxState } from '../../reducers';
import { getDogs } from '../../reducers/dogs';
import { webApp } from '../../types/webapp';
import { omit } from '../../utils';
import { isBookingScheduleInThePast } from '../../utils/bookingValidations/isBookingScheduleInThePast';

export type DraftsState = webApp.BaseBookingDraft;

const initialState = {};

/**
 * The purpose of `drafts` is to be used as a storing machine of drafts
 * This isn't limited to forms; Any type of structure that you want to
 * save as draft can use this.
 * The two important things in order to use this properly is specify
 * a `draftKey` so it knows how to save your draft and last thing is
 * to use the hook `useDraft` as that provides a simple abstraction
 * to use this without going into details of `createSlice`.
 *
 * If you are interested in knowing more about `createSlice`,
 * head on to this documentation https://redux-toolkit.js.org/api/createSlice
 */
const slice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    add: (state, {
      payload,
    }) => ({
      ...state,
      ...payload,
    }),
    clearByDraftKey: (state, {
      payload: {
        draftKey,
      },
    }) => omit([draftKey])(current(state)),
    clear: () => initialState,
  },
});

// selector functions
const selectors = {
  getDrafts: (state: ReduxState) => state.drafts,
  getDraftByKey: (key: string) => (state: ReduxState) => state.drafts[key],
  getBookingDraftByKey: (key: string) => (state: ReduxState): Partial<webApp.BaseBookingDraft> => {
    const draft = state.drafts[key] as DraftsState;
    const dogs = getDogs(state);

    /**
     * If draft contains a dog that no longer exists on the profile,
     * dog should not appear
     */
    const getSelectedLiveDogs = () => {
      if (!dogs || !dogs.length) {
        return [];
      }

      return draft.selectedDogs.filter((selectedDog) => (
        dogs.find((dog) => dog.id === selectedDog.id)
      ));
    };

    const bookingDraft = draft && {
      ...draft,
      startDate: isBookingScheduleInThePast(draft) ? '' : draft.startDate,
      startTime: isBookingScheduleInThePast(draft) ? '' : draft.startTime,
      selectedDogs: getSelectedLiveDogs(),
    };

    return bookingDraft;
  },
};

export const draftsSlice = {
  ...slice,
  selectors,
};
