import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
} from '@reduxjs/toolkit/query/react';

import { FEATURE_DEV_SELECTOR_ENABLED } from '../../constants';
import { ReduxState } from '../../reducers';
import { getOwnerToken } from '../../selectors';
import { configSlice } from '../config';
import { walkerSlice } from '../walker';

const getDomainFromStore = (state: ReduxState) => (
  domain = process.env.REACT_APP_WAG_API_URL || '',
): string => {
  if (!FEATURE_DEV_SELECTOR_ENABLED) {
    // Do not use the domain from the store if on production mode
    // Dev selector is turned off in production
    return domain;
  }

  const config = configSlice.selectors.getConfig(state);
  const configDomain = (config.domain && config.domain.value) || domain;
  return configDomain;
};

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
const ownerRawBaseQueryFn = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, {
    getState,
  }) => {
    const state = getState() as ReduxState;
    const commonHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    // Set the common headers
    Object.entries(commonHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    const token = getOwnerToken(state);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    }

    return headers;
  },
});

const walkerRawBaseQueryFn = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, {
    getState,
  }) => {
    const state = getState() as ReduxState;
    const commonHeaders = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    // Set the common headers
    Object.entries(commonHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    const token = walkerSlice.selectors.getToken(state);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    }

    return headers;
  },
});

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
export const customBaseQuery = (rawBaseQueryFn: BaseQueryFn): BaseQueryFn => async (
  args,
  api,
  extraOptions,
) => {
  const state = api.getState() as ReduxState;
  const baseUrl = getDomainFromStore(state)();

  const isString = (data: unknown) => typeof data === 'string';
  const pathUrl = isString(args) ? args : args.url;
  const url = `${baseUrl}/${pathUrl}`;
  const adjustedArgs = isString(args) ? url : {
    ...args,
    url,
  };
  return rawBaseQueryFn(adjustedArgs, api, extraOptions);
};

// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery(ownerRawBaseQueryFn),
  endpoints: () => ({}),
});

export const walkerApiSlice = createApi({
  reducerPath: 'apiWalker',
  refetchOnMountOrArgChange: true,
  baseQuery: customBaseQuery(walkerRawBaseQueryFn),
  endpoints: () => ({}),
});
