
import { createAction } from 'redux-actions';
import type {
  BookingSetScheduleParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  BOOKING_OVERNIGHT,
  BOOKING_OVERNIGHT_SUCCESS,
  BOOKING_OVERNIGHT_FAILURE,
} from '../../actionTypes';


/**
 * Book a walk
 */
export type BookingOvernightParams = {
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string,
  hasPickupAndDropoff: boolean,
  keyMode: number,
  lockboxInfo: string,
  lockboxCode: string,
  accessNotes: string,
  notes: string,
  walkTypeId: number,
  dogs: Array<{ id: number }>,
  questionnaire: Array<{ id: number, value: boolean }>,
  sitterShouldStay: boolean,
  shotsUpToDate: boolean,
  boardingWithOtherDogs: boolean,
  friendlyWithOtherDogs: boolean,
};

export type BookingOvernightPayload = BookingOvernightParams;

export type BookingOvernightAction = {
  type: typeof BOOKING_OVERNIGHT,
  payload: BookingOvernightPayload,
};

export const bookingOvernight: (
  BookingOvernightParams
) => BookingOvernightAction = createAction(
  BOOKING_OVERNIGHT,
  ({
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    keyMode,
    lockboxInfo,
    lockboxCode,
    accessNotes,
    notes,
    walkTypeId,
    dogs,
    questionnaire,
    sitterShouldStay,
    shotsUpToDate,
    boardingWithOtherDogs,
    friendlyWithOtherDogs,
  }) => ({
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    keyMode,
    lockboxInfo,
    lockboxCode,
    accessNotes,
    notes,
    walkTypeId,
    dogs,
    questionnaire,
    sitterShouldStay,
    shotsUpToDate,
    boardingWithOtherDogs,
    friendlyWithOtherDogs,
  }),
);


/**
 * Book a walk Success
 */
export type BookingOvernightSuccessAction = {
  type: typeof BOOKING_OVERNIGHT_SUCCESS,
  payload: BookingSetScheduleParsedResponse,
};

export const bookingOvernightSuccess: (
  BookingSetScheduleParsedResponse,
) => BookingOvernightSuccessAction = createAction(
  BOOKING_OVERNIGHT_SUCCESS,
);


/**
 * Book a walk Failure
 */
export type BookingOvernightFailureAction = {
  type: typeof BOOKING_OVERNIGHT_FAILURE,
  payload: ErrorParsedResponse,
};

export const bookingOvernightFailure: (
  ErrorParsedResponse,
) => BookingOvernightFailureAction = createAction(
  BOOKING_OVERNIGHT_FAILURE,
);
