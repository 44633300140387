import { handleActions } from 'redux-actions';
import type { WalkCoordinateInfo } from 'webApp';

import {
  WALK_COORDINATES_GET_SUCCESS,
  LOGOUT,
} from '../../actions/actionTypes';
import type { GetWalkCoordinatesSuccessAction } from '../../actions/walk/walkCoordinates';

export type State = WalkCoordinateInfo;
export const initialState: State = {
  coordinates: [],
  distance: 0,
  markerCoordinates: [],
  time: 0,
  walkID: null,
};
export const reducer: any = handleActions({
  [WALK_COORDINATES_GET_SUCCESS]: (state: State, {
    payload: {
      coordinates,
      distance,
      markerCoordinates,
      time,
      walkID,
    },
  }: GetWalkCoordinatesSuccessAction): State => {
    if (distance === 0) {
      return initialState;
    }

    return {
      coordinates,
      distance,
      markerCoordinates,
      time,
      walkID,
    };
  },
  [LOGOUT]: () => initialState,
}, initialState);
export default reducer;
