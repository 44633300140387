// NOTE: Please make sure to follow SUBJECT_ACTION_STATUS pattern
export const ALERT = 'ALERT';
export const ALERTS_CLEAR = 'ALERTS_CLEAR';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const AUTHENTICATE_WITH_TOKEN = 'AUTHENTICATE_WITH_TOKEN';
export const AUTHENTICATION_CODE_POST = 'AUTHENTICATION_CODE_POST';
export const AUTHENTICATION_CODE_POST_FAILURE = 'AUTHENTICATION_CODE_POST_FAILURE';
export const AUTHENTICATION_CODE_POST_SUCCESS = 'AUTHENTICATION_CODE_POST_SUCCESS';
export const AUTHENTICATION_PASSWORD_POST = 'AUTHENTICATION_PASSWORD_POST';
export const AUTHENTICATION_PASSWORD_POST_FAILURE = 'AUTHENTICATION_PASSWORD_POST_FAILURE';
export const AUTHENTICATION_PASSWORD_POST_SUCCESS = 'AUTHENTICATION_PASSWORD_POST_SUCCESS';
export const AUTHENTICATION_SESSION_GET = 'AUTHENTICATION_SESSION_GET';
export const AUTHENTICATION_SESSION_GET_FAILURE = 'AUTHENTICATION_SESSION_GET_FAILURE';
export const AUTHENTICATION_SESSION_GET_SUCCESS = 'AUTHENTICATION_SESSION_GET_SUCCESS';
export const AUTHENTICATION_SESSION_POST = 'AUTHENTICATION_SESSION_POST';
export const AUTHENTICATION_SESSION_POST_FAILURE = 'AUTHENTICATION_SESSION_POST_FAILURE';
export const AUTHENTICATION_SESSION_POST_SUCCESS = 'AUTHENTICATION_SESSION_POST_SUCCESS';
export const AUTHENTICATION_UPGRADE_POST = 'AUTHENTICATION_UPGRADE_POST';
export const AUTHENTICATION_UPGRADE_POST_FAILURE = 'AUTHENTICATION_UPGRADE_POST_FAILURE';
export const AUTHENTICATION_UPGRADE_POST_SUCCESS = 'AUTHENTICATION_UPGRADE_POST_SUCCESS';
export const AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE = 'AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE';
export const AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS = 'AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS';
export const AUTHENTICATION_VERIFY_POST = 'AUTHENTICATION_VERIFY_POST';
export const AUTHENTICATION_VERIFY_POST_FAILURE = 'AUTHENTICATION_VERIFY_POST_FAILURE';
export const AUTHENTICATION_VERIFY_POST_SUCCESS = 'AUTHENTICATION_VERIFY_POST_SUCCESS';
export const BOOKING_CANCEL_SCHEDULE = 'BOOKING_CANCEL_SCHEDULE';
export const BOOKING_CANCEL_SCHEDULE_FAILURE = 'BOOKING_CANCEL_SCHEDULE_FAILURE';
export const BOOKING_CANCEL_SCHEDULE_SUCCESS = 'BOOKING_CANCEL_SCHEDULE_SUCCESS';
export const BOOKING_EDIT_SCHEDULE = 'BOOKING_EDIT_SCHEDULE';
export const BOOKING_EDIT_SCHEDULE_FAILURE = 'BOOKING_EDIT_SCHEDULE_FAILURE';
export const BOOKING_EDIT_SCHEDULE_SUCCESS = 'BOOKING_EDIT_SCHEDULE_SUCCESS';
export const BOOKING_FIELDS_ADD = 'BOOKING_FIELDS_ADD';
export const BOOKING_MEET_GREET = 'BOOKING_MEET_GREET';
export const BOOKING_MEET_GREET_FAILURE = 'BOOKING_MEET_GREET_FAILURE';
export const BOOKING_MEET_GREET_SUCCESS = 'BOOKING_MEET_GREET_SUCCESS';
export const BOOKING_OVERNIGHT = 'BOOKING_OVERNIGHT';
export const BOOKING_OVERNIGHT_FAILURE = 'BOOKING_OVERNIGHT_FAILURE';
export const BOOKING_OVERNIGHT_SUCCESS = 'BOOKING_OVERNIGHT_SUCCESS';
export const BOOKING_SET_SCHEDULE = 'BOOKING_SET_SCHEDULE';
export const BOOKING_SET_SCHEDULE_FAILURE = 'BOOKING_SET_SCHEDULE_FAILURE';
export const BOOKING_SET_SCHEDULE_SUCCESS = 'BOOKING_SET_SCHEDULE_SUCCESS';
export const CREDIT_CARD_UPDATE = 'CREDIT_CARD_UPDATE';
export const CREDIT_CARD_UPDATE_FAILURE = 'CREDIT_CARD_UPDATE_FAILURE';
export const CREDIT_CARD_UPDATE_SUCCESS = 'CREDIT_CARD_UPDATE_SUCCESS';
export const CREDIT_PACKAGES_GET = 'CREDIT_PACKAGES_GET';
export const CREDIT_PACKAGES_GET_FAILURE = 'CREDIT_PACKAGES_GET_FAILURE';
export const CREDIT_PACKAGES_GET_SUCCESS = 'CREDIT_PACKAGES_GET_SUCCESS';
export const DOGS_CLEAR = 'DOGS_CLEAR';
export const DOGS_GET = 'DOGS_GET';
export const DOGS_GET_FAILURE = 'DOGS_GET_FAILURE';
export const DOGS_GET_SUCCESS = 'DOGS_GET_SUCCESS';
export const DOG_BREEDS_CLEAR = 'DOG_BREEDS_CLEAR';
export const DOG_BREEDS_GET = 'DOG_BREEDS_GET';
export const DOG_BREEDS_GET_FAILURE = 'DOG_BREEDS_GET_FAILURE';
export const DOG_BREEDS_GET_SUCCESS = 'DOG_BREEDS_GET_SUCCESS';
export const DOG_BREED_ASSETS_GET = 'DOG_BREED_ASSETS_GET';
export const DOG_BREED_ASSETS_GET_FAILURE = 'DOG_BREED_ASSETS_GET_FAILURE';
export const DOG_BREED_ASSETS_GET_SUCCESS = 'DOG_BREED_ASSETS_GET_SUCCESS';
export const DOG_CLEAR = 'DOG_CLEAR';
export const DOG_CREATE = 'DOG_CREATE';
export const DOG_CREATE_FAILURE = 'DOG_CREATE_FAILURE';
export const DOG_CREATE_QUESTIONAIRE = 'DOG_CREATE_QUESTIONAIRE';
export const DOG_CREATE_QUESTIONAIRE_FAILURE = 'DOG_CREATE_QUESTIONAIRE_FAILURE';
export const DOG_CREATE_QUESTIONAIRE_SUCCESS = 'DOG_CREATE_QUESTIONAIRE_SUCCESS';
export const DOG_CREATE_SUCCESS = 'DOG_CREATE_SUCCESS';
export const DOG_DELETE = 'DOG_DELETE';
export const DOG_DELETE_FAILURE = 'DOG_DELETE_FAILURE';
export const DOG_DELETE_SUCCESS = 'DOG_DELETE_SUCCESS';
export const DOG_GET = 'DOG_GET';
export const DOG_GET_FAILURE = 'DOG_GET_FAILURE';
export const DOG_GET_QUESTIONAIRE = 'DOG_GET_QUESTIONAIRE';
export const DOG_GET_QUESTIONAIRE_FAILURE = 'DOG_GET_QUESTIONAIRE_FAILURE';
export const DOG_GET_QUESTIONAIRE_SUCCESS = 'DOG_GET_QUESTIONAIRE_SUCCESS';
export const DOG_GET_SUCCESS = 'DOG_GET_SUCCESS';
export const DOG_PROFILE_PATCH = 'DOG_PROFILE_PATCH';
export const DOG_PROFILE_PATCH_FAILURE = 'DOG_PROFILE_PATCH_FAILURE';
export const DOG_PROFILE_PATCH_SUCCESS = 'DOG_PROFILE_PATCH_SUCCESS';
export const DOG_UPDATE_QUESTIONAIRE = 'DOG_UPDATE_QUESTIONAIRE';
export const DOG_UPDATE_QUESTIONAIRE_FAILURE = 'DOG_UPDATE_QUESTIONAIRE_FAILURE';
export const DOG_UPDATE_QUESTIONAIRE_SUCCESS = 'DOG_UPDATE_QUESTIONAIRE_SUCCESS';
export const DOG_UPDATE_V4 = 'DOG_UPDATE_V4';
export const DOG_UPDATE_V4_FAILURE = 'DOG_UPDATE_V4_FAILURE';
export const DOG_UPDATE_V4_SUCCESS = 'DOG_UPDATE_V4_SUCCESS';
export const ESTIMATE_CLEAR = 'ESTIMATE_CLEAR';
export const ESTIMATE_OVERNIGHT = 'ESTIMATE_OVERNIGHT';
export const ESTIMATE_OVERNIGHT_FAILURE = 'ESTIMATE_OVERNIGHT_FAILURE';
export const ESTIMATE_OVERNIGHT_SUCCESS = 'ESTIMATE_OVERNIGHT_SUCCESS';
export const GLOBAL_ERROR_HIDE = 'GLOBAL_ERROR_HIDE';
export const GLOBAL_ERROR_SHOW = 'GLOBAL_ERROR_SHOW';
export const LOGIN_WITH_CODE_REQUEST_CREATE = 'LOGIN_WITH_CODE_REQUEST_CREATE';
export const LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE = 'LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE';
export const LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS = 'LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS';
export const LOGIN_WITH_CODE_SUBMIT = 'LOGIN_WITH_CODE_SUBMIT';
export const LOGIN_WITH_CODE_SUBMIT_FAILURE = 'LOGIN_WITH_CODE_SUBMIT_FAILURE';
export const LOGIN_WITH_CODE_SUBMIT_SUCCESS = 'LOGIN_WITH_CODE_SUBMIT_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const MY_PROFILE_UPDATE = 'MY_PROFILE_UPDATE';
export const MY_PROFILE_UPDATE_FAILURE = 'MY_PROFILE_UPDATE_FAILURE';
export const MY_PROFILE_UPDATE_SUCCESS = 'MY_PROFILE_UPDATE_SUCCESS';
export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const OWNER_ADDRESS_V2_REGISTER = 'OWNER_ADDRESS_V2_REGISTER';
export const OWNER_ADDRESS_V2_REGISTER_FAILURE = 'OWNER_ADDRESS_V2_REGISTER_FAILURE';
export const OWNER_ADDRESS_V2_REGISTER_SUCCESS = 'OWNER_ADDRESS_V2_REGISTER_SUCCESS';
export const OWNER_ADD_PREFERRED_WALKER = 'OWNER_ADD_PREFERRED_WALKER';
export const OWNER_ADD_PREFERRED_WALKER_FAILURE = 'OWNER_ADD_PREFERRED_WALKER_FAILURE';
export const OWNER_ADD_PREFERRED_WALKER_SUCCESS = 'OWNER_ADD_PREFERRED_WALKER_SUCCESS';
export const OWNER_APPLY_DETAILS_REGISTER = 'OWNER_APPLY_DETAILS_REGISTER';
export const OWNER_APPLY_DETAILS_REGISTER_FAILURE = 'OWNER_APPLY_DETAILS_REGISTER_FAILURE';
export const OWNER_APPLY_DETAILS_REGISTER_SUCCESS = 'OWNER_APPLY_DETAILS_REGISTER_SUCCESS';
export const OWNER_AUTH_REFRESH_GET = 'OWNER_AUTH_REFRESH_GET';
export const OWNER_AUTH_REFRESH_GET_FAILURE = 'OWNER_AUTH_REFRESH_GET_FAILURE';
export const OWNER_AUTH_REFRESH_GET_SUCCESS = 'OWNER_AUTH_REFRESH_GET_SUCCESS';
export const OWNER_AVAILABLE_SERVICES = 'OWNER_AVAILABLE_SERVICES';
export const OWNER_AVAILABLE_SERVICES_FAILURE = 'OWNER_AVAILABLE_SERVICES_FAILURE';
export const OWNER_AVAILABLE_SERVICES_SUCCESS = 'OWNER_AVAILABLE_SERVICES_SUCCESS';
export const OWNER_CREDIT_GET = 'OWNER_CREDIT_GET';
export const OWNER_CREDIT_GET_FAILURE = 'OWNER_CREDIT_GET_FAILURE';
export const OWNER_CREDIT_GET_SUCCESS = 'OWNER_CREDIT_GET_SUCCESS';
export const OWNER_GET = 'OWNER_GET';
export const OWNER_GET_FAILURE = 'OWNER_GET_FAILURE';
export const OWNER_GET_SUCCESS = 'OWNER_GET_SUCCESS';
export const OWNER_GOOGLE_SIGNIN_REGISTER = 'OWNER_GOOGLE_SIGNIN_REGISTER';
export const OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE = 'OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE';
export const OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS = 'OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS';
export const OWNER_HOME_ACCESS_GET = 'OWNER_HOME_ACCESS_GET';
export const OWNER_HOME_ACCESS_GET_FAILURE = 'OWNER_HOME_ACCESS_GET_FAILURE';
export const OWNER_HOME_ACCESS_GET_SUCCESS = 'OWNER_HOME_ACCESS_GET_SUCCESS';
export const OWNER_HOME_ACCESS_UPDATE = 'OWNER_HOME_ACCESS_UPDATE';
export const OWNER_HOME_ACCESS_UPDATE_FAILURE = 'OWNER_HOME_ACCESS_UPDATE_FAILURE';
export const OWNER_HOME_ACCESS_UPDATE_SUCCESS = 'OWNER_HOME_ACCESS_UPDATE_SUCCESS';
export const OWNER_LOGIN = 'OWNER_LOGIN';
export const OWNER_LOGIN_FAILURE = 'OWNER_LOGIN_FAILURE';
export const OWNER_LOGIN_SUCCESS = 'OWNER_LOGIN_SUCCESS';
export const OWNER_MASKED_INFO_GET = 'OWNER_MASKED_INFO_GET';
export const OWNER_MASKED_INFO_GET_FAILURE = 'OWNER_MASKED_INFO_GET_FAILURE';
export const OWNER_MASKED_INFO_GET_SUCCESS = 'OWNER_MASKED_INFO_GET_SUCCESS';
export const OWNER_PASSWORD_CHANGE = 'OWNER_PASSWORD_CHANGE';
export const OWNER_PASSWORD_CHANGE_FAILURE = 'OWNER_PASSWORD_CHANGE_FAILURE';
export const OWNER_PASSWORD_CHANGE_SUCCESS = 'OWNER_PASSWORD_CHANGE_SUCCESS';
export const OWNER_PASSWORD_RESET = 'OWNER_PASSWORD_RESET';
export const OWNER_PASSWORD_RESET_EMAIL_SEND = 'OWNER_PASSWORD_RESET_EMAIL_SEND';
export const OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE = 'OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE';
export const OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS = 'OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS';
export const OWNER_PASSWORD_RESET_FAILURE = 'OWNER_PASSWORD_RESET_FAILURE';
export const OWNER_PASSWORD_RESET_SUCCESS = 'OWNER_PASSWORD_RESET_SUCCESS';
export const OWNER_PAYMENT_METHODS_GET = 'OWNER_PAYMENT_METHODS_GET';
export const OWNER_PAYMENT_METHODS_GET_FAILURE = 'OWNER_PAYMENT_METHODS_GET_FAILURE';
export const OWNER_PAYMENT_METHODS_GET_SUCCESS = 'OWNER_PAYMENT_METHODS_GET_SUCCESS';
export const OWNER_PAYMENT_METHOD_CREATE = 'OWNER_PAYMENT_METHOD_CREATE';
export const OWNER_PAYMENT_METHOD_CREATE_FAILURE = 'OWNER_PAYMENT_METHOD_CREATE_FAILURE';
export const OWNER_PAYMENT_METHOD_CREATE_SUCCESS = 'OWNER_PAYMENT_METHOD_CREATE_SUCCESS';
export const OWNER_PAYMENT_METHOD_UPDATE = 'OWNER_PAYMENT_METHOD_UPDATE';
export const OWNER_PAYMENT_METHOD_UPDATE_FAILURE = 'OWNER_PAYMENT_METHOD_UPDATE_FAILURE';
export const OWNER_PAYMENT_METHOD_UPDATE_SUCCESS = 'OWNER_PAYMENT_METHOD_UPDATE_SUCCESS';
export const OWNER_PREFERRED_WALKER_CREATE = 'OWNER_PREFERRED_WALKER_CREATE';
export const OWNER_PREFERRED_WALKER_CREATE_FAILURE = 'OWNER_PREFERRED_WALKER_CREATE_FAILURE';
export const OWNER_PREFERRED_WALKER_CREATE_SUCCESS = 'OWNER_PREFERRED_WALKER_CREATE_SUCCESS';
export const OWNER_PREFERRED_WALKER_DELETE = 'OWNER_PREFERRED_WALKER_DELETE';
export const OWNER_PREFERRED_WALKER_DELETE_FAILURE = 'OWNER_PREFERRED_WALKER_DELETE_FAILURE';
export const OWNER_PREFERRED_WALKER_DELETE_SUCCESS = 'OWNER_PREFERRED_WALKER_DELETE_SUCCESS';
export const OWNER_PREMIUM_SUBSCRIPTION_GET = 'OWNER_PREMIUM_SUBSCRIPTION_GET';
export const OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE = 'OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE';
export const OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS = 'OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS';
export const OWNER_REGISTRATION_ADDRESS = 'OWNER_REGISTRATION_ADDRESS';
export const OWNER_REGISTRATION_ADDRESS_FAILURE = 'OWNER_REGISTRATION_ADDRESS_FAILURE';
export const OWNER_REGISTRATION_ADDRESS_SUCCESS = 'OWNER_REGISTRATION_ADDRESS_SUCCESS';
export const OWNER_REGISTRATION_BASIC_INFO = 'OWNER_REGISTRATION_BASIC_INFO';
export const OWNER_REGISTRATION_BASIC_INFO_FAILURE = 'OWNER_REGISTRATION_BASIC_INFO_FAILURE';
export const OWNER_REGISTRATION_BASIC_INFO_SUCCESS = 'OWNER_REGISTRATION_BASIC_INFO_SUCCESS';
export const OWNER_REGISTRATION_BASIC_INFO_V2 = 'OWNER_REGISTRATION_BASIC_INFO_V2';
export const OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE = 'OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE';
export const OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS = 'OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS';
export const OWNER_REGISTRATION_DOG_INFO = 'OWNER_REGISTRATION_DOG_INFO';
export const OWNER_REGISTRATION_DOG_INFO_CREATE = 'OWNER_REGISTRATION_DOG_INFO_CREATE';
export const OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE = 'OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE';
export const OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS = 'OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS';
export const OWNER_REGISTRATION_DOG_INFO_FAILURE = 'OWNER_REGISTRATION_DOG_INFO_FAILURE';
export const OWNER_REGISTRATION_DOG_INFO_SUCCESS = 'OWNER_REGISTRATION_DOG_INFO_SUCCESS';
export const OWNER_REGISTRATION_PREFERRED_SERVICES_RAW = 'OWNER_REGISTRATION_PREFERRED_SERVICES_RAW';
export const OWNER_REGISTRATION_PREFERRED_SERVICES = 'OWNER_REGISTRATION_PREFERRED_SERVICES';
export const OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE = 'OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE';
export const OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS = 'OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS';
export const OWNER_REGISTRATION_REQUEST_LOCKBOX = 'OWNER_REGISTRATION_REQUEST_LOCKBOX';
export const OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE = 'OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE';
export const OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS = 'OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS';
export const OWNER_SERVICES_GET = 'OWNER_SERVICES_GET';
export const OWNER_SERVICES_GET_FAILURE = 'OWNER_SERVICES_GET_FAILURE';
export const OWNER_SERVICES_GET_SUCCESS = 'OWNER_SERVICES_GET_SUCCESS';
export const OWNER_SMART_MODULES = 'OWNER_SMART_MODULES';
export const OWNER_SMART_MODULES_FAILURE = 'OWNER_SMART_MODULES_FAILURE';
export const OWNER_SMART_MODULES_SUCCESS = 'OWNER_SMART_MODULES_SUCCESS';
export const OWNER_UPDATE = 'OWNER_UPDATE';
export const OWNER_UPDATE_FAILURE = 'OWNER_UPDATE_FAILURE';
export const OWNER_UPDATE_SUCCESS = 'OWNER_UPDATE_SUCCESS';
export const PASSWORD_TIPS = 'PASSWORD_TIPS';
export const PASSWORD_TIPS_FAILURE = 'PASSWORD_TIPS_FAILURE';
export const PASSWORD_TIPS_SUCCESS = 'PASSWORD_TIPS_SUCCESS';
export const PAST_PET_CAREGIVERS_GET = 'PAST_PET_CAREGIVERS_GET';
export const PAST_PET_CAREGIVERS_GET_FAILURE = 'PAST_PET_CAREGIVERS_GET_FAILURE';
export const PAST_PET_CAREGIVERS_GET_SUCCESS = 'PAST_PET_CAREGIVERS_GET_SUCCESS';
export const POLL_REQUEST_WAG_API = 'POLL_REQUEST_WAG_API';
export const POLL_START = 'POLL_START';
export const POLL_STOP = 'POLL_STOP';
export const PREFERRED_WALKER_UPDATE = 'PREFERRED_WALKER_UPDATE';
export const PREFERRED_WALKER_UPDATE_FAILURE = 'PREFERRED_WALKER_UPDATE_FAILURE';
export const PREFERRED_WALKER_UPDATE_SUCCESS = 'PREFERRED_WALKER_UPDATE_SUCCESS';
export const PROMO_CODE_APPLY = 'PROMO_CODE_APPLY';
export const PROMO_CODE_APPLY_FAILURE = 'POMO_CODE_APPLY_FAILURE';
export const PROMO_CODE_APPLY_SUCCESS = 'PROMO_CODE_APPLY_SUCCESS';
export const PROMO_CODE_RESET = 'PROMO_CODE_RESET';
export const QUERY_PARAMS_RESET = 'QUERY_PARAMS_RESET';
export const QUERY_PARAMS_SET = 'QUERY_PARAMS_SET';
export const REBOOKING = 'REBOOKING';
export const REBOOKING_FAILURE = 'REBOOKING_FAILURE';
export const REBOOKING_SUCCESS = 'REBOOKING_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESPONSE_SERVER_ERROR = 'RESPONSE_SERVER_ERROR';
export const RESPONSE_UNAUTHENTICATED = 'RESPONSE_UNAUTHENTICATED';
export const SAVE_SIGNUP_ADDRESS = 'SAVE_SIGNUP_ADDRESS';
export const SERVICEABLE_ZIP_CODE_CHECK = 'SERVICEABLE_ZIP_CODE_CHECK';
export const SERVICEABLE_ZIP_CODE_CHECK_FAILURE = 'SERVICEABLE_ZIP_CODE_CHECK_FAILURE';
export const SERVICEABLE_ZIP_CODE_CHECK_SUCCESS = 'SERVICEABLE_ZIP_CODE_CHECK_SUCCESS';
export const SERVICE_PROVIDERS_GET = 'SERVICE_PROVIDERS_GET';
export const SERVICE_PROVIDERS_GET_FAILURE = 'SERVICE_PROVIDERS_GET_FAILURE';
export const SERVICE_PROVIDERS_GET_SUCCESS = 'SERVICE_PROVIDERS_GET_SUCCESS';
export const SET_BROWSER_ID = 'SET_BROWSER_ID';
export const SITTING_QUESTIONNAIRE_GET = 'SITTING_QUESTIONNAIRE';
export const SITTING_QUESTIONNAIRE_GET_FAILURE = 'SITTING_QUESTIONNAIRE_FAILURE';
export const SITTING_QUESTIONNAIRE_GET_SUCCESS = 'SITTING_QUESTIONNAIRE_SUCCESS';
export const SUBSCRIBE_TO_PREMIUM = 'SUBSCRIBE_TO_PREMIUM';
export const SUBSCRIBE_TO_PREMIUM_FAILURE = 'SUBSCRIBE_TO_PREMIUM_FAILURE';
export const SUBSCRIBE_TO_PREMIUM_SUCCESS = 'SUBSCRIBE_TO_PREMIUM_SUCCESS';
export const TRAINING_BOOKING = 'TRAINING_BOOKING';
export const TRAINING_BOOKING_FAILURE = 'TRAINING_BOOKING_FAILURE';
export const TRAINING_BOOKING_SUCCESS = 'TRAINING_BOOKING_SUCCESS';
export const UPDATE_WALKER_PASSWORD = 'UPDATE_WALKER_PASSWORD';
export const UPDATE_WALKER_PASSWORD_FAILURE = 'UPDATE_WALKER_PASSWORD_FAILURE';
export const UPDATE_WALKER_PASSWORD_SUCCESS = 'UPDATE_WALKER_PASSWORD_SUCCESS';
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD';
export const VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE';
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS';
export const VISITOR_ACCOUNT_CREATE = 'VISITOR_ACCOUNT_CREATE';
export const VISITOR_ACCOUNT_CREATE_FAILURE = 'VISITOR_ACCOUNT_CREATE_FAILURE';
export const VISITOR_ACCOUNT_CREATE_SUCCESS = 'VISITOR_ACCOUNT_CREATE_SUCCESS';
export const WALKER_CLEAR = 'WALKER_CLEAR';
export const WALKER_CONTACT_INFO_FAILURE = 'WALKER_CONTACT_INFO_FAILURE';
export const WALKER_CONTACT_INFO_GET = 'WALKER_CONTACT_INFO_GET';
export const WALKER_CONTACT_INFO_SUCCESS = 'WALKER_CONTACT_INFO_SUCCESS';
export const WALKER_GET = 'WALKER_GET';
export const WALKER_GET_FAILURE = 'WALKER_GET_FAILURE';
export const WALKER_GET_SUCCESS = 'WALKER_GET_SUCCESS';
export const WALKER_PASSWORD_RESET = 'WALKER_PASSWORD_RESET';
export const WALKER_PASSWORD_RESET_FAILURE = 'WALKER_PASSWORD_RESET_FAILURE';
export const WALKER_PASSWORD_RESET_SUCCESS = 'WALKER_PASSWORD_RESET_SUCCESS';
export const WALKER_PROFILE_BY_PROMO_CODE_GET = 'WALKER_PROFILE_BY_PROMO_CODE_GET';
export const WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE = 'WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE';
export const WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS = 'WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS';
export const WALKER_PROFILE_GET = 'WALKER_PROFILE_GET';
export const WALKER_PROFILE_GET_FAILURE = 'WALKER_PROFILE_GET_FAILURE';
export const WALKER_PROFILE_GET_SUCCESS = 'WALKER_PROFILE_GET_SUCCESS';
export const WALKER_SCHEDULE_GET = 'WALKER_SCHEDULE_GET';
export const WALKER_SCHEDULE_GET_FAILURE = 'WALKER_SCHEDULE_GET_FAILURE';
export const WALKER_SCHEDULE_GET_SUCCESS = 'WALKER_SCHEDULE_GET_SUCCESS';
export const WALKER_SET_AUTH_TOKEN = 'WALKER_SET_AUTH_TOKEN';
export const WALKER_SIGN_IN = 'WALKER_SIGN_IN';
export const WALKER_SIGN_IN_FAILURE = 'WALKER_SIGN_IN_FAILURE';
export const WALKER_SIGN_IN_SUCCESS = 'WALKER_SIGN_IN_SUCCESS';
export const WALKER_SIGN_UP = 'WALKER_SIGN_UP';
export const WALKER_SIGN_UP_FAILURE = 'WALKER_SIGN_UP_FAILURE';
export const WALKER_SIGN_UP_SUCCESS = 'WALKER_SIGN_UP_SUCCESS';
export const WALKER_STRIPE_CONNECT_GET_REDIRECT_URL = 'WALKER_STRIPE_CONNECT_GET_REDIRECT_URL';
export const WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE = 'WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE';
export const WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS = 'WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS';
export const WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL = 'WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL';
export const WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE = 'WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE';
export const WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS = 'WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS';
export const WALKER_STRIPE_CONNECT_VERIFY_MIGRATION = 'WALKER_STRIPE_CONNECT_VERIFY_MIGRATION';
export const WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE = 'WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE';
export const WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS = 'WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS';
export const WALKER_STRIPE_CONNECT_VERIFY_TOKEN = 'WALKER_STRIPE_CONNECT_VERIFY_TOKEN';
export const WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE = 'WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE';
export const WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS = 'WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS';
export const WALKS_IN_PENDING_REVIEW_GET = 'WALKS_IN_PENDING_REVIEW_GET';
export const WALKS_IN_PENDING_REVIEW_GET_FAILURE = 'WALKS_IN_PENDING_REVIEW_GET_FAILURE';
export const WALKS_IN_PENDING_REVIEW_GET_SUCCESS = 'WALKS_IN_PENDING_REVIEW_GET_SUCCESS';
export const WALKS_IN_PROGRESS_GET = 'WALKS_IN_PROGRESS_GET';
export const WALKS_IN_PROGRESS_GET_FAILURE = 'WALKS_IN_PROGRESS_GET_FAILURE';
export const WALKS_IN_PROGRESS_GET_SUCCESS = 'WALKS_IN_PROGRESS_GET_SUCCESS';
export const WALKS_IN_THE_PAST_GET = 'WALKS_IN_THE_PAST_GET';
export const WALKS_IN_THE_PAST_GET_FAILURE = 'WALKS_IN_THE_PAST_GET_FAILURE';
export const WALKS_IN_THE_PAST_GET_SUCCESS = 'WALKS_IN_THE_PAST_GET_SUCCESS';
export const WALKS_POLL_START = 'WALKS_POLL_START';
export const WALKS_POLL_STOP = 'WALKS_POLL_STOP';
export const WALKS_UPCOMING_GET = 'WALKS_UPCOMING_GET';
export const WALKS_UPCOMING_GET_FAILURE = 'WALKS_UPCOMING_GET_FAILURE';
export const WALKS_UPCOMING_GET_SUCCESS = 'WALKS_UPCOMING_GET_SUCCESS';
export const WALK_CONFIRMATION_VIEWED = 'WALK_CONFIRMATION_VIEWED';
export const WALK_COORDINATES_GET = 'WALK_COORDINATES_GET';
export const WALK_COORDINATES_GET_FAILURE = 'WALK_COORDINATES_GET_FAILURE';
export const WALK_COORDINATES_GET_SUCCESS = 'WALK_COORDINATES_GET_SUCCESS';
export const WALK_COORDINATES_POLL_START = 'WALK_COORDINATES_POLL_START';
export const WALK_COORDINATES_POLL_STOP = 'WALK_COORDINATES_POLL_STOP';
export const WALK_COVER_CREATE = 'WALK_COVER_CREATE';
export const WALK_COVER_CREATE_FAILURE = 'WALK_COVER_CREATE_FAILURE';
export const WALK_COVER_CREATE_SUCCESS = 'WALK_COVER_CREATE_SUCCESS';
export const WALK_DETAILS_GET = 'WALK_DETAILS_GET';
export const WALK_DETAILS_GET_FAILURE = 'WALK_DETAILS_GET_FAILURE';
export const WALK_DETAILS_GET_SUCCESS = 'WALK_DETAILS_GET_SUCCESS';
export const WALK_RATE_AND_TIP = 'WALK_RATE_AND_TIP';
export const WALK_RATE_AND_TIP_FAILURE = 'WALK_RATE_AND_TIP_FAILURE';
export const WALK_RATE_AND_TIP_SUCCESS = 'WALK_RATE_AND_TIP_SUCCESS';
export const WALK_RATE_SORRY_MODAL_HIDE = 'WALK_RATE_SORRY_MODAL_HIDE';
export const WALK_RATE_SORRY_MODAL_SHOW = 'WALK_RATE_SORRY_MODAL_SHOW';
export const WALK_SCHEDULE_UPDATE = 'WALK_SCHEDULE_UPDATE';
export const WALK_SCHEDULE_UPDATE_FAILURE = 'WALK_SCHEDULE_UPDATE_FAILURE';
export const WALK_SCHEDULE_UPDATE_SUCCESS = 'WALK_SCHEDULE_UPDATE_SUCCESS';
