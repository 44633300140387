
import { createAction } from 'redux-actions';
import type {
  VerifyPasswordParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAILURE,
} from '../../actionTypes';

/**
 * Verify password
 */
export type VerifyPasswordParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password: string;
};
export type VerifyPasswordAction = {
  type: typeof VERIFY_PASSWORD;
  payload: VerifyPasswordParams;
};

export const verifyPassword: (params: VerifyPasswordParams) => VerifyPasswordAction = createAction(
  VERIFY_PASSWORD,
);

/**
 * Verify password success
 */
export type VerifyPasswordSuccessAction = {
  type: typeof VERIFY_PASSWORD_SUCCESS;
  payload: VerifyPasswordParsedResponse;
};

export const verifyPasswordSuccess: (
  VerifyPasswordParsedResponse,
) => VerifyPasswordSuccessAction = createAction(
  VERIFY_PASSWORD_SUCCESS,
);

/**
 * Verify password failure
 */
export type VerifyPasswordFailureAction = {
  type: typeof VERIFY_PASSWORD_FAILURE;
  payload: ErrorParsedResponse;
};

export const verifyPasswordFailure: (
  ErrorParsedResponse,
) => VerifyPasswordFailureAction = createAction(
  VERIFY_PASSWORD_FAILURE,
);
