import React from 'react';

import { SvgIcon, SvgIconProps } from '@waglabs/icons';

export type WellnessPlanDiamondIconProps = SvgIconProps;

export const WellnessPlanDiamondIcon = (
  props: WellnessPlanDiamondIconProps
) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="m21.114 39-20-23.362 8.781-9.597L20.815 5 32.16 6.193l8.955 9.445z"
        fill="#A1D6EA"
      />
      <path 
        d="m21.114 39-20-23.362h40z"
        fill="#7FBBEC"
      />
      <path 
        d="M21.114 39 9.472 15.737h22.687z"
        fill="#AADCF9"
      />
      <path 
        d="M20.816 5 9.472 15.737h22.687z"
        fill="#DAEDF4"
      />
      <path
        d="m9.895 6.04-4.429 4.958-4.352 4.739h8.358zM32.159 6.193l4.603 4.805 4.352 4.739h-8.955z"
        fill="#62B2F2"
      />
      <path
        d="m10.04 6.007-.567 9.73L20.816 5zM32.159 6.193v9.445L20.816 5z"
        fill="#89C7EC"
      />
    </g>
  </SvgIcon>
);
