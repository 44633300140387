export const OwnerInfoPage = {
  CompleteProfileGoogleSignupBtn: 'complete-profile-google-signup-btn',
  CompleteProfileEmailSignupBtn: 'complete-profile-email-signup-btn',
  ConflictModal: 'conflict-modal',
};

export const OwnerInfoForm = {
  FirstName: 'firstname',
  LastName: 'lastname',
  Email: 'email',
  Phone: 'phone',
  CompleteProfileEmailSignupBtn: 'complete-profile-email-signup-btn',
  ErrorText: 'error-text',
  SignUpWithEmailBtn: 'sign-up-with-email-btn',
};
