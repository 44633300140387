import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';

import { wagApi } from '../../../types/wagapi';

import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_AUTHENTICATION_PASSWORD_POST,
  Endpoint,
} from '../../../constants/endpoints';
import { AUTHENTICATION_PASSWORD_POST } from '../../../actions/actionTypes';
import { getOwnerToken } from '../../../selectors';
import {
  postAuthenticationPasswordSuccess,
  postAuthenticationPasswordFailure,
} from '../../../actions/auth/postAuthenticationPassword';
import { postAuthenticationPasswordResponseParser } from '../../../helpers/Http/ResponseParsers';
import {
  ApiErrorV5,
  buildApiErrorPipeline,
  createErrorForV5,
  mapErrorByType,
} from '../../../utils/buildApiErrorPipeline';
import { MagicLoginErrors } from '../../../constants';

const postAuthenticationPassword$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(AUTHENTICATION_PASSWORD_POST),
    retry(3),
    switchMap(({ payload: { sessionToken, password } }) =>
      post$({
        endpoint: ENDPOINT_AUTHENTICATION_PASSWORD_POST,
        payload: {
          sessionToken,
          password,
        },
        token: getOwnerToken(state$.value),
      } as {
        endpoint: Endpoint;
        payload: wagApi.PostAuthenticationPasswordRequestPayload;
        token: string;
      }).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: postAuthenticationPasswordSuccess,
            onFailure: postAuthenticationPasswordFailure,
            parser: postAuthenticationPasswordResponseParser,
          } as any) // TODO - Update typings on handleResponse
        ),
        catchError((error, source) => {
          const errorV5 = buildApiErrorPipeline<ApiErrorV5>(error).pipe(
            createErrorForV5,
            mapErrorByType(MagicLoginErrors.PasswordFlow)
          );
          return handleErrorV5Response(
            action$,
            postAuthenticationPasswordFailure
          )(errorV5, source);
        })
      )
    )
  );

export default postAuthenticationPassword$;
