import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import type { Notification as NotificationType } from '../../../reducers/notifications';
import Notification from '../Notification/Notification';
import './NotificationContainer.css';

type Props = {
  notifications: Array<NotificationType>,
};

// FIXME Add notification type
const Notifications = ({
  notifications,
}: Props) => (
  <div className="notification-container">
    <TransitionGroup>
      {notifications.map(notification => (
        <CSSTransition
          key={notification.ID}
          timeout={300}
          classNames="notification"
        >
          <Notification {...notification} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
);

export default connect(state => ({
  notifications: state.notifications.notifications,
}))(Notifications);
