
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalkerContactInfoParsedResponse,
} from 'webApp';

import {
  WALKER_CONTACT_INFO_GET,
  WALKER_CONTACT_INFO_SUCCESS,
  WALKER_CONTACT_INFO_FAILURE,
} from '../../actionTypes';


/**
 * Get Walker Contact Info
 */
export type GetWalkerContactInfoParams = {
  scheduleId: string,
  walkID: string,
};

export type GetWalkerContactInfoPayload = GetWalkerContactInfoParams;

export type GetWalkerContactInfoAction = {
  type: typeof WALKER_CONTACT_INFO_GET,
  payload: GetWalkerContactInfoPayload,
}

export const getWalkerContactInfo: (
  params: GetWalkerContactInfoParams
) => GetWalkerContactInfoAction = createAction(
  WALKER_CONTACT_INFO_GET,
  ({
    scheduleId,
    walkID,
  }) => ({
    scheduleId,
    walkID,
  }),
);


/**
 * Get Walker Contact Info success
 */
export type GetWalkerContactInfoSuccessAction = {
  type: typeof WALKER_CONTACT_INFO_SUCCESS,
  payload: GetWalkerContactInfoParsedResponse,
};

export const getWalkerContactInfoSuccess: (
  GetWalkerContactInfoParsedResponse,
) => GetWalkerContactInfoSuccessAction = createAction(
  WALKER_CONTACT_INFO_SUCCESS,
);


/**
 * Get Walker Contact Info failure
 */
export type GetWalkerContactInfoFailureAction = {
  type: typeof WALKER_CONTACT_INFO_FAILURE,
  payload: ErrorParsedResponse,
};

export const getWalkerContactInfoFailure: (
  ErrorParsedResponse,
) => GetWalkerContactInfoFailureAction = createAction(
  WALKER_CONTACT_INFO_FAILURE,
);
