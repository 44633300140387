 
// see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
type EventProps = {
  eventCategory: string,
  eventLabel?: string,
};

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_GOOGLE_TRACKING_URL) {
  throw new Error('Google Tracking URL missing');
}

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_GOOGLE_TRACKING_ID) {
  throw new Error('Google Container ID missing');
}

class Google {
  constructor() {
    // taken from https://developers.google.com/analytics/devguides/collection/analyticsjs/
    /* eslint-disable */
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; // $FlowFixMe
      a.async = 1;
      a.src = g; // $FlowFixMe
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    const { ga } = window;
    ga('create', process.env.REACT_APP_GOOGLE_TRACKING_ID, 'auto');
    ga('send', 'pageview');
    /* eslint-enable */
  }

  trackEvent = ({ eventCategory, eventLabel }: EventProps) => {
    window.ga('send', 'event', eventCategory, eventLabel);
  }
}

export default Google;
