
import { createAction } from 'redux-actions';
import type {
  BookingSetScheduleParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  BOOKING_SET_SCHEDULE,
  BOOKING_SET_SCHEDULE_SUCCESS,
  BOOKING_SET_SCHEDULE_FAILURE,
} from '../../actionTypes';


/**
 * Book a walk
 */
export type BookingSetScheduleParams = {
  accessInfo: any,
  dogNotes: string,
  dogs: number[],
  startDate: string,
  startTime: string,
  useFastestAvailable: number,
  usePreferredWalkers: number,
  walkTypeId: number,
};

export type BookingSetSchedulePayload = BookingSetScheduleParams;

export type BookingSetScheduleAction = {
  type: typeof BOOKING_SET_SCHEDULE,
  payload: BookingSetSchedulePayload,
};

export const bookingSetSchedule: (
  BookingSetScheduleParams
) => BookingSetScheduleAction = createAction(
  BOOKING_SET_SCHEDULE,
  ({ // TODO  verify that we need these callbacks
    accessInfo,
    dogNotes,
    dogs,
    startDate,
    startTime,
    useFastestAvailable,
    usePreferredWalkers,
    walkTypeId,
  }) => ({
    accessInfo,
    dogNotes,
    dogs,
    startDate,
    startTime,
    useFastestAvailable,
    usePreferredWalkers,
    walkTypeId,
  }),
);


/**
 * Book a walk Success
 */
export type BookingSetScheduleSuccessAction = {
  type: typeof BOOKING_SET_SCHEDULE_SUCCESS,
  payload: BookingSetScheduleParsedResponse,
};

export const bookingSetScheduleSuccess: (
  BookingSetScheduleParsedResponse,
) => BookingSetScheduleSuccessAction = createAction(
  BOOKING_SET_SCHEDULE_SUCCESS,
);


/**
 * Book a walk Failure
 */
export type BookingSetScheduleFailureAction = {
  type: typeof BOOKING_SET_SCHEDULE_FAILURE,
  payload: ErrorParsedResponse,
};

export const bookingSetScheduleFailure: (
  ErrorParsedResponse,
) => BookingSetScheduleFailureAction = createAction(
  BOOKING_SET_SCHEDULE_FAILURE,
);
