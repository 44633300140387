import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const SuccessCheckIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 88 88"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M88 44c0 3.84-2.68 7.277-3.627 10.819-.98 3.665-.405 7.976-2.262 11.185-1.883 3.255-5.922 4.902-8.564 7.544-2.641 2.64-4.289 6.68-7.543 8.563-3.209 1.857-7.52 1.282-11.185 2.262C51.277 85.319 47.84 88 44 88c-3.84 0-7.277-2.68-10.819-3.627-3.665-.98-7.976-.405-11.185-2.262-3.255-1.883-4.902-5.922-7.544-8.564-2.641-2.641-6.68-4.289-8.563-7.543-1.857-3.21-1.282-7.52-2.262-11.185C2.681 51.277 0 47.84 0 43.999c0-3.84 2.68-7.276 3.627-10.818.98-3.665.405-7.976 2.262-11.185 1.883-3.255 5.922-4.902 8.564-7.544 2.641-2.641 4.289-6.68 7.543-8.563 3.209-1.857 7.52-1.282 11.185-2.262C36.723 2.681 40.16 0 44.001 0c3.84 0 7.276 2.68 10.818 3.627 3.665.98 7.976.405 11.185 2.262 3.255 1.883 4.902 5.922 7.544 8.563 2.641 2.642 6.68 4.29 8.563 7.544 1.857 3.209 1.282 7.52 2.262 11.185C85.319 36.723 88 40.16 88 44"
        fill="#FAAB43"
      />
      <path
        d="M79.957 43.853c0 3.833-2.046 7.298-3.175 10.757-1.168 3.585-1.598 7.578-3.78 10.562-2.202 3.014-5.898 4.645-8.925 6.838-2.997 2.172-5.685 5.18-9.286 6.344-3.474 1.123-7.409.3-11.259.3-3.85 0-7.785.823-11.258-.3-3.6-1.164-6.29-4.172-9.286-6.344-3.027-2.193-6.723-3.824-8.926-6.838-2.181-2.984-2.61-6.977-3.78-10.562-1.128-3.459-3.175-6.924-3.175-10.757 0-3.834 2.047-7.299 3.175-10.758 1.17-3.584 1.599-7.578 3.78-10.561 2.203-3.014 5.899-4.645 8.926-6.839 2.997-2.172 5.685-5.18 9.285-6.344 3.474-1.123 7.41-.3 11.26-.3 3.85 0 7.784-.823 11.258.3 3.6 1.164 6.29 4.172 9.286 6.344 3.027 2.194 6.723 3.825 8.926 6.839 2.18 2.983 2.61 6.977 3.78 10.561 1.128 3.46 3.174 6.924 3.174 10.758Z"
        stroke="#FAE7A8"
        strokeWidth="1.725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.38 28.665c-.82-.892-2.15-.886-2.967.012L38.397 48.533l-6.188-5.572c-.856-.77-2.108-.874-3.076-.16-1.176.868-1.488 2.618-.692 3.906l7.052 11.416c.244.394.562.764.934 1.06 1.738 1.386 4.174.966 5.44-.934l.16-.24 17.568-26.364c.604-.902.532-2.17-.216-2.98"
        fill="#FAE7A8"
      />
    </g>
  </SvgIcon>
);
