import {
  SocialAuthErrors,
  EndpointSocialAuthentication,
  FACEBOOK_SOCIAL_SIGN_IN,
  GOOGLE_SOCIAL_SIGN_IN,
  APPLE_SOCIAL_SIGN_IN,
} from '../../constants';
import {
  buildApiErrorPipeline,
  mapErrorByType,
  mapErrorToApiSlice,
  unwrapSliceError,
  createErrorForV6,
} from '../../utils/buildApiErrorPipeline';
import { tryCatch } from '../../utils/tryCatch';
import { apiSlice } from '../apiSlice';

const extractSocialAuthProviderUrl = (
  socialAuthProviderToLink: string,
) => {
  switch (socialAuthProviderToLink) {
  case FACEBOOK_SOCIAL_SIGN_IN:
    return EndpointSocialAuthentication.Facebook;
  case GOOGLE_SOCIAL_SIGN_IN:
    return EndpointSocialAuthentication.Google;
  case APPLE_SOCIAL_SIGN_IN:
    return EndpointSocialAuthentication.Apple;
  default:
    return '';
  }
};

const generateBodyBasedOnSocialAuthProvider = (
  socialAuthProviderToLink: string,
  facebookAccessToken?: string,
  googleIdToken?: string,
  appleIdToken?: string,
) => {
  switch (socialAuthProviderToLink) {
  case FACEBOOK_SOCIAL_SIGN_IN:
    return {
      accessToken: facebookAccessToken,
    };
  case GOOGLE_SOCIAL_SIGN_IN:
    return {
      accessToken: googleIdToken,
    };
  case APPLE_SOCIAL_SIGN_IN:
    return {
      accessToken: appleIdToken,
    };
  default:
    return {
      accessToken: '',
    };
  }
};

export const putLinkAccountAuthApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    /*
      TOOD refactor to use multiple endpoints in this slice instead of
      complex body extraction logic to determine th social auth provider
      to call the put request to
    */

    putLinkAccountAuthentication: builder.mutation({
      queryFn: async (body, _api, _extraOptions, query) => {
        // Body is only dynamic paremter accepted by the rtk queryfn
        // hence the destructuring and function calling
        const {
          socialAuthProviderToLink,
          facebookAccessToken,
          googleIdToken,
          appleIdToken,
        } = body;

        const url = extractSocialAuthProviderUrl(socialAuthProviderToLink);

        const newBody = generateBodyBasedOnSocialAuthProvider(
          socialAuthProviderToLink,
          facebookAccessToken,
          googleIdToken,
          appleIdToken,
        );

        const adjustedArgs = {
          url,
          method: 'PUT',
          body: newBody,
        };

        const [response] = await tryCatch(() => query(adjustedArgs));

        if (response.error) {
          return buildApiErrorPipeline(response.error)
            .pipe(
              unwrapSliceError,
              createErrorForV6,
              mapErrorByType(SocialAuthErrors.LinkAccountsAuthApiErrors),
              mapErrorToApiSlice,
            );
        }
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePutLinkAccountAuthenticationMutation,
} = putLinkAccountAuthApi;
