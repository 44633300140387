import { ofType } from 'redux-observable';
import {
  takeUntil,
  take,
  merge,
  mergeMapTo,
} from 'rxjs/operators';
import { of } from 'rxjs';

import store from '../../../store/configureStore';
import { getOwnerID, getOwnerToken } from '../../../selectors';
import {
  OWNER_AUTH_REFRESH_GET_SUCCESS,
  OWNER_AUTH_REFRESH_GET_FAILURE,
  GLOBAL_ERROR_SHOW,
} from '../../../actions/actionTypes';
import { getOwnerRefreshToken } from '../../../actions/owner/getOwnerRefreshToken';
import getResponseActionForStatusCode from '../getResponseActionForStatusCode';
import {
  STATUS_CODE_UNAUTHENTICATED,
  STATUS_CODE_SERVER_ERROR,
  STATUS_CODE_SERVER_NOT_RESPONDING,
} from '../../../constants/statusCodes';
import { API_ERROR_TITLE, API_ERROR_MESSAGE } from '../../../constants/errors';

export const refreshToken = (action$, error, source) =>
  action$.pipe(
    ofType(OWNER_AUTH_REFRESH_GET_SUCCESS),
    takeUntil(action$.pipe(ofType(OWNER_AUTH_REFRESH_GET_FAILURE))),
    take(1),
    mergeMapTo(source),
    merge(of(getOwnerRefreshToken()))
  );

export const handleErrorV5Response = (action$, onError) => (error, source) => {
  const errorCode = error.status;
  const defaultErrorMessage = 'error occurred..';

  /**
   * ! Stripe related endpoints contains the human-readable
   * error message in `error.response.detail`
   */
  const errorResponseDetail = error.response && error.response.detail;
  const errorResponseTitle = error.response && error.response.title;
  const errorMessage =
    errorResponseDetail || errorResponseTitle || defaultErrorMessage;
  const errorMessages =
    error.response && error.response.errors ? error.response.errors[0] : {};
  const { Rollbar } = window;

  if (error.status === STATUS_CODE_UNAUTHENTICATED) {
    const state = store.getState();
    const ownerID = getOwnerID(state);
    const ownerToken = getOwnerToken(state);

    // Get new refresh token only if user is already authenticated
    if (ownerID && ownerToken) {
      return refreshToken(action$, error, source);
    }
  }

  if (error.status === STATUS_CODE_SERVER_ERROR) {
    Rollbar.error(`wagAPI error response :: ${error}`);
  }

  if (error.status === STATUS_CODE_SERVER_NOT_RESPONDING) {
    const errorResponse = `XHR connection error :: ${error}`;
    Rollbar.error(errorResponse);
    store.dispatch({
      type: GLOBAL_ERROR_SHOW,
      payload: {
        title: API_ERROR_TITLE,
        message: API_ERROR_MESSAGE,
      },
    });
  }

  return [
    getResponseActionForStatusCode(errorCode),
    onError({
      errorCode,
      error: errorMessage,
      errorMessages,
      response: error.response,
    }),
  ];
};
