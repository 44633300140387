export const parseQueryString = (querystring: string) => {
  // Remove any preceding url and split
  const parsedQueryString = querystring.substring(querystring.indexOf('?') + 1).split('&');
  const params = {};
  const d = decodeURIComponent;
  let pair;

  // March and parse
  for (let i = parsedQueryString.length - 1; i >= 0; i -= 1) {
    pair = parsedQueryString[i].split('=');
    params[d(pair[0])] = d(pair[1] || '');
  }

  return params;
};
export default parseQueryString;
