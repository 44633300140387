import { handleActions } from 'redux-actions';
import type {
  CreditPackage,
  WalkType,
  ConfirmationTimes,
} from 'webApp';

import {
  CREDIT_PACKAGES_GET_SUCCESS,
  OWNER_GET_SUCCESS,
} from '../../actions/actionTypes';
import type { GetCreditPackagesSuccessAction } from '../../actions/owner/getCreditPackages';
import type { GetOwnerSuccessAction } from '../../actions/owner/getOwner';

export type ServicesState = {
  packages: Record<string, CreditPackage>;
  walkTypes: WalkType[];
  confirmationTimes: ConfirmationTimes;
};
export const initialState: ServicesState = {
  packages: {},
  walkTypes: [],
  confirmationTimes: {},
};
export const reducer: any = handleActions({
  [OWNER_GET_SUCCESS]: (state: ServicesState, {
    payload: {
      walkTypes,
      confirmationTimes,
    },
  }: GetOwnerSuccessAction): ServicesState => ({ ...state,
    walkTypes,
    confirmationTimes }),
  [CREDIT_PACKAGES_GET_SUCCESS]: (state: ServicesState, {
    payload,
  }: GetCreditPackagesSuccessAction): ServicesState => ({ ...state,
    packages: payload }),
}, initialState);
export default reducer;
