import {
  useSelector,
  useDispatch,
} from 'react-redux';

import { updatePreferredWalker } from '../../actions/owner/updatePreferredWalker';
import { PREFERRED_WALKER_REQUEST_KEY } from '../../constants';
import { ReduxState } from '../../reducers';
import { getRequestStates } from '../../selectors';

export const useUpdateOwnerPreferredWalker = () => {
  const getUpdateOwnerPreferredWalkerState = (state: ReduxState) => (
    getRequestStates(state, PREFERRED_WALKER_REQUEST_KEY)
  );

  const {
    error,
    isFetching,
    isSuccess,
  } = useSelector(getUpdateOwnerPreferredWalkerState);
  const dispatch = useDispatch();

  const execute = (params: {
    walkerId: string;
    isPreferred: boolean;
  }) => {
    dispatch(updatePreferredWalker(params));
  };

  return {
    // Don't need the response from the server here because it doesn't return the walker.
    data: null,
    error,
    isLoading: isFetching,
    isSuccess,
    execute,
  };
};
