import {
  SCHEDULE_TYPE_ASAP,
  SCHEDULE_TYPE_SCHEDULED,
} from './app';

export const DRAFT_SIGNUP_WELCOME_FORM = 'signupWelcomeForm';
export const DRAFT_SIGNUP_DOG_INFO_FORM = 'signupDogInfoForm';
export const DRAFT_SIGNUP_ADDRESS_FORM = 'signupAddressForm';
export const DRAFT_SIGNUP_BASIC_INFO_FORM = 'signupBasicInfoForm';
export const DRAFT_BOOKING_SINGLE_WALK_FORM = 'bookingSingleWalkBookForm';
export const DRAFT_BOOKING_SINGLE_ASAP_WALK_FORM = 'bookingSingleASAPWalkBookForm';
export const DRAFT_BOOKING_RECURRING_WALK_FORM = 'bookingRecurringWalkBookForm';
export const DRAFT_BOOKING_TRAINING_FORM = 'bookingTrainingForm';

export const signupDraftKeys = [
  DRAFT_SIGNUP_WELCOME_FORM,
  DRAFT_SIGNUP_DOG_INFO_FORM,
  DRAFT_SIGNUP_ADDRESS_FORM,
  DRAFT_SIGNUP_BASIC_INFO_FORM,
] as const;
export const scheduleBookingDraftKey: Record<string, string> = {
  [SCHEDULE_TYPE_SCHEDULED]: DRAFT_BOOKING_SINGLE_WALK_FORM,
  [SCHEDULE_TYPE_ASAP]: DRAFT_BOOKING_SINGLE_ASAP_WALK_FORM,
};

export const DRAFT_WELLNESS_PLANS_FORM = 'wellnessPlansForm';
export const DRAFT_WELLNESS_ADDONS_FORM = 'wellnessAddonsForm';
export const DRAFT_WELLNESS_CHECKOUT_FORM = 'wellnessCheckoutForm';

export const wellnessDraftKeys = [
  DRAFT_WELLNESS_PLANS_FORM,
  DRAFT_WELLNESS_ADDONS_FORM,
  DRAFT_WELLNESS_CHECKOUT_FORM,
] as const;

export const DRAFT_WELLNESS_PREMIUM_PLANS_FORM = 'wellnessPremiumPlansForm';
export const DRAFT_WELLNESS_PREMIUM_CHECKOUT_FORM = 'wellnessPremiumCheckoutForm';

export const wellnessPremiumDraftKeys = [
  DRAFT_WELLNESS_PREMIUM_PLANS_FORM,
  DRAFT_WELLNESS_PREMIUM_CHECKOUT_FORM,
] as const;

export const DRAFT_LOGGED_IN_WELLNESS_PLANS_FORM = 'loggedInWellnessPlansForm';
export const DRAFT_LOGGED_IN_WELLNESS_CHECKOUT_FORM = 'loggedInWellnessCheckoutForm';

export const loggedInWellnessDraftKeys = [
  DRAFT_LOGGED_IN_WELLNESS_PLANS_FORM,
  DRAFT_LOGGED_IN_WELLNESS_CHECKOUT_FORM,
];

export const DRAFT_VET_CHAT_CHECKOUT_FORM = 'vetChatCheckoutForm';

export const vetChatDraftKeys = [
  DRAFT_VET_CHAT_CHECKOUT_FORM,
] as const;

export const DRAFT_LOGGED_IN_VET_CHAT_CHECKOUT_FORM = 'loggedInVetChatCheckoutForm';

export const loggedInVetChatDraftKeys = [
  DRAFT_LOGGED_IN_VET_CHAT_CHECKOUT_FORM,
];

export const DRAFT_PREMIUM_CHECKOUT_FORM = 'premiumCheckoutForm';

export const MOBILE_APP_TAKE_OVER = 'mobileAppTakeOver';

export const premiumDraftKeys = [
  DRAFT_PREMIUM_CHECKOUT_FORM,
];
