export const AddressInfo = {
  Street: 'address-form-street',
  StreetAutocompleteMenu: 'address-form-street-autocomplete-menu',
  StreetAutocompleteMenuItem: 'address-form-street-autocomplete-item-option',
  SuiteOrApt: 'suite-or-apt',
  City: 'city',
  State: 'state',
  ZipCode: 'zip-code',
  SubmitBtn: 'submitBtn',
};
