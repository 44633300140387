export const QS_NEW_PREMIUM_SUBSCRIBER = 'new_premium_subscriber';
export const QS_PROMO_CODE_KEY = 'promo_code';
export const QS_QUESTION_ID_KEY = 'question_id';
export const QS_REDIRECT_URL_KEY = 'redirect_url';
export const QS_SIGNUP_FLOW_SOURCE_KEY = 'signup_flow_source';
export const QS_SUCCESS_REDIRECT = 'success_redirect';
export const QS_UTM_CONTENT_KEY = 'utm_content';
export const QS_UTM_CAMPAIGN = 'utm_campaign';
export const QS_UTM_SOURCE = 'utm_source';
export const QS_UTM_MEDIUM = 'utm_medium';
export const QS_UTM_TERM = 'utm_term';
export const QS_UTM_CONTENT = 'utm_content';
export const QS_PREFERRED_SERVICE = 'preferred_service';
export const QS_GCLID = 'gclid';
export const QS_AD_GROUP = 'ad_group';
export const QS_AD_CREATIVE = 'ad_creative';
export const QS_ADID = 'adid';
export const QS_WELLNESS_PLAN = 'wellness_plan';
export const QS_VET_CHAT = 'vet_chat';
export const QS_PET_NAME = 'pet_name';
export const QS_PET_BREED = 'pet_breed';
export const QS_PET_BIRTHDAY = 'pet_birthday';
export const QS_PET_WEIGHT = 'pet_weight';
export const QS_BRANCH_LINK = 'branch_link';

export const UtmContent = Object.freeze({
  BIRD: 'BIRD',
  BREED: 'BREED',
  CAT: 'CAT',
  ConditionGuide: 'CONDITION_GUIDE',
  GROOMING: 'GROOMING',
  HORSE: 'HORSE',
  RABBIT: 'RABBIT',
  SENSE: 'SENSE',
  SymptomGuide: 'SYMPTOM_GUIDE',
  TreatmentGuide: 'TREATMENT_GUIDE',
  WELLNESS: 'WELLNESS',
  WagWellness: 'wag-wellness',
  VetChat: 'vet-chat',
  Premium: 'premium',
});

export const UtmCampaign = {
  WebFunnel: 'web-funnel',
} as const;

export const UtmSource = {
  Web: 'web',
} as const;

export const UtmMedium = {
  Organic: 'organic',
} as const;

export const utmQueryStringKeys = [
  QS_UTM_MEDIUM,
  QS_UTM_CONTENT_KEY,
  QS_UTM_SOURCE,
  QS_UTM_CAMPAIGN,
  QS_UTM_TERM,
  QS_UTM_CONTENT,
] as const;

export const petQueryStringKeys = [
  QS_PET_NAME,
  QS_PET_BREED,
  QS_PET_BIRTHDAY,
  QS_PET_WEIGHT,
];
