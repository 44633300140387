import React, { ReactNode } from 'react';

import queryString from 'query-string';
import { connect } from 'react-redux';
import {
  withRouter,
  ContextRouter,
  useHistory,
} from 'react-router-dom';

import { WagRedirect } from '../components/WagRedirect';
import {
  CookieKeys,
  QS_REDIRECT_URL_KEY,
  ROUTE_LOGIN,
  ROUTE_SIGNUP_BASIC_INFO,
} from '../constants';
import { useLogout } from '../hooks';
import { isInEmbed } from '../hooks/useIsInEmbed';
import { getAuthParams } from '../selectors';
import { getCookie } from '../utils/getCookie';

type LocationProp = ContextRouter['location'];
type RedirectToLoginProps = {
  location: LocationProp;
};

const RedirectToLogin = ({
  location: {
    pathname,
  },
}: RedirectToLoginProps) => {
  const queryStringToAttach = queryString.stringify({
    [QS_REDIRECT_URL_KEY]: pathname,
  });
  return (
    <WagRedirect to={{
      path: ROUTE_LOGIN,
      search: queryStringToAttach,
    }}
    />
  );
};

export type Props = {
  children: ReactNode;
  ownerID: string;
  renderLoggedIn: (arg0: {
    ownerID: string;
    ownerToken: string;
  }) => ReactNode;
  renderLoggedOut: (arg0: {
    location: LocationProp;
    redirectToLogin: ReactNode;
  }) => ReactNode;
  token: string;
} & ContextRouter;
export const AuthComponent = (props: Props) => {
  const {
    children,
    location,
    ownerID,
    renderLoggedIn,
    renderLoggedOut,
    token,
  } = props;
  const {
    execute: logout,
  } = useLogout();

  // Check if we're logged in
  if (ownerID && token) {
    if (isInEmbed) {
      /**
       * There will be cases where the user will be "authenticated" at the end of the flow
       * and by opening again the modal, the normal behavior of the app is to
       *  redirect it to home screen.
       * We do not support right now accessing the home screen in the embedded version
       *
       * Logout the user and redirect back to the signup flow
       */

      // Check if cookie has expired and if so redirect to first step of direct booking
      if (!getCookie(CookieKeys.OwnerIdentifier) || !getCookie(CookieKeys.OwnerAuthToken)) {
        return (
          <WagRedirect
            to={{
              path: ROUTE_SIGNUP_BASIC_INFO,
            }}
          />
        );
      }
    }
    // If we passed a render prop, invoke it with
    // auth props
    if (renderLoggedIn) {
      return renderLoggedIn({
        ownerID,
        ownerToken: token,
      });
    }
    // If we didnt, wrap the children with auth props
    const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, {
      ownerID,
      ownerToken: token,
    }));

    return childrenWithProps;
  }

  const redirectToLogin = <RedirectToLogin location={location} />;

  if (renderLoggedOut) {
    /**
     * Make sure to clear the necessary items to avoid stale data
     */
    logout();
    return renderLoggedOut({ redirectToLogin, location });
  }

  return redirectToLogin;
};
export default withRouter<any>(connect((state) => ({ ...getAuthParams(state) }))(AuthComponent));
