import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isBookingScheduleInThePast } from "../utils/bookingValidations/isBookingScheduleInThePast";
import { createNotification, clearAll } from "../actions/notification";
import { BOOKING_IN_THE_PAST_ERROR, BOOKING_BOARDING_IN_THE_PAST_ERROR, BOOKING_SITTING_IN_THE_PAST_ERROR, BOOKING_TRAINING_IN_THE_PAST_ERROR } from "../constants/errors";
import { WALK_TYPE_SITTING, WALK_TYPE_BOARDING, WALK_TYPE_TRAINING } from "../constants/app";
// Enhance more
export const useBookingScheduleValidation = (strategy = isBookingScheduleInThePast) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAll());
  }, [dispatch]);

  const execute = ({
    startDate,
    startTime,
    walkTypeId
  }) => {
    const isBookingInThePast = strategy({
      startDate,
      startTime
    });

    if (isBookingInThePast) {
      let error;

      switch (walkTypeId) {
        case WALK_TYPE_BOARDING:
          error = BOOKING_BOARDING_IN_THE_PAST_ERROR;
          break;

        case WALK_TYPE_SITTING:
          error = BOOKING_SITTING_IN_THE_PAST_ERROR;
          break;

        case WALK_TYPE_TRAINING:
          error = BOOKING_TRAINING_IN_THE_PAST_ERROR;
          break;

        default:
          error = BOOKING_IN_THE_PAST_ERROR;
          break;
      }

      dispatch(createNotification({
        notificationType: 'danger',
        text: error
      }));
      window.scrollTo(0, 0);
      return false;
    }

    return true;
  };

  return execute;
};