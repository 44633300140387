import { handleActions } from 'redux-actions';

import { SITTING_QUESTIONNAIRE_GET_SUCCESS } from '../../actions/actionTypes';
import type { SittingQuestionnaireSuccessAction } from '../../actions/booking/getSittingQuestionnaire';

export type SittingQuestionnaireState = {};
export const initialState: SittingQuestionnaireState = {};
export const reducer: any = handleActions<SittingQuestionnaireState, Record<string, any>>({
  [SITTING_QUESTIONNAIRE_GET_SUCCESS]: (state: SittingQuestionnaireState, {
    payload,
  }: SittingQuestionnaireSuccessAction): SittingQuestionnaireState => ({ ...state,
    ...payload }),
}, initialState);
export default reducer;
