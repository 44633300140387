import type { GetWalkerResponse } from "wagAPI";
import type { GetWalkerParsedResponse } from "webApp";
export const getWalkerResponseParser = (response: GetWalkerResponse): GetWalkerParsedResponse => ({
  id: String(response.id),
  email: response.email,
  phone: response.phone,
  firstName: response.first_name,
  lastName: response.last_name,
  stripeConnectFlow: response.stripe_connect_flow,
  thumb: response.thumb
});
export default getWalkerResponseParser;