import React from 'react';

if (process.env.REACT_APP_STAGE !== 'test' && !process.env.REACT_APP_FACEBOOK_KEY) {
  throw new Error('Facebook pixel URL missing');
}

const FacebookPixel = () => (
  <img
    height="1"
    width="1"
    style={{ display: 'none' }}
    src={`https://www.facebook.com/tr?id=${process.env.REACT_APP_FACEBOOK_KEY || ''}&ev=PageView&noscript=1`}
    alt="fb"
  />
);

export default FacebookPixel;
