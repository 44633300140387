import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import type { RegisterOwnerApplyDetailsRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_APPLY_DETAILS_REGISTER, Endpoint } from "../../../constants/endpoints";
import { OWNER_APPLY_DETAILS_REGISTER } from "../../../actions/actionTypes";
import { getOwnerToken } from "../../../selectors";
import { registerOwnerApplyDetailsSuccess, registerOwnerApplyDetailsFailure, RegisterOwnerApplyDetailsAction } from "../../../actions/registerOwner/registerOwnerApplyDetails";
import { registerOwnerApplyDetailsResponseParser } from "../../../helpers/Http/ResponseParsers";

const registerOwnerApplyDetails$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_APPLY_DETAILS_REGISTER), retry(3), switchMap(({
  payload: {
    token: legacyToken,
    phoneNumber
  }
}: RegisterOwnerApplyDetailsAction) => post$(({
  endpoint: ENDPOINT_OWNER_APPLY_DETAILS_REGISTER,
  payload: {
    token: legacyToken,
    phoneNumber
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: RegisterOwnerApplyDetailsRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: registerOwnerApplyDetailsSuccess,
  onFailure: registerOwnerApplyDetailsFailure,
  parser: registerOwnerApplyDetailsResponseParser
})), catchError(handleErrorV5Response(action$, registerOwnerApplyDetailsFailure)))));

export default registerOwnerApplyDetails$;