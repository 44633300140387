import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { UpdateDogQuestionaireRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_DOG_QUESTIONAIRE } from "../../../constants/endpoints";
import { DOG_UPDATE_QUESTIONAIRE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { updateDogQuestionaireSuccess, updateDogQuestionaireFailure, UpdateDogQuestionaireAction } from "../../../actions/dog/updateDogQuestionaire";

const updateDogQuestionaire$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(DOG_UPDATE_QUESTIONAIRE), switchMap(({
  payload: {
    dogID,
    walk: {
      dmWalkEat,
      dmWalkLeashTrained,
      dmWalkScared,
      dmWalkJump,
      dmWalkPullLeash,
      dmWalkChase,
      dmWalkInteraction,
      dmWalkDirt
    },
    home: {
      dmHomeProtect,
      dmHomeShy,
      dmHomePees,
      dmHomeRushOut
    }
  }
}: UpdateDogQuestionaireAction) => post$(({
  endpoint: ENDPOINT_DOG_QUESTIONAIRE,
  payload: {
    dog_id: dogID,
    answers: {
      walk: {
        dmWalkEat,
        dmWalkLeashTrained,
        dmWalkScared,
        dmWalkJump,
        dmWalkPullLeash,
        dmWalkChase,
        dmWalkInteraction,
        dmWalkDirt
      },
      home: {
        dmHomeProtect,
        dmHomeShy,
        dmHomePees,
        dmHomeRushOut
      }
    },
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: string;
  payload: UpdateDogQuestionaireRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updateDogQuestionaireSuccess,
  onFailure: updateDogQuestionaireFailure
})), catchError(handleErrorV5Response(action$, updateDogQuestionaireFailure)))));

export default updateDogQuestionaire$;