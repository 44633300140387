import moment from "moment";
import type { BookingSetScheduleResponse } from "wagAPI";
import type { BookingSetScheduleParsedResponse } from "webApp";
export const bookingSetScheduleResponseParser = ({
  schedule,
  ...others
}: BookingSetScheduleResponse): BookingSetScheduleParsedResponse => ({
  id: String(schedule.id),
  days: schedule.days.map(day => parseInt(day, 10)),
  isRecurring: !!schedule.is_recurring,
  startTime: moment(schedule.start_time, 'hh:mm:ss').format('h:mma'),
  startDate: moment(schedule.first_walk).format('MM/DD/YYYY'),
  requestTypeID: schedule.request_type,
  walkTypeID: schedule.walk_type_id,
  walkIDs: others.walk_ids
});
export default bookingSetScheduleResponseParser;