
import { createAction } from 'redux-actions';

import type { ErrorParsedResponse } from 'webApp';

import {
  UPDATE_WALKER_PASSWORD,
  UPDATE_WALKER_PASSWORD_SUCCESS,
  UPDATE_WALKER_PASSWORD_FAILURE,
} from '../../actionTypes';

/**
 * Update walker password
 */
export type UpdateWalkerPasswordParams = {
  password: string,
};
export type UpdateWalkerPasswordAction = {
  type: typeof UPDATE_WALKER_PASSWORD,
  payload: UpdateWalkerPasswordParams,
};

export const updateWalkerPassword: (params: UpdateWalkerPasswordParams) =>
  UpdateWalkerPasswordAction = createAction(
    UPDATE_WALKER_PASSWORD,
  );

/**
 * Update walker password success
 */
export type UpdateWalkerPasswordSuccessAction = {
  type: typeof UPDATE_WALKER_PASSWORD_SUCCESS,
};

export const updateWalkerPasswordSuccess: () => UpdateWalkerPasswordSuccessAction = createAction(
  UPDATE_WALKER_PASSWORD_SUCCESS,
);

/**
 * Update walker password failure
 */
export type UpdateWalkerPasswordFailureAction = {
  type: typeof UPDATE_WALKER_PASSWORD_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateWalkerPasswordFailure: (
  ErrorParsedResponse
) => UpdateWalkerPasswordFailureAction = createAction(
  UPDATE_WALKER_PASSWORD_FAILURE,
);
