import { ReduxState } from '../reducers';

import { applyMigration } from './applyMigration';

export const localStorageKey = 'wag-partner-app';

const filterState = (state: ReduxState): ReduxState => {
  const {
    requests,
    owner,
  } = state;
  const clearedRequestsState = Object.keys(requests).reduce((acc, requestKey) => ({
    ...acc,
    [requestKey]: {
      // TODO - Fix typing
      ...(requests as any)[requestKey],
      isFetched: false,
      isFetching: false,
      isSubscribed: false,
    },
  }), {});
  return {
    ...state,
    requests: clearedRequestsState,
    owner,
    error: {} as ReduxState['error'], // clear global error on refresh
  };
};

export const loadState = (key: string) => {
  let persistedState: Partial<ReduxState> = {};

  try {
    persistedState = JSON.parse(window.localStorage.getItem(key) || '') || {};
  } catch (error) {
    window.localStorage.clear();
    persistedState = {};
  }

  return persistedState;
};

export const saveState = (key = 'app-state', wait = 0) => (store) => (next) => (action) => {
  const nextAction = next(action);
  let throttled = false;

  if (!throttled) {
    throttled = true;
    setTimeout(() => {
      const state = store.getState();
      const sanitizedState = applyMigration(filterState(state));

      try {
        const serializedState = JSON.stringify(sanitizedState);
        throttled = false;
        window.localStorage.setItem(key, serializedState);
      } catch (error) {
        window.localStorage.clear();
      }
    }, wait);
  }

  return nextAction;
};
