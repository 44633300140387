export const CookieKeys = {

  // Walker auth token
  AuthToken: 'a_t',
  WalkerIdentifier: 'w_id',
  OwnerIdentifier: 'o_id',
  /*
    Separate key for owner auth token to avoid any authentication conflicts.
    More long term fix would be to refactor AuthToken to WalkerAuthToken
    everywhere it's used across wagweb and owner-webapp for clearer naming.
  */
  OwnerAuthToken: 'o_a_t',
};
