import moment from 'moment';

type FormatOptions = {
  startTime: string;
  startDate: string;
};
export const fromDateToTime = (date: string): moment.Moment => moment(date).format('HH:mm:ss');
export const parseTimeOnly = (time: string): moment.Moment => moment(time, 'HH:mm');
export const toStandardTime = (militaryDateTime: string): string => parseTimeOnly(militaryDateTime).format('LT');
export const prettyFormatSchedule = ({
  startTime,
  startDate,
}: FormatOptions): string | null | undefined => {
  if (!startTime || !startDate) {
    return null;
  }

  const prettyFormat = 'dddd, MMMM Do [at] hh:mm a';
  const savedWalkDateFormat = 'MM/DD/YYYYhh:mm a';
  const date = moment([startDate, startTime].join(' '), savedWalkDateFormat).format(prettyFormat);
  return date;
};
