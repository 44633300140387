import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { ChangeOwnerPasswordRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_CHANGE_PASSWORD, Endpoint } from "../../../constants/endpoints";
import { OWNER_PASSWORD_CHANGE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { changeOwnerPasswordSuccess, changeOwnerPasswordFailure, ChangeOwnerPasswordAction } from "../../../actions/owner/changeOwnerPassword";

const changeOwnerPassword$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_PASSWORD_CHANGE), switchMap(({
  payload: {
    newPassword
  }
}: ChangeOwnerPasswordAction) => post$(({
  endpoint: ENDPOINT_OWNER_CHANGE_PASSWORD,
  payload: {
    type: 'owner',
    password: newPassword,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: ChangeOwnerPasswordRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: changeOwnerPasswordSuccess,
  onFailure: changeOwnerPasswordFailure
})), catchError(handleErrorV5Response(action$, changeOwnerPasswordFailure)))));

export default changeOwnerPassword$;