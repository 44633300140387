
import { createAction } from 'redux-actions';
import type {
  BookingSetScheduleParsedResponse,
  ErrorParsedResponse,
  V4Dog,
} from 'webApp';

import {
  WALK_SCHEDULE_UPDATE,
  WALK_SCHEDULE_UPDATE_SUCCESS,
  WALK_SCHEDULE_UPDATE_FAILURE,
} from '../../actionTypes';
import type {
  WalkType,
} from '../../../constants/app';

// TODO - Change this later; This is just a patch to fix weird typings on legacy code
export type ScheduleUpdateParams = any;

/**
 * Update a walk schedule (Book a walk)
 */
export type UpdateWalkScheduleParams = {
  selectDogs: V4Dog,
  accessNotes: string,
  dogNotes: string,
  gateCode: string,
  isRecurring: boolean,
  keyMode: number,
  lockBoxCode: string,
  lockBoxInfo?: string,
  onDemand?: boolean,
  scheduleDays: Array<number>,
  scheduleRecurringTypeId?: number,
  startDate: string, // 'YYYY-MM-DD'
  startTime: string, // 'HH:mm:00'
  useFastestAvailable: boolean,
  usePreferredWalker?: null,
  walkTypeId: WalkType, // 1 = regular walk, 2 = deluxe walk
  accessInfo: any,
};

export type UpdateWalkSchedulePayload = UpdateWalkScheduleParams;

export type updateWalkScheduleAction = {
  type: typeof WALK_SCHEDULE_UPDATE,
  payload: UpdateWalkSchedulePayload,
}

export const updateWalkSchedule: (
  UpdateWalkScheduleParams,
) => updateWalkScheduleAction = createAction(
  WALK_SCHEDULE_UPDATE,
);


/**
 * Update walk schedule success
 */
export type UpdateWalkScheduleSuccessAction = {
  type: typeof WALK_SCHEDULE_UPDATE_SUCCESS,
  payload: BookingSetScheduleParsedResponse,
};

export const updateWalkScheduleSuccess: (
  BookingSetScheduleParsedResponse,
) => UpdateWalkScheduleSuccessAction = createAction(
  WALK_SCHEDULE_UPDATE_SUCCESS,
);


/**
 * Update walk schedule failure
 */
export type UpdateWalkScheduleFailureAction = {
  type: typeof WALK_SCHEDULE_UPDATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateWalkScheduleFailure: (
  ErrorParsedResponse,
) => UpdateWalkScheduleFailureAction = createAction(
  WALK_SCHEDULE_UPDATE_FAILURE,
);
