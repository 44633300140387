import { handleActions } from 'redux-actions';
import type { StyledSystemProps } from 'webApp';

import {
  NOTIFICATION_CREATE,
  NOTIFICATION_CLEAR,
  NOTIFICATIONS_CLEAR,
} from '../../actions/actionTypes';
import type {
  CreateNotificationAction,
  ClearNotificationAction,
} from '../../actions/notification';

export type Notification = {
  ID: number;
  type?: string;
  text: string;
  timeout? : number;
  notificationStyleProps? : StyledSystemProps;
};
export type NotificationState = {
  notifications: Array<Notification>;
  lastID: number;
};
export const initialState = {
  lastID: 0,
  notifications: [],
};
export const reducer: any = handleActions({
  [NOTIFICATION_CREATE]: (state: NotificationState, {
    payload: {
      notificationType,
      text,
      timeout,
      notificationStyleProps,
    },
  }: CreateNotificationAction): NotificationState => ({ ...state,
    notifications: [...state.notifications, {
      ID: state.lastID + 1,
      type: notificationType,
      text,
      timeout,
      notificationStyleProps,
    }],
    lastID: state.lastID + 1 }),
  [NOTIFICATION_CLEAR]: (state: NotificationState, {
    payload: {
      notificationID,
    },
  }: ClearNotificationAction): NotificationState => ({ ...state,
    notifications: state.notifications.filter(({
      ID,
    }) => ID !== notificationID) }),
  [NOTIFICATIONS_CLEAR]: (state: NotificationState): NotificationState => ({ ...state,
    notifications: [] }),
}, initialState);
export default reducer;
