import { webApp } from '../types/webapp';

// TODO remove duplicate constants

export const ROUTE_APP = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGIN_REQUEST = '/login-request';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_UNSERVICEABLE = '/unserviceable';

export const ROUTE_SIGNUP_V1 = '/v1/signup';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_SIGNUP_V1_BASIC_INFO = `${ROUTE_SIGNUP_V1}/basic-info`;
export const ROUTE_SIGNUP_BASIC_INFO = '/signup/basic-info';
export const ROUTE_SIGNUP_V1_DOG_INFO = `${ROUTE_SIGNUP_V1}/dog-info`;
/**
 * @deprecated Remove
 */
export const ROUTE_SIGNUP_DOG_INFO = '/signup/dog-info';
export const ROUTE_SIGNUP_DOG_AGE = '/signup/dog-age';
export const ROUTE_SIGNUP_PROMO_CODE = '/signup/promo-code';
export const ROUTE_SIGNUP_V1_ADDRESS = `${ROUTE_SIGNUP_V1}/address`;
export const ROUTE_SIGNUP_ADDRESS = '/signup/address';
export const ROUTE_SIGNUP_LOCKBOX = '/signup/get-lock-box';
export const ROUTE_SIGNUP_LOCKBOX_SEND = '/signup/get-lock-box/send';
export const ROUTE_SIGNUP_FIRST_FREE_WALK = '/signup/first-free-walk';
export const ROUTE_SIGNUP_BOOKING = '/signup/booking';
export const ROUTE_SIGNUP_BOOKING_WALK = '/signup/booking/book-a-walk';
export const ROUTE_SIGNUP_BOOKING_GET_IN = '/signup/booking/how-to-get-in';
export const ROUTE_SIGNUP_WELCOME = '/signup/welcome';
export const ROUTE_SIGNUP_V1_COMPLETE = `${ROUTE_SIGNUP_V1}/complete`;
export const ROUTE_SIGNUP_COMPLETE = '/signup/complete';
export const ROUTE_SIGNUP_LOADING = '/signup/loading';
export const ROUTE_SIGNUP_SELECTION_SCREEN = '/select-service';

export const ROUTE_HOME = '/home';
export const ROUTE_PROFILE = '/my-profile';
export const ROUTE_PROFILE_EDIT = '/my-profile/edit';
export const ROUTE_PAYMENTS = '/payments';
export const ROUTE_PAST_SERVICES = '/past-services';
export const ROUTE_UPCOMING_SERVICES = '/upcoming-services';
export const ROUTE_ADD_DOG = '/add-dog';
export const ROUTE_DOG_PROFILE = (dogID: string) => `/dog-profile/${dogID}`;
export const ROUTE_DOG_PROFILE_EDIT = (dogID: string) => `/dog-profile/${dogID}/edit`;

export const ROUTE_WALK_BOOKING_ONETTIME_DURATION = '/select-walk-duration';
export const ROUTE_WALK_BOOKING_DROPIN_DURATION = '/select-dropin-duration';
export const ROUTE_WALK_BOOKING_ONETIME_SCHEDULED = ({ walkType }: { walkType: string }) => `/book/${walkType}/scheduled`;
export const ROUTE_WALK_BOOKING_ONETIME_ACCESS_INFO = ({ walkType }: { walkType: string }) => `/book/${walkType}/scheduled/access-info`;
export const ROUTE_WALK_BOOKING_ONETIME_PAYMENTS = ({ walkType }: { walkType: string }) => `/book/${walkType}/scheduled/payments`;
export const ROUTE_WALK_BOOKING_ONETIME_CONFIRMATION = ({ walkType }: { walkType: string }) => `/book/${walkType}/scheduled/booking-confirmation-screen`;

export const ROUTE_WALK_BOOKING_RECURRING = '/booking/recurring';
export const ROUTE_WALK_BOOKING_RECURRING_SELECTED = ({ walkType }: { walkType: string }) => `/booking/recurring/${walkType}`;
export const ROUTE_WALK_BOOKING_RECURRING_ACCESS_INFO = ({ walkType }: { walkType: string }) => `/booking/recurring/${walkType}/scheduled/access-info`;
export const ROUTE_WALK_BOOKING_RECURRING_PAYMENTS = ({ walkType }: { walkType: string }) => `/booking/recurring/${walkType}/scheduled/payments`;
export const ROUTE_WALK_BOOKING_RECURRING_CONFIRMATION = ({ walkType }: { walkType: string }) => `/booking/recurring/${walkType}/scheduled/booking-confirmation-screen`;

export const ROUTE_WALK_BOOKING_DROP_INS_ASAP = ({ walkType }: { walkType: string }) => `/book/${walkType}/asap`;

export const ROUTE_OVERNIGHT = '/overnight';
export const ROUTE_OVERNIGHT_SITTING = '/overnight/sitting';
export const ROUTE_OVERNIGHT_BOARDING = '/overnight/boarding';
export const ROUTE_OVERNIGHT_SELECTED = (overnightType: string) => `/overnight/${overnightType}`;
export const ROUTE_OVERNIGHT_BOOKING_ACCESS_INFO = '/overnight/access-info';
export const ROUTE_OVERNIGHT_SURVEY = '/overnight/survey';
export const ROUTE_OVERNIGHT_PREFERENCES = '/overnight/preferences';
export const ROUTE_OVERNIGHT_CHECKOUT = '/overnight/checkout';
export const ROUTE_OVERNIGHT_PAYMENTS = '/overnight/payments';
export const ROUTE_OVERNIGHT_CONFIRMATION = '/overnight/confirmation';
export const ROUTE_OVERNIGHT_SUMMARY = '/overnight/summary';

export const ROUTE_WALK_SELECTED = (walkID: string) => `/walk/${walkID}`;
export const ROUTE_WALK_SELECTED_CANCEL = (walkID: string) => `/walk/${walkID}/cancel`;
export const ROUTE_WALK_SELECTED_EDIT = (walkID: string) => `/walk/${walkID}/edit`;
export const ROUTE_WALK_SELECTED_EDIT_ACCESS_INFO = (walkID: string) => `/walk/${walkID}/edit/access-info`;
export const ROUTE_SELECT_WALK_DURATION = '/select-walk-duration';

export const ROUTE_WALKER_STRIPE_CONNECT = '/walker/stripe-connect';
export const ROUTE_WALKER_STRIPE_CONNECT_LOGIN = '/walker/stripe-connect/signIn';
export const ROUTE_WALKER_STRIPE_CONNECT_LANDING = '/walker/stripe-connect/landing';

export const ROUTE_TRAINING = '/training';
export const ROUTE_TRAINING_SELECT_TRAINING_DURATION = '/training/select-training-duration';
export const ROUTE_TRAINING_PREFERRED_MATCHING = '/training/preferred-matching';
export const ROUTE_TRAINING_PREFERRED_MATCHING_HELP = '/training/preferred-matching/help';
export const ROUTE_TRAINING_BOOKING = '/training/booking';
export const ROUTE_TRAINING_BOOKING_ACCESS_INFO = '/training/booking/access-info';
export const ROUTE_TRAINING_BOOKING_PAYMENT = '/training/booking/payment';
export const ROUTE_TRAINING_BOOKING_SUCCESS = '/training/booking/success';

// TODO - Use the upcoming standard way to get routes 👀
export const ROUTE_WALKER_PROFILE = (walkerID: number) => `/walker-profile/${walkerID}`;

export const ROUTE_JOIN_WAG_PREMIUM = '/join-premium';
export const ROUTE_JOIN_WAG_PREMIUM_SUCCESS = '/join-premium/success';

export const ROUTE_DEV_SELECTOR = '/dev/selector';

export const ROUTE_HELP = 'https://help.wagwalking.com/category/dog-owners';
export const ROUTE_HELP_WALKER = 'https://help.wagwalking.com/category/dog-walkers';

export const ROUTE_PASSWORD_RECOVERY = (
  userType: webApp.PasswordRecoveryUserTypeIdentifier,
  userId: number,
  resetToken: string,
) => `/login/reset-password/${userType}/web/${userId}/${resetToken}`;

// New routes should use this mapping for a better type safe
const WAGWEB_URL = process.env.REACT_APP_WAGWEB_URL || 'https://wagwalking.com';

export const Routes = {
  Embed: '/embed',

  // Application routes
  Home: '/home',
  Login: '/login',

  MyProfile: '/my-profile',

  PetProfile: '/my-profile/pets/:id',
  PetProfileEdit: '/my-profile/pets/:id/edit',

  AddPet: '/add-pet',

  PastServices: '/past-services',
  Payments: '/payments',
  Premium: '/premium',
  PremiumCheckout: '/premium/checkout',
  PremiumComplete: '/premium/complete',
  Services: '/services',
  ServicesLive: '/services/{{id}}/live',
  ServicesReportCard: '/report-card/{{id}}',

  UpcomingServices: '/upcoming-services',

  WagHealth: '/wag-health',
  WagHealthCheckout: '/wag-health/checkout',
  WagHealthComplete: '/wag-health/complete',

  LoggedInWagHealth: '/my-wag-health',
  LoggedInWagHealthCheckout: '/my-wag-health/checkout',
  LoggedInWagHealthComplete: '/my-wag-health/complete',

  /**
   * @deprecated - Use the /services path
   */
  Walk: '/walk',
  WalkerProfile: '/walker-profile/{{id}}',

  Wellness: '/wellness',
  WellnessPlans: '/wellness/plans',
  WellnessAddons: '/wellness/addons',
  WellnessCheckout: '/wellness/checkout',
  WellnessComplete: '/wellness/complete',
  WellnessPremium: '/wellness/premium',
  WellnessPremiumPlans: '/wellness/premium/plans',
  WellnessPremiumCheckout: '/wellness/premium/checkout',
  WellnessPremiumComplete: '/wellness/premium/complete',

  /**
   * Wellness recommendation is a new version of wellness plan
   * We added `/v2` so we can create different versions next time around
   */
  WellnessRecommendation: '/v2/wellness',
  WellnessRecommendationAccount: '/v2/wellness/account',
  WellnessRecommendationFlow: '/v2/wellness/subscriptions',
  WellnessRecommendationBreedStep: '/v2/wellness/subscriptions/:id/breed',
  WellnessRecommendationBirthdayStep: '/v2/wellness/subscriptions/:id/birthday',
  WellnessRecommendationWeightStep: '/v2/wellness/subscriptions/:id/weight',
  WellnessRecommendationSpayedStep: '/v2/wellness/subscriptions/:id/spay-or-neuter',
  WellnessRecommendationGroomingStep: '/v2/wellness/subscriptions/:id/grooming',
  WellnessRecommendationLoadingStep: '/v2/wellness/subscriptions/:id/loading',
  WellnessRecommendationSummaryStep: '/v2/wellness/subscriptions/:id/summary',
  WellnessRecommendationPlanDetailsStep: '/v2/wellness/subscriptions/:id/plans',
  WellnessRecommendationPlanAddonsDetailsStep: '/v2/wellness/subscriptions/:id/addons',
  WellnessRecommendationCheckout: '/v2/wellness/subscriptions/checkout',
  WellnessRecommendationComplete: '/v2/wellness/subscriptions/complete',
  WellnessRecommendationAddNewPet: '/v2/wellness/create-pet',

  WellnessRecommendationsBreedPath: '/breed',
  WellnessRecommendationsBirthdayPath: '/birthday',
  WellnessRecommendationsWeightPath: '/weight',
  WellnessRecommendationsSpayedPath: '/spay-or-neuter',
  WellnessRecommendationsGroomingPath: '/grooming',
  WellnessRecommendationsSummaryPath: '/summary',
  WellnessRecommendationsPlansPath: '/plans',
  WellnessRecommendationsAddonsPath: '/addons',

  LoggedInWellness: '/my-wellness',
  LoggedInWellnessPlans: '/my-wellness/plans',
  LoggedInWellnessAddons: '/my-wellness/addons',
  LoggedInWellnessCheckout: '/my-wellness/checkout',
  LoggedInWellnessComplete: '/my-wellness/complete',
  LoggedInWellnessSubscriptions: '/my-wellness/subscriptions',
  LoggedInWellnessPlanDetailsStep: '/my-wellness/subscriptions/:id/plans',
  LoggedInWellnessAddonsDetailsStep: '/my-wellness/subscriptions/:id/addons',
  LoggedInWellnessSubscriptionCheckout: '/my-wellness/subscriptions/:id/checkout',
  LoggedInWellnessSubscriptionComplete: '/my-wellness/subscriptions/complete',
  LoggedInWellnessCancellationHelp: '/my-wellness/cancellation-help',

  WalkerSignIn: '/walker/signin',
  WalkerSignUp: '/walker/signup',

  WalkerStripeConnectDashboard: '/walker/stripe-connect/dashboard',

  Signup: '/signup/welcome',
  SignupOwnerExists: '/signup/owner-exists',
  SignupPetInfoPart1: '/signup/pet-info',
  SignupPetInfoPart2: '/signup/pet-info/part-2',
  SignupAddress: '/signup/address',
  SignupBasicInfo: '/signup/basic-info',
  SignupComplete: '/signup/complete',

  DirectBookingFlow: {
    SelectService: '/signup/direct-booking/select-service',
  }
} as const;

export const ExternalRoutes = {
  PremiumLandingPage: `${WAGWEB_URL}/wag-premium`,
  PremiumSupportPage: 'https://support.wagwalking.com/en_us/what-is-wag-premium-pp-S1ZIz0mQ5',
  VetChatLandingPage: `${WAGWEB_URL}/wag-health`,
  WellnessLandingPage: `${WAGWEB_URL}/wag-wellness`,
  WellnessPlansLandingPage: `${WAGWEB_URL}/wag-wellness/wellness-plans`,
  WellnessReimbursementForm: 'https://wagwalking.kustomer.help/contact/wag-wellness-reimbursement-SkrAemJ8F',
  TermsAndConditions: 'https://safety.wagwalking.com/terms',
  TermsAndConditionsApp: 'https://safety.wagwalking.com/terms/app',
  TermsAndConditionsArbitration: 'https://safety.wagwalking.com/terms#arbitrataion',
  PrivacyPolicy: 'https://safety.wagwalking.com/privacy',
  MobileTerms: 'https://safety.wagwalking.com/terms-pet-parent-mobile',
  WellnessAgreement: 'https://safety.wagwalking.com/terms-wellness-membership-agreement',
  NorthpassQuizDomain: process.env.REACT_APP_NORTHPASS_QUIZ_DOMAIN,
} as const;

export type AppRoute = typeof ROUTE_APP
  | typeof ROUTE_LOGIN
  | typeof ROUTE_LOGIN_REQUEST
  | typeof ROUTE_FORGOT_PASSWORD
  | typeof ROUTE_UNSERVICEABLE

  | typeof ROUTE_SIGNUP_V1
  | typeof ROUTE_SIGNUP
  | typeof ROUTE_SIGNUP_V1_BASIC_INFO
  | typeof ROUTE_SIGNUP_BASIC_INFO
  | typeof ROUTE_SIGNUP_V1_DOG_INFO
  | typeof ROUTE_SIGNUP_DOG_INFO
  | typeof ROUTE_SIGNUP_DOG_AGE
  | typeof ROUTE_SIGNUP_V1_ADDRESS
  | typeof ROUTE_SIGNUP_ADDRESS
  | typeof ROUTE_SIGNUP_LOCKBOX
  | typeof ROUTE_SIGNUP_LOCKBOX_SEND
  | typeof ROUTE_SIGNUP_FIRST_FREE_WALK
  | typeof ROUTE_SIGNUP_BOOKING
  | typeof ROUTE_SIGNUP_BOOKING_WALK
  | typeof ROUTE_SIGNUP_BOOKING_GET_IN
  | typeof ROUTE_SIGNUP_V1_COMPLETE
  | typeof ROUTE_SIGNUP_COMPLETE
  | typeof ROUTE_SIGNUP_WELCOME
  | typeof ROUTE_SIGNUP_LOADING
  | typeof ROUTE_SIGNUP_SELECTION_SCREEN

  | typeof ROUTE_HOME
  | typeof ROUTE_PROFILE
  | typeof ROUTE_PROFILE_EDIT
  | typeof ROUTE_PAYMENTS
  | typeof ROUTE_PAST_SERVICES
  | typeof ROUTE_UPCOMING_SERVICES
  | typeof ROUTE_ADD_DOG
  | typeof ROUTE_DOG_PROFILE
  | typeof ROUTE_DOG_PROFILE_EDIT

  | typeof ROUTE_WALK_BOOKING_ONETTIME_DURATION
  | typeof ROUTE_WALK_BOOKING_ONETIME_SCHEDULED
  | typeof ROUTE_WALK_BOOKING_ONETIME_ACCESS_INFO
  | typeof ROUTE_WALK_BOOKING_ONETIME_PAYMENTS
  | typeof ROUTE_WALK_BOOKING_ONETIME_CONFIRMATION

  | typeof ROUTE_WALK_BOOKING_RECURRING
  | typeof ROUTE_WALK_BOOKING_RECURRING_SELECTED
  | typeof ROUTE_WALK_BOOKING_RECURRING_ACCESS_INFO
  | typeof ROUTE_WALK_BOOKING_RECURRING_PAYMENTS
  | typeof ROUTE_WALK_BOOKING_RECURRING_CONFIRMATION

  | typeof ROUTE_WALK_BOOKING_DROP_INS_ASAP

  | typeof ROUTE_OVERNIGHT
  | typeof ROUTE_OVERNIGHT_SITTING
  | typeof ROUTE_OVERNIGHT_BOARDING
  | typeof ROUTE_OVERNIGHT_BOOKING_ACCESS_INFO
  | typeof ROUTE_OVERNIGHT_SURVEY
  | typeof ROUTE_OVERNIGHT_PREFERENCES
  | typeof ROUTE_OVERNIGHT_CHECKOUT
  | typeof ROUTE_OVERNIGHT_PAYMENTS
  | typeof ROUTE_OVERNIGHT_CONFIRMATION

  | typeof ROUTE_WALK_SELECTED
  | typeof ROUTE_SELECT_WALK_DURATION
  | typeof ROUTE_WALKER_STRIPE_CONNECT

  | typeof ROUTE_TRAINING
  | typeof ROUTE_TRAINING_SELECT_TRAINING_DURATION
  | typeof ROUTE_TRAINING_PREFERRED_MATCHING
  | typeof ROUTE_TRAINING_BOOKING
  | typeof ROUTE_TRAINING_BOOKING_ACCESS_INFO
  | typeof ROUTE_TRAINING_BOOKING_PAYMENT
  | typeof ROUTE_TRAINING_BOOKING_SUCCESS

  | typeof ROUTE_WALKER_PROFILE

  | typeof ROUTE_JOIN_WAG_PREMIUM
  | typeof ROUTE_JOIN_WAG_PREMIUM_SUCCESS

  | typeof ROUTE_DEV_SELECTOR
  | typeof ROUTE_HELP
