import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const DogNameOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M21 20H3c-.552 0-1-.442-1-.987V7.566a.994.994 0 011-.987h6.2v-.592a.994.994 0 011-.987h3.6c.552 0 1 .442 1 .987V7.96a.994.994 0 01-1 .986h-3.6c-.552 0-1-.442-1-.986v-.593H3c-.112 0-.2.087-.2.198v11.447c0 .11.088.198.2.198h18c.112 0 .2-.087.2-.198V7.566a.197.197 0 00-.2-.198h-6.2v-.79H21c.552 0 1 .443 1 .988v11.447A.994.994 0 0121 20zM10 7.368v.593c0 .11.088.197.2.197h3.6c.112 0 .2-.087.2-.197V5.987a.197.197 0 00-.2-.198h-3.6c-.112 0-.2.087-.2.198v1.381zm.872 9.474h-5.68c-.592 0-.792-.908-.792-1.153v-.078c0-1.264 1.136-1.682 1.888-1.958l.24-.087c.272-.103.272-.142.272-.253 0-.19-.04-.316-.08-.347-.4-.324-.616-.798-.616-1.35 0-1.105.776-1.871 1.896-1.871 1.12 0 1.936.79 1.936 1.87 0 .538-.224 1.02-.64 1.35-.064.048-.12.206-.12.332 0 .11 0 .158.296.269l.24.087c.752.276 1.888.694 1.888 1.958v.078c0 .245-.128 1.153-.728 1.153zm-5.56-.79h5.416c.04-.102.072-.268.072-.363v-.078c0-.672-.52-.908-1.368-1.216l-.248-.095c-.712-.268-.808-.655-.808-1.003 0-.276.104-.686.408-.931a.928.928 0 00.352-.75c0-.537-.352-1.082-1.136-1.082-.808 0-1.096.584-1.096 1.082 0 .323.104.568.32.742.328.268.376.703.376.94 0 .378-.096.75-.784 1.01l-.248.095c-.856.315-1.368.552-1.368 1.215v.08c0 .07.056.252.112.355zm13.488 0h-5.6v-.789h5.6v.79zm-1.6-2.368h-4v-.79h4v.79zm1.6-2.368h-5.6v-.79h5.6v.79z"
      fill="#727272"
      fill-rule="nonzero"
    />
  </SvgIcon>
);
