import {
  BOOKING_MEET_GREET_FAILURE,
  AUTHENTICATION_CODE_POST,
  AUTHENTICATION_CODE_POST_FAILURE,
  AUTHENTICATION_CODE_POST_SUCCESS,
  AUTHENTICATION_PASSWORD_POST,
  AUTHENTICATION_PASSWORD_POST_FAILURE,
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
  AUTHENTICATION_SESSION_GET,
  AUTHENTICATION_SESSION_GET_FAILURE,
  AUTHENTICATION_SESSION_GET_SUCCESS,
  AUTHENTICATION_SESSION_POST,
  AUTHENTICATION_SESSION_POST_FAILURE,
  AUTHENTICATION_SESSION_POST_SUCCESS,
  AUTHENTICATION_UPGRADE_POST,
  AUTHENTICATION_UPGRADE_POST_FAILURE,
  AUTHENTICATION_UPGRADE_POST_SUCCESS,
  AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE,
  AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS,
  AUTHENTICATION_VERIFY_POST,
  AUTHENTICATION_VERIFY_POST_FAILURE,
  AUTHENTICATION_VERIFY_POST_SUCCESS,
  BOOKING_CANCEL_SCHEDULE,
  BOOKING_CANCEL_SCHEDULE_FAILURE,
  BOOKING_CANCEL_SCHEDULE_SUCCESS,
  BOOKING_EDIT_SCHEDULE,
  BOOKING_EDIT_SCHEDULE_FAILURE,
  BOOKING_EDIT_SCHEDULE_SUCCESS,
  BOOKING_MEET_GREET,
  BOOKING_MEET_GREET_SUCCESS,
  BOOKING_OVERNIGHT,
  BOOKING_OVERNIGHT_FAILURE,
  BOOKING_OVERNIGHT_SUCCESS,
  BOOKING_SET_SCHEDULE,
  BOOKING_SET_SCHEDULE_FAILURE,
  BOOKING_SET_SCHEDULE_SUCCESS,
  CREDIT_CARD_UPDATE,
  CREDIT_CARD_UPDATE_FAILURE,
  CREDIT_CARD_UPDATE_SUCCESS,
  DOGS_GET,
  DOGS_GET_FAILURE,
  DOGS_GET_SUCCESS,
  DOG_BREEDS_GET,
  DOG_BREEDS_GET_FAILURE,
  DOG_BREEDS_GET_SUCCESS,
  DOG_BREED_ASSETS_GET,
  DOG_BREED_ASSETS_GET_FAILURE,
  DOG_BREED_ASSETS_GET_SUCCESS,
  DOG_CREATE,
  DOG_CREATE_FAILURE,
  DOG_CREATE_SUCCESS,
  DOG_DELETE,
  DOG_DELETE_FAILURE,
  DOG_DELETE_SUCCESS,
  DOG_PROFILE_PATCH,
  DOG_PROFILE_PATCH_FAILURE,
  DOG_PROFILE_PATCH_SUCCESS,
  DOG_UPDATE_QUESTIONAIRE,
  DOG_UPDATE_QUESTIONAIRE_FAILURE,
  DOG_UPDATE_QUESTIONAIRE_SUCCESS,
  DOG_UPDATE_V4,
  DOG_UPDATE_V4_FAILURE,
  DOG_UPDATE_V4_SUCCESS,
  ESTIMATE_OVERNIGHT,
  ESTIMATE_OVERNIGHT_FAILURE,
  ESTIMATE_OVERNIGHT_SUCCESS,
  LOGIN_WITH_CODE_REQUEST_CREATE,
  LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE,
  LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS,
  LOGIN_WITH_CODE_SUBMIT,
  LOGIN_WITH_CODE_SUBMIT_FAILURE,
  LOGIN_WITH_CODE_SUBMIT_SUCCESS,
  MY_PROFILE_UPDATE,
  MY_PROFILE_UPDATE_FAILURE,
  MY_PROFILE_UPDATE_SUCCESS,
  OWNER_ADD_PREFERRED_WALKER as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE,
  OWNER_ADD_PREFERRED_WALKER_FAILURE as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_FAILURE,
  OWNER_ADD_PREFERRED_WALKER_SUCCESS as OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_SUCCESS,
  OWNER_APPLY_DETAILS_REGISTER,
  OWNER_APPLY_DETAILS_REGISTER_FAILURE,
  OWNER_APPLY_DETAILS_REGISTER_SUCCESS,
  OWNER_AVAILABLE_SERVICES,
  OWNER_AVAILABLE_SERVICES_FAILURE,
  OWNER_AVAILABLE_SERVICES_SUCCESS,
  OWNER_CREDIT_GET,
  OWNER_CREDIT_GET_FAILURE,
  OWNER_CREDIT_GET_SUCCESS,
  OWNER_GET,
  OWNER_GET_FAILURE,
  OWNER_GET_SUCCESS,
  OWNER_GOOGLE_SIGNIN_REGISTER,
  OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE,
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  OWNER_HOME_ACCESS_GET,
  OWNER_HOME_ACCESS_GET_FAILURE,
  OWNER_HOME_ACCESS_GET_SUCCESS,
  OWNER_HOME_ACCESS_UPDATE,
  OWNER_HOME_ACCESS_UPDATE_FAILURE,
  OWNER_HOME_ACCESS_UPDATE_SUCCESS,
  OWNER_LOGIN,
  OWNER_LOGIN_FAILURE,
  OWNER_LOGIN_SUCCESS,
  OWNER_MASKED_INFO_GET,
  OWNER_MASKED_INFO_GET_FAILURE,
  OWNER_MASKED_INFO_GET_SUCCESS,
  OWNER_PASSWORD_CHANGE,
  OWNER_PASSWORD_CHANGE_FAILURE,
  OWNER_PASSWORD_CHANGE_SUCCESS,
  OWNER_PASSWORD_RESET,
  OWNER_PASSWORD_RESET_EMAIL_SEND,
  OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
  OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
  OWNER_PASSWORD_RESET_FAILURE,
  OWNER_PASSWORD_RESET_SUCCESS,
  OWNER_PAYMENT_METHODS_GET,
  OWNER_PAYMENT_METHODS_GET_FAILURE,
  OWNER_PAYMENT_METHODS_GET_SUCCESS,
  OWNER_PAYMENT_METHOD_CREATE,
  OWNER_PAYMENT_METHOD_CREATE_FAILURE,
  OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
  OWNER_PAYMENT_METHOD_UPDATE,
  OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
  OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
  OWNER_PREFERRED_WALKER_CREATE,
  OWNER_PREFERRED_WALKER_CREATE_FAILURE,
  OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
  OWNER_PREFERRED_WALKER_DELETE,
  OWNER_PREFERRED_WALKER_DELETE_FAILURE,
  OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
  OWNER_PREMIUM_SUBSCRIPTION_GET,
  OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE,
  OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
  OWNER_REGISTRATION_ADDRESS,
  OWNER_REGISTRATION_ADDRESS_FAILURE,
  OWNER_REGISTRATION_ADDRESS_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO,
  OWNER_REGISTRATION_BASIC_INFO_FAILURE,
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_V2,
  OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  OWNER_REGISTRATION_PREFERRED_SERVICES,
  OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE,
  OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS,
  OWNER_REGISTRATION_REQUEST_LOCKBOX,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS,
  OWNER_SERVICES_GET,
  OWNER_SERVICES_GET_FAILURE,
  OWNER_SERVICES_GET_SUCCESS,
  OWNER_SMART_MODULES,
  OWNER_SMART_MODULES_FAILURE,
  OWNER_SMART_MODULES_SUCCESS,
  OWNER_UPDATE,
  OWNER_UPDATE_FAILURE,
  OWNER_UPDATE_SUCCESS,
  PASSWORD_TIPS,
  PASSWORD_TIPS_FAILURE,
  PASSWORD_TIPS_SUCCESS,
  PAST_PET_CAREGIVERS_GET,
  PAST_PET_CAREGIVERS_GET_FAILURE,
  PAST_PET_CAREGIVERS_GET_SUCCESS,
  PREFERRED_WALKER_UPDATE,
  PREFERRED_WALKER_UPDATE_FAILURE,
  PREFERRED_WALKER_UPDATE_SUCCESS,
  PROMO_CODE_APPLY,
  PROMO_CODE_APPLY_FAILURE,
  PROMO_CODE_APPLY_SUCCESS,
  REBOOKING,
  REBOOKING_FAILURE,
  REBOOKING_SUCCESS,
  SERVICEABLE_ZIP_CODE_CHECK,
  SERVICEABLE_ZIP_CODE_CHECK_FAILURE,
  SERVICEABLE_ZIP_CODE_CHECK_SUCCESS,
  SERVICE_PROVIDERS_GET,
  SERVICE_PROVIDERS_GET_FAILURE,
  SERVICE_PROVIDERS_GET_SUCCESS,
  SITTING_QUESTIONNAIRE_GET,
  SITTING_QUESTIONNAIRE_GET_FAILURE,
  SITTING_QUESTIONNAIRE_GET_SUCCESS,
  SUBSCRIBE_TO_PREMIUM,
  SUBSCRIBE_TO_PREMIUM_FAILURE,
  SUBSCRIBE_TO_PREMIUM_SUCCESS,
  TRAINING_BOOKING,
  TRAINING_BOOKING_FAILURE,
  TRAINING_BOOKING_SUCCESS,
  UPDATE_WALKER_PASSWORD,
  UPDATE_WALKER_PASSWORD_FAILURE,
  UPDATE_WALKER_PASSWORD_SUCCESS,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD_SUCCESS,
  VISITOR_ACCOUNT_CREATE,
  VISITOR_ACCOUNT_CREATE_FAILURE,
  VISITOR_ACCOUNT_CREATE_SUCCESS,
  WALKER_CONTACT_INFO_FAILURE,
  WALKER_CONTACT_INFO_GET,
  WALKER_CONTACT_INFO_SUCCESS,
  WALKER_GET,
  WALKER_GET_FAILURE,
  WALKER_GET_SUCCESS,
  WALKER_PASSWORD_RESET,
  WALKER_PASSWORD_RESET_FAILURE,
  WALKER_PASSWORD_RESET_SUCCESS,
  WALKER_PROFILE_BY_PROMO_CODE_GET,
  WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
  WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
  WALKER_PROFILE_GET,
  WALKER_PROFILE_GET_FAILURE,
  WALKER_PROFILE_GET_SUCCESS,
  WALKER_SCHEDULE_GET,
  WALKER_SCHEDULE_GET_FAILURE,
  WALKER_SCHEDULE_GET_SUCCESS,
  WALKER_SIGN_IN,
  WALKER_SIGN_IN_FAILURE,
  WALKER_SIGN_IN_SUCCESS,
  WALKER_SIGN_UP,
  WALKER_SIGN_UP_FAILURE,
  WALKER_SIGN_UP_SUCCESS,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
  WALKS_IN_PENDING_REVIEW_GET,
  WALKS_IN_PENDING_REVIEW_GET_FAILURE,
  WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
  WALKS_IN_PROGRESS_GET,
  WALKS_IN_PROGRESS_GET_FAILURE,
  WALKS_IN_PROGRESS_GET_SUCCESS,
  WALKS_IN_THE_PAST_GET,
  WALKS_IN_THE_PAST_GET_FAILURE,
  WALKS_IN_THE_PAST_GET_SUCCESS,
  WALKS_UPCOMING_GET,
  WALKS_UPCOMING_GET_FAILURE,
  WALKS_UPCOMING_GET_SUCCESS,
  WALK_COVER_CREATE,
  WALK_COVER_CREATE_FAILURE,
  WALK_COVER_CREATE_SUCCESS,
  WALK_DETAILS_GET,
  WALK_DETAILS_GET_FAILURE,
  WALK_DETAILS_GET_SUCCESS,
  WALK_SCHEDULE_UPDATE,
  WALK_SCHEDULE_UPDATE_FAILURE,
  WALK_SCHEDULE_UPDATE_SUCCESS,
} from '../../actions/actionTypes';
import {
  AUTHENTICATION_CODE_POST_REQUEST_KEY,
  AUTHENTICATION_PASSWORD_POST_REQUEST_KEY,
  AUTHENTICATION_SESSION_GET_REQUEST_KEY,
  AUTHENTICATION_SESSION_POST_REQUEST_KEY,
  AUTHENTICATION_UPGRADE_POST_REQUEST_KEY,
  AUTHENTICATION_VERIFY_POST_REQUEST_KEY,
  BOOKING_CANCEL_SCHEDULE_REQUEST_KEY,
  BOOKING_EDIT_SCHEDULE_REQUEST_KEY,
  BOOKING_MEET_GREET_REQUEST_KEY,
  BOOKING_OVERNIGHT_REQUEST_KEY,
  BOOKING_SET_SCHEDULE_REQUEST_KEY,
  CREDIT_CARD_UPDATE_REQUEST_KEY,
  DOG_BREED_ASSETS_REQUEST_KEY,
  DOG_CREATE_REQUEST_KEY,
  DOG_DELETE_REQUEST_KEY,
  DOG_GET_BREEDS_REQUEST_KEY,
  DOG_PROFILE_PATCH_REQUEST_KEY,
  DOG_UPDATE_QUESTIONAIRE_REQUEST_KEY,
  DOG_UPDATE_V4_REQUEST_KEY,
  ESTIMATE_OVERNIGHT_REQUEST_KEY,
  GET_DOGS_REQUEST_KEY,
  LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY,
  LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY,
  MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY,
  OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY,
  OWNER_AVAILABLE_SERVICES_REQUEST_KEY,
  OWNER_CREDIT_REQUEST_KEY,
  OWNER_GET_REQUEST_KEY,
  OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY,
  OWNER_HOME_ACCESS_GET_REQUEST_KEY,
  OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY,
  OWNER_MASKED_INFO_GET_REQUEST_KEY,
  OWNER_PASSWORD_CHANGE_REQUEST_KEY,
  OWNER_PASSWORD_RESET_REQUEST_KEY,
  OWNER_PAYMENT_METHODS_GET_REQUEST_KEY,
  OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY,
  OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY,
  OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY,
  OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY,
  OWNER_REGISTRATION_ADDRESS_REQUEST_KEY,
  OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY,
  OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY,
  OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY,
  OWNER_SERVICES_GET_REQUEST_KEY,
  OWNER_SMART_MODULES_REQUEST_KEY,
  OWNER_TOKEN_JWT_REQUEST_KEY,
  OWNER_UPDATE_REQUEST_KEY,
  PASSWORD_RESET_EMAIL_REQUEST_KEY,
  PASSWORD_TIPS_REQUEST_KEY,
  PAST_PET_CAREGIVERS_GET_REQUEST_KEY,
  PREFERRED_WALKER_REQUEST_KEY,
  PROMO_CODE_REQUEST_KEY,
  REBOOKING_REQUEST_KEY,
  SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY,
  SERVICE_PROVIDERS_GET_REQUEST_KEY,
  SITTING_QUESTIONNAIRE_REQUEST_KEY,
  SUBSCRIBE_TO_PREMIUM_REQUEST_KEY,
  TRAINING_BOOKING_REQUEST_KEY,
  UPDATE_WALKER_PASSWORD_REQUEST_KEY,
  VERIFY_PASSWORD_REQUEST_KEY,
  VISITOR_ACCOUNT_CREATE_REQUEST_KEY,
  WALKER_CONTACT_INFO_REQUEST_KEY,
  WALKER_GET_REQUEST_KEY,
  WALKER_PASSWORD_RESET_REQUEST_KEY,
  WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY,
  WALKER_PROFILE_REQUEST_KEY,
  WALKER_SIGN_IN_REQUEST_KEY,
  WALKER_SIGN_UP_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_REQUEST_KEY,
  WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY,
  WALKER_WALK_GET_REQUEST_KEY,
  WALKS_IN_PAST_REQUEST_KEY,
  WALKS_IN_PROGRESS_REQUEST_KEY,
  WALKS_PENDING_REVIEW_REQUEST_KEY,
  WALKS_UPCOMING_REQUEST_KEY,
  WALK_COVER_CREATE_REQUEST_KEY,
  WALK_DETAILS_GET_REQUEST_KEY,
  WALK_SCHEDULE_UPDATE_REQUEST_KEY,
} from '../../constants/requestKeys';

export const getRequestKeyForAction = (action: string) => {
  switch (action) {
  case AUTHENTICATION_CODE_POST:
  case AUTHENTICATION_CODE_POST_FAILURE:
  case AUTHENTICATION_CODE_POST_SUCCESS:
    return AUTHENTICATION_CODE_POST_REQUEST_KEY;

  case AUTHENTICATION_SESSION_GET:
  case AUTHENTICATION_SESSION_GET_FAILURE:
  case AUTHENTICATION_SESSION_GET_SUCCESS:
    return AUTHENTICATION_SESSION_GET_REQUEST_KEY;

  case AUTHENTICATION_SESSION_POST:
  case AUTHENTICATION_SESSION_POST_FAILURE:
  case AUTHENTICATION_SESSION_POST_SUCCESS:
    return AUTHENTICATION_SESSION_POST_REQUEST_KEY;

  case AUTHENTICATION_VERIFY_POST:
  case AUTHENTICATION_VERIFY_POST_FAILURE:
  case AUTHENTICATION_VERIFY_POST_SUCCESS:
    return AUTHENTICATION_VERIFY_POST_REQUEST_KEY;

  case AUTHENTICATION_PASSWORD_POST:
  case AUTHENTICATION_PASSWORD_POST_FAILURE:
  case AUTHENTICATION_PASSWORD_POST_SUCCESS:
    return AUTHENTICATION_PASSWORD_POST_REQUEST_KEY;

  case AUTHENTICATION_UPGRADE_POST:
  case AUTHENTICATION_UPGRADE_POST_FAILURE:
  case AUTHENTICATION_UPGRADE_POST_SUCCESS:
  case AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE:
  case AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS:
    return AUTHENTICATION_UPGRADE_POST_REQUEST_KEY;

  case BOOKING_MEET_GREET:
  case BOOKING_MEET_GREET_FAILURE:
  case BOOKING_MEET_GREET_SUCCESS:
    return BOOKING_MEET_GREET_REQUEST_KEY;

  case BOOKING_OVERNIGHT:
  case BOOKING_OVERNIGHT_FAILURE:
  case BOOKING_OVERNIGHT_SUCCESS:
    return BOOKING_OVERNIGHT_REQUEST_KEY;

  case BOOKING_SET_SCHEDULE:
  case BOOKING_SET_SCHEDULE_FAILURE:
  case BOOKING_SET_SCHEDULE_SUCCESS:
    return BOOKING_SET_SCHEDULE_REQUEST_KEY;

  case BOOKING_CANCEL_SCHEDULE:
  case BOOKING_CANCEL_SCHEDULE_FAILURE:
  case BOOKING_CANCEL_SCHEDULE_SUCCESS:
    return BOOKING_CANCEL_SCHEDULE_REQUEST_KEY;

  case BOOKING_EDIT_SCHEDULE:
  case BOOKING_EDIT_SCHEDULE_FAILURE:
  case BOOKING_EDIT_SCHEDULE_SUCCESS:
    return BOOKING_EDIT_SCHEDULE_REQUEST_KEY;

  case CREDIT_CARD_UPDATE:
  case CREDIT_CARD_UPDATE_FAILURE:
  case CREDIT_CARD_UPDATE_SUCCESS:
    return CREDIT_CARD_UPDATE_REQUEST_KEY;

  case DOG_BREED_ASSETS_GET:
  case DOG_BREED_ASSETS_GET_FAILURE:
  case DOG_BREED_ASSETS_GET_SUCCESS:
    return DOG_BREED_ASSETS_REQUEST_KEY;

  case DOG_BREEDS_GET:
  case DOG_BREEDS_GET_FAILURE:
  case DOG_BREEDS_GET_SUCCESS:
    return DOG_GET_BREEDS_REQUEST_KEY;

  case DOG_CREATE:
  case DOG_CREATE_FAILURE:
  case DOG_CREATE_SUCCESS:
    return DOG_CREATE_REQUEST_KEY;

  case DOG_DELETE:
  case DOG_DELETE_FAILURE:
  case DOG_DELETE_SUCCESS:
    return DOG_DELETE_REQUEST_KEY;

  case DOG_PROFILE_PATCH:
  case DOG_PROFILE_PATCH_FAILURE:
  case DOG_PROFILE_PATCH_SUCCESS:
    return DOG_PROFILE_PATCH_REQUEST_KEY;

  case DOG_UPDATE_V4:
  case DOG_UPDATE_V4_FAILURE:
  case DOG_UPDATE_V4_SUCCESS:
    return DOG_UPDATE_V4_REQUEST_KEY;

  case DOG_UPDATE_QUESTIONAIRE:
  case DOG_UPDATE_QUESTIONAIRE_FAILURE:
  case DOG_UPDATE_QUESTIONAIRE_SUCCESS:
    return DOG_UPDATE_QUESTIONAIRE_REQUEST_KEY;

  case DOGS_GET:
  case DOGS_GET_FAILURE:
  case DOGS_GET_SUCCESS:
    return GET_DOGS_REQUEST_KEY;

  case ESTIMATE_OVERNIGHT:
  case ESTIMATE_OVERNIGHT_FAILURE:
  case ESTIMATE_OVERNIGHT_SUCCESS:
    return ESTIMATE_OVERNIGHT_REQUEST_KEY;

  case LOGIN_WITH_CODE_REQUEST_CREATE:
  case LOGIN_WITH_CODE_REQUEST_CREATE_FAILURE:
  case LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS:
    return LOGIN_WITH_CODE_REQUEST_CREATE_REQUEST_KEY;

  case LOGIN_WITH_CODE_SUBMIT:
  case LOGIN_WITH_CODE_SUBMIT_FAILURE:
  case LOGIN_WITH_CODE_SUBMIT_SUCCESS:
    return LOGIN_WITH_CODE_SUBMIT_REQUEST_KEY;

  case MY_PROFILE_UPDATE:
  case MY_PROFILE_UPDATE_FAILURE:
  case MY_PROFILE_UPDATE_SUCCESS:
    return MY_PROFILE_UPDATE_REQUEST_REQUEST_KEY;

  case OWNER_APPLY_DETAILS_REGISTER:
  case OWNER_APPLY_DETAILS_REGISTER_FAILURE:
  case OWNER_APPLY_DETAILS_REGISTER_SUCCESS:
    return OWNER_APPLY_DETAILS_REGISTER_REQUEST_KEY;

  case OWNER_CREDIT_GET:
  case OWNER_CREDIT_GET_FAILURE:
  case OWNER_CREDIT_GET_SUCCESS:
    return OWNER_CREDIT_REQUEST_KEY;

  case OWNER_GOOGLE_SIGNIN_REGISTER:
  case OWNER_GOOGLE_SIGNIN_REGISTER_FAILURE:
  case OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS:
    return OWNER_GOOGLE_SIGNIN_REGISTER_REQUEST_KEY;

  case OWNER_GET:
  case OWNER_GET_FAILURE:
  case OWNER_GET_SUCCESS:
    return OWNER_GET_REQUEST_KEY;

  case OWNER_HOME_ACCESS_GET:
  case OWNER_HOME_ACCESS_GET_FAILURE:
  case OWNER_HOME_ACCESS_GET_SUCCESS:
    return OWNER_HOME_ACCESS_GET_REQUEST_KEY;

  case OWNER_HOME_ACCESS_UPDATE:
  case OWNER_HOME_ACCESS_UPDATE_FAILURE:
  case OWNER_HOME_ACCESS_UPDATE_SUCCESS:
    return OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY;

  case OWNER_LOGIN:
  case OWNER_LOGIN_FAILURE:
  case OWNER_LOGIN_SUCCESS:
    return OWNER_TOKEN_JWT_REQUEST_KEY;

  case OWNER_MASKED_INFO_GET:
  case OWNER_MASKED_INFO_GET_FAILURE:
  case OWNER_MASKED_INFO_GET_SUCCESS:
    return OWNER_MASKED_INFO_GET_REQUEST_KEY;

  case OWNER_PASSWORD_CHANGE:
  case OWNER_PASSWORD_CHANGE_FAILURE:
  case OWNER_PASSWORD_CHANGE_SUCCESS:
    return OWNER_PASSWORD_CHANGE_REQUEST_KEY;

  case OWNER_PASSWORD_RESET_EMAIL_SEND:
  case OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE:
  case OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS:
    return PASSWORD_RESET_EMAIL_REQUEST_KEY;

  case OWNER_PASSWORD_RESET:
  case OWNER_PASSWORD_RESET_FAILURE:
  case OWNER_PASSWORD_RESET_SUCCESS:
    return OWNER_PASSWORD_RESET_REQUEST_KEY;

  case OWNER_PAYMENT_METHOD_CREATE:
  case OWNER_PAYMENT_METHOD_CREATE_FAILURE:
  case OWNER_PAYMENT_METHOD_CREATE_SUCCESS:
    return OWNER_PAYMENT_METHOD_CREATE_REQUEST_KEY;

  case OWNER_PAYMENT_METHOD_UPDATE:
  case OWNER_PAYMENT_METHOD_UPDATE_FAILURE:
  case OWNER_PAYMENT_METHOD_UPDATE_SUCCESS:
    return OWNER_PAYMENT_METHOD_UPDATE_REQUEST_KEY;

  case OWNER_PAYMENT_METHODS_GET:
  case OWNER_PAYMENT_METHODS_GET_FAILURE:
  case OWNER_PAYMENT_METHODS_GET_SUCCESS:
    return OWNER_PAYMENT_METHODS_GET_REQUEST_KEY;

  case OWNER_PREFERRED_WALKER_CREATE:
  case OWNER_PREFERRED_WALKER_CREATE_FAILURE:
  case OWNER_PREFERRED_WALKER_CREATE_SUCCESS:
    return OWNER_PREFERRED_WALKER_CREATE_REQUEST_KEY;

  case OWNER_PREFERRED_WALKER_DELETE:
  case OWNER_PREFERRED_WALKER_DELETE_FAILURE:
  case OWNER_PREFERRED_WALKER_DELETE_SUCCESS:
    return OWNER_PREFERRED_WALKER_DELETE_REQUEST_KEY;

  case OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE:
  case OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_FAILURE:
  case OWNER_ADD_PREFERRED_WALKER_BY_PROMO_CODE_SUCCESS:
    return OWNER_PREFERRED_WALKER_ADD_BY_PROMO_CODE_REQUEST_KEY;

  case OWNER_PREMIUM_SUBSCRIPTION_GET:
  case OWNER_PREMIUM_SUBSCRIPTION_GET_FAILURE:
  case OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS:
    return OWNER_PREMIUM_SUBSCRIPTION_GET_REQUEST_KEY;

  case OWNER_REGISTRATION_ADDRESS:
  case OWNER_REGISTRATION_ADDRESS_FAILURE:
  case OWNER_REGISTRATION_ADDRESS_SUCCESS:
    return OWNER_REGISTRATION_ADDRESS_REQUEST_KEY;

  case OWNER_REGISTRATION_BASIC_INFO:
  case OWNER_REGISTRATION_BASIC_INFO_FAILURE:
  case OWNER_REGISTRATION_BASIC_INFO_SUCCESS:
  case OWNER_REGISTRATION_BASIC_INFO_V2:
  case OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE:
  case OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS:
    return OWNER_REGISTRATION_BASIC_INFO_REQUEST_KEY;

  case OWNER_REGISTRATION_PREFERRED_SERVICES:
  case OWNER_REGISTRATION_PREFERRED_SERVICES_FAILURE:
  case OWNER_REGISTRATION_PREFERRED_SERVICES_SUCCESS:
    return OWNER_REGISTRATION_PREFERRED_SERVICES_REQUEST_KEY;

  case OWNER_REGISTRATION_REQUEST_LOCKBOX:
  case OWNER_REGISTRATION_REQUEST_LOCKBOX_FAILURE:
  case OWNER_REGISTRATION_REQUEST_LOCKBOX_SUCCESS:
    return OWNER_REGISTRATION_REQUEST_LOCKBOX_REQUEST_KEY;

  case OWNER_SERVICES_GET:
  case OWNER_SERVICES_GET_FAILURE:
  case OWNER_SERVICES_GET_SUCCESS:
    return OWNER_SERVICES_GET_REQUEST_KEY;

  case OWNER_SMART_MODULES:
  case OWNER_SMART_MODULES_FAILURE:
  case OWNER_SMART_MODULES_SUCCESS:
    return OWNER_SMART_MODULES_REQUEST_KEY;

  case OWNER_AVAILABLE_SERVICES:
  case OWNER_AVAILABLE_SERVICES_FAILURE:
  case OWNER_AVAILABLE_SERVICES_SUCCESS:
    return OWNER_AVAILABLE_SERVICES_REQUEST_KEY;

  case OWNER_UPDATE:
  case OWNER_UPDATE_FAILURE:
  case OWNER_UPDATE_SUCCESS:
    return OWNER_UPDATE_REQUEST_KEY;

  case PASSWORD_TIPS:
  case PASSWORD_TIPS_FAILURE:
  case PASSWORD_TIPS_SUCCESS:
    return PASSWORD_TIPS_REQUEST_KEY;

  case PAST_PET_CAREGIVERS_GET:
  case PAST_PET_CAREGIVERS_GET_FAILURE:
  case PAST_PET_CAREGIVERS_GET_SUCCESS:
    return PAST_PET_CAREGIVERS_GET_REQUEST_KEY;

  case PREFERRED_WALKER_UPDATE:
  case PREFERRED_WALKER_UPDATE_FAILURE:
  case PREFERRED_WALKER_UPDATE_SUCCESS:
    return PREFERRED_WALKER_REQUEST_KEY;

  case PROMO_CODE_APPLY:
  case PROMO_CODE_APPLY_FAILURE:
  case PROMO_CODE_APPLY_SUCCESS:
    return PROMO_CODE_REQUEST_KEY;

  case REBOOKING:
  case REBOOKING_FAILURE:
  case REBOOKING_SUCCESS:
    return REBOOKING_REQUEST_KEY;

  case SERVICEABLE_ZIP_CODE_CHECK:
  case SERVICEABLE_ZIP_CODE_CHECK_FAILURE:
  case SERVICEABLE_ZIP_CODE_CHECK_SUCCESS:
    return SERVICEABLE_ZIP_CODE_CHECK_REQUEST_KEY;

  case SERVICE_PROVIDERS_GET:
  case SERVICE_PROVIDERS_GET_FAILURE:
  case SERVICE_PROVIDERS_GET_SUCCESS:
    return SERVICE_PROVIDERS_GET_REQUEST_KEY;

  case SITTING_QUESTIONNAIRE_GET:
  case SITTING_QUESTIONNAIRE_GET_FAILURE:
  case SITTING_QUESTIONNAIRE_GET_SUCCESS:
    return SITTING_QUESTIONNAIRE_REQUEST_KEY;

  case SUBSCRIBE_TO_PREMIUM:
  case SUBSCRIBE_TO_PREMIUM_FAILURE:
  case SUBSCRIBE_TO_PREMIUM_SUCCESS:
    return SUBSCRIBE_TO_PREMIUM_REQUEST_KEY;

  case TRAINING_BOOKING:
  case TRAINING_BOOKING_FAILURE:
  case TRAINING_BOOKING_SUCCESS:
    return TRAINING_BOOKING_REQUEST_KEY;

  case UPDATE_WALKER_PASSWORD:
  case UPDATE_WALKER_PASSWORD_FAILURE:
  case UPDATE_WALKER_PASSWORD_SUCCESS:
    return UPDATE_WALKER_PASSWORD_REQUEST_KEY;

  case VERIFY_PASSWORD:
  case VERIFY_PASSWORD_FAILURE:
  case VERIFY_PASSWORD_SUCCESS:
    return VERIFY_PASSWORD_REQUEST_KEY;

  case VISITOR_ACCOUNT_CREATE:
  case VISITOR_ACCOUNT_CREATE_FAILURE:
  case VISITOR_ACCOUNT_CREATE_SUCCESS:
    return VISITOR_ACCOUNT_CREATE_REQUEST_KEY;

  case WALKER_PROFILE_BY_PROMO_CODE_GET:
  case WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE:
  case WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS:
    return WALKER_PROFILE_BY_PROMO_CODE_GET_REQUEST_KEY;

  case WALK_COVER_CREATE:
  case WALK_COVER_CREATE_FAILURE:
  case WALK_COVER_CREATE_SUCCESS:
    return WALK_COVER_CREATE_REQUEST_KEY;

  case WALK_DETAILS_GET:
  case WALK_DETAILS_GET_FAILURE:
  case WALK_DETAILS_GET_SUCCESS:
    return WALK_DETAILS_GET_REQUEST_KEY;

  case WALK_SCHEDULE_UPDATE:
  case WALK_SCHEDULE_UPDATE_FAILURE:
  case WALK_SCHEDULE_UPDATE_SUCCESS:
    return WALK_SCHEDULE_UPDATE_REQUEST_KEY;

  case WALKER_PASSWORD_RESET:
  case WALKER_PASSWORD_RESET_FAILURE:
  case WALKER_PASSWORD_RESET_SUCCESS:
    return WALKER_PASSWORD_RESET_REQUEST_KEY;

  case WALKER_GET:
  case WALKER_GET_FAILURE:
  case WALKER_GET_SUCCESS:
    return WALKER_GET_REQUEST_KEY;

  case WALKER_CONTACT_INFO_GET:
  case WALKER_CONTACT_INFO_FAILURE:
  case WALKER_CONTACT_INFO_SUCCESS:
    return WALKER_CONTACT_INFO_REQUEST_KEY;

  case WALKER_PROFILE_GET:
  case WALKER_PROFILE_GET_FAILURE:
  case WALKER_PROFILE_GET_SUCCESS:
    return WALKER_PROFILE_REQUEST_KEY;

  case WALKER_SIGN_IN:
  case WALKER_SIGN_IN_FAILURE:
  case WALKER_SIGN_IN_SUCCESS:
    return WALKER_SIGN_IN_REQUEST_KEY;

  case WALKER_SIGN_UP:
  case WALKER_SIGN_UP_FAILURE:
  case WALKER_SIGN_UP_SUCCESS:
    return WALKER_SIGN_UP_REQUEST_KEY;

  case WALKER_STRIPE_CONNECT_GET_REDIRECT_URL:
  case WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE:
  case WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS:
    return WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_REQUEST_KEY;

  case WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL:
  case WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE:
  case WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS:
    return WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_REQUEST_KEY;

  case WALKER_STRIPE_CONNECT_VERIFY_MIGRATION:
  case WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE:
  case WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS:
    return WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_REQUEST_KEY;

  case WALKER_STRIPE_CONNECT_VERIFY_TOKEN:
  case WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE:
  case WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS:
    return WALKER_STRIPE_CONNECT_VERIFY_REQUEST_KEY;

  case WALKER_SCHEDULE_GET:
  case WALKER_SCHEDULE_GET_FAILURE:
  case WALKER_SCHEDULE_GET_SUCCESS:
    return WALKER_WALK_GET_REQUEST_KEY;

  case WALKS_IN_PENDING_REVIEW_GET:
  case WALKS_IN_PENDING_REVIEW_GET_FAILURE:
  case WALKS_IN_PENDING_REVIEW_GET_SUCCESS:
    return WALKS_IN_PAST_REQUEST_KEY;

  case WALKS_IN_PROGRESS_GET:
  case WALKS_IN_PROGRESS_GET_FAILURE:
  case WALKS_IN_PROGRESS_GET_SUCCESS:
    return WALKS_IN_PROGRESS_REQUEST_KEY;

  case WALKS_IN_THE_PAST_GET:
  case WALKS_IN_THE_PAST_GET_FAILURE:
  case WALKS_IN_THE_PAST_GET_SUCCESS:
    return WALKS_PENDING_REVIEW_REQUEST_KEY;

  case WALKS_UPCOMING_GET:
  case WALKS_UPCOMING_GET_FAILURE:
  case WALKS_UPCOMING_GET_SUCCESS:
    return WALKS_UPCOMING_REQUEST_KEY;

  default:
    return undefined;
  }
};
export default getRequestKeyForAction;
