import { handleActions } from 'redux-actions';

import { BOOKING_EDIT_SCHEDULE_SUCCESS } from '../../actions/actionTypes';
import type { BookingEditScheduleSuccessAction } from '../../actions/booking/bookingEditSchedule';

export type BookingScheduleState = {};
export const initialState = {};
export const reducer: any = handleActions<BookingScheduleState, Record<string, any>>({
  [BOOKING_EDIT_SCHEDULE_SUCCESS]: (state: BookingScheduleState, {
    payload,
  }: BookingEditScheduleSuccessAction) => ({ ...state,
    ...payload }),
}, initialState);
export default reducer;
