import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { BaseTheme } from "@waglabs/webapp-theme";
export function useStyledTheme() {
  const theme = useContext(ThemeContext);
  const themeName = theme && theme.name;
  const isPartnerMode = BaseTheme.name !== themeName;
  return {
    isPartnerMode,
    theme
  };
}