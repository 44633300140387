import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';
import {
  TRAINING_BOOKING,
  TRAINING_BOOKING_SUCCESS,
  TRAINING_BOOKING_FAILURE,
} from '../../actionTypes';

/**
 * Booking training
 */
export type BookingTrainingParams = {
  selectedPets: webApp.V6.Pet[];
  times: string[],
  notes: string,
  usePreferred: boolean,
  // TODO - determine what is this?
  behaviours: number[]
};

export type BookingTrainingPayload = BookingTrainingParams;

export type BookingTrainingAction = {
  type: typeof TRAINING_BOOKING,
  payload: BookingTrainingPayload,
};

export const bookingTraining: (
  params: BookingTrainingParams,
) => BookingTrainingAction = createAction(
  TRAINING_BOOKING,
);

/**
 * Booking training success action
 */
export type BookingTrainingSuccessAction = {
  type: typeof TRAINING_BOOKING_SUCCESS,
};

export const bookingTrainingSuccess: (
  response: webApp.BookingTrainingParsedResponse
) => BookingTrainingSuccessAction = createAction(
  TRAINING_BOOKING_SUCCESS,
);

/**
 * Booking training failure action
 */
export type BookingTrainingFailureAction = {
  type: typeof TRAINING_BOOKING_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const bookingTrainingFailure: (
  error: webApp.ErrorParsedResponse,
) => BookingTrainingFailureAction = createAction(
  TRAINING_BOOKING_FAILURE,
);
