import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';
import type { PostAuthenticationUpgradeRequestPayload } from 'wagAPI';

import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import { AjaxResponse } from 'rxjs/ajax';

import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  postAuthenticationUpgradeResponseParser,
} from '../../../helpers/Http/ResponseParsers';

import { AUTHENTICATION_UPGRADE_POST } from '../../../actions/actionTypes';
import {
  postAuthenticationUpgradeSuccess,
  postAuthenticationUpgradeFailure,
  postAuthenticationUpgradeWalkerSuccess,
  postAuthenticationUpgradeWalkerFailure,
  PostAuthenticationUpgradeAction,
} from '../../../actions/auth/postAuthenticationUpgrade';

import {
  getOwnerID,
  getLegacyOwnerToken,
} from '../../../selectors';

import {
  ENDPOINT_AUTHENTICATION_UPGRADE_POST,
  Endpoint,
} from '../../../constants/endpoints';
import {
  Auth,
  DeviceType,
} from '../../../constants/app';

import { tryCatchSync } from '../../../utils';

const postAuthenticationUpgrade$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(
  ofType(AUTHENTICATION_UPGRADE_POST),
  retry(3),
  switchMap(({
    payload: {
      id,
      token,
      deviceType,
      userRoleType,
    } = {
      id: Number(getOwnerID(state$.value)),
      token: getLegacyOwnerToken(state$.value) || '',
      deviceType: DeviceType.Web,
      userRoleType: Auth.RoleType.Owner
    },
  }: PostAuthenticationUpgradeAction) => post$(({
    endpoint: ENDPOINT_AUTHENTICATION_UPGRADE_POST,
    payload: {
      id: id || Number(getOwnerID(state$.value)),
      token: token || getLegacyOwnerToken(state$.value),
      deviceType: deviceType || DeviceType.Web,
      userRoleType: userRoleType || Auth.RoleType.Owner
    },
    token: ''
  } as {
    endpoint: Endpoint;
    payload: PostAuthenticationUpgradeRequestPayload;
    token: string;
  })).pipe(
    flatMap((response: AjaxResponse) => {
      const [payload,] = tryCatchSync(() => JSON.parse(response.request.body));

      if (payload.userRoleType === Auth.RoleType.Walker) {
        return handleResponse({
          response,
          onSuccess: postAuthenticationUpgradeWalkerSuccess,
          onFailure: postAuthenticationUpgradeWalkerFailure,
          parser: postAuthenticationUpgradeResponseParser
        });
      }

      return handleResponse({
        response,
        onSuccess: postAuthenticationUpgradeSuccess,
        onFailure: postAuthenticationUpgradeFailure,
        parser: postAuthenticationUpgradeResponseParser
      });
    }),
    catchError((error: any) => {
      const [payload,] = tryCatchSync(() => JSON.parse(error.request.body));
  
      if (payload.userRoleType === Auth.RoleType.Walker) {
        return handleErrorV5Response(action$, postAuthenticationUpgradeWalkerFailure);
      }
      return handleErrorV5Response(action$, postAuthenticationUpgradeFailure);
    }))
  )
);

export default postAuthenticationUpgrade$;