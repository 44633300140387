import {
  ROUTE_OVERNIGHT,
  ROUTE_OVERNIGHT_BOARDING,
  ROUTE_OVERNIGHT_SITTING,
  ROUTE_SELECT_WALK_DURATION,
  ROUTE_WALK_BOOKING_DROPIN_DURATION,
  ROUTE_WALK_BOOKING_RECURRING,
} from './routes';

export const URL_CHROME_DOWNLOAD = 'https://www.google.com/chrome/';
export const URL_FIREFOX_DOWNLOAD = 'https://www.mozilla.org/en-US/firefox/';
export const GOOGLE_DOWNLOAD_URL = 'https://wagwalking.app.link/Jtodorrlp7';
export const APPLE_DOWNLOAD_URL = 'https://wagwalking.app.link/Yhn7cdnlp7';
export const WAGWALKING_URL = process.env.REACT_APP_WAG_CONTENT_URL || 'https://wagwalking.com';
export const SIGNUP_URL = '/signup';
export const HELP_URL = 'https://help.wagwalking.com/';
export const HELP_OWNERS_URL = 'https://help.wagwalking.com/category/dog-owners';
export const SAFETY_BASE_URL = process.env.REACT_APP_SAFETY_BASE_URL || 'https://safety.wagwalking.com';
export const WAGWEBAPP_VERSION = '/asset-manifest.json';
export const ROUTE_TERMS = (plain?: boolean) => `https://wagwalking.com/terms?${plain ? 'plain=1' : ''}`;
export const ROUTE_ARBITRATION = (plain?: boolean) => `https://wagwalking.com/terms?${plain ? 'plain=1' : ''}&source=arbitration`;
export const ROUTE_PRIVACY = (plain?: boolean) => `https://wagwalking.com/privacy?${plain ? 'plain=1' : ''}`;
export const ROUTE_MOBILE_TERMS = (plain?: boolean) => `https://wagwalking.com/terms/owner-mobile?${plain ? 'plain=1' : ''}`;
export const QUERY_STRING_BOOKING_FLOW = 'booking=1';
export const URL_WAG_HOME = `${WAGWALKING_URL}/`;
export const TRAINING_BRANCH_LINK = process.env.REACT_APP_TRAINING_BRANCH_LINK || APPLE_DOWNLOAD_URL;
export const APP_MOBILE_TERMS_URL = `${SAFETY_BASE_URL}/terms-pet-parent-mobile`;
export const APP_PRIVACY_URL = `${SAFETY_BASE_URL}/privacy`;
export const APP_TERMS_URL = `${SAFETY_BASE_URL}/terms/app`;
export const WALKER_DOWNLOAD_LOGIN_PORTAL = 'http://walkerdownload.wagwalking.com';
export const DIGITAL_TRAINING_GET_THE_APP_MODAL = 'https://wagwalking.app.link/BDDSeBpQ5ab';
export const WALKER_LOGIN_URL = 'https://wagwalker.app.link/X6MwsgySxeb';
export const WALKER_ONBOARDING_AGREEMENT_PDF_URL = 'https://assets.wagwalking.com/walker-agreements/latest.pdf';
export const WagWalkingRedirects: {
  [key: string]: string;
} = {
  [ROUTE_SELECT_WALK_DURATION]: 'https://wagwalking.com/dog-walking',
  [ROUTE_WALK_BOOKING_RECURRING]: 'https://wagwalking.com/dog-walking',
  [ROUTE_WALK_BOOKING_DROPIN_DURATION]: 'https://wagwalking.com/drop-ins',
  [ROUTE_OVERNIGHT]: 'https://wagwalking.com/dog-boarding',
  [ROUTE_OVERNIGHT_BOARDING]: 'https://wagwalking.com/dog-boarding',
  [ROUTE_OVERNIGHT_SITTING]: 'https://wagwalking.com/dog-sitting',
};

export const MULI_FONT_S3_URL = 'https://assets.wagwalkingweb.com/fonts/muli-v16-latin-regular.woff2';
