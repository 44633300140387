import { BaseTheme } from '@waglabs/webapp-theme';

import { asyncComponent } from '../components/AsyncComponent';

/**
 * This configObj will be used to toggle specific functionality between
 * both the Petco and Wag environments. If there are any more environments to add
 * in the future, please add them as siblings to the 'petco' and 'wag' keys, and ensure
 * that the new key is a value that will be used in that environment's
 * "REACT_APP_PROJECT_ENV" var. Likewise, please add documentation on any new config
 * properties that are added.
 *
 *
 * Config properties:
 * - theme: The theme we will be using in App.js
 * - isPartnerNavigationEnabled: Determines if app navigation will be handled via the
 *                               Hamburger menu or embedded navigation as well as styling
 *                               differences (e.g. back arrows vs cancel buttons)
 * - isPartnerAuthEnabled: Determines if we will handle Welcome.js functionality
 *                         with the help of Petco's API, or if we will handle Welcome.js
 *                         natively without needing to hit Petco's API
 */
const configObj = {
  wag: {
    theme: BaseTheme,
    isPartnerAuthEnabled: false,
    isPartnerNavigationEnabled: false,
    signupComponent: asyncComponent(() => import('../containers/WagSignUpV2')),
  },
};
export const getConfig = (env = 'wag') => configObj[env];

/**
 * To be imported by components that need access to the configuration in order to determine
 * what functionality to allow for.
 *
 * Ex:
 * import { config } from '../src/config';
 *
 * <ThemeProvider theme={config.theme} />
 */
export const config = getConfig(process.env.REACT_APP_PROJECT_ENV);
