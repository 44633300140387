import moment from 'moment';
import * as Yup from 'yup';

export const birthdaySchemaMinDate = moment('1970-01-01', 'YYYY-MM-DD').toDate();
export const birthdaySchema = Yup.date()
  .required()
  .min(birthdaySchemaMinDate, 'Selected birthday must be later than 1970-01-01')
  .test('future-date', 'Selected birthday can\'t be in the future', (value) => {
    const isFutureDate = moment(value).isAfter(moment(), 'day');
    // false = if same day (error)
    // true = if not same day (success)
    return !isFutureDate;
  })
  .test('same-day', 'Selected birthday can\'t be today', (value) => {
    const isSameDay = moment(value).isSame(moment(), 'day');
    // false = if same day (error)
    // true = if not same day (success)
    return !isSameDay;
  })
  .typeError('Birthday must be formatted as mm/dd/yyyy')
  .label('Birthday');
