import { createAction } from "redux-actions";
import { RESET_REQUEST, PROMO_CODE_RESET } from "../actionTypes";
import type { RequestKey } from "../../constants/requestKeys";

/**
 * Reset request
 */
export type ResetRequestPayload = {
  requestKey: RequestKey;
};
export type ResetRequestAction = {
  type: typeof RESET_REQUEST;
  payload: ResetRequestPayload;
};
export type ResetPromoCodeAction = {
  type: typeof PROMO_CODE_RESET;
};
export const resetRequest: (arg0: RequestKey) => ResetPromoCodeAction = createAction(RESET_REQUEST, requestKey => ({
  requestKey
}));
export const resetPromoCode: any = () => createAction(PROMO_CODE_RESET);
export default resetRequest;