export type StripeApplePayResult = {
  shippingContact: stripe.StripeApplePayPaymentContact;
  token: stripe.StripeCardTokenResponse;
};

export const stripeApplePayBuildSession = (
  paymentRequest: Omit<stripe.StripeApplePayPaymentRequest, 'billingContact' | 'total'>,
) => new Promise<StripeApplePayResult>((resolve, reject) => {
  const session = Stripe.applePay.buildSession(
    paymentRequest as stripe.StripeApplePayPaymentRequest,
    async (result, completion) => {
      const {
        token,
        shippingContact,
      } = result as StripeApplePayResult;

      completion(ApplePaySession.STATUS_SUCCESS);

      resolve({
        shippingContact,
        token,
      });
    },
    (error) => reject(error),
  );

  session.begin();
});
