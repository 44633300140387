import { createContext } from 'react';

export type ServiceCostDetail = {
  label: string;
  amount: number;
};

export type PaymentContextType = {
  onPremiumSubscribe: () => void;
  serviceCostDetails: ServiceCostDetail[];
};

export const PaymentContext = createContext<PaymentContextType>({
  onPremiumSubscribe: () => undefined,
  serviceCostDetails: [],
});
