import React, {
  Node,
  ComponentType,
} from 'react';
import {
  Flex,
  FlexProps
} from '@waglabs/flex-next';
import Text from '@waglabs/text';
import Icon from '@waglabs/icon';
import NavLink from '@waglabs/nav-link';

type Props = FlexProps & {
  iconName: string,
  renderIcon?: () => Node,
  href?: string,
  label: string,
  onClick?: () => null,
  Component?: ComponentType<any>;
};

export const SidebarNavigationItem = ({
  iconName,
  renderIcon,
  href,
  label,
  onClick,
  Component = NavLink,
  ...rest
}: Props) => (
  <Component
    onClick={onClick}
    to={href} // NavLink
    href={href} // Link
    width={1}
  >
    <Flex
      data-href={href}
      alignItems="center"
      flexShrink="0"
      pl={[3]}
      width={1}
      height={50}
      hover
      {...rest}
    >
      {(renderIcon && renderIcon()) || (
        <Icon
          name={iconName}
          data-href={href}
          height={[20, 20, 30]}
          width={[20, 20, 30]}
          mr={[2, 2, 3]}
          hover
          alt={iconName}
        />
      )}
      <Text
        color="wagGray"
        data-href={href}
        m={0}
        fontSize={[4, 4, 6]}
        hoverColor="#727272" // FIXME: Update styled navlink to accept color names
        hover
      >
        {label}
      </Text>
    </Flex>
  </Component>
);

SidebarNavigationItem.defaultProps = {
  href: '',
  onClick: () => null,
};
