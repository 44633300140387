import {
  EndpointSocialAuthentication,
  SocialAuthErrors,
} from '../../constants';
import { wagApi } from '../../types/wagapi';
import {
  buildApiErrorPipeline,
  mapErrorByType,
  mapErrorToApiSlice,
  unwrapSliceError,
  createErrorForV6,
} from '../../utils/buildApiErrorPipeline';
import { tryCatch } from '../../utils/tryCatch';
import { apiSlice } from '../apiSlice';

export const socialAuthApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postFacebookSocialAuthentication: builder.mutation<
        wagApi.PostSocialAuthenticationResponse,
        wagApi.PostSocialAuthenticationPayload
      >({
        queryFn: async (body, _api, _extraOptions, query) => {
          const adjustedArgs = {
            url: EndpointSocialAuthentication.Facebook,
            method: 'POST',
            body,
          };
          const [response] = await tryCatch(() => query(adjustedArgs));

          if (response.error) {
            return buildApiErrorPipeline(response.error)
              .pipe(
                unwrapSliceError,
                createErrorForV6,
                mapErrorByType(SocialAuthErrors.LoginWithSocialFlow),
                mapErrorToApiSlice,
              );
          }
          return response;
        },
      }),

    postAppleSocialAuthentication: builder.mutation<
      wagApi.PostSocialAuthenticationResponse,
      wagApi.PostSocialAuthenticationPayload
    >({
      queryFn: async (body, _api, _extraOptions, query) => {
        const adjustedArgs = {
          url: EndpointSocialAuthentication.Apple,
          method: 'POST',
          body,
        };

        const [response] = await tryCatch(() => query(adjustedArgs));

        if (response.error) {
          return buildApiErrorPipeline(response.error)
            .pipe(
              unwrapSliceError,
              createErrorForV6,
              mapErrorByType(SocialAuthErrors.LoginWithSocialFlow),
              mapErrorToApiSlice,
            );
        }
        return response;
      },
    }),

    postGoogleSocialAuthentication: builder.mutation<
      wagApi.PostSocialAuthenticationResponse,
      wagApi.PostSocialAuthenticationPayload
    >({
      queryFn: async (body, _api, _extraOptions, query) => {
        const adjustedArgs = {
          url: EndpointSocialAuthentication.Google,
          method: 'POST',
          body,
        };

        const [response] = await tryCatch(() => query(adjustedArgs));

        if (response.error) {
          return buildApiErrorPipeline(response.error)
            .pipe(
              unwrapSliceError,
              createErrorForV6,
              mapErrorByType(SocialAuthErrors.LoginWithSocialFlow),
              mapErrorToApiSlice,
            );
        }
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostFacebookSocialAuthenticationMutation,
  usePostAppleSocialAuthenticationMutation,
  usePostGoogleSocialAuthenticationMutation,
} = socialAuthApi;
