import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

type Props = SvgIconProps;

export const DropInServiceIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 64 64"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 0h64v64H0z" />
      <path
        opacity=".201"
        d="M4 4h56v56H4z"
      />
      <g
        transform="translate(5 7)"
        fillRule="nonzero"
      >
        <ellipse
          fill="#DBED5C"
          cx="23.841"
          cy="22.549"
          rx="23.039"
          ry="22.549"
        />
        <path
          d="M12.22 18.105c-.365.99-.828 1.986-1.317 3.017-1.194 2.553-2.429 5.184-2.238 8.036.304 4.557 3.826 8.862 8.59 10.437a13.68 13.68 0 004.253.599c4.682 0 10.46-1.703 13.72-4.997 4.742-4.78 8.509-16.53 8.711-23.383a21.847 21.847 0 00-2.088-3.337c.599 6.138-2.91 20.179-8.055 25.363-4.13 4.16-12.302 5.108-15.906 3.91-3.944-1.319-6.99-4.98-7.23-8.706-.156-2.355.923-4.656 2.062-7.094.483-1.033.984-2.103 1.38-3.17 1.541-4.191 2.33-6.583 1.99-11.427-.08-1.43-.594-4.243-1.464-5.392-.65.244-1.287.513-1.906.814.824.905 1.314 3.5 1.371 4.711.315 4.444-.387 6.584-1.874 10.619z"
          fill="#A2C23F"
        />
        <path
          d="M42.213 34.38c1.04-1.395 2.514-3.492 3.602-4.107 1.329-.75 2.86-1.368 4.355-.25 1.176.865 2.308 2.643 1.867 4.07-.694 2.249-2.57 3.513-2.479 4.774.091 1.26 1.929 3.133 2.479 4.36.647 1.439 1.337 3.508-.218 4.756-1.555 1.25-3.37 1.393-4.89.612-1.038-.538-2.593-1.964-3.62-2.668-.35-.242-14.68-.496-15.168.018-.535.551-3.458 3.58-4.737 3.869-1.28.287-2.882.41-4.328-.919-1.447-1.328-1.747-3.51-1.176-4.19.57-.679 2.678-3.029 2.49-4.265-.188-1.237-1.911-1.987-2.9-3.554-.987-1.566-1.431-3.91-.458-5.012.973-1.102 3.108-2.072 4.655-1.613.823.248 3.102 2.834 3.852 3.724.55.652 16.542.576 16.674.395z"
          fill="#CE8E51"
        />
        <circle
          fill="#A36A3B"
          cx="42.469"
          cy="41.667"
          r="1.471"
        />
        <circle
          fill="#A36A3B"
          cx="34.626"
          cy="41.667"
          r="1.471"
        />
        <circle
          fill="#A36A3B"
          cx="27.763"
          cy="40.686"
          r="1.471"
        />
      </g>
    </g>
  </SvgIcon>
);
