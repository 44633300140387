import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { walkerSlice } from '../slices/walker';

export const useWalker = () => {
  const dispatch = useDispatch();

  const clear = () => {
    dispatch(walkerSlice.actions.clear());
  };

  return {
    clear: useCallback(clear, [dispatch]),
  };
};