export const Sidebar = {
  HelpBtn: 'navigation-help-btn',
  HomeBtn: 'navigation-home-btn',
  LogoutBtn: 'navigation-logout-btn',
  PastServicesBtn: 'navigation-past-services-btn',
  PaymentsBtn: 'navigation-payments-btn',
  PremiumBtn: 'navigation-item-premium-btn',
  UpcomingServicesBtn: 'navigation-upcoming-services-btn',
  WellnessBtn: 'navigation-wellness-btn',
} as const;
