import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { EstimateOvernightRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_ESTIMATE_OVERNIGHT, Endpoint } from "../../../constants/endpoints";
import { ESTIMATE_OVERNIGHT } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { estimateOvernightSuccess, estimateOvernightFailure, EstimateOvernightAction } from "../../../actions/booking/estimateOvernight";

const overnight$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(ESTIMATE_OVERNIGHT), retry(3), switchMap(({
  payload: {
    dogCount,
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    walkTypeId
  }
}: EstimateOvernightAction) => post$(({
  endpoint: ENDPOINT_ESTIMATE_OVERNIGHT,
  payload: {
    dog_count: dogCount,
    end_date: endDate,
    end_time: endTime,
    has_pickup_and_dropoff: hasPickupAndDropoff ? 1 : 0,
    id: getOwnerID(state$.value),
    start_date: startDate,
    start_time: startTime,
    walk_type_id: walkTypeId
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: EstimateOvernightRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: estimateOvernightSuccess,
  onFailure: estimateOvernightFailure
})), catchError(handleErrorV5Response(action$, estimateOvernightFailure)))));

export default overnight$;