import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type MessageIconProps = SvgIconProps;
export const MessageIcon = ({
  color = '#00BF8F',
  ...rest
}: MessageIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    color={color}
    {...rest}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <circle
        fill="#00BF8F"
        cx="20"
        cy="20"
        r="20"
      />
      <path
        d="M4 4h32v32H4z"
      />
      <g
        transform="translate(11 12)"
      >
        <g
          fill="#FFF"
        >
          <rect
            width="18"
            height="13"
            rx="4"
          />
          <path
            d="M9.857 16.57 12 13H6l2.143 3.57a1 1 0 0 0 1.714 0Z"
          />
        </g>
        <circle
          fill="#00BF8F"
          cx="4"
          cy="6"
          r="1"
        />
        <circle
          fill="#00BF8F"
          cx="9"
          cy="6"
          r="1"
        />
        <circle
          fill="#00BF8F"
          cx="14"
          cy="6"
          r="1"
        />
      </g>
    </g>
  </SvgIcon>
);
