import { switchMap, flatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { StripeConnectGetLoginLinkBody } from "wagAPI";
import type { Action$, Dependencies } from "redux-observable";
import { handleResponse } from "../../../helpers/Http/responseHandlers";
import { stripeConnectRedirectLinkResponseParser } from "../../../helpers/Http/ResponseParsers";
import { ENDPOINT_WALKER_STRIPE_CONNECT_GET_DASHBOARD_URL, Endpoint } from "../../../constants/endpoints";
import { WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL } from "../../../actions/actionTypes";
import { signUpWalkerStripeRedirectSuccess, signUpWalkerStripeRedirectFailure } from "../../../actions/walker/signUpWalker";

const makeStripeCallEpic$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL), switchMap(({
  payload: {
    id,
    token
  }
}) => get$(({
  endpoint: ENDPOINT_WALKER_STRIPE_CONNECT_GET_DASHBOARD_URL,
  payload: {
    id,
    token
  }
} as {
  endpoint: Endpoint;
  payload: StripeConnectGetLoginLinkBody;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: signUpWalkerStripeRedirectSuccess,
  onFailure: signUpWalkerStripeRedirectFailure,
  parser: stripeConnectRedirectLinkResponseParser
})))));

export default makeStripeCallEpic$;