import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const CalendarOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M21.14 23H3.1A1.1 1.1 0 012 21.9V3.86a1.1 1.1 0 011.1-1.1h2.42V2.1A1.1 1.1 0 016.62 1h1.32a1.1 1.1 0 011.1 1.1v.66h6.16V2.1A1.1 1.1 0 0116.3 1h1.32a1.1 1.1 0 011.1 1.1v.66h2.42a1.1 1.1 0 011.1 1.1V21.9a1.1 1.1 0 01-1.1 1.1zM3.1 3.64a.23.23 0 00-.22.22V21.9c0 .114.106.22.22.22h18.04a.23.23 0 00.22-.22V8.04H2.88v-.88h18.48v-3.3a.23.23 0 00-.22-.22h-2.42v.66a1.1 1.1 0 01-1.1 1.1H16.3a1.1 1.1 0 01-1.1-1.1v-.66H9.04v.66a1.1 1.1 0 01-1.1 1.1H6.62a1.1 1.1 0 01-1.1-1.1v-.66H3.1zm13.2-1.76a.218.218 0 00-.22.22v2.2c0 .123.097.22.22.22h1.32c.123 0 .22-.097.22-.22V2.1a.218.218 0 00-.22-.22H16.3zm-9.68 0a.218.218 0 00-.22.22v2.2c0 .123.097.22.22.22h1.32c.123 0 .22-.097.22-.22V2.1a.218.218 0 00-.22-.22H6.62zm8.193 17.653c-.255 0-.792-.326-2.693-1.514l-2.394 1.426a.438.438 0 01-.501-.035.45.45 0 01-.15-.476l.898-2.824-2.526-2.016a.44.44 0 01.273-.783h2.966l1.03-2.376c.14-.325.668-.325.809 0l1.03 2.376h2.93c.185 0 .352.115.413.29a.443.443 0 01-.14.493l-2.49 2.007.923 2.825a.43.43 0 01-.15.484c-.07.079-.131.123-.228.123zm-2.693-2.464c.08 0 .158.026.229.07.572.352 1.179.73 1.663 1.021l-.678-2.06a.44.44 0 01.141-.483l1.76-1.417h-1.971a.445.445 0 01-.405-.264l-.739-1.707-.74 1.707a.445.445 0 01-.404.264H8.978l1.778 1.417c.14.114.202.299.15.475l-.634 2.015 1.628-.968a.385.385 0 01.22-.07z"
      fill="#727272"
      fillRule="nonzero"
    />
  </SvgIcon>
);
