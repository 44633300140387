import { createAction } from "redux-actions";
import { NOTIFICATION_CREATE, NOTIFICATION_CLEAR, NOTIFICATIONS_CLEAR } from "../actionTypes";
import type { NotificationType } from "../../constants/app";
import type { StyledSystemProps } from 'webApp'

/**
 * Create notification
 */
export type CreateNotificationParams = {
  notificationStyleProps: StyledSystemProps;
  notificationType: NotificationType;
  timeout?: number;
  text: string;
};
export type CreateNotificationPayload = CreateNotificationParams;
export type CreateNotificationAction = {
  type: typeof NOTIFICATION_CREATE;
  payload: CreateNotificationPayload;
};
export const createNotification: (arg0: CreateNotificationParams) => CreateNotificationAction = createAction(NOTIFICATION_CREATE, ({
  notificationType,
  text,
  timeout,
  notificationStyleProps,
}) => ({
  notificationType,
  text,
  timeout,
  notificationStyleProps,
}));

/**
 * Clear notification
 */
export type ClearNotificationPayload = {
  notificationID: number;
};
export type ClearNotificationAction = {
  type: typeof NOTIFICATION_CLEAR;
  payload: ClearNotificationPayload;
};
// TODO Rename to clearNotification
export const clear: (arg0: number) => ClearNotificationAction = createAction(NOTIFICATION_CLEAR, notificationID => ({
  notificationID,
}));

/**
 * Clear all notifications
 */
export type clearAllNotificationsAction = {
  type: typeof NOTIFICATIONS_CLEAR;
};
// TODO Rename to clearAllNotifications
export const clearAll: () => clearAllNotificationsAction = createAction(NOTIFICATIONS_CLEAR);