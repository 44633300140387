
import { createAction } from 'redux-actions';
import type {
  GetSmartModulesParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_SMART_MODULES,
  OWNER_SMART_MODULES_FAILURE,
  OWNER_SMART_MODULES_SUCCESS,
} from '../../actionTypes';

/**
 * Get owner smart modules
 */
export type GetSmartModulesAction = {
  type: typeof OWNER_SMART_MODULES,
};

export const getSmartModules: () =>
  GetSmartModulesAction = createAction(
    OWNER_SMART_MODULES,
  );

/**
 * Get owner smart modules success
 */
export type GetSmartModulesSuccessAction = {
  type: typeof OWNER_SMART_MODULES_SUCCESS,
  payload: GetSmartModulesParsedResponse,
};

export const getSmartModulesSuccess: (
  GetSmartModulesParsedResponse,
) => GetSmartModulesSuccessAction = createAction(
  OWNER_SMART_MODULES_SUCCESS,
);

/**
 * Get owner smart modules failure
 */
export type GetSmartModulesFailureAction = {
  type: typeof OWNER_SMART_MODULES_FAILURE,
  payload: ErrorParsedResponse,
};

export const getSmartModulesFailure: (
  ErrorParsedResponse,
) => GetSmartModulesFailureAction = createAction(
  OWNER_SMART_MODULES_FAILURE,
);
