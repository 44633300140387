import type { ReduxState as State } from '../index';

import type { BookingFieldsState } from './bookingFields.reducer';

export const getBookingFields: (arg0: State) => BookingFieldsState = ({
  bookingFields: {
    startTime,
    startDate,
    recurringTimes,
    days,
    dogs,
    walkTypeId,
    pickUp,
  },
}) => ({
  startTime,
  startDate,
  recurringTimes,
  days,
  dogs,
  walkTypeId,
  pickUp,
});
