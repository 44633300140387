export const getWalkerProfileByPromoCodeResponseParser = ({
  profile: {
    link,
    name,
    rating,
    rating_count: ratingCount,
    services_count: servicesCount,
    catchphrase,
    picture: pictureUrl,
    thumbnail: thumbnailUrl,
    tags: ratingBadges,
    bio,
    video: videoUrl,
    services,
    fields,
    badges
  }
}: any) => ({
  link,
  name,
  rating,
  ratingCount,
  servicesCount,
  catchphrase,
  pictureUrl,
  thumbnailUrl,
  ratingBadges: ratingBadges.map(ratingBadge => ({ ...ratingBadge,
    imageUrl: ratingBadge.image
  })),
  bio,
  videoUrl,
  services: services.map(service => ({ ...service,
    imageUrl: service.image,
    isChecked: service.is_checked
  })),
  fields: fields.map(field => ({ ...field,
    tags: field.tags.filter(({
      is_checked: isChecked
    }) => isChecked).map(({
      label
    }) => label)
  })),
  achievements: badges.map(badge => ({ ...badge,
    assetLarge: badge.asset_large
  })).sort((badgeA, badgeB) => badgeB.weight - badgeA.weight)
});