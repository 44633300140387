
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalkerParsedResponse,
} from 'webApp';

import {
  WALKER_GET,
  WALKER_GET_SUCCESS,
  WALKER_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walker
 */
export type GetWalkerPayload = {
  id: string,
};

export type GetWalkerAction = {
  type: typeof WALKER_GET,
  payload: GetWalkerPayload,
};

export const getWalker: (string) => GetWalkerAction = createAction(
  WALKER_GET,
  id => ({ id }),
);


/**
 * Get walker success
 */
export type GetWalkerSuccessAction = {
  type: typeof WALKER_GET_SUCCESS,
  payload: GetWalkerParsedResponse,
};

export const getWalkerSuccess: (
  GetWalkerParsedResponse,
) => GetWalkerSuccessAction = createAction(
  WALKER_GET_SUCCESS,
);


/**
 * Get walker failure
 */
export type GetWalkerFailureAction = {
  type: typeof WALKER_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getWalkerFailure: (
  ErrorParsedResponse,
) => GetWalkerFailureAction = createAction(
  WALKER_GET_FAILURE,
);
