const AvatarPositions = {
  1: [
    {
      position: '50%',
      transform: 'translateX(-50%)',
      zIndex: 7,
    },
  ],
  2: [
    {
      position: '40%',
      transform: 'translateX(-40%)',
      zIndex: 7,
    },
    {
      position: '60%',
      transform: 'translateX(-60%)',
      zIndex: 6,

    },
  ],
  3: [
    {
      position: '35%',
      transform: 'translateX(-35%)',
      zIndex: 7,
    },
    {
      position: '50%',
      transform: 'translateX(-50%)',
      zIndex: 6,

    },
    {
      position: '65%',
      transform: 'translateX(-65%)',
      zIndex: 5,

    },
  ],
};

export default AvatarPositions;
