import React, {
  useState,
  useEffect,
} from 'react';

import { connect } from 'react-redux';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';

import type { HomeAccess } from 'webApp';

import Box from '@waglabs/box';
import { Button } from '@waglabs/button-next';
import { Form, TextAreaFormField } from '@waglabs/form';
import Flex from '@waglabs/flex';
import Icon from '@waglabs/icon';
import { Typography } from '@waglabs/typography';
import { TextArea } from '@waglabs/control';

import { NavigationWrapper } from '../NavigationPage/NavigationWrapper';
import { BodyContainer } from '../../components/BodyContainer';
import { Divider } from '../../components/Divider';
import { Sidebar } from '../Survey/components/Sidebar';
import { ConfirmModal } from './components/ConfirmModal';
import { AccessMethodDescriptions } from './AccessMethods';
import type { AccessInfoValue } from './AccessInfo';

import {
  ACCESS_METHOD_KEY_HIDDEN,
  ACCESS_METHOD_LOCKBOX,
  ACCESS_METHOD_KEY_WITH_DOORMAN,
  ACCESS_METHOD_WILL_BE_HOME,
  ACCESS_METHOD_OTHER,
} from '../../constants/app';
import {
  OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY,
} from '../../constants/requestKeys';

import { getOwnerHomeAccess } from '../../actions/owner/getOwnerHomeAccess';
import { updateOwnerHomeAccess } from '../../actions/owner/updateOwnerHomeAccess';
import { resetRequest } from '../../actions/requests';


import { getDefaultHomeAccess } from '../../reducers/owner/owner.selectors';
import { getRequestStates } from '../../selectors';

type Props = RouteComponentProps & {
  accessInfo: AccessInfoValue;
  defaultHomeAccess: HomeAccess;
  updateOwnerHomeAccessRequestStatus: Record<string, any>;
  onContinue: (values: any) => void;
  onGetOwnerHomeAccess: () => void;
  onResetRequest: typeof resetRequest;
  onUpdateOwnerHomeAccess: (values: any) => void;
};

export const mapFormToRequest = (
  values: AccessInfoValue = {
    method: '',
    keyHiddenLocation: '',
    lockboxCode: '',
    lockboxLocation: '',
    lockboxOther: '',
    otherNotes: '',
    additionalNotes: '',
  }
): HomeAccess => ({
  home: {
    htgiHomeLockbox: values.method === ACCESS_METHOD_LOCKBOX,
    htgiHomeLockboxCode:
      values.method === ACCESS_METHOD_LOCKBOX ? values.lockboxCode : undefined,
    htgiHomeLockboxLocation:
      values.method === ACCESS_METHOD_LOCKBOX
        ? values.lockboxLocation
        : undefined,
    htgiHomeLockboxOther:
      values.method === ACCESS_METHOD_LOCKBOX ? values.lockboxOther : undefined,
    htgiHomeKeyHidden: values.method === ACCESS_METHOD_KEY_HIDDEN,
    htgiHomeKeyHiddenLocation:
      values.method === ACCESS_METHOD_KEY_HIDDEN
        ? values.keyHiddenLocation
        : undefined,
    htgiHomeDoorman: values.method === ACCESS_METHOD_KEY_WITH_DOORMAN,
    htgiHomeAtHome: values.method === ACCESS_METHOD_WILL_BE_HOME,
    htgiHomeOther: values.method === ACCESS_METHOD_OTHER,
    htgiHomeOtherDetail:
      values.method === ACCESS_METHOD_OTHER ? values.otherNotes : undefined,
  },
  additional_notes: {
    htgiAdditionalNotes: values.additionalNotes !== '',
    htgiAdditionalNotesText:
      values.additionalNotes !== '' ? values.additionalNotes : undefined,
    additionalNotes:
      values.additionalNotes !== '' ? values.additionalNotes : undefined,
  },
});
export const AccessSummary = (props: Props) => {
  const {
    accessInfo,
    defaultHomeAccess,
    updateOwnerHomeAccessRequestStatus,
    onContinue,
    onGetOwnerHomeAccess,
    onResetRequest,
    onUpdateOwnerHomeAccess,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  useEffect(() => {
    if (onResetRequest) {
      onResetRequest(OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY);
    }
  }, [onResetRequest]);
  useEffect(() => {
    if (showModal && updateOwnerHomeAccessRequestStatus.isSuccess) {
      onContinue({ ...accessInfo, ...formValues });
      // re-poll after we save
      onGetOwnerHomeAccess();
    }
  }, [
    accessInfo,
    formValues,
    showModal,
    onContinue,
    onGetOwnerHomeAccess,
    updateOwnerHomeAccessRequestStatus.isSuccess,
  ]);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    setFormValues(values);

    if (
      JSON.stringify(defaultHomeAccess) !==
      JSON.stringify(mapFormToRequest({ ...accessInfo, ...values }))
    ) {
      setShowModal(true);
    } else {
      onContinue({ ...accessInfo, ...values });
    }
  };

  const onCancelModalClick = () => setShowModal(false);

  const onConfirmModalClick = () => {
    onUpdateOwnerHomeAccess(mapFormToRequest({ ...accessInfo, ...formValues }));
  };

  const onDenyModalClick = () => {
    onContinue({ ...accessInfo, ...formValues });
  };

  return (
    <NavigationWrapper
      title="Access Summary"
    >
      <Flex
        flexDirection="row"
        height="100%"
        justifyContent="center"
        overflowX="hidden"
        mt={[0, 0, 4]}
      >
        <Flex
          flexDirection="column"
          height="100%"
        >
          <BodyContainer
            boxSizing="border-box"
          >
            <Flex
              flexDirection="column"
              width={1}
              p={[0, 2]}
              px={[0, 3]}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box
                width={1}
                boxSizing="border-box"
                border={['none', 'none', '1px solid #D1D1D1']}
                borderRadius="16px"
                pt={[4, 3]}
                px={[0, 4]}
              >
                <Typography
                  textAlign="center"
                  fontSize="28px"
                  fontWeight="bold"
                  color="text.header"
                  lineHeight={1.5}
                  mb={4}
                  display={['none', 'none', 'block']}
                >
                  Access Summary
                </Typography>
                <Form
                  boxSizing="border-box"
                  initialValues={{
                    additionalNotes: accessInfo.additionalNotes || '',
                  }}
                  onSubmit={onSubmit}
                  render={() => (
                    <>
                      <Flex
                        flexDirection="column"
                        px={[3, 0]}
                      >
                        <Typography
                          width="60%"
                          color="text.word"
                          fontSize={[3, 4]}
                          px={3}
                        >
                          Home Access:
                        </Typography>
                        <Box
                          py={0}
                          px={4}
                        >
                          <Typography
                            fontWeight="bold"
                            fontSize={[3, 4]}
                            color="text.header"
                          >
                            {AccessMethodDescriptions[accessInfo.method]}
                          </Typography>
                          {accessInfo.method === ACCESS_METHOD_KEY_HIDDEN && (
                            <Typography
                              fontSize={[3, 4]}
                              color="text.word"
                            >
                              {accessInfo.keyHiddenLocation}
                            </Typography>
                          )}
                          {accessInfo.method === ACCESS_METHOD_LOCKBOX && (
                            <>
                              <Typography
                                fontSize={[3, 4]}
                                color="text.word"
                              >
                                Code:&nbsp;
                                {accessInfo.lockboxCode}
                              </Typography>
                              <Typography
                                fontSize={[3, 4]}
                                color="text.word"
                              >
                                Location:&nbsp;
                                {accessInfo.lockboxLocation}
                              </Typography>
                              <Typography
                                fontSize={[3, 4]}
                                color="text.word"
                              >
                                Other details:&nbsp;
                                {accessInfo.lockboxOther}
                              </Typography>
                            </>
                          )}
                          {accessInfo.method === ACCESS_METHOD_OTHER && (
                            <Typography
                              fontSize={[3, 4]}
                              color="text.word"
                            >
                              {accessInfo.otherNotes}
                            </Typography>
                          )}
                        </Box>
                        <Divider
                          width="100%"
                          my={2}
                          border="1px solid"
                          borderTop="none"
                          borderColor="backgroundGray"
                        />
                        <Flex
                          mt={[2, 3]}
                          px={3}
                          alignItems="center"
                        >
                          <Icon
                            width={['21px', '24px']}
                            height={['21px', '24px']}
                            mx={[1, 2]}
                            name="pageNote"
                          />
                          <Typography
                            color="text.word"
                            fontSize={[3, 4]} my={1}
                          >
                            Additional Notes
                          </Typography>
                        </Flex>
                        <Box
                          mx={3}
                        >
                          <TextAreaFormField
                            name="additionalNotes"
                            hasErrorMessage={false}
                            mb={2}
                            renderComponent={(fieldProps) => (
                              <TextArea
                                px={3}
                                placeholder="E.G. Enter from Main Ave and look for the door marked #123"
                                {...fieldProps}
                              />
                            )}
                          />
                        </Box>
                      </Flex>
                      <Divider
                        display={['block', 'none']}
                        width="100%"
                        my={2}
                        border="1px solid"
                        borderTop="none"
                        borderColor="backgroundGray"
                      />

                      <Flex
                        px={[4, 0]}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="white"
                          width="100%"
                          fontSize={['16px', '24px']}
                          py={[9, 12]}
                          px={[14, 20]}
                          my={[2, 4]}
                        >
                          Next
                        </Button>
                      </Flex>
                    </>
                  )}
                />
              </Box>
            </Flex>
          </BodyContainer>
          <Sidebar />
          {showModal && (
            <ConfirmModal
              onCancelModalClick={onCancelModalClick}
              onConfirmModalClick={onConfirmModalClick}
              onDenyModalClick={onDenyModalClick}
            />
          )}
        </Flex>
      </Flex>
    </NavigationWrapper>
  );
};
export default withRouter<any>(
  connect(
    (state) => ({
      defaultHomeAccess: getDefaultHomeAccess(state),
      updateOwnerHomeAccessRequestStatus: getRequestStates(
        state,
        OWNER_HOME_ACCESS_UPDATE_REQUEST_KEY
      ),
    }),
    {
      onGetOwnerHomeAccess: getOwnerHomeAccess,
      onResetRequest: resetRequest,
      onUpdateOwnerHomeAccess: updateOwnerHomeAccess,
    }
  )(AccessSummary)
);
