
import { createAction } from 'redux-actions';
import type {
  SignUpWalkerParsedResponse,
  StripeConnectGetLoginLinkParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  WALKER_SIGN_UP,
  WALKER_SIGN_UP_SUCCESS,
  WALKER_SIGN_UP_FAILURE,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE,
  WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS,
} from '../../actionTypes';

/**
   * sign up Walker
  */
export type SignUpWalkerParams = {
  email: string;
  password: string;
  source: string;
  willUpdatePassword?: boolean;
};

export type SignUpWalkerPayload = SignUpWalkerParams;

export type SignUpWalkerAction = {
  type: typeof WALKER_SIGN_UP;
  payload: SignUpWalkerPayload;
}

export const signUpWalker: (params: SignUpWalkerParams) => SignUpWalkerAction = createAction(WALKER_SIGN_UP);

/**
   * sign up walker success
   */

export type SignUpWalkerSuccessAction = {
  type: typeof WALKER_SIGN_UP_SUCCESS;
  payload: SignUpWalkerParsedResponse;
};

export const signUpWalkerSuccess: (response: SignUpWalkerParsedResponse) =>
  SignUpWalkerSuccessAction = createAction(
    WALKER_SIGN_UP_SUCCESS,
  );

/**
   * sign up walker failure
   */

export type SignUpWalkerFailureAction = {
  type: typeof WALKER_SIGN_UP_FAILURE;
  payload: ErrorParsedResponse;
};

export const signUpWalkerFailure: (error: ErrorParsedResponse) => SignUpWalkerFailureAction = createAction(
  WALKER_SIGN_UP_FAILURE,
);


/**
 * sign up walker get stripe redirect url
 */

export type SignUpWalkerStripeRedirectParams = {
  token: string;
  id: string;
}

export type SignUpWalkerStripeRedirectPayload = SignUpWalkerStripeRedirectParams;


export type SignUpWalkerStripeRedirectAction = {
  type: typeof WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL;
  payload: SignUpWalkerStripeRedirectPayload;
};

export const signUpWalkerStripeRedirect: (payload: SignUpWalkerStripeRedirectPayload) =>
 SignUpWalkerStripeRedirectAction = createAction(
   WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL,
 );

/**
 * sign up walker get stripe redirect url success
 */

export type SignUpWalkerStripeRedirectSuccessAction = {
  type: typeof WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS;
  payload: StripeConnectGetLoginLinkParsedResponse;
}

export const signUpWalkerStripeRedirectSuccess: (response: StripeConnectGetLoginLinkParsedResponse) =>
 SignUpWalkerStripeRedirectSuccessAction = createAction(
   WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_SUCCESS,
 );

/**
 *  sign up walker stripe redirect url failure
 */

export type SignUpWalkerStripeRedirectFailureAction = {
  type: typeof WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE;
  payload: ErrorParsedResponse;
};

export const signUpWalkerStripeRedirectFailure: (error: ErrorParsedResponse) =>
 SignUpWalkerStripeRedirectFailureAction = createAction(
   WALKER_STRIPE_CONNECT_SIGN_UP_GET_REDIRECT_URL_FAILURE,
 );
