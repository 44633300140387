import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_BOOKING_ADD_SITTING_SCHEDULE, Endpoint } from "../../../constants/endpoints";
import { BOOKING_OVERNIGHT } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { bookingOvernightSuccess, bookingOvernightFailure, BookingOvernightAction } from "../../../actions/booking/bookingOvernight";

const overnight$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(BOOKING_OVERNIGHT), retry(3), switchMap(({
  payload: {
    startDate,
    startTime,
    endDate,
    endTime,
    hasPickupAndDropoff,
    keyMode,
    lockboxInfo,
    lockboxCode,
    accessNotes,
    notes,
    walkTypeId,
    dogs,
    questionnaire,
    sitterShouldStay,
    shotsUpToDate,
    boardingWithOtherDogs,
    friendlyWithOtherDogs
  }
}: BookingOvernightAction) => post$(({
  endpoint: ENDPOINT_BOOKING_ADD_SITTING_SCHEDULE,
  payload: {
    apt_info: accessNotes,
    booking_source: 'web',
    check_for_duplicates: false,
    end_date: endDate,
    end_time: endTime,
    has_pickup_and_dropoff: hasPickupAndDropoff,
    id: getOwnerID(state$.value),
    is_recurring: false,
    key_mode: [keyMode],
    lockbox_code: lockboxCode,
    lockbox_info: lockboxInfo || accessNotes,
    notes,
    on_demand: 0,
    start_date: startDate,
    start_time: startTime,
    use_fastest_available: 1,
    use_preferred: 1,
    walk_type_id: walkTypeId,
    dogs,
    sitting_questionnaire_answers: questionnaire,
    sitter_should_stay: sitterShouldStay,
    shots_up_to_date: shotsUpToDate,
    boarding_with_other_dogs: boardingWithOtherDogs,
    friendly_with_other_dogs: friendlyWithOtherDogs,
    pick_best_available_sitters: true
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: any;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: bookingOvernightSuccess,
  onFailure: bookingOvernightFailure
})), catchError(handleErrorV5Response(action$, bookingOvernightFailure)))));

export default overnight$;