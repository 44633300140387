import { Component } from "react";
import type { Location } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { setQueryParams } from "../../actions/queryParams/queryParams.actions";
type ParentProps = {
  location: Location;
};
type WrapperProps = {
  onSetQueryParams: typeof setQueryParams;
};
type Props = ParentProps & WrapperProps;

const saveParams = (props: Props) => {
  const {
    location,
    onSetQueryParams
  } = props;
  const parsedQueryString = queryString.parse(location.search);

  if (Object.keys(parsedQueryString).length > 0) {
    onSetQueryParams(parsedQueryString);
  }
};

class CaptureParams extends Component<Props> {
  componentDidMount() {
    saveParams(this.props);
  }

  componentDidUpdate(props) {
    saveParams(props);
  }

  render() {
    return null;
  }

}

export default connect(null, {
  onSetQueryParams: setQueryParams
})(CaptureParams);