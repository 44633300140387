import type { GetOwnerPremiumSubscriptionSuccessResponse, V5Services } from "wagAPI";
import type { GetOwnerPremiumSubscriptionParsedSuccessResponse, GetOwnerPremiumSubscriptionParsedSuccessResponseService } from "webApp";

const servicesMapper = ({
  min_price: minPrice,
  cancel_price: cancelPrice,
  cancel_payout: cancelPayout,
  service_fee: serviceFee,
  walk_type_id: walkTypeId,
  description_short: descriptionShort,
  sort_order: sortOrder,
  price_per_dog: pricePerDog,
  additional_dog_price: additionalDogPrice,
  name_short: nameShort,
  is_surgeable: isSurgeable,
  additional_dog_payout: additionalDogPayout,
  slider_bar_order: sliderBarOrder,
  uses_mitm_numbers: usesMitmNumbers,
  name_slider: nameSlider,
  pricing_order: pricingOrder,
  recurring_discount: recurringDiscount,
  ...rest
}: V5Services): GetOwnerPremiumSubscriptionParsedSuccessResponseService => ({
  minPrice,
  cancelPrice,
  cancelPayout,
  serviceFee,
  walkTypeId,
  descriptionShort,
  sortOrder,
  pricePerDog,
  additionalDogPrice,
  nameShort,
  isSurgeable,
  additionalDogPayout,
  sliderBarOrder,
  usesMitmNumbers,
  nameSlider,
  pricingOrder,
  recurringDiscount,
  ...rest
});

export const getOwnerPremiumSubscriptionResponseParser = ({
  is_subscribed: isSubscribed,
  can_subscribe: canSubscribe,
  can_trial: canTrial,
  will_auto_renew: willAutoRenew,
  next_invoice_date: nextInvoiceDate,
  trial_start_date: trialStartDate,
  trial_end_date: trialEndDate,
  services_with_premium: servicesWithPremium,
  services_without_premium: servicesWithoutPremium // eslint-disable-next-line max-len

}: GetOwnerPremiumSubscriptionSuccessResponse): GetOwnerPremiumSubscriptionParsedSuccessResponse => ({
  isSubscribed,
  canSubscribe,
  canTrial,
  willAutoRenew,
  nextInvoiceDate,
  trialStartDate,
  trialEndDate,
  servicesWithPremium: servicesWithPremium.map(servicesMapper),
  servicesWithoutPremium: servicesWithoutPremium.map(servicesMapper)
});