import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import rootEpic from '../epics';
import reducer from '../reducers';

import {
  epicMiddleware,
  middleware,
} from './middleware';
import {
  loadState,
  localStorageKey,
} from './persistState';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/* eslint-enable */
const store = createStore(
  reducer,
  loadState(localStorageKey), // Pre-loaded state
  composeEnhancers(applyMiddleware(...middleware)),
);
epicMiddleware.run(rootEpic);
export default store;

// Connect with the Cypress helper
// https://github.com/Svish/cypress-helper-redux
if ('Cypress' in window) {
  const w = window as any;
  // eslint-disable-next-line no-underscore-dangle
  w.__chr__store__ = store;
}
