import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { ResetWalkerPasswordRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_RESET_PASSWORD, Endpoint } from "../../../constants/endpoints";
import { WALKER_PASSWORD_RESET } from "../../../actions/actionTypes";
import { resetWalkerPasswordSuccess, resetWalkerPasswordFailure, ResetWalkerPasswordAction } from "../../../actions/walker/resetWalkerPassword";

const resetWalkerPassword$ = (action$: Action$, state$: State$, {
  postLegacy$
}: Dependencies) => action$.pipe(ofType(WALKER_PASSWORD_RESET), switchMap(({
  payload: {
    resetToken,
    password,
    userID
  }
}: ResetWalkerPasswordAction) => postLegacy$(({
  endpoint: ENDPOINT_WALKER_RESET_PASSWORD,
  payload: {
    id: userID,
    reset_token: resetToken,
    password
  }
} as {
  endpoint: Endpoint;
  payload: ResetWalkerPasswordRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: resetWalkerPasswordSuccess,
  onFailure: resetWalkerPasswordFailure
})), catchError(handleErrorV5Response(action$, resetWalkerPasswordFailure)))));

export default resetWalkerPassword$;