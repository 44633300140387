import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { wellnessSlice } from '../slices/wellness';
import { app } from '../types/app';

export const useWellness = () => {
  const dispatch = useDispatch();
  const wellness = useSelector(wellnessSlice.selectors.get);
  const raw = useSelector(wellnessSlice.selectors.getRaw);

  /**
   * TODO - Remove this if the partial saving is totally fine
   * or if we need to create a separate function
   */
  const save = (wellness: Partial<app.Wellness.Wellness>) => {
    dispatch(wellnessSlice.actions.save(wellness));
  };

  /**
   * TODO - Update typing definition
   */
  const savePetAddons = (wellnessPets: Partial<app.Wellness.WellnessPet>[]) => {
    dispatch(wellnessSlice.actions.savePetAddons(wellnessPets));
  };

  const removePet = (wellnessPet: Partial<app.Wellness.WellnessPet>) => {
    dispatch(wellnessSlice.actions.removePet(wellnessPet));
  };

  const removeExtra = (wellnessExtra: Partial<app.Wellness.WellnessExtra>) => {
    dispatch(wellnessSlice.actions.removeExtra(wellnessExtra));
  };

  const clear = () => {
    dispatch(wellnessSlice.actions.clear());
  };

  return {
    data: wellness,
    /**
     * Unfiltered wellness data including pet that don't have plans (ie "no plans")
     */
    raw,
    save,
    savePetAddons,
    removePet,
    removeExtra,
    clear,
  };
};
