
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  SUBSCRIBE_TO_PREMIUM,
  SUBSCRIBE_TO_PREMIUM_SUCCESS,
  SUBSCRIBE_TO_PREMIUM_FAILURE,
} from '../../actionTypes';

/**
 * Subscribe to premium
 */

export type SubscribeToPremiumAction = {
  type: typeof SUBSCRIBE_TO_PREMIUM,
};

export const subscribeToPremium: (
) => SubscribeToPremiumAction = createAction(
  SUBSCRIBE_TO_PREMIUM,
);


/**
 * Subscribe to premium success action
 */
export type SubscribeToPremiumSuccessAction = {
  type: typeof SUBSCRIBE_TO_PREMIUM_SUCCESS,
};

export const subscribeToPremiumSuccess: (
  response: webApp.SubscribeToPremiumParsedSuccessResponse
) => SubscribeToPremiumSuccessAction = createAction(
  SUBSCRIBE_TO_PREMIUM_SUCCESS,
);


/**
 * Subscribe to premium failure action
 */
export type SubscribeToPremiumFailureAction = {
  type: typeof SUBSCRIBE_TO_PREMIUM_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const subscribeToPremiumFailure: (
  error: webApp.ErrorParsedResponse,
) => SubscribeToPremiumFailureAction = createAction(
  SUBSCRIBE_TO_PREMIUM_FAILURE,
);
