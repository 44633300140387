import { handleActions } from 'redux-actions';

import {
  ESTIMATE_CLEAR,
  ESTIMATE_OVERNIGHT_SUCCESS,
} from '../../actions/actionTypes';
import type { EstimateOvernightSuccessAction } from '../../actions/booking/estimateOvernight';

export type EstimateState = {};
export const initialState = {};
export const reducer: any = handleActions<EstimateState, Record<string, any>>({
  [ESTIMATE_CLEAR]: (): EstimateState => ({}),
  [ESTIMATE_OVERNIGHT_SUCCESS]: (state: EstimateState, {
    payload,
  }: EstimateOvernightSuccessAction): EstimateState => ({ ...state,
    ...payload }),
}, initialState);
export default reducer;
