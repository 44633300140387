import type { GetAvailableServicesRequestResponse } from "wagAPI";
import type { GetAvailableServicesParsedResponse } from "webApp";

const getService = ({
  name,
  name_short: nameShort,
  description,
  description_short: descriptionShort,
  length,
  price,
  payout,
  additional_dog_price: additionalDogPrice,
  additional_dog_payout: additionalDogPayout,
  cancel_price: cancelPrice,
  cancel_payout: cancelPayout,
  service_fee: serviceFee,
  is_surgeable: isSurgeable,
  sort_order: sortOrder,
  slider_bar_order: sliderBarOrder,
  pricing_order: pricingOrder,
  category,
  recurring_discount: recurringDiscount,
  min_price: minPrice,
  name_slider: nameSlider,
  uses_mitm_numbers: usesMitmNumbers,
  minutes,
  price_per_dog: pricePerDog,
  type,
  walk_type_id: walkTypeId
}) => ({
  name,
  nameShort,
  description,
  descriptionShort,
  length,
  price,
  payout,
  additionalDogPrice,
  additionalDogPayout,
  cancelPrice,
  cancelPayout,
  serviceFee,
  isSurgeable,
  sortOrder,
  sliderBarOrder,
  pricingOrder,
  category,
  recurringDiscount,
  minPrice,
  nameSlider,
  usesMitmNumbers,
  minutes,
  pricePerDog,
  type,
  walkTypeId
});

const getGrouping = ({
  type,
  title,
  description,
  subtitle,
  starting_price: startingPrice,
  image_url: imageURL,
  slider_bar_order: sliderBarOrder
}) => ({
  type,
  title,
  subtitle,
  description,
  startingPrice,
  imageURL,
  sliderBarOrder
});

export const getAvailableServicesResponseParser = ({
  available_services: availableServices,
  default_services: defaultServices,
  available_services_groupings: serviceGrouping
}: GetAvailableServicesRequestResponse): GetAvailableServicesParsedResponse => ({
  availableServices: availableServices.map(service => getService(service)),
  defaultServices: defaultServices.map(service => getService(service)),
  servicesGroupings: serviceGrouping.map(grouping => getGrouping(grouping))
});