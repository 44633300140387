
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';

import {
  OWNER_PASSWORD_CHANGE,
  OWNER_PASSWORD_CHANGE_SUCCESS,
  OWNER_PASSWORD_CHANGE_FAILURE,
} from '../../actionTypes';


/**
 * Change owner password
 */
export type ChangeOwnerPasswordPayload = {
  newPassword: string,
};

export type ChangeOwnerPasswordAction = {
  type: typeof OWNER_PASSWORD_CHANGE,
  payload: ChangeOwnerPasswordPayload,
};

export const changeOwnerPassword: (string) => ChangeOwnerPasswordAction = createAction(
  OWNER_PASSWORD_CHANGE,
  newPassword => ({ newPassword }),
);


/**
 * Change owner password success
 */
export type ChangeOwnerPasswordSuccessAction = {
  type: typeof OWNER_PASSWORD_CHANGE_SUCCESS,
};

export const changeOwnerPasswordSuccess: () => ChangeOwnerPasswordSuccessAction = createAction(
  OWNER_PASSWORD_CHANGE_SUCCESS,
);


/**
 * Change owner password failure
 */
export type ChangeOwnerPasswordFailureAction = {
  type: typeof OWNER_PASSWORD_CHANGE_FAILURE,
  payload: ErrorParsedResponse,
};

export const changeOwnerPasswordFailure: (
  ErrorParsedResponse,
) => ChangeOwnerPasswordFailureAction = createAction(
  OWNER_PASSWORD_CHANGE_FAILURE,
);
