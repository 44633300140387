import { handleActions } from 'redux-actions';

import { SAVE_SIGNUP_ADDRESS } from '../../actions/actionTypes';
import type { SaveSignUpAddressAction } from '../../actions/owner/saveSignupAddress';

export type SignupState = {
  apartmentNumber?: string;
  city: string;
  state: string;
  streetAddress: string;
  zipCode: string;
  latitude?: number;
  longitude?: number;
  phone: string;
};
export const initialState = {
  apartmentNumber: '',
  city: '',
  state: '',
  streetAddress: '',
  zipCode: '',
  latitude: NaN,
  longitude: NaN,
  phone: '',
};
export const reducer: any = handleActions<SignupState, Record<string, any>>({
  [SAVE_SIGNUP_ADDRESS]: (defaultState: SignupState, {
    payload: {
      apartmentNumber,
      city,
      state,
      streetAddress,
      zipCode,
      latitude,
      longitude,
      phone,
    },
  }: SaveSignUpAddressAction): SignupState => ({ ...defaultState,
    apartmentNumber,
    city,
    state,
    streetAddress,
    zipCode,
    latitude,
    longitude,
    phone }),
}, initialState);
export default reducer;
