import { FEATURE_SEGMENT_ENABLED } from '../constants';
import { webApp } from '../types/webapp';

/**
 * Returns an object containing a function that makes a Segment identify call.
 */
export const useSegmentIdentify = () => {
  /**
   * Makes a Segment identify call with the given
   * id and traits.
   */
  const execute = async ({
    id,
    traits,
  }: webApp.SegmentIdentifySchema) => {
    if (!FEATURE_SEGMENT_ENABLED) {
      console.warn('Attempted to identify, but Segment is not enabled');
      return;
    }

    const { analytics } = window;
    if (analytics) {
      analytics.identify(id, traits);
    }
  };

  return {
    execute,
  };
};
