
import { createAction } from 'redux-actions';
import type {
  CreateOwnerPaymentMethodParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_PAYMENT_METHOD_CREATE,
  OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
  OWNER_PAYMENT_METHOD_CREATE_FAILURE,
} from '../../actionTypes';

/**
 * Create owner payment method
 */
export type CreateOwnerPaymentMethodParams = {
  stripeToken: string,
}

export type CreateOwnerPaymentMethodPayload = CreateOwnerPaymentMethodParams;

export type CreateOwnerPaymentMethodAction = {
  type: typeof OWNER_PAYMENT_METHOD_CREATE,
  payload: CreateOwnerPaymentMethodPayload,
};

export const createOwnerPaymentMethod: (
  CreateOwnerPaymentMethodParams,
) => CreateOwnerPaymentMethodAction = createAction(
  OWNER_PAYMENT_METHOD_CREATE,
  ({
    stripeToken,
  }) => ({
    stripeToken,
  }),
);


/**
 * Create owner payment method success action
 */
export type CreateOwnerPaymentMethodSuccessAction = {
  type: typeof OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
  payload: CreateOwnerPaymentMethodParsedResponse,
};

export const createOwnerPaymentMethodSuccess: (
  CreateOwnerPaymentMethodParsedResponse
) => CreateOwnerPaymentMethodSuccessAction = createAction(
  OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
);


/**
 * Create owner payment method failure action
 */
export type CreateOwnerPaymentMethodFailureAction = {
  type: typeof OWNER_PAYMENT_METHOD_CREATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const createOwnerPaymentMethodFailure: (
  ErrorParsedResponse,
) => CreateOwnerPaymentMethodFailureAction = createAction(
  OWNER_PAYMENT_METHOD_CREATE_FAILURE,
);
