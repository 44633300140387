
import { createAction } from 'redux-actions';
import type {
  PostAuthenticationPasswordParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  AUTHENTICATION_PASSWORD_POST,
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
  AUTHENTICATION_PASSWORD_POST_FAILURE,
} from '../../actionTypes';

/**
 * Post authentication password
 */
export type PostAuthenticationPasswordParams = {
  facebookAccessToken?: string | null | undefined;
  sessionToken: string,
  password: string,
};

export type PostAuthenticationPasswordPayload = PostAuthenticationPasswordParams;

export type PostAuthenticationPasswordAction = {
  type: typeof AUTHENTICATION_PASSWORD_POST,
  payload: PostAuthenticationPasswordPayload,
};

export const postAuthenticationPassword: (
  params: PostAuthenticationPasswordParams,
) => PostAuthenticationPasswordAction = createAction(
  AUTHENTICATION_PASSWORD_POST,
);

/**
 * Post authentication password success action
 */
export type PostAuthenticationPasswordSuccessAction = {
  type: typeof AUTHENTICATION_PASSWORD_POST_SUCCESS,
};

export const postAuthenticationPasswordSuccess: (
  response: PostAuthenticationPasswordParsedResponse
) => PostAuthenticationPasswordSuccessAction = createAction(
  AUTHENTICATION_PASSWORD_POST_SUCCESS,
);

/**
 * Post authentication password failure action
 */
export type PostAuthenticationPasswordFailureAction = {
  type: typeof AUTHENTICATION_PASSWORD_POST_FAILURE,
  payload: ErrorParsedResponse,
};

export const postAuthenticationPasswordFailure: (
  error: ErrorParsedResponse,
) => PostAuthenticationPasswordFailureAction = createAction(
  AUTHENTICATION_PASSWORD_POST_FAILURE,
);
