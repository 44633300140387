import React, {
  Component,
  Fragment,
} from 'react';

import { connect } from 'react-redux';
import { webApp } from '../../types/webapp';

import ReportError from './ReportError';
import ErrorDisplay from '../ErrorDisplay';

type Props = {
  children: React.ReactNode;
  connectedReportError: (arg0: any) => React.ReactNode;
  renderError?: (
    arg0: any
  ) => React.ReactElement<React.ComponentProps<any>, any> | null | undefined;
  error?: {
    title: string;
    message: string;
  };
};

type State = {
  errorMessage: string;
  componentStack: string;
  hasError: boolean;
};

class ConnectedErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  state: State = {
    errorMessage: '',
    componentStack: '',
    hasError: false,
  };

  componentDidCatch(error: Error, errorInfo: webApp.ErrorInfo) {
    this.setState({
      errorMessage: error.message,
      componentStack: errorInfo.componentStack,
    });
  }

  render() {
    const { children, renderError, connectedReportError } = this.props;
    const { errorMessage, componentStack, hasError } = this.state;

    if (hasError) {
      return (
        <Fragment>
          {connectedReportError ? (
            connectedReportError({
              componentStack,
              errorMessage,
            })
          ) : (
            <ReportError
              componentStack={componentStack}
              errorMessage={errorMessage}
            />
          )}

          {renderError ? (
            renderError({
              errorMessage,
              componentStack,
            })
          ) : (
            <ErrorDisplay />
          )}
        </Fragment>
      );
    }

    return children || null;
  }
}

export default connect((state) => ({
  error: state.error,
}))(ConnectedErrorBoundary);
