import {
  createSlice,
  DeepPartial,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { filter } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { ReduxState } from '../../reducers';
import { app } from '../../types/app';

export type WellnessRecommendationState = {
  allIds: string[];
  bySubscriptionId: Record<string, app.Wellness.WellnessRecommendation.Subscription>;
};
const initialState: WellnessRecommendationState = {
  allIds: [],
  bySubscriptionId: {},
};

const slice = createSlice({
  name: 'wellness-recommendation',
  initialState,
  reducers: {
    createSubscription: (state, {
      payload,
    }: {
      payload: DeepPartial<Pick<app.Wellness.WellnessRecommendation.Subscription, 'pet'>>
        & Partial<Omit<app.Wellness.WellnessRecommendation.Subscription, 'pet'>>,
    }) => {
      const pendingSubscription: app.Wellness.WellnessRecommendation.Subscription = {
        pet: {
          name: '',
          breed: '',
          gender: undefined,
          birthday: moment().format('MM/DD/YYYY'),
          weight: 0,
          spayed: undefined,
          neutered: undefined,
          groomingFrequency: 0,
          ...payload?.pet,
        },
        plan: payload?.plan || null,
        addons: payload?.addons || [],
        uuid: payload.uuid || uuidv4(),
      };

      return {
        allIds: [...state.allIds, pendingSubscription.uuid],
        bySubscriptionId: {
          ...state.bySubscriptionId,
          [pendingSubscription.uuid]: pendingSubscription,
        },
      };
    },
    updateSubscription: (state, {
      payload,
    }: {
      payload: {
        uuid: string,
        newSubscription: app.Wellness.WellnessRecommendation.Subscription,
      }
    }) => {
      const subscription = state.bySubscriptionId[payload.uuid];

      if (!subscription) {
        return state;
      }

      return {
        ...state,
        bySubscriptionId: {
          ...state.bySubscriptionId,
          [payload.uuid]: payload.newSubscription,
        },
      };
    },
    removeSubscription: (state, {
      payload,
    }: {
      payload: {
        uuid: string,
      }
    }) => {
      const subscription = state.bySubscriptionId[payload.uuid];

      if (!subscription) {
        return state;
      }

      const newAllids = filter(
        (uuid) => uuid !== payload.uuid,
        state.allIds,
      );

      const newBySubscriptId = filter(
        ({ uuid }) => uuid !== payload.uuid,
        state.bySubscriptionId,
      );

      return {
        ...state,
        allIds: newAllids,
        bySubscriptionId: newBySubscriptId,
      };
    },
    clear: () => initialState,
  },
});
// selector functions
const selectors = {
  allIds: (state: ReduxState) => state.wellnessRecommendation.allIds,
  bySubscriptionId: (uuid: string) => (state: ReduxState) => (
    state.wellnessRecommendation.bySubscriptionId[uuid]
  ),
};

export const wellnessRecommendationSlice = {
  ...slice,
  selectors,
};
