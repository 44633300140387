export type ApiError = ApiErrorV1 | ApiErrorV4 | ApiErrorV5 | ApiErrorV6 | ApiErrorV7;

export type ApiErrorV1 = Error & {
  version: 1;
};
export type ApiErrorV4 = Error & {
  [key: string]: any;
  version: 4;
  success: boolean;
};

export type ApiErrorV5 = Error & {
  version: 5;
  response: {
    title: string;
    status: number;
    type: string;
    detail: string;
  };
};
export type ApiErrorV6 = Error & {
  [key: string]: any;
  version: 6;
  title: string;
  status: number;
  type: string;
  detail: string;
};

export type ApiErrorV7 = Error & {
  [key: string]: unknown;
  version: 7;
  title: string;
  status: number;
  type: string;
  detail: string;
};

/**
 * `buildApiErrorPipeline` is a generic pipeline function that accepts an API error object (`ApiError`)
 *  that returns an object that contains `pipe` function that accepts multiple functions to process
 *  the passed API error object
 *
 * This can be used whether you want to build / process different API error version (ex: 5)
 */
export const buildApiErrorPipeline = <T extends ApiError>(error: T) => ({
  pipe: (...args: Function[]) => args.reduce((value, pipeFn) => pipeFn(value), error),
});
