
import { createAction } from 'redux-actions';
import type {
  PostAuthenticationUpgradeParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  AUTHENTICATION_UPGRADE_POST,
  AUTHENTICATION_UPGRADE_POST_SUCCESS,
  AUTHENTICATION_UPGRADE_POST_FAILURE,
  AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS,
  AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE,
} from '../../actionTypes';

/**
 * Post authentication upgrade
 */
export type PostAuthenticationUpgradeParams = {
  id: number,
  token: string, // legacy token
  deviceType: string,
  userRoleType: string,
};

export type PostAuthenticationUpgradePayload = PostAuthenticationUpgradeParams;

export type PostAuthenticationUpgradeAction = {
  type: typeof AUTHENTICATION_UPGRADE_POST;
  payload: PostAuthenticationUpgradePayload;
};

export const postAuthenticationUpgrade: (
  params?: Partial<PostAuthenticationUpgradeParams> | null | undefined,
) => PostAuthenticationUpgradeAction = createAction(
  AUTHENTICATION_UPGRADE_POST,
);

/**
 * Post authentication upgrade success action
 */
export type PostAuthenticationUpgradeSuccessAction = {
  type: typeof AUTHENTICATION_UPGRADE_POST_SUCCESS;
};

export const postAuthenticationUpgradeSuccess: (
  response: PostAuthenticationUpgradeParsedResponse
) => PostAuthenticationUpgradeSuccessAction = createAction(
  AUTHENTICATION_UPGRADE_POST_SUCCESS,
);

/**
 * Post authentication upgrade failure action
 */
export type PostAuthenticationUpgradeFailureAction = {
  type: typeof AUTHENTICATION_UPGRADE_POST_FAILURE;
  payload: ErrorParsedResponse;
};

export const postAuthenticationUpgradeFailure: (
  error: ErrorParsedResponse,
) => PostAuthenticationUpgradeFailureAction = createAction(
  AUTHENTICATION_UPGRADE_POST_FAILURE,
);

/**
 * Post authentication upgrade success action for walkers/pcg
 */
 export type PostAuthenticationUpgradeSuccessWalkerAction = {
  type: typeof AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS;
};

export const postAuthenticationUpgradeWalkerSuccess: (
  response: PostAuthenticationUpgradeParsedResponse
) => PostAuthenticationUpgradeSuccessWalkerAction = createAction(
  AUTHENTICATION_UPGRADE_POST_WALKER_SUCCESS,
);

/**
 * Post authentication upgrade success action for walkers/pcg
 */
export type PostAuthenticationUpgradeFailureWalkerAction = {
  type: typeof AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE;
  payload: ErrorParsedResponse;
};

export const postAuthenticationUpgradeWalkerFailure: (
  error: ErrorParsedResponse,
) => PostAuthenticationUpgradeFailureWalkerAction = createAction(
  AUTHENTICATION_UPGRADE_POST_WALKER_FAILURE,
);
