import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

// TODO - allow changing the color
export const CheckConfirmedIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#00BF8F"
    viewBox="0 0 40 43"
    {...props}
  >
    <path
      d="M32 0a8 8 0 0 1 8 8v35L0 0h32Zm1.543 6.275-7.836 8.067-1.998-2.264a.976.976 0 0 0-1.249-.065c-.477.353-.604 1.064-.28 1.587l2.864 4.637c.099.16.228.31.38.43.705.564 1.695.394 2.21-.378l.065-.098 7.137-10.71a.996.996 0 0 0-.089-1.21.802.802 0 0 0-1.204.004Z"
      fill="#3A5080"
      fillRule="evenodd"
    />
  </SvgIcon>
);
