import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

type Props = SvgIconProps;

export const TrainingServiceIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 64 64"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M.038.068h8.151v9.078H.038z"
      />
      <path
        id="c"
        d="M0 .045h10.824v9.678H0z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M25.666 23.595l-.532-.418c-1.34-1.052-1.584-3.02-.542-4.373l3.357-4.357a3.077 3.077 0 014.332-.547l.532.418c1.34 1.052 1.584 3.02.542 4.373l-3.357 4.357a3.077 3.077 0 01-4.332.547"
        fill="#4669B2"
      />
      <g transform="translate(24.368 12.838)">
        <mask
          id="b"
          fill="#fff"
        >
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M1.042 8.594A2.657 2.657 0 01.584 4.9l2.943-3.82A2.598 2.598 0 017.185.62a2.657 2.657 0 01.458 3.693L4.701 8.132a2.598 2.598 0 01-3.66.462"
          fill="#3798E4"
          mask="url(#b)"
        />
      </g>
      <path
        d="M34.126 22.531c3.623 2.66 3.73 8.745.239 13.59-2.792 3.876-7.551 5.58-10.79 4.91l-.003.007s-3.79-1.273-9.248 7.044l-.75-3.791 5.458-7.671.107-.148c-.897-2.968-.252-6.71 2.105-9.982 3.491-4.847 9.258-6.619 12.882-3.959z"
        fill="#4669B2"
      />
      <path
        d="M21.802 16.146l.077.003 9.592 4.944 1.19.613.042.022.978.503c-3.313-.98-6.942 4.488-6.942 4.488L13.573 44.291l-9.19-5.307.032-.051-.915-.529s9.1-14.584 11.575-18.553c2.135-3.425 5.65-3.696 6.575-3.705z"
        fill="#3798E4"
      />
      <g transform="translate(3.5 38.36)">
        <mask
          id="d"
          fill="#fff"
        >
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#616A9B"
          mask="url(#d)"
          d="M0 .045l1.9 4.37 8.924 5.308-.75-3.791z"
        />
      </g>
      <path
        d="M22.61 26.93c.89-1.582 2.084-2.805 3.58-3.667l-8.088-4.178s-1.07 0-3.009 3.655l7.517 4.19z"
        fill="#4669B2"
      />
      <path
        d="M30.192 16.623c1.535 1.06 28.156 6.89 25.704 20.988-2.96 17.029-27.467 13.777-27.426 8.297.083-11.27 34.97-.618 30.967-17.512-2.171-9.162-17.615-9.392-25.436-10.607"
        stroke="#AAA"
        strokeWidth="2.007"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
