import {
  ApiError,
  ApiErrorV5,
  ApiErrorV6,
  ApiErrorV7,
} from '../buildApiErrorPipeline';

export const getApiErrorMesage = (apiError: ApiError) => {
  if (apiError?.version === 5) {
    return (apiError as ApiErrorV5).response.detail;
  }

  if (apiError?.version === 6) {
    return (apiError as ApiErrorV6).detail;
  }

  if (apiError?.version === 7) {
    return (apiError as ApiErrorV7).detail;
  }
  return apiError.message || '';
};
