import {
  handleActions,
  combineActions,
} from 'redux-actions';
import type { Dog } from 'webApp';

import {
  DOG_CLEAR,
  DOG_CREATE_FAILURE,
  DOG_CREATE_SUCCESS,
  DOG_CREATE,
  DOG_PROFILE_PATCH,
  DOG_PROFILE_PATCH_SUCCESS,
  LOGOUT,
  OWNER_GET_SUCCESS,
  OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE,
  OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS,
  OWNER_REGISTRATION_DOG_INFO_CREATE,
} from '../../actions/actionTypes';
import type {
  PatchDogProfileAction,
  PatchDogProfileSuccessAction,
} from '../../actions/dog/patchDogProfile';
import type { GetOwnerSuccessAction } from '../../actions/owner/getOwner';

type UpdateDogActions = RegisterOwnerDogInfoCreateAction
  & PatchDogProfileAction
  & GetOwnerSuccessAction;
type UpdateDogSuccessActions = PatchDogProfileSuccessAction;

export type DogState = {
  dog: Dog;
  oldDog: Dog;
};

export const initialState: DogState = {
  dog: {},
  oldDog: {},
};

export const reducer: any = handleActions({
  [combineActions(
    DOG_CREATE,
    OWNER_REGISTRATION_DOG_INFO_CREATE,
    DOG_PROFILE_PATCH,
  )]: (state: DogState, {
    payload,
  }: UpdateDogActions): DogState => ({
    ...state,
    dog: {
      ...state.dog,
      ...payload,
    },
    oldDog: state.dog,
  }),
  [combineActions(
    DOG_CREATE_SUCCESS,
    DOG_PROFILE_PATCH_SUCCESS,
  )]: (state: DogState, {
    payload,
  }: UpdateDogSuccessActions): DogState => ({
    ...state,
    dog: {
      ...state.dog,
      ...payload,
    },
    oldDog: {},
  }),
  [OWNER_REGISTRATION_DOG_INFO_CREATE_SUCCESS]: (state: DogState, {
    payload,
  }: RegisterOwnerDogInfoCreateSuccessAction): DogState => ({
    dog: { ...state.dog,
      ...payload },
    oldDog: {},
  }),
  [OWNER_GET_SUCCESS]: (state: DogState, {
    payload: {
      dog,
    },
  }: GetOwnerSuccessAction): DogState => ({
    dog: { ...state.dog,
      ...dog },
    oldDog: {},
  }),
  [combineActions(
    DOG_CREATE_FAILURE,
    OWNER_REGISTRATION_DOG_INFO_CREATE_FAILURE,
  )]: (state: DogState): DogState => ({
    ...state,
    dog: {
      ...state.oldDog,
    },
    oldDog: {},
  }),
  [combineActions(
    LOGOUT,
    DOG_CLEAR,
  )]: () => initialState,
}, initialState);
export default reducer;
