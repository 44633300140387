
import { createAction } from 'redux-actions';

import type { ErrorParsedResponse, GetWalkDetailsParsedResponse } from 'webApp';

import {
  WALK_DETAILS_GET,
  WALK_DETAILS_GET_SUCCESS,
  WALK_DETAILS_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walk details of a specific walk
 */
export type GetWalkDetailsPayload = {
   walkID: number,
}
export type GetWalkDetailsAction = {
  type: typeof WALK_DETAILS_GET,
  payload: GetWalkDetailsPayload,
}

export const getWalkDetails: (number) => GetWalkDetailsAction = createAction(
  WALK_DETAILS_GET,
  walkID => ({ walkID }),
);


/**
 * Get walk details of a specific walk success
 */
export type GetWalkDetailsSuccessAction = {
  type: typeof WALK_DETAILS_GET_SUCCESS,
  payload: GetWalkDetailsParsedResponse,
}

export const getWalkDetailsSuccess: (
  GetWalkDetailsParsedResponse,
) => GetWalkDetailsSuccessAction = createAction(
  WALK_DETAILS_GET_SUCCESS,
);


/**
 * Get walk details of a specific walk failure
 */
export type GetWalkDetailsFailureAction = {
  type: typeof WALK_DETAILS_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalkDetailsFailure: (
  ErrorParsedResponse,
) => GetWalkDetailsFailureAction = createAction(
  WALK_DETAILS_GET_FAILURE,
);
