
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  PAST_PET_CAREGIVERS_GET,
  PAST_PET_CAREGIVERS_GET_SUCCESS,
  PAST_PET_CAREGIVERS_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get past pet caregivers
 */

export type GetPastPetCaregiversAction = {
  type: typeof PAST_PET_CAREGIVERS_GET,
};

export const getPastPetCaregivers: (
) => GetPastPetCaregiversAction = createAction(
  PAST_PET_CAREGIVERS_GET,
);


/**
 * Get past pet caregivers success action
 */
export type GetPastPetCaregiversSuccessAction = {
  type: typeof PAST_PET_CAREGIVERS_GET_SUCCESS,
};

export const getPastPetCaregiversSuccess: (
  response: webApp.GetPastPetCaregiversParsedResponse
) => GetPastPetCaregiversSuccessAction = createAction(
  PAST_PET_CAREGIVERS_GET_SUCCESS,
);


/**
 * Get past pet caregivers failure action
 */
export type GetPastPetCaregiversFailureAction = {
  type: typeof PAST_PET_CAREGIVERS_GET_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const getPastPetCaregiversFailure: (
  error: webApp.ErrorParsedResponse,
) => GetPastPetCaregiversFailureAction = createAction(
  PAST_PET_CAREGIVERS_GET_FAILURE,
);
