import React from "react";
import type { ReportErrorType } from "webApp";
import { ErrorReporterContext } from "./ErrorReporterProvider";

const filterState = (state: Record<string, any>) => {
  const {
    auth,
    form,
    ...filteredState
  } = state;
  return { ...filteredState,
    requests: Object.entries(filteredState.requests).reduce((acc, [requestKey, requestState]) => ({
      [requestKey]: {
        requestState,
        values: null
      }
    }), {})
  };
};

const ReportError = ({
  errorMessage,
  componentStack,
  state
}: ReportErrorType) => <ErrorReporterContext.Consumer>
    {({
    report
  }) => {
    report({
      errorMessage,
      errorInfo: {
        componentStack,
        state: state ? JSON.stringify(filterState(state)) : ''
      }
    });
    return null;
  }}
  </ErrorReporterContext.Consumer>;

export default ReportError;