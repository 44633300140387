import { combineEpics } from 'redux-observable';

import registerOwnerApplyDetails$ from './registerOwnerApplyDetails/registerOwnerApplyDetails.epic';
import registerOwnerBasicInfo$ from './registerOwnerBasicInfo/registerOwnerBasicInfo.epic';
import registerOwnerBasicInfoV2$ from './registerOwnerBasicInfoV2/registerOwnerBasicInfoV2.epic';
import registerOwnerDogInfoCreate$ from './registerOwnerDogInfoCreate/registerOwnerDogInfoCreate.epic';
import registerOwnerDogQuestionaireCreate$ from './registerOwnerDogInfoCreate/registerOwnerDogQuestionaireCreate.epic';
import registerOwnerGoogleSignin$ from './registerOwnerGoogleSignin/registerOwnerGoogleSignin.epic';
import registerOwnerRequestLockbox$ from './registerOwnerRequestLockbox/registerOwnerRequestLockbox.epic';

export default combineEpics(
  registerOwnerBasicInfo$,
  registerOwnerBasicInfoV2$,
  registerOwnerDogInfoCreate$,
  registerOwnerDogQuestionaireCreate$,
  registerOwnerRequestLockbox$,
  registerOwnerGoogleSignin$,
  registerOwnerApplyDetails$,
);
