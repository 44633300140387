
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  OWNER_REGISTRATION_BASIC_INFO,
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_FAILURE,
  OWNER_REGISTRATION_BASIC_INFO_V2,
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
} from '../../actionTypes';


/**
 * Register owner basic info
 */
export type RegisterOwnerBasicInfoParams = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
  promoCode?: string,
  signupFlowSource?: string,
};

export type RegisterOwnerBasicInfoV2Params = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  phone: string,
  token: string,
  qs?: {
    [key: string]: string;
  },
};

export type RegisterOwnerBasicInfoPayload = RegisterOwnerBasicInfoParams;
export type RegisterOwnerBasicInfoV2Payload = RegisterOwnerBasicInfoV2Params;

export type RegisterOwnerBasicInfoAction = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO,
  payload: RegisterOwnerBasicInfoPayload,
};

export type RegisterOwnerBasicInfoV2Action = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO_V2,
  payload: RegisterOwnerBasicInfoV2Payload,
};


export const registerOwnerBasicInfo: (
  params: RegisterOwnerBasicInfoParams,
) => RegisterOwnerBasicInfoAction = createAction(
  OWNER_REGISTRATION_BASIC_INFO,
);

export const registerOwnerBasicInfoV2: (
  params: RegisterOwnerBasicInfoV2Params,
) => RegisterOwnerBasicInfoV2Action = createAction(
  OWNER_REGISTRATION_BASIC_INFO_V2,
);


/**
 * Register owner basic info success
 */
export type RegisterOwnerBasicInfoSuccessAction = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  payload: webApp.RegisterOwnerParsedResponse,
};

export const registerOwnerBasicInfoSuccess: (
  response: webApp.RegisterOwnerParsedResponse,
) => RegisterOwnerBasicInfoSuccessAction = createAction(
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
);

export type RegisterOwnerBasicV2InfoSuccessAction = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  payload: webApp.RegisterOwnerV2ParsedResponse,
};

export const registerOwnerBasicInfoV2Success: (
  response: webApp.RegisterOwnerV2ParsedResponse,
) => RegisterOwnerBasicV2InfoSuccessAction = createAction(
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
);


/**
 * Register owner basic info failure
 */
export type RegisterOwnerBasicInfoFailureAction = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const registerOwnerBasicInfoFailure: (
  error: webApp.ErrorParsedResponse,
)
=> RegisterOwnerBasicInfoFailureAction = createAction(
  OWNER_REGISTRATION_BASIC_INFO_FAILURE,
);

export type RegisterOwnerBasicInfoV2FailureAction = {
  type: typeof OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const registerOwnerBasicInfoV2Failure: (
  error: webApp.ErrorParsedResponse,
)
=> RegisterOwnerBasicInfoV2FailureAction = createAction(
  OWNER_REGISTRATION_BASIC_INFO_V2_FAILURE,
);
