
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalksUpcomingParsedResponse,
} from 'webApp';

import {
  WALKS_UPCOMING_GET,
  WALKS_UPCOMING_GET_SUCCESS,
  WALKS_UPCOMING_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get upcoming walks for the current user
 */
export type GetWalksUpcomingAction = {
  type: typeof WALKS_UPCOMING_GET,
}

export const getWalksUpcoming: () => GetWalksUpcomingAction = createAction(
  WALKS_UPCOMING_GET,
);


/**
 * Get upcoming walks for the current user success
 */
export type GetWalksUpcomingSuccessAction = {
  type: typeof WALKS_UPCOMING_GET_SUCCESS,
  payload: GetWalksUpcomingParsedResponse,
}

export const getWalksUpcomingSuccess: (
  GetWalksUpcomingParsedResponse,
) => GetWalksUpcomingSuccessAction = createAction(
  WALKS_UPCOMING_GET_SUCCESS,
);


/**
 * Get upcoming walks for the current user failure
 */
export type GetWalksUpcomingFailureAction = {
  type: typeof WALKS_UPCOMING_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalksUpcomingFailure: (
  ErrorParsedResponse,
) => GetWalksUpcomingFailureAction = createAction(
  WALKS_UPCOMING_GET_FAILURE,
);
