import {
  ApiError,
  ApiErrorV4,
  ApiErrorV5,
  ApiErrorV6,
} from '../buildApiErrorPipeline';
import { createErrorForV4 } from '../createErrorForV4';
import { createErrorForV5 } from '../createErrorForV5';
import { createErrorForV6 } from '../createErrorForV6';

type ErrorTypeMapping = Record<string, any>;

const mapError = (
  error: ApiError,
  errorTypeMapping: ErrorTypeMapping,
) => <ApiErrorVersion>(
  mapperFn: (
    error: ApiErrorVersion,
    errorTypeMapping: ErrorTypeMapping
  ) => ApiError,
) => mapperFn(error as unknown as ApiErrorVersion, errorTypeMapping);

// Error mapper for V4 endpoints
const mapErrorForV4 = (error: ApiErrorV4, errorTypeMapping: ErrorTypeMapping) => {
  const {
    type: errorType,
  } = error;

  if (!errorType) {
    return error;
  }

  const errorFromTypeMapping = errorTypeMapping[errorType];

  if (!errorFromTypeMapping) {
    return error;
  }

  const errorResponse = {
    ...errorFromTypeMapping,
    detail: errorFromTypeMapping.message,
  };
  const v4Error = createErrorForV4(error, errorResponse);
  return v4Error;
};

// Error mapper for V5 endpoints
const mapErrorForV5 = (error: ApiErrorV5, errorTypeMapping: ErrorTypeMapping) => {
  const errorType = error.response && error.response.type;

  if (!errorType) {
    return error;
  }

  const errorFromTypeMapping = errorTypeMapping[errorType];

  if (!errorFromTypeMapping) {
    return error;
  }

  const errorResponse = {
    ...errorFromTypeMapping,
    detail: errorFromTypeMapping.message,
  };
  const v5Error = createErrorForV5(error, errorResponse);
  return v5Error;
};

// Error mapper for V6 endpoints
const mapErrorForV6 = (error: ApiErrorV6, errorTypeMapping: ErrorTypeMapping) => {
  const errorType = error && error.type;

  if (!errorType) {
    return error;
  }

  const errorFromTypeMapping = errorTypeMapping[errorType];

  if (!errorFromTypeMapping) {
    return error;
  }

  const errorResponse = {
    ...errorFromTypeMapping,
    detail: errorFromTypeMapping.message,
  };
  const v6Error = createErrorForV6(error, errorResponse);

  if (error.email) {
    /*
      Special case for 409 returned from social auth endpoints
      for facebook, apple and google which behaves in a
      similar manner to a response from a 200 level call
    */
    v6Error.email = error.email;
  }

  return v6Error;
};

export const mapErrorByType = (errorTypeMapping: ErrorTypeMapping) => (
  error: ApiError,
) => {
  if (!errorTypeMapping) {
    return;
  }

  const mapErrorFn = mapError(error, errorTypeMapping);

  if (error.version === 4) {
    return mapErrorFn<ApiErrorV4>(mapErrorForV4);
  }

  if (error.version === 5) {
    return mapErrorFn<ApiErrorV5>(mapErrorForV5);
  }

  if (error.version === 6) {
    return mapErrorFn<ApiErrorV6>(mapErrorForV6);
  }

  return error;
};
