
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';

import {
  OWNER_PASSWORD_RESET_EMAIL_SEND,
  OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
  OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
} from '../../actionTypes';


/**
 * Send owner password reset email
 */
export type SendOwnerPasswordResetEmailPayload = {
  email: string,
};

export type SendOwnerPasswordResetEmailAction = {
  type: typeof OWNER_PASSWORD_RESET_EMAIL_SEND,
  payload: SendOwnerPasswordResetEmailPayload,
};

export const sendOwnerPasswordResetEmail: (
  email: string,
) => SendOwnerPasswordResetEmailAction = createAction(
  OWNER_PASSWORD_RESET_EMAIL_SEND,
  (email: string) => ({ email }),
);


/**
 * Send owner password reset email success
 */
export type SendOwnerPasswordResetEmailSuccessAction = {
  type: typeof OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
}

export const sendOwnerPasswordResetEmailSuccess: ()
=> SendOwnerPasswordResetEmailSuccessAction = createAction(
  OWNER_PASSWORD_RESET_EMAIL_SEND_SUCCESS,
);


/**
 * Send owner password reset email failure
 */
export type SendOwnerPasswordResetEmailFailureAction = {
  type: typeof OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
  payload: ErrorParsedResponse,
}

export const sendOwnerPasswordResetEmailFailure: (
  error: ErrorParsedResponse,
) => SendOwnerPasswordResetEmailFailureAction = createAction(
  OWNER_PASSWORD_RESET_EMAIL_SEND_FAILURE,
);
