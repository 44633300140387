
import { createAction } from 'redux-actions';
import type {
  BookingCancelScheduleParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  BOOKING_CANCEL_SCHEDULE,
  BOOKING_CANCEL_SCHEDULE_SUCCESS,
  BOOKING_CANCEL_SCHEDULE_FAILURE,
} from '../../actionTypes';

export type BookingCancelScheduleParams = {
  scheduleID: number,
  mode: number,
  date?: string,
  cancellationReason?: string,
  day?: number,
};

export type BookingCancelSchedulePayload = BookingCancelScheduleParams;

export type BookingCancelScheduleAction = {
  type: typeof BOOKING_CANCEL_SCHEDULE,
  payload: BookingCancelSchedulePayload,
};

export type BookingCancelScheduleSuccessAction = {
  type: typeof BOOKING_CANCEL_SCHEDULE_SUCCESS,
  payload: BookingCancelScheduleParsedResponse,
};

export type BookingCancelScheduleFailureAction = {
  type: typeof BOOKING_CANCEL_SCHEDULE_FAILURE,
  payload: ErrorParsedResponse,
};

export const bookingCancelSchedule: (
  BookingCancelScheduleParams
) => BookingCancelScheduleAction = createAction(
  BOOKING_CANCEL_SCHEDULE,
  ({
    scheduleID,
    mode,
    date,
    cancellationReason,
    day,
  }) => ({
    scheduleID,
    mode,
    date,
    cancellationReason,
    day,
  }),
);

export const bookingCancelScheduleSuccess: (
  BookingCancelScheduleParsedResponse,
) => BookingCancelScheduleSuccessAction = createAction(
  BOOKING_CANCEL_SCHEDULE_SUCCESS,
);

export const bookingCancelScheduleFailure: (
  ErrorParsedResponse,
) => BookingCancelScheduleFailureAction = createAction(
  BOOKING_CANCEL_SCHEDULE_FAILURE,
);
