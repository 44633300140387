import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';

import {
  Auth,
  ENDPOINT_OWNER_RESET_PASSWORD_EMAIL,
  Endpoint,
} from '../../../constants';

import { OWNER_PASSWORD_RESET_EMAIL_SEND } from '../../../actions/actionTypes';

import { getOwnerToken } from '../../../selectors';

import {
  sendOwnerPasswordResetEmailSuccess,
  sendOwnerPasswordResetEmailFailure,
  SendOwnerPasswordResetEmailAction,
} from '../../../actions/owner/sendOwnerPasswordResetEmail';

import { wagApi } from '../../../types/wagapi';

const sendOwnerPasswordResetEmail$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(OWNER_PASSWORD_RESET_EMAIL_SEND),
    switchMap(({
      payload: {
        email,
      },
    }: SendOwnerPasswordResetEmailAction) =>
      post$({
        endpoint: ENDPOINT_OWNER_RESET_PASSWORD_EMAIL,
        payload: {
          email,
          source: 'web',
          userRoleType: Auth.RoleType.Owner,
        },
        token: getOwnerToken(state$.value),
      } as {
        endpoint: Endpoint;
        payload: wagApi.SendPasswordResetEmailRequestPayload;
        token: string;
      }).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: sendOwnerPasswordResetEmailSuccess,
            onFailure: sendOwnerPasswordResetEmailFailure,
          })
        ),
        catchError(
          handleErrorV5Response(action$, sendOwnerPasswordResetEmailFailure)
        )
      )
    )
  );

export default sendOwnerPasswordResetEmail$;
