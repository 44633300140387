
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
} from 'webApp';
import {
  OWNER_HOME_ACCESS_GET,
  OWNER_HOME_ACCESS_GET_FAILURE,
  OWNER_HOME_ACCESS_GET_SUCCESS,
} from '../../actionTypes';

/**
 * Get an owner's default home access
 */

export type OwnerHomeAccessGetAction = {
  type: typeof OWNER_HOME_ACCESS_GET,
};

export const getOwnerHomeAccess: () => OwnerHomeAccessGetAction = createAction(
  OWNER_HOME_ACCESS_GET,
);

/**
 * Get owner's home access Success
 */
export type OwnerHomeAccessGetSuccessAction = {
  type: typeof OWNER_HOME_ACCESS_GET_SUCCESS
};

export const getOwnerHomeAccessSuccess: () => OwnerHomeAccessGetSuccessAction = createAction(
  OWNER_HOME_ACCESS_GET_SUCCESS,
);


/**
 * Get owner's home access Failure
 */
export type OwnerHomeAccessGetFailureAction = {
  type: typeof OWNER_HOME_ACCESS_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerHomeAccessFailure: (
  ErrorParsedResponse,
) => OwnerHomeAccessGetFailureAction = createAction(
  OWNER_HOME_ACCESS_GET_FAILURE,
);
