import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

type Props = SvgIconProps;

export const WalkServiceIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 64 64"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 0h64v64H0z" />
      <path
        opacity=".201"
        d="M4 4h56v56H4z"
      />
      <g transform="translate(9 4)">
        <g fillRule="nonzero">
          <path
            d="M34.18 37.573s1.176 5.071 3.761 5.342c2.586.27 4.748.082 6.23 1.128.447.296.834.675 1.139 1.116 0 0 0-2.35-1.44-2.997-1.44-.646-4.195-.587-4.554-2.039-.358-1.451-.91-4.354-.658-4.953.253-.6-5.053-.077-4.477 2.403zM20.742 49.26s-1.963.277-2.75-.534c-.787-.81-.353-2.632-.834-3.76-.647-1.5-4.031-4.942-4.031-4.942l4.389-.453a42.896 42.896 0 011.651 4.243c.447 1.58-.587 2.773-.14 3.878a3.103 3.103 0 001.715 1.569zM7.732 33.031s-2.667-.2-4.113-1.11C2.003 30.915.223 27.424.223 27.424s.534 4.366 2.133 5.876c1.598 1.51 4.277 1.998 4.277 1.998l1.1-2.268zM30.062 40.47a38.054 38.054 0 01-2.715 5.106 15.225 15.225 0 005.77-1.762 9.214 9.214 0 01-3.055-3.344z"
            fill="#000"
          />
          <path
            d="M38.91 34.576c-.093-2.832-1.521-9.83-1.521-9.83l-12.34 1.868s-2.021 2.68-4.754 3.526c-2.732.846-10.036.84-11.94 1.763-1.622.77-3.978 3.702-2.938 6.857 1.04 3.156 6.335 2.768 6.64 2.11.259-.54.652-.658 2.35-.147 1.699.511 7.816 4.454 9.514 4.818 1.125.184 2.27.208 3.402.07a38.054 38.054 0 002.715-5.106 9.214 9.214 0 003.056 3.367c.64-.348 1.247-.753 1.815-1.21 1.44-1.164 4.096-5.247 4.002-8.086z"
            fill="#2E2829"
          />
          <path
            d="M13.203 38.936s-.905 7.093-4.83 7.263c-2.78.112-3.573.382-4.296.952-.722.57-.746 1.575-3.096 1.516 0 0 .805-.417 1.533-2.474.729-2.056 1.434-2.526 2.521-2.65 1.087-.123.406-3.331.265-5.288-.141-1.957 6.898-3.937 7.903.681zM34.24 39.548s-2.803 7.05-3.609 9.202a4.213 4.213 0 00.388 3.778s-2.045-.282-2.35-3.114c-.306-2.833.235-4.208-.112-6.546-.346-2.34 2.445-6.981 5.682-3.32z"
            fill="#2E2829"
          />
        </g>
        <ellipse
          fill="#FAAB43"
          transform="rotate(30 42.156 32.782)"
          cx="42.156"
          cy="32.782"
          rx="2.727"
          ry="1.704"
        />
        <path
          d="M23.973 27.698c2.04 1.64 3.806 2.734 5.3 3.284 2.24.826 2.702.946 4.864.911 1.441-.023 2.878-.636 4.311-1.838l.351 2.432c-1.314 1.222-2.744 1.833-4.29 1.833-2.317 0-3.445-.08-5.983-1.201-2.538-1.122-5.43-3.544-5.998-4.275.516-.407.997-.789 1.445-1.146z"
          fill="#7786B7"
        />
        <path
          d="M25.606 13.503s-4.74-5.19-8.291-6.39c-3.552-1.202-2.769.383-2.491 1.863.277 1.481 4.056 11.827 5.33 12.563 1.274.735 5.452-8.036 5.452-8.036"
          fill="#2D2829"
        />
        <path
          d="M25.606 13.503s-4.74-5.19-8.291-6.39c-3.552-1.202-2.484.8-1.152 1.504 1.951 1.033 4.917 4.066 7.3 7.36 1.217-1.347 2.143-2.474 2.143-2.474M29.787 11.646s-.406-3.7.313-5.926c.72-2.226 2.288-3.962 4.034-.124 1.746 3.839.734 5.833 1.339 6.749.59.895 1.29 1.76 1.29 1.76l-6.976-2.459z"
          fill="#000"
        />
        <path
          d="M35.017 31.437c.88-.267 2.137-2.068 2.888-2.389.752-.321 1.928-.397 2.608-.759.681-.361 1.198-1.557 1.198-1.557s.555.292 1.195-.154c.666-.463.703-2.586.18-3.643-.522-1.056-2.012-2.153-2.28-3.157-.27-1.004-.41-3.279-1.052-4.015-.64-.735-2.377-2.304-4.491-3.052-2.115-.75-4.509-1.424-5.625-1.125-1.116.299-2.098.735-2.098.735l-3.83 5.937 5.005 11.757 2.918 1.127s2.505.563 3.384.295"
          fill="#FFF"
        />
        <path
          d="M32.4 31.1c.909-1.186-.755-3.629-1.1-4.825-.514-1.78-.686-3.87-.08-5.654.226-.668.89-1.588.883-2.298-.007-.921-.317-2.016-.631-2.88-.513-1.409-1.585-2.578-2.69-3.616-1.448.394-2.75 1.09-3.459 1.513-1.09.65-4.898 5.38-5.141 7.598-.243 2.219 2.09 4.225 3.297 6.055 1.208 1.83 5.647 4.108 6.964 4.115.49.002 1.154.09 1.838.183a1.15 1.15 0 01.118-.19"
          fill="#000"
        />
        <path
          d="M40.195 15.886c.57.941.517 2.017-.118 2.4-.635.384-1.611-.067-2.181-1.01-.57-.941-.516-2.016.119-2.4.635-.384 1.611.068 2.18 1.01M32.006 18.637c.36 1.34-.549 2.749-2.028 3.145-1.48.397-2.97-.369-3.33-1.71-.359-1.34.55-2.75 2.029-3.146 1.479-.396 2.97.37 3.329 1.71"
          fill="#818080"
        />
        <path
          d="M31.036 19.038c.17.635-.36 1.327-1.186 1.548-.825.22-1.631-.113-1.802-.747-.17-.634.362-1.327 1.187-1.548.825-.221 1.632.114 1.801.747"
          fill="#000"
        />
        <path
          d="M30.468 18.756c.053.198-.112.414-.37.483-.258.07-.51-.035-.563-.233-.053-.198.114-.414.37-.483.259-.069.51.035.563.233"
          fill="#FFF"
        />
        <path
          d="M40.309 16.288a1.063 1.063 0 01-.743 1.308 1.063 1.063 0 01-1.298-.761 1.064 1.064 0 01.743-1.308 1.064 1.064 0 011.298.761"
          fill="#000"
        />
        <path
          d="M39.82 16.048c.04.15-.084.312-.279.364-.194.052-.384-.026-.424-.176-.04-.149.086-.312.28-.364.194-.052.384.027.424.176"
          fill="#FFF"
        />
        <path
          d="M35.864 17.633c1.13-.35 2.098.213 2.779.826.634.571 1.102 1.603 1.504 2.213.403.61 1.241 1.283 1.774 2.157.534.873 1.236 3.7.823 3.88-1.689.738-1.125-1.497-2.35-1.322-.554.079-2.616 2.66-3.746 3.46-.996.706-4.483 1.772-4.483 1.772s3.407-1.486 4.19-2.54c.515-.695.809-1.563.827-2.314.028-1.146-.315-1.84-1.331-3.087-.975-1.195-1.853-1.347-2.054-2.936-.084-.668.746-1.699 2.067-2.11"
          fill="#818080"
        />
        <path
          d="M38.56 19.158c.09.567-.679 1.16-1.72 1.326-1.039.165-1.955-.16-2.046-.727-.09-.568.68-1.162 1.72-1.327 1.04-.166 1.956.16 2.046.728"
          fill="#000"
        />
        <g fillRule="nonzero">
          <path
            d="M42.78 25.583c-.568-.383-.759-.223-1.262-1.738-.331.371-.874-.046-1.276.21-.493.358-.94.776-1.33 1.242.029.002-.361-.413-.333-.388.95.956.963 1.945 1.187 2.28.139.228.33.418.56.554a2.386 2.386 0 002.04.024c.376-.168.687-.453.89-.812.345-.535.094-1.007-.476-1.372z"
            fill="#AC676D"
          />
          <path
            d="M41.15 24.008a.834.834 0 00-.946.098c-.538.378-1.083.567-.13.8.953.232 1.989 2.022 1.989 2.022a10.213 10.213 0 01-.914-2.672c-.032-.62.001-.248.001-.248z"
            fill="#322631"
            opacity=".5"
          />
        </g>
        <path
          d="M41.039 23.162a8.16 8.16 0 00-2.03-.764s.847.92.361 1.833c-.189.344-1.265 2.232-1.45 2.648-.484.936-1.367 1.639-2.428 1.932 1.184-.085 2.227-.787 2.696-1.815.575-1.007 1.316-2.156 2.048-2.605.622-.382 1.234-.274 1.42-.009.42-.384-.537-1.097-.617-1.22z"
          fill="#241F20"
          fillRule="nonzero"
        />
      </g>
    </g>
  </SvgIcon>
);
