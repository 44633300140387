import React from 'react';

import { theme } from '@waglabs/base-theme';
import {
  Chip,
  Props as ChipProps,
} from '@waglabs/chip';
import { Grid } from '@waglabs/grid';
import { Image } from '@waglabs/image-next';
import { Typography } from '@waglabs/typography';

import { DataTestIds } from '../../constants';

export type DirectBookWalkerChipProps = {
  value: {
    name: string;
    imageUrl: string;
  };
} & Omit<ChipProps, 'value'>;

export const DirectBookWalkerChip = ({
  value,
  ...rest
}: DirectBookWalkerChipProps) => (
  <Chip
    {...{
      as: 'aside',
    }}
    gridGap="8px"
    backgroundColor={theme.colors.backgroundGray}
    alignItems="center"
    pl="8px"
    pr="48px"
    py="8px"
    border="unset"
    data-testid={DataTestIds.Components.BookWalkerChip}
    {...rest}
  >
    <Image
      src={value.imageUrl}
      alt={value.name}
      loading="lazy"
      width="36px"
      height="36px"
      borderRadius="50%"
    />
    <Grid>
      <Typography
        fontSize={['12px', '14px']}
        m={0}
      >
        Requesting booking with:
      </Typography>
      <Typography
        fontFamily="muliBold"
        fontSize={['14px', '16px']}
        m={0}
      >
        {value.name}
      </Typography>
    </Grid>
  </Chip>
);
