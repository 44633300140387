import { wagApi } from '../../../types/wagapi';
import { webApp } from '../../../types/webapp';

import { SIGN_UP_BY_GOOGLE_MULTIPLE_ACCOUNTS_ERROR } from '../../../constants/errors';

export const registerOwnerGoogleSigninResponseParser = (
  response: wagApi.OwnerGoogleSigninResponse
): webApp.OwnerGoogleSigninParsedResponse => {
  // If by any chance, the parser should throw an error, and
  // catch it on the catchError stream on the epic
  if (response.multiple_accounts) {
    throw new Error(SIGN_UP_BY_GOOGLE_MULTIPLE_ACCOUNTS_ERROR);
  }

  const {
    token,
    owner: {
      id,
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
    },
  } = response;

  return {
    ownerID: String(id),
    ownerToken: token,
    firstName,
    lastName,
    email,
    phone,
  };
};
