import { switchMap, catchError, flatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { SignInWalkerRequestPayload } from "wagAPI";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_SIGN_IN, Endpoint } from "../../../constants/endpoints";
import { WALKER_SIGN_IN } from "../../../actions/actionTypes";
import { signInWalkerSuccess, signInWalkerFailure, SignInWalkerAction } from "../../../actions/walker/signInWalker";
import { signInWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const signInWalker$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(WALKER_SIGN_IN), switchMap(({
  payload: {
    email,
    password,
    source,
    willUpdatePassword = false
  }
}: SignInWalkerAction) => post$(({
  endpoint: ENDPOINT_WALKER_SIGN_IN,
  payload: {
    email,
    password,
    source,
    generate_password_reset_jwt: willUpdatePassword
  }
} as {
  endpoint: Endpoint;
  payload: SignInWalkerRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: signInWalkerSuccess,
  onFailure: signInWalkerFailure,
  parser: signInWalkerResponseParser
})), catchError(handleErrorV5Response(action$, signInWalkerFailure)))));

export default signInWalker$;