import { catchError, flatMap, retry, switchMap } from "rxjs/operators";
import { ofType, Action$, State$, Dependencies } from "redux-observable";
import type { SubscribeToPremiumRequestPayload } from "wagAPI";
import { SUBSCRIBE_TO_PREMIUM } from "../../../actions/actionTypes";
import { subscribeToPremiumSuccess, subscribeToPremiumFailure } from "../../../actions/owner";
import { getOwnerToken } from "../../../selectors";
import { ENDPOINT_SUBSCRIBE_TO_PREMIUM, Endpoint } from "../../../constants/endpoints";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { subscribeToPremiumResponseParser } from "../../../helpers/Http/ResponseParsers";
export const subscribeToPremium$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(SUBSCRIBE_TO_PREMIUM), retry(3), switchMap(() => post$(({
  endpoint: ENDPOINT_SUBSCRIBE_TO_PREMIUM,
  payload: {
    is_subscribed: true,
    will_auto_renew: true
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: SubscribeToPremiumRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: subscribeToPremiumSuccess,
  onFailure: subscribeToPremiumFailure,
  parser: subscribeToPremiumResponseParser
})), catchError(handleErrorV5Response(action$, subscribeToPremiumFailure)))));
export default subscribeToPremium$;