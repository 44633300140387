
import { createAction } from 'redux-actions';
import type {
  GetAvailableServicesParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_AVAILABLE_SERVICES,
  OWNER_AVAILABLE_SERVICES_FAILURE,
  OWNER_AVAILABLE_SERVICES_SUCCESS,
} from '../../actionTypes';


/**
 * Get owner services
 */
export type GetAvailableServicesAction = {
  type: typeof OWNER_AVAILABLE_SERVICES,
}

export const getAvailableServices: () =>
  GetAvailableServicesAction = createAction(
    OWNER_AVAILABLE_SERVICES,
  );

/**
 * Get owner services success
 */
export type GetAvailableServicesSuccessAction = {
  type: typeof OWNER_AVAILABLE_SERVICES_SUCCESS,
  payload: GetAvailableServicesParsedResponse,
};

export const getAvailableServicesSuccess: (
  GetAvailableServicesParsedResponse,
) => GetAvailableServicesSuccessAction = createAction(
  OWNER_AVAILABLE_SERVICES_SUCCESS,
);

/**
 * Get owner services failure
 */
export type GetAvailableServicesFailureAction = {
  type: typeof OWNER_AVAILABLE_SERVICES_FAILURE,
  payload: ErrorParsedResponse,
};

export const getAvailableServicesFailure: (
  ErrorParsedResponse,
) => GetAvailableServicesFailureAction = createAction(
  OWNER_AVAILABLE_SERVICES_FAILURE,
);
