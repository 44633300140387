export type Props = {
  [key: string]: unknown;
  id: number;
}[];

export const arrayToObject = (arr: Readonly<Props>) => arr.reduce((accumulator, currentValue) => {
  if (typeof currentValue.id !== 'number') {
    return accumulator;
  }

  accumulator[currentValue.id] = currentValue;
  return accumulator;
}, {} as Record<string, unknown>);
