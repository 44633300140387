import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { ProviderService as ProviderServiceType } from "webApp";
import { getServiceProviders } from "../../actions/owner/getServiceProviders";
import { getOwnerServiceProvider } from "../../reducers/owner/owner.selectors";
import { maybe } from "../../utils/monads";
import { getRequestStates } from "../../selectors";
import { SERVICE_PROVIDERS_GET_REQUEST_KEY } from "../../constants";
export const useServiceProvider = (providerService: ProviderServiceType) => {
  const serviceProvider = useSelector(getOwnerServiceProvider);

  // Point-free
  const getOwnerServiceProviderRequestState = state => getRequestStates(state, SERVICE_PROVIDERS_GET_REQUEST_KEY);

  const {
    error,
    isFetching,
    isSuccess
  } = useSelector(getOwnerServiceProviderRequestState);
  const dispatch = useDispatch();
  const nullableServiceProvider = maybe({
    profiles: []
  });
  useEffect(() => {
    dispatch(getServiceProviders({
      service: providerService
    }));
  }, [dispatch, providerService]);
  return {
    data: nullableServiceProvider(serviceProvider),
    error,
    isLoading: isFetching,
    isSuccess
  };
};