import debounce from 'debounce-promise';

import { useLazyGetPetBreedsQuery } from '../slices/breeds/v6';

export const useGetPetBreedsAutocomplete = () => {
  const [getPetBreedsQuery, {
    isLoading,
    isError,
    isSuccess,
  }] = useLazyGetPetBreedsQuery();

  const getOptions = debounce(async (inputValue: string) => {
    const response = await getPetBreedsQuery({
      ...inputValue && {
        name: inputValue,
        nameSearchMethod: 'substring',
      },
    }).unwrap();

    const items = response.items.map((item) => ({
      label: item.name,
      value: item.name,
      extras: {
        type: item.type,
      },
    }));

    return items;
  }, 300);

  return {
    execute: getOptions,
    isLoading,
    isError,
    isSuccess,
  };
};
