import { handleActions } from 'redux-actions';
import type { VerifyPasswordParsedResponse } from 'webApp';

import {
  PASSWORD_TIPS_SUCCESS,
  VERIFY_PASSWORD_SUCCESS,
} from '../../actions/actionTypes';

export type PasswordVerification = VerifyPasswordParsedResponse;
export type PasswordTips = string[];
export type PasswordState = {
  tips: PasswordTips;
  verification: PasswordVerification;
};
export const initialState: PasswordState = {
  tips: [],
  verification: {},
};
const reducer = handleActions<PasswordState, Record<string, any>>({
  [VERIFY_PASSWORD_SUCCESS]: (state: PasswordState, {
    payload,
  }) => ({ ...state,
    verification: payload }),
  [PASSWORD_TIPS_SUCCESS]: (state: PasswordState, {
    payload: {
      tips,
    },
  }) => ({ ...state,
    tips }),
}, initialState);
export default reducer;
