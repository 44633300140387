import type { ReduxAction } from "redux";
import type { ApiV5Response } from "wagAPI";
export const handleWagApiV5Response = ({
  response: {
    response,
    status
  },
  onSuccess,
  onFailure,
  parser
}: {
  response: {
    response: ApiV5Response;
    status: number;
  };
  onSuccess: ReduxAction;
  onFailure: ReduxAction;
  parser?: (arg0: any) => any;
}) => {
  if (status < 400) {
    if (parser) {
      return [onSuccess(parser(response))];
    }

    return [onSuccess(response)];
  }

  return onFailure(response.error);
};