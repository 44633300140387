export const ENDPOINT_AUTHENTICATION_CODE_POST = 'api/v5/authentication/code';
export const ENDPOINT_AUTHENTICATION_PASSWORD_POST = 'api/v5/authentication/password';
export const ENDPOINT_AUTHENTICATION_SESSION_GET = 'api/v5/authentication/session';
export const ENDPOINT_AUTHENTICATION_SESSION_POST = 'api/v5/authentication/session';
export const ENDPOINT_AUTHENTICATION_UPGRADE_POST = 'api/v5/authentication/upgrade';
export const ENDPOINT_AUTHENTICATION_VERIFY_POST = 'api/v5/authentication/verify';
export const ENDPOINT_AUTHENTICATION_TWO_FACTOR_PASSPORT_POST = 'api/v5/authentication/two-factor-password';
export const ENDPOINT_BOOKING_ADD_SITTING_SCHEDULE = 'dog/add_sitting_schedule';
export const ENDPOINT_BOOKING_CANCEL_SCHEDULE = 'dog/cancelschedule';
export const ENDPOINT_BOOKING_MEET_GREET = 'dog/addschedule';
export const ENDPOINT_BOOKING_SET_SCHEDULE = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/booking`;
export const ENDPOINT_CREATE_VISITOR_ACCOUNT = 'owner/get_signup_flow';
export const ENDPOINT_CREDIT_PACKAGES = 'owner/packages';
export const ENDPOINT_DOG_BREEDS = 'dog/common-breeds';
export const ENDPOINT_DOG_BREED_ASSETS = 'api/attributes/v1/autocomplete/breeds';
export const ENDPOINT_DOG_CREATE_V5 = ({ ownerID }: { ownerID: string }) => `api/v5/owner/${ownerID}/dog`;
export const ENDPOINT_DOG_DELETE = (dogID: string) => `api/v4/dog/${dogID}`;
export const ENDPOINT_DOG_PROFILE_PATCH = ({
  ownerID,
  dogID,
}: {
  ownerID: string;
  dogID: number;
}) => `api/v5/owner/${ownerID}/dog/${dogID}`;
export const ENDPOINT_DOG_QUESTIONAIRE = 'owner/dog_manager_questionnaire';

export const ENDPOINT_DOG_UPDATE_V4 = ({ dogID }: { dogID: string }) => `api/v4/dog/${dogID}`;
export const ENDPOINT_ESTIMATE_OVERNIGHT = 'dog/calculate_sitting_price';
export const ENDPOINT_LOGIN_WITH_CODE_REQUEST_CREATE = 'api/v5/auth/partner/19cf2144-604f-4cfe-ae86-0eae20f364de/login_request';
export const ENDPOINT_LOGIN_WITH_CODE_SUBMIT = 'api/v5/auth/partner/19cf2144-604f-4cfe-ae86-0eae20f364de/login_request';
export const ENDPOINT_OWNER_ADDRESS_V2_REGISTER = 'api/v5/owner/signup/address';
export const ENDPOINT_OWNER_ADD_PREFERRED_WALKER = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/add-preferred-walker`;
export const ENDPOINT_OWNER_APPLY_DETAILS_REGISTER = 'api/v5/owner/signup/apply_details';
export const ENDPOINT_OWNER_AUTH_REFRESH = 'api/v5/auth/owner';
export const ENDPOINT_OWNER_AVAILABLE_SERVICES_V5 = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/available_services`;
export const ENDPOINT_OWNER_CHANGE_PASSWORD = 'owner/changepassword';
export const ENDPOINT_OWNER_CREDIT_GET = 'owner/checkcredit';
export const ENDPOINT_OWNER_GET = 'api/owner/full';
export const ENDPOINT_OWNER_GOOGLE_SIGNIN_REGISTER = 'api/v6/authentication/social/google';
export const ENDPOINT_OWNER_HOME_ACCESS_GET = 'owner/home_access';
export const ENDPOINT_OWNER_HOME_ACCESS_UPDATE = 'owner/home_access';
export const ENDPOINT_OWNER_LOGIN = 'api/v5/auth/owner';
export const ENDPOINT_OWNER_MASKED_INFO_GET = 'api/v5/auth/partner/19cf2144-604f-4cfe-ae86-0eae20f364de/login_request';
export const ENDPOINT_OWNER_PAYMENT_METHODS_GET = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/payment_method`;
export const ENDPOINT_OWNER_PAYMENT_METHOD_CREATE = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/payment_method`;
export const ENDPOINT_OWNER_PAYMENT_METHOD_UPDATE = ({
  id,
  ownerID,
}: {
  id: string;
  ownerID: string;
}) => `api/v5/owner/${ownerID}/payment_method/${id}`;
export const ENDPOINT_OWNER_PREFERRED_SERVICES = 'api/v5/owner/signup/services';
export const ENDPOINT_OWNER_PREFERRED_WALKER_CREATE = ({
  ownerID,
  walkerID,
}: {
  ownerID: string;
  walkerID: string;
}) => `api/v4/owner/${ownerID}/walker/${walkerID}/preferred`;
export const ENDPOINT_OWNER_PREFERRED_WALKER_DELETE = ({
  ownerID,
  walkerID,
}: {
  ownerID: string;
  walkerID: string;
}) => `api/v4/owner/${ownerID}/walker/${walkerID}/preferred`;
export const ENDPOINT_OWNER_PREMIUM_SUBSCRIPTION_GET = 'api/v5/owner/premium';
export const ENDPOINT_OWNER_REGISTER = 'api/v5/owner';
export const ENDPOINT_OWNER_REGISTRATION_DOG_INFO = 'api/v5/owner/signup/dogs';
export const ENDPOINT_OWNER_RESET_PASSWORD = 'owner/reset-password';
export const ENDPOINT_OWNER_RESET_PASSWORD_EMAIL = 'api/v5/authentication/reset-password-email';
export const ENDPOINT_OWNER_SERVICES_GET = ({ ownerID }: { ownerID: string }) => `api/v5/owner/${ownerID}/walk`;
export const ENDPOINT_OWNER_SMART_MODULES_V5 = ({
  ownerID,
}: {
  ownerID: string;
}) => `api/v5/owner/${ownerID}/modules`;
export const ENDPOINT_OWNER_UPDATE = ({ ownerID }: { ownerID: string }) => `api/v5/owner/${ownerID}`;
export const ENDPOINT_PASSWORD_TIPS = 'walker/password-tips';
export const ENDPOINT_PAST_PET_CAREGIVERS_GET = 'api/v5/owner/past_pet_caregivers';
export const ENDPOINT_PROMO_CODE = ({ ownerID }: { ownerID: string }) => `api/v5/owner/${ownerID}/promo_code`;
// Tyson suggested to use this endpoint for rebooking. https://wagsters.slack.com/archives/CVAQX55BR/p1587452136126700
export const ENDPOINT_REBOOKING = ({ ownerID }: { ownerID: string }) => `/api/v4/owner/${ownerID}/booking_request`;
export const ENDPOINT_REQUEST_LOCKBOX = 'api/owner/requestlockbox';

export const ENDPOINT_SERVICE_PROVIDERS_GET = ({
  ownerID,
  service,
}: {
  ownerID: string;
  service: string;
}) => `api/v4/owner/${ownerID}/providers/${service}`;
export const ENDPOINT_SITTING_QUESTIONNAIRE = 'owner/sitting_questionnaire';
export const ENDPOINT_WALKER_STRIPE_CONNECT_GET_DASHBOARD_URL = 'rest/v4/walker/stripe/login_link';
export const ENDPOINT_SUBSCRIBE_TO_PREMIUM = 'api/v5/owner/premium';

export const ENDPOINT_TRAINING_BOOKING = ({ ownerID }: { ownerID: string }) => `/api/v5/owner/${ownerID}/booking/training`;
export const ENDPOINT_UPDATE_CREDIT_CARD = 'owner/addcardtoken';
export const ENDPOINT_UPDATE_PREFERRED_WALKER = '/walker/setpreferredwalker';
export const ENDPOINT_VERIFY_PASSWORD = 'walker/password-verify';
export const ENDPOINT_WALKER = 'api/walker/full';
export const ENDPOINT_WALKER_CONTACT_INFO = 'dog/walker_contact_info';
export const ENDPOINT_WALKER_PASSWORD_UPDATE = ({
  walkerID,
}: {
  walkerID: string;
}) => `rpc/v4/walker/${walkerID}/password-reset`;
export const ENDPOINT_WALKER_PROFILE = (walkerID: string) => `walker/${walkerID}/profile`;
export const ENDPOINT_WALKER_PROFILE_BY_PROMO_CODE_GET = ({
  promoCode,
}: {
  promoCode: string;
}) => `/pet-care-provider/${promoCode}/profile`;
export const ENDPOINT_WALKER_RESET_PASSWORD = 'walker/reset-password';
export const ENDPOINT_WALKER_SCHEDULE_GET = 'walker/getwalk';
export const ENDPOINT_WALKER_SIGN_IN = 'walker/login';
export const ENDPOINT_WALKER_STRIPE_CONNECT_GET_REDIRECT_URL = 'rest/v4/walker/stripe/connect';
export const ENDPOINT_WALKER_STRIPE_CONNECT_VERIFY_TOKEN = 'rest/v4/walker/stripe/activate';
export const ENDPOINT_WALKER_VERIFY_MIGRATION = 'rest/v4/walker/stripe/migrated';
export const ENDPOINT_WALKER_WALK_COVER_CREATE = 'walker/create_covers_for_walks';
export const ENDPOINT_WALKS = '/walks';
export const ENDPOINT_WALKS_IN_PENDING_REVIEW = 'walk/nonreviewedwalks';
export const ENDPOINT_WALKS_IN_PROGRESS = 'dog/walkinprogress';
export const ENDPOINT_WALKS_IN_THE_PAST = 'api/owner/walk-history-with-loc';
export const ENDPOINT_WALK_COORDINATES = 'walk/location';
export const ENDPOINT_WALK_DETAILS_GET = ({
  ownerID,
  walkID,
}: {
  ownerID: string;
  walkID: string;
}) => `/api/v5/owner/${ownerID}/walk/${walkID}`;
export const ENDPOINT_WALK_RATE_AND_TIP_V5 = (
  ownerID: string,
  walkID: string,
) => `api/v5/owner/${ownerID}/walk/${walkID}/rating`;
export const ENDPOINT_OWNER_SIGNUP_BASIC_INFO = 'api/v5/owner/signup/details';

export const ENDPOINT_PASSWORD_RESET_SESSION = 'api/v6/authentication/password-reset/session';
export const ENDPOINT_PASSWORD_RESET = 'api/v6/authentication/password-reset';

export enum EndpointSocialAuthentication {
  Google = 'api/v6/authentication/social/google',
  Facebook = 'api/v6/authentication/social/facebook',
  Apple = 'api/v6/authentication/social/apple',
}

// New routes should use this mapping for a better type safe
export const Endpoints = {
  Legacy: {
    GetWalkerProfile: 'pet-care-provider/{{promoCode}}/profile',
  },
  V4: {
    GetWalkerStripeLoginLink: 'rest/v4/walker/stripe/login_link',
    GetScheduleCostEstimate: 'api/v4/owner/schedule/estimate_price',
  },
  V5: {
    PostCreateDog: 'api/v5/owner/{{ownerId}}/dog',
    PostOwnerPaymentMethod: 'api/v5/owner/{{ownerId}}/payment_method',
    PostSubscribeToPremium: 'api/v5/owner/premium',
    PatchOwnerProfile: 'api/v5/owner/{{ownerId}}',
    PostOwnerSignupApplyDetails: 'api/v5/owner/signup/apply_details',
  },
  V6: {
    PostRegisterOwner: 'api/v6/owners',
    PostAuthenticationUpgrade: 'api/v6/authentication/upgrade',
    PostWellnessSubsription: 'api/v6/wellness_subscriptions',
    GetOwnerWellnessSubscriptions: 'api/v6/owners/{{ownerId}}/wellness-subscriptions',
    GetOwnerServices: 'api/v6/owners/{{ownerId}}/services',
    PutOwnerUpdateAddress: 'api/v6/owners/{{ownerId}}/address',
    PostOwnerPets: 'api/v6/owners/{{ownerId}}/pets',
    GetOwnerPets: 'api/v6/owners/{{ownerId}}/pets',
    PutWalkerUpdateGender: 'api/v6/walkers/{{walkerIdentifier}}/gender',
    PutWalkerUpdateAddress: 'api/v6/walkers/{{walkerIdentifier}}/address',
    PatchWalker: 'api/v6/walkers/{{walkerIdentifier}}',
    GetWalkerApplication: 'api/v6/walkers/{{walkerIdentifier}}/application',
    GetApplicationQuizStage: 'api/v6/walkers/{{walkerIdentifier}}/application/northpass',
    GetApplicationAboutMeStage: 'api/v6/walkers/{{walkerIdentifier}}/application/about-me',
    GetApplicationPaymentStage: 'api/v6/walkers/{{walkerIdentifier}}/application/stripe',
    GetApplicationBackgroundCheckStage: 'api/v6/walkers/{{walkerIdentifier}}/application/vetty',
    GetWalkerWagBusinessInfo: 'api/v6/walkers/{{walkerIdentifier}}/business',
    GetPremiumSubscriptionPlans: 'api/v6/owners/{{ownerId}}/premium-subscription-plans',
    PutSubscribeToPremium: 'api/v7/owners/{{ownerIdentifier}}/premium-subscription',
    OnboardingQuiz: 'api/v6/walkers/{{walkerIdentifier}}/application/onboarding-quiz',
    OnboardingQuizQuestions: 'api/v6/walkers/{{walkerIdentifier}}/onboarding-quiz/questions',
    OnboardingQuizAnswers: 'api/v6/walkers/{{walkerIdentifier}}/onboarding-quiz/answers',
    // media endpoints
    PostCreateMediaUploadUrl: 'api/v6/_operations/create-media-upload-url',

    // pets endpoints
    PatchPet: 'api/v6/pets/{{petIdentifier}}',
    PutPetImage: 'api/v6/pets/{{petIdentifier}}/image',

    // breeds endpoints
    GetPetBreeds: 'api/v6/breeds',
  },
  Mapbox: {
    V5: {
      Geocoding: 'https://api.mapbox.com/geocoding/v5/mapbox.places/{{query}}.json',
    },
  },
} as const;

export type Endpoint =
  | typeof ENDPOINT_BOOKING_CANCEL_SCHEDULE
  | typeof ENDPOINT_AUTHENTICATION_CODE_POST
  | typeof ENDPOINT_AUTHENTICATION_PASSWORD_POST
  | typeof ENDPOINT_AUTHENTICATION_SESSION_GET
  | typeof ENDPOINT_AUTHENTICATION_SESSION_POST
  | typeof ENDPOINT_AUTHENTICATION_UPGRADE_POST
  | typeof ENDPOINT_AUTHENTICATION_VERIFY_POST
  | typeof ENDPOINT_AUTHENTICATION_TWO_FACTOR_PASSPORT_POST
  | typeof ENDPOINT_BOOKING_MEET_GREET
  | typeof ENDPOINT_BOOKING_SET_SCHEDULE
  | typeof ENDPOINT_CREATE_VISITOR_ACCOUNT
  | typeof ENDPOINT_CREDIT_PACKAGES
  | typeof ENDPOINT_DOG_BREEDS
  | typeof ENDPOINT_DOG_CREATE_V5
  | typeof ENDPOINT_DOG_PROFILE_PATCH
  | typeof ENDPOINT_DOG_UPDATE_V4
  | typeof ENDPOINT_LOGIN_WITH_CODE_REQUEST_CREATE
  | typeof ENDPOINT_LOGIN_WITH_CODE_SUBMIT
  | typeof ENDPOINT_OWNER_ADDRESS_V2_REGISTER
  | typeof ENDPOINT_OWNER_ADD_PREFERRED_WALKER
  | typeof ENDPOINT_OWNER_APPLY_DETAILS_REGISTER
  | typeof ENDPOINT_OWNER_AUTH_REFRESH
  | typeof ENDPOINT_OWNER_AVAILABLE_SERVICES_V5
  | typeof ENDPOINT_OWNER_CHANGE_PASSWORD
  | typeof ENDPOINT_OWNER_CREDIT_GET
  | typeof ENDPOINT_OWNER_GET
  | typeof ENDPOINT_OWNER_GOOGLE_SIGNIN_REGISTER
  | typeof ENDPOINT_OWNER_LOGIN
  | typeof ENDPOINT_OWNER_MASKED_INFO_GET
  | typeof ENDPOINT_OWNER_PAYMENT_METHODS_GET
  | typeof ENDPOINT_OWNER_PAYMENT_METHOD_CREATE
  | typeof ENDPOINT_OWNER_PAYMENT_METHOD_UPDATE
  | typeof ENDPOINT_OWNER_PREFERRED_SERVICES
  | typeof ENDPOINT_OWNER_PREFERRED_WALKER_CREATE
  | typeof ENDPOINT_OWNER_PREFERRED_WALKER_DELETE
  | typeof ENDPOINT_OWNER_PREMIUM_SUBSCRIPTION_GET
  | typeof ENDPOINT_OWNER_REGISTER
  | typeof ENDPOINT_OWNER_REGISTRATION_DOG_INFO
  | typeof ENDPOINT_OWNER_RESET_PASSWORD
  | typeof ENDPOINT_OWNER_RESET_PASSWORD_EMAIL
  | typeof ENDPOINT_OWNER_SERVICES_GET
  | typeof ENDPOINT_OWNER_SMART_MODULES_V5
  | typeof ENDPOINT_OWNER_UPDATE
  | typeof ENDPOINT_PAST_PET_CAREGIVERS_GET
  | typeof ENDPOINT_PROMO_CODE
  | typeof ENDPOINT_REBOOKING
  | typeof ENDPOINT_REQUEST_LOCKBOX
  | typeof ENDPOINT_SERVICE_PROVIDERS_GET
  | typeof ENDPOINT_SUBSCRIBE_TO_PREMIUM
  | typeof ENDPOINT_TRAINING_BOOKING
  | typeof ENDPOINT_UPDATE_CREDIT_CARD
  | typeof ENDPOINT_WALKER
  | typeof ENDPOINT_WALKER_CONTACT_INFO
  | typeof ENDPOINT_WALKER_PASSWORD_UPDATE
  | typeof ENDPOINT_WALKER_PROFILE_BY_PROMO_CODE_GET
  | typeof ENDPOINT_WALKER_RESET_PASSWORD
  | typeof ENDPOINT_WALKER_SIGN_IN
  | typeof ENDPOINT_WALKER_STRIPE_CONNECT_VERIFY_TOKEN
  | typeof ENDPOINT_WALKER_VERIFY_MIGRATION
  | typeof ENDPOINT_WALKS
  | typeof ENDPOINT_WALKS_IN_PENDING_REVIEW
  | typeof ENDPOINT_WALKS_IN_PROGRESS
  | typeof ENDPOINT_WALKS_IN_THE_PAST
  | typeof ENDPOINT_WALK_COORDINATES
  | typeof ENDPOINT_WALK_DETAILS_GET
  | typeof ENDPOINT_WALK_RATE_AND_TIP_V5;
