import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';

import {
  get$,
  getLegacy$,
  patch$,
  patchLegacy$,
  post$,
  postLegacy$,
  put$,
  putLegacy$,
  destroy$,
  destroyLegacy$,
} from '../helpers/Http/Api';
import {
  apiSlice,
  walkerApiSlice,
} from '../slices/apiSlice';

import {
  saveState,
  localStorageKey,
} from './persistState';

const localStorageThrottle = 1000;
const persistState = saveState(localStorageKey, localStorageThrottle);
export const epicMiddleware = createEpicMiddleware({
  dependencies: {
    get$,
    getLegacy$,
    patch$,
    patchLegacy$,
    put$,
    putLegacy$,
    post$,
    postLegacy$,
    destroy$,
    destroyLegacy$,
  },
});
export const middleware = [
  persistState,
  thunk,
  epicMiddleware,
  apiSlice.middleware,
  walkerApiSlice.middleware,
];
export default middleware;
