
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  OWNER_PREFERRED_WALKER_CREATE,
  OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
  OWNER_PREFERRED_WALKER_CREATE_FAILURE,
} from '../../actionTypes';

/**
 * Create owner preferred walker
 */
export type CreateOwnerPreferredWalkerParams = {
  walkerID: string;
};

export type CreateOwnerPreferredWalkerPayload = CreateOwnerPreferredWalkerParams;

export type CreateOwnerPreferredWalkerAction = {
  type: typeof OWNER_PREFERRED_WALKER_CREATE,
  payload: CreateOwnerPreferredWalkerPayload,
};

export const createOwnerPreferredWalker: (
  params: CreateOwnerPreferredWalkerParams,
) => CreateOwnerPreferredWalkerAction = createAction(
  OWNER_PREFERRED_WALKER_CREATE,
);


/**
 * Create owner preferred walker success action
 */
export type CreateOwnerPreferredWalkerSuccessAction = {
  type: typeof OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
};

export const createOwnerPreferredWalkerSuccess: (
  response: webApp.CreateOwnerPreferredWalkerParsedResponse
) => CreateOwnerPreferredWalkerSuccessAction = createAction(
  OWNER_PREFERRED_WALKER_CREATE_SUCCESS,
);


/**
 * Create owner preferred walker failure action
 */
export type CreateOwnerPreferredWalkerFailureAction = {
  type: typeof OWNER_PREFERRED_WALKER_CREATE_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const createOwnerPreferredWalkerFailure: (
  error: webApp.ErrorParsedResponse,
) => CreateOwnerPreferredWalkerFailureAction = createAction(
  OWNER_PREFERRED_WALKER_CREATE_FAILURE,
);
