export const PremiumCheckoutForm = {
  AcceptedTerms: 'acceptedTerms',
  BillingAddressCity: 'billingAddressCity',
  BillingAddressState: 'billingAddressState',
  BillingAddressSuiteOrApt: 'billingAddressSuiteOrApt',
  BillingAddressZipCode: 'billingAddressZipCode',
  BillingStreetAddress: 'billingStreetAddress',
  Email: 'email',
  ExpiryDate: 'expiryDate',
  FirstName: 'firstName',
  LastName: 'lastName',
  Phone: 'phone',
  Plan: 'plan',
  SubmitBtn: 'submit-btn',
} as const;
