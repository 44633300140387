import Dinero from 'dinero.js';
// https://sarahdayan.github.io/dinero.js/index.html
// Dinero calculates in minor currency (pennies in USD)
export const convertToMinorCurrency = (amount: number) => {
  const amountHasDecimal = amount.toString().indexOf('.') !== -1;

  if (amountHasDecimal) {
    return +amount.toFixed(2).replace('.', '');
  }

  return amount * 100;
};

// takes in a price and percentage value. ex: 50% = 50. NOT .5
// Returns number
export const calculateTipFromPercentage = (price: number, percentage: number) => {
  const amount = Dinero({
    amount: convertToMinorCurrency(price),
  });
  const percentOfAmount = Dinero({
    amount: amount.getAmount(),
  }).percentage(percentage);
  return percentOfAmount.toUnit();
};

export const centsToLocalDollars = (cents: number, currency: string = 'USD') => Dinero({
  amount: cents,
  currency,
}).toFormat('$0,0.00');

// stringifies amount, default to format such as '$1,000.50'. Other formats: https://sarahdayan.github.io/dinero.js/module-Dinero.html#~toFormat
export const getDollarString = (amount: number, format = '$0,0.00') => Dinero({
  amount: convertToMinorCurrency(amount),
}).toFormat(format);
type calculateCurrencyProps = {
  numbersToCalculate: Array<number>;
  operator: string;
  needsMinorConversion?: boolean;
  returnString?: boolean;
  stringFormat?: string;
};

/*
-Takes in an array of numbers to add or subtract. Numbers can be whole or of minor currency values
-Can return string or total in minor currency
-All of your numbers in the numbersToCalculate array must be normalized
(don't mix minor currency with regular dollar values)
*/
export const calculateCurrency = ({
  numbersToCalculate,
  operator,
  needsMinorConversion = false,
  returnString = false,
  stringFormat = '$0,0.00',
}: calculateCurrencyProps) => {
  let total = Dinero({
    amount: 0,
  });

  if (operator === 'subtract') {
    const startingValue = !needsMinorConversion ? numbersToCalculate[0] : convertToMinorCurrency(numbersToCalculate[0]);
    total = Dinero({
      amount: startingValue,
    });
  }

  if (needsMinorConversion) {
    // eslint-disable-next-line no-param-reassign
    numbersToCalculate = numbersToCalculate.map((num) => convertToMinorCurrency(num));
  }

  numbersToCalculate.forEach((num, index) => {
    // Skip the 0 index to prevent from subtracting itself
    if (operator === 'subtract' && index === 0) {
      return;
    }

    total = total[operator](Dinero({
      amount: num,
    }));
  });
  return returnString ? total.toFormat(stringFormat) : total.getAmount();
};
