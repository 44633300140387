import * as Yup from 'yup';

// Use branch quick links schema if the data properties are optional and without default values
// `branchSchema` = deep links with defaults
// `branchQuickLinksSchema` quick links without defaults
export const branchQuickLinksSchema = Yup.object({
  channel: Yup.string().default('web'),
  feature: Yup.string().required(),
  campaign: Yup.string().required(),
  tags: Yup.array().of(Yup.string()).default([]),
  data: Yup.object()
    .shape({
      $customSmsText: Yup.string(),
      $ogImageUrl: Yup.string(),
      branchSplashScreen: Yup.string(),
      customFweFullscreen: Yup.boolean(),
      dlwag: Yup.string(),
      fweButtonCtaTextColor: Yup.string(),
      fweHeadlineText: Yup.string(),
      fweHeadlineTextColor: Yup.string(),
      fweDeclineTextColor: Yup.string(),
      fweDeclineCopy: Yup.string(),
      fweButtonCta: Yup.string(),
      fweButtonColor: Yup.string(),
      fweImageUrl: Yup.string(),
      headlineText: Yup.string(),
      signupCtaDeeplink: Yup.string(),
      signupImage: Yup.string(),
    })
    .required(),
});

// https://github.com/jquense/yup#typescript-setting
export type BranchQuickLinksSchema = Yup.InferType<typeof branchQuickLinksSchema>;
