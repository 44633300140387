import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  ENV_STRIPE_PUBLIC_KEY,
  PREMIUM_SUBSCRIPTION_PRICE,
  VetChatSubscriptionLabels,
} from '../constants';
import { stripeApplePayBuildSession } from '../utils/stripeApplePayBuildSession';

export const useApplePay = () => {
  const [applePayButtonDisplay, setApplePayButtonDisplay] = useState<boolean>(false);
  const [creditCardPaymentOption, setCreditCardPaymentOption] = useState<boolean>(true);
  const executeInitializeApplePay = useCallback(() => {
    const stripeApplePaySetup = () => {
      Stripe.setPublishableKey(ENV_STRIPE_PUBLIC_KEY);
      Stripe.applePay.checkAvailability((available) => {
        if (applePayButtonDisplay) {
          return;
        }
        if (available) {
          setApplePayButtonDisplay(true);
          setCreditCardPaymentOption(false);
        }
      });
    };

    const stripeScript = document.createElement('script');
    stripeScript.src = 'https://js.stripe.com/v2/';
    stripeScript.onload = () => stripeApplePaySetup();
    document.body.append(stripeScript);
  }, [applePayButtonDisplay]);

  // The default object that's used if none is passed
  const defaultPaymentRequest = {
    requiredShippingContactFields: ['postalAddress', 'phone', 'email', 'name'],
    requiredBillingContactFields: ['postalAddress'],
    countryCode: 'US',
    currencyCode: 'USD',
    total: {
      label: VetChatSubscriptionLabels.Monthly,
      amount: PREMIUM_SUBSCRIPTION_PRICE,
      type: 'final',
    },
  } as Omit<stripe.StripeApplePayPaymentRequest, 'billingContact' | 'total'>;

  const executeStripeApplePayBuildSession = async (paymentRequest = defaultPaymentRequest) => (
    stripeApplePayBuildSession(paymentRequest)
  );

  useEffect(() => {
    executeInitializeApplePay();
  }, [executeInitializeApplePay]);

  return {
    executeInitializeApplePay,
    executeStripeApplePayBuildSession,
    setCreditCardPaymentOption,
    creditCardPaymentOption,
    setApplePayButtonDisplay,
    applePayButtonDisplay,
  };
};
