
import { createAction } from 'redux-actions';
import type {
  GetCreditPackagesParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  CREDIT_PACKAGES_GET,
  CREDIT_PACKAGES_GET_SUCCESS,
  CREDIT_PACKAGES_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get credit packages
 */
export type GetCreditPackagesAction = {
  type: typeof CREDIT_PACKAGES_GET,
};

export const getCreditPackages: () => GetCreditPackagesAction = createAction(
  CREDIT_PACKAGES_GET,
);


/**
 * Get credit packages success
 */

export type GetCreditPackagesSuccessAction = {
  type: typeof CREDIT_PACKAGES_GET_SUCCESS,
  payload: GetCreditPackagesParsedResponse,
};

export const getCreditPackagesSuccess: (
  GetCreditPackagesParsedResponse,
) => GetCreditPackagesSuccessAction = createAction(
  CREDIT_PACKAGES_GET_SUCCESS,
);


/**
 * Get credit packages failure
 */
export type GetCreditPackagesFailureAction = {
  type: typeof CREDIT_PACKAGES_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getCreditPackagesFailure: (
  ErrorParsedResponse,
) => GetCreditPackagesFailureAction = createAction(
  CREDIT_PACKAGES_GET_FAILURE,
);
