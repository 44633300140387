import { createSlice } from '@reduxjs/toolkit';

import { ENV_GOOGLE_CLIENT_ID } from '../../constants';
import type { ReduxState } from '../../reducers';

export type ConfigFormField = {
  label?: string;
  type: 'text';
  value?: string;
  inputProps?: Record<string, unknown>;
} | {
  label?: string;
  type: 'checkbox';
  value: boolean;
  checked: boolean;
} | {
  label?: string;
  type: 'number';
  value: number;
  inputProps?: Record<string, unknown>;
};
export type ConfigState = {
  domain: ConfigFormField;
  signupV2Enabled: ConfigFormField;
  googleClientId: ConfigFormField;
  migration: ConfigFormField;
};
const initialState: ConfigState = {
  domain: {
    label: 'Domain',
    type: 'text',
    value: process.env.REACT_APP_WAG_API_URL || '',
  },
  signupV2Enabled: {
    label: 'Signup V2 Enabled',
    type: 'checkbox',
    value: !!Number(process.env.REACT_APP_FEATURE_SIGNUP_V2_ENABLED),
    checked: !!Number(process.env.REACT_APP_FEATURE_SIGNUP_V2_ENABLED),
  },
  googleClientId: {
    label: 'Google Client Id',
    type: 'text',
    value: ENV_GOOGLE_CLIENT_ID,
  },
  migration: {
    label: 'Migration Version',
    value: 0,
    type: 'number',
    inputProps: {
      disabled: true,
    },
  },
};
const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    update: (state, {
      payload,
    }) => ({
      ...state,
      ...payload,
    }),
    clear: () => initialState,
  },
});
// selector functions
const selectors = {
  getConfig: (state: ReduxState) => state.config,
};
export const configSlice = {
  ...slice,
  selectors,
};
export const {
  update: updateConfig,
  clear: clearConfig,
} = configSlice.actions;
