export const replaceOrAddItemToArray = ({
  items = [],
  item,
  findItemCallback,
  addToBeginning = false,
}) => {
  const matchedItem = items.find(findItemCallback);

  if (matchedItem) {
    return items.map((possibleItemToReplace) => {
      if (possibleItemToReplace === matchedItem) {
        return { ...possibleItemToReplace,
          ...item };
      }

      return possibleItemToReplace;
    });
  }

  if (addToBeginning) {
    return [item, ...items];
  }

  return items.concat(item);
};
export default replaceOrAddItemToArray;
