
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  OWNER_PREFERRED_WALKER_DELETE,
  OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
  OWNER_PREFERRED_WALKER_DELETE_FAILURE,
} from '../../actionTypes';

/**
 * Delete owner preferred walker
 */
export type DeleteOwnerPreferredWalkerParams = {
  walkerID: string;
};

export type DeleteOwnerPreferredWalkerPayload = DeleteOwnerPreferredWalkerParams;

export type DeleteOwnerPreferredWalkerAction = {
  type: typeof OWNER_PREFERRED_WALKER_DELETE,
  payload: DeleteOwnerPreferredWalkerPayload,
};

export const deleteOwnerPreferredWalker: (
  params: DeleteOwnerPreferredWalkerParams,
) => DeleteOwnerPreferredWalkerAction = createAction(
  OWNER_PREFERRED_WALKER_DELETE,
);


/**
 * Delete owner preferred walker success action
 */
export type DeleteOwnerPreferredWalkerSuccessAction = {
  type: typeof OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
};

export const deleteOwnerPreferredWalkerSuccess: (
  response: webApp.DeleteOwnerPreferredWalkerParsedResponse
) => DeleteOwnerPreferredWalkerSuccessAction = createAction(
  OWNER_PREFERRED_WALKER_DELETE_SUCCESS,
);


/**
 * Delete owner preferred walker failure action
 */
export type DeleteOwnerPreferredWalkerFailureAction = {
  type: typeof OWNER_PREFERRED_WALKER_DELETE_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const deleteOwnerPreferredWalkerFailure: (
  error: webApp.ErrorParsedResponse,
) => DeleteOwnerPreferredWalkerFailureAction = createAction(
  OWNER_PREFERRED_WALKER_DELETE_FAILURE,
);
