import { unauthenticatedResponse, serverErrorResponse } from "../../../actions/response";
import { STATUS_CODE_UNAUTHENTICATED, STATUS_CODE_SERVER_NOT_RESPONDING, STATUS_CODE_SERVER_ERROR } from "../../../constants/statusCodes";
import type { StatusCode } from "../../../constants/statusCodes";
import { RESPONSE_MESSAGE_UNAUTHENTICATED, RESPONSE_MESSAGE_SERVER_ERROR } from "../../../constants/app";
export const getResponseActionForStatusCode = (statusCode: StatusCode = STATUS_CODE_SERVER_ERROR) => {
  switch (statusCode) {
    case STATUS_CODE_UNAUTHENTICATED:
      return unauthenticatedResponse(RESPONSE_MESSAGE_UNAUTHENTICATED);

    case STATUS_CODE_SERVER_ERROR:
    case STATUS_CODE_SERVER_NOT_RESPONDING:
      return serverErrorResponse(RESPONSE_MESSAGE_SERVER_ERROR);

    default:
      return serverErrorResponse(RESPONSE_MESSAGE_SERVER_ERROR);
  }
};
export default getResponseActionForStatusCode;