import type { WalkerProfileResponse, WalkerBadges as apiWalkerBadges, WalkerProfile } from "wagAPI";
import type { GetWalkerProfileParsedResponse, WalkerBadges as webAppWalkerBadges } from "webApp";

const getWalkerBadgeResponseParser = (badges: apiWalkerBadges): webAppWalkerBadges => {
  const propReplacer = (value, apiPropertyKey, webAppPropertyKey): any => Object.keys(value).filter(key => key !== apiPropertyKey).reduce((accumulator, currentValue) => {
    accumulator[currentValue] = value[currentValue];
    accumulator[webAppPropertyKey] = value[apiPropertyKey];
    return accumulator;
  }, {});

  return {
    achievements: badges.achievement.map(achievement => propReplacer(achievement, 'asset_large', 'assetLarge')) || [],
    compliments: badges.compliment.map(compliment => propReplacer(compliment, 'asset_large', 'assetLarge')) || []
  };
};

export const parseWalkerProfile = ({
  badges,
  bio,
  first_name: firstName,
  last_name: lastName,
  latitude,
  longitude,
  phone,
  picture,
  rating,
  reviews,
  thumb,
  video,
  walker_id: id
}: WalkerProfile) => ({
  badges: getWalkerBadgeResponseParser(badges),
  bio,
  firstName,
  id,
  lastName,
  latitude: Number(latitude),
  longitude: Number(longitude),
  numReviews: reviews.length,
  phone,
  picture,
  rating,
  reviews,
  // response returns reviews that are not anonymous
  thumb,
  video
});
export const getWalkerProfileResponseParser = ({
  walker
}: WalkerProfileResponse): GetWalkerProfileParsedResponse => parseWalkerProfile(walker);
export default getWalkerProfileResponseParser;