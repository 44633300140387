
import { createAction } from 'redux-actions';
import type {
  ApplyPromoCodeParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  PROMO_CODE_APPLY,
  PROMO_CODE_APPLY_SUCCESS,
  PROMO_CODE_APPLY_FAILURE,
} from '../../actionTypes';


/**
 * Apply promo code
 */
export type ApplyPromoCodeActionPayload = {
  promoCode: string,
};

export type ApplyPromoCodeAction = {
  type: typeof PROMO_CODE_APPLY,
  payload: ApplyPromoCodeActionPayload,
};

export const applyPromoCode: (string) => ApplyPromoCodeAction = createAction(
  PROMO_CODE_APPLY,
  promoCode => ({ promoCode }),
);


/**
 * Apply promo code success
 */
export type ApplyPromoCodeSuccessAction = {
  type: typeof PROMO_CODE_APPLY_SUCCESS,
  payload: ApplyPromoCodeParsedResponse,
};

export const applyPromoCodeSuccess: (
  ApplyPromoCodeParsedResponse,
) => ApplyPromoCodeSuccessAction = createAction(
  PROMO_CODE_APPLY_SUCCESS,
);


/**
 * Apply promo code failure
 */
export type ApplyPromoCodeFailureAction = {
  type: typeof PROMO_CODE_APPLY_FAILURE,
  payload: ErrorParsedResponse,
};

export const applyPromoCodeFailure: (
  ErrorParsedResponse,
) => ApplyPromoCodeFailureAction = createAction(
  PROMO_CODE_APPLY_FAILURE,
);
