import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

// TODO - allow changing the color
export const FurThreeIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#00BF8F"
    viewBox="0 0 48 48"
    {...props}
  >
    <g
      fillRule="nonzero"
      fill="none"
    >
      <path
        d="M11.462 14.18c1.07-2.808 2.451-5.557 4.54-7.695 2.09-2.137 4.968-3.61 7.925-3.477 2.907.139 5.54 1.767 7.807 3.664.089.068.174.14.259.213 2.927 2.493 5.594 5.672 6.177 9.519-1.924-2.359-4.946-3.737-7.956-3.62 2.59 1.327 4.687 3.615 5.813 6.34a8.158 8.158 0 0 1-4.817-1.551 25.826 25.826 0 0 1 5.01 6.167c-3.62.728-7.437 1.934-9.704 4.907-.78 1.022-8.589.752-12.23-3.08-3.849-4.051-3.5-11.698-2.824-11.388Z"
        fill="#DBA445"
      />
      <path
        d="M13.896 12.88c.88-.823 1.793-1.667 2.919-2.078 1.126-.411 2.53-.282 3.318.633a9.136 9.136 0 0 0-4.027 2.447c4.296-1.104 9.096.09 12.408 3.085a9.446 9.446 0 0 0-2.704-4.674c-.22-.205-.455-.408-.583-.682-.128-.274-.113-.645.12-.834.241-.195.591-.12.888-.036l3.314.94-2.882-2.032c-.21-.149-.45-.393-.336-.627.123-.255.498-.178.754-.287.37-.16.41-.727.164-1.052-.245-.325-.658-.459-1.05-.552-2.354-.56-4.886-.247-7.072.802-2.186 1.048-4.02 2.818-5.23 4.947"
        fill="#E0B466"
      />
      <path
        d="M42.158 42.669c-1.112 1.505-3.045 2.332-4.875 2.082-1.835-.243-3.49-1.565-4.173-3.314-.567 1.648-1.59 3.237-3.156 3.957-1.56.72-3.7.285-4.5-1.261-.725 1.267-2.239 2.028-3.67 1.838-1.433-.184-2.707-1.303-3.099-2.72-2.8.97-5.757 1.66-8.708 1.405-2.951-.256-5.909-1.553-7.71-3.945C.504 38.355.036 35.255.007 32.268 0 32.155 0 32.042 0 31.928c-.006-3.885.672-8.008 3.191-10.936-.537 3.017.369 6.26 2.39 8.533a12.86 12.86 0 0 1 1.035-8.61 8.489 8.489 0 0 1 1.93 4.754 26.34 26.34 0 0 1 1.42-7.872c2.875 2.357 6.235 4.558 9.93 4.415 2.612-.101 5.02-1.374 7.328-2.618 2.303-1.25 4.706-2.534 7.318-2.665 2.606-.138 7.616 1.808 9.64 4.76-.03-.576 3.461 4.941 3.794 10.579.372 6.285-5.806 11.759-5.818 10.4Z"
        fill="#DBA445"
      />
      <path
        d="M37.283 44.751c-1.835-.243-3.49-1.565-4.173-3.314-.567 1.648-1.59 3.237-3.156 3.957-1.56.72-3.7.285-4.5-1.261-.725 1.267-2.239 2.028-3.67 1.838-1.433-.184-2.707-1.303-3.099-2.72-2.8.97-5.757 1.66-8.708 1.405-2.951-.256-5.909-1.553-7.71-3.945C.504 38.355.036 35.255.007 32.268c.736 2.493 2.197 4.766 4.232 6.355 3.962 3.094 9.83 3.38 14.068.684 2.554-1.625 4.512-4.308 5.014-7.33.497-3.029-.59-6.35-2.969-8.235 5.476 2.945 8.136 10.359 5.821 16.202-.467 1.195-1.087 2.677-.25 3.647.817.958 2.5.476 3.178-.595.678-1.071.643-2.434.585-3.707.082 1.589 2.385 2.142 3.647 1.202 1.257-.946 1.683-2.636 2.022-4.189.69 1.762 1.783 3.814 3.647 3.85 1.25.024 2.303-.929 3.063-1.94 3.588-4.778 3.156-12.221-.97-16.524 6.218 4.403 8.682 10.574 5.543 17.6-3.138 7.028-7.524 5.713-9.354 5.463Z"
        fill="#C59033"
      />
      <path
        d="m29.738 22.155 1.95 2.462a15.545 15.545 0 0 1-6.337-2.023c2.027 1.213 3.596 3.129 4.646 5.271 1.05 2.143 1.606 4.506 1.897 6.881l1.932-4.743c.148-.361.322-.752.666-.925.666-.333 1.388.37 1.69 1.06.301.69.527 1.527 1.206 1.83a11.644 11.644 0 0 1-.408-4.456c.716 1.623 1.66 3.442 3.367 3.821.289-3.06-1.344-5.954-3.158-8.41-.665-.902-1.385-1.798-2.338-2.372-.952-.576-2.19-.778-3.168-.247l2.038 2.42c.195.23.402.529.295.812-.164.435-.795.312-1.208.112l-3.07-1.493Zm-17.09-.255c.963.723 1.951 1.474 2.562 2.52.611 1.046.755 2.474.023 3.437a9.278 9.278 0 0 0-3.129-3.558c1.885 4.082 1.65 9.107-.607 12.989a9.299 9.299 0 0 0 3.99-3.595c.157-.258.308-.531.548-.712.24-.18.6-.237.826-.04.235.205.23.569.206.881l-.275 3.491 1.41-3.267c.103-.239.293-.524.54-.456.27.074.266.464.42.7.225.34.78.27 1.046-.038.267-.307.317-.746.332-1.155.09-2.459-.694-4.93-2.123-6.914-1.428-1.985-3.484-3.48-5.768-4.283"
        fill="#E0B466"
      />
    </g>
  </SvgIcon>
);
