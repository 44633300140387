
import { createAction } from 'redux-actions';
import type {
  V5AddPreferredWalkerSuccessResponse,
  V5AddPreferredWalkerErrorResponse,
} from 'wagAPI';
import {
  OWNER_ADD_PREFERRED_WALKER,
  OWNER_ADD_PREFERRED_WALKER_FAILURE,
  OWNER_ADD_PREFERRED_WALKER_SUCCESS,
} from '../../actionTypes';

export type AddPreferredWalkerParams = {
  walkerPromoCode: string,
};

export type AddPreferredWalkerAction = {
  type: typeof OWNER_ADD_PREFERRED_WALKER,
  payload: AddPreferredWalkerParams,
};

export const addPreferredWalker: (
  AddPreferredWalkerParams
) => AddPreferredWalkerAction = createAction(
  OWNER_ADD_PREFERRED_WALKER,
  ({ walkerPromoCode }) => ({ walkerPromoCode }),
);

export type AddPreferredWalkerActionSuccess = {
  type: typeof OWNER_ADD_PREFERRED_WALKER_SUCCESS,
  payload: V5AddPreferredWalkerSuccessResponse,
};

export const addPreferredWalkerSuccess: () => AddPreferredWalkerActionSuccess = createAction(
  OWNER_ADD_PREFERRED_WALKER_SUCCESS,
);

export type AddPreferredWalkerActionFailure = {
  type: typeof OWNER_ADD_PREFERRED_WALKER_FAILURE,
  payload: V5AddPreferredWalkerErrorResponse,
};

export const addPreferredWalkerFailure: () => AddPreferredWalkerActionFailure = createAction(
  OWNER_ADD_PREFERRED_WALKER_FAILURE,
);
