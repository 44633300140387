import moment from 'moment';

import serviceBoarding from '../assets/svg/service-boarding.svg';
import serviceDropin from '../assets/svg/service-dropin.svg';
import serviceHealth from '../assets/svg/service-health.svg';
import serviceSitting from '../assets/svg/service-sitting.svg';
import serviceTraining from '../assets/svg/service-training.svg';
import serviceWalking from '../assets/svg/service-walking.svg';
import { webApp } from '../types/webapp';

import { TimeFormat } from './app';
import {
  ROUTE_OVERNIGHT_BOARDING,
  ROUTE_OVERNIGHT_SITTING,
  ROUTE_SELECT_WALK_DURATION,
  ROUTE_TRAINING,
  ROUTE_WALK_BOOKING_DROPIN_DURATION,
} from './routes';
import {
  WALK_TYPE_ID_BOARDING,
  WALK_TYPE_ID_DROP_INS,
  WALK_TYPE_ID_HEALTH,
  WALK_TYPE_ID_SITTING,
  WALK_TYPE_ID_TRAINING,
  WALK_TYPE_ID_WALK,
} from './walkTypes';

/**
 * Categories that are mapped to the owner services endpoint
 */
export const ServiceCategories = {
  Walk: 'walk',
  DropIns: 'drop-in',
  Sitting: 'sitting',
  Training: 'training',
} as const;

export const service = {
  [WALK_TYPE_ID_WALK]: {
    title: 'Walking',
    icon: serviceWalking,
    id: WALK_TYPE_ID_WALK,
    url: ROUTE_SELECT_WALK_DURATION,
    /**
     * Used by useGetOwnerWalkServicesQuery()
     */
    category: ServiceCategories.Walk,
  },
  [WALK_TYPE_ID_DROP_INS]: {
    title: 'Drop-Ins',
    icon: serviceDropin,
    id: WALK_TYPE_ID_DROP_INS,
    url: ROUTE_WALK_BOOKING_DROPIN_DURATION,
    category: ServiceCategories.DropIns,
  },
  [WALK_TYPE_ID_TRAINING]: {
    title: 'Training',
    icon: serviceTraining,
    id: WALK_TYPE_ID_TRAINING,
    url: ROUTE_TRAINING,
    /**
     * Used by useGetOwnerTrainingServicesQuery()
     */
    category: ServiceCategories.Training,
  },
  [WALK_TYPE_ID_SITTING]: {
    title: 'Sitting',
    icon: serviceSitting,
    id: WALK_TYPE_ID_SITTING,
    url: ROUTE_OVERNIGHT_SITTING,
    /**
     * Used by useGetOwnerSittingServicesQuery()
     */
    category: ServiceCategories.Sitting,
  },
  [WALK_TYPE_ID_BOARDING]: {
    title: 'Boarding',
    icon: serviceBoarding,
    id: WALK_TYPE_ID_BOARDING,
    url: ROUTE_OVERNIGHT_BOARDING,
    /**
     * Used by useGetOwnerSittingServicesQuery()
     */
    category: ServiceCategories.Sitting,
  },
  [WALK_TYPE_ID_HEALTH]: {
    title: 'Health',
    icon: serviceHealth,
    id: WALK_TYPE_ID_HEALTH,
    category: '',
  },
};
// to array
export const services = Object.entries(service).map(([, value]) => value);

export const getServiceTypeDisplayText = (serviceType: string) => {
  const knownTypes: Record<string, string> = {
    [ServiceServiceType.Walk20Min]: '20 min walk',
    [ServiceServiceType.WalkDeluxe]: 'Deluxe Walk',
    [ServiceServiceType.DropIn]: 'Drop-In',
    [ServiceServiceType.Training]: 'Training',
    [ServiceServiceType.Sitting]: 'Sitting',
    [ServiceServiceType.Boarding]: 'Boarding',
  };

  return knownTypes[serviceType] || knownTypes.walk_20_min;
};

export const getComputedServiceDescription = (computableService: webApp.V6.Service) => {
  const walkEndTime = moment(computableService.endTime).format(TimeFormat.default);
  const walkStartTime = moment(computableService.startTime).format(TimeFormat.default);

  const walkDiff = moment(walkEndTime, TimeFormat.default).diff(
    moment(walkStartTime, TimeFormat.default),
    'minutes',
  );

  const knownTypes: Record<string, string> = {
    walk: `${walkDiff} Minute Visit`,
    training: '60 Minute Visit',
    sitting: '',
    boarding: '',
  };

  if (!(computableService.type in knownTypes)) {
    return knownTypes.walk;
  }

  return knownTypes[computableService.type];
};

export const ServiceState = {
  Cancelled: 'cancelled',
  Completed: 'completed',
  Confirmed: 'confirmed',
  Engaged: 'engaged',
  Started: 'started',
  Pending: 'pending',
} as const;

export const ServiceStatus = {
  Requested: 'requested',
  Cancelled: 'cancelled',
} as const;

/**
 * These are the service types that are mapped in the endpoint
 */
export const ServiceServiceType = {
  Walk: 'walk',
  Walk20Min: 'walk_20_min',
  WalkDeluxe: 'walk_deluxe',
  Sitting: 'sitting',
  Boarding: 'boarding',
  Training: 'training',
  DropIn: 'drop_in',
} as const;

export const ServiceCardAction = {
  RequestAgain: 'request-again',
  GetHelp: 'get-help',
  Cancel: 'cancel',
} as const;

export const ServiceCancellationFee: Record<string, number> = {
  [ServiceServiceType.Walk]: 10,
  [ServiceServiceType.Walk20Min]: 10,
  [ServiceServiceType.WalkDeluxe]: 10,
  [ServiceServiceType.Boarding]: 25,
  [ServiceServiceType.DropIn]: 10,
  [ServiceServiceType.Sitting]: 25,
  [ServiceServiceType.Training]: 0,
} as const;
