import React from 'react';
import Flex from '@waglabs/flex';
import Image from '@waglabs/image';
import Button from '@waglabs/button';
import Text from '@waglabs/text';

import walkerHeads from './assets/walker-heads.svg';
import { ARIA_LABEL } from '../../../../../../constants/app';

type Props = {
  children: any,
  onClick?: () => void,
  type: string,
  disabled?: boolean,
  variant?: string,
  activeColor?: string,
  isValid?: boolean,
};

// NOTE: the StyledButton component in wag-react puts {children}
// inside of a <Text> component and isn't suitable for this
// button as we need to put a <div> in the button itself.
const StyledButton = ({
  activeColor,
  children,
  isValid,
  onClick,
  type,
  ...props
}: Props) => (
  <Button
    borderRadius={3}
    border={0}
    width={[230, 350]}
    height={[40, 55]}
    onClick={onClick}
    type={type}
    disabled={!isValid && Boolean(onClick)}
    isValid={isValid && Boolean(onClick)}
    bg={activeColor}
    {...props}
  >
    {children}
  </Button>
);

export const SidebarBecomeAWalkerButton = () => (
  <StyledButton
    type="button"
    bg="wagGreen"
    height={60}
    width={[290, 290, 320]}
  >
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      boxSizing="border-box"
      px={2}
      hover
    >
      <Image
        height={[35, 35, 40]}
        src={walkerHeads}
        hover
        alt={ARIA_LABEL.IMAGE.WALKER_HEADS}
      />
      <Text
        color="white"
        fontSize={[5, 6, 7]}
        fontWeight={500}
        whiteSpace="nowrap"
        ml={2}
        my={0}
        hover
      >
        Become a Walker
      </Text>
    </Flex>
  </StyledButton>
);
