/**
 * @deprecated - Please use the `Login` value
 */
import * as Components from './components';
import * as ForgotPassword from './forgot-password';
import * as MyVetChat from './home/my-wag-health';
import * as MyWellness from './home/my-wellness';
import * as Survey from './home/overnight/survey';
import * as SingleBook from './home/single-book';
import * as LOGIN from './login';
import * as Login from './login';
import * as Navigation from './navigation';
import * as Notification from './notification';
import * as Premium from './premium';
import * as ResetPassword from './reset-password';
import * as SIGNUP from './signup';
import * as SignupNew from './signup-new';
import * as TermsAndConditions from './terms-and-conditions';
import * as WellnessV2 from './v2/wellness';
import * as VetChat from './vet-chat';
import * as Walker from './walker';
import * as Wellness from './wellness';

// TODO - Replace to be PascalCase instead of UPPERCASE
/**
 * @deprecated - Please use the `DataTestIds` instead of this for newer data test ids
 * and follow the convention of PascalCase
 */
export const DATA_TEST_IDS = {
  SIGNUP,
  LOGIN,
  Navigation,
  Components,
  ForgotPassword,
  ResetPassword,
  Notification,
  Survey,
  Home: {
    ...SingleBook,
  },
  Walker,
  Wellness,
};

export const DataTestIds = {
  Components,
  Login,
  MyVetChat,
  MyWellness,
  Navigation,
  Notification,
  Premium,
  Signup: {
    ...SIGNUP,
    ...SignupNew,
  },
  TermsAndConditions,
  VetChat,
  Walker,
  Wellness,
  WellnessV2,
};
