
import { createAction } from 'redux-actions';
import type {
  UpdateOwnerPaymentMethodParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_PAYMENT_METHOD_UPDATE,
  OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
  OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
} from '../../actionTypes';


/**
 * Update owner payment method
 */
export type UpdateOwnerPaymentMethodParams = {
  id: string,
}

export type UpdateOwnerPaymentMethodPayload = UpdateOwnerPaymentMethodParams;

export type UpdateOwnerPaymentMethodAction = {
  type: typeof OWNER_PAYMENT_METHOD_UPDATE,
  payload: UpdateOwnerPaymentMethodPayload,
};

export const updateOwnerPaymentMethod: (
  UpdateOwnerPaymentMethodParams
) => UpdateOwnerPaymentMethodAction = createAction(
  OWNER_PAYMENT_METHOD_UPDATE,
  ({
    id,
  }) => ({
    id,
  }),
);

/**
 * Update owner payment method success
 */
export type UpdateOwnerPaymentMethodSuccessAction = {
  type: typeof OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
  payload: UpdateOwnerPaymentMethodParsedResponse,
};

export const updateOwnerPaymentMethodSuccess: (
  UpdateOwnerPaymentMethodParsedResponse,
) => UpdateOwnerPaymentMethodSuccessAction = createAction(
  OWNER_PAYMENT_METHOD_UPDATE_SUCCESS,
);


/**
 * Update owner payment method failure
 */
export type UpdateOwnerPaymentMethodFailureAction = {
  type: typeof OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateOwnerPaymentMethodFailure: (
  ErrorParsedResponse,
) => UpdateOwnerPaymentMethodFailureAction = createAction(
  OWNER_PAYMENT_METHOD_UPDATE_FAILURE,
);
