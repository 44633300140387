import { decamelizeKeys } from 'humps';
import { SegmentTrackSchema } from 'webApp';

import { FEATURE_SEGMENT_ENABLED } from '../constants';

export const useSegmentTrack = <DataSchema>() => {
  const execute = async ({
    event,
    data,
  }: {
    event: string;
    data?: DataSchema | SegmentTrackSchema;
  }) => {
    if (!FEATURE_SEGMENT_ENABLED) {
      console.warn('Attempted to track, but Segment is not enabled');
      return;
    }

    const decamelizedSegmentTrackData = decamelizeKeys(data);
    const { analytics } = window;
    if (analytics) {
      analytics.track(event, decamelizedSegmentTrackData);
    }
  };
  return {
    execute,
  };
};
