import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { WalkCoordinatesRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALK_COORDINATES, Endpoint } from "../../../constants/endpoints";
import { WALK_COORDINATES_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalkCoordinatesFailure, getWalkCoordinatesSuccess, GetWalkCoordinatesAction } from "../../../actions/walk/walkCoordinates";
import { getWalkCoordinatesResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalkCoordinates$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALK_COORDINATES_GET), switchMap(({
  payload: {
    walkID
  }
}: GetWalkCoordinatesAction) => get$(({
  endpoint: ENDPOINT_WALK_COORDINATES,
  payload: {
    walk_id: walkID,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: WalkCoordinatesRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkCoordinatesSuccess,
  onFailure: getWalkCoordinatesFailure,
  parser: getWalkCoordinatesResponseParser
})), catchError(handleErrorV5Response(action$, getWalkCoordinatesFailure)))));

export default getWalkCoordinates$;