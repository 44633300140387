import React, { Component } from 'react';

if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  throw new Error('Google Tag Manager ID not set');
}
const googleTagManagerID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

type Props = {};

class GoogleTagManager extends Component<Props> {
  componentWillMount() {
    // taken from https://developers.google.com/tag-manager/quickstart
    // minimally changed to preserve third party compatibility
    (function (w, d, s, l, i) { // eslint-disable-line
      w[l] = w[l] || []; // eslint-disable-line no-param-reassign
      w[l].push({
        'gtm.start':
          new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];

      const j = d.createElement(s); const
        dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      if (f.parentNode) {
        f.parentNode.insertBefore(j, f);
      }
    }(
      window,
      document,
      'script',
      'dataLayer',
      googleTagManagerID,
    ));
  }

  render() {
    return (
      <noscript>
        <iframe
          title="google-tag-manager"
          src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerID}`}
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    );
  }
}

export default GoogleTagManager;
