import * as React from 'react';
import Flex from '@waglabs/flex';

type SidebarProps = {
  children?: React$Node,
};

export const Sidebar = ({
  children,
}: SidebarProps) => (
  <Flex
    flexDirection="column"
    display={['none', 'none', 'flex']}
    boxSizing="border-box"
    width={200}
    px={4}
    pt={3}
    alignItems="flex-end"
  >
    {children}
  </Flex>
);
