
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  AUTHENTICATION_SESSION_POST,
  AUTHENTICATION_SESSION_POST_SUCCESS,
  AUTHENTICATION_SESSION_POST_FAILURE,
} from '../../actionTypes';

/**
 * Post authentication session
 */
export type PostAuthenticationSessionParams = {
  userRoleIdentifier: string,
  userRoleIdentificationMethod: string,
  userRoleType: string,
};

export type PostAuthenticationSessionPayload = PostAuthenticationSessionParams;

export type PostAuthenticationSessionAction = {
  type: typeof AUTHENTICATION_SESSION_POST,
  payload: PostAuthenticationSessionPayload,
};

export const postAuthenticationSession: (
  params: webApp.PostAuthenticationSessionParams,
) => PostAuthenticationSessionAction = createAction(
  AUTHENTICATION_SESSION_POST,
);

/**
 * Post authentication session success action
 */
export type PostAuthenticationSessionSuccessAction = {
  type: typeof AUTHENTICATION_SESSION_POST_SUCCESS,
};

export const postAuthenticationSessionSuccess: (
  response: webApp.PostAuthenticationSessionParsedResponse
) => PostAuthenticationSessionSuccessAction = createAction(
  AUTHENTICATION_SESSION_POST_SUCCESS,
);

/**
 * Post authentication session failure action
 */
export type PostAuthenticationSessionFailureAction = {
  type: typeof AUTHENTICATION_SESSION_POST_FAILURE,
  payload: webApp. ErrorParsedResponse,
};

export const postAuthenticationSessionFailure: (
  error: webApp.ErrorParsedResponse,
) => PostAuthenticationSessionFailureAction = createAction(
  AUTHENTICATION_SESSION_POST_FAILURE,
);
