import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

// TODO - allow changing the color
export const FurTwoIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#00BF8F"
    viewBox="0 0 48 48"
    {...props}
  >
    <g
      fillRule="nonzero"
      fill="none"
    >
      <path
        d="M42.158 42.678c-1.112 1.5-3.045 2.326-4.875 2.076-1.835-.242-3.49-1.56-4.173-3.305-.567 1.644-1.59 3.228-3.156 3.947-1.56.717-3.7.284-4.5-1.258-.725 1.263-2.239 2.023-3.67 1.833-1.433-.184-2.707-1.3-3.099-2.712-2.8.968-5.757 1.656-8.708 1.4-2.951-.254-5.909-1.548-7.71-3.934-1.764-2.35-2.232-5.441-2.261-8.42C0 32.192 0 32.079 0 31.967c-.006-3.875.672-7.988 3.191-10.907-.537 3.008.369 6.242 2.39 8.51a12.794 12.794 0 0 1 1.035-8.587 8.453 8.453 0 0 1 1.93 4.741c.07-2.676.548-5.324 1.42-7.85 2.875 2.35 6.235 4.544 9.93 4.402 2.612-.1 5.02-1.37 7.328-2.61 2.303-1.247 4.706-2.529 7.318-2.66 2.606-.136 7.616 1.804 9.64 4.75-.03-.576 3.461 4.926 3.794 10.549.372 6.268-5.806 11.727-5.818 10.373Z"
        fill="#DBA445"
      />
      <path
        d="M37.283 44.754c-1.835-.242-3.49-1.56-4.173-3.305-.567 1.644-1.59 3.228-3.156 3.947-1.56.717-3.7.284-4.5-1.258-.725 1.263-2.239 2.023-3.67 1.833-1.433-.184-2.707-1.3-3.099-2.712-2.8.968-5.757 1.656-8.708 1.4-2.951-.254-5.909-1.548-7.71-3.934-1.764-2.35-2.232-5.441-2.261-8.42.736 2.486 2.197 4.753 4.232 6.338 3.962 3.085 9.83 3.37 14.068.682 2.554-1.62 4.512-4.296 5.014-7.311.497-3.02-.59-6.332-2.969-8.213 5.476 2.938 8.136 10.332 5.821 16.159-.467 1.193-1.087 2.67-.25 3.638.817.955 2.5.474 3.178-.594.678-1.068.643-2.427.585-3.697.082 1.585 2.385 2.136 3.647 1.199 1.257-.944 1.683-2.63 2.022-4.178.69 1.757 1.783 3.804 3.647 3.84 1.25.023 2.303-.926 3.063-1.935 3.588-4.765 3.156-12.189-.97-16.48 6.218 4.392 8.682 10.546 5.543 17.554-3.138 7.008-7.524 5.697-9.354 5.447Z"
        fill="#C59033"
      />
      <path
        d="m29.738 22.219 1.95 2.456a15.574 15.574 0 0 1-6.337-2.018c2.027 1.21 3.596 3.12 4.646 5.257 1.05 2.136 1.606 4.493 1.897 6.863l1.932-4.731c.148-.36.322-.75.666-.922.666-.333 1.388.369 1.69 1.056.301.688.527 1.524 1.206 1.826a11.583 11.583 0 0 1-.408-4.445c.716 1.619 1.66 3.434 3.367 3.812.289-3.052-1.344-5.939-3.158-8.389-.665-.899-1.385-1.792-2.338-2.365-.952-.574-2.19-.776-3.168-.246l2.038 2.414c.195.23.402.527.295.81-.164.433-.795.31-1.208.11l-3.07-1.488Zm-17.09-.255c.963.721 1.951 1.47 2.562 2.514.611 1.043.755 2.468.023 3.427a9.26 9.26 0 0 0-3.129-3.548c1.885 4.072 1.65 9.083-.607 12.954a9.29 9.29 0 0 0 3.99-3.585c.157-.257.308-.53.548-.71.24-.18.6-.236.826-.04.235.205.23.567.206.879l-.275 3.482 1.41-3.26c.103-.237.293-.522.54-.454.27.074.266.463.42.698.225.339.78.27 1.046-.037.267-.306.317-.744.332-1.152.09-2.453-.694-4.917-2.123-6.896-1.428-1.98-3.484-3.47-5.768-4.272"
        fill="#E0B466"
      />
    </g>
  </SvgIcon>
);
