import { combineEpics } from 'redux-observable';

import {
  getDogHomeQuestionaire$,
  getDogWalkQuestionaire$,
} from './getDogQuestionaires/getDogQuestionaires.epic';
import updateDogQuestionaire$ from './updateDogQuestionaire/updateDogQuestionaire.epic';

export default combineEpics(
  getDogHomeQuestionaire$,
  getDogWalkQuestionaire$,
  updateDogQuestionaire$,
);
