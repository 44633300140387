import { STATUS_CODE_SERVER_ERROR, STATUS_CODE_SERVER_NOT_RESPONDING } from "../../../constants/statusCodes";
import { ERORR_MESSAGE_SERVER_ERROR } from "../../../constants/statusMessages";

const getErrorMessageForStatus = (status: string, defaultMessage: string = '') => {
  switch (status) {
    case STATUS_CODE_SERVER_ERROR:
    case STATUS_CODE_SERVER_NOT_RESPONDING:
      return ERORR_MESSAGE_SERVER_ERROR;

    default:
      return defaultMessage;
  }
};

export default getErrorMessageForStatus;