import rootReducer, { ReduxState } from '../reducers';

import { migrationOptions } from './migration';

/**
 * Retrieve the initial store structure state
 * https://stackoverflow.com/a/35641992
 */
const getStoreInitialState = () => (
  rootReducer(undefined, {
    type: 'clear',
  })
) as unknown as ReduxState;

/**
 * `applyMigration(...)` function is like a middleware as it sits inside the `saveState(...)`
 * function. Everytime there is a save operation that is being dispatched by any Redux action
 * we check if we can apply the migration.
 *
 * There are two places we can run this function.
 *
 * 1. Run inside the `loadState(...)` to change the initial value of each property
 *    but not restructure the store. We cannot add or delete. It is constrained
 *    to only change the values of the existing properties.
 *    This only runs on store initialization (at the start).
 *
 * 2. Run inside the `saveState(...)` which allows us to take control of the wheel.
 *    This is the place where we have full control on what is saved and not saved.
 *    This runs on every store mutation operation unlike `loadState(...)` which
 *    only runs on store initialization.
 */
export const applyMigration = (state: ReduxState) => {
  const currentState = {
    ...state,
  };

  /**
   * If no migration key set on the store, get the next state and combine it
   * with the initial state store state
   *
   * Ultimately, if there's no migration key set on the store it will
   * just load the initial state store (fresh).
   */
  if (currentState.config && !currentState.config.migration) {
    const nextVersion = migrationOptions.version;
    const initialStoreState = getStoreInitialState();
    const pendingNextState = migrationOptions.history?.[nextVersion]?.(
      currentState,
      initialStoreState,
    ) as ReduxState;

    if (pendingNextState && pendingNextState.config) {
      pendingNextState.config.migration.value = nextVersion;
    }

    return {
      ...initialStoreState,
      ...pendingNextState,
    };
  }

  if (currentState.config?.migration) {
    const currentVersion = migrationOptions.version;
    const nextVersion = migrationOptions.version;

    const initialStoreState = getStoreInitialState();
    const pendingNextState = migrationOptions.history?.[nextVersion]?.(
      currentState,
      initialStoreState,
    ) as ReduxState;

    if (pendingNextState && pendingNextState.config) {
      pendingNextState.config.migration.value = nextVersion;
    }

    /**
       * If the currentVersion is equal to the nextVersion
       * then there should be no "breaking" changes
       * Let's copy the `currentState` and apply the `pendingNextState`
       */
    if (currentVersion === nextVersion) {
      return {
        ...initialStoreState,
        ...currentState,
        ...pendingNextState,
      };
    }

    return {
      ...initialStoreState,
      ...pendingNextState,
    };
  }

  return currentState;
};
