import type {
  PastService,
  PastWalker,
} from 'webApp';

import {
  getWalksInThePast,
  getWalkType,
  getPreferredWalkers,
} from '../../selectors';
import type { ReduxState } from '../index';

export const selectors = {
  getPastServices: (state: ReduxState): PastService[] => (
    getWalksInThePast(state)
      .map((pastWalk) => ({ ...pastWalk, walkType: getWalkType(state, pastWalk.walkTypeID) }))
      .map(({
        walkType,
        ...pastWalk
      }) => ({
        ...pastWalk,
        id: parseInt(pastWalk.walkID, 10),
        photoURL: pastWalk.photoURL,
        completedTime: pastWalk.completedTime,
        type: walkType ? walkType.nameShort : 'unknown',
        unread: false,
      }))
  ),
  getPastWalkers: (state: ReduxState): PastWalker[] => (
    getWalksInThePast(state)
      .map((pastWalk) => pastWalk.walker)
      .reduce<PastWalker[]>((accumulator, currentValue) => {
        const preferredWalkers = getPreferredWalkers(state);
        const preferredIndex = preferredWalkers && preferredWalkers.length !== 0
          ? preferredWalkers.findIndex(
            (walker) => walker.id.toString() === currentValue.id.toString(),
          )
          : -1;
        const isPreferred = preferredIndex >= 0;

        if (!accumulator.length) {
          const updatedObj = {
            ...currentValue,
            numberOfTimesWalked: 1,
            // TODO
            isLiked: isPreferred,
          };
          return [updatedObj];
        }

        const foundIndex = accumulator.findIndex((walker) => walker.id === currentValue.id);

        if (foundIndex > -1) {
          const updatedObj = {
            ...accumulator[foundIndex],
            numberOfTimesWalked: accumulator[foundIndex].numberOfTimesWalked + 1,
            // TODO
            isLiked: isPreferred,
          };
          return [
            ...accumulator.slice(0, foundIndex),
            updatedObj,
            ...accumulator.slice(foundIndex + 1),
          ];
        }

        return [...accumulator, currentValue];
      }, [])
  ),
  getWalkRateAndTipSuccess: (state: ReduxState) => state.walks.walkRateAndTipSuccess,
};
export default selectors;
