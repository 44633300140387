import { ApiErrorV6 } from '../buildApiErrorPipeline';

export const createErrorForV6 = (
  error: ApiErrorV6,
  newSourceError?: ApiErrorV6,
): ApiErrorV6 => {
  const v6Error = (new Error(error as any) as unknown) as ApiErrorV6;
  v6Error.version = 6;

  // Copy error object to another
  const source = newSourceError || error;
  return Object.getOwnPropertyNames(source).reduce((accumulator, key) => {
    accumulator[key] = source[key];
    return accumulator;
  }, v6Error);
};
