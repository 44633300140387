import React, { Component } from 'react';
import md5 from 'md5';
import store from '../../store/configureStore';
import Facebook from './Facebook/Facebook';
import generateIDFALikeBrowserID from '../../utils/getIDFA';
import Google from './Google/Google';

import {
  getOwnerEmail,
  getOwnerFirstName,
  getOwnerLastName,
  getOwnerPhone,
} from '../../reducers/owner/owner.selectors';

import {
  getOwnerID,
} from '../../selectors';

import { isLoggedIn } from '../../reducers/auth/auth.selector';
import dataLayerInstance from './GoogleTagManager/DataLayer/dataLayerInstance';

import {
  FEATURE_SEGMENT_ENABLED,
} from '../../constants';
import { useSegmentTrack } from '../../hooks';

type ParentProps = {
  children: React$Node,
  browserID: string,
};
type Props = ParentProps;

export const DATALAYER = 'dataLayer';
export const FACEBOOK = 'facebook';
export const GOOGLE = 'google';
export const SEGMENT = 'segment';

export type FacebookEvent = {
  eventType: typeof FACEBOOK,
  eventName: string,
  options?: Object,
};

export type GoogleEvent = {
  eventType: typeof GOOGLE,
  eventName: string,
  options?: {
    eventLabel?: string,
  },
};

export type LogoutEvent = {
  eventType: 'logout',
};

// TODO: this is a bad practice, but is being temporarily used to pass flow until
//   tracking is rewritten
export type EventProps = Object;
/* keep old version for reference
export type EventProps =
  | FacebookEvent
  | GoogleEvent
  | LogoutEvent;
*/
export type TrackProps = EventProps[] | EventProps | null;
export type TrackType = (props: TrackProps, value: Record<string, any>) => void;
export type TrackerContextType = {
  track: TrackType,
};
export type EventType = Pick<EventProps, 'eventType'>['eventType'];
export const eventTypes: { [EventType]: EventType } = {
  [FACEBOOK]: FACEBOOK,
  [GOOGLE]: GOOGLE,
  [SEGMENT]: SEGMENT,
};

const trackFacebook = (
  { event, options, facebook }: {
    event: FacebookEvent,
    options?: Object,
    facebook: Facebook,
  },
) => {
  facebook.event(event.eventName, options);
};

const trackGoogle = (
  { event, google }: { event: GoogleEvent, google: Google },
) => {
  const { eventName } = event;
  const { eventLabel } = (event.options || {});
  google.trackEvent({
    eventCategory: eventName,
    eventLabel,
  });
};

// Note: The name of this function must start in capitallet
// because based on the rules-of-hooks, a function that uses
// hooks should start with a capital letter
const TrackSegment = ({
  event,
  options,
}) => {
  const { eventName } = event;

  const {
    execute: segmentTrack,
  } = useSegmentTrack();

  segmentTrack({
    event: eventName,
    data: options,
  });
};

const trackDataLayer = ({
  email,
}: {
  email: string,
}) => {
  if (email) {
    dataLayerInstance.push({
      dl_wag: md5(email),
    });
  }
};

const identifySegment = ({
  firstName,
  lastName,
  email,
  phone,
},
analytics, ownerId) => {
  analytics.identify(ownerId, {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
  });
};

const getTrack = ({
  facebook,
  google,
}: {
  facebook: Facebook,
  google: Google,
}): TrackType => (eventOrEvents, options = {}) => {

  if (!eventOrEvents) {
    return;
  }

  const track = (event: EventProps) => {
    if (!event) {
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('Tracking Event:', event); // eslint-disable-line
    }

    const state = store.getState();
    const isUserLoggedIn = isLoggedIn(state);

    // TODO: Consider moving to LoggedInContainer.js?, so that this
    // track() method can be specific to event tracking for each
    // data platform, and not worry about handling Segment identify calls
    if (event.eventType === SEGMENT && isUserLoggedIn) {
      if (!FEATURE_SEGMENT_ENABLED) {
        console.warn('Attempted to identify, but Segment is not enabled');
        return;
      }

      const email = getOwnerEmail(state);
      const firstName = getOwnerFirstName(state);
      const lastName = getOwnerLastName(state);
      const phone = getOwnerPhone(state);
      const ownerId = getOwnerID(state);
      const { analytics } = window;
      if (analytics) {
        identifySegment({
          email,
          firstName,
          lastName,
          phone,
        }, analytics, ownerId);
      }
    }

    if (event.eventType === SEGMENT) {
      TrackSegment({
        event,
        options,
      });
    }

    if (event.eventType === FACEBOOK) {
      trackFacebook({
        event,
        facebook,
      });
    }

    if (event.eventType === DATALAYER) {
      if (options) {
        trackDataLayer({
          email: options.email,
        });
      }
    }
    if (event.eventType === GOOGLE) {
      trackGoogle({
        event,
        google,
      });
    }
  };

  if (Array.isArray(eventOrEvents)) {
    eventOrEvents.forEach(event => track(event));
  } else {
    track(eventOrEvents);
  }
};

// provide default track method, used if consumer is rendered outside of a provider
export const TrackerContext = React.createContext<TrackerContextType>({
  track: () => console.log('TrackerProvider context not found'), // eslint-disable-line
});

class TrackerProvider extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.facebook = new Facebook({ key: process.env.REACT_APP_FACEBOOK_KEY || '' });
    this.google = new Google();
  }

  componentDidUpdate(prevProps: Props) { }

  facebook: Facebook;

  google: Google;

  render() {
    const { children } = this.props;
    return (
      <TrackerContext.Provider
        value={{
          track: getTrack({
            facebook: this.facebook,
            google: this.google,
          }),
        }}
      >
        {children}
      </TrackerContext.Provider>
    );
  }
}

export default TrackerProvider;
