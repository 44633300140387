import moment from 'moment';

export const isBookingScheduleInThePast = ({
  startDate,
  startTime,
}) => {
  if (typeof startDate !== 'string' && typeof startTime !== 'string') {
    return true;
  }
  const isStartTimeInRange = startTime && startTime.split('-').length === 2;
  // Get the start time
  const newStartTime = isStartTimeInRange ? startTime.split('-')[0] : startTime;

  const momentedStartDate = moment(startDate, 'YYYY-MM-DD');
  const momentedStartTime = moment(newStartTime, 'HH:mm A');

  // Check if it can be scheduled today
  const isStartDateInThePast = momentedStartDate.isBefore(moment(), 'day');
  const isStartDateInTheFuture = momentedStartDate.isAfter(moment(), 'day');
  const isStartTimeInThePast = momentedStartTime.isBefore(moment(), 'hour')
    || momentedStartTime.isBefore(moment(), 'minutes');

  if (isStartDateInTheFuture) {
    return false;
  }
  return isStartDateInThePast || isStartTimeInThePast;
};
