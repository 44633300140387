
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalkerProfileParsedResponse,
} from 'webApp';

import {
  WALKER_PROFILE_GET,
  WALKER_PROFILE_GET_SUCCESS,
  WALKER_PROFILE_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get Walker Profile
 */
export type GetWalkerProfilePayload = {
  walkerID: string,
};

export type GetWalkerProfileAction = {
  type: typeof WALKER_PROFILE_GET,
  payload: GetWalkerProfilePayload,
}

export const getWalkerProfile: (string) => GetWalkerProfileAction = createAction(
  WALKER_PROFILE_GET,
  walkerID => ({ walkerID }),
);


/**
 * Get Walker Profile success
 */
export type GetWalkerProfileSuccessAction = {
  type: typeof WALKER_PROFILE_GET_SUCCESS,
  payload: GetWalkerProfileParsedResponse,
};

export const getWalkerProfileSuccess: (
  GetWalkerProfileParsedResponse,
) => GetWalkerProfileSuccessAction = createAction(
  WALKER_PROFILE_GET_SUCCESS,
);


/**
 * Get Walker Profile failure
 */
export type GetWalkerProfileFailureAction = {
  type: typeof WALKER_PROFILE_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getWalkerProfileFailure: (
  ErrorParsedResponse,
) => GetWalkerProfileFailureAction = createAction(
  WALKER_PROFILE_GET_FAILURE,
);
