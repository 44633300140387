import { useCallback } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { configSlice } from '../slices/config';

export const useConfig = () => {
  const data = useSelector(configSlice.selectors.getConfig);
  const dispatch = useDispatch();

  const updateConfig = (
    config: Record<string, any> = {},
  ) => {
    dispatch(configSlice.actions.update(config));
  };

  const clearConfig = () => {
    dispatch(configSlice.actions.clear());
  };

  return {
    data,
    updateConfig,
    clearConfig: useCallback(clearConfig, [dispatch]),
  };
};
