import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { ApplyPromoCodeRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_PROMO_CODE, Endpoint } from "../../../constants/endpoints";
import { PROMO_CODE_APPLY } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { applyPromoCodeSuccess, applyPromoCodeFailure, ApplyPromoCodeAction } from "../../../actions/owner/applyPromoCode";
import { applyPromoCodeResponseParser } from "../../../helpers/Http/ResponseParsers";

const applyPromoCode$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(PROMO_CODE_APPLY), switchMap(({
  payload: {
    promoCode
  }
}: ApplyPromoCodeAction) => post$(({
  endpoint: ENDPOINT_PROMO_CODE({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    promoCode
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: ApplyPromoCodeRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: applyPromoCodeSuccess,
  onFailure: applyPromoCodeFailure,
  parser: applyPromoCodeResponseParser
})), catchError(handleErrorV5Response(action$, applyPromoCodeFailure)))));

export default applyPromoCode$;