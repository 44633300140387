export const PetInfoForm = {
  PetName: 'pet-name',
  PetBirthdate: 'pet-birthdate',
  PetBreed: 'pet-breed',
  PetImageUrl: 'pet-image-url',
  SubmitBtn: 'submit-btn',
};

export const PetInfoForm2 = {
  PetGenderMale: 'pet-gender--male',
  PetGenderFemale: 'pet-gender--female',
  PetSterilizedYes: 'pet-sterilized--yes',
  PetSterilizedNo: 'pet-sterilized--no',
  PetSterilizedNotSure: 'pet-sterilized--not-sure',
  PetWeightSmall: 'pet-weight--small',
  PetWeightMedium: 'pet-weight--medium',
  PetWeightLarge: 'pet-weight--large',
  PetWeightXL: 'pet-weight--xl',
  SubmitBtn: 'submit-btn',
};
