import React, { Component } from 'react';

import { connect } from 'react-redux';
import { DataTestIds } from  '../../../constants';
import type { StyledSystemProps } from 'webApp';

import { clear } from '../../../actions/notification';
import type { Notification as NotificationType } from '../../../reducers/notifications';
import onEnterPress from '../../../utils/onEnterPress';

type Props = NotificationType & {
  clear: Function,
  timeout?: number,
  notificationStyleProps: StyledSystemProps;
};

class Notification extends Component<Props> {
  static defaultProps = {
    type: 'info',
    timeout: 5000,
  };

  timeout = null;

  constructor(props) {
    super(props);

    const {
      timeout,
    } = this.props;

    this.timeout = setTimeout(this.clear, timeout);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  clear = () => {
    const {
      onClear,
      ID,
    } = this.props;

    if (onClear) {
      onClear(ID);
    }
  }

  render() {
    const {
      type,
      text,
      notificationStyleProps,
    } = this.props;

    return (
      <div
        className={`alert alert-${type || ''} notification text-center`}
        onClick={this.clear}
        data-testId={DataTestIds.Notification.notificationTestId}
        style={{
          ...notificationStyleProps,
        }}
        role="button"
        tabIndex={0}
        onKeyPress={onEnterPress}
      >
        {text}
      </div>
    );
  }
}

export default connect(null, {
  onClear: clear,
})(Notification);
