export const WellnessCheckoutForm = {
  AcceptedTerms: 'acceptedTerms',
  AddOrChangePaymentBtn: 'add-or-change-payment-btn',
  City: 'city',
  DeletePetPrefixBtn: 'delete-pet-btn',
  Email: 'email',
  ErrorText: 'error-text',
  ExpiryDate: 'expiryDate',
  FirstName: 'firstName',
  LastName: 'lastName',
  MobileTermsAndConditions: 'mobileTermsAndConditions',
  ModalCancelBtn: 'modal-cancel-btn',
  ModalDeleteBtn: 'modal-delete-btn',
  NoPaymentMethodText: 'no-payment-method-text',
  Phone: 'phone',
  SameBillingAndMailingDetails: 'isSameBillingAndMailingDetails',
  State: 'state',
  StreetAddress: 'streetAddress',
  SubmitBtn: 'submit-btn',
  SuiteOrApt: 'suiteOrApt',
  TotalText: 'total-text',
  WellnessTermsAndConditions: 'wellnessTermsAndConditions',
  ZipCode: 'zipCode',
} as const;
