import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetWalksUpcomingRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKS, Endpoint } from "../../../constants/endpoints";
import { WALKS_UPCOMING_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalksUpcomingSuccess, getWalksUpcomingFailure } from "../../../actions/walk/getWalksUpcoming";
import { getWalksUpcomingResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalksUpcoming$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKS_UPCOMING_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_WALKS,
  payload: {
    include_pending: 1,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetWalksUpcomingRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalksUpcomingSuccess,
  onFailure: getWalksUpcomingFailure,
  parser: getWalksUpcomingResponseParser
})), catchError(handleErrorV5Response(action$, getWalksUpcomingFailure)))));

export default getWalksUpcoming$;