import { ApiErrorV5 } from '../buildApiErrorPipeline';

export const createErrorForV5 = (
  error: ApiErrorV5,
  response: ApiErrorV5['response'] | undefined = undefined,
): ApiErrorV5 => {
  const v5Error = (new Error(error as any) as unknown) as ApiErrorV5;
  v5Error.response = response || error.response;
  v5Error.version = 5;
  return v5Error;
};
