import moment from "moment";
import type { BookingMeetGreetRequestResponse } from "wagAPI";
import type { BookingMeetGreetParsedResponse } from "webApp";
export const bookingMeetGreetResponseParser = ({
  schedule
}: BookingMeetGreetRequestResponse): BookingMeetGreetParsedResponse => ({
  id: String(schedule.id),
  startTime: moment(schedule.start_time, 'hh:mm:ss').format('h:mma'),
  startDate: moment(schedule.first_walk).format('MM/DD/YYYY'),
  requestTypeID: schedule.request_type,
  walkTypeID: schedule.walk_type_id
});
export default bookingMeetGreetResponseParser;