import { flatMap, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { GetWalkerRequestPayload } from "wagAPI";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER, Endpoint } from "../../../constants/endpoints";
import { WALKER_GET } from "../../../actions/actionTypes";
import { getWalkerSuccess, getWalkerFailure, GetWalkerAction } from "../../../actions/walker/getWalker";
import { getWalkerToken } from "../../../selectors";
import { getWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalker$ = (action$: Action$, state$: State$, {
  getLegacy$
}: Dependencies) => action$.pipe(ofType(WALKER_GET), switchMap(({
  payload: {
    id
  }
}: GetWalkerAction) => getLegacy$(({
  endpoint: ENDPOINT_WALKER,
  token: getWalkerToken(state$.value),
  payload: {
    id
  }
} as {
  endpoint: Endpoint;
  payload: GetWalkerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalkerSuccess,
  onFailure: getWalkerFailure,
  parser: getWalkerResponseParser
})), catchError(handleErrorV5Response(action$, getWalkerFailure)))));

export default getWalker$;