// TODO test this function
export const sortSmartModules = (smartModules) => [...smartModules].sort((a, b) => {
  // coerce sticky to a number and sort the objects first based on stickiness
  // if stickiness is equal then sort on weight
  if (+a.sticky < +b.sticky) {
    return 1;
  }

  if (+a.sticky === +b.sticky && a.weight < b.weight) {
    return 1;
  }

  if (+a.sticky > +b.sticky) {
    return -1;
  }

  if (+a.sticky === +b.sticky && a.weight > b.weight) {
    return -1;
  }

  return 0;
});
