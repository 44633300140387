export * from './loginByCodeForm';
export * from './loginByTwoFactorPasswordForm';
export * from './loginByPasswordForm';

export const LoginForm = {
  Email: 'email',
  EmailHelperText: 'email-helper-text',
  Password: 'password',
  ContinueBtn: 'continue-btn',
};
