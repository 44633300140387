
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalksInPendingReviewParsedResponse,
} from 'webApp';

import {
  WALKS_IN_PENDING_REVIEW_GET,
  WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
  WALKS_IN_PENDING_REVIEW_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walks in pending review for the current user
 */
export type GetWalksInPendingReviewAction = {
  type: typeof WALKS_IN_PENDING_REVIEW_GET,
}

export const getWalksInPendingReview: () => GetWalksInPendingReviewAction = createAction(
  WALKS_IN_PENDING_REVIEW_GET,
);


/**
 * Get walks in pending review for the current user success
 */
export type GetWalksInPendingReviewSuccessAction = {
  type: typeof WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
  payload: GetWalksInPendingReviewParsedResponse,
}

export const getWalksInPendingReviewSuccess: (
  GetWalksInPendingReviewParsedResponse,
) => GetWalksInPendingReviewSuccessAction = createAction(
  WALKS_IN_PENDING_REVIEW_GET_SUCCESS,
);


/**
 * Get walks in pending review for the current user failure
 */
export type GetWalksInPendingReviewFailureAction = {
  type: typeof WALKS_IN_PENDING_REVIEW_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalksInPendingReviewFailure: (
  ErrorParsedResponse,
) => GetWalksInPendingReviewFailureAction = createAction(
  WALKS_IN_PENDING_REVIEW_GET_FAILURE,
);
