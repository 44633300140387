import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import type { CreateOwnerPreferredWalkerRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_PREFERRED_WALKER_CREATE, Endpoint } from "../../../constants/endpoints";
import { OWNER_PREFERRED_WALKER_CREATE } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { createOwnerPreferredWalkerSuccess, createOwnerPreferredWalkerFailure, CreateOwnerPreferredWalkerAction } from "../../../actions/owner/createOwnerPreferredWalker";
import { createOwnerPreferredWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const createOwnerPreferredWalker$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_PREFERRED_WALKER_CREATE), retry(3), switchMap(({
  payload: {
    walkerID
  }
}: CreateOwnerPreferredWalkerAction) => post$(({
  endpoint: ENDPOINT_OWNER_PREFERRED_WALKER_CREATE({
    ownerID: getOwnerID(state$.value),
    walkerID
  }),
  payload: {},
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: CreateOwnerPreferredWalkerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: createOwnerPreferredWalkerSuccess,
  onFailure: createOwnerPreferredWalkerFailure,
  parser: createOwnerPreferredWalkerResponseParser
})), catchError(handleErrorV5Response(action$, createOwnerPreferredWalkerFailure)))));

export default createOwnerPreferredWalker$;