import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { UpdateCreditCardResponse, UpdateCreditCardRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_UPDATE_CREDIT_CARD, Endpoint } from "../../../constants/endpoints";
import { CREDIT_CARD_UPDATE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { updateCreditCardSuccess, updateCreditCardFailure, UpdateCreditCardAction } from "../../../actions/owner/updateCreditCard";
import { updateCreditCardResponseParser } from "../../../helpers/Http/ResponseParsers";
import { STATUS_CODE_CUSTOM_ERROR } from "../../../constants/statusCodes";
import { CVC_CHECK_ERROR } from "../../../constants/errors";
import { CVC_CHECK_FAIL } from "../../../constants/app";
// replaces the optional error object property with a string
type SanitizedResponse = UpdateCreditCardResponse & {
  error?: string;
};

const transformResponse = (response: UpdateCreditCardResponse): SanitizedResponse => {
  const finalResponse: SanitizedResponse = { ...response
  };

  if (response.error && typeof response.error === 'object') {
    const errorMessage = response.error && response.error.jsonBody && response.error.jsonBody.error && response.error.jsonBody.error.message;
    finalResponse.error = errorMessage;
    return finalResponse;
  }

  const allCards = response.all_cards && response.all_cards[0];

  if (allCards && allCards.cvc_check && allCards.cvc_check === CVC_CHECK_FAIL) {
    finalResponse.error_code = STATUS_CODE_CUSTOM_ERROR;
    finalResponse.error = CVC_CHECK_ERROR;
  }

  return finalResponse;
};

const updateCreditCard$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(CREDIT_CARD_UPDATE), switchMap(({
  payload: {
    stripeToken
  }
}: UpdateCreditCardAction) => post$(({
  endpoint: ENDPOINT_UPDATE_CREDIT_CARD,
  payload: {
    stripe_token: stripeToken,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: UpdateCreditCardRequestPayload;
  token: string;
})).pipe(flatMap((response: SanitizedResponse) => handleResponse({
  transformResponse,
  response,
  onSuccess: updateCreditCardSuccess,
  onFailure: updateCreditCardFailure,
  parser: updateCreditCardResponseParser
})), catchError(handleErrorV5Response(action$, updateCreditCardFailure)))));

export default updateCreditCard$;