import React from 'react';

import { SvgIcon, SvgIconProps } from '@waglabs/icons';

export type ChatIconProps = SvgIconProps;
export const ChatIcon = ({
  color = '#00BF8F',
  ...rest
}: ChatIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    color={color}
    {...rest}
  >
    <path
      d="M12 1c6.063 0 11 4.937 11 11s-4.937 11-11 11c-2.103 0-4.136-.59-5.887-1.707l-4.523 1.68a.411.411 0 01-.15.027.469.469 0 01-.3-.114.45.45 0 01-.122-.45L2.47 17.51A11.078 11.078 0 011 12C1 5.937 5.937 1 12 1zm4.84 13.2H7.16a.436.436 0 00-.44.44c0 .246.194.44.44.44h9.68c.246 0 .44-.194.44-.44a.436.436 0 00-.44-.44zm-1.76-2.64H7.16a.436.436 0 00-.44.44c0 .246.194.44.44.44h7.92c.246 0 .44-.194.44-.44a.436.436 0 00-.44-.44zm1.76-2.64H7.16a.436.436 0 00-.44.44c0 .246.194.44.44.44h9.68c.246 0 .44-.194.44-.44a.436.436 0 00-.44-.44z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);
