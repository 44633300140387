import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export type WellnessPlanWagPremiumIconProps = SvgIconProps;

export const WellnessPlanWagPremiumIcon = (
  props: WellnessPlanWagPremiumIconProps
) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M36.117 13.065c0 .348.094.673.258.954-10.4 5.45-14.347-1.286-15.717-5.294a1.92 1.92 0 0 0 1.284-1.805C21.942 5.86 21.072 5 20 5s-1.942.86-1.942 1.92a1.92 1.92 0 0 0 1.284 1.805c-1.37 4.008-5.318 10.744-15.716 5.294.163-.281.257-.606.257-.954 0-1.06-.869-1.92-1.941-1.92-1.073 0-1.942.86-1.942 1.92s.87 1.92 1.942 1.92c.341 0 .662-.087.94-.24L9.903 32h20.195l7.02-17.256c.28.154.6.241.941.241 1.073 0 1.942-.86 1.942-1.92s-.87-1.92-1.942-1.92-1.941.86-1.941 1.92Z"
        fill="#FABC44"
      />
      <path
        d="M38.058 11.145c1.073 0 1.942.86 1.942 1.92s-.87 1.92-1.942 1.92c-.341 0-.662-.087-.94-.24L30.097 32H14.7c4.114-1.952 7.129-4.676 9.044-8.17a34.183 34.183 0 0 0 3.177-8.11c2.322.696 5.397.424 9.453-1.701a1.893 1.893 0 0 1-.258-.954c0-1.06.869-1.92 1.941-1.92ZM10 33h20v3H10z"
        fill="#FAAB43"
      />
    </g>
  </SvgIcon>
);
