import React from 'react';
import Modal, { Props as ModalProps } from '@waglabs/modal';

import Flex from '@waglabs/flex';
import { Button } from '@waglabs/button-next';
import { Typography } from '@waglabs/typography';
import Box from '@waglabs/box';

type Props = ModalProps & {
  onCancelModalClick: () => void,
  onConfirmModalClick: () => void,
  onDenyModalClick: () => void,
};

export const ConfirmModal = (props: Props) => {
  const {
    onCancelModalClick,
    onConfirmModalClick,
    onDenyModalClick,
  } = props;

  const renderContent = () => (
    <Flex
      flexDirection="column"
      alignItems="center"
      py={[2, 3]}
      px={[0, 5]}
    >
      <Typography
        color="red"
        mb={1}
        textAlign="center"
        fontSize={[18, 28]}
      >
        Update Access Notes?
      </Typography>
      <Typography
        mt={[2, 3]}
        textAlign="center"
        fontSize={[14, 24]}
        color="medGray"
      >
        Would you like to set this as your default Access Notes for future bookings?
      </Typography>
      <Typography
        mt={[1, 2]}
        mb={[3, 5]}
        textAlign="center"
        fontSize={[14, 18]}
        color="wagGray"
      >
        (This will not apply to currently scheduled services.)
      </Typography>
      <Button
        variant="contained"
        onClick={onConfirmModalClick}
        width={[1, '356px']}
        fontSize={['16px', '24px']}
        py={[9, 12]}
        px={[14, 20]}
      >
        Yes
      </Button>
      <Button
        mt={2}
        onClick={onDenyModalClick}
        width={[1, '356px']}
        borderRadius={[3, '12px']}
        fontSize={['16px', '24px']}
        py={[9, 12]}
        px={[14, 20]}
      >
        No Thanks
      </Button>
    </Flex>
  );

  return (
    <>
      <Box
        height={['100vh']}
        width={1}
        zIndex={5}
        bg="black"
        onClick={onCancelModalClick}
        opacity={0.5}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      <Modal
        zIndex={5}
        containerProps={{
          height: 'auto',
          width: ['278px', '592px'],
        }}
        contentProps={{
          bg: 'white',
          mr: 'auto',
          ml: 'auto',
        }}
        disableHeader
        modalTopContent={renderContent}
        data-testid="error-modal-close"
        {...props}
      />
    </>
  );
};
