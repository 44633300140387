
import { createAction } from 'redux-actions';
import type {
  UpdatePreferredWalkerParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  PREFERRED_WALKER_UPDATE,
  PREFERRED_WALKER_UPDATE_SUCCESS,
  PREFERRED_WALKER_UPDATE_FAILURE,
} from '../../actionTypes';


/**
 * Update preferred walker
 */
export type UpdatePreferredWalkerParams = {
  walkerId: string,
  isPreferred: boolean,
}

export type UpdatePreferredWalkerPayload = UpdatePreferredWalkerParams;

export type UpdatePreferredWalkerAction = {
  type: typeof PREFERRED_WALKER_UPDATE,
  payload: UpdatePreferredWalkerPayload,
};

export const updatePreferredWalker: (UpdatePreferredWalkerParams) =>
  UpdatePreferredWalkerAction = createAction(
    PREFERRED_WALKER_UPDATE,
    ({ walkerId, isPreferred }) => ({ walkerId, isPreferred }),
  );

/**
 * Update preferred walker success
 */
export type UpdatePreferredWalkerSuccessAction = {
  type: typeof PREFERRED_WALKER_UPDATE_SUCCESS,
  payload: UpdatePreferredWalkerParsedResponse,
};

export const updatePreferredWalkerSuccess: (
  UpdatePreferredWalkerParsedResponse,
) => UpdatePreferredWalkerSuccessAction = createAction(
  PREFERRED_WALKER_UPDATE_SUCCESS,
);


/**
 * Update preferred walker failure
 */

export type UpdatePreferredWalkerFailureAction = {
  type: typeof PREFERRED_WALKER_UPDATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updatePreferredWalkerFailure: (
  ErrorParsedResponse,
) => UpdatePreferredWalkerFailureAction = createAction(
  PREFERRED_WALKER_UPDATE_FAILURE,
);
