
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  WALKER_PROFILE_BY_PROMO_CODE_GET,
  WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
  WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get walker profile by promo code
 */

export type GetWalkerProfileByPromoCodeParams = {
  promoCode: string,
};

export type GetWalkerProfileByPromoCodeAction = {
  type: typeof WALKER_PROFILE_BY_PROMO_CODE_GET,
};

export const getWalkerProfileByPromoCode: (
  params: GetWalkerProfileByPromoCodeParams
) => GetWalkerProfileByPromoCodeAction = createAction(
  WALKER_PROFILE_BY_PROMO_CODE_GET,
);


/**
 * Get walker profile by promo code success action
 */
export type GetWalkerProfileByPromoCodeSuccessAction = {
  type: typeof WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
};

export const getWalkerProfileByPromoCodeSuccess: (
  response: webApp.GetWalkerProfileByPromoCodeParsedResponse
) => GetWalkerProfileByPromoCodeSuccessAction = createAction(
  WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
);


/**
 * Get walker profile by promo code failure action
 */
export type GetWalkerProfileByPromoCodeFailureAction = {
  type: typeof WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const getWalkerProfileByPromoCodeFailure: (
  error: webApp.ErrorParsedResponse,
) => GetWalkerProfileByPromoCodeFailureAction = createAction(
  WALKER_PROFILE_BY_PROMO_CODE_GET_FAILURE,
);
