import { useCallback } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { draftsSlice } from '../slices/drafts';

export const useDraft = <T = any>(draftKey: string) => {
  const dispatch = useDispatch();
  const draft: T = useSelector(draftsSlice.selectors.getDraftByKey(draftKey));

  const addDraft = (values: any) => {
    dispatch(draftsSlice.actions.add({
      [draftKey]: values,
    }));
  };

  const clearDraft = () => {
    dispatch(draftsSlice.actions.clearByDraftKey({
      draftKey,
    }));
  };

  return {
    draft,
    addDraft: useCallback(addDraft, [
      dispatch,
      draftKey,
    ]),
    clearDraft: useCallback(clearDraft, [
      dispatch,
      draftKey,
    ]),
  };
};
