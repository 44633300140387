
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
} from 'webApp';

import {
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
} from '../../actionTypes';

/**
 * Verify walker migration
 */
export type VerifyWalkerMigrationAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
};

export const verifyWalkerMigration: () => VerifyWalkerMigrationAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION,
);

/**
 * Verify walker migration success
 */
export type VerifyWalkerMigrationSuccessAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
};

export const verifyWalkerMigrationSuccess: () => VerifyWalkerMigrationSuccessAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_SUCCESS,
);

/**
 * Verify walker migration failure
 */
export type VerifyWalkerMigrationFailureAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
  payload: ErrorParsedResponse,
};

export const verifyWalkerMigrationFailure: (
  ErrorParsedResponse,
) => VerifyWalkerMigrationFailureAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_MIGRATION_FAILURE,
);
