import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const PetWeightSmallIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 48 48"
    color="#9B7F5C"
    {...props}
  >
    <path
      d="M21.093 33.99a.507.507 0 0 0-.107.093c.009-.14.029-.307.114-.516.068-.166.244-.686.244-.736.067-.196.13-.39.188-.583l.02.499c-.125.787-.359 1.184-.459 1.243M32.23 21.097c.458.614.942 1.293.492 2.054-.261.442-.688.694-1.158.78a.438.438 0 0 0-.079-.065c-.778-.998-2.275-.973-3.532-.78-.4.046-2.685.289-3.688-.728.262-.427.46-.95.493-1.571.492-.246.977-.562 1.014-.8.07-.46.39-2.416.936-4.253.547-1.837-.512-2.31-.512-2.31-.364-.08-.767.054-1.18.315l.003-.004c-.493.29-1.012.802-1.497 1.375-.216-1-.577-1.934-1.178-2.11 0 0-.875-.067-1.165 1.356-.18.882-.436 1.763-.631 2.385a3.755 3.755 0 0 0-2.5.32 3.794 3.794 0 0 0-1.908 2.395.452.452 0 0 1-.079.185.167.167 0 0 1-.034.033.22.22 0 0 1-.101.034.517.517 0 0 1-.11.002 1.747 1.747 0 0 0-.762.162c-.072.033-.14.072-.205.115a1.383 1.383 0 0 0-.114-.008c-.525-.01-.833.321-.705.81.048.183.2.436.384.643l.007.034.002.006c.023.104.05.21.079.313l.005.017.04.14.014.044.034.107.017.048.032.095.018.048.03.083.025.061.023.058c.03.074.06.137.086.185.13.243.305.456.5.652a6.37 6.37 0 0 0 .53.47 2.776 2.776 0 0 0 .175.127l.056.037a3.077 3.077 0 0 0 .275.157l.072.038c.429.203.857.28 1.335.348.205.03.419.057.646.091l.02.004c.557 1.834.687 3.201 1.536 4.056.069.363.133.708.182.999.015 1.113-.027 2.595-.035 2.968-.174.777-.432 1.159-.535 1.212-.143.072-.49.44-.13.723.429.337 1.146.204 1.401-.019a.398.398 0 0 0 .155.186c.49.337 1.285.096 1.452-.17.117-.188-.027-.444.133-.918.057-.17.2-.7.197-.75.383-1.392.56-2.745.61-3.193.962.034 2.152-.012 3.638-.14-.015.206-.04.432-.075.684-.125.88.594 1.46.692 2.356.044.399-.036.86-.37 1.438-.83 1.44 1.6 1.269 2.117-.525.118-.408-.388-.483-.296-2.012a9.46 9.46 0 0 1 .102-.895c.099 1.05 1.274 1.764.274 3.435-.853 1.426 1.58 1.294 2.124-.491.167-.545-.798-.508.121-4.154.176-.696.636-1.286.755-2.004.096-.578-.194-1.488-.466-2.268a2.898 2.898 0 0 0 1.761-1.29c.746-1.22-.535-2.494-1.548-2.726"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);
