
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  HomeAccess,
} from 'webApp';
import {
  OWNER_HOME_ACCESS_UPDATE,
  OWNER_HOME_ACCESS_UPDATE_FAILURE,
  OWNER_HOME_ACCESS_UPDATE_SUCCESS,
} from '../../actionTypes';

export type OwnerHomeAccessUpdatePayload = {
  id: string,
  home_access_details: HomeAccess;
}

export type OwnerHomeAccessUpdateAction = {
  type: typeof OWNER_HOME_ACCESS_UPDATE,
  payload: HomeAccess,
};

export const updateOwnerHomeAccess: (
  HomeAccess
) => OwnerHomeAccessUpdateAction = createAction(
  OWNER_HOME_ACCESS_UPDATE,
  payload => (payload),
);

/**
 * Update owner's home access Success
 */
export type OwnerHomeAccessUpdateSuccessAction = {
  type: typeof OWNER_HOME_ACCESS_UPDATE_SUCCESS
};

export const updateOwnerHomeAccessSuccess: (
) => OwnerHomeAccessUpdateSuccessAction = createAction(
  OWNER_HOME_ACCESS_UPDATE_SUCCESS,
);


/**
 * Update owner's home access Failure
 */
export type OwnerHomeAccessUpdateFailureAction = {
  type: typeof OWNER_HOME_ACCESS_UPDATE_FAILURE,
  payload: ErrorParsedResponse,
};

export const updateOwnerHomeAccessFailure: (
  ErrorParsedResponse,
) => OwnerHomeAccessUpdateFailureAction = createAction(
  OWNER_HOME_ACCESS_UPDATE_FAILURE,
);
