export const Form = {
  HeaderText: 'header-text',
  StreetAddress: 'street-address',
  AutoCompleteMenu: 'autocomplete-menu',
  AutoCompleteDropdownOption: 'address-form-street-autocomplete-item-option',
  ApartmentNumber: 'apartment-number',
  Gender: 'gender',
  Documents: 'documents',
  SubmitBtn: 'submit-button',
};
