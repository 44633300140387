import type { PaymentMethod as PaymentMethodResponse } from "wagAPI";
import type { PaymentMethod } from "webApp";
export const ownerPaymentMethodResponseParser = (response: PaymentMethodResponse): PaymentMethod => ({
  id: response.id,
  brand: response.brand,
  expMonth: response.exp_month,
  expYear: response.exp_year,
  last4: response.last4,
  tokenizationMethod: response.tokenization_method,
  isDefault: response.is_default
});
export default ownerPaymentMethodResponseParser;