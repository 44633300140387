import { handleActions } from 'redux-actions';

import {
  WALK_RATE_SORRY_MODAL_SHOW,
  WALK_RATE_SORRY_MODAL_HIDE,
} from '../../actions/actionTypes';
import type {
  SorryModalShowAction,
  SorryModalHideAction,
} from '../../actions/sorryModal/sorryModal.actions';

export type State = {
  isVisible: boolean;
  rating: number | null | undefined;
  hasBeenDismissed: boolean;
};
export const initialState = {
  isVisible: false,
  rating: null,
  hasBeenDismissed: false,
};
export const reducer: any = handleActions({
  [WALK_RATE_SORRY_MODAL_SHOW]: (state: State, {
    payload,
  }: SorryModalShowAction): State => ({ ...state,
    isVisible: payload.rating <= 3,
    rating: payload.rating,
    hasBeenDismissed: false }),
  [WALK_RATE_SORRY_MODAL_HIDE]: (state: State, {
    payload,
  }: SorryModalHideAction): State => ({ ...state,
    isVisible: payload.isVisible,
    hasBeenDismissed: payload.hasBeenDismissed }),
}, initialState);
