import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetWalksInThePastRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKS_IN_THE_PAST, Endpoint } from "../../../constants/endpoints";
import { WALKS_IN_THE_PAST_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalksInThePastSuccess, getWalksInThePastFailure } from "../../../actions/walk/getWalksInThePast";
import { getWalksInThePastResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalksInThePast$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKS_IN_THE_PAST_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_WALKS_IN_THE_PAST,
  payload: {
    id: getOwnerID(state$.value),
    limit: 10,
    offset: 0
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetWalksInThePastRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getWalksInThePastSuccess,
  onFailure: getWalksInThePastFailure,
  parser: getWalksInThePastResponseParser
})), catchError(handleErrorV5Response(action$, getWalksInThePastFailure)))));

export default getWalksInThePast$;