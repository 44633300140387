import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import type { BookingUpdateScheduleRequestPayload } from 'wagAPI';

import { WALK_SCHEDULE_UPDATE } from '../../../actions/actionTypes';
import {
  updateWalkScheduleSuccess,
  updateWalkScheduleFailure,
  updateWalkScheduleAction,
} from '../../../actions/walk/updateWalkSchedule';
import { WALK_TYPE_REGULAR_WALK } from '../../../constants/app';
import {
  ENDPOINT_BOOKING_SET_SCHEDULE,
  Endpoint,
} from '../../../constants/endpoints';
import { mapFormToRequest } from '../../../containers/AccessInfo/AccessSummary';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import { bookingSetScheduleResponseParser } from '../../../helpers/Http/ResponseParsers';
import {
  getOwnerToken,
  getOwnerID,
} from '../../../selectors';

// single/recurring/dropin booking
const updateWalkSchedule$ = (action$: Action$, state$: State$, {
  post$,
}: Dependencies) => action$.pipe(ofType(WALK_SCHEDULE_UPDATE), switchMap(({
  payload: {
    accessInfo,
    dogNotes,
    // defaultHomeAccess,
    isRecurring,
    onDemand,
    startDate,
    startTime,
    usePreferredWalker,
    walkTimes = [],
    walkTypeId,
    selectedDogs,
    scheduleRecurringTypeId,
  },
}: updateWalkScheduleAction) => post$(({
  endpoint: ENDPOINT_BOOKING_SET_SCHEDULE({
    ownerID: getOwnerID(state$.value),
  }),
  payload: {
    walk_type: walkTypeId || WALK_TYPE_REGULAR_WALK,
    walk_times: walkTimes.length > 0 ? walkTimes : [{
      date: startDate,
      time_of_day: startTime,
    }],
    start_date: walkTimes.length > 0 ? startDate : undefined,
    is_recurring: isRecurring || false,
    is_on_demand: onDemand || false,
    use_preferred: usePreferredWalker,
    use_fastest_available: true,
    dogs: selectedDogs && selectedDogs.length ? selectedDogs.map((dog) => dog.id) : [],
    notes: dogNotes,
    htgi: mapFormToRequest(accessInfo),
    // optionally add `schedule_recurring_type_id` in the payload if it exists
    ...scheduleRecurringTypeId && {
      schedule_recurring_type_id: scheduleRecurringTypeId,
    },
  },
  token: getOwnerToken(state$.value),
} as {
  endpoint: Endpoint;
  payload: BookingUpdateScheduleRequestPayload;
  token: string;
})).pipe(flatMap((response) => handleResponse({
  response,
  onSuccess: updateWalkScheduleSuccess,
  onFailure: updateWalkScheduleFailure,
  parser: bookingSetScheduleResponseParser,
})), catchError(handleErrorV5Response(action$, updateWalkScheduleFailure)))));

export default updateWalkSchedule$;
