
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  OwnerRefreshTokenParsedResponse,
} from 'webApp';
import {
  OWNER_AUTH_REFRESH_GET,
  OWNER_AUTH_REFRESH_GET_FAILURE,
  OWNER_AUTH_REFRESH_GET_SUCCESS,
} from '../../actionTypes';


/**
* Refresh Token get request
*/
export type GetOwnerRefreshTokenAction = {
  type: typeof OWNER_AUTH_REFRESH_GET,
};

export const getOwnerRefreshToken: (
) => GetOwnerRefreshTokenAction = createAction(
  OWNER_AUTH_REFRESH_GET,
);


/**
* Refresh Token success
*/
export type GetOwnerRefreshTokenSuccessParams = {
  ownerToken: string,
};

export type GetOwnerRefreshTokenSuccessPayload = GetOwnerRefreshTokenSuccessParams;

export type GetOwnerRefreshTokenSuccessAction = {
  type: typeof OWNER_AUTH_REFRESH_GET_SUCCESS,
  payload: OwnerRefreshTokenParsedResponse,
};

export const getOwnerRefreshTokenSuccess: (
  GetOwnerRefreshTokenSuccessParams,
) => GetOwnerRefreshTokenSuccessAction = createAction(
  OWNER_AUTH_REFRESH_GET_SUCCESS,
  ({
    ownerToken,
  }) => ({
    ownerToken,
  }),
);


/**
* Refresh Token failure
*/
export type GetOwnerRefreshTokenFailureAction = {
  type: typeof OWNER_AUTH_REFRESH_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerRefreshTokenFailure: (
  ErrorParsedResponse,
) => GetOwnerRefreshTokenFailureAction = createAction(
  OWNER_AUTH_REFRESH_GET_FAILURE,
);
