import { switchMap, flatMap, catchError, retry } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type { Action$, State$, Dependencies } from 'redux-observable';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_AUTHENTICATION_SESSION_GET,
  Endpoint,
} from '../../../constants/endpoints';
import { AUTHENTICATION_SESSION_GET } from '../../../actions/actionTypes';
import { getOwnerToken } from '../../../selectors';
import {
  getAuthenticationSessionSuccess,
  getAuthenticationSessionFailure,
} from '../../../actions/auth/getAuthenticationSession';
import { getAuthenticationSessionResponseParser } from '../../../helpers/Http/ResponseParsers';

const getAuthenticationSession$ = (
  action$: Action$,
  state$: State$,
  { get$ }: Dependencies
) =>
  action$.pipe(
    ofType(AUTHENTICATION_SESSION_GET),
    retry(3),
    switchMap(() =>
      get$({
        endpoint: ENDPOINT_AUTHENTICATION_SESSION_GET,
        token: getOwnerToken(state$.value),
      } as {
        endpoint: Endpoint;
        token: string;
      }).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: getAuthenticationSessionSuccess,
            onFailure: getAuthenticationSessionFailure,
            parser: getAuthenticationSessionResponseParser,
          })
        ),
        catchError(
          handleErrorV5Response(action$, getAuthenticationSessionFailure)
        )
      )
    )
  );

export default getAuthenticationSession$;
