import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import { BOOKING_EDIT_SCHEDULE } from '../../../actions/actionTypes';
import { cancelSchedule$$ } from '../cancelSchedule/cancelSchedule.epic';
import { setSchedule$$ } from '../setSchedule/setSchedule.epic';
import {
  bookingEditScheduleFailure,
  bookingEditScheduleSuccess,
  BookingEditScheduleParams,
} from '../../../actions/booking/bookingEditSchedule/bookingEditSchedule.actions';
export const postEditSchedule = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies,
  {
    scheduleID,
    date,
    day,
    mode,
    cancellationReason,
    ...setSchedulePayload
  }: BookingEditScheduleParams
) =>
  cancelSchedule$$(
    action$,
    state$,
    {
      post$,
    },
    {
      scheduleID,
      date,
      day,
      mode,
      cancellationReason,
    }
  ).pipe(
    flatMap(({ payload }) => {
      if (payload.error || payload.errorCode) {
        return throwError(payload.error);
      }

      return setSchedule$$(
        action$,
        state$,
        {
          post$,
        },
        setSchedulePayload
      ).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: bookingEditScheduleSuccess,
            onFailure: bookingEditScheduleFailure,
            parser: () => response.payload,
          } as any) // TODO - Update typings on handleResponse
        ),
        catchError(handleErrorV5Response(action$, bookingEditScheduleFailure))
      );
    })
  );

const editSchedule$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(BOOKING_EDIT_SCHEDULE),
    retry(3),
    switchMap(({ payload }) =>
      postEditSchedule(
        action$,
        state$,
        {
          post$,
        },
        payload
      )
    )
  );

export default editSchedule$;
