import type { GetWalksInThePastResponse, WalkInThePast as WalkInThePastWagApi } from "wagAPI";
import type { GetWalksInThePastParsedResponse, WalkInThePast as WalkInThePastWebApp } from "webApp";
import { WALK_STATUS } from "../../../constants/app";
import { fromDateToTime } from "../../../utils/scheduleFormatter";
export const parseWalkInThePast = (walk: WalkInThePastWagApi): WalkInThePastWebApp => {
  const startDateMatcher = (walk.date || '').match(/(\d{4})-(\d\d?)-(\d\d?)/); // year-month-day

  const startDate = startDateMatcher ? `${startDateMatcher[2]}/${startDateMatcher[3]}/${startDateMatcher[1]}` : '';
  const isCancelled = Boolean(Number(walk.is_cancelled));
  const isConfirmed = Boolean(Number(walk.is_confirmed));
  const {
    walker: {
      first_name: firstName,
      last_name: lastName,
      thumb
    }
  } = walk;
  const result = {
    distanceInMiles: Number(walk.distance.toFixed(2)),
    dogs: walk.dogs.map(({
      id
    }) => id),
    endTime: fromDateToTime(walk.walk_end),
    engagedTime: fromDateToTime(walk.walk_engaged),
    isCancelled,
    isCompleted: true,
    isConfirmed: isConfirmed && !isCancelled,
    isDoorLocked: Boolean(walk.is_door_locked),
    isPee: Boolean(walk.is_pee),
    isPending: !isConfirmed && !isCancelled,
    isPoo: Boolean(walk.is_poo),
    isWalkerRated: Boolean(walk.is_walker_rated),
    lengthInMinutes: Math.ceil(walk.time),
    photoURL: walk.photo_url,
    note: walk.note,
    scheduleID: String(walk.schedule_id),
    startDate,
    startTime: fromDateToTime(walk.walk_start),
    startedTime: fromDateToTime(walk.walk_started),

    /**
     * ! TODO: Remove `status` fallback value once `api/v5/shim/owner/walk-history-with-loc`
     * endpoint supports returning the `state` (maps to `status`) property of the walk.
     * It is safe to assume the response will always have a `state` property
     */
    status: walk.state || WALK_STATUS.COMPLETED_NO_REPORT,
    completedTime: walk.walk_completed,
    tip: walk.tip,
    total: walk.total,
    updatedAt: walk.updated_at,
    video: walk.video_url,
    walker: {
      firstName,
      id: String(walk.walker_id),
      lastName,
      thumb
    },
    walkerID: Number(walk.walker_id) || 0,
    walkID: String(walk.id),
    walkTypeID: Number(walk.walk_type_id),
    type: walk.type
  };
  return result;
};
export const getWalksInThePastResponseParser = ({
  walks
}: GetWalksInThePastResponse): GetWalksInThePastParsedResponse => walks.map((walk: WalkInThePastWagApi): WalkInThePastWebApp => parseWalkInThePast(walk));
export default getWalksInThePastResponseParser;