import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetCreditPackagesRequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_CREDIT_PACKAGES, Endpoint } from "../../../constants/endpoints";
import { CREDIT_PACKAGES_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getCreditPackagesSuccess, getCreditPackagesFailure } from "../../../actions/owner/getCreditPackages";
import { getCreditPackagesResponseParser } from "../../../helpers/Http/ResponseParsers";

const getCreditPackages$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(CREDIT_PACKAGES_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_CREDIT_PACKAGES,
  payload: {
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetCreditPackagesRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getCreditPackagesSuccess,
  onFailure: getCreditPackagesFailure,
  parser: getCreditPackagesResponseParser
})), catchError(handleErrorV5Response(action$, getCreditPackagesFailure)))));

export default getCreditPackages$;