import {
  useCallback,
  useMemo,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import moment from 'moment';

import {
  getSessionToken,
  getSessionTokenCreatedAt
} from '../reducers/auth';

import { authSlice } from '../slices/auth';

export const useSession = () => {
  const dispatch = useDispatch();
  const sessionToken = useSelector(getSessionToken);
  const sessionTokenCreatedAt = useSelector(getSessionTokenCreatedAt);

  /**
   * Token will be valid for the 7 days (1 week)
   * after that, it is expected to be expired
   */
  const isInvalid = useMemo(() => {
    if (!sessionTokenCreatedAt) {
      // There should be a sessionTokenCreatedAt always
      // otherwise, we declare it is invalid
      return true;
    }

    const maxDateToken = moment(sessionTokenCreatedAt).add(7, 'days');
    // Negate the return which means the date is not between the following
    const isExpired = !moment().isBetween(sessionTokenCreatedAt, maxDateToken);
    return isExpired;
  }, [sessionTokenCreatedAt]);

  const clear = () => {
    dispatch(authSlice.actions.clearSession());
  };

  return {
    sessionToken,
    sessionTokenCreatedAt,
    isInvalid,
    clear: useCallback(clear, [dispatch]),
  };
};
