const mapRatingBadgesToRatingBadge = ratingBadge => ({ ...ratingBadge,
  imageUrl: ratingBadge.image
});

const mapProfileServicesToProfileService = profileService => ({ ...profileService,
  imageUrl: profileService.image,
  isChecked: profileService.is_checked
});

const mapFieldsToField = field => ({ ...field,
  tags: field.tags.filter(({
    is_checked: isChecked
  }) => isChecked).map(({
    label
  }) => label)
});

const mapBadgesToAchievement = badge => ({ ...badge,
  icon: badge.asset,
  iconLarge: badge.asset_large
});

const sortAchievementsByWeightInDescendingOrder = (achievementA, achievementB) => achievementB.weight - achievementA.weight;

const mapProfilesToProfile = ({
  id,
  isPreferred,
  link,
  name,
  rating,
  rating_count: ratingCount,
  services_count: servicesCount,
  catchphrase,
  picture: pictureUrl,
  thumbnail: thumbnailUrl,
  tags: ratingBadges,
  bio,
  video: videoUrl,
  services: profileServices,
  fields,
  badges
}) => ({
  id,
  isPreferred,
  link,
  name,
  rating,
  ratingCount,
  servicesCount,
  catchphrase,
  pictureUrl,
  thumbnailUrl,
  ratingBadges: ratingBadges.map(mapRatingBadgesToRatingBadge),
  bio,
  videoUrl,
  services: profileServices.map(mapProfileServicesToProfileService),
  fields: fields.map(mapFieldsToField),
  achievements: badges.map(mapBadgesToAchievement).sort(sortAchievementsByWeightInDescendingOrder)
});

export const getServiceProvidersResponseParser = ({
  data: {
    profiles,
    city,
    state,
    service,
    page,
    latitude,
    longitude
  }
}: any) => ({
  profiles: profiles.map(mapProfilesToProfile),
  city,
  state,
  service,
  page,
  latitude,
  longitude
});