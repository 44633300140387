import { handleActions } from 'redux-actions';

import {
  ALERT,
  ALERT_CLEAR,
  ALERTS_CLEAR,
} from '../../actions/actionTypes';
import type {
  AlertAction,
  ClearAlertAction,
} from '../../actions/alert';

export type Alert = {
  ID: number;
  type: string;
  data: any;
};
export type AlertState = {
  alerts: Array<Alert>;
  lastID: number;
};
export const initialState = {
  alerts: [],
  lastID: 0,
};
export const reducer: any = handleActions({
  [ALERT]: (state: AlertState, {
    payload: {
      alertType,
      data,
    },
  }: AlertAction): AlertState => ({ ...state,
    alerts: state.alerts.concat({
      ID: state.lastID + 1,
      type: alertType,
      data,
    }),
    lastID: state.lastID + 1 }),
  [ALERT_CLEAR]: (state: AlertState, {
    payload: {
      alertID,
    },
  }: ClearAlertAction): AlertState => ({ ...state,
    alerts: state.alerts.filter(({
      ID,
    }) => ID !== alertID) }),
  [ALERTS_CLEAR]: (state: AlertState): AlertState => ({ ...state,
    alerts: [] }),
}, initialState);
export default reducer;
