import { useLocation } from 'react-router-dom';

import { useSegmentPageTransition } from '@waglabs/segment';

/**
 * Creating a component instead of putting this on the `renderTracked` in the `App.tsx` due
 * to some React weird issues about incorrect React version
 */
export const SegmentPageTrigger = () => {
  const location = useLocation();

  useSegmentPageTransition(location.pathname, {
    /**
     * Give time for the nested routes to run the <Helmet>
     */
    delayTracking: 1000,
  });

  return null;
};
