import {
  ACCESS_METHOD_KEY_HIDDEN,
  ACCESS_METHOD_LOCKBOX,
  ACCESS_METHOD_KEY_WITH_DOORMAN,
  ACCESS_METHOD_WILL_BE_HOME,
  ACCESS_METHOD_OTHER,
} from '../../constants/app';

export const AccessMethodDescriptions = {
  [ACCESS_METHOD_KEY_HIDDEN]: 'Key Hidden',
  [ACCESS_METHOD_LOCKBOX]: 'Lockbox',
  [ACCESS_METHOD_KEY_WITH_DOORMAN]: 'Key with Doorman',
  [ACCESS_METHOD_WILL_BE_HOME]: 'Someone will be Home',
  [ACCESS_METHOD_OTHER]: 'Other',
};
