import * as React from 'react';

import { useDispatch } from 'react-redux';

import { Box } from '@waglabs/box-next';

import { logout } from '../../../../../../../actions/owner/auth/auth.actions'; // dispatch function and payload
import {
  apiSlice,
  walkerApiSlice,
} from '../../../../../../../slices/apiSlice';
import { draftsSlice } from '../../../../../../../slices/drafts';
import { petsSlice } from '../../../../../../../slices/pets';
import { servicesSlice } from '../../../../../../../slices/services';
import { wellnessSlice } from '../../../../../../../slices/wellness';

import { LogoutButton } from './LogoutButton';

const LogoutButtonContainer = () => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    /**
     * Clear drafts on logout to avoid reusing drafts
     * on another session (i.e different user)
     */
    dispatch(draftsSlice.actions.clear());
    /**
     * Clear api state to clear the cache
     */
    dispatch(apiSlice.util.resetApiState());
    dispatch(walkerApiSlice.util.resetApiState());
    /**
     * Clear services to avoid reuse previous services
     * when we start polling again
     */
    dispatch(servicesSlice.actions.clear());
    /**
     * Clear wellness state to clear previous form records
     */
    dispatch(wellnessSlice.actions.clear());
    /**
     * Clear pet state
     */
    dispatch(petsSlice.actions.clear());
    dispatch(logout());
  };

  return (
    <Box
      width={1}
    >
      <LogoutButton
        onClick={handleOnClick}
      />
    </Box>
  );
};

export default LogoutButtonContainer;
