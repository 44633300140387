import { useGetUrlQuery } from './useGetUrlQuery';

export const useGetWalkerFromUrlQuery = () => {
  const {
    walker_name: walkerName = '',
    walker_image: walkerImage = '',
    walker_uuid: walkerUuid = '',
  } = useGetUrlQuery();

  const walker = {
    name: String(walkerName),
    imageUrl: String(walkerImage),
    uuid: String(walkerUuid),
  };

  const isValid = !!walker.name && !!walker.imageUrl && !!walker.uuid;

  return {
    ...walker,
    isValid,
  };
};
