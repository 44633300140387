const PetTypeName = {
  Dog: 'dog',
  Cat: 'cat',
  Rabbit: 'rabbit',
  Hamster: 'hamster',
  Rodent: 'rodent',
  Fish: 'fish',
  Lizard: 'lizard',
  Bird: 'bird',
  Ferret: 'ferret',
  Turtle: 'turtle',
  Tortoise: 'tortoise',
};

const PetSize = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  ExtraLarge: 'extraLarge',
  Unspecified: 'unspecified',
};

const PetWeight = {
  Small: 5,
  Medium: 21,
  Large: 51,
  XL: 100,
};

const PetSizeOptions = [
  {
    label: 'S: 5 - 20 lb',
    value: PetSize.Small,
    weightValue: PetWeight.Small,
  },
  {
    label: 'M: 21 - 50 lb',
    value: PetSize.Medium,
    weightValue: PetWeight.Medium,
  },
  {
    label: 'L: 51 - 99 lb',
    value: PetSize.Large,
    weightValue: PetWeight.Large,
  },
  {
    label: 'XL: 100+ lb',
    value: PetSize.ExtraLarge,
    weightValue: PetWeight.XL,
  },
];

const PetSterile = {
  Yes: 'yes',
  No: 'no',
  Unspecified: 'unspecified',
};

const PetGender = {
  Female: 'female',
  Male: 'male',
  Unspecified: 'unspecified',
};

export const PetStrings = {
  TypeNames: PetTypeName,
  Size: PetSize,
  SizeOptions: PetSizeOptions,
  Weight: PetWeight,
  Sterile: PetSterile,
  Gender: PetGender,
};
