import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { // UpdatePreferredWalkerResponse,
UpdatePreferredWalkerRequestPayload } from "wagAPI";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorResponse, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_UPDATE_PREFERRED_WALKER, Endpoint } from "../../../constants/endpoints";
import { PREFERRED_WALKER_UPDATE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { updatePreferredWalkerSuccess, updatePreferredWalkerFailure, UpdatePreferredWalkerAction } from "../../../actions/owner/updatePreferredWalker";
import { updatePreferredWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const updatePreferredWalker$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(PREFERRED_WALKER_UPDATE), switchMap(({
  payload: {
    walkerId,
    isPreferred
  }
}: UpdatePreferredWalkerAction) => post$(({
  endpoint: ENDPOINT_UPDATE_PREFERRED_WALKER,
  payload: {
    id: getOwnerID(state$.value),
    walker_id: walkerId,
    remove: isPreferred ? 0 : 1 // 0 - add owner as preferred, 1 - remove user

  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: UpdatePreferredWalkerRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updatePreferredWalkerSuccess,
  onFailure: updatePreferredWalkerFailure,
  parser: updatePreferredWalkerResponseParser
})), catchError(response => handleErrorResponse({
  response,
  onError: updatePreferredWalkerFailure
})))));

export default updatePreferredWalker$;