import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const DogNotesOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M17.652 10.68H6.348V9.8h11.304v.88zM22 2.98V21.9c0 .607-.487 1.1-1.087 1.1H3.087C2.487 23 2 22.507 2 21.9V2.98c0-.607.487-1.1 1.087-1.1h3.26V1h.87v.88h4.348V1h.87v.88h4.348V1h.87v.88h3.26c.6 0 1.087.493 1.087 1.1zm-.87 4.18H2.87V21.9c0 .123.095.22.217.22h17.826a.217.217 0 00.217-.22V7.16zm0-4.18a.217.217 0 00-.217-.22h-3.26v1.76h-.87V2.76h-4.348v1.76h-.87V2.76H7.217v1.76h-.87V2.76h-3.26a.217.217 0 00-.217.22v3.3h18.26v-3.3zm-5.217 9.46H6.348v.88h9.565v-.88zm1.74 2.64H6.347v.88h11.304v-.88zm-1.74 2.64H6.348v.88h9.565v-.88z"
      fill="#727272"
      fill-rule="evenodd"
    />
  </SvgIcon>
);

