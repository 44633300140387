import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_PASSWORD_TIPS } from "../../../constants/endpoints";
import { PASSWORD_TIPS } from "../../../actions/actionTypes";
import { getPasswordTipsSuccess, getPasswordTipsFailure } from "../../../actions/password";

const passwordTips$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(PASSWORD_TIPS), switchMap(() => get$(({
  endpoint: ENDPOINT_PASSWORD_TIPS
} as {
  endpoint: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getPasswordTipsSuccess,
  onFailure: getPasswordTipsFailure
})), catchError(handleErrorV5Response(action$, getPasswordTipsFailure)))));

export default passwordTips$;