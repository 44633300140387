
import { createAction } from 'redux-actions';
import type {
  GetOwnerServicesParsedResponse,
  ErrorParsedResponse,
} from 'webApp';

import {
  OWNER_SERVICES_GET,
  OWNER_SERVICES_GET_SUCCESS,
  OWNER_SERVICES_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get owner services
 */
export type GetOwnerServicesAction = {
  type: typeof OWNER_SERVICES_GET,
};

export const getOwnerServices: () => GetOwnerServicesAction = createAction(
  OWNER_SERVICES_GET,
);


/**
 * Get owner services success
 */
export type GetOwnerServicesSuccessAction = {
  type: typeof OWNER_SERVICES_GET_SUCCESS,
  payload: GetOwnerServicesParsedResponse,
};

export const getOwnerServicesSuccess: (
  GetOwnerServicesParsedResponse,
) => GetOwnerServicesSuccessAction = createAction(
  OWNER_SERVICES_GET_SUCCESS,
);


/**
 * Get owner services failure
 */
export type GetOwnerServicesFailureAction = {
  type: typeof OWNER_SERVICES_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerServicesFailure: (
  ErrorParsedResponse,
) => GetOwnerServicesFailureAction = createAction(
  OWNER_SERVICES_GET_FAILURE,
);
