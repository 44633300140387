 
type DataObject = {
  [string]: string | DataObject,
  event?: string,
};
type Data = DataObject[];

type Props = {
  initialValues?: Data,
};

class DataLayer {
  constructor(props?: Props) {
    window.dataLayer = (window && window.dataLayer)
      || (props && props.initialValues)
      || [];
  }

  push = (...values: DataObject[]) => {
    window.dataLayer.push(...values);
  }
}

export default DataLayer;
