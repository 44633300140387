import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_SERVICE_PROVIDERS_GET, Endpoint } from "../../../constants/endpoints";
import { SERVICE_PROVIDERS_GET } from "../../../actions/actionTypes";
import { getOwnerID, getOwnerToken } from "../../../selectors";
import { getServiceProvidersSuccess, getServiceProvidersFailure } from "../../../actions/owner/getServiceProviders";
import { getServiceProvidersResponseParser } from "../../../helpers/Http/ResponseParsers";

const getServiceProviders$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(SERVICE_PROVIDERS_GET), retry(3), switchMap(({
  payload: {
    service
  }
}) => get$(({
  endpoint: ENDPOINT_SERVICE_PROVIDERS_GET({
    ownerID: getOwnerID(state$.value),
    service
  }),
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: getServiceProvidersSuccess,
  onFailure: getServiceProvidersFailure,
  parser: getServiceProvidersResponseParser
})), catchError(handleErrorV5Response(action$, getServiceProvidersFailure)))));

export default getServiceProviders$;