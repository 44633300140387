import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { BookingSetScheduleRequest } from "webApp";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_BOOKING_SET_SCHEDULE, Endpoint } from "../../../constants/endpoints";
import { BOOKING_SET_SCHEDULE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { bookingSetScheduleSuccess, bookingSetScheduleFailure, BookingSetScheduleAction } from "../../../actions/booking/bookingSetSchedule";
import { bookingSetScheduleResponseParser } from "../../../helpers/Http/ResponseParsers";
import { mapFormToRequest } from "../../../containers/AccessInfo/AccessSummary";
import { WALK_TYPE_REGULAR_WALK } from "../../../constants/app";
// sign up flow booking
export const setSchedule$$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies, {
  accessInfo,
  dogNotes,
  dogs,
  startDate,
  startTime,
  usePreferredWalkers,
  walkTypeId
}: any) => // ! TODO: add type definition
post$(({
  endpoint: ENDPOINT_BOOKING_SET_SCHEDULE({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    walk_type: walkTypeId || WALK_TYPE_REGULAR_WALK,
    walk_times: [{
      date: startDate,
      time_of_day: startTime
    }],
    is_recurring: false,
    is_on_demand: false,
    use_fastest_available: true,
    use_preferred: usePreferredWalkers,
    dogs,
    notes: dogNotes,
    htgi: mapFormToRequest(accessInfo)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: BookingSetScheduleRequest;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: bookingSetScheduleSuccess,
  onFailure: bookingSetScheduleFailure,
  parser: bookingSetScheduleResponseParser
})), catchError(handleErrorV5Response(action$, bookingSetScheduleFailure)));

const setSchedule$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(BOOKING_SET_SCHEDULE), retry(3), switchMap(({
  payload: {
    accessInfo,
    dogNotes,
    dogs,
    startDate,
    startTime,
    usePreferredWalkers,
    walkTypeId
  }
}: BookingSetScheduleAction) => setSchedule$$(action$, state$, {
  post$
}, {
  accessInfo,
  dogNotes,
  dogs,
  startDate,
  startTime,
  usePreferredWalkers,
  walkTypeId
})));

export default setSchedule$;