import { useSelector } from 'react-redux';

import { ownerSlice } from '../slices/owners';

export const useGetOwner = () => {
  const data = useSelector(ownerSlice.selectors.getOwner);

  return {
    data,
  };
};
