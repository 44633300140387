import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const CrossSlantIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#D1D1D1"
    viewBox="0 0 26 26"
    {...props}
  >
    <defs>
      <path
        id="cross-slant-a"
        d="M0 0h26v26H0z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <mask
        id="cross-slant-b"
        fill="#fff"
      >
        <use xlinkHref="#cross-slant-a" />
      </mask>
      <path
        d="M22.413.524c.816-.695 2.146-.7 2.966-.01.748.628.82 1.609.216 2.307l-7.372 9.756 5.336 6.685c.763.955.508 2.239-.55 2.935l-.142.088c-.968.552-2.22.472-3.076-.124l-6.188-4.312-10.016 7.627c-.816.695-2.146.7-2.966.01-.748-.628-.82-1.609-.216-2.307l7.371-9.757-5.335-6.684c-.763-.955-.508-2.239.55-2.935l.142-.088c.968-.552 2.22-.472 3.076.124l6.188 4.312Z"
        fill="currentColor"
        fillRule="nonzero"
        mask="url(#cross-slant-b)"
      />
    </g>
  </SvgIcon>
);
