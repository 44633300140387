export const WellnessPlansForm = {
  PlanSelectorPrefix: 'wellness-plan',
  PetName: 'pet-name',
  PetBreed: 'pet-breed',
  PetBirthday: 'pet-birthday',
  PetWeight: 'pet-weight',
  AddAnotherPetBtn: 'add-another-pet-btn',
  SubmitBtn: 'submit-btn',
  TotalText: 'total-text',
  PremiumExtraBtn: 'premium-extra-btn',
} as const;
