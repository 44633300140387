import { createSlice } from '@reduxjs/toolkit';

import { ReduxState } from '../../reducers';
import { app } from '../../types/app';

export type WellnessPremiumState = app.Wellness.WellnessPremium.WellnessPremium;
const initialState: WellnessPremiumState = {
  pets: [], // pets contain the addons
  userInfo: {},
  addressInfo: {},
  sameBillingAndMailingAddress: false,
  extras: [],
};

const slice = createSlice({
  name: 'wellness-premium',
  initialState,
  reducers: {
    save: (state, {
      payload,
    }) => ({
      ...state,
      ...payload,
    }),
  },
});

// selector functions
const selectors = {
  get: (state: ReduxState) => state.wellnessPremium,
  getPets: (state: ReduxState) => state.wellnessPremium.pets,
};

export const wellnessPremiumSlice = {
  ...slice,
  selectors,
};
