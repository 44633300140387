import { useDispatch } from 'react-redux';

import { useFacebookLogin } from '..';
import { usePostFacebookSocialAuthenticationMutation } from '../../slices/auth/socialAuthApi';
import { ownerSlice } from '../../slices/owners/owner';
import { tryCatch } from '../../utils/tryCatch';

export const useFacebookLoginWithAuthentication = () => {
  const {
    execute: executeFacebookLogin,
    submitting: facebookLoginSubmitting,
    setAccessToken,
    accessToken,
  } = useFacebookLogin();

  const [
    executePostFacebookSocialAuthentication,
    {
      isLoading: facebookAuthenticationSubmitting,
      isSuccess: facebookAuthenticationSuccesful,
      isError: facebookAuthenticationIsError,
      data: facebookAuthenticationData,
      error: facebookAuthenticationError,
    },
  ] = usePostFacebookSocialAuthenticationMutation();

  const dispatch = useDispatch();

  const execute = async (params) => {
    const [facebookResponse, facebookError] = await tryCatch(() => executeFacebookLogin());
    if (facebookError) {
      return;
    }

    if (facebookResponse && facebookResponse.accessToken) {
      setAccessToken(facebookResponse.accessToken);

      const response = await executePostFacebookSocialAuthentication({
        accessToken: facebookResponse.accessToken,
        ...params
      }).unwrap();

      // To fix race condition on facebook login first render
      if (response) {
        await dispatch(ownerSlice.actions.setOwnerID(response.id));
        await dispatch(ownerSlice.actions.setOwnerToken(response.token));
      }

      return response;
    }
  };

  return {
    execute,
    facebookLoginSubmitting,
    facebookAuthenticationSubmitting,
    facebookAuthenticationSuccesful,
    facebookAuthenticationIsError,
    facebookAuthenticationData,
    facebookAuthenticationError,
    accessToken,
  };
};
