
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalkCoordinatesParsedResponse,
} from 'webApp';

import {
  WALK_COORDINATES_GET,
  WALK_COORDINATES_GET_SUCCESS,
  WALK_COORDINATES_GET_FAILURE,
  WALK_COORDINATES_POLL_START,
  WALK_COORDINATES_POLL_STOP,
} from '../../actionTypes';

/**
 * Get walk coordinates
 */
export type GetWalkCoordinatesPayload = {
  walkID: string,
};

export type GetWalkCoordinatesAction = {
  type: typeof WALK_COORDINATES_GET,
  payload: GetWalkCoordinatesPayload,
};

export const getWalkCoordinates: (string) => GetWalkCoordinatesAction = createAction(
  WALK_COORDINATES_GET,
  walkID => ({ walkID }),
);


/**
 * Get walk coordinates success
 */
export type GetWalkCoordinatesSuccessAction = {
  type: typeof WALK_COORDINATES_GET_SUCCESS,
  payload: GetWalkCoordinatesParsedResponse,
}

export const getWalkCoordinatesSuccess: (
  GetWalkCoordinatesParsedResponse,
) => GetWalkCoordinatesSuccessAction = createAction(
  WALK_COORDINATES_GET_SUCCESS,
);


/**
 * Get walk coordinates failure
 */
export type GetWalkCoordinatesFailureAction = {
  type: typeof WALK_COORDINATES_GET_FAILURE,
  payload: ErrorParsedResponse,
}

export const getWalkCoordinatesFailure: (
  ErrorParsedResponse,
) => GetWalkCoordinatesFailureAction = createAction(
  WALK_COORDINATES_GET_FAILURE,
);


/**
 * Start walk coordinates poll
 */
export type StartWalkCoordinatesPollPayload = {
  walkID: string,
};

export type StartWalkCoordinatesPollAction = {
  type: typeof WALK_COORDINATES_POLL_START,
  payload: StartWalkCoordinatesPollPayload,
}

export const startWalkCoordinatesPoll: (
  string,
) => StartWalkCoordinatesPollAction = createAction(
  WALK_COORDINATES_POLL_START,
  walkID => ({ walkID }),
);


/**
 * Stop walk coordinates poll
 */
export type StopWalkCoordinatesPollPayload = {
  type: typeof WALK_COORDINATES_POLL_STOP,
};

export const stopWalkCoordinatesPoll: () => StopWalkCoordinatesPollPayload = createAction(
  WALK_COORDINATES_POLL_STOP,
);
