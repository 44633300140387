import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { ResetPasswordRequestPayload, ResetPasswordResponse } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_RESET_PASSWORD, Endpoint } from "../../../constants/endpoints";
import { OWNER_PASSWORD_RESET } from "../../../actions/actionTypes";
import { resetOwnerPasswordSuccess, resetOwnerPasswordFailure, ResetOwnerPasswordAction } from "../../../actions/owner/resetOwnerPassword";

const resetOwnerPassword$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(OWNER_PASSWORD_RESET), switchMap(({
  payload: {
    password,
    resetToken,
    userID
  }
}: ResetOwnerPasswordAction) => post$(({
  endpoint: ENDPOINT_OWNER_RESET_PASSWORD,
  payload: {
    id: userID,
    password,
    reset_token: resetToken
  }
} as {
  endpoint: Endpoint;
  payload: ResetPasswordRequestPayload;
})).pipe(flatMap((response: ResetPasswordResponse) => handleResponse({
  response,
  onSuccess: resetOwnerPasswordSuccess,
  onFailure: resetOwnerPasswordFailure
})), catchError(handleErrorV5Response(action$, resetOwnerPasswordFailure)))));

export default resetOwnerPassword$;