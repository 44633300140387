import React from 'react';

import Link from '@waglabs/link';
import { Typography } from '@waglabs/typography';

import { ExternalRoutes } from './routes';

export const Terms = {
  Terms1: (
    <Typography
      variant="legal"
      m={0}
    >
      By continuing, I agree to Wag!&apos;s
      <Link
        href={ExternalRoutes.TermsAndConditionsApp}
        fontWeight="bold"
        fontSize="inherit"
      >
        &nbsp;Terms&nbsp;
      </Link>
      (including a
      <Link
        fontWeight="bold"
        fontSize="inherit"
        href={ExternalRoutes.TermsAndConditionsArbitration}
      >
        &nbsp;mutual
        arbitration
        clause
      </Link>
      )&nbsp;and&nbsp;
      <Link
        href={ExternalRoutes.PrivacyPolicy}
        fontWeight="bold"
        fontSize="inherit"
      >
        Privacy Policy
      </Link>
      .
      Additionally I agree to receive text messages from Wag! at my cell phone number.
      Text “STOP” in response to those messages to cancel.
      Message & data rates may apply. Message frequency varies.
      Read
      <Link
        href={ExternalRoutes.MobileTerms}
        fontWeight="bold"
        fontSize="inherit"
      >
        &nbsp;mobile terms&nbsp;
      </Link>
      for more info.
    </Typography>
  ),
  Terms2: (
    <Typography
      m={0}
    >
      I&apos;ve read and accept Wag!&apos;s
      <Link
        href={ExternalRoutes.TermsAndConditionsApp}
        fontWeight="bold"
        fontSize="inherit"
      >
        &nbsp;Terms
      </Link>
      &nbsp;(including a&nbsp;
      <Link
        href={ExternalRoutes.TermsAndConditionsApp}
        fontWeight="bold"
        fontSize="inherit"
      >
        mutual arbitration clause
      </Link>
      ) and&nbsp;
      <Link
        href={ExternalRoutes.PrivacyPolicy}
        fontWeight="bold"
        fontSize="inherit"
      >
        Privacy Policy.
      </Link>
    </Typography>
  ),
  MobileTermsAndConditions: (
    <Typography
      m={0}
    >
      I agree to receive auto-dialed service and offer-related calls and
      &nbsp;text messages from Wag! at my cell phone number.
      Consent to receive auto-dialed marketing calls and text messages is not
      &nbsp;required. Text STOP to 63650 to cancel. Text HELP for help.
      Message & data rates may apply. Message frequency varies.
      Read Wag!&apos;s
      <Link
        href={ExternalRoutes.MobileTerms}
        fontWeight="bold"
        fontSize="inherit"
      >
        &nbsp;mobile terms&nbsp;
      </Link>
      for more info.
    </Typography>
  ),
} as const;
