
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  DogQuestionaire,
} from 'webApp';
import {
  DOG_GET_QUESTIONAIRE,
  DOG_GET_QUESTIONAIRE_SUCCESS,
  DOG_GET_QUESTIONAIRE_FAILURE,
} from '../../actionTypes';


/**
 * Get dog
 */
export type GetDogQuestionaireParams = {
  dogID: string,
};

export type GetDogQuestionairePayload = GetDogQuestionaireParams;

export type GetDogQuestionaireAction = {
  type: typeof DOG_GET_QUESTIONAIRE,
  payload: GetDogQuestionairePayload,
};

export const getDogQuestionaire: (
  GetDogQuestionaireParams
) => GetDogQuestionaireAction = createAction(
  DOG_GET_QUESTIONAIRE, ({
    dogID,
  }) => ({
    dogID,
  }),
);

/**
 * Get dog Success
 */
export type GetDogQuestionaireSuccessAction = {
  type: typeof DOG_GET_QUESTIONAIRE_SUCCESS,
  payload: DogQuestionaire,
};

export const getDogQuestionaireSuccess: (
  DogQuestionaire,
) => GetDogQuestionaireSuccessAction = createAction(DOG_GET_QUESTIONAIRE_SUCCESS);


/**
 * Get dog Failure
 */
export type GetDogQuestionaireFailureAction = {
  type: typeof DOG_GET_QUESTIONAIRE_FAILURE,
  payload: ErrorParsedResponse,
};

export const getDogQuestionaireFailure: (
  ErrorParsedResponse,
) => GetDogQuestionaireFailureAction = createAction(DOG_GET_QUESTIONAIRE_FAILURE);
