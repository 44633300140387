import {
  switchMap,
  flatMap,
  catchError,
  retry,
  tap,
} from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import { wagApi } from '../../../types/wagapi';

import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';

import {
  ENDPOINT_OWNER_GOOGLE_SIGNIN_REGISTER,
  Endpoint,
} from '../../../constants/endpoints';
import { DeviceType } from '../../../constants/app';

import { OWNER_GOOGLE_SIGNIN_REGISTER } from '../../../actions/actionTypes';
import {
  registerOwnerGoogleSigninSuccess,
  registerOwnerGoogleSigninFailure,
  RegisterOwnerGoogleSigninAction,
} from '../../../actions/registerOwner/registerOwnerGoogleSignin';

import { registerOwnerGoogleSigninResponseParser } from '../../../helpers/Http/ResponseParsers';

import { SIGN_UP_BY_GOOGLE_MULTIPLE_ACCOUNTS_ERROR } from '../../../constants';

const passByOrThrowIfMultipleAccounts = ({
  multiple_accounts: multipleAccounts
}: wagApi.OwnerGoogleSigninResponse) => {
  // create API error instance, otherwise, let it go (pass by)
  if (multipleAccounts) {
    // TODO - This is a custom error specifically to trigger the error handling
    const err: any = new Error();
    err.response = {
      detail: SIGN_UP_BY_GOOGLE_MULTIPLE_ACCOUNTS_ERROR,
    };
    throw err;
  }
};

/**
 * @deprecated - Please use the use usePostGoogleSocialAuthenticationMutation hook instead
 * or useGoogleLogin which incorporates that hook and the upgrade hook to log into the app.
 */
const registerOwnerGoogleSignin$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(OWNER_GOOGLE_SIGNIN_REGISTER),
    retry(3),
    switchMap(
      ({ payload: { id, deviceID } }: RegisterOwnerGoogleSigninAction) =>
        post$({
          endpoint: ENDPOINT_OWNER_GOOGLE_SIGNIN_REGISTER,
          payload: {
            id_token: id,
            device_id: deviceID,
            device_type: DeviceType.Web,
          },
        } as {
          endpoint: Endpoint;
          payload: wagApi.RegisterOwnerGoogleSigninRequestPayload;
        }).pipe(
          tap(({
            response,
          }: {
            response: wagApi.OwnerGoogleSigninResponse,
          }) => (
            passByOrThrowIfMultipleAccounts(response)
          )),
          flatMap((response: AjaxResponse) => (
            // TODO - Fix typings
            handleResponse({
              response,
              onSuccess: registerOwnerGoogleSigninSuccess,
              onFailure: registerOwnerGoogleSigninFailure,
              parser: registerOwnerGoogleSigninResponseParser,
            } as any)
          )),
          catchError(
            handleErrorV5Response(action$, registerOwnerGoogleSigninFailure)
          )
        )
    )
  );

export default registerOwnerGoogleSignin$;
