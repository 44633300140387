import { PureComponent } from "react";
type Primitive = boolean | string | number | null | void;
type Props = {
  watch: Primitive;
  onUpdate: (value: Primitive, prevValue: Primitive) => void;
};

class Trigger extends PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      onUpdate,
      watch
    } = this.props;
    onUpdate(watch, prevProps.watch);
  }

  render() {
    return null;
  }

}

export default Trigger;