
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
} from 'webApp';
import {
  SITTING_QUESTIONNAIRE_GET,
  SITTING_QUESTIONNAIRE_GET_SUCCESS,
  SITTING_QUESTIONNAIRE_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get Sitting Questionnaire
 */
export type SittingQuestionnaireParams = {
  walkTypeId: number,
};

export type SittingQuestionnairePayload = SittingQuestionnaireParams;

export type SittingQuestionnaireAction = {
  type: typeof SITTING_QUESTIONNAIRE_GET,
  payload: SittingQuestionnairePayload,
};

export const getSittingQuestionnaire: (
  SittingQuestionnaireParams
) => SittingQuestionnaireAction = createAction(
  SITTING_QUESTIONNAIRE_GET,
  ({
    walkTypeId,
  }) => ({
    walkTypeId,
  }),
);


/**
 * Get Sitting Questionnaire Success
 */
export type SittingQuestionnaireSuccessAction = {
  type: typeof SITTING_QUESTIONNAIRE_GET_SUCCESS,
  payload: any, // TODO type
};

export const getSittingQuestionnaireSuccess: (
  any // TODO type
) => SittingQuestionnaireSuccessAction = createAction(
  SITTING_QUESTIONNAIRE_GET_SUCCESS,
);


/**
 * Get Sitting Questionnaire Failure
 */
export type SittingQuestionnaireFailureAction = {
  type: typeof SITTING_QUESTIONNAIRE_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getSittingQuestionnaireFailure: (
  ErrorParsedResponse,
) => SittingQuestionnaireFailureAction = createAction(
  SITTING_QUESTIONNAIRE_GET_FAILURE,
);
