import moment from "moment";
import type { GetWalkDetailsResponse } from "wagAPI";
import type { GetWalkDetailsParsedResponse } from "webApp";
import { WALK_TYPE_REGULAR_WALK } from "../../../constants/app";
export const getWalkDetailsResponseParser = (responseData: GetWalkDetailsResponse): GetWalkDetailsParsedResponse => {
  const {
    distance,
    dog,
    note,
    schedule,
    tip,
    total,
    walker,
    completed_time: completedTime,
    end_date: endDate,
    id: walkID,
    is_cancelled: isCancelled,
    is_completed: isCompleted,
    is_confirmed: isConfirmed,
    is_door_locked: isDoorLocked,
    is_pee: isPee,
    is_poo: isPoo,
    length_in_minutes: lengthMinutes,
    photo_url: photoURL,
    service_type: serviceType,
    start_date: startDate,
    video_url: videoURL,

    /**
     * ! TODO: We have to improvise for now here since the server
     * should be returning `walk_type_id`
     */
    walk_type_id: walkTypeID = WALK_TYPE_REGULAR_WALK
  } = responseData;
  const {
    id: scheduleId,
    is_recurring: scheduleIsRecurring
  } = schedule;
  return {
    completedTime,
    distance,
    endDate,
    isCancelled,
    isCompleted,
    isConfirmed,
    isDoorLocked,
    isPee,
    isPoo,
    lengthMinutes,
    note,
    photoURL,
    serviceType,
    startDate,
    tip,
    total,
    videoURL,
    walker,
    // ! TODO: This shoud be `dogs`
    dogs: dog.map(({
      id
    }: any) => id),
    // FIWME: more specific
    schedule: {
      id: scheduleId,
      isRecurring: !!scheduleIsRecurring
    },

    /**
     * ! TODO: API doesn't return `startTime` so we have to inject this property
     */
    startTime: moment(startDate).format('HH:mm:ss'),

    /**
     * ! TODO: This should be `id`
     * ! Yes, we need to convert it to a string since
     *  existing walks data structure has a string walkID
     */
    walkID: walkID.toString(),
    walkTypeID
  };
};