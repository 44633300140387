import {
  AnyAction,
  createSlice,
} from '@reduxjs/toolkit';
import { uniq } from 'ramda';

import { LOGOUT } from '../../actions/actionTypes';
import { State } from '../../constants';
import { ReduxState } from '../../reducers';
import { store } from '../../types/store';
import { wagApi } from '../../types/wagapi';
import { webApp } from '../../types/webapp';
import { ownersApi } from '../owners/v6';

export type PetsState = {
  allIds: string[];
  byId: Record<string, store.V6.Pet>;
};

const initialState: PetsState = {
  allIds: [],
  byId: {},
};

const slice = createSlice({
  name: 'pets',
  initialState,
  reducers: {
    add: (state, { payload }: { payload: store.V6.Pet }) => {
      const allIds = [
        ...state.allIds,
        payload.uuid,
      ];

      const byId = {
        ...state.byId,
        [payload.uuid]: {
          /**
           * Not active = not yet added to the owner; newly created pet
           */
          state: State.NotActive,
          ...payload,
          createdTime: new Date().toISOString(),
        },
      };

      return {
        ...state,
        allIds,
        byId,
      };
    },
    update: (state, { payload }: { payload: store.V6.Pet }) => {
      const byId = {
        ...state.byId,
        [payload.uuid]: payload,
      };

      return {
        ...state,
        byId,
      };
    },
    clearInactive: (state) => {
      const activePets = state.allIds
        .map((id) => state.byId[id])
        .filter((pet) => pet.state === State.Active);

      const allIds = activePets.map((pet) => pet.uuid);
      const byId = activePets.reduce((accumulator, currentValue) => {
        accumulator[currentValue.uuid] = currentValue;
        return accumulator;
      }, {} as Record<string, store.V6.Pet>);

      return {
        allIds,
        byId,
      };
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher<AnyAction>(
      ownersApi.endpoints.getOwnerPets.matchFulfilled,
      (state, { payload }) => {
        const { items: pets } = payload as wagApi.V6.GetOwnerPetsResponse;

        const additionalIds = pets.map((pet) => pet.uuid);
        const additionalById = pets.reduce((accumulator, currentValue) => {
          accumulator[currentValue.uuid] = { ...currentValue };
          accumulator[currentValue.uuid].state = State.Active;
          return accumulator;
        }, {} as Record<string, store.V6.Pet>);

        const allIds = uniq([
          ...state.allIds,
          ...additionalIds,
        ]);
        const byId = {
          ...state.byId,
          ...additionalById,
        };

        return {
          allIds,
          byId,
        };
      },
    );

    builder.addMatcher<AnyAction>(
      (action) => [
        LOGOUT,
      ].includes(action.type),
      () => initialState,
    );
  },
});

const selectors = {
  getById: (id: string) => (state: ReduxState): webApp.V6.Pet => (
    state.pets.byId[id]
  ),
  getAllNotActive: (state: ReduxState): webApp.V6.Pet[] => (
    state.pets.allIds
      .map((id) => state.pets.byId[id])
      .filter((pet) => pet.state === State.NotActive)
  ),
  getAll: (state: ReduxState): webApp.V6.Pet[] => (
    state.pets.allIds
      .map((id) => state.pets.byId[id])
      .filter((pet) => pet.state === State.Active)
  ),
  allIds: (state: ReduxState) => state.pets.allIds,
};

export const petsSlice = {
  ...slice,
  selectors,
};
