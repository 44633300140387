import { useState } from "react";
export const useToggleDisplay = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  const toggle = () => setIsDisplayed(state => !state);

  const show = () => setIsDisplayed(true);

  const hide = () => setIsDisplayed(false);

  return {
    isDisplayed,
    toggle,
    show,
    hide
  };
};