// ! TODO: - Move to wag-react
import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const InfoOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    color="#474747"
    {...props}
  >
    <path
      d="M12 23C5.937 23 1 18.063 1 12S5.937 1 12 1s11 4.937 11 11-4.937 11-11 11zm0-21.12C6.42 1.88 1.88 6.42 1.88 12c0 5.58 4.54 10.12 10.12 10.12 5.58 0 10.12-4.54 10.12-10.12 0-5.58-4.54-10.12-10.12-10.12zm1.1 15.84h-2.2a1.1 1.1 0 01-1.1-1.1v-4.84a1.1 1.1 0 011.1-1.1h2.2a1.1 1.1 0 011.1 1.1v4.84a1.1 1.1 0 01-1.1 1.1zm-2.2-6.16a.218.218 0 00-.22.22v4.84c0 .123.097.22.22.22h2.2c.123 0 .22-.097.22-.22v-4.84a.218.218 0 00-.22-.22h-2.2zM12 9.8a2.2 2.2 0 11.002-4.402A2.2 2.2 0 0112 9.8zm0-3.52c-.73 0-1.32.59-1.32 1.32 0 .73.59 1.32 1.32 1.32.73 0 1.32-.59 1.32-1.32 0-.73-.59-1.32-1.32-1.32z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);
