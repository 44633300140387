import React from 'react';
import {
  Redirect,
  withRouter,
} from 'react-router-dom';

type Props = any & {
  to: {
    path: string;
    hash?: string;
    search?: string;
    state?: any;
  };
  push?: boolean;
  retainQueryString?: boolean;
};

/**
 * Abstraction of `Redirect` component that automagically attach query strings
 */
export const WagRedirect = withRouter<any, Props>(
  ({
    location: { search: existingQueryString },
    push = false,
    retainQueryString = true,
    to: { hash, path: pathname, search, state },
  }: Props) => {
    const forwardedQueryString =
      search || (retainQueryString && existingQueryString);
    return (
      <Redirect
        to={{
          hash,
          pathname,
          state,
          search: forwardedQueryString,
        }}
        push={push}
      />
    );
  }
);
