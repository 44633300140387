import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

/**
 * TODO: Merge with HouseOutlinedIcon
 * At the time of writing this I was unable to merge them due to
 * lack of knowledge on SVG manipulations
 */
export const ProfileHouseOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M19.35 23H4.66a1.107 1.107 0 01-1.114-1.1V10.4l-1.914 1.883L1 11.658 11.693 1.125a.461.461 0 01.632 0L23 11.693l-.632.625-1.905-1.892V21.9c0 .607-.499 1.1-1.113 1.1zm-4.23-.88h4.23a.22.22 0 00.222-.22V9.537l-7.559-7.479-7.576 7.47V21.9c0 .123.098.22.222.22h4.23v-5.06c0-.607.498-1.1 1.112-1.1h4.007c.614 0 1.113.493 1.113 1.1v5.06zm-5.341 0h4.451v-5.06a.22.22 0 00-.222-.22H10a.22.22 0 00-.222.22v5.06z"
      fill="#727272"
      fillRule="nonzero"
    />
  </SvgIcon>
);
