import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

// TODO - allow changing the color
export const FurOneIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#00BF8F"
    viewBox="0 0 48 48"
    {...props}
  >
    <g
      fillRule="nonzero"
      fill="none"
    >
      <path d="M29.82 44.462c-2.82.97-5.8 1.66-8.772 1.404-2.972-.255-5.95-1.553-7.764-3.946-1.777-2.357-2.248-5.458-2.278-8.445-.006-.113-.006-.227-.006-.34-.006-3.886.677-8.01 3.214-10.94-.541 3.019.371 6.262 2.408 8.536a12.784 12.784 0 0 1 1.042-8.612 8.46 8.46 0 0 1 1.942 4.755c.071-2.684.554-5.34 1.43-7.874 2.897 2.357 6.281 4.559 10.002 4.416 1.28-.049 6.13 6.217 5.583 11.521-.578 5.607-6.599 10.253-6.802 9.525Z" fill="#DBA445" />
      <path d="M21.048 45.866c-2.972-.255-5.95-1.553-7.764-3.946-1.777-2.357-2.248-5.458-2.278-8.445.742 2.493 2.213 4.767 4.262 6.356 3.991 3.095 9.9 3.38 14.169.685 2.572-1.625 4.544-4.31 5.05-7.333.5-3.03-.594-6.35-2.99-8.237 5.516 2.946 7.55 12.045 2.99 16.974-4.225 4.567-10.466 4.202-13.439 3.946Z" fill="#C59033" />
      <path d="M23.74 23.103c.969.724 1.964 1.475 2.579 2.521.616 1.047.76 2.476.023 3.438a9.3 9.3 0 0 0-3.15-3.558c1.897 4.083 1.66 9.11-.612 12.992a9.343 9.343 0 0 0 4.019-3.596c.158-.258.309-.531.55-.712.242-.18.605-.237.833-.04.236.205.231.569.207.881l-.277 3.493 1.42-3.269c.104-.239.295-.524.544-.456.271.074.268.464.424.7.226.34.784.27 1.053-.037.268-.307.32-.746.334-1.156.091-2.46-.699-4.931-2.138-6.916-1.438-1.985-3.509-3.481-5.81-4.285" fill="#E0B466" />
    </g>
  </SvgIcon>
);
