
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';


import {
  WALKER_PASSWORD_RESET,
  WALKER_PASSWORD_RESET_SUCCESS,
  WALKER_PASSWORD_RESET_FAILURE,
} from '../../actionTypes';


/**
 * Reset walker password
 */
export type ResetWalkerPasswordParams = {
  password: string,
  resetToken: string,
  userID: string,
};

export type ResetWalkerPasswordPayload = ResetWalkerPasswordParams;

export type ResetWalkerPasswordAction = {
  type: typeof WALKER_PASSWORD_RESET,
  payload: ResetWalkerPasswordPayload,
};

export const resetWalkerPassword: (
  ResetWalkerPasswordParams,
) => ResetWalkerPasswordAction = createAction(
  WALKER_PASSWORD_RESET,
  ({
    password,
    resetToken,
    userID,
  }) => ({
    password,
    resetToken,
    userID,
  }),
);


/**
 * Reset walker password success
 */
export type ResetWalkerPasswordSuccessAction = {
  type: typeof WALKER_PASSWORD_RESET_SUCCESS,
};

export const resetWalkerPasswordSuccess: () => ResetWalkerPasswordSuccessAction = createAction(
  WALKER_PASSWORD_RESET_SUCCESS,
);


/**
 * Reset walker password failure
 */
export type ResetWalkerPasswordFailureAction = {
  type: typeof WALKER_PASSWORD_RESET_FAILURE,
  payload: ErrorParsedResponse,
};

export const resetWalkerPasswordFailure: (
  ErrorParsedResponse,
) => ResetWalkerPasswordFailureAction = createAction(
  WALKER_PASSWORD_RESET_FAILURE,
);
