import { wagApi } from '../../types/wagapi';

export const getServiceCostDetails = (costData: wagApi.V4.GetScheduleCostEstimateResponse | undefined) => {
  if (!costData || !costData.lineItems.length) {
    return [];
  }

  return costData.lineItems.map((lineItem) => ({
    label: lineItem.description,
    amount: (lineItem.unitPriceInCents * lineItem.quantity) / 100,
  }));
};
