/* eslint-disable */

/**
 * Polyfill for Reflect.ownKeys
 * https://github.com/glenjamin/Reflect.ownKeys
 */

if (typeof Reflect === 'object' && typeof Reflect.ownKeys === 'function') {
  module.exports = Reflect.ownKeys;
} else if (typeof Object.getOwnPropertySymbols === 'function') {
  module.exports = function Reflect_ownKeys(o) {
    return Object.getOwnPropertyNames(o).concat(Object.getOwnPropertySymbols(o));
  };
} else {
  module.exports = Object.getOwnPropertyNames;
}
