
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetOwnerMaskedInfoParsedResponse,
} from 'webApp';

import {
  OWNER_MASKED_INFO_GET,
  OWNER_MASKED_INFO_GET_SUCCESS,
  OWNER_MASKED_INFO_GET_FAILURE,
} from '../../actionTypes';


/**
 * Get owner masked info
 */
export type GetOwnerMaskedInfoAction = {
  type: typeof OWNER_MASKED_INFO_GET,
};

export const getOwnerMaskedInfo: (
) => GetOwnerMaskedInfoAction = createAction(
  OWNER_MASKED_INFO_GET,
);


/**
 * Get owner masked info success
 */
export type GetOwnerMaskedInfoSuccessAction = {
  type: typeof OWNER_MASKED_INFO_GET_SUCCESS,
  payload: GetOwnerMaskedInfoParsedResponse,
};

export const getOwnerMaskedInfoSuccess: (
  GetOwnerMaskedInfoParsedResponse,
) => GetOwnerMaskedInfoSuccessAction = createAction(
  OWNER_MASKED_INFO_GET_SUCCESS,
);


/**
 * Get owner masked info failure
 */
export type GetOwnerMaskedInfoFailureAction = {
  type: typeof OWNER_MASKED_INFO_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getOwnerMaskedInfoFailure: (
  ErrorParsedResponse,
) => GetOwnerMaskedInfoFailureAction = createAction(
  OWNER_MASKED_INFO_GET_FAILURE,
);
