import type { ConfirmationTimes as ConfirmationTimesWagAPI, GetOwnerRequestResponse, Walker as WalkerWagAPI, WalkType as WalkTypeWagAPI } from "wagAPI";
import type { ConfirmationTimes as ConfirmationTimesWebApp, GetOwnerParsedResponse, Walker as WalkerWebApp, WalkType as WalkTypeWebApp } from "webApp";
export const getWalkTypes = (walkTypes: WalkTypeWagAPI[] = []): WalkTypeWebApp[] => walkTypes.map(({
  walk_type_id: id,
  additional_dog_price: additionalDogPrice,
  cancel_price: cancelPrice,
  description,
  description_short: descriptionShort,
  length,
  name,
  name_short: nameShort,
  price
}) => ({
  id,
  additionalDogPrice,
  cancelPrice,
  description,
  descriptionShort,
  length,
  name,
  nameShort,
  price
}));
export const getPreferredWalkers = (preferredWalkers: WalkerWagAPI[]): WalkerWebApp[] => preferredWalkers.map(({
  bio,
  first_name: firstName,
  id,
  last_name: lastName,
  latitude,
  longitude,
  num_reviews: numReviews,
  phone,
  picture,
  rating,
  thumb,
  video
}: WalkerWagAPI): WalkerWebApp => ({
  bio,
  firstName,
  id,
  lastName,
  latitude,
  longitude,
  numReviews,
  phone: String(phone),
  picture,
  rating,
  thumb,
  video
}));

const parseConfirmationTimes = (times: ConfirmationTimesWagAPI): ConfirmationTimesWebApp => ({
  onDemand: times.on_demand,
  singleSameDay: times.single_same_day,
  singleDifferentDay: times.single_different_day,
  multipleSameDay: times.multiple_same_day,
  multipleDifferentDay: times.multiple_different_day,
  multipleRecurring: times.multiple_recurring
});

export const getOwnerResponseParser = (response: GetOwnerRequestResponse): GetOwnerParsedResponse => ({
  address: {
    apartment: response.apt,
    latitude: String(response.latitude),
    longitude: String(response.longitude),
    cityID: response.city_id,
    regionID: response.region_id,
    state: response.state,
    streetAddress: response.address,
    zipCode: response.zipcode
  },
  appVersion: response.app_version,
  cohorts: response.cohorts,
  confirmationTimes: parseConfirmationTimes(response.request_confirmation_times),
  createdAt: response.created_at,
  dogCount: response.dog_count,
  dogID: response.dog_id,
  dog: {
    age: response.dog.age,
    birthday: response.dog.birthday,
    breed: response.dog.breed,
    dogID: response.dog.id ? String(response.dog.id) : null,
    imageURL: response.dog.image_url,
    likes: response.dog.likes,
    name: response.dog.name,
    vetName: response.dog.vet_name || '',
    vetPhone: response.dog.vet_phone || ''
  },
  dogs: Array.isArray(response.dogs) ? response.dogs.map(dog => ({
    dogID: String(dog.id),
    name: dog.name,
    age: dog.age,
    breed: dog.breed,
    breedPlural: dog.breed_plural
  })) : [],
  email: response.email,
  firstName: response.first_name,
  freeWalk: Boolean(response.free_walk),
  isCardInvalid: Boolean(response.is_card_invalid),
  lastName: response.last_name,
  lockbox: {
    code: response.lockbox_code,
    status: response.lockbox_status,
    address: response.lockbox_address,
    type: response.lockbox_type
  },
  numberOfReferrals: response.total_referral,
  numberOfWalksCompleted: response.walk_completed_count,
  ownerID: String(response.id),
  phone: response.phone,
  preferredWalkers: getPreferredWalkers(response.preferred_walkers),
  promoCode: response.promo_code,
  stripeCard: response.stripe_card && {
    brand: response.stripe_card.brand,
    lastFourDigits: response.stripe_card.last4,
    expirationMonth: response.stripe_card.exp_month,
    expirationYear: response.stripe_card.exp_year,
    isCardInvalid: Boolean(response.is_card_invalid)
  },
  updatedAt: response.updated_at,
  walkTypes: getWalkTypes(response.available_services)
});
export default getOwnerResponseParser;