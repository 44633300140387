import React from 'react';

import { SvgIcon, SvgIconProps } from '@waglabs/icons';

export type LockIconProps = SvgIconProps;
export const LockIcon = (props: LockIconProps) => (
  <SvgIcon viewBox="0 0 20 22" color="#FAAB43" {...props}>
    <path
      d="M10 0c4.078 0 7.391 3.353 7.391 7.48v3.08h1.522c.6 0 1.087.493 1.087 1.1v9.24c0 .607-.487 1.1-1.087 1.1H1.087C.487 22 0 21.507 0 20.9v-9.24c0-.607.487-1.1 1.087-1.1h1.522V7.48C2.609 3.353 5.922 0 10 0Zm0 13.2c-1.2 0-2.174.986-2.174 2.2 0 .686.33 1.338.87 1.751v1.769c0 .246.191.44.434.44h1.74a.433.433 0 0 0 .434-.44v-1.769c.54-.413.87-1.065.87-1.751 0-1.214-.974-2.2-2.174-2.2Zm.002-11.64c-3.324 0-6.022 2.75-6.022 6.136v2.864h12.043V7.696c0-3.387-2.697-6.136-6.021-6.136Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);
