import { tryCatch } from '../utils/tryCatch';

export const useAppleLogin = () => {
  /* 
    TODO move to redux query and set social token 
    in state on success reducer 
  */
  const executeAppleLogin = async () => {
    return await tryCatch(() => window.AppleID.auth.signIn());
  }
  return {
    executeAppleLogin,
  };
};