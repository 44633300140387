
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  GetWalkerStripeConnectRedirectUrlParsedResponse,
} from 'webApp';

import {
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
} from '../../actionTypes';

/**
 * Get walker
 */
export type GetWalkerStripeConnectRedirectUrlAction = {
  type: typeof WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
};

export const getWalkerStripeConnectRedirectUrl: ()
=> GetWalkerStripeConnectRedirectUrlAction = createAction(
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL,
);


/**
 * Get walker success
 */
export type GetWalkerStripeConnectRedirectUrlSuccessAction = {
  type: typeof WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
  payload: GetWalkerStripeConnectRedirectUrlParsedResponse,
};

export const getWalkerStripeConnectRedirectUrlSuccess: (
  GetWalkerStripeConnectRedirectUrlParsedResponse,
) => GetWalkerStripeConnectRedirectUrlSuccessAction = createAction(
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
);


/**
 * Get walker failure
 */
export type GetWalkerStripeConnectRedirectUrlFailureAction = {
  type: typeof WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
  payload: ErrorParsedResponse,
};

export const getWalkerStripeConnectRedirectUrlFailure: (ErrorParsedResponse)
=> GetWalkerStripeConnectRedirectUrlFailureAction = createAction(
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_FAILURE,
);
