import * as React from 'react';

import {
  withRouter,
} from 'react-router-dom';

import { NavigationPage } from './NavigationPage';

import { HamburgerMenu } from './components/Navigation/components/HamburgerMenu';

type Props = {
  children: any;
  renderWagLeftButton: () => void;
  renderWagRightButton: () => void;
  title?: string;
};


const defaultWagLeftButton = props => <HamburgerMenu {...props} />;

const defaultWagRightButton = () => null;

export const Navigation = ({
  renderWagLeftButton,
  renderWagRightButton,
  children,
  title,
  ...rest
}: Props) => (
  <NavigationPage
    title={title}
    renderWagLeftButton={renderWagLeftButton || defaultWagLeftButton}
    renderWagRightButton={renderWagRightButton || defaultWagRightButton}
    {...rest}
  >
    {children}
  </NavigationPage>
);

export const NavigationWrapper = withRouter<any>(Navigation);
