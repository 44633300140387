export const ENV_SIGNUP_REGISTRATION_SOURCE = process.env.REACT_APP_REGISTRATION_SOURCE || 'webapp';
// TODO - It would great to have the component use the `useConfig` hook
// for a centralized configuration. However, there seems to be an issue on the library itself
// https://github.com/anthonyjgrove/react-google-login/issues/311
export const ENV_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const ENV_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
export const ENV_POLLING_WALKS_INTERVAL = Number(process.env.WALK_POLLING_INTERVAL) || 30000;
export const ENV_SUPPORT_PHONE = process.env.REACT_APP_SUPPORT_PHONE;
export const ENV_ENABLE_V6_POLLING = Number(process.env.REACT_APP_ENABLE_V6_SERVICES) || process.env.NODE_ENV === 'development';
export const ENV_ENABLE_V6_SERVICES = Number(process.env.REACT_APP_ENABLE_V6_SERVICES) || process.env.NODE_ENV === 'development';
export const ENV_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY || '';
export const ENV_APP_COOKIE_SUBDOMAIN = process.env.REACT_APP_COOKIE_SUBDOMAIN || '';
