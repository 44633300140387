import { handleActions } from 'redux-actions';

import {
  WALKER_CLEAR,
  WALKER_CONTACT_INFO_FAILURE,
  WALKER_CONTACT_INFO_SUCCESS,
  WALKER_GET_FAILURE,
  WALKER_GET_SUCCESS,
  WALKER_GET,
  WALKER_SCHEDULE_GET_FAILURE,
  WALKER_SCHEDULE_GET_SUCCESS,
  WALKER_SCHEDULE_GET,
  WALKER_SET_AUTH_TOKEN,
  WALKER_SIGN_IN_FAILURE,
  WALKER_SIGN_IN_SUCCESS,
  WALKER_SIGN_IN,
  WALKER_SIGN_UP,
  WALKER_SIGN_UP_SUCCESS,
  WALKER_SIGN_UP_FAILURE,
  WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS,
  WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS,
  WALKER_PROFILE_BY_PROMO_CODE_GET,
} from '../../actions/actionTypes';
import type { GetWalkerSuccessAction } from '../../actions/walker/getWalker';
import type { GetWalkerContactInfoSuccessAction } from '../../actions/walker/getWalkerContactInfo';
import type { GetWalkerStripeConnectRedirectUrlSuccessAction } from '../../actions/walker/getWalkerStripeConnectRedirectUrl';
import type { GetWalkerWalkSuccessAction } from '../../actions/walker/getWalkerWalk';
import type { SignInWalkerSuccessAction } from '../../actions/walker/signInWalker';
import type { SetWalkerAuthTokenAction } from '../../actions/walker/walker';
import { webApp } from '../../types/webapp';

export type State = {
  authToken: string | null | undefined;
  passwordResetJWT: string | null | undefined;
  oldWalker: webApp.AuthenticatedWalker | null | undefined;
  walker: webApp.AuthenticatedWalker;
  walkToBeCovered: {};
  oldWalkToBeCovered: {};
  isPayable: boolean;
  stripeRedirectUrl: string | null | undefined;
};
export const initialState: State = {
  authToken: null,
  passwordResetJWT: null,
  oldWalker: null,
  walker: {
    id: null,
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    stripeConnectFlow: '',
  },
  walkToBeCovered: {},
  oldWalkToBeCovered: {},
  isPayable: false,
  stripeRedirectUrl: null,
};
export const reducer: any = handleActions({
  [WALKER_CLEAR]: () => ({ ...initialState }),
  [WALKER_SIGN_IN]: (state: State): State => ({ ...state,
    authToken: undefined }),
  [WALKER_SIGN_IN_SUCCESS]: (state: State, {
    payload: {
      token,
      id,
      isPasswordCompliant,
      passwordResetJWT,
    },
  }: SignInWalkerSuccessAction): State => ({ ...state,
    authToken: token,
    passwordResetJWT,
    walker: { ...state.walker,
      id,
      isPasswordCompliant } }),
  [WALKER_SIGN_IN_FAILURE]: (state: State): State => ({ ...state,
    authToken: undefined }),
  [WALKER_GET]: (state: State): State => ({ ...state,
    oldWalker: state.walker }),
  [WALKER_SIGN_UP]: (state: State): State => ({ ...state,
    authToken: undefined }),
  [WALKER_SIGN_UP_SUCCESS]: (state: State, {
    payload: {
      token,
      id,
      isPasswordCompliant,
      passwordResetJWT,
    },
  }: SignInWalkerSuccessAction): State => ({ ...state,
    authToken: token,
    passwordResetJWT,
    walker: { ...state.walker,
      id,
      isPasswordCompliant } }),
  [WALKER_SIGN_UP_FAILURE]: (state: State): State => ({ ...state,
    authToken: undefined }),

  /**
   * FIXME: This should not be nested under the walker property
   * because it doesn't belong to the global walker property.
   * This is specific only to a walker. We should move it into another place.
   *
   * Also, we should have another selector to get it not reusing the `getWalker`
   */
  [WALKER_CONTACT_INFO_SUCCESS]: (state: State, {
    payload: {
      contact,
    },
  }: GetWalkerContactInfoSuccessAction): State => ({ ...state,
    walker: { ...state.walker,
      maskedPhone: contact } }),
  [WALKER_CONTACT_INFO_FAILURE]: (state: State): State => ({ ...state }),
  [WALKER_GET_SUCCESS]: (state: State, {
    payload: {
      id,
      email,
      phone,
      firstName,
      lastName,
      stripeConnectFlow,
      thumb,
    },
  }: GetWalkerSuccessAction): State => ({ ...state,
    walker: { ...state.walker,
      id,
      email,
      phone,
      firstName,
      lastName,
      stripeConnectFlow,
      thumb },
    oldWalker: undefined }),
  [WALKER_GET_FAILURE]: (state: State): State => ({ ...state,
    walker: { ...initialState.walker,
      ...state.oldWalker },
    oldWalker: undefined }),
  [WALKER_SCHEDULE_GET]: (state) => ({ ...state,
    oldWalker: state.walker }),
  [WALKER_SCHEDULE_GET_SUCCESS]: (state, {
    payload,
  }: GetWalkerWalkSuccessAction) => ({ ...state,
    walkToBeCovered: payload,
    oldWalkToBeCovered: {} }),
  [WALKER_SCHEDULE_GET_FAILURE]: (state) => ({ ...state,
    walkToBeCovered: state.oldWalkToBeCovered,
    oldWalker: {} }),
  [WALKER_STRIPE_CONNECT_GET_REDIRECT_URL_SUCCESS]: (state, {
    payload: {
      isPayable,
      stripeRedirectUrl,
    },
  }: GetWalkerStripeConnectRedirectUrlSuccessAction) => ({ ...state,
    isPayable,
    stripeRedirectUrl }),
  [WALKER_SET_AUTH_TOKEN]: (state, {
    payload,
  }: SetWalkerAuthTokenAction) => ({ ...state,
    authToken: payload.token }),
  [WALKER_PROFILE_BY_PROMO_CODE_GET]: () => ({ ...initialState }),
  [WALKER_PROFILE_BY_PROMO_CODE_GET_SUCCESS]: (state, {
    payload,
  }) => ({ ...state,
    walker: { ...payload } }),
}, initialState);
export default reducer;
