import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { wellnessPremiumSlice } from '../slices/wellness';
import { app } from '../types/app';

export const useWellnessPremium = () => {
  const dispatch = useDispatch();
  const wellness = useSelector(wellnessPremiumSlice.selectors.get);

  /**
   * TODO - Remove this if the partial saving is totally fine
   * or if we need to create a separate function
   */
  const save = (wellnessPremium: Partial<app.Wellness.WellnessPremium.WellnessPremium>) => {
    dispatch(wellnessPremiumSlice.actions.save(wellnessPremium));
  };

  return {
    data: wellness,
    save,
  };
};
