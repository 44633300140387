
import { createAction } from 'redux-actions';
import type {
  GetServiceProvidersParsedResponse,
  ErrorParsedResponse,
  ProviderService as ProviderServiceType,
} from 'webApp';

import {
  SERVICE_PROVIDERS_GET,
  SERVICE_PROVIDERS_GET_SUCCESS,
  SERVICE_PROVIDERS_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get service providers
 */

export type GetServiceProvidersParams = {
  service: ProviderServiceType,
};

export type GetServiceProvidersAction = {
  type: typeof SERVICE_PROVIDERS_GET,
};

export const getServiceProviders: (
  GetServiceProvidersParams,
) => GetServiceProvidersAction = createAction(
  SERVICE_PROVIDERS_GET,
);


/**
 * Get service providers success action
 */
export type GetServiceProvidersSuccessAction = {
  type: typeof SERVICE_PROVIDERS_GET_SUCCESS,
  payload: GetServiceProvidersParsedResponse
};

export const getServiceProvidersSuccess: (
  GetServiceProvidersParsedResponse
) => GetServiceProvidersSuccessAction = createAction(
  SERVICE_PROVIDERS_GET_SUCCESS,
);


/**
 * Get service providers failure action
 */
export type GetServiceProvidersFailureAction = {
  type: typeof SERVICE_PROVIDERS_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getServiceProvidersFailure: (
  ErrorParsedResponse,
) => GetServiceProvidersFailureAction = createAction(
  SERVICE_PROVIDERS_GET_FAILURE,
);
