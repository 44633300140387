import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { GetWalksInPendingReviewRequestPayload, GetWalksInPendingReviewResponse } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKS_IN_PENDING_REVIEW, Endpoint } from "../../../constants/endpoints";
import { WALKS_IN_PENDING_REVIEW_GET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getWalksInPendingReviewSuccess, getWalksInPendingReviewFailure } from "../../../actions/walk/getWalksInPendingReview";
import { getWalksInPendingReviewResponseParser } from "../../../helpers/Http/ResponseParsers";

const getWalksInPendingReview$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(WALKS_IN_PENDING_REVIEW_GET), switchMap(() => get$(({
  endpoint: ENDPOINT_WALKS_IN_PENDING_REVIEW,
  payload: {
    limit: 100,
    offset: 0,
    id: getOwnerID(state$.value)
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: GetWalksInPendingReviewRequestPayload;
  token: string;
})).pipe(flatMap((response: GetWalksInPendingReviewResponse) => handleResponse({
  response,
  onSuccess: getWalksInPendingReviewSuccess,
  onFailure: getWalksInPendingReviewFailure,
  parser: getWalksInPendingReviewResponseParser
})), catchError(handleErrorV5Response(action$, getWalksInPendingReviewFailure)))));

export default getWalksInPendingReview$;