import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';

import type { PostAuthenticationSessionRequestPayload } from 'wagAPI';

import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';

import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  postAuthenticationSessionResponseParser,
} from '../../../helpers/Http/ResponseParsers';

import {
  ENDPOINT_AUTHENTICATION_SESSION_POST,
  Endpoint,
} from '../../../constants/endpoints';
import {
  Auth,
  MagicLoginErrors,
} from '../../../constants';

import { AUTHENTICATION_SESSION_POST } from '../../../actions/actionTypes';
import {
  postAuthenticationSessionSuccess,
  postAuthenticationSessionFailure,
} from '../../../actions/auth/postAuthenticationSession';

import { getOwnerToken } from '../../../selectors';

import {
  ApiErrorV5,
  buildApiErrorPipeline,
  createErrorForV5,
  mapErrorByType,
} from '../../../utils/buildApiErrorPipeline';
import { tryCatchSync } from '../../../utils/tryCatch';

const postAuthenticationSession$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(
  ofType(AUTHENTICATION_SESSION_POST),
  retry(3),
  switchMap(({
    payload: {
      userRoleIdentifier,
      userRoleIdentificationMethod,
      userRoleType
    }
  }) => post$(({
    endpoint: ENDPOINT_AUTHENTICATION_SESSION_POST,
    payload: {
      userRoleIdentifier,
      userRoleIdentificationMethod,
      userRoleType,
    },
  } as {
    endpoint: Endpoint;
    payload: PostAuthenticationSessionRequestPayload;
    token: string;
  })).pipe(flatMap((response) =>
    handleResponse({
      response, 
      onSuccess: postAuthenticationSessionSuccess,
      onFailure: postAuthenticationSessionFailure,
      parser: postAuthenticationSessionResponseParser
    })),
    catchError((error, source) => {
      const [payload,] = tryCatchSync(() => JSON.parse(error.request.body));
      const errorFlow = payload.userRoleType === Auth.RoleType.Owner
        ? MagicLoginErrors.CreateAuthenticationSessionForPetParentFlow
        : MagicLoginErrors.CreateAuthenticationSessionForPetCaregiverFlow;

      const errorV5 = buildApiErrorPipeline<ApiErrorV5>(error).pipe(
        createErrorForV5,
        mapErrorByType(errorFlow)
      );

      return handleErrorV5Response(
        action$,
        postAuthenticationSessionFailure
      )(errorV5, source);
    }))
  )
);

export default postAuthenticationSession$;