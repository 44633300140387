import { FormikState } from 'formik';

type FormikFieldHelper<T> = Pick<FormikState<T>, 'errors' | 'touched'>;
type FormikFieldHelperOptions = {
  errorKeyExtractor: string;
};

const isErrorObject = (error: unknown) => typeof error === 'object';

export const getFormikFieldHelper = ({
  errors,
  touched,
}: FormikFieldHelper<any>) => (fieldName: string, {
  errorKeyExtractor,
}: FormikFieldHelperOptions = {
  errorKeyExtractor: '',
}) => {
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);

  if (!hasError) {
    return {
      hasError: false,
      errorMessage: '',
    };
  }

  if (isErrorObject(errors[fieldName])) {
    return {
      hasError,
      errorMessage: String((errors[fieldName] as Record<string, string>)[errorKeyExtractor] || ''),
    };
  }

  return {
    hasError,
    errorMessage: String(errors[fieldName]),
  };
};
