import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const StethoscopeOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M19.384 16.88V13.76c0-1.7-1.365-3.082-3.042-3.082-1.678 0-3.043 1.383-3.043 3.082v5.32c0 1.215-.973 2.202-2.173 2.202-1.199 0-2.173-.987-2.173-2.202 0-2.035-.008-4.342-.008-5.073l5.763-5.011a1.11 1.11 0 00.321-.775v-5.24c0-.608-.487-1.101-1.086-1.101h-.644v-.44A.433.433 0 0012.865 1h-.435c-.956 0-1.738.793-1.738 1.761 0 .97.782 1.762 1.738 1.762h.435a.433.433 0 00.434-.44V2.76h.652c.122 0 .218.097.218.22v5.24a.234.234 0 01-.044.133l-1.677 1.462h-7.84-.01L2.939 8.38a.238.238 0 01-.069-.158v-5.24c0-.124.096-.22.217-.22h.652v1.32c0 .247.192.44.435.44h.435c.956 0 1.738-.792 1.738-1.76C6.346 1.791 5.564 1 4.608 1h-.435a.433.433 0 00-.435.44v.44h-.652C2.486 1.88 2 2.375 2 2.983v5.24c0 .282.122.563.356.801l5.728 4.95c0 .696.009 3.047.009 5.108 0 1.7 1.365 3.082 3.042 3.082 1.678 0 3.042-1.382 3.042-3.082v-5.32c0-1.215.974-2.201 2.173-2.201 1.2 0 2.173.986 2.173 2.201v3.118a3.069 3.069 0 00-2.607 3.039c0 1.7 1.364 3.082 3.042 3.082C20.635 23 22 21.617 22 19.918a3.084 3.084 0 00-2.616-3.039zM12.43 3.632a.878.878 0 01-.869-.88c0-.485.391-.881.87-.881v1.761zm-6.953-.88c0 .484-.391.88-.87.88V1.872c.479 0 .87.396.87.88zm5.954 7.926l-2.903 2.519-2.92-2.52h5.823zm7.518 11.44c-1.2 0-2.173-.986-2.173-2.201 0-1.216.974-2.202 2.173-2.202 1.2 0 2.173.986 2.173 2.202 0 1.215-.973 2.201-2.173 2.201zm.87-2.201c0 .484-.392.88-.87.88a.878.878 0 01-.869-.88c0-.485.391-.881.87-.881.477 0 .868.396.868.88z"
      fill="#727272"
      fillRule="nonzero"
    />
  </SvgIcon>
);
