import {
  AnyAction,
  createSlice,
} from '@reduxjs/toolkit';

import { ReduxState } from '../../reducers';
import {
  initialState as oldInitialState,
  AuthState,
} from '../../reducers/auth/auth.reducer';
import { webApp } from '../../types/webapp';
import { ownersApi as ownersApiV6 } from '../owners/v6/ownersApi';

import { authApi } from './authApi';
import { socialAuthApi } from './socialAuthApi';
import { authApi as authApiV6 } from './v6/authApi';

export type DraftsState = webApp.BaseBookingDraft;

const initialState: AuthState = {
  ...oldInitialState,
  // add new initial state
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppleIdToken: (state, { payload }) => ({
      ...state,
      appleIdToken: payload,
    }),
    setFacebookAccessToken: (state, { payload }) => ({
      ...state,
      facebookAccessToken: payload,
    }),
    setGoogleIdToken: (state, { payload }) => ({
      ...state,
      googleIdToken: payload,
    }),
    setSocialAuthProviderToLink: (state, { payload }) => ({
      ...state,
      socialAuthProviderToLink: payload,
    }),
    clearSession: (state) => ({
      ...state,
      sessionToken: null,
      sessionTokenCreatedAt: null,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher<AnyAction>(
      authApi.endpoints.postAuthenticationTwoFactorPassword.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ...payload,
      }),
    );

    builder.addMatcher<AnyAction>(
      authApiV6.endpoints.postAuthenticationUpgrade.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.session.token,
        ownerID: payload.user.roleId,
        ownerUUID: payload.uuid,
        refreshToken: payload.refresh.token,
      }),
    );

    builder.addMatcher<AnyAction>(
      ownersApiV6.endpoints.postRegisterOwner.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.session.token,
        ownerID: payload.id,
        ownerUUID: payload.uuid,
        refreshToken: payload.refresh.token,
      }),
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postFacebookSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.session.token,
        ownerID: payload.id,
        ownerUUID: payload.uuid,
        refreshToken: payload.refresh.token,
      }),
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postAppleSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.session.token,
        ownerID: payload.id,
        ownerUUID: payload.uuid,
        refreshToken: payload.refresh.token,
      }),
    );

    builder.addMatcher<AnyAction>(
      socialAuthApi.endpoints.postGoogleSocialAuthentication.matchFulfilled,
      (state, {
        payload,
      }) => ({
        ...state,
        ownerToken: payload.session.token,
        ownerID: payload.id,
        ownerUUID: payload.uuid,
        refreshToken: payload.refresh.token,
      }),
    );
  },
});

// selector functions
const selectors = {
  getFacebookAccessToken: (state: ReduxState) => state.auth.facebookAccessToken,
  getGoogleIdToken: (state: ReduxState) => state.auth.googleIdToken,
  getAppleIdToken: (state: ReduxState) => state.auth.appleIdToken,
  getSocialAuthProviderToLink: (state: ReduxState) => state.auth.socialAuthProviderToLink,
  getOwnerID: (state: ReduxState) => state.auth.ownerID,
  getOwnerToken: (state: ReduxState) => state.auth.ownerToken,
};

export const authSlice = {
  ...slice,
  selectors,
};
