/**
 * @deprecated - Let's use `DogInfoForm` to be consistent
 * with our casing
 */
export const DOG_INFO_FORM = {
  Name: 'name',
  Birthday: 'birthday',
  Breed: 'breed',
  Weight: 'weight',
  WalkInfoBehaviors: 'walkInfo',
  HomeInfoBehaviors: 'homeInfo',
  Id: 'id',
  ImageURL: 'imageURL',
  NextBtn: 'nextBtn',
} as const;

export const DogInfoForm = {
  Name: 'name',
  Birthday: 'birthday',
  Breed: 'breed',
  Weight: 'weight',
  WalkInfoBehaviors: 'walkInfo',
  HomeInfoBehaviors: 'homeInfo',
  Id: 'id',
  ImageURL: 'imageURL',
  NextBtn: 'nextBtn',
} as const;
