import type { RegisterOwnerParsedResponse } from "webApp";
import type { OwnerRegisterResponse } from "wagAPI";
export const registerOwnerResponseParser = ({
  user: {
    roleId
  },
  session: {
    token
  }
}: OwnerRegisterResponse): RegisterOwnerParsedResponse => ({
  ownerID: String(roleId),
  ownerToken: token
});
export default registerOwnerResponseParser;