import { createContext } from 'react';

export type ToggleContextType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
};
export const ToggleContext = createContext<ToggleContextType>({
  isOpen: false,
  toggle: () => undefined,
});
