import { flatMap, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { VerifyWalkerMigrationRequestPayload, VerifyWalkerMigrationResponse } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { WALKER_STRIPE_CONNECT_VERIFY_MIGRATION } from "../../../actions/actionTypes";
import { ENDPOINT_WALKER_VERIFY_MIGRATION, Endpoint } from "../../../constants/endpoints";
import { getWalkerId, getWalkerToken } from "../../../selectors";
import { verifyWalkerMigrationFailure, verifyWalkerMigrationSuccess } from "../../../actions/walker/verifyWalkerMigration";

const verifyWalkerMigration$ = (action$: Action$, state$: State$, {
  getLegacy$
}: Dependencies) => action$.pipe(ofType(WALKER_STRIPE_CONNECT_VERIFY_MIGRATION), switchMap(() => getLegacy$(({
  endpoint: ENDPOINT_WALKER_VERIFY_MIGRATION,
  payload: {
    id: getWalkerId(state$.value)
  },
  token: getWalkerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: VerifyWalkerMigrationRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: verifyWalkerMigrationSuccess,
  onFailure: verifyWalkerMigrationFailure
})), // return failure action if call succeeds but migration fails
flatMap((action: {
  payload: VerifyWalkerMigrationResponse;
}) => {
  const {
    success,
    migrated
  } = action.payload;

  if (success && !migrated) {
    return [verifyWalkerMigrationFailure({
      errorCode: 500,
      error: 'Migration failed',
      errorMessages: {}
    })];
  }

  return [action];
}), catchError(handleErrorV5Response(action$, verifyWalkerMigrationFailure)))));

export default verifyWalkerMigration$;