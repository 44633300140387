import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { BookingMeetGreetRequestPayload } from "wagAPI";
import { WALK_TYPE_MEET_GREET } from "../../../constants/app";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_BOOKING_MEET_GREET, Endpoint } from "../../../constants/endpoints";
import { BOOKING_MEET_GREET } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { bookingMeetGreetSuccess, bookingMeetGreetFailure, BookingMeetGreetAction } from "../../../actions/booking/bookingMeetGreet";
import { bookingMeetGreetResponseParser } from "../../../helpers/Http/ResponseParsers";

const meetGreet$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(BOOKING_MEET_GREET), retry(3), switchMap(({
  payload: {
    startDate,
    startTime
  }
}: BookingMeetGreetAction) => post$(({
  endpoint: ENDPOINT_BOOKING_MEET_GREET,
  payload: {
    free_walk: true,
    id: getOwnerID(state$.value),
    is_recurring: false,
    start_date: startDate,
    start_time: startTime,
    use_fastest_available: 1,
    walk_type_id: WALK_TYPE_MEET_GREET
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: BookingMeetGreetRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: bookingMeetGreetSuccess,
  onFailure: bookingMeetGreetFailure,
  parser: bookingMeetGreetResponseParser
})), catchError(handleErrorV5Response(action$, bookingMeetGreetFailure)))));

export default meetGreet$;