import { createAction } from "redux-actions";
import { RESPONSE_UNAUTHENTICATED, RESPONSE_SERVER_ERROR } from "../actionTypes";

/**
 * Unauthenticated response
 */
export type UnauthenticatedResponsePayload = {
  message: string;
};
export type UnauthenticatedResponseAction = {
  type: typeof RESPONSE_UNAUTHENTICATED;
  payload: UnauthenticatedResponsePayload;
};
export const unauthenticatedResponse: (arg0: string) => UnauthenticatedResponseAction = createAction(RESPONSE_UNAUTHENTICATED, message => ({
  message
}));

/**
 * Server error response
 */
export type ServerErrorResponsePayload = {
  message: string;
};
export type ServerErrorResponseAction = {
  type: typeof RESPONSE_SERVER_ERROR;
  payload: UnauthenticatedResponsePayload;
};
export const serverErrorResponse: (arg0: string) => ServerErrorResponseAction = createAction(RESPONSE_SERVER_ERROR, message => ({
  message
}));