import {
  groupBy,
  map,
  mergeAll,
  pipe,
} from 'ramda';

import {
  WellnessPlanDiamondIcon,
  WellnessPlanDiamondIconProps,
  WellnessPlanGoldIcon,
  WellnessPlanGoldIconProps,
  WellnessPlanPlatinumIcon,
  WellnessPlanPlatinumIconProps,
  WellnessPlanWagPremiumIcon,
  WellnessPlanWagPremiumIconProps,
} from '../icons';
import { app } from '../types/app';
import { arrayToObject } from '../utils';
import { PREMIUM_SUBSCRIPTION_PRICE } from './app';

export const wellnessPlans: Readonly<app.Wellness.WellnessPlan[]> = [{
  id: 0,
  name: 'no-plan',
  displayName: 'No plan',
  pricePerMonth: 0,
  coverage: {
    title: '',
    description: '',
  },
}, {
  id: 1,
  name: 'gold',
  displayName: 'Gold',
  pricePerMonth: 14.95,
  coverage: {
    title: '<p><b> Gold: </b> Cover your pet’s basic vet visits to keep them healthy </p>',
    description: '<ul>'
    + '<li> <p> Physical wellness exam (1/year) </p> </li>'
    + '<li> <p> Annual shots / boosters (3/year) </p> </li>'
    + '<li> <p> Blood / diagnostic testing (1/year) </p> </li>'
    + '</ul>',
  },
}, {
  id: 2,
  name: 'platinum',
  displayName: 'Platinum',
  pricePerMonth: 29.95,
  coverage: {
    title: '<p><b> Platinum: </b> Stay on top of occasional tests and treatments as your pet ages </p>',
    description: '<ul>'
    + '<li> <p> Physical wellness exam (1/year) </p> </li>'
    + '<li> <p> Annual shots / boosters (6/year) </p> </li>'
    + '<li> <p> Blood / diagnostic testing (1/year) </p> </li>'
    + '<li> <p> Deworming (1/year) </p> </li>'
    + '<li> <p> Urine / fecal tests (1/year) </p> </li>'
    + '<li> <p> Anal gland expression (1/year) </p> </li>'
    + '</ul>',
  },
}, {
  id: 3,
  name: 'diamond',
  displayName: 'Diamond',
  pricePerMonth: 59.95,
  coverage: {
    title: '<p><b> Diamond: </b> Best for puppies and kittens who need a little extra attention </p>',
    description: '<ul>'
    + '<li> <p> Physical wellness exam (2/year) </p> </li>'
    + '<li> <p> Annual shots / boosters (9/year) </p> </li>'
    + '<li> <p> Blood / diagnostic testing (2/year) </p> </li>'
    + '<li> <p> Deworming (2/year) </p> </li>'
    + '<li> <p> Urine / fecal tests (2/year) </p> </li>'
    + '<li> <p> Anal gland expression (2/year) </p> </li>'
    + '</ul>',
  },
}] as const;

export const wellnessPlanMap = arrayToObject(
  wellnessPlans,
) as Record<number, app.Wellness.WellnessPlan>;

// TODO - Update typings; Typing the `pipe` is difficult on this case
export const wellnessPlanByNameMap = pipe(
  groupBy((item: typeof wellnessPlans[0]) => item.name) as unknown as any,
  map((item: typeof wellnessPlans) => mergeAll(item)),
)(wellnessPlans) as unknown as Record<string, app.Wellness.WellnessPlan>;

export const wellnessPlanIconsMap: Record<
  app.Wellness.WellnessPlan['name'],
  (...args: (WellnessPlanDiamondIconProps | WellnessPlanGoldIconProps | WellnessPlanPlatinumIconProps)[]) => JSX.Element
> = {
  gold: WellnessPlanGoldIcon,
  platinum: WellnessPlanPlatinumIcon,
  diamond: WellnessPlanDiamondIcon,
};

export const wellnessPetPlanAddons: Readonly<app.Wellness.WellnessPetAddon[]> = [{
  id: 1,
  // Mapped in the endpoint
  name: 'fleaTickHeartwormMeds',
  displayName: 'Flea, tick, & heartworm meds',
  pricePerMonth: 19.95,
  description: '<p> Parasites are a threat to your pet throughout the year. Despite the common belief that fleas, ticks and mosquitoes are only active in warmer months, these pests can cause big problems for your pet at any time during the year, including transmitting diseases to them. </p>'
    + '<p> The best way to protect against them is year-round flea, tick and heartworm disease protection. </p>'
    + '<p><b>Flea, tick and heartworm coverage pays for:</b></p>'
    + '<p> ANY flea and tick medication, including Nexgard, Bravecto, Trifexis, Sentinel, Frontline, Capstar, Simparica & K9 Advantix. </p>'
    + '<p> ANY heartworm medication, including Advantage Multi, Heartgard, Interceptor, Iverheart, Sentinel, Trifexis & Tri-Heart. </p>',
}, {
  id: 2,
  // Mapped in the endpoint
  name: 'grooming',
  displayName: 'Grooming',
  pricePerMonth: 19.95,
  description: '<p><b>Coverage pays for:</b>'
  + '<p> Your pet\'s wash & blow-dry, haircut, and nail trims when carried out by your vet or specialist pet groomer (including mobile groomers). </p>'
  + '<p> The maximum this plan will pay for is <b>$300</b> in a 12-month period. </p>'
  + '<p> Not offered with Wag! Premium subscription. </p>',
}, {
  id: 3,
  // Mapped in the endpoint
  name: 'dentalCleaning',
  displayName: 'Dental cleaning',
  pricePerMonth: 29.95,
  description: '<p> Dental cleaning is carried out by your vet and is crucial to help keep your pet risk-free from periodontal disease, which can be very painful and expensive to treat. </p>'
  + '<p><b>Dental cleaning coverage pays for:</b></p>'
  + `<ul>
    <li> <p> Pre-Anesthetic Blood Test/ECG </p> </li>
    <li> <p> Anesthesia </p> </li>
    <li> <p> Extractions </p> </li>
    <li> <p> Dental cleaning/polishing </p> </li>
    <li> <p> Painkillers given during pre/post-surgery </p> </li>
  </ul>`
  + '<p>The maximum this plan will pay for is <b>$400</b> in a 12-month period.</p>',
}, {
  id: 4,
  // Mapped in the endpoint
  name: 'spayNeuter',
  displayName: 'Spay / neuter',
  pricePerMonth: 29.95,
  description: `
  <b>Spay / Neuter coverage pays for:</b><br />
  <ul>
    <li> <p> Pre-Anesthetic Blood Test/ECG </p> </li>
    <li> <p> Anesthesia </p> </li>
    <li> <p> Surgery </p> </li>
    <li> <p> Painkillers given during pre/post-surgery </p> </li>
  </ul>
  <p> The maximum this plan will pay for is <b>$400</b>. </p>
`,
}] as const;

export const wellnessPetPlanAddonMap = arrayToObject(
  wellnessPetPlanAddons,
) as Record<number, app.Wellness.WellnessPetAddon>;

// TODO - Fix typings
export const wellnessPetPlanAddonByNameMap = pipe<any, any, any>(
  groupBy((item: typeof wellnessPetPlanAddons[0]) => item.name),
  map((item: typeof wellnessPetPlanAddons) => mergeAll(item)),
)(wellnessPetPlanAddons) as Record<string, app.Wellness.WellnessPetAddon>;

export const wellnessPlanExtras = [{
  id: 1,
  name: 'wag-premium',
  displayName: 'Wag! Premium',
  pricePerMonth: PREMIUM_SUBSCRIPTION_PRICE,
}] as const;

export const wellnessPlanExtrasMap = arrayToObject(
  wellnessPlanExtras,
) as Record<number, app.Wellness.WellnessExtra>;

export const wellnessPlanExtrasIconsMap: Record<
  app.Wellness.WellnessExtra['name'],
  (...args: (WellnessPlanWagPremiumIconProps)[]) => JSX.Element
> = {
  'wag-premium': WellnessPlanWagPremiumIcon,
};

export const wellnessPlansWithPremium: app.Wellness.WellnessPlan['name'][] = [
  'platinum',
  'diamond',
];
