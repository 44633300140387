import React from "react";
import Flex from "@waglabs/flex";
import Text from "@waglabs/text";
import { DEFAULT_ERROR_TITLE, DEFAULT_ERROR_MESSAGE } from "../../constants/errors";
type Props = {
  title?: string;
  message?: string;
};

const ErrorDisplay = ({
  title = DEFAULT_ERROR_TITLE,
  message = DEFAULT_ERROR_MESSAGE
}: Props) => <Flex position="absolute" left="0" top="0" right="0" bottom="0" flexDirection="row" justifyContent="center">
    <Flex flexDirection="column" justifyContent="center">
      <Flex bg="backgroundGray" borderRadius={3} minWidth="280px" maxWidth="500px" p={3} width="100%" color="medGray" flexDirection="column" justifyContent="center" boxSizing="border-box">
        {title && <Text fontSize="20px" fontWeight={500} textAlign="center" color="primary">
            {title}
          </Text>}
        {message && <Text textAlign="center">
            {message}
          </Text>}
      </Flex>
    </Flex>
  </Flex>;

export default ErrorDisplay;