import type { WalkCoordinatesResponse } from "wagAPI";
import type { GetWalkCoordinatesParsedResponse } from "webApp";
export const getWalkCoordinatesResponseParser = ({
  annotationdata,
  distance,
  locationdata,
  time
}: WalkCoordinatesResponse): GetWalkCoordinatesParsedResponse => {
  const emptyData = {
    coordinates: [],
    distance: 0,
    markerCoordinates: [],
    time: 0,
    walkID: '0'
  };
  const walkID = locationdata[0] && locationdata[0].walk_id;

  if (!locationdata.length) {
    return emptyData;
  }

  return {
    coordinates: locationdata.map(walkCoordinate => ({
      latitude: Number(walkCoordinate.latitude),
      longitude: Number(walkCoordinate.longitude)
    })),
    distance,
    markerCoordinates: annotationdata.map(({
      latitude,
      longitude,
      pin_type: markerType
    }) => ({
      markerType,
      latitude: Number(latitude),
      longitude: Number(longitude)
    })),
    time,
    walkID
  };
};
export default getWalkCoordinatesResponseParser;