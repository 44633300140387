import React from 'react';

import Link from '@waglabs/link';

import {
  ROUTE_JOIN_WAG_PREMIUM,
  HELP_OWNERS_URL,
  Routes,
  DataTestIds,
} from '../../../../../../../constants';

import {
  HelpIcon,
  WellnessNavigationOutlinedIcon,
} from '../../../../../../../icons';

export const NavigationItemData = [
  {
    label: 'Home',
    path: '/home',
    iconName: 'home',
    dataTestId: DataTestIds.Navigation.Sidebar.HomeBtn,
  },
  {
    label: 'Past Services',
    path: '/past-services',
    iconName: 'walkerSilhouetteGray',
    dataTestId: DataTestIds.Navigation.Sidebar.PastServicesBtn,
  },
  {
    label: 'Upcoming Services',
    path: '/upcoming-services',
    iconName: 'calendarGray',
    dataTestId: DataTestIds.Navigation.Sidebar.UpcomingServicesBtn,
  },
  {
    label: 'Payments',
    path: '/payments',
    iconName: 'paymentGray',
    dataTestId: DataTestIds.Navigation.Sidebar.PaymentsBtn,
  },
  {
    label: 'Premium',
    path: ROUTE_JOIN_WAG_PREMIUM,
    iconName: 'premiumStar',
    dataTestId: DataTestIds.Navigation.Sidebar.PremiumBtn,
  },
  {
    label: 'Wellness',
    path: Routes.LoggedInWellness,
    renderIcon: () => (
      <WellnessNavigationOutlinedIcon
        height={['20px', null, '30px']}
        width={['20px', null, '30px']}
        mr={['10px', null, '20px']}
      />
    ),
    dataTestId: DataTestIds.Navigation.Sidebar.WellnessBtn,
  },
  {
    label: 'Help',
    path: HELP_OWNERS_URL,
    renderIcon: () => (
      <HelpIcon
        height={['20px', null, '30px']}
        width={['20px', null, '30px']}
        mr={['10px', null, '20px']}
      />
    ),
    dataTestId: DataTestIds.Navigation.Sidebar.HelpBtn,
    Component: (props: unknown) => (
      <Link
        target="_blank"
        {...props}
      />
    ),
  },
];
