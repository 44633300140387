
import { createAction } from 'redux-actions';

import { webApp } from '../../../types/webapp';

import {
  AUTHENTICATION_SESSION_GET,
  AUTHENTICATION_SESSION_GET_SUCCESS,
  AUTHENTICATION_SESSION_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get authentication session
 */

export type GetAuthenticationSessionAction = {
  type: typeof AUTHENTICATION_SESSION_GET,
};

export const getAuthenticationSession: (
) => GetAuthenticationSessionAction = createAction(
  AUTHENTICATION_SESSION_GET,
);

/**
 * Get authentication session success action
 */
export type GetAuthenticationSessionSuccessAction = {
  type: typeof AUTHENTICATION_SESSION_GET_SUCCESS,
};

export const getAuthenticationSessionSuccess: (
  response: webApp.GetAuthenticationSessionParsedResponse
) => GetAuthenticationSessionSuccessAction = createAction(
  AUTHENTICATION_SESSION_GET_SUCCESS,
);

/**
 * Get authentication session failure action
 */
export type GetAuthenticationSessionFailureAction = {
  type: typeof AUTHENTICATION_SESSION_GET_FAILURE,
  payload: webApp.ErrorParsedResponse,
};

export const getAuthenticationSessionFailure: (
  error: webApp.ErrorParsedResponse,
) => GetAuthenticationSessionFailureAction = createAction(
  AUTHENTICATION_SESSION_GET_FAILURE,
);
