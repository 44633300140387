
import { createAction } from 'redux-actions';

import { DOGS_CLEAR } from '../../actionTypes';

export type ClearDogsAction = {
  type: typeof DOGS_CLEAR,
};

export const clearDogs: () => ClearDogsAction = createAction(DOGS_CLEAR);
