
import { createAction } from 'redux-actions';
import type {
  BookingMeetGreetParsedResponse,
  ErrorParsedResponse,
} from 'webApp';
import {
  BOOKING_MEET_GREET,
  BOOKING_MEET_GREET_SUCCESS,
  BOOKING_MEET_GREET_FAILURE,
} from '../../actionTypes';

export type BookingMeetGreetParams = {
  startDate: string,
  startTime: string,
};

export type BookingMeetGreetPayload = BookingMeetGreetParams;

export type BookingMeetGreetAction = {
  type: typeof BOOKING_MEET_GREET,
  payload: BookingMeetGreetPayload,
};

export const bookingMeetGreet: (
  BookingMeetGreetParams
) => BookingMeetGreetAction = createAction(
  BOOKING_MEET_GREET,
  ({
    startDate,
    startTime,
  }) => ({
    startDate,
    startTime,
  }),
);

export type BookingMeetGreetSuccessAction = {
  type: typeof BOOKING_MEET_GREET_SUCCESS,
  payload: BookingMeetGreetParsedResponse,
};

export const bookingMeetGreetSuccess: (
  BookingMeetGreetParsedResponse,
) => BookingMeetGreetSuccessAction = createAction(
  BOOKING_MEET_GREET_SUCCESS,
);

export type BookingMeetGreetFailureAction = {
  type: typeof BOOKING_MEET_GREET_FAILURE,
  payload: ErrorParsedResponse,
};

export const bookingMeetGreetFailure: (
  ErrorParsedResponse,
) => BookingMeetGreetFailureAction = createAction(
  BOOKING_MEET_GREET_FAILURE,
);
