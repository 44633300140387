import type { ReduxState } from "../reducers/index";
// see https://stackoverflow.com/c/wag/questions/223/224
export type requestConfirmationTypes = "onDemand" | "singleSameDay" | "singleDifferentDay" | "multipleSameDay" | "multipleDifferentDay" | "multipleRecurring";
const requestTypeEnum: Array<requestConfirmationTypes | null> = [null, 'onDemand', 'singleSameDay', 'singleDifferentDay', 'multipleSameDay', 'multipleDifferentDay', 'multipleRecurring'];

const getApprovalTimeEstimate = (state: ReduxState, requestType: number): number | null => {
  if (state.services.confirmationTimes && requestTypeEnum[requestType]) {
    return state.services.confirmationTimes[requestTypeEnum[requestType]].time;
  }

  return null;
};

export { getApprovalTimeEstimate };