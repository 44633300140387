import { switchMap, flatMap, catchError, retry } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import type { UpdateOwnerV5RequestPayload } from "wagAPI";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_UPDATE, Endpoint } from "../../../constants/endpoints";
import { MY_PROFILE_UPDATE } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { updateMyProfileSuccess, updateMyProfileFailure, UpdateMyProfileAction } from "../../../actions/owner/updateMyProfile";
import { updateOwnerResponseParser } from "../../../helpers/Http/ResponseParsers";

const updateMyProfile$ = (action$: Action$, state$: State$, {
  patch$
}: Dependencies) => action$.pipe(ofType(MY_PROFILE_UPDATE), retry(3), switchMap(({
  payload: {
    apartmentNumber,
    city,
    email,
    firstName,
    lastName,
    latitude,
    longitude,
    phone,
    state,
    streetAddress,
    zipCode
  }
}: UpdateMyProfileAction) => patch$(({
  endpoint: ENDPOINT_OWNER_UPDATE({
    ownerID: getOwnerID(state$.value)
  }),
  payload: {
    address: {
      address_1: streetAddress,
      address_2: apartmentNumber,
      state,
      city: city || '',
      // endpoint requires the city field to be present
      zipcode: zipCode
    },
    latitude: latitude && String(latitude),
    longitude: longitude && String(longitude),
    email,
    first_name: firstName,
    last_name: lastName,
    phone
  },
  token: getOwnerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: UpdateOwnerV5RequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: updateMyProfileSuccess,
  onFailure: updateMyProfileFailure,
  parser: updateOwnerResponseParser
})), catchError(handleErrorV5Response(action$, updateMyProfileFailure)))));

export default updateMyProfile$;