import {
  switchMap,
  flatMap,
  catchError,
  retry,
} from 'rxjs/operators';
import type { PostAuthenticationCodeRequestPayload } from 'wagAPI';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  handleErrorV5Response,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_AUTHENTICATION_CODE_POST,
  Endpoint,
} from '../../../constants/endpoints';
import { AUTHENTICATION_CODE_POST } from '../../../actions/actionTypes';
import {
  postAuthenticationCodeSuccess,
  postAuthenticationCodeFailure,
  PostAuthenticationCodeAction,
} from '../../../actions/auth/postAuthenticationCode';
import { postAuthenticationCodeResponseParser } from '../../../helpers/Http/ResponseParsers';
import { getSessionToken } from '../../../reducers/auth/auth.selector';
import {
  ApiErrorV5,
  buildApiErrorPipeline,
  createErrorForV5,
  mapErrorByType,
} from '../../../utils/buildApiErrorPipeline';
import { MagicLoginErrors } from '../../../constants';

const postAuthenticationCode$ = (
  action$: Action$,
  state$: State$,
  { post$ }: Dependencies
) =>
  action$.pipe(
    ofType(AUTHENTICATION_CODE_POST),
    retry(3),
    switchMap(
      ({
        payload: {
          sessionToken = getSessionToken(state$.value),
          authenticationMethod,
        },
      }: PostAuthenticationCodeAction) =>
        post$({
          endpoint: ENDPOINT_AUTHENTICATION_CODE_POST,
          payload: {
            sessionToken,
            authenticationMethod,
          },
        } as {
          endpoint: Endpoint;
          payload: PostAuthenticationCodeRequestPayload;
        }).pipe(
          flatMap((response) =>
            handleResponse({
              response,
              onSuccess: postAuthenticationCodeSuccess,
              onFailure: postAuthenticationCodeFailure,
              parser: postAuthenticationCodeResponseParser,
            } as any) // TODO - Update typings on handleResponse
          ),
          catchError((error, source) => {
            const errorV5 = buildApiErrorPipeline<ApiErrorV5>(error).pipe(
              createErrorForV5,
              mapErrorByType(MagicLoginErrors.CodeFlow)
            );
            return handleErrorV5Response(
              action$,
              postAuthenticationCodeFailure
            )(errorV5, source);
          })
        )
    )
  );

export default postAuthenticationCode$;
