import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { width, height, display } from 'styled-system';
import Flex from '@waglabs/flex';
import type {
  FlexProps,
} from '@waglabs/flex-next';
import Icon from '@waglabs/icon';
import StyledNavLink from '@waglabs/styled-nav-link';

import { HamburgerSpin } from 'react-animated-burgers';
import { SidebarContext } from '../../../../SidebarContext';
import { ROUTE_APP } from '../../../../../../constants/routes';
import { DATA_TEST_IDS } from '../../../../../../constants';

const Hamburger = styled(HamburgerSpin)`
  ${height}
  ${display}
  padding: 0;

  span {
    ${width};
    &:before {
      ${width};
    }
    &:after {
      ${width};
    }
  }
  &:focus {
    outline: none;
  }
`;

type Props = {
  theme: any,
  logoProps?: FlexProps;
};

export const BaseHamburgerMenu = ({
  theme: {
    colors: {
      wagGreen,
    },
  },
  logoProps,
}: Props) => (
  <SidebarContext.Consumer>
    {({
      isSidebarVisible,
      onToggleSidebar,
    }) => (
      <Flex
        height={[45, 45, 60]}
        alignItems="center"
      >
        <Flex
          data-testid="toggle-nav"
        >
          <Hamburger
            isActive={isSidebarVisible}
            barColor={wagGreen}
            buttonColor="transparent"
            toggleButton={onToggleSidebar}
            p={0}
            width={[30, 30, 40]}
            height={[25, 25, 30]}
            type="button"
            data-testId={DATA_TEST_IDS.Navigation.Header.HamburgerBtn}
          />
        </Flex>
        <Flex
          display={['none', 'none', 'flex']}
          height={[45, 45, 60]}
          alignItems="center"
          ml={3}
          {...logoProps}
        >
          <StyledNavLink
            to={ROUTE_APP}
            data-testid="logo"
          >
            <Icon
              mt={1}
              hover
              name="wagTextLogo"
              width="80px"
              height="40px"
            />
          </StyledNavLink>
        </Flex>
      </Flex>
    )}
  </SidebarContext.Consumer>
);

export const HamburgerMenu = withRouter<any, any>(withTheme(BaseHamburgerMenu));
