import * as React from 'react';

import styled from 'styled-components';

import { Box } from '@waglabs/box-next';
import {
  Button,
  ButtonProps,
} from '@waglabs/button-next';

import { SidebarNavigationItem } from '../../SidebarNavigationItem/SidebarNavigationItem';

import { DATA_TEST_IDS } from '../../../../../../../constants';

export type LogoutButtonProps = ButtonProps;
export const LogoutButton = (props: LogoutButtonProps) => {
  return (
    <Box
      width={1}
    >
      <ButtonWithHoverEffect // Use a Button Element to handle onClick functionality
        width={1}
        mb={['80px', null, 3]}
        data-testid={DATA_TEST_IDS.Navigation.Sidebar.LogoutBtn}
        justifyContent="flex-start"
        {...props}
      >
        <SidebarNavigationItem
          iconName="exitGray"
          label="Logout"
          pl="22px"
        />
      </ButtonWithHoverEffect>
    </Box>
  );
};

const ButtonWithHoverEffect = styled(Button)`
  &:hover {
    background-color: ${(props: any) => props.theme.colors.backgroundGray};
  }
`;
