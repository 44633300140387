import { switchMap, flatMap, catchError } from "rxjs/operators";
import type { PasswordVerifyRequest, PasswordVerifyResponse } from "wagAPI";
import type { VerifyPasswordParsedResponse } from "webApp";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_VERIFY_PASSWORD } from "../../../constants/endpoints";
import { VERIFY_PASSWORD } from "../../../actions/actionTypes";
import { verifyPasswordSuccess, verifyPasswordFailure } from "../../../actions/password";
import type { VerifyPasswordAction } from "../../../actions/password";

/* eslint-disable camelcase */
const verifyPasswordParser: (arg0: PasswordVerifyResponse) => VerifyPasswordParsedResponse = ({
  pwd_score,
  pwd_strength,
  pwd_warning,
  pwd_suggestions
}) => ({
  /* eslint-enable camelcase */
  score: pwd_score,
  strength: pwd_strength.replace(/The password is (\w)/, (match, p1) => p1.toUpperCase()),
  warning: pwd_warning,
  suggestions: pwd_suggestions
});

const verifyPassword$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(VERIFY_PASSWORD), switchMap(({
  payload: {
    firstName,
    lastName,
    email,
    password
  }
}: VerifyPasswordAction) => post$(({
  endpoint: ENDPOINT_VERIFY_PASSWORD,
  payload: {
    first_name: firstName,
    last_name: lastName,
    email,
    password
  }
} as {
  endpoint: string;
  payload: PasswordVerifyRequest;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: verifyPasswordSuccess,
  onFailure: verifyPasswordFailure,
  parser: verifyPasswordParser
})), catchError(handleErrorV5Response(action$, verifyPasswordFailure)))));

export default verifyPassword$;