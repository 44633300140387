import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { ApiWalker } from 'types/wagapi';

import { apiSlice } from '../slices/apiSlice';
import { authSlice } from '../slices/auth';
import {
  BreedsState,
  breedsSlice,
} from '../slices/breeds';
import { breedsApi } from '../slices/breeds/v6';
import {
  configSlice,
  ConfigState,
} from '../slices/config';
import {
  draftsSlice,
  DraftsState,
} from '../slices/drafts';
import {
  ownerSlice,
  OwnerState,
} from '../slices/owners/owner';
import { ownersApi as ownerApiV6 } from '../slices/owners/v6';
import {
  petsSlice,
  PetsState,
} from '../slices/pets';
import {
  servicesSlice,
  ServicesState as ServicesSliceState,
} from '../slices/services';
import { walkerSlice } from '../slices/walker';
import { walkerApi } from '../slices/walker/v4';
import {
  wellnessSlice,
  WellnessState,
  wellnessPremiumSlice,
  WellnessPremiumState,
  wellnessRecommendationSlice,
  WellnessRecommendationState,
} from '../slices/wellness';

import alerts, { AlertState } from './alerts';
import auth, { AuthState } from './auth';
import booking from './booking';
import { BookingScheduleState } from './booking/booking.reducer';
import bookingFields, { BookingFieldsState } from './bookingFields';
import dog, { DogState } from './dog';
import dogBreedAssets, { DogBreedAssetsState } from './dogBreedAssets';
import dogBreeds, { DogBreedsState } from './dogBreeds';
import {
  dogs,
  DogsState,
} from './dogs';
import error, { ErrorState } from './error';
import estimate, { EstimateState } from './estimate';
import notifications, { NotificationState } from './notifications';
import owner from './owner';
import password, { PasswordState } from './password';
import queryParams, { QueryParamsState as QueryParams } from './queryParams';
import requests, { RequestsState } from './requests';
import services, { ServicesState } from './services';
import signup, { SignupState } from './signup';
import sittingQuestionnaire, { SittingQuestionnaireState } from './sittingQuestionnaire';
import {
  reducer as sorryModal,
  State as SorryModalState,
} from './sorryModal';
import walkCoordinates, { State as WalkCoordinatesState } from './walkCoordinates';
import walker, { State as WalkerState } from './walker';
import walkers, { State as WalkersState } from './walkers';
import walks, { State as WalksState } from './walks';

export type ReduxState = {
  alerts: AlertState,
  auth: AuthState,
  booking: BookingScheduleState,
  bookingFields: BookingFieldsState,
  breeds: BreedsState,
  config: ConfigState,
  dog: DogState,
  dogBreedAssets: DogBreedAssetsState,
  dogBreeds: DogBreedsState,
  dogs: DogsState,
  drafts: DraftsState,
  error: ErrorState,
  estimate: EstimateState,
  notifications: NotificationState,
  owner: OwnerState,
  password: PasswordState,
  pets: PetsState,
  queryParams: QueryParams,
  requests: RequestsState,
  services: ServicesState & ServicesSliceState,
  signup: SignupState,
  sittingQuestionnaire: SittingQuestionnaireState,
  sorryModal: SorryModalState,
  walkCoordinates: WalkCoordinatesState,
  walker: WalkerState,
  walkers: WalkersState,
  apiWalker: ApiWalker,
  walks: WalksState,
  wellness: WellnessState,
  wellnessPremium: WellnessPremiumState,
  wellnessRecommendation: WellnessRecommendationState,
};

const rootReducer = combineReducers({
  alerts,
  auth: reduceReducers(auth, authSlice.reducer),
  [apiSlice.reducerPath]: apiSlice.reducer,
  booking,
  bookingFields,
  breeds: breedsSlice.reducer,
  [breedsApi.reducerPath]: breedsApi.reducer,
  config: configSlice.reducer,
  dog,
  dogs,
  dogBreedAssets,
  dogBreeds,
  drafts: draftsSlice.reducer,
  error,
  estimate,
  notifications,
  owner: reduceReducers(owner, ownerSlice.reducer),
  [ownerApiV6.reducerPath]: ownerApiV6.reducer,
  password,
  pets: petsSlice.reducer,
  queryParams,
  sittingQuestionnaire,
  requests,
  services: reduceReducers(services, servicesSlice.reducer),
  signup,
  sorryModal,
  walkCoordinates,
  walker: reduceReducers(walker, walkerSlice.reducer),
  [walkerApi.reducerPath]: walkerApi.reducer,
  walkers,
  walks,
  wellness: wellnessSlice.reducer,
  wellnessPremium: wellnessPremiumSlice.reducer,
  wellnessRecommendation: wellnessRecommendationSlice.reducer,
});

export default rootReducer;
