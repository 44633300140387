import { switchMap, catchError, flatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { SignUpWalkerRequestPayload } from "wagAPI";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_SIGN_IN, Endpoint } from "../../../constants/endpoints";
import { WALKER_SIGN_UP } from "../../../actions/actionTypes";
import { signUpWalkerSuccess, signUpWalkerFailure, SignUpWalkerAction } from "../../../actions/walker/signUpWalker";
import { signUpWalkerResponseParser } from "../../../helpers/Http/ResponseParsers";

const signUpWalker$ = (action$: Action$, state$: State$, {
  post$
}: Dependencies) => action$.pipe(ofType(WALKER_SIGN_UP), switchMap(({
  payload: {
    email,
    password,
    source,
    willUpdatePassword = false
  }
}: SignUpWalkerAction) => post$(({
  endpoint: ENDPOINT_WALKER_SIGN_IN,
  payload: {
    email,
    password,
    source,
    generate_password_reset_jwt: willUpdatePassword
  }
} as {
  endpoint: Endpoint;
  payload: SignUpWalkerRequestPayload;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: signUpWalkerSuccess,
  onFailure: signUpWalkerFailure,
  parser: signUpWalkerResponseParser
})), catchError(handleErrorV5Response(action$, signUpWalkerFailure)))));

export default signUpWalker$;