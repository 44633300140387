import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleV5Response } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_OWNER_SMART_MODULES_V5 } from "../../../constants/endpoints";
import { OWNER_SMART_MODULES } from "../../../actions/actionTypes";
import { getOwnerToken, getOwnerID } from "../../../selectors";
import { getSmartModulesSuccess, getSmartModulesFailure } from "../../../actions/owner/getSmartModules";
import { getSmartModulesResponseParser } from "../../../helpers/Http/ResponseParsers";

const getSmartModules$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(OWNER_SMART_MODULES), switchMap(() => get$(({
  endpoint: ENDPOINT_OWNER_SMART_MODULES_V5({
    ownerID: getOwnerID(state$.value)
  }),
  token: getOwnerToken(state$.value)
} as {
  endpoint: string;
  token: string;
})).pipe(flatMap(response => handleV5Response({
  response,
  onSuccess: getSmartModulesSuccess,
  onFailure: getSmartModulesFailure,
  parser: getSmartModulesResponseParser
})), catchError(handleErrorV5Response(action$, getSmartModulesFailure)))));

export default getSmartModules$;