
import { createAction } from 'redux-actions';
import type {
  ErrorParsedResponse,
  VerifyWalkerStripeTokenParsedResponse,
} from 'webApp';

import {
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
} from '../../actionTypes';


/**
 * Verify walker stripe token
 */
export type VerifyWalkerStripeTokenParams = {
  walkerId: string,
  code: string,
};

export type VerifyWalkerStripeTokenPayload = VerifyWalkerStripeTokenParams;

export type VerifyWalkerStripeTokenAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  payload: VerifyWalkerStripeTokenPayload,
};

export const verifyWalkerStripeToken: (
  VerifyWalkerStripeTokenParams,
) => VerifyWalkerStripeTokenAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  ({
    walkerId,
    code,
  }) => ({
    walkerId,
    code,
  }),
);


/**
 * Verify walker stripe token success
 */
export type VerifyWalkerStripeTokenSuccessAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
  payload: VerifyWalkerStripeTokenParsedResponse,
};

export const verifyWalkerStripeTokenSuccess: (
  VerifyWalkerStripeTokenParsedResponse,
)
=> VerifyWalkerStripeTokenSuccessAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_SUCCESS,
);


/**
 * Verify walker stripe token failure
 */
export type VerifyWalkerStripeTokenFailureAction = {
  type: typeof WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
  payload: ErrorParsedResponse,
};

export const verifyWalkerStripeTokenFailure: (
  ErrorParsedResponse,
) => VerifyWalkerStripeTokenFailureAction = createAction(
  WALKER_STRIPE_CONNECT_VERIFY_TOKEN_FAILURE,
);
