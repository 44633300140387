import { switchMap, flatMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorResponse, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_CREATE_VISITOR_ACCOUNT, Endpoint } from "../../../constants/endpoints";
import { VISITOR_ACCOUNT_CREATE } from "../../../actions/actionTypes";
import { getDeviceInformationHeaders } from "../../../selectors";
import { createVisitorAccountSuccess, createVisitorAccountFailure } from "../../../actions/owner/createVisitorAccount";

const createVisitorAccount$ = (action$: Action$, state$: State$, {
  get$
}: Dependencies) => action$.pipe(ofType(VISITOR_ACCOUNT_CREATE), switchMap(() => get$(({
  endpoint: ENDPOINT_CREATE_VISITOR_ACCOUNT,
  additionalHeaders: getDeviceInformationHeaders(state$.value)
} as {
  endpoint: Endpoint;
  additionalHeaders: any;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: createVisitorAccountSuccess,
  onFailure: createVisitorAccountFailure
})), catchError(response => handleErrorResponse({
  response,
  onError: createVisitorAccountFailure
})))));

export default createVisitorAccount$;