import * as React from 'react';
import { withRouter, Match } from 'react-router-dom';
import { SidebarContext } from '../../../../SidebarContext';

type State = {
  isSidebarVisible: boolean,
}

type Props = {
  children: React$Node,
  match: Match,
}

export class SidebarContextProvider extends React.Component<Props, State> {
  state = {
    isSidebarVisible: false,
  };

  onToggleSidebar = (e: any) => {
    const clickedPath = e.target.getAttribute('data-href');
    const { match: { path } } = this.props;
    if (clickedPath === path) {
      this.setState({
        isSidebarVisible: true,
      });
    }

    this.setState(prevState => ({
      isSidebarVisible: !prevState.isSidebarVisible,
    }));
  };

  render() {
    const {
      children,
    } = this.props;

    const {
      isSidebarVisible,
    } = this.state;

    return (
      <SidebarContext.Provider
        value={{
          isSidebarVisible,
          onToggleSidebar: this.onToggleSidebar,
        }}
      >
        {children}
      </SidebarContext.Provider>
    );
  }
}

export const SidebarContextProviderWithRouter = withRouter<any>(SidebarContextProvider);
