import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const EmailOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M20.224 4.757c-1.955-2.234-4.642-3.562-7.548-3.738a11.027 11.027 0 00-8.764 3.527c-2.255 2.437-3.267 5.735-2.8 9.051.722 5.084 4.826 8.937 9.979 9.359.343.026.687.044 1.03.044 3.11 0 5.963-1.161 7.953-3.263l-.643-.607c-2.017 2.137-5.046 3.22-8.27 2.955-4.739-.387-8.508-3.923-9.178-8.602-.431-3.052.502-6.087 2.572-8.33a10.133 10.133 0 018.06-3.246c2.668.159 5.134 1.381 6.94 3.44a10.327 10.327 0 012.536 7.503c-.017.202-.044.43-.079.66a2.839 2.839 0 01-2.783 2.427c-2.414 0-3.268-1.504-3.268-2.797V7.132h-.88v1.504c-.732-.906-1.833-1.504-3.083-1.504a3.967 3.967 0 00-3.964 3.958v1.76a3.967 3.967 0 003.964 3.958c1.497 0 2.783-.845 3.461-2.067.564 1.16 1.788 2.067 3.823 2.067 1.805 0 3.355-1.372 3.61-3.228.027-.203.062-.458.08-.678.211-2.929-.784-5.902-2.748-8.145zm-8.226 11.171a3.084 3.084 0 01-3.083-3.078v-1.76a3.084 3.084 0 013.083-3.078c1.7 0 3.082 1.38 3.082 3.078v1.76a3.084 3.084 0 01-3.082 3.078z"
      fill="#474747"
      fillRule="nonzero"
    />
  </SvgIcon>
);
