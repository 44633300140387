import {
  switchMap,
  flatMap,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type {
  Action$,
  State$,
  Dependencies,
} from 'redux-observable';
import {
  handleErrorResponse,
  handleResponse,
} from '../../../helpers/Http/responseHandlers';
import {
  ENDPOINT_OWNER_MASKED_INFO_GET,
  Endpoint,
} from '../../../constants/endpoints';
import { OWNER_MASKED_INFO_GET } from '../../../actions/actionTypes';
import { getOwnerMaskedInfoResponseParser } from '../../../helpers/Http/ResponseParsers';
import {
  getOwnerMaskedInfoSuccess,
  getOwnerMaskedInfoFailure,
} from '../../../actions/owner/getOwnerMaskedInfo';

const getOwnerMaskedInfo$ = (
  action$: Action$,
  state$: State$,
  { get$ }: Dependencies
) =>
  action$.pipe(
    ofType(OWNER_MASKED_INFO_GET),
    switchMap(() =>
      get$({
        endpoint: ENDPOINT_OWNER_MASKED_INFO_GET,
      } as {
        endpoint: Endpoint;
        additionalHeaders: any;
      }).pipe(
        flatMap((response) =>
          handleResponse({
            response,
            onSuccess: getOwnerMaskedInfoSuccess,
            onFailure: getOwnerMaskedInfoFailure,
            parser: getOwnerMaskedInfoResponseParser,
          })
        ),
        catchError((response) =>
          handleErrorResponse({
            response,
            onError: getOwnerMaskedInfoFailure,
          })
        )
      )
    )
  );

export default getOwnerMaskedInfo$;
