import { flatMap, retry, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import type { VerifyWalkerStripeTokenRequestPayload } from "wagAPI";
import type { Action$, State$, Dependencies } from "redux-observable";
import { handleErrorV5Response, handleResponse } from "../../../helpers/Http/responseHandlers";
import { ENDPOINT_WALKER_STRIPE_CONNECT_VERIFY_TOKEN, Endpoint } from "../../../constants/endpoints";
import { WALKER_STRIPE_CONNECT_VERIFY_TOKEN } from "../../../actions/actionTypes";
import { verifyWalkerStripeTokenSuccess, verifyWalkerStripeTokenFailure, VerifyWalkerStripeTokenAction } from "../../../actions/walker/verifyWalkerStripeToken";
import { getWalkerToken } from "../../../selectors";
import { verifyWalkerStripeTokenResponseParser } from "../../../helpers/Http/ResponseParsers";

const verifyWalkerStripeToken$ = (action$: Action$, state$: State$, {
  postLegacy$
}: Dependencies) => action$.pipe(ofType(WALKER_STRIPE_CONNECT_VERIFY_TOKEN), retry(3), switchMap(({
  payload: {
    code,
    walkerId
  }
}: VerifyWalkerStripeTokenAction) => postLegacy$(({
  endpoint: ENDPOINT_WALKER_STRIPE_CONNECT_VERIFY_TOKEN,
  payload: {
    code,
    id: walkerId,
    walkerId
  },
  token: getWalkerToken(state$.value)
} as {
  endpoint: Endpoint;
  payload: VerifyWalkerStripeTokenRequestPayload;
  token: string;
})).pipe(flatMap(response => handleResponse({
  response,
  onSuccess: verifyWalkerStripeTokenSuccess,
  onFailure: verifyWalkerStripeTokenFailure,
  parser: verifyWalkerStripeTokenResponseParser
})), catchError(handleErrorV5Response(action$, verifyWalkerStripeTokenFailure)))));

export default verifyWalkerStripeToken$;