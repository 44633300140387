import React, { Component } from "react";
import { TrackerContext } from ".";
import type { TrackType, TrackProps as TrackFuncProps } from ".";
export type ChildRenderer = (arg0: {
  track: TrackType;
}) => React.ReactNode;
export type TrackerProps = {
  trackMountProps?: TrackFuncProps;
  children?: ChildRenderer;
  render?: ChildRenderer; // eslint-disable-line react/no-unused-prop-types

};
type TrackProps = {
  track: TrackType;
};
type Props = TrackerProps & TrackProps;

class TrackerComponent extends Component<Props> {
  componentDidMount() {
    const {
      track,
      trackMountProps
    } = this.props;

    if (trackMountProps) {
      track(trackMountProps);
    }
  }

  render() {
    const {
      children,
      track
    } = this.props;
    return children ? children({
      track
    }) : null;
  }

}

const Tracker = ({
  children,
  render,
  trackMountProps
}: TrackerProps) => <TrackerContext.Consumer>
    {({
    track
  }) => <TrackerComponent track={track} trackMountProps={trackMountProps}>
        {children || render}
      </TrackerComponent>}
  </TrackerContext.Consumer>;

export default Tracker;