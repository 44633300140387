
import { createAction } from 'redux-actions';
import type { ErrorParsedResponse } from 'webApp';

import {
  DOG_BREEDS_CLEAR,
  DOG_BREEDS_GET,
  DOG_BREEDS_GET_SUCCESS,
  DOG_BREEDS_GET_FAILURE,
} from '../../actionTypes';

/**
 * Get Dog Breeds
 */
export type GetDogBreedsAction = {
  type: typeof DOG_BREEDS_GET,
};

export const getDogBreeds: () => GetDogBreedsAction = createAction(DOG_BREEDS_GET);


/**
 * Get Dog Breeds Success
 */
export type GetDogBreedsSuccessActionParams = {
  breeds: Array<string>,
};

export type GetDogBreedsSuccessActionPayload = GetDogBreedsSuccessActionParams;

export type GetDogBreedsSuccessAction = {
  type: typeof DOG_BREEDS_GET_SUCCESS,
  payload: GetDogBreedsSuccessActionPayload,
};

export const getDogBreedsSuccess: (
  GetDogBreedsSuccessActionParams
) => GetDogBreedsSuccessAction = createAction(
  DOG_BREEDS_GET_SUCCESS,
  ({ breeds }) => ({ breeds }),
);


/**
 * Get Dog Breeds Failure
 */
export type GetDogBreedsFailureActionAction = {
  type: typeof DOG_BREEDS_GET_FAILURE,
  payload: ErrorParsedResponse,
};

export const getDogBreedsFailure: (
  ErrorParsedResponse,
) => GetDogBreedsFailureActionAction = createAction(
  DOG_BREEDS_GET_FAILURE,
);


/**
 * Clear dog breeds
 */
export type ClearBreedsAction = {
  type: typeof DOG_BREEDS_CLEAR,
};

export const clearDogBreeds: () => ClearBreedsAction = createAction(DOG_BREEDS_CLEAR);
