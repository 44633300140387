// ! TODO: - Move to wag-react
import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const LockOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    color="#727272"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      d="M20.913 11.56h-1.522V8.48C19.391 4.353 16.078 1 12 1 7.922 1 4.609 4.353 4.609 8.48v3.08H3.087c-.6 0-1.087.493-1.087 1.1v9.24c0 .607.487 1.1 1.087 1.1h17.826c.6 0 1.087-.493 1.087-1.1v-9.24c0-.607-.487-1.1-1.087-1.1zM5.478 8.48c0-3.643 2.922-6.6 6.522-6.6s6.522 2.957 6.522 6.6v3.08H5.478V8.48zM21.13 21.9c0 .123-.095.22-.217.22H3.087a.217.217 0 01-.217-.22v-9.24c0-.123.095-.22.217-.22h17.826c.122 0 .217.097.217.22v9.24zM12 14.2c-1.2 0-2.174.986-2.174 2.2 0 .686.33 1.338.87 1.751v1.769c0 .246.191.44.434.44h1.74a.433.433 0 00.434-.44v-1.769c.54-.413.87-1.065.87-1.751 0-1.214-.974-2.2-2.174-2.2zm.652 3.335a.445.445 0 00-.217.379v1.566h-.87v-1.566c0-.159-.078-.3-.217-.379a1.308 1.308 0 01-.652-1.135c0-.73.582-1.32 1.304-1.32.722 0 1.304.59 1.304 1.32 0 .466-.243.898-.652 1.135z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);
