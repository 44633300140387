import { combineEpics } from "redux-observable";
import getWalkCoordinates$ from "./getWalkCoordinates/getWalkCoordinates.epic";
import getWalksInPendingReview$ from "./getWalksInPendingReview/getWalksInPendingReview.epic";
import getWalksInProgress$ from "./getWalksInProgress/getWalksInProgress.epic";
import getWalksInThePast$ from "./getWalksInThePast/getWalksInThePast.epic";
import getWalksUpcoming$ from "./getWalksUpcoming/getWalksUpcoming.epic";
import pollWalkCoordinates$ from "./pollWalkCoordinates/pollWalkCoordinates.epic";
import pollWalks$ from "./pollWalks/pollWalks.epic";
import updateWalkSchedule$ from "./updateWalkSchedule/updateWalkSchedule.epic";
import walkRateAndTip$ from "./walkRateAndTip/walkRateAndTip.epic";
import { getWalkDetails$ } from "./getWalkDetails";
export default combineEpics(getWalkDetails$, getWalkCoordinates$, getWalksInPendingReview$, getWalksInProgress$, getWalksInThePast$, getWalksUpcoming$, pollWalkCoordinates$, pollWalks$, updateWalkSchedule$, walkRateAndTip$);