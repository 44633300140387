import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

export const PhoneOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M17.917 1H7.083C6.485 1 6 1.493 6 2.1v19.8c0 .607.485 1.1 1.083 1.1h10.834c.598 0 1.083-.493 1.083-1.1V2.1c0-.607-.485-1.1-1.083-1.1zm.216 20.9c0 .123-.095.22-.216.22H7.083a.216.216 0 01-.216-.22v-3.3h11.266v3.3zm0-4.18H6.867V5.4h11.266v12.32zm0-13.2H6.867V2.1c0-.123.095-.22.216-.22h10.834c.121 0 .216.097.216.22v2.42zm-7.8-1.76h4.334v.88h-4.334v-.88zm1.3 17.6c0-.484.39-.88.867-.88s.867.396.867.88-.39.88-.867.88a.876.876 0 01-.867-.88z"
      fill="#727272"
      fillRule="nonzero"
    />
  </SvgIcon>
);
