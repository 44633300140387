import React from 'react';

import {
  SvgIcon,
  SvgIconProps,
} from '@waglabs/icons';

/**
 * TODO: Merge with UserOutlinedIcon
 * At the time of writing this I was unable to merge them due to
 * lack of knowledge on SVG manipulations
 */
export const ProfileUserOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#FFF"
        d="M-535-357H905v881H-535z"
      />
      <path
        d="M12 23C5.937 23 1 18.063 1 12S5.937 1 12 1s11 4.937 11 11-4.937 11-11 11zm-6.82-3.529A10.09 10.09 0 0012 22.12a10.09 10.09 0 006.82-2.649c-.607-1.39-2.385-2.13-3.82-2.719-1.486-.607-1.68-1.408-1.68-2.103 0-.678.326-1.426.818-1.866.66-.58 1.004-1.381 1.004-2.323 0-1.584-.986-3.291-3.142-3.291-2.112 0-3.062 1.654-3.062 3.291 0 .986.316 1.769.95 2.323.58.52.792 1.329.792 1.866 0 .704-.194 1.496-1.62 2.077-1.495.616-3.273 1.355-3.88 2.745zM12 6.28c2.332 0 4.022 1.751 4.022 4.171 0 1.197-.45 2.235-1.303 2.983-.299.273-.519.775-.519 1.206 0 .387 0 .827 1.065 1.267 1.478.616 3.361 1.4 4.206 2.913a10.05 10.05 0 002.649-6.811c0-5.58-4.54-10.12-10.12-10.12-5.58 0-10.12 4.54-10.12 10.12a10.05 10.05 0 002.649 6.811c.845-1.514 2.728-2.297 4.136-2.878 1.144-.466 1.144-.924 1.144-1.293 0-.37-.159-.907-.493-1.206-.81-.721-1.24-1.751-1.24-2.983C8.057 7.996 9.685 6.28 12 6.28z"
        fill="#727272"
      />
    </g>
  </SvgIcon>
);
