
import { createAction } from 'redux-actions';
import type {
  GetWalkerWalkParsedResponse,
} from 'webApp';

import {
  WALKER_SCHEDULE_GET,
  WALKER_SCHEDULE_GET_SUCCESS,
  WALKER_SCHEDULE_GET_FAILURE,
} from '../../actionTypes';


/**
 * getWalkerWalk Action Creator
 */
type GetWalkerWalkParams = {
  id: string;
  token: string;
  walkId: string;
};

type GetWalkerWalkPayload = GetWalkerWalkParams;

export type GetWalkerWalkAction = {
  type: typeof WALKER_SCHEDULE_GET;
  payload: GetWalkerWalkPayload;
};

export const getWalkerWalk: (params: GetWalkerWalkParams) => GetWalkerWalkAction = createAction(
  WALKER_SCHEDULE_GET,
  ({
    id,
    token,
    walkId,
  }) => ({
    id,
    token,
    walkId,
  }),
);


/**
 * getWalkerWalkSuccess Action Creator
 */
export type GetWalkerWalkSuccessParams = GetWalkerWalkParsedResponse;

export type GetWalkerWalkSuccessPayload = GetWalkerWalkParsedResponse;

export type GetWalkerWalkSuccessAction = {
  type: typeof WALKER_SCHEDULE_GET_SUCCESS,
  payload: GetWalkerWalkSuccessPayload,
};

export const getWalkerWalkSuccess: (
  GetWalkerWalkSuccessParams,
) => GetWalkerWalkSuccessAction = createAction(
  WALKER_SCHEDULE_GET_SUCCESS,
);


/**
 * getWalkerWalkFailure Action Creator
 */
export type GetWalkerWalkFailureAction = {
  type: typeof WALKER_SCHEDULE_GET_SUCCESS,
  payload: GetWalkerWalkSuccessPayload,
};

export const getWalkerWalkFailure: () => (
  GetWalkerWalkFailureAction
) = createAction(WALKER_SCHEDULE_GET_FAILURE);
