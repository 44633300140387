import {
  handleActions,
  combineActions,
} from 'redux-actions';
import type { Owner } from 'webApp';

import {
  CREDIT_CARD_UPDATE_SUCCESS,
  LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS,
  LOGOUT,
  MY_PROFILE_UPDATE_SUCCESS,
  OWNER_ADDRESS_V2_REGISTER,
  OWNER_AVAILABLE_SERVICES_SUCCESS,
  OWNER_CREDIT_GET_SUCCESS,
  OWNER_GET_SUCCESS,
  OWNER_HOME_ACCESS_GET_SUCCESS,
  OWNER_LOGIN_SUCCESS,
  OWNER_MASKED_INFO_GET_SUCCESS,
  OWNER_PAYMENT_METHOD_CREATE_SUCCESS,
  OWNER_PAYMENT_METHODS_GET_SUCCESS,
  OWNER_REGISTRATION_ADDRESS_SUCCESS,
  OWNER_REGISTRATION_ADDRESS,
  OWNER_REGISTRATION_BASIC_INFO_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS,
  OWNER_REGISTRATION_BASIC_INFO,
  OWNER_REGISTRATION_BASIC_INFO_V2,
  OWNER_REGISTRATION_PREFERRED_SERVICES,
  OWNER_REGISTRATION_PREFERRED_SERVICES_RAW,
  OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS,
  OWNER_SERVICES_GET_SUCCESS,
  OWNER_SMART_MODULES_SUCCESS,
  OWNER_UPDATE_SUCCESS,
  PROMO_CODE_APPLY_SUCCESS,
  PROMO_CODE_APPLY_FAILURE,
  PROMO_CODE_RESET,
  SERVICE_PROVIDERS_GET_SUCCESS,
  OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIBE_TO_PREMIUM_SUCCESS,
  PAST_PET_CAREGIVERS_GET_SUCCESS,
} from '../../actions/actionTypes';
import type { GetOwnerPremiumSubscriptionSuccessAction } from '../../actions/owner';
import type { ApplyPromoCodeSuccessAction } from '../../actions/owner/applyPromoCode';
import type { CreateLoginWithCodeRequestSuccessAction } from '../../actions/owner/createLoginWithCodeRequest';
import type { GetAvailableServicesSuccessAction } from '../../actions/owner/getAvailableServices';
import type { GetOwnerSuccessAction } from '../../actions/owner/getOwner';
import type { GetOwnerCreditSuccessAction } from '../../actions/owner/getOwnerCredit';
import type { GetOwnerMaskedInfoSuccessAction } from '../../actions/owner/getOwnerMaskedInfo';
import type { GetOwnerPaymentMethodsSuccessAction } from '../../actions/owner/getOwnerPaymentMethods';
import type { GetOwnerServicesSuccessAction } from '../../actions/owner/getOwnerServices';
import type { GetServiceProvidersSuccessAction } from '../../actions/owner/getServiceProviders';
import type { GetSmartModulesSuccessAction } from '../../actions/owner/getSmartModules';
import type { LoginOwnerSuccessAction } from '../../actions/owner/loginOwner';
import type { UpdateCreditCardSuccessAction } from '../../actions/owner/updateCreditCard';
import type { UpdateOwnerSuccessAction } from '../../actions/owner/updateOwner';
import type {
  RegisterOwnerAddressAction,
  RegisterOwnerAddressSuccessAction,
} from '../../actions/registerOwner/registerOwnerAddress';
import type { RegisterOwnerAddressV2Action } from '../../actions/registerOwner/registerOwnerAddressV2';
import type {
  RegisterOwnerBasicInfoAction,
  RegisterOwnerBasicInfoSuccessAction,
} from '../../actions/registerOwner/registerOwnerBasicInfo';
import type { RegisterOwnerGoogleSigninSuccessAction } from '../../actions/registerOwner/registerOwnerGoogleSignin';
import type { RegisterOwnerPreferredServicesAction } from '../../actions/registerOwner/registerOwnerPreferredServices';

type OwnerState = Owner;

export const initialState: Owner = {
  address: {
    apartment: '',
    cityID: '',
    latitude: 0,
    longitude: 0,
    regionID: '',
    state: '',
    streetAddress: '',
    zipCode: '',
  },
  appVersion: '',
  cohorts: [],
  confirmationTimes: null,
  createdAt: '',
  dog: {
    age: '',
    birthday: '',
    breedPlural: '',
    breed: '',
    createdAt: '',
    dogID: null,
    guid: '',
    imageURL: '',
    likes: '',
    name: '',
    notes: '',
    updatedAt: '',
    vetName: '',
    vetPhone: '',
  },
  dogCount: 0,
  dogID: '',
  dogs: [],
  email: '',
  firstName: '',
  homeAccess: {
    home: {
      htgiHomeLockbox: false,
      htgiHomeLockboxCode: '',
      htgiHomeLockboxLocation: '',
      htgiHomeLockboxOther: '',
      htgiHomeKeyHidden: false,
      htgiHomeKeyHiddenLocation: '',
      htgiHomeDoorman: false,
      htgiHomeAtHome: false,
      htgiHomeOther: false,
      htgiHomeOtherDetail: '',
    },
    additional_notes: {
      htgiAdditionalNotes: false,
      htgiAdditionalNotesText: '',
      additionalNotes: '',
    },
  },
  freeWalk: false,
  isCardInvalid: false,
  lastName: '',
  loginKey: '',
  lockbox: {
    code: '',
    status: 0,
    address: '',
    type: 0,
  },
  location: {
    timeZone: '',
    zipTimeZone: '',
    primaryCity: '',
    latitude: 0,
    longitude: 0,
    cityID: '',
  },
  maskedEmail: '',
  maskedPhone: '',
  meetAndGreetCompletedAt: '',
  numberOfReferrals: 0,
  numberOfWalksCompleted: 0,
  ownerID: null,
  paymentMethods: [],
  phone: '',
  pastPetCaregivers: [],
  preferredServices: [],
  preferredWalkers: [],
  promoCode: '',
  services: [],
  stripeCard: {
    brand: null,
    expirationMonth: null,
    expirationYear: null,
    isCardInvalid: true,
    lastFourDigits: null,
  },
  tokenExpirary: 0,
  updatedAt: '',
  walkTypes: [],
  creditBalance: 0,
  pastDueBalance: 0,
  temporaryCreditBalance: 0,
  modules: [],
  availableServices: [],
  promoCodeSuccess: {},
  premium: {
    isSubscribed: false,
    canSubscribe: false,
    canTrial: false,
    willAutoRenew: null,
    nextInvoiceDate: null,
    trialEndDate: null,
    trialStartDate: null,
    servicesWithPremium: [],
    servicesWithoutPremium: [],
  },
};

type UpdateOwnerSuccess = UpdateOwnerSuccessAction
& RegisterOwnerAddressSuccessAction;

export const reducer: any = handleActions(
  {
    [OWNER_GET_SUCCESS]: (state: OwnerState, {
      payload: {
        address,
        dog,
        stripeCard,
        ...payload
      },
    }: GetOwnerSuccessAction): OwnerState => ({
      ...state,
      address: {
        ...state.address,
        ...address,
      },
      dog: {
        ...state.dog,
        ...dog,
      },
      stripeCard: {
        ...state.stripeCard,
        ...stripeCard,
      },
      ...payload,
    }),

    [OWNER_HOME_ACCESS_GET_SUCCESS]: (state: OwnerState, {
      payload,
    }): OwnerState => ({
      ...state,
      homeAccess: { ...payload, success: undefined },
    }),

    [OWNER_SERVICES_GET_SUCCESS]: (state: OwnerState, {
      payload,
    }: GetOwnerServicesSuccessAction): OwnerState => ({
      ...state,
      services: payload,
    }),

    [combineActions(
      OWNER_PREMIUM_SUBSCRIPTION_GET_SUCCESS,
      SUBSCRIBE_TO_PREMIUM_SUCCESS,
    )]: (state: OwnerState, {
      payload,
    }: GetOwnerPremiumSubscriptionSuccessAction): OwnerState => ({
      ...state,
      premium: payload,
    }),

    [PROMO_CODE_APPLY_SUCCESS]: (state: OwnerState, {
      payload,
    }: ApplyPromoCodeSuccessAction): OwnerState => ({
      ...state,
      creditBalance: payload.balance,
      promoCodeSuccess: payload,
    }),

    [PROMO_CODE_APPLY_FAILURE]: (state: OwnerState, {
      payload,
    }: ApplyPromoCodeSuccessAction): OwnerState => ({
      ...state,
      promoCodeSuccess: payload.response || {},
    }),

    [PROMO_CODE_RESET]: (state: OwnerState): OwnerState => ({
      ...state,
      promoCodeSuccess: {},
    }),

    [OWNER_PAYMENT_METHODS_GET_SUCCESS]: (state: OwnerState, {
      payload,
    }: GetOwnerPaymentMethodsSuccessAction): OwnerState => ({
      ...state,
      paymentMethods: payload,
    }),

    [OWNER_PAYMENT_METHOD_CREATE_SUCCESS]: (state: OwnerState, {
      payload,
    }: GetOwnerPaymentMethodsSuccessAction): OwnerState => ({
      ...state,
      paymentMethods: [...state.paymentMethods].concat(payload),
    }),

    [combineActions(
      OWNER_REGISTRATION_BASIC_INFO,
      OWNER_REGISTRATION_BASIC_INFO_V2,
    )]: (state: OwnerState, {
      payload: {
        email,
        firstName,
        lastName,
        phone,
      },
    }: RegisterOwnerBasicInfoAction): OwnerState => ({
      ...state,
      email,
      firstName,
      lastName,
      phone,
    }),

    [OWNER_GOOGLE_SIGNIN_REGISTER_SUCCESS]: (state: OwnerState, {
      payload: {
        ownerID,
        ownerToken,
        firstName,
        lastName,
        email,
        phone,
      },
    }: RegisterOwnerGoogleSigninSuccessAction): OwnerState => ({
      ...state,
      ownerID,
      ownerToken,
      firstName,
      lastName,
      email,
      phone,
    }),

    [combineActions(
      OWNER_REGISTRATION_PREFERRED_SERVICES,
      OWNER_REGISTRATION_PREFERRED_SERVICES_RAW,
    )]: (state: OwnerState, {
      payload: {
        preferredServices,
      },
    }: RegisterOwnerPreferredServicesAction): OwnerState => ({
      ...state,
      preferredServices: [
        ...preferredServices,
      ],
    }),

    [LOGIN_WITH_CODE_REQUEST_CREATE_SUCCESS]: (state: OwnerState, {
      payload: {
        loginKey,
      },
    }: CreateLoginWithCodeRequestSuccessAction): OwnerState => ({
      ...state,
      loginKey,
    }),

    [OWNER_MASKED_INFO_GET_SUCCESS]: (state: OwnerState, {
      payload: {
        maskedEmail,
        maskedPhone,
      },
    }: GetOwnerMaskedInfoSuccessAction): OwnerState => ({
      ...state,
      maskedEmail,
      maskedPhone,
    }),

    [OWNER_REGISTRATION_BASIC_INFO_SUCCESS]: (state: OwnerState, {
      payload,
    }: RegisterOwnerBasicInfoSuccessAction): OwnerState => ({
      ...state,
      ...payload,
    }),

    [OWNER_REGISTRATION_BASIC_INFO_V2_SUCCESS]: (state: OwnerState, {
      payload: {
        ownerID,
        ownerToken,
      },
    }: RegisterOwnerBasicInfoSuccessAction): OwnerState => ({
      ...state,
      ownerID,
      ownerToken,
    }),

    [OWNER_LOGIN_SUCCESS]: (state: OwnerState, {
      payload: {
        ownerID,
      },
    }: LoginOwnerSuccessAction): OwnerState => ({
      ...state,
      ownerID,
    }),

    [OWNER_REGISTRATION_ADDRESS]: (state: OwnerState, {
      payload: {
        city,
        ...address
      },
    }: RegisterOwnerAddressAction): OwnerState => ({
      ...state,
      address: {
        ...state.address,
        cityID: city,
        ...address,
      },
    }),

    [OWNER_ADDRESS_V2_REGISTER]: (state: OwnerState, {
      payload,
    }: RegisterOwnerAddressV2Action): OwnerState => ({
      ...state,
      address: payload,
    }),

    [combineActions(
      OWNER_REGISTRATION_ADDRESS_SUCCESS,
      OWNER_UPDATE_SUCCESS,
      MY_PROFILE_UPDATE_SUCCESS,
    )]: (state: OwnerState, {
      payload,
    }: UpdateOwnerSuccess): OwnerState => ({
      ...state,
      ...payload,
      address: {
        ...state.address,
        ...payload.address,
      },
    }),

    [combineActions(
      OWNER_CREDIT_GET_SUCCESS,
    )]: (state: OwnerState, {
      payload,
    }: GetOwnerCreditSuccessAction): OwnerState => ({
      ...state,
      ...payload,
    }),

    [CREDIT_CARD_UPDATE_SUCCESS]: (state: OwnerState, {
      payload: {
        brand,
        expirationMonth,
        expirationYear,
        isCardInvalid,
        lastFourDigits,
      },
    }: UpdateCreditCardSuccessAction): OwnerState => ({
      ...state,
      isCardInvalid,
      stripeCard: {
        brand,
        lastFourDigits,
        expirationMonth,
        expirationYear,
        isCardInvalid,
      },
    }),

    [LOGOUT]: () => initialState,

    [OWNER_SMART_MODULES_SUCCESS]: (state: OwnerState, {
      payload: {
        modules,
      },
    }: GetSmartModulesSuccessAction): OwnerState => ({
      ...state,
      modules,
    }),

    [OWNER_AVAILABLE_SERVICES_SUCCESS]: (state: OwnerState, {
      payload: {
        availableServices,
        defaultServices,
        servicesGroupings,
      },
    }: GetAvailableServicesSuccessAction): OwnerState => ({
      ...state,
      availableServices,
      defaultServices,
      servicesGroupings,
    }),
    [SERVICE_PROVIDERS_GET_SUCCESS]: (state: OwnerState, {
      payload: {
        profiles,
        city,
        state: cityState,
        service,
        page,
        latitude,
        longitude,
      },
    }: GetServiceProvidersSuccessAction) => ({
      ...state,
      serviceProvider: {
        profiles,
        city,
        state: cityState,
        service,
        page,
        latitude,
        longitude,
      },
    }),
    [PAST_PET_CAREGIVERS_GET_SUCCESS]: (state: OwnerState, {
      payload,
    }) => ({
      ...state,
      pastPetCaregivers: payload,
    }),
  },
  initialState,
);

export default reducer;
