import { ApiErrorV7 } from '../buildApiErrorPipeline';

export const createErrorForV7 = (
  error: ApiErrorV7,
  newSourceError?: ApiErrorV7,
): ApiErrorV7 => {
  const v7Error = (new Error(error as any) as unknown) as ApiErrorV7;
  v7Error.version = 7;

  // Copy error object to another
  const source = newSourceError || error;
  return Object.getOwnPropertyNames(source).reduce((accumulator, key) => {
    accumulator[key] = source[key];
    return accumulator;
  }, v7Error);
};
