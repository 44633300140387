import 'react-app-polyfill/ie11';
import './polyfills';
import React from 'react';
import 'whatwg-fetch'; // required until cypress support fetch API
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

import store from './store/configureStore';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

unregister();


// For react-widgets
Moment.locale('en');
momentLocalizer();

const element = document.getElementById('root');

const renderApp = () => {
  render(
    <Provider store={store}>
      <App {...element.dataset} />
    </Provider>,
    element,
  );
};
renderApp();

/*
 * Use Cypress to assert values from redux store
 * https://www.cypress.io/blog/2018/11/14/testing-redux-store/
 * window.Cypress won't be instantiated in any other environment
 */
if (window.Cypress) {
  window.store = store;
}
