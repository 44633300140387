/* eslint-disable */

/**
 * Number.isNaN Polyfill
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
 */

Number.isNaN =
  Number.isNaN ||
  function(value) {
    // eslint-disable-next-line
    return value !== value;
  };
