import * as React from 'react';
import Box from '@waglabs/box';

export const MyProfileSeparator = ({ hasError, ...props }: any) => (
  <Box
    width={1}
    height={1}
    bg={hasError ? 'red' : 'backgroundGray'}
    my={2}
    {...props}
  />
);
